import { formatWithOptions } from 'date-fns/fp'
import { es } from 'date-fns/locale'
import { toDate } from './parseDate'
import { isNullOrUndefined } from 'utils/objects'

export function formatDateWithPattern(value: any, pattern: string) {
  const dateToString = formatWithOptions({ locale: es }, pattern)
  const dateValue = toDate(value)
  return dateValue !== undefined ? dateToString(dateValue) : value
}

export function formatDateOnly(value: any) {
  return formatDateWithPattern(value, 'dd/MM/yyyy')
}

export function formatDateWithHHMM(value: any) {
  return formatDateWithPattern(value, 'dd/MM/yyyy HH:mm')
}

export function formatDateNoYearWithHHMMSS(value: any) {
  return formatDateWithPattern(value, 'dd/MM HH:mm:SS')
}

export function formatTimestampShort(value: any) {
  return formatDateWithPattern(value, 'dd/MM HH:mm')
}

export function formatTimestamp(value: any) {
  return formatDateWithPattern(value, 'dd/MM HH:mm:ss')
}

export function formatDateISO(value: any) {
  const dateValue = toDate(value)
  return !isNullOrUndefined(dateValue) ? dateValue?.toISOString() : dateValue
}

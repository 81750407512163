import React, { ReactNode, useEffect, useState } from 'react'
import LoadingOverlay from 'react-loading-overlay'

import './BymaLoadingOverlay.scss'

interface BymaLoadingOverlayProps {
  isLoading: boolean
  loadingText?: string
  className?: string
  children: ReactNode
  variant?: 'small' | 'default'
  showLoadingOverlay?: boolean
}

const Loading = (props: BymaLoadingOverlayProps) => {
  const { variant = 'default' } = props

  return (
    <LoadingOverlay
      active={props.isLoading}
      text={props.loadingText}
      className={`byma-loading ${props.className ?? ''}`}
      spinner
      styles={{
        spinner: (base) => ({
          ...base,
          width: variant === 'default' ? '80px' : '19px',
          height: variant === 'default' ? '80px' : '19px',
          '& svg circle': {
            stroke: '#0D6EFD',
          },
        }),
      }}
    >
      {props.children}
    </LoadingOverlay>
  )
}

const MINIMUM_DELAY = 300

const DelayedLoading = (props: BymaLoadingOverlayProps) => {
  const [lastLoading, setLastLoading] = useState(0)

  //console.log(`DEBUG withLoadingOverlay DelayedLoading isLoading=${props.isLoading}`)

  useEffect(() => {
    if (props.isLoading && lastLoading === 0) {
      setLastLoading(Date.now())
      setTimeout(() => {
        setLastLoading(0)
      }, MINIMUM_DELAY)
    }
  }, [props.isLoading, lastLoading])

  const show = props.isLoading || lastLoading !== 0

  return <Loading {...props} isLoading={show} />
}

export const withLoadingOverlay = (props: BymaLoadingOverlayProps) => {
  const { showLoadingOverlay = true } = props

  //console.log(`DEBUG withLoadingOverlay showLoadingOverlay=${showLoadingOverlay}`)

  return showLoadingOverlay ? <DelayedLoading {...props} /> : <>{props.children}</>
}

export default DelayedLoading

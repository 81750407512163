import React from 'react'
import { BymaSelect, SelectProps } from '../BymaSelect'

import styles from './BymaMultiSelect.module.scss'

interface MultiSelectProps extends SelectProps {
  options: SelectOption[]
  onChange?: (selected: any) => void
  menuIsOpen?: boolean
  //width?: string
  //height?: string
  valueMapper?: (option: SelectOption | null) => any
  className?: any
}

export const BymaMultiSelect = (props: MultiSelectProps) => {
  return (
    <BymaSelect
      {...props}
      multiselect={true}
      useCheckbox={false}
      className={`${props.className || styles.bymaMultiSelect}`}
    />
  )
}

import CreateColocacion from './ColocacionCreate'
import EditColocacion from './ColocacionView'
import { AgentesPanel, ColocacionRoutes } from './ColocacionView'

export { AgentesPanel }

export default {
  create: CreateColocacion,
  edit: EditColocacion,
  routes: ColocacionRoutes,
}

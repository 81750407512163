import * as React from 'react'
import FilterForm from './FilterForm'
import FilterButton from './FilterButton'

import { Col, Row } from 'react-bootstrap'

import styles from './Filter.module.scss'

export const ListFilters = ({
  filters,
  showFiltersMenu,
  filtersWrapperColClassName = '',
  filterFormStyles = {},
  filterBtnColClassName = '',
  filterBtnClassName = '',
  listFiltersRowContainer = '',
}) => {
  return (
    <Row className={listFiltersRowContainer}>
      <Col className={`col-10 ${filtersWrapperColClassName} ps-0`}>
        <FilterForm filters={filters} className={styles.filtersForm} style={filterFormStyles} />
      </Col>

      {showFiltersMenu ? (
        <Col className={`${styles.filtersButtonCol} ${filterBtnColClassName || 'col-2'}`}>
          <FilterButton
            filters={filters}
            className={`${styles.filtersButton} ${filterBtnClassName}`}
          />
        </Col>
      ) : null}
    </Row>
  )
}

import React from 'react'
import { Row } from 'react-bootstrap'

import IntegracionesList from './IntegracionesList'
import { useColocacionEditContext } from 'features/Colocacion/ColocacionViewContext'
import { ColocacionSectionCard } from '../../ColocacionSectionCard'
import { AddIcon } from 'components/BymaIcons'

import { ColocacionSummary } from 'features/Colocacion/ColocacionSummary'
import { Loading } from 'components'
import { LocalErrorProvider } from 'core'

const IntegracionesSection = () => {
  const props = useColocacionEditContext()

  return (
    <ColocacionSectionCard title='Agregar Integraciones' icon={AddIcon}>
      <Row className='mb-3'>
        <ColocacionSummary {...props} showTitulo={true} />
      </Row>
      <Row className='p-0'>
        <Loading>
          <LocalErrorProvider>
            <IntegracionesList {...props} />
          </LocalErrorProvider>
        </Loading>
      </Row>
    </ColocacionSectionCard>
  )
}

export default IntegracionesSection

import React, { ReactNode } from 'react'

import { BymaLoadingOverlay } from '.'
import { useLoadingConsumer } from 'core'

interface LoadingProps {
  className?: string
  children: ReactNode
}

const Loading = (props: LoadingProps) => {
  const loadingConsumer = useLoadingConsumer()

  const isLoading = loadingConsumer.tasks.length > 0
  const loadingText: string = (isLoading ? loadingConsumer.tasks[0].message : undefined) || ''

  return (
    <BymaLoadingOverlay isLoading={isLoading} loadingText={loadingText} className={props.className}>
      {props.children}
    </BymaLoadingOverlay>
  )
}

export default Loading

import { useCallback, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { ColocacionContextInfo, useColocacionContext } from './context'
import { ColocacionesApi } from 'model'
import { isComponentAuthorized, useAuthContext } from 'core/auth'
import * as BymaPermissions from 'services/auth/bymaPermissions'
import { OfertaContextInfo } from 'features/common/Oferta'

/**
 * Secciones del detalle de la Colocacion (para utilizar junto a useState y cambiar lo que se ve en la seccion derecha)
 */
export enum ColocacionSectionType {
  COLOCACION = 'COLOCACION',
  TABLERO_COLOCACIONES = 'TABLERO_COLOCACIONES',
  TITULOS = 'TITULOS',
  AGENTES = 'AGENTES',
  CO_COLOCADORES = 'CO_COLOCADORES',
  CATEGORIAS_CLIENTES = 'CATEGORIAS_CLIENTES',
  IMPORTACION_OFERTAS = 'IMPORTACION_OFERTAS',
  DOCUMENTOS = 'DOCUMENTOS',
  CREATE_TITULO = 'CREATE_TITULO',
  TITULO = 'DETALLES_TITULO',
  TITULO_INTEGRACIONES = 'TITULO_INTEGRACIONES',
  TITULO_CANJES = 'TITULO_CANJES',
  TITULO_FLUJO_FONDOS = 'TITULO_FLUJO_FONDOS',
  TITULO_CALCULO_CORTE = 'TITULO_CALCULO_CORTE',
  TITULO_OFERTAS_POR_VALOR = 'TITULO_OFERTAS_POR_VALOR',
}

export const ColocacionRoutePaths = {
  index: 'detail',
  coColocadores: 'co-colocadores',
  agentes: 'agentes',
  categorias: 'categorias',
  documentos: 'documentos',

  colocacionesBoard: 'board',
  importacionOfertas: 'import-ofertas',

  titulos: 'titulos',
  titulosNew: 'titulos/new',
  tituloView: 'titulos/:tituloId',
  tituloIntegraciones: 'titulos/:tituloId/integraciones',
  tituloFlujoFondos: 'titulos/:tituloId/flujo-fondos',
  tituloCanjes: 'titulos/:tituloId/canjes',
  tituloCalculoCorte: 'calculo-corte/:tituloId',
  tituloOfertasPorValor: 'ofertas-por-valor/:tituloId',
}

const ColocacionSectionPaths = {
  [ColocacionSectionType.COLOCACION]: ColocacionRoutePaths.index,
  [ColocacionSectionType.TABLERO_COLOCACIONES]: ColocacionRoutePaths.colocacionesBoard,
  [ColocacionSectionType.IMPORTACION_OFERTAS]: ColocacionRoutePaths.importacionOfertas,
  [ColocacionSectionType.TITULOS]: ColocacionRoutePaths.titulos,
  [ColocacionSectionType.CREATE_TITULO]: ColocacionRoutePaths.titulosNew,

  [ColocacionSectionType.TITULO]: ColocacionRoutePaths.tituloView,
  [ColocacionSectionType.TITULO_INTEGRACIONES]: ColocacionRoutePaths.tituloIntegraciones,
  [ColocacionSectionType.TITULO_CANJES]: ColocacionRoutePaths.tituloCanjes,
  [ColocacionSectionType.TITULO_FLUJO_FONDOS]: ColocacionRoutePaths.tituloFlujoFondos,
  [ColocacionSectionType.TITULO_CALCULO_CORTE]: ColocacionRoutePaths.tituloCalculoCorte,
  [ColocacionSectionType.TITULO_OFERTAS_POR_VALOR]: ColocacionRoutePaths.tituloOfertasPorValor,

  [ColocacionSectionType.CO_COLOCADORES]: ColocacionRoutePaths.coColocadores,
  [ColocacionSectionType.AGENTES]: ColocacionRoutePaths.agentes,
  [ColocacionSectionType.CATEGORIAS_CLIENTES]: ColocacionRoutePaths.categorias,
  [ColocacionSectionType.DOCUMENTOS]: ColocacionRoutePaths.documentos,
}

export interface ColocacionSectionOptions {
  tituloId?: Identifier
  moveBack?: boolean
}

export interface ColocacionViewContext extends EditContext, ColocacionContextInfo {
  setSection: (ColocacionSectionType, options?: ColocacionSectionOptions) => void
  colocacionId: Identifier
  tituloId?: Identifier
  showConfirm: boolean
  setShowConfirm: (show: boolean) => void
  ensureColocacion: () => void
  createOfertaContext: OfertaContextInfo
  editOfertaContext: OfertaContextInfo
}

export interface ColocacionEditProps extends ColocacionViewContext {
  key?: string
}

const useColocacionEditContext = (isNew = false): ColocacionViewContext => {
  const routeParams = useParams()
  const navigate = useNavigate()

  const { colocacionId, tituloId } = routeParams

  const [editEnabled, setEditEnabled] = useState(isNew)
  const [showConfirm, setShowConfirm] = useState(false)

  const colocacionContextInfo = useColocacionContext()

  const setSection = useCallback(
    (section, options: ColocacionSectionOptions = {}) => {
      const { moveBack = false, tituloId: tituloIdOption } = options

      const path = ColocacionSectionPaths[section]

      const interpolatedPath = path.replace(':tituloId', tituloIdOption).replace('titulos/', '')

      const fullPath = moveBack ? `../${interpolatedPath}` : interpolatedPath

      setEditEnabled(isNew)

      navigate(fullPath, {
        state: { colocacionId, tituloId },
        relative: moveBack ? 'path' : 'route',
      })
    },
    [tituloId, colocacionId, navigate, isNew],
  )

  const refreshColocacion = useCallback(() => {
    if (!colocacionContextInfo.colocacion) {
      ColocacionesApi.getOne(colocacionId).then((colocacion) =>
        colocacionContextInfo.setColocacion(colocacion),
      )
    }
  }, [colocacionId, colocacionContextInfo.colocacion])

  const { userInfo } = useAuthContext()

  const readOnly = !isComponentAuthorized(
    BymaPermissions.COLOCACION_EDIT_PERMISSION,
    userInfo?.authorities,
  )

  return {
    setSection,
    colocacionId: colocacionId || 0,
    tituloId,
    editEnabled,
    setEditEnabled,
    readOnly,
    isNew,
    showConfirm,
    setShowConfirm,
    ensureColocacion: refreshColocacion,
    ...colocacionContextInfo,
  }
}

export { useColocacionEditContext }

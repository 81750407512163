import React from 'react'
import Badge from 'react-bootstrap/Badge'
import BymaOverlay from 'components/BymaOverlay/BymaOverlay'

import './BymaBadge.scss'

export type BymaBadgeTypes =
  | 'primary'
  | 'secondary'
  | 'danger'
  | 'warning'
  | 'info'
  | 'data'
  | 'data-cell'
  | 'grey'
  | 'light'
  | 'dark'
  | 'success'
  | 'pending'
  | 'active'
  | 'error'

interface BadgeProps {
  text: string
  type?: BymaBadgeTypes
  className?: string
  overlayText?: string
  variant?: 'Default' | 'Big'
}

const bymaBadgeClass = {
  Default: 'byma-badgeDefault',
  Big: 'byma-badgeBig',
}

const BymaBadge = (props: BadgeProps) => {
  const {
    text,
    type = 'success',
    overlayText = undefined,
    variant = 'Default',
    className = '',
  } = props

  if (text === undefined || text === null || text.length === 0) {
    return <></>
  }

  if (overlayText) {
    return (
      <BymaOverlay text={overlayText}>
        <Badge pill bg={type} className={`byma-badge ${bymaBadgeClass[variant]} ${className}`}>
          {text}
        </Badge>
      </BymaOverlay>
    )
  }

  return (
    <Badge pill bg={type} className={`byma-badge ${bymaBadgeClass[variant]} ${className}`}>
      {text}
    </Badge>
  )
}

export default BymaBadge

import React from 'react'
import { useNavigate } from 'react-router-dom'
import { BymaButton } from '../BymaButton'
import { ArrowBackIcon } from '../../BymaIcons'

export const BymaBackButton = () => {
  const navigate = useNavigate()

  return (
    <BymaButton
      type='button'
      id='back-form-btn'
      name='back-form-btn'
      label={'Volver'}
      iconLeft={ArrowBackIcon}
      onClick={() => navigate(-1)}
      style={{
        backgroundColor: 'transparent',
        border: '1px solid #626573',
      }}
    />
  )
}

import React, { useCallback } from 'react'

import { Col, Row } from 'react-bootstrap'

import { ListBase } from 'core'

import { BymaButton, BymaDataTable, AddIcon, LocalErrorAlert, BymaCard } from 'components'
import { TITULO_ENTITY_NAME, CanjeApi } from 'model'

import { ColocacionEditProps } from '../../../ColocacionViewContext'
import {
  EntityContextInfo,
  useEditButtonCell,
  useDeleteButtonCell,
  useEntityContext,
} from 'features/common/Entity'

import CanjeModal from './CanjeModal'
import { read } from 'fs'

const getCanjeColumns = (context: EntityContextInfo, readOnly) => {
  return [
    {
      field: 'tituloDenominacion',
      headerName: 'Titulo',
      textAlign: 'left',
      minWidth: 200,
    },
    {
      field: 'tituloALiquidar',
      headerName: 'Título a liquidar',
      textAlign: 'left',
      minWidth: 200,
    },

    {
      field: 'precio',
      headerName: 'Valor Teórico',
      textAlign: 'right',
    },

    {
      field: 'porcentaje',
      headerName: 'Porcentaje',
      textAlign: 'right',
    },

    {
      field: 'cantidadMinima',
      headerName: 'Cantidad Mínima',
      textAlign: 'right',
    },

    {
      field: 'laminaMinima',
      headerName: 'Lamina Mínima',
      textAlign: 'right',
    },

    {
      field: 'tipoRedondeo',
      headerName: 'Tipo redondeo',
      textAlign: 'left',
      maxWidth: 200,
    },

    {
      field: 'cotizacionUSD',
      headerName: 'Tipo de Cambio',
      textAlign: 'right',
    },

    useEditButtonCell({ context, minWidth: 80 }),
    {
      ...useDeleteButtonCell({ context, minWidth: 80 }),
      hide: readOnly,
    },
  ]
}

const CanjesFields = ({ listKey, queryKey, context, api, readOnly }) => {
  const dataProvider = useCallback((params) => api.getAllAsPage({ ...params }), [api])

  return (
    <>
      <Row sm={12} className='mb-3'>
        <Col sm={12}>
          <BymaDataTable
            key='titulo-canjes'
            columns={getCanjeColumns(context, readOnly)}
            sizeColumnsToFit={true}
            style={{ height: '200px', width: '100%' }}
            pagination={false}
            sortable={false}
            showLoadingOverlay={false}
            queryProps={{
              dataProvider,
              listKey,
              queryKey,
            }}
            listPaginationProps={{
              showLimits: false,
            }}
            suppressHorizontalScroll={true}
          />
        </Col>
      </Row>

      <Row>
        <Col style={{ display: 'flex', justifyContent: 'right' }}>
          <BymaButton
            id={'titulo-nuevo-canje'}
            label='Agregar Canje'
            name={'titulo-nuevo-canje'}
            iconLeft={AddIcon}
            iconStyles={{ width: 12 }}
            onClick={() => {
              context.setEntityId(undefined)
              context.setEditEnabled(true)
              context.setShowModal(true)
            }}
          />
        </Col>
      </Row>

      <Row className='mt-3'>
        <LocalErrorAlert />
      </Row>
    </>
  )
}

export const CanjesTable = (props: ColocacionEditProps) => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const api = new CanjeApi(props.tituloId!)

  const CANJES_LIST_CLASSIFIER = 'titulo.canjess'
  const LIST_KEY = [TITULO_ENTITY_NAME, CANJES_LIST_CLASSIFIER]
  const QUERY_KEY = [...LIST_KEY, { id: props.tituloId }]

  const readOnly = props.readOnly

  const context = useEntityContext(TITULO_ENTITY_NAME, 'Canje', QUERY_KEY, readOnly)

  return (
    <BymaCard titleBorder='none' footer={<></>}>
      <BymaCard
        titleBackgorundColor='var(--byma-primary-2)'
        style={{
          border: '1px solid var(--byma-primary-2)',
          backgroundColor: '#384256',
        }}
        title={'Canjes'}
      >
        <CanjesFields
          listKey={LIST_KEY}
          queryKey={QUERY_KEY}
          context={context}
          api={api}
          readOnly={readOnly}
        />
      </BymaCard>

      <CanjeModal context={context} api={api} formId='titulo-canje-form' />
    </BymaCard>
  )
}

const CanjesList = (props: ColocacionEditProps) => {
  return (
    <ListBase listKey='titulo.canjes'>
      <CanjesTable {...props} />
    </ListBase>
  )
}

export default CanjesList

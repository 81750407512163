import React from 'react'
import { EntityModal } from 'features/common/Entity'

import { Pais } from 'model/pais'
import { EntityModalProps } from 'features/common/Entity'
import { PaisForm } from './PaisForm'

const PaisSchemaProperties = (yup) =>
  yup.object().shape({
    codigo: yup.string().required(),
    denominacion: yup.string().required(),
    alfa2: yup.string().required(),
    alfa3: yup.string().required(),
  })

const PaisModal = (props: EntityModalProps<Pais>) => {
  const editEnabled = props.context.editEnabled

  return (
    <EntityModal
      {...props}
      focusField='denominacion'
      getSchema={PaisSchemaProperties}
      renderCreateForm={(props) =>
        PaisForm({ ...props, cardTitle: 'Nueva Nacionalidad', editEnabled: true })
      }
      renderEditForm={(props) =>
        PaisForm({
          ...props,
          cardTitle: editEnabled ? 'Editar Nacionalidad' : 'Detalle Nacionalidad',
          editEnabled,
        })
      }
    />
  )
}

export default PaisModal

import React from 'react'
import { Navigate } from 'react-router-dom'

import { BymaPageFrame, BymaSidebar, Loading } from 'components'

import { LoadingProvider, LocalErrorProvider } from 'core'

import { ColocacionMainSection } from './sections/ColocacionMain/ColocacionMainSection'
import { AgentesSection } from './sections/Autorizaciones/Agentes/AgentesSection'
import { CoColocadoresSection } from './sections/Autorizaciones/CoColocadores/CoColocadoresSection'
import { CategoriasClienteSection } from './sections/Autorizaciones/Clientes/ClientesSection'

import {
  ColocacionSidebarIconElement,
  TitulosSidebarIconElement,
  AutorizacionesSidebarIconElement,
  DocumentosSidebarIconElement,
  HomeSidebarIconElement,
  ImportOfertasSidebarIconElement,
} from './ColocacionSidebarIcons'

import {
  useColocacionEditContext,
  ColocacionRoutePaths,
  ColocacionSectionType,
} from './ColocacionViewContext'
import { TitulosSection } from './sections/TitulosSection'
import { DocumentosSection } from './sections/Documentos'
import { CreateTituloSection } from './sections/TituloCreate/CreateTituloSection'

import { Outlet, Route } from 'react-router-dom'
import { TituloRoutes } from './sections/TituloView'
import { ColocacionContextProvider } from './context'
import { ColocacionBoardRoutes, ColocacionesBoardSection } from './ColocacionBoard/ColocacionBoard'
import ImportOfertas from './sections/ImportOfertas/ImportOfertas'

/**
 * Componente para editar la Colocacion
 * Una colocacion es un "documento" con varias secciones que pueden editarse en forma indpependiente
 *
 * Ver:
 * @param props https://www.figma.com/file/mhoyqkY8Cz46W14hTBIgE2/Colocaciones?type=design&node-id=1571-272324&mode=design&t=DWHMxiPabwUPGCwo-0
 *
 *
 * @returns
 */
const ColocacionEditPanel = () => {
  return (
    <Loading>
      <ColocacionMainSection />
    </Loading>
  )
}

const ColocacionesBoardPanel = () => {
  return <ColocacionesBoardSection />
}

export const AgentesPanel = () => {
  return (
    <Loading>
      <AgentesSection />
    </Loading>
  )
}

const CoColocadoresPanel = () => {
  return (
    <Loading>
      <CoColocadoresSection />
    </Loading>
  )
}

const CategoriasPanel = () => {
  return (
    <Loading>
      <CategoriasClienteSection />
    </Loading>
  )
}

const DocumentosPanel = () => {
  return (
    <Loading>
      <DocumentosSection />
    </Loading>
  )
}

const TitulosPanel = () => {
  return (
    <Loading>
      <TitulosSection />
    </Loading>
  )
}

const TituloCreatePanel = () => {
  return <CreateTituloSection />
}

const LeftNavBar = ({ setSection }) => {
  return (
    <BymaSidebar
      key={'colocacion-edit-menu'}
      items={[
        {
          key: 'colocacion-board-link',
          label: 'Tablero de colocaciones',
          icon: <HomeSidebarIconElement />,
          onClick: () => {
            setSection(ColocacionSectionType.TABLERO_COLOCACIONES)
          },
        },
        {
          key: 'colocacion-link',
          label: 'Colocación',
          icon: <ColocacionSidebarIconElement />,
          onClick: () => {
            setSection(ColocacionSectionType.COLOCACION)
          },
        },
        {
          key: 'titulos-link',
          label: 'Titulos',
          icon: <TitulosSidebarIconElement />,
          onClick: () => {
            setSection(ColocacionSectionType.TITULOS)
          },
        },
        {
          key: 'autorizaciones-link',
          label: 'Autorizaciones',
          icon: <AutorizacionesSidebarIconElement />,
          subItems: [
            {
              key: 'co-colocadores-link',
              label: 'Co-colocadores',
              onClick: () => {
                setSection(ColocacionSectionType.CO_COLOCADORES)
              },
            },
            {
              key: 'agentes-link',
              label: 'Agentes',
              onClick: () => {
                setSection(ColocacionSectionType.AGENTES)
              },
            },
            {
              key: 'categoria-clientes-link',
              label: 'Categoria de clientes',
              onClick: () => {
                setSection(ColocacionSectionType.CATEGORIAS_CLIENTES)
              },
            },
          ],
        },
        {
          key: 'documentos-link',
          label: 'Documentos',
          icon: <DocumentosSidebarIconElement />,
          onClick: () => {
            setSection(ColocacionSectionType.DOCUMENTOS)
          },
        },
        {
          key: 'import-ofertas-link',
          label: 'Importar Ofertas',
          icon: <ImportOfertasSidebarIconElement />,
          onClick: () => {
            setSection(ColocacionSectionType.IMPORTACION_OFERTAS)
          },
        },
      ]}
    >
      <LoadingProvider>
        <LocalErrorProvider>
          <Outlet />
        </LocalErrorProvider>
      </LoadingProvider>
    </BymaSidebar>
  )
}

const EditColocacion = () => {
  const context = useColocacionEditContext()
  const { setSection } = context

  return (
    <ColocacionContextProvider>
      <BymaPageFrame
        bcHome={{ value: 'Dashboard', link: '/home' }}
        bcItems={[]}
        bcActive='Colocación'
        title='DETALLE de COLOCACION'
        showTitle={false}
        showToast={true}
      >
        <LeftNavBar setSection={setSection} />
      </BymaPageFrame>
    </ColocacionContextProvider>
  )
}

export const ColocacionRoutes = (
  <>
    <Route
      index
      element={<Navigate to={ColocacionRoutePaths.colocacionesBoard} replace={true} />}
    />
    <Route path={ColocacionRoutePaths.index} element={<ColocacionEditPanel />} />
    <Route path={ColocacionRoutePaths.agentes} element={<AgentesPanel />} />
    <Route path={ColocacionRoutePaths.colocacionesBoard} element={<ColocacionesBoardPanel />} />
    <Route path={ColocacionRoutePaths.importacionOfertas} element={<ImportOfertas />} />
    <Route path={ColocacionRoutePaths.categorias} element={<CategoriasPanel />} />
    <Route path={ColocacionRoutePaths.coColocadores} element={<CoColocadoresPanel />} />
    <Route path={ColocacionRoutePaths.documentos} element={<DocumentosPanel />} />
    <Route path={ColocacionRoutePaths.titulos} element={<TitulosPanel />} />
    <Route path={ColocacionRoutePaths.titulosNew} element={<TituloCreatePanel />} />

    {TituloRoutes}

    {ColocacionBoardRoutes}
  </>
)

export default () => <EditColocacion />

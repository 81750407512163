import { useState } from 'react'
import { BymaTableColumn } from './BymaTable'
import { useListContext } from 'core'

export interface BymaTableContext {
  columns: BymaTableColumn[]
  setColumns: (cols: BymaTableColumn[]) => void
  showColumn: (field: string) => void
  hideColumn: (field: string) => void
}

const applyColumnState = (columns: BymaTableColumn[], columnOrder: string[] | undefined) => {
  const newColumns: BymaTableColumn[] = []
  let changed = false
  columns.forEach((column) => {
    const hide = column.hide
    const show = columnOrder?.includes(column.field) || column.disabledHide
    if (hide && show) {
      changed = true
      newColumns.push({ ...column, hide: false })
    } else {
      newColumns.push(column)
    }
  })
  return changed ? newColumns : columns
}

const useBymaTableContext = (props: { columns: BymaTableColumn[] }): BymaTableContext => {
  const { columnOrder } = useListContext()

  //se inicializa con el orden guardado previamente
  const [columns, setColumns] = useState<BymaTableColumn[]>(
    applyColumnState(props.columns, columnOrder.columnOrder),
  )

  const showColumn = (source: string) => {
    const newColumns = columns.map((col: any) => ({
      ...col,
      hide: source === col.field ? false : col.hide,
    }))

    setColumns(newColumns)
  }

  const hideColumn = (source: string) => {
    console.log('Ocultando columna!!!! ' + source)
    const newColumns = columns.map((col: any) => ({
      ...col,
      hide: source === col.field ? true : col.hide,
    }))

    setColumns(newColumns)
  }

  return {
    columns,
    setColumns,
    showColumn,
    hideColumn,
  }
}

export { useBymaTableContext }

import { ResourceApi } from 'services/api'
import { Titulo } from 'model'

class TituloDashboardApi extends ResourceApi<Titulo> {
  constructor() {
    super('/api/colocaciones/titulos/dashboard')
  }
}

export default new TituloDashboardApi()

import { useContext } from 'react'

import { defaultLoadingApiContextInfo } from './LoadingContext'

import { LoadingApiContext, LoadingDataContext } from './LoadingContext'

const useLoadingProducer = (): LoadingApiContextInfo => {
  return useContext(LoadingApiContext) || defaultLoadingApiContextInfo
}

const useLoadingConsumer = (): LoadingDataContextInfo => {
  return useContext(LoadingDataContext)
}

export { useLoadingProducer, useLoadingConsumer }

import React, { useState } from 'react'
import { SortableList, SortableItem, SortableItemProps } from './react-sortable-list'

import styles from './BymaSortableList.module.scss'

interface BymaSortableItemProps extends SortableItemProps {
  title?: string
}

interface BymaSortableListProps {
  key: string
  items: SortableItemProps[]
  setItems?: React.Dispatch<React.SetStateAction<SortableItemProps[]>>
  disabled?: boolean
}

interface DragHandlerProps {
  title?: string
  fontSize?: number
  disabled?: boolean
}

const DragHandler =
  ({ title, fontSize, disabled }: DragHandlerProps) =>
  (props) => {
    const classes =
      styles.handlerContent +
      ' flex justify-center items-center h-8 w-8 ' +
      'rounded m-4 transition ease-in-out delay-150 hover:-translate-y-1 ' +
      'hover:scale-110 hover:bg-blue-500 hover:text-white duration-300'

    //console.log(`DEBUG DragHandler Rendering item handler ${JSON.stringify(props)}`)
    return (
      <div {...props} className={classes}>
        <div className={styles.dragTitle}>
          <span className={styles.titleSpan} style={{ fontSize }}>
            {title}
          </span>
        </div>
        {!disabled ? (
          <div className={styles.dragIcon}>
            <img src={'images/icons/drag.svg'} />
          </div>
        ) : null}
      </div>
    )
  }

//see https://docs.dndkit.com/api-documentation/draggable/usedraggable
//https://github.com/react-dnd/react-dnd/issues/3342
export const BymaSortableList = (props: BymaSortableListProps) => {
  const [items, setItems] = useState<BymaSortableItemProps[]>(props.items)

  //console.log('Rendering sortable list')
  //console.log(props)
  //console.log(items)

  return (
    <SortableList
      key={props.key}
      items={props.setItems ? props.items : items}
      setItems={props.setItems ? props.setItems : setItems}
      disabled={props.disabled}
    >
      {({ items }: { items: BymaSortableItemProps[] }) => (
        <>
          {items.map((item: BymaSortableItemProps) => (
            <SortableItem
              key={item.id}
              id={item.id}
              title={item.title}
              className={styles.itemContent}
              DragHandler={DragHandler({
                title: item.title,
                fontSize: item.fontSize,
                disabled: props.disabled,
              })}
            >
              {item.content}
            </SortableItem>
          ))}
        </>
      )}
    </SortableList>
  )
}

import React, { CSSProperties } from 'react'

import { Button } from 'react-bootstrap'

import styles from './BymaButton.module.scss'

import { getIconPath } from 'components'

export interface ButtonProps {
  key?: string
  id: string
  name: string
  label: string
  type?: 'submit' | 'button' | 'reset' | undefined
  className?: string
  groupClassName?: string
  onClick?: any
  iconLeft?: string
  iconRight?: string
  disabled?: boolean
  style?: CSSProperties | undefined
  variant?: 'Default' | 'Large'
  href?: string
  form?: string
  iconStyles?: CSSProperties
}

export const BymaButton = (props: ButtonProps) => {
  const {
    id,
    key = id,
    label,
    onClick,
    iconLeft,
    iconRight,
    iconStyles,
    className = '',
    groupClassName = '',
    type = 'submit',
    disabled = false,
    style = {},
    variant = 'Default',
    href = undefined,
    form,
  } = props

  //TODO usar variantes (primary, secondary) para los colores

  const backgroundColor = 'var(--byma-primary-2)'
  const border = '1px solid var(--byma-primary-2)'
  const disabledColor = '#626573'

  const buttonClasses =
    styles.bymaButton + ' ' + `${styles[`bymaButton${variant}`]}` + ' ' + className

  let hrefProps = {}
  if (href !== undefined) {
    hrefProps = {
      href: href,
      target: '_blank',
    }
  }
  return (
    <div className={styles.buttonGroup + ' ' + groupClassName}>
      <Button
        type={type}
        id={id}
        key={key}
        form={form}
        style={{
          backgroundColor: disabled ? disabledColor : backgroundColor,
          border: disabled ? disabledColor : border,
          gap: label && label.length > 0 ? '10px' : undefined,
          color: disabled ? 'var(--secondary-gray-normal)' : 'inherit',
          ...style,
        }}
        className={buttonClasses}
        onClick={onClick}
        disabled={disabled}
        {...hrefProps}
      >
        {iconLeft && (
          <div className='d-flex align-items-center justify-content-center'>
            <img
              className={styles.buttonLeftAddon}
              style={iconStyles}
              src={getIconPath(iconLeft)}
            />
          </div>
        )}
        {label}
        {iconRight && (
          <div className='d-flex align-items-center justify-content-center'>
            <img
              className={styles.buttonLeftAddon}
              style={iconStyles}
              src={getIconPath(iconRight)}
            />
          </div>
        )}
      </Button>
    </div>
  )
}

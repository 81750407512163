export interface HelpItem extends BaseEntity {
  codigo: string
  configUi: HelpItemData
  link?: string
}
export interface HelpItemData {
  text: string
  buttonText?: string
  buttonIcon?: string
  link?: string
  contactosAtencion?: string[]
  contactosMercado?: string[]
}

export enum SECCION_AYUDA {
  MANUAL = 'manual',
  TUTORIAL = 'tutorial',
  COLOCACIONES = 'colocaciones',
  ARANCELES = 'aranceles',
  NORMATIVA = 'normativa',
  CONTACTO = 'contacto',
}

export enum METADATA_TYPE {
  INFO = 'info',
  CONTACT = 'contacto',
}

export const SECCION_AYUDA_CONFIG = {
  [SECCION_AYUDA.MANUAL]: {
    title: 'Manual de Usuario',
    subTitle: 'Descripción',
    type: METADATA_TYPE.INFO,
    order: 1,
    buttonIcon: 'send.svg',
    descriptionClassname: 'col-7',
    buttonClassname: 'col-5',
  },
  [SECCION_AYUDA.TUTORIAL]: {
    title: 'Video Tutorial',
    subTitle: 'Descripción',
    type: METADATA_TYPE.INFO,
    order: 2,
    buttonIcon: 'send.svg',
    descriptionClassname: 'col-7',
    buttonClassname: 'col-5',
  },
  [SECCION_AYUDA.COLOCACIONES]: {
    title: 'Colocaciones',
    subTitle: 'Descripción',
    type: METADATA_TYPE.INFO,
    order: 3,
    buttonIcon: 'send.svg',
    descriptionClassname: 'col-7',
    buttonClassname: 'col-5',
  },
  [SECCION_AYUDA.ARANCELES]: {
    title: 'Aranceles',
    subTitle: 'Descripción',
    type: METADATA_TYPE.INFO,
    order: 4,
    buttonIcon: 'send.svg',
    descriptionClassname: 'col-7',
    buttonClassname: 'col-5',
  },
  [SECCION_AYUDA.NORMATIVA]: {
    title: 'Normativa Legal',
    subTitle: 'Descripción',
    type: METADATA_TYPE.INFO,
    order: 5,
    buttonIcon: 'send.svg',
    descriptionClassname: 'col-7',
    buttonClassname: 'col-5',
  },
  [SECCION_AYUDA.CONTACTO]: {
    title: 'Contacto',
    subTitle: '',
    type: METADATA_TYPE.CONTACT,
    order: 6,
    contacts: [],
    buttonIcon: 'send.svg',
    text:
      'Consultas y soporte sobre la plataforma BYMA Primarias:\n' +
      'Centro de atención BYMA: \n' +
      'Mercado Primario BYMA: \n',
    descriptionClassname: 'col-7',
    buttonClassname: 'col-5',
  },
}

import React from 'react'

import {
  BymaButton,
  BymaCard,
  BymaDataTable,
  BymaExportButton,
  BymaPageFrame,
  BymaTextInputFilter,
  BymaSelect,
} from 'components'

import { ListBase, useListContext, useNotificationProducer, LoadingProvider } from 'core'

import { FiltersHeader } from 'features/common/Filters'

import { useEditButtonCell, useDeleteButtonCell } from 'features/common/Entity'

import { EntityContextInfo, useEntityContext } from 'features/common/Entity'

import { INVERSOR_ENTITY_NAME, InversorApi, OrdenInversorOptions } from 'model/inversor'

import InversorModal from './InversorModal'
import { SORT_FILTER_NAME } from 'services/api'

const getInversorColumns = (context: EntityContextInfo) => {
  return [
    {
      field: 'numero',
      headerName: 'Código FCI/SSN',
      textAlign: 'left',
      minWidth: 150,
    },
    {
      field: 'denominacion',
      headerName: 'Denominación FCI/SSN',
      textAlign: 'left',
      minWidth: 350,
    },
    {
      field: 'tipoDocumento',
      headerName: 'Tipo de Registro',
      textAlign: 'left',
      maxWidth: 150,
    },
    {
      field: 'grupoEconomicoDenominacion',
      headerName: 'Grupo Económico',
      textAlign: 'left',
      minWidth: 350,
    },

    useEditButtonCell({ context, minWidth: 40 }),
    useDeleteButtonCell({ context, minWidth: 40 }),
  ]
}

/**
 *
 * @returns TODO crear componente SbaList
 */
const InversorTable = () => {
  const { addNotification } = useNotificationProducer()

  const context = useEntityContext(INVERSOR_ENTITY_NAME, 'FCI/SSN')

  const { filterValues } = useListContext()

  const handleAddInversor = () => {
    context.setEntityId(undefined)
    context.setShowModal(true)
    context.setEditEnabled(true)
  }

  const dataProvider = (params) => InversorApi.getPaginatedList(params)

  const filters = [
    <BymaTextInputFilter
      name='numero'
      key='numero'
      label='Código FCI/SSN'
      type='text'
      alwaysOn={true}
      style={{ width: '200px' }}
      variant='Small'
    />,

    <BymaTextInputFilter
      name='denominacion'
      key='denominacion'
      label='Denominación FCI/SSN'
      type='text'
      alwaysOn={true}
      style={{ width: '300px' }}
      variant='Small'
    />,

    <BymaSelect
      name={SORT_FILTER_NAME}
      key={SORT_FILTER_NAME}
      label='Ordenar'
      style={{ minWidth: '250px' }}
      options={OrdenInversorOptions}
      alwaysOn={true}
      variant='Small'
      hideLabel={true}
    />,
  ]

  return (
    <BymaPageFrame
      bcHome={{ value: 'Administrar', link: '/home' }}
      bcItems={[]}
      bcActive='ABM FCI/SSN'
      showTitle={false}
      showToast={true}
    >
      <BymaCard key={'inversores-card'} header={{ title: 'FCI/SSN' }}>
        <div style={{ paddingBottom: '1%', paddingTop: '2%' }}>
          <FiltersHeader
            filters={filters}
            showFiltersMenu={false}
            extra={[
              <BymaButton
                type='button'
                id='inversores-add-btn'
                key='inversores-add-btn'
                name='inversores-add-btn'
                label={'+ Nuevo FCI/SSN'}
                onClick={handleAddInversor}
              />,
            ]}
            exportButton={
              <BymaExportButton
                id='inversores-export-button'
                name='inversores-export-button'
                serverDownload={() => InversorApi.export({ filter: filterValues })}
                getFileName={() => 'inversores.csv'}
                postDownloading={() =>
                  addNotification({
                    message: 'Se descargo archivo de FCI/SSN',
                    type: 'info',
                  })
                }
              />
            }
          />
        </div>

        <BymaDataTable
          key='inversores'
          columns={getInversorColumns(context)}
          sizeColumnsToFit={true}
          style={{ height: '310px', width: '100%' }}
          queryProps={{
            dataProvider: dataProvider,
            listKey: context.listQueryKey,
          }}
          listPaginationProps={{
            showLimits: true,
          }}
          //suppressHorizontalScroll={true}
        />
      </BymaCard>

      <InversorModal
        context={context}
        api={InversorApi}
        navigateTo='/inversores'
        formId='colocador-form'
      />
    </BymaPageFrame>
  )
}

const GrupoEconomicoList = () => {
  return (
    <LoadingProvider>
      <ListBase listKey='inversores.hist'>
        <InversorTable />
      </ListBase>
    </LoadingProvider>
  )
}

export default GrupoEconomicoList

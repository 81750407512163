import React, { CSSProperties } from 'react'

import { BymaButton } from 'components'
import { DownloadIcon } from 'components/BymaIcons'

import { useDownloadFile, DownloadFileProps, useLoadingProducer } from 'core'

import styles from './BymaExportButton.module.scss'

export type ExportButtonProps = Omit<Partial<DownloadFileProps>, 'serverDownload'> & {
  id: string
  name: string
  columns?: any[]
  label?: string
  icon?: string
  serverDownload: () => Promise<Blob>
  getFileName: () => string
  exportBtnWrapperStyles?: CSSProperties
}

export const BymaExportButton = ({
  id,
  label = '',
  icon = DownloadIcon,
  serverDownload,
  getFileName,
  onError = (error) => alert(`Error en Export ${error}`),
  preDownloading = () => {},
  postDownloading = () => {
    alert('Export Finalizado')
  },
  exportBtnWrapperStyles,
}: ExportButtonProps) => {
  const loadingProducer = useLoadingProducer()

  const preDownloadingHandler = () => {
    loadingProducer.addTask({ message: 'Descargando Archivo', id })
    preDownloading()
  }

  const postDownloadingHandler = () => {
    loadingProducer.removeTask(id)
    postDownloading()
  }

  const errorHandler = (error) => {
    loadingProducer.removeTask(id)
    onError(error)
  }

  const {
    download,
    ref: exportLinkRef,
    url: exportLinkUrl,
    name: exportFileName,
    isDownloading,
  } = useDownloadFile({
    serverDownload: serverDownload,
    getFileName: getFileName,
    onError: errorHandler,
    preDownloading: preDownloadingHandler,
    postDownloading: postDownloadingHandler,
  })

  return (
    <div style={exportBtnWrapperStyles}>
      <a href={exportLinkUrl} download={exportFileName} className='hidden' ref={exportLinkRef} />
      <BymaButton
        type='button'
        id={id}
        name={id}
        label={label}
        onClick={download}
        //groupClassName={'export-group-button'}
        groupClassName={styles.exportGroupButton}
        iconLeft={icon}
        disabled={isDownloading}
        style={{
          backgroundColor: '#626573',
          border: '1px solid #626573',
          height: '33px',
        }}
      />
    </div>
  )
}

import React, { useCallback, useEffect, useMemo } from 'react'

import {
  BymaDataTable,
  BymaExportButton,
  BymaSelect,
  BymaCheckbox,
  BymaTextInputFilter,
  BymaCard,
  useSelectSingleFilterProps,
  BymaTableColumn,
  BymaButton,
  AddIcon,
  AdjudicacionIcon,
  EstadoButtonIcon,
  UploadIcon,
  Loading,
} from 'components'

import {
  ListBase,
  useNotificationProducer,
  useListContext,
  LoadingProvider,
  useFetchDetailQuery,
} from 'core'

import {
  OFERTA_ENTITY_NAME,
  ESTADO_OFERTA_DETAIL_INFOS,
  OfertasApi,
  OrdenOfertasLibroColocacion,
  EstadoOfertaGroupOptions,
  EstadoOfertaDetailInfo,
  EstadoOfertaDetail,
  ofertaCapCellFormatter,
  tramoFormatter,
  tipoValorLicitacionFormatter,
  ofertaUltimaModificacionFullFormatter,
  ColocacionesApi,
  OfertaCreationInfo,
  ofertaCantidadFormatter,
  ofertaValorFormatter,
} from 'model'

import { BymaTableBadgeRow, BymaNavigateButton, VisibilityIcon } from 'components'

import { FiltersHeader } from '../../common/Filters'
import TableColumnButton from 'components/BymaTable/TableColumnButton'
import { useBymaTableContext } from 'components/BymaTable/BymaTableContext'
import { ColocacionSectionType, ColocacionViewContext } from '../ColocacionViewContext'
import {
  CreateOfertaExtendidaModal,
  EditOfertaExtendidaModal,
} from 'features/common/Oferta/OfertaExtendidaForm'

import styles from './ColocacionBoard.module.scss'
import { OfertaContextInfo } from 'features/common/Oferta'

const defaultCellWidth = 250

const useFetchOfertaDetail = (context: OfertaContextInfo) => {
  const queryFn = useFetchDetailQuery<OfertaCreationInfo>({
    dataProvider: (id) => OfertasApi.getOne(id),

    onSuccess: (data) => {
      context.setOferta(data)
      context.setOfertaId(data.id)
      context.setEditEnabled(false)

      setTimeout(() => context.setShowOfertaExtendida(true), 100)
    },
  })

  return queryFn
}

const columnDefaults = {
  textAlign: 'left',
  unSortIcon: true,
  wrapHeaderText: true,
  flex: 1,
}

const getLibroOfertaColumns = (
  context: ColocacionViewContext,
  queryDetailFn: (id) => Promise<OfertaCreationInfo>,
): BymaTableColumn[] => {
  return [
    {
      ...columnDefaults,
      field: 'id',
      headerName: 'Nº Oferta',
      disabledHide: true,
    },
    {
      ...columnDefaults,
      field: 'tituloDenominacion',
      headerName: 'Denominación Título',
      minWidth: 120,
      disabledHide: true,
      cellStyle: { textWrap: 'wrap' },
    },
    {
      ...columnDefaults,
      field: 'agenteIdOrganizacion',
      headerName: 'Agente',
      hide: true,
    },
    {
      ...columnDefaults,
      field: 'deTerceros',
      headerName: 'De terceros',
      hide: true,
    },

    {
      ...columnDefaults,
      field: 'tipoValor',
      headerName: 'Licita',
      valueFormatter: (params) => tipoValorLicitacionFormatter(params?.data?.tipoValor),
      hide: true,
    },

    {
      ...columnDefaults,
      field: 'tipoOferta',
      valueFormatter: (params) => tramoFormatter(params?.data?.tipoOferta),
      headerName: 'Tramo',
      hide: true,
    },

    {
      ...columnDefaults,
      field: 'valor',
      headerName: 'Precio Ofertado',
      disabledHide: true,
      valueFormatter: (params) => ofertaValorFormatter(params?.data?.valor),
      //wrapHeaderText: false,
    },
    {
      ...columnDefaults,
      field: 'valorAdjudicado',
      headerName: 'Precio Adjudicado',
      hide: true,
      valueFormatter: (params) => ofertaValorFormatter(params?.data?.cantidadvalorAdjudicado),
      //wrapHeaderText: false,
    },
    {
      ...columnDefaults,
      field: 'cantidad',
      headerName: 'Cantidad Ofertada',
      disabledHide: true,
      valueFormatter: (params) => ofertaCantidadFormatter(params?.data?.cantidad),
      //wrapHeaderText: false,
    },
    {
      ...columnDefaults,
      field: 'cantidadAdjudicada',
      headerName: 'Cantidad Adjudicada',
      hide: true,
      valueFormatter: (params) => ofertaCantidadFormatter(params?.data?.cantidadAdjudicada),
      //wrapHeaderText: false,
    },

    {
      ...columnDefaults,
      field: 'monedaEmision',
      headerName: 'Moneda',
      hide: true,
    },

    {
      ...columnDefaults,
      field: 'integracionTituloDenominacion',
      headerName: 'Integracion',
      disabledHide: true,
    },
    {
      ...columnDefaults,
      field: 'montoALiquidar',
      headerName: 'Monto a liquidar',
      valueFormatter: (params) => ofertaCantidadFormatter(params?.data?.montoALiquidar),
      hide: true,
    },
    {
      ...columnDefaults,
      field: 'duenioPorcentajeInversion',
      headerName: 'Dueño CAP',
      hide: true,
    },
    {
      ...columnDefaults,
      field: 'porcentajeInversion',
      headerName: 'CAP',
      textAlign: 'center',
      valueFormatter: (params) => ofertaCapCellFormatter(params?.data),
      hide: true,
    },
    {
      ...columnDefaults,
      field: 'numeroCliente',
      headerName: 'Cliente Comitente',
      disabledHide: true,
    },
    {
      ...columnDefaults,
      field: 'cuit',
      headerName: 'CUIT',
      disabledHide: true,
    },
    {
      ...columnDefaults,
      field: 'timestampModificacion',
      headerName: 'Últ. mod.',
      textAlign: 'center',
      minWidth: 110,
      valueFormatter: (params) => ofertaUltimaModificacionFullFormatter(params?.data),
      disabledHide: true,
    },

    {
      ...columnDefaults,
      headerName: 'Estado',
      field: 'estadoDetail',
      minWidth: 110,
      textAlign: 'center',
      hide: true,
      cellRenderer: BymaTableBadgeRow,
      cellRendererParams: (params) => {
        const estado = params?.data?.estadoDetail || EstadoOfertaDetail.VIGENTE
        const estadoOfertaDetailInfo: EstadoOfertaDetailInfo = ESTADO_OFERTA_DETAIL_INFOS[estado]

        return estadoOfertaDetailInfo
          ? {
            text: estadoOfertaDetailInfo.shortText || estadoOfertaDetailInfo.text,
            overlayText: estadoOfertaDetailInfo.text,
            type: estadoOfertaDetailInfo.badgeType,
          }
          : {}
      },
    },

    //Todo falta: esto
    //Circulo (rojo, verde, ama) Estado de la Oferta en Siopel No obligatorio
    // Se dejo consulta: https://docs.google.com/document/d/1fWd4DdG4OXDhlqveMyAZ4_BG3989FsBt5tuoB6u0ZOI/edit?disco=AAABCUMRGW4

    {
      field: 'ver-editar-action',
      headerName: 'Ver y Editar',
      cellClass: 'action-button',
      cellRenderer: BymaNavigateButton,
      cellRendererParams: (params) => {
        return {
          label: '',
          icon: VisibilityIcon,
          onClick: () => queryDetailFn(params?.data?.id),
        }
      },
      width: defaultCellWidth,
      sortable: false,
      disabledHide: true,
      flex: 1,
    },
  ]
}

const OFERTA_LIST_CLASSIFIER = 'colocacionBoard.libroOfertas'
const LIST_KEY = [OFERTA_ENTITY_NAME, OFERTA_LIST_CLASSIFIER]

const LibroOfertasColocacion = (props: {
  key?: number
  tituloOptions: any
  context: ColocacionViewContext
}) => {
  const { addNotification } = useNotificationProducer()
  const { tituloOptions, context, key } = props

  const selectSingleFilterProps = useSelectSingleFilterProps()

  const ofertaDetailFn = useFetchOfertaDetail(context.editOfertaContext)

  const unsoportedFields = context.colocacion?.ofertaUnsupportedFields
    ? context.colocacion.ofertaUnsupportedFields
    : []

  const columns = useMemo(
    () =>
      getLibroOfertaColumns(context, ofertaDetailFn).filter(
        (col) => !unsoportedFields.includes(col.field),
      ),
    [unsoportedFields],
  )

  const tableContext = useBymaTableContext({ columns })

  //Filtros
  const filters = [
    <BymaSelect
      {...selectSingleFilterProps}
      name='tituloId'
      key='tituloId'
      label='Denominación Titulo'
      style={{ minWidth: '181px' }}
      options={tituloOptions}
      alwaysOn={true}
      isClearable={false}
      placeholder='Seleccione un Titulo'
    />,
    <BymaSelect
      {...selectSingleFilterProps}
      name='estadoGroup'
      key='estadoGroup'
      label='Estado'
      style={{ minWidth: '181px' }}
      options={EstadoOfertaGroupOptions}
      alwaysOn={true}
      placeholder='Seleccione un Estado'
    />,
    <BymaTextInputFilter
      name='searchTerm'
      key='searchTerm'
      label='Buscar'
      style={{ minWidth: 190 }}
      alwaysOn={true}
      variant='Small'
    />,
    <BymaCheckbox
      key='ordenOfertas'
      type='checkbox'
      id='ordenOfertas'
      name='ordenOfertas'
      label='Ordenar por Mejor Oferta'
      labelStyle={{ display: 'none' }}
      hideLabel={true}
      multicheck={false}
      alwaysOn={true}
      variant='Small'
      options={[
        { value: OrdenOfertasLibroColocacion.MEJOR_VALOR, label: 'Ordenar por Mejor Oferta' },
      ]}
      style={{ width: window.innerWidth * 0.5 }}
    />,
  ]

  const { filterValues, setFilters, displayedFilters } = useListContext()

  const isValidTituloId = useCallback(
    (tituloId) => tituloOptions.find((o) => o.value == tituloId),
    [tituloOptions],
  )

  //Inicializamos los filtros con cada nueva colocacion
  //Vemos que se invoca varias veces con el mismo valor de context.colocacionId
  //si queremos resetear solo cuando cambie la colocacion deberiamos revisar
  useEffect(() => {
    //console.log(`DEBUG LibroOfertasColocacion seteandoFiltros colocacionId=${context.colocacionId}`)
    const tituloId = filterValues['tituloId']
    if (!tituloId || !isValidTituloId(tituloId)) {
      const newFilters = {
        tituloId: tituloOptions ? tituloOptions[0]?.value : undefined,
      }
      setFilters({ ...filterValues, ...newFilters }, displayedFilters)
    }
  }, [context.colocacionId])

  //si se explicita un orden 'custom' se deshabilita ordenamiento por columnas
  const sortableTable = !filterValues['ordenOfertas']

  //callback para creacion de nueva oferta
  const onClickNuevaOferta = useCallback(() => {
    if (context.colocacion?.titulos && context.colocacion?.titulos.length == 1) {
      context.createOfertaContext.setCurrentTitulo(context.colocacion?.titulos[0])
    } else {
      context.createOfertaContext.setCurrentTitulo(undefined)
    }
    context.createOfertaContext.setShowOfertaExtendida(true)
  }, [context, context.colocacion])

  const CardExtraButtons = () => {
    return (
      <div className={styles.cardButtonsList}>
        <BymaButton
          type='button'
          id={'libro-ofertas-adjudicacion-manual'}
          name={'libro-ofertas-adjudicacion-manual'}
          label={'Adjudicación manual'}
          onClick={() => { }}
          iconLeft={AdjudicacionIcon}
          style={{
            backgroundColor: 'transparent',
            border: '1px solid #FFFFFF',
            height: '33px',
          }}
        />
        <BymaButton
          type='button'
          id={'libro-ofertas-import-estado'}
          name={'libro-ofertas-import-estado'}
          label={'Importar estado'}
          onClick={() => { }}
          iconLeft={EstadoButtonIcon}
          style={{
            backgroundColor: '#626573',
            border: '1px solid #626573',
            height: '33px',
          }}
        />
        <BymaButton
          type='button'
          id={'libro-ofertas-import-ofertas'}
          name={'libro-ofertas-import-ofertas'}
          label={'Importar ofertas'}
          onClick={
            () => {
              context.setSection(ColocacionSectionType.IMPORTACION_OFERTAS, {moveBack: true})
            }
          }
          iconLeft={UploadIcon}
          style={{
            backgroundColor: '#626573',
            border: '1px solid #626573',
            height: '33px',
          }}
        />
        <BymaButton
          type='button'
          id={'libro-ofertas-nueva-oferta'}
          name={'libro-ofertas-nueva-oferta'}
          label={'Nueva oferta'}
          onClick={onClickNuevaOferta}
          iconLeft={AddIcon}
          iconStyles={{ width: 12 }}
        />
        <BymaExportButton
          id='ofertas-colocacion-export-button'
          name='ofertas-colocacion-export-button'
          serverDownload={() =>
            ColocacionesApi.exportOfertas(context.colocacionId, { filter: filterValues })
          }
          getFileName={() => `ofertas-colocacion-${context.colocacionId}.csv`}
          postDownloading={() =>
            addNotification({
              message: 'Se descargo archivo de Ofertas',
              type: 'info',
            })
          }
        />
      </div>
    )
  }

  const tableDataProvider = useCallback(
    (params) => {
      //HACK:!!!
      //LisContext cachea valores seleccionados previamente en otra colocacion
      //Eso hace que se dispare la consulta con un tituloId de otra coloacion (que no va a traer datos generando una consulta de mas)
      //Como workaround si detectamos esa situacion generamos una respuesta vacia
      //
      const tituloId = params.filter ? params.filter['tituloId'] : undefined
      const validTituloId = tituloId === undefined || isValidTituloId(tituloId)

      //console.log(`DEBUG LibroOfertasColocacion validTituloId=${validTituloId} colocacionId=${context.colocacionId} ${context.colocacion?.id} params=${JSON.stringify(params)}`)

      return validTituloId
        ? ColocacionesApi.getOfertas(context.colocacionId, params)
        : Promise.resolve({
          content: [],
          pageSize: 0,
          total: 0,
          page: 0,
          ignore: true,
        })
    },
    [context.colocacionId, tituloOptions],
  )

  return (
    <BymaCard
      key={'ofertas-colocacion-card-' + key}
      className={styles.libroOfertasColocacion}
      header={{
        title: 'Libro de ofertas',
        extras: <CardExtraButtons/>,
      }}
    >
      <div style={{ paddingBottom: '20px', paddingTop: '20px' }}>
        <FiltersHeader
          filters={filters}
          showFiltersMenu={false}
          extra={[<TableColumnButton key='ofertas-colocacion-columns' context={tableContext} />]}
          filtersStyle={{ alignItems: 'flex-start' }}
          extraStyles={{ marginTop: 22.4 }}
        />
      </div>

      <BymaDataTable
        key={'ofertas-colocacion-' + key}
        sizeColumnsToFit={true}
        style={{ height: '310px', width: '100%' }}
        pagination={false}
        sortable={sortableTable}
        queryProps={{
          dataProvider: tableDataProvider,
          listKey: [...LIST_KEY, context.colocacionId.toString()],
        }}
        listPaginationProps={{
          showLimits: true,
        }}
        suppressHorizontalScroll={true}
        context={tableContext}
        columns={columns}
        showLoadingOverlay={false}
      />

      <CreateOfertaExtendidaModal context={context.createOfertaContext} />
      <EditOfertaExtendidaModal context={context.editOfertaContext} />
    </BymaCard>
  )
}

export default (context: ColocacionViewContext) => {
  const tituloOptions = useMemo(() => {
    console.log(`DEBUG LibroOfertasColocacion calcula tituloOptions ${context.colocacionId}`)

    return (
      context.colocacion?.titulos?.map((t) => ({
        value: t.id,
        label: t.denominacion,
      })) || []
    )
  }, [context.colocacion])

  /*
  console.log(
    `DEBUG LibroOfertasColocacion 
      colocacion_id=${context.colocacionId}
      colocacion_id=${context.colocacion?.id}
      tituloOptions=${JSON.stringify(tituloOptions)} 
      defaultValues=${JSON.stringify(filterDefaultValues)}`
  )
  */

  return context.colocacion?.id != context.colocacionId ? null : (
    <LoadingProvider>
      <ListBase listKey={LIST_KEY.join('.')} perPage={50}>
        <Loading>
          <LibroOfertasColocacion tituloOptions={tituloOptions} context={context} />
        </Loading>
      </ListBase>
    </LoadingProvider>
  )
}

import React, { CSSProperties, useCallback, useRef, useState } from 'react'
import { useFormContext, Controller } from 'react-hook-form'
import { HelperText, ErrorMessage } from 'components'
import BymaTooltip from 'components/BymaOverlay/BymaTooltip'
import DatePicker from 'react-datepicker'
import BymaDatePickerCustomHeader from './BymaDatePickerCustomHeader'
import { isEmpty } from 'utils/string'

import i18n from 'i18n'
import es from 'date-fns/locale/es'

import 'react-datepicker/dist/react-datepicker.css'

import { ReactComponent as DatePickerCalendarIcon } from 'assets/img/calendar.svg'
import { TimePicker, renderTimeViewClock } from '@mui/x-date-pickers'

import './BymaDatePicker.scss'
import styles from './BymaDatePicker.module.scss'
import clsx from 'clsx'

export interface DatePickerProps extends FieldProps {
  onChange?: (selected: any) => void
  valueMapper?: (option: SelectOption | null) => any
  style?: CSSProperties | undefined
  defaultValue?: unknown
  helperText?: string
  variant?: 'Default' | 'Small'
  showTimeSelect?: boolean
  dateFormat?: string
  placeholder?: string
  hourPicker?: boolean
  clearBtn?: boolean
  readOnly?: boolean
}

/**
 * Componente basado en https://reactdatepicker.com/
 *
 * Ver: https://github.com/Hacker0x01/react-datepicker/blob/main/docs/datepicker.md
 *
 * @param props
 * @returns
 */

export const BymaDatePicker = (props: DatePickerProps) => {
  const {
    name,
    id = name,
    label,
    onChange: customOnChange = () => {},
    className = '',
    style = {},
    helperText = undefined,
    required = false,
    toolTipText = undefined,
    variant = 'Default',
    showTimeSelect = false,
    dateFormat = 'dd/MM/yyyy',
    placeholder = i18n.t('text.datePickerPlaceHolderDefault'),
    hourPicker,
    clearBtn = true,
    readOnly,
  } = props

  //const [open, setOpen] = useState(false)
  const [component, setComponent] = useState<any>()

  const width = 'inherit'
  const height = 'inherit'

  const { control } = useFormContext()

  const datePickerId = 'byma-date-picker-' + name
  const wrapperId = 'byma-date-picker-wrapper-ref-' + name
  const wrapperRef = useRef(null)

  /*
  const [calendarIsOpen, setCalendarIsOpen] = useState<boolean>(false)

  function useOutsideAlerter(ref: any) {
    function handleClickInside(event: any) {
      console.log('handleClickInside!!!!!!!!!!!')
      console.log(event.target)
      console.log(ref.current)

      const calendarHeaderClick = ['prev-year-button',
        'prev-month-button',
        'next-year-button',
        'next-month-button'].includes(event.target?.id)

      const isTimeSelection = calendarIsOpen
        && showTimeSelect
        && event.target?.tagName !== 'LI'

      if (ref.current !== undefined
        && ref.current.contains(event.target)
        && !calendarHeaderClick
        && !isTimeSelection) {
        console.log('setenado calendar open to: ' + (!calendarIsOpen))
        setCalendarIsOpen(!calendarIsOpen)
      }
    }

    useEffect(() => {
      // Bind the event listener
      //console.log('useEffect handleClickOutside!!!!!!!!!!!')
      document.addEventListener('click', handleClickInside)
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener('click', handleClickInside)
      }
    })
  }

  useOutsideAlerter(wrapperRef)
*/

  const onIconClick = useCallback(() => {
    if (component && component.input) component.input.focus()
  }, [component])

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState }) => {
        const value = !isEmpty(props.value)
          ? props.value
          : !isEmpty(field.value)
          ? field.value
          : null

        const errorMessage = fieldState.error?.message

        //console.log(`DEBUG BymaDatePicker render name=${name} value=${value}`)

        return (
          <span
            className={styles.datePickerFormGroup + ' d-inline-block'}
            data-toggle='popover'
            data-trigger='focus'
            data-mdb-perfect-scrollbar='true'
            style={{ width: '100%' }}
          >
            <div
              className={styles[`bymaDatePicker${variant}`] + ' ' + className}
              style={{
                width: width,
                height: height,
                ...style,
              }}
            >
              <div className='d-flex'>
                <label className={styles.fieldLabel} htmlFor={id}>
                  {label ? label + (required ? ' *' : '') : ' '}
                </label>
                {toolTipText && <BymaTooltip text={toolTipText} />}
              </div>

              <div ref={wrapperRef} id={wrapperId} style={{ position: 'relative' }}>
                {hourPicker ? (
                  <TimePicker
                    onChange={(date, event) => {
                      field.onChange(date)
                      customOnChange(date)
                    }}
                    readOnly={readOnly}
                    value={value}
                    sx={{
                      width: '100%',
                      '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: readOnly ? '#6c757d' : '#ced4da',
                      },
                      '& .MuiOutlinedInput-root': {
                        '&:hover > fieldset': { borderColor: readOnly ? '#6c757d' : '#ced4da' },
                        height: '48px',
                        borderRadius: '20px',
                        fontSize: 14,
                        color: '#ffffff',
                        maxHeight: 40,
                      },
                    }}
                    slotProps={{
                      layout: {
                        className: styles.clockLayout,
                      },

                      toolbar: {
                        className: styles.clockToolbar,
                      },
                      actionBar: {
                        className: styles.clockActionBar,
                        actions: ['cancel', 'accept'],
                      },
                      inputAdornment: {
                        className: styles.inputAdornment,
                      },
                    }}
                    viewRenderers={{
                      hours: renderTimeViewClock,
                      minutes: renderTimeViewClock,
                      seconds: renderTimeViewClock,
                    }}
                  />
                ) : (
                  <DatePicker
                    portalId='calendar-portal'
                    disabled={readOnly}
                    id={datePickerId}
                    showIcon={false}
                    selected={value}
                    renderCustomHeader={BymaDatePickerCustomHeader}
                    out
                    prevYearButtonDisabled={false}
                    nextYearButtonDisabled={false}
                    className={
                      !isEmpty(value)
                        ? styles[`bymaDatePicker${variant}`]
                        : styles.bymaDatePickerNone
                    }
                    inputClassName={styles.inputDatePicker}
                    wrapperClassName={
                      variant === 'Default'
                        ? clsx(styles.wrapperDatePickerDefault, readOnly && styles.readOnly)
                        : clsx(styles.wrapperDatePicker, readOnly && styles.readOnly)
                    }
                    calendarClassName={styles.calendarDatePicker}
                    clearButtonClassName={clearBtn ? styles.clearButtonDatePicker : 'd-none'}
                    dayClassName={() => styles.dayDatePicker}
                    weekDayClassName={() => styles.weekDayDatePicker}
                    timeClassName={() => styles.timeDatePicker}
                    monthClassName={() => styles.monthDatePicker}
                    yearClassName={() => styles.timeDatePicker}
                    dateFormat={dateFormat}
                    onChange={(date, event) => {
                      field.onChange(date)
                      customOnChange(date)
                    }}
                    onKeyDown={(event) => {
                      console.log(`DEBUG onKeyDown ${event.key}`)

                      //se cierra el popup al salir del componente
                      if (component && event.key === 'Tab') {
                        component.setOpen(false)
                      }
                    }}
                    onSelect={() => {
                      component.setFocus()
                    }}
                    showTimeSelect={showTimeSelect}
                    isClearable={true}
                    placeholderText={placeholder}
                    ref={(datepicker) => {
                      setComponent(datepicker)
                    }}
                    autoComplete='off'
                    locale={es}
                    popperClassName={styles.datePickerFormGroup}
                  />
                )}

                <div className={styles.calendarIconContainer} onClick={onIconClick}>
                  {hourPicker ? null : <DatePickerCalendarIcon width={20} height={20} />}
                </div>
              </div>
            </div>
            {helperText && <HelperText text={helperText} />}
            <ErrorMessage error={errorMessage} />
          </span>
        )
      }}
    />
  )
}

import React, { useMemo, useCallback } from 'react'

//TODO implementar hook para referescar el detalle
import { useQueryClient } from 'react-query'

import { Col, Row } from 'react-bootstrap'

import { Route } from 'react-router-dom'

import { BymaBadge, EditIcon, LocalErrorAlert, Loading, BymaCard } from 'components'
import TituloEditIcon from 'assets/img/colocacion-view/titulo_edit.svg'

import {
  useColocacionEditContext,
  ColocacionViewContext,
  ColocacionRoutePaths,
} from '../../ColocacionViewContext'

import { ColocacionSectionCard } from '../ColocacionSectionCard'

import NavDropdown from 'react-bootstrap/NavDropdown'
import { LinkContainer } from 'react-router-bootstrap'
import IntegracionesSection from './Integraciones/IntegracionesSection'
import FlujoFondosSection from './FlujoFondos/FlujoFondosSection'
import CanjesSection from './Canjes/CanjesSection'

import { FormProvider } from 'react-hook-form'
import { Form } from 'react-bootstrap'
import * as yup from 'yup'

import { useEditForm, LocalErrorProvider } from 'core'
import { BymaButton } from 'components'

import { TitulosApi, tituloFormDataToModel, tituloModelToFormData } from 'model/titulo'
import {
  TituloDetailStepFields,
  tituloDetailStepYupFields,
  TituloTramoCompetitivoStepFields,
  TituloTramoNoCompetitivoStepFields,
  tituloTramosStepYupFields,
} from '../TituloFields'

import styles from './TituloViewSection.module.scss'
import { ColocacionSummary } from '../../ColocacionSummary'
import clsx from 'clsx'

/**
 * Boton/Dropdown para acceder a los detalles
 * @returns
 */
const DetailsButton = (context: ColocacionViewContext) => {
  const { colocacion } = context

  const sections = useMemo(
    () =>
      [
        { label: 'Integracion', navigateTo: 'integraciones' },
        { label: 'Flujo de Fondos', navigateTo: 'flujo-fondos' },
        { label: 'Canje', navigateTo: 'canjes' },
      ].filter((s) => s.navigateTo !== 'canjes' || colocacion?.canje),
    [colocacion],
  )

  return (
    <NavDropdown
      title='+ Agregar'
      id='admin-scrolling-dropdown'
      className={clsx(styles.navDropdown, ' admin-scrolling-dropdown-titulo')}
    >
      {sections.map((section) => (
        <LinkContainer
          key={section.navigateTo}
          to={section.navigateTo}
          className={styles.linkContainer}
        >
          <NavDropdown.Item>{section.label}</NavDropdown.Item>
        </LinkContainer>
      ))}
    </NavDropdown>
  )
}

const EditButton = (context: ColocacionViewContext) => (
  <BymaButton
    id='entity-confirm-btn'
    name='entity-confirm-btn'
    label={'Editar'}
    onClick={() => context.setEditEnabled(true)}
    type={'button'}
    style={{
      padding: '10.5px 16px',
      fontFamily: 'var(--font-family-regular)',
      fontSize: '14px',
      lineHeight: '15px',
    }}
    iconLeft={EditIcon}
  />
)

const SaveButton = () => (
  <BymaButton
    id='entity-confirm-btn'
    name='entity-confirm-btn'
    label={'Guardar'}
    type={'submit'}
    style={{
      padding: '10.5px 16px',
      fontFamily: 'var(--font-family-regular)',
      fontSize: '14px',
      lineHeight: '15px',
    }}
  />
)

export const TituloViewContent = (context: ColocacionViewContext) => {
  //console.log(`DEBUG TituloViewContent editEnabled = ${context.editEnabled}`)

  return (
    <BymaCard
      titleBorder='none'
      footer={
        context.editEnabled ? (
          <>
            <SaveButton />
          </>
        ) : null
      }
    >
      {!context.editEnabled && (
        <Row sm={12} className='py-3'>
          <Col sm={12} style={{ display: 'flex', justifyContent: 'flex-end', gap: '2%' }}>
            {!context.readOnly && <EditButton {...context} />}
            <DetailsButton {...context} />
          </Col>
        </Row>
      )}

      <div>
        <Row className='mb-3 border-bottom'>
          <Col>{TituloDetailStepFields(context)}</Col>
        </Row>

        <Row className='mb-3 border-bottom'>
          <Col>{TituloTramoCompetitivoStepFields(context)}</Col>
        </Row>

        <Row className='mb-3'>
          <Col>{TituloTramoNoCompetitivoStepFields(context)}</Col>
        </Row>

        <Row>
          <LocalErrorAlert />
        </Row>
      </div>
    </BymaCard>
  )
}

const TituloHeaderExtras = (context: ColocacionViewContext) => {
  return (
    <>
      <BymaBadge text={'Titulo 1 Licita precio'} type='data' />
      <BymaBadge text={'Colocacion Nro 387594'} type='data' />
    </>
  )
}
/**
 * @see https://www.figma.com/file/mhoyqkY8Cz46W14hTBIgE2/Colocaciones?type=design&node-id=2263-390999&mode=design&t=P500IzGEf0LmwP8F-0
 *
 * @returns
 */
export const TituloViewSection = () => {
  const context = useColocacionEditContext()

  const { tituloId } = context

  //console.log(`DEBUG TituloViewSection colocacion=${JSON.stringify(context.colocacion)}`)

  const validationSchema = useMemo(
    () =>
      yup.object({
        ...tituloDetailStepYupFields(yup),
        ...tituloTramosStepYupFields(yup),
      }),
    [yup],
  )

  const getFormData = useCallback(() => {
    return TitulosApi.getOne(tituloId)
      .then((titulo) => tituloModelToFormData(titulo))
      .then((titulo) => {
        context.setTitulo(titulo)
        return titulo
      })
  }, [tituloId])

  const queryKey = `titulo_edit:${tituloId}`

  const mutation = useCallback(
    (formData) =>
      TitulosApi.update(tituloFormDataToModel(formData)).then((titulo) => {
        context.setTitulo(titulo)
        context.setEditEnabled(false)
        return titulo
      }),
    [],
  )

  //TODO usar hook para refrescar un detalle
  const queryClient = useQueryClient()

  const editForm = useEditForm<any>({
    queryKey,
    getFormData: getFormData,
    mutation: mutation,
    schema: validationSchema,

    confirm: false,
    keepPreviousData: true,

    successNotification: 'Se actualizó el Título exitosamente',
    errorNotification: 'Error actualizando el Título',

    onSuccess: () => queryClient.invalidateQueries(queryKey),

    clearLocalErrorOnLoad: true,
  })

  return (
    <ColocacionSectionCard
      title={context.editEnabled ? 'Editar Título' : 'Detalle Título'}
      icon={TituloEditIcon}
      headerExtras={<TituloHeaderExtras {...context} />}
      showFooter={false}
    >
      <Row className='mb-3'>
        <ColocacionSummary {...context} showTitulo={false} />
      </Row>
      <Row>
        <FormProvider {...editForm.form}>
          <Form id='colocacion-edit-main' onSubmit={editForm.onSubmit} style={{ padding: '0px' }}>
            <TituloViewContent {...context} />
          </Form>
        </FormProvider>
      </Row>
    </ColocacionSectionCard>
  )
}

export const TituloRoutes = (
  <>
    <Route
      path={ColocacionRoutePaths.tituloView}
      element={
        <Loading>
          <LocalErrorProvider>
            <TituloViewSection />
          </LocalErrorProvider>
        </Loading>
      }
    />
    <Route path={ColocacionRoutePaths.tituloIntegraciones} element={<IntegracionesSection />} />
    <Route path={ColocacionRoutePaths.tituloFlujoFondos} element={<FlujoFondosSection />} />
    <Route path={ColocacionRoutePaths.tituloCanjes} element={<CanjesSection />} />
  </>
)

import React, { ReactNode } from 'react'
import Card from 'react-bootstrap/Card'

import styles from './BymaHelpBox.module.scss'

interface HelpBoxProps {
  title: string
  children: ReactNode
  className?: string
}

const BymaHelpBox = (props: HelpBoxProps) => {
  const { title, children, className = '' } = props
  return (
    <Card className={styles.bymaHelpBox + ' ' + className}>
      <Card.Header className={styles.cardHeader}>{title}</Card.Header>
      <Card.Body className={styles.cardBody}>{children}</Card.Body>
    </Card>
  )
}

export default BymaHelpBox

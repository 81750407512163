import { useState } from 'react'

import { useForm, FieldValues } from 'react-hook-form'

import { yupResolver } from '@hookform/resolvers/yup'
import { reach, isSchema } from 'yup'

import { useBymaMutation } from 'core'

import { isNullOrUndefined } from 'utils/objects'

import { UseEditFormReturn } from './types'

import { EditFormProps } from './types'

export function useCreateForm<
  FormDataType extends FieldValues = any,
  TData = any,
  TVariables = unknown,
>({
  mutation,
  schema,
  successNotification,
  errorNotification,
  onSuccess,
  resetOnSuccess = true,
  confirm = false,
  defaultValues,
  values,
  taskId,
  taskMessage,
}: EditFormProps<FormDataType, TData>): UseEditFormReturn<FormDataType, TData, TVariables> {
  const [showConfirm, setShowConfirm] = useState<boolean>(false)
  const [formData, setFormData] = useState<any>(undefined)

  const useFormResult = useForm<FormDataType>({
    resolver: yupResolver(schema),
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    defaultValues: defaultValues,
    values: values,
  })

  const onSuccessImpl = (values, variables) => {
    if (onSuccess) {
      onSuccess(values, variables)
    }

    if (resetOnSuccess) {
      useFormResult.reset()
    }
  }

  const useBymaMutationResult = useBymaMutation<TData, TVariables, FormDataType>({
    mutation: mutation,
    onSuccess: onSuccessImpl,
    successNotification: successNotification,
    errorNotification: errorNotification,
    useFormReturn: useFormResult,
    taskMessage,
    taskId,
  })

  const onSubmit = (newData: any) => {
    if (confirm) {
      //console.log('useCreateForm onSubmit confirm')

      setFormData(newData)
      setShowConfirm(confirm)
    } else {
      //console.log('useCreateForm onSubmit mutate')

      useBymaMutationResult.mutate(newData)
    }
  }

  const isFieldDefined = (field: string) => {
    try {
      return isSchema(schema) && !isNullOrUndefined(reach(schema, field))
    } catch (error) {
      return false
    }
  }

  const handleConfirm = confirm
    ? () => {
        if (formData) {
          //console.log('useCreateForm handleConfirm mutate')

          useBymaMutationResult.mutate(formData)
        }
        setFormData(undefined)
      }
    : () => {}

  return {
    form: useFormResult,
    mutation: useBymaMutationResult,

    onSubmit: useFormResult.handleSubmit(onSubmit),

    handleConfirm,
    showConfirm,
    setShowConfirm,

    isFieldDefined,
  }
}

import AxiosMockAdapter from 'axios-mock-adapter-path-params'

export const colocadoresResponse = [
  {
    id: 1,
    cuit: '30-70829725-5',
    denominacion: 'AEROMAR BURSATIL SOCIEDAD DE BOLSA S.A.',
    direccion: 'Florida 375 7mo piso (C1043AAX) CABA',
    situacionIva: 'RESPONSABLE_INSCRIPTO',
    email: 'helenad@aeromar.com.ar',
    telefono: '(54 11) 4326-6362/50',
    contacto: null,
    tipoColocador: null,
    numeroCuenta: null,
    lugarLiquidacion: null,
  },
  {
    id: 2,
    cuit: '29.835.291',
    denominacion: 'INTL CIBSA S.A.',
    direccion: null,
    situacionIva: null,
    email: null,
    telefono: null,
    contacto: null,
    tipoColocador: null,
    numeroCuenta: null,
    lugarLiquidacion: null,
  },
  {
    id: 3,
    cuit: '29.835.293',
    denominacion: 'BADEVAL SOCIEDAD DE BOLSA S.A.',
    direccion: null,
    situacionIva: null,
    email: null,
    telefono: null,
    contacto: null,
    tipoColocador: null,
    numeroCuenta: null,
    lugarLiquidacion: null,
  },
  {
    id: 4,
    cuit: '29.835.293',
    denominacion: 'ALLARIA LEDESMA Y CIA SOCIEDAD DE BOLSA S.A.',
    direccion: null,
    situacionIva: null,
    email: null,
    telefono: null,
    contacto: null,
    tipoColocador: null,
    numeroCuenta: null,
    lugarLiquidacion: null,
  },
  {
    id: 5,
    cuit: '29.835.295',
    denominacion: 'BANCO SANTANDER RIO',
    direccion: null,
    situacionIva: null,
    email: null,
    telefono: null,
    contacto: null,
    tipoColocador: null,
    numeroCuenta: null,
    lugarLiquidacion: null,
  },
  {
    id: 6,
    cuit: '29.835.296',
    denominacion: 'BANCO PROVINCIA DE SANTA FE',
    direccion: null,
    situacionIva: null,
    email: null,
    telefono: null,
    contacto: null,
    tipoColocador: null,
    numeroCuenta: null,
    lugarLiquidacion: null,
  },
  {
    id: 7,
    cuit: '29.835.297',
    denominacion: 'BANCO BANEX',
    direccion: null,
    situacionIva: null,
    email: null,
    telefono: null,
    contacto: null,
    tipoColocador: null,
    numeroCuenta: null,
    lugarLiquidacion: null,
  },
  {
    id: 8,
    cuit: '29.835.298',
    denominacion: 'BANCO REGIONAL DE CUYO',
    direccion: null,
    situacionIva: null,
    email: null,
    telefono: null,
    contacto: null,
    tipoColocador: null,
    numeroCuenta: null,
    lugarLiquidacion: null,
  },
  {
    id: 9,
    cuit: '29.835.299',
    denominacion: 'BANCO DE CORDOBA',
    direccion: null,
    situacionIva: null,
    email: null,
    telefono: null,
    contacto: null,
    tipoColocador: null,
    numeroCuenta: null,
    lugarLiquidacion: null,
  },
]

export const getColocadorResponse = (id: number) => {
  const entities = colocadoresResponse.filter((col) => col.id == id)
  return entities !== undefined && entities.length > 0 ? entities[0] : {}
}

export const getColocadoresExportResponse = () => {
  const blob = new Blob([''])
  return blob
}

export const mockColocadores = (mock: AxiosMockAdapter) => {
  mock.onGet('/api/colocadores').reply(() => {
    return [200, colocadoresResponse]
  })

  mock.onGet('/api/colocadores/:id').reply((config: any) => {
    const { id } = config.routeParams
    return [200, getColocadorResponse(id)]
  })

  mock.onPost('/api/colocadores').reply((config: any) => {
    const { id } = config.routeParams
    return [200, getColocadorResponse(id)]
  })

  mock.onDelete('/api/colocadores/:id').reply((config: any) => {
    const { id } = config.routeParams
    return [200, {}]
  })

  mock.onPut('/api/colocadores/:id').reply((config: any) => {
    const { id } = config.routeParams
    return [200, getColocadorResponse(id)]
  })

  mock.onGet('/api/colocadores/export').reply(() => {
    return [
      200,
      getColocadoresExportResponse(),
      {
        'content-disposition': 'filename="colocadores.csv"',
        'content-type': 'application/csv',
      },
    ]
  })
}

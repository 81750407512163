import { useState } from 'react'

import { UseEditFormReturn } from './types'

import { useForm, FieldValues } from 'react-hook-form'

import { yupResolver } from '@hookform/resolvers/yup'
import { reach, isSchema } from 'yup'

import { useBymaMutation, useEditQuery } from 'core'

import { isNullOrUndefined } from 'utils/objects'

import { EditFormProps } from './types'

export const useEditForm = <FormDataType extends FieldValues = any>({
  queryKey = 'DEFAULT_EDIT_FORM_KEY',
  getFormData = () => Promise.resolve({}),
  mutation,
  schema,
  successNotification,
  errorNotification,
  onSuccess,
  confirm = false,
  defaultValues = {},
  keepPreviousData = false,
  //seteamos un cache time minimo para evitar refetch innecesario
  cacheTime = 500,
  clearLocalErrorOnLoad,

  updateOnSuccess = false,
}: EditFormProps): UseEditFormReturn<FormDataType> => {
  const [showConfirm, setShowConfirm] = useState<boolean>(false)
  const [formData, setFormData] = useState<any>(undefined)

  //console.log(`DEBUG useEditForm queryKey=${queryKey}`)

  const useQueryResult = useEditQuery({
    entityKey: queryKey,
    id: '',
    dataProvider: getFormData,
    keepPreviousData,
    cacheTime,
    clearLocalErrorOnLoad,
  })

  //console.log(`DEBUG useEditForm useQueryResult.data=${JSON.stringify(useQueryResult.data)}`)

  const useFormResult = useForm<FormDataType>({
    resolver: yupResolver(schema),
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    //evitamos setear values = undefined para no mostrar valores previos
    values: useQueryResult.data || defaultValues,
    defaultValues: defaultValues,
  })

  const onSuccessImpl = (data) => {
    if (updateOnSuccess) {
      useFormResult.reset(data)
    }
    if (onSuccess) {
      onSuccess(data, data)
    }
  }

  const useBymaMutationResult = useBymaMutation({
    mutation: mutation,
    onSuccess: onSuccessImpl,
    successNotification: successNotification,
    errorNotification: errorNotification,
    useFormReturn: useFormResult,
  })

  const onSubmit = (newData: any) => {
    if (confirm) {
      setFormData(newData)
      setShowConfirm(confirm)
    } else {
      useBymaMutationResult.mutate(newData)
    }
  }

  const handleConfirm = confirm
    ? () => {
        if (formData) {
          useBymaMutationResult.mutate(formData)
        }
        setFormData(undefined)
      }
    : () => {}

  const isFieldDefined = (field: string) => {
    try {
      return isSchema(schema) && !isNullOrUndefined(reach(schema, field))
    } catch (error) {
      return false
    }
  }

  return {
    query: useQueryResult,
    form: useFormResult,
    mutation: useBymaMutationResult,

    onSubmit: useFormResult.handleSubmit(onSubmit),

    handleConfirm,
    showConfirm,
    setShowConfirm,

    isFieldDefined,
  }
}

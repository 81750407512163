import { numberFormat } from 'utils/number'
import { TipoRedondeo } from 'model/common'
import { isNullOrUndefined } from 'utils/objects'

export const TITULO_INTEGRACION_ENTITY_NAME = 'integracion'

export interface TituloIntegracion extends DenominableEntity {
  id: number

  tituloColocacionId: number

  tituloDenominacion: number

  cotizacion: number

  cantidadMinima: number

  laminaMinima: number

  tipoRedondeo: TipoRedondeo

  aliasSiopel: string

  totalOfertado?: number

  totalAdjudicado?: number

  totalIntegrado?: number
}

const integracionNumberFormat = numberFormat(9)

export const integracionNumberPropertyFormatter = (value) =>
  !isNullOrUndefined(value) ? integracionNumberFormat.format(value) : ''

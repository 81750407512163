import React from 'react'
import { EntityModal } from 'features/common/Entity'

import { Colocador } from 'model/colocador'
import { EntityModalProps } from 'features/common/Entity'
import { ColocadorForm } from './ColocadorForm'

const ColocadorSchemaProperties = (yup) =>
  yup.object().shape({
    denominacion: yup.string().required(),
    cuit: yup.string().required(),
  })

const ColocadorModal = (props: EntityModalProps<Colocador>) => {
  const editEnabled = props.context.editEnabled

  return (
    <EntityModal
      {...props}
      focusField='denominacion'
      getSchema={ColocadorSchemaProperties}
      renderCreateForm={(props) =>
        ColocadorForm({ ...props, cardTitle: 'Nuevo Colocador', editEnabled: true })
      }
      renderEditForm={(props) =>
        ColocadorForm({
          ...props,
          cardTitle: editEnabled ? 'Editar Colocador' : 'Detalle Colocador',
          editEnabled,
        })
      }
    />
  )
}

export default ColocadorModal

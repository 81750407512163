export const OFERTA_IMPORT_ENTITY_NAME = 'oferta_import'

import { Oferta } from 'model/oferta/Model'

export const OFERTAS_IMPORT_RESULT_ENTITY_NAME = 'ofertas_import_result'

export enum EstadoOfertasImport {
  PREVIEW = 'PREVIEW',
  ERROR = 'ERROR',
  WARNING = 'WARNING',
  PROCESADA = 'PROCESADA',
}

export enum EstadoOfertasImportItem {
  OK = 'OK',
  WARNING = 'WARNING',
  ERROR = 'ERROR',
}

export interface OfertaImportError {
  message: string
  field?: string
  code?: string
}

export enum OrdenOfertasImport {
  BY_ERROR_LEVEL = 'BY_ERROR_LEVEL',
  BY_POSICION = 'BY_POSICION',
}

export interface OfertasImportItem extends BaseEntity {
  estado: EstadoOfertasImportItem
  oferta: Oferta
  errors?: OfertaImportError[]
  warnings?: OfertaImportError[]
}

export interface OfertasImport extends BaseEntity {
  fechaAlta: string
  usuarioAlta: string
  estado: EstadoOfertasImport
  colocacionId: Identifier

  ofertas: OfertasImportItem[]
}

export interface OfertasImporCreate extends BaseEntity {
  documento: string
  colocacionId: Identifier
}

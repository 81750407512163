import { useCallback, useMemo } from 'react'
import { useMutation } from 'react-query'
import { UseFormReturn, FieldValues } from 'react-hook-form'

import { useLoadingProducer, useNotificationProducer, useLocalErrorProducer } from 'core'

import { toApiError, getApiErrorMessage } from 'services/api'

export type NotificationParamType<R, V> = string | ((result: R, variables: V) => string) | undefined

export type ErrorNotificationParamType<V> = NotificationParamType<ApiError, V>

export type MutationFnParam<TData = unknown, TVariables = unknown> = (
  variables: TVariables,
) => Promise<TData>

export type MutationSuccessCallbackParam<TData = unknown, TVariables = unknown> = (
  data: TData,
  variables: TVariables,
) => void

export type MutationErrorCallbackParam<TVariables = unknown> = (
  error: unknown,
  variables: TVariables,
) => void

function getNotification<R, V>(
  notification: NotificationParamType<R, V>,
  result: R,
  variables: V,
): string | undefined {
  if (!notification) {
    return undefined
  }
  if (notification instanceof Function) {
    return notification(result, variables)
  }
  return notification as string
}

export interface UseBymaMutationParams<
  TData = unknown,
  TVariables = unknown,
  TFieldValues extends FieldValues = FieldValues,
> {
  mutation: MutationFnParam<TData, TVariables>

  onSuccess?: MutationSuccessCallbackParam<TData, TVariables>
  onError?: MutationErrorCallbackParam<TVariables>

  successNotification?: NotificationParamType<TData, TVariables>
  errorNotification?: ErrorNotificationParamType<TVariables>

  useFormReturn?: UseFormReturn<TFieldValues>

  taskId?: string
  taskMessage?: string
}

function setFormFieldErrors<TFieldValues extends FieldValues = FieldValues>(
  useFormReturn: UseFormReturn<TFieldValues>,
  apiError: ApiError,
) {
  //console.log(`DEBUG useBymaMutation setFormFieldErrors apiError = ${JSON.stringify(apiError)}`)

  const details = apiError?.details
  if (!details) {
    return
  }

  details.forEach((detail) => {
    if (detail.field) {
      const errorJoin = detail.errors.join('\n')

      /*
      console.log(
        `DEBUG useBymaMutation setFormFieldErrors setError (${detail.field},${errorJoin})`,
      )
      */

      useFormReturn.setError(detail.field, {
        type: 'custom',
        message: errorJoin,
      })
    }
  })
}

function getGeneralError(apiError: ApiError): ApiError | null {
  console.log(`DEBUG useBymaMutation setFormFieldErrors apiError = ${JSON.stringify(apiError)}`)

  const details = apiError?.details
  if (!details || !details.length) {
    return apiError
  }

  let result = null

  details.forEach((detail) => {
    if (!detail.field) {
      const errorJoin = detail.errors.join('\n')
      result = result ? result + '\n' + errorJoin : errorJoin
    }
  })

  return result ? { message: result, id: apiError.id } : null
}

const DEFAULT_TASK_ID = 'mutation'
const DEFAULT_TASK_MESSAGE = ''

function useBymaMutation<
  TData = unknown,
  TVariables = unknown,
  TFieldValues extends FieldValues = FieldValues,
>(params: UseBymaMutationParams<TData, TVariables, TFieldValues>) {
  const notificationProducer = useNotificationProducer()

  const loadingProducer = useLoadingProducer()

  const localErrorProducer = useLocalErrorProducer()

  const taskId = params.taskId || DEFAULT_TASK_ID

  const taskMessage = params.taskMessage || DEFAULT_TASK_MESSAGE

  const addErrorNotification = useCallback(
    (apiError, variables) => {
      if (params.errorNotification) {
        const generalErrorNotificationMessage = getNotification(
          params.errorNotification,
          apiError,
          variables,
        )
        if (generalErrorNotificationMessage) {
          notificationProducer.addNotification({
            message: generalErrorNotificationMessage,
            type: 'error',
          })
        }
      }
    },
    [notificationProducer, params.errorNotification],
  )

  const result = useMutation<TData, ApiError, TVariables>(params.mutation, {
    onMutate() {
      loadingProducer.addTask({ message: taskMessage, id: taskId })
    },

    onSuccess(data, variables) {
      //console.log(`DEBUG useBymaMutation onSuccess data=${JSON.stringify(data)}`)

      const notificationMessage = getNotification(params.successNotification, data, variables)
      if (notificationMessage) {
        //console.log(`DEBUG useBymaMutation onSuccess notificationMessage notificationMessage=${JSON.stringify(notificationMessage)}`)

        notificationProducer.addNotification({
          type: 'info',
          message: notificationMessage,
        })
      }
      if (params.onSuccess) {
        params.onSuccess(data, variables)
      }

      if (localErrorProducer) {
        localErrorProducer.clearError()
      }
    },

    onError(error, variables) {
      const apiError = toApiError(error)

      /*
      console.log(
        `DEBUG useBymaMutation onError error.response=${JSON.stringify(
          (error as any).response,
        )} error=${JSON.stringify(error)}`,
      )
      */

      if (params.useFormReturn) {
        setFormFieldErrors(params.useFormReturn, apiError)
      }

      if (localErrorProducer) {
        const generalError = getGeneralError(apiError)

        /*
        console.log(
          `DEBUG useBymaMutation onError localErrorProducer generalError=${JSON.stringify(
            generalError,
          )}`,
        )
        */

        if (generalError) {
          localErrorProducer.setError({
            message: generalError.message,
            supportId: generalError.id,
          })

          //solo mostramos notificacion si es un error general
          addErrorNotification(apiError, variables)
        }
      } else {
        addErrorNotification(apiError, variables)

        /*
        console.log(
          `DEBUG useBymaMutation onError !localErrorProducer notificationMessage=${JSON.stringify(notificationMessage)}`
        )
        */
      }

      if (params.onError) {
        /*
        console.log(
          `DEBUG useBymaMutation se invoca custom error apiError=${JSON.stringify(apiError)}`,
        )
        */
        params.onError(apiError, variables)
      }
    },

    onSettled() {
      loadingProducer.removeTask(taskId)
    },
  })

  const error = useMemo(() => getApiErrorMessage(toApiError(result.error)), [result.error])

  return {
    ...result,
    error,
  }
}

export { useBymaMutation }

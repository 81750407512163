import { useEffect } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import {
  TipoOferta,
  Titulo,
  getTituloColocacionById,
  mapDenominableListToOption,
  TituloAction,
} from 'model'
import { OfertaContextInfo } from './OfertaContextInfo'
import { isDefined } from 'utils/objects'

//TODO BP-166 agregar useWatch para "tituloId" en el form, si el valor no es undefined, tomar como referencia el titulo seleccionado
const useOfertaEditState = (context: OfertaContextInfo) => {
  const { setValue, control } = useFormContext()

  const tipoOferta = useWatch({ control, name: 'tipoOferta' })
  const tituloId = useWatch({ control, name: 'tituloId' })

  const isCapVisible =
    context.tipoAltaOferta === TituloAction.OFERTA_EXTENDIDA &&
    context.isFieldEnabled('porcentajeInversion')

  const onTipoOfertaChanged = (tipoOferta) => {
    if (tipoOferta !== TipoOferta.COMPETITIVA) {
      setValue('valor', null)
    }
  }

  useEffect(() => {
    onTipoOfertaChanged(tipoOferta)
  }, [tipoOferta])

  const isCompetitivo = tipoOferta === TipoOferta.COMPETITIVA

  const valorInputPlaceHolder = isCompetitivo ? 'Completa' : 'Solo competitivo'

  const titulo: Titulo | undefined =
    isDefined(tituloId) && context.currentColocacion
      ? getTituloColocacionById(context.currentColocacion, tituloId)
      : context.currentTitulo

  const cantidadMinima = isCompetitivo
    ? titulo?.tramoCompetitivo?.cantidadMinimaOferta
    : titulo?.tramoNoCompetitivo?.cantidadMinimaOferta

  const cantidadMaxima = isCompetitivo
    ? titulo?.tramoCompetitivo?.cantidadMaximaOferta
    : titulo?.tramoNoCompetitivo?.cantidadMaximaOferta

  const integracionTituloOptions = mapDenominableListToOption(titulo?.integraciones)

  const valorReferencia = titulo?.valorReferencia

  return {
    isCompetitivo,
    valorInputEnabled: isCompetitivo,
    valorInputPlaceHolder,
    cantidadMinima,
    cantidadMaxima,
    integracionTituloOptions,
    valorReferencia,
    titulo,
    isCapVisible,
  }
}

export default useOfertaEditState

import React, { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import useAuthContext from '../../core/auth/useAuthContext'
import { BymaBackButton } from '..'
import { routes } from 'routes/routes'

//TODO see https://www.toptal.com/react/react-router-tutorial
const ProtectedRoute = (props: any) => {
  const { showBackButton = true } = props
  const auth = useAuthContext()
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const [isLoggedIn, setIsLoggedIn] = useState(false)

  const checkAuthenticated = () => {
    if (!auth.authenticated) {
      setIsLoggedIn(false)
      return navigate('/login')
    }
    if (auth.userInfo && auth.userInfo.passwordExpirationDays <= 0) {
      setIsLoggedIn(false)
      return navigate('/change-password-expired')
    }
    setIsLoggedIn(true)
  }

  useEffect(() => {
    checkAuthenticated()
  }, [isLoggedIn])

  return (
    <React.Fragment>
      {isLoggedIn ? (
        <>
          {props.children}
          {pathname !== routes.home && pathname !== routes.dashboard && showBackButton && (
            <div className='pb-5'>
              <BymaBackButton />
            </div>
          )}
        </>
      ) : null}
    </React.Fragment>
  )
}
export default ProtectedRoute

import React, { CSSProperties, ReactNode, useContext } from 'react'
import Accordion from 'react-bootstrap/Accordion'
import { useAccordionButton } from 'react-bootstrap/AccordionButton'
import AccordionContext from 'react-bootstrap/AccordionContext'

import styles from './BymaAccordion.module.scss'
import './BymaAccordion.scss'

interface AccordionItemHeader {
  title?: string
  content: ReactNode
}

interface AccordionItem {
  header: AccordionItemHeader
  body: ReactNode
  footer?: ReactNode
  id?: number
}

export interface BymaAccordionProps {
  key: string
  items: AccordionItem[]
  className?: string
  defaultActiveKey?: string
  alwaysOpen?: boolean
  useLeftButton?: boolean
  style?: CSSProperties | undefined
  closed?: boolean
  onClick?: (e) => void | null
}

export const BYMA_ACCORDION_ACTIVE_KEY_CLOSED = 'X'

const BymaAccordion = (props: BymaAccordionProps) => {
  const {
    key,
    defaultActiveKey = undefined,
    items,
    alwaysOpen = true,
    useLeftButton = false,
    style = {},
    closed = false,
    onClick = null,
  } = props

  const LeftButton = ({ eventKey, disabled }) => {
    const { activeEventKey } = useContext(AccordionContext)

    const decoratedOnClick = useAccordionButton(eventKey, () => {
      onClick && onClick(items[eventKey].id)
    })

    const isActive =
      activeEventKey &&
      (activeEventKey === eventKey || (activeEventKey as string[]).includes(eventKey))

    const className = isActive
      ? `${styles.leftButton} ${styles.not_collapsed}`
      : `${styles.leftButton} ${styles.collapsed}`

    return (
      <button
        id={'left-button-' + eventKey}
        type='button'
        onClick={decoratedOnClick}
        className={className}
        disabled={disabled}
      />
    )
  }

  const activeKeyClosed = closed ? BYMA_ACCORDION_ACTIVE_KEY_CLOSED : undefined

  return (
    <Accordion
      key={key}
      defaultActiveKey={defaultActiveKey}
      className={styles.bymaAccordion}
      alwaysOpen={alwaysOpen}
      activeKey={activeKeyClosed}
    >
      {items &&
        items.length > 0 &&
        items.map((item: any, idx: number) => (
          <Accordion.Item
            className={styles.accordionItem}
            eventKey={idx.toString()}
            style={style}
            key={'item-' + idx}
          >
            <Accordion.Header
              className={`${useLeftButton ? styles.leftButtonHeader : styles.rightButtonHeader} ${
                item.header.active && styles.activeItem
              } `}
            >
              {item.header.title && (
                <span className={styles.accordionLabel}>
                  <span>{item.header.title}</span>
                </span>
              )}
              {useLeftButton && !closed && (
                <LeftButton eventKey={idx.toString()} disabled={closed} />
              )}
              {item.header.content}
            </Accordion.Header>

            <Accordion.Body className={styles.accordionBody}>{item.body}</Accordion.Body>
          </Accordion.Item>
        ))}
    </Accordion>
  )
}

export default BymaAccordion

import React, { useState } from 'react'

import { ToggleOnIcon } from 'components'
import { useColocacionEditContext } from 'features/Colocacion/ColocacionViewContext'
import { ColocacionSectionCard } from '../ColocacionSectionCard'
import { ColocacionSummary } from 'features/Colocacion/ColocacionSummary'
import ImportOfertasContent from './ImportOfertasContent'
import OfertasPreviewContent from './OfertasPreviewContent'

import styles from './ImportOfertas.module.scss'

export default () => {
  const context = useColocacionEditContext()
  const [importData, setImportData] = useState<any | undefined>(undefined)
  return (
    <ColocacionSectionCard
      title={'importación masiva de ofertas'}
      icon={ToggleOnIcon}
      showFooter={false}
    >
      <div className={styles.importOfertasContent}>
        <ColocacionSummary {...context} colocacionActionEnabled={false} />
        {importData !== undefined && importData.id !== undefined ? (
          <OfertasPreviewContent
            context={context}
            fileName={importData?.fileName}
            setImportData={setImportData}
            importData={importData}
          />
        ) : (
          <ImportOfertasContent context={context} setImportData={setImportData} />
        )}
      </div>
    </ColocacionSectionCard>
  )
}

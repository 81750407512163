import React, { useState, useEffect, useMemo, useCallback } from 'react'
import { FormProvider, UseFormReturn } from 'react-hook-form'

import { Col, Form, Row } from 'react-bootstrap'

import { isEqual } from 'lodash'

import {
  BymaCard,
  BymaSelect,
  BymaButton,
  Loading,
  LocalErrorAlert,
  BymaSwitchButtonInput,
  NumberInput,
} from 'components'

import * as yup from 'yup'

import { useQueryClient } from 'react-query'

import { LoadingProvider, LocalErrorProvider, useLocalErrorProducer, useCreateForm } from 'core'

import {
  OfertaContextInfo,
  useParticipanteOfertaState,
  useOfertaEditState,
  getOfertaBaseDefaultValues,
  getOfertaBaseSchema,
  OfertaParticipanteFormFields,
} from '../../common/Oferta'

import {
  OfertaCreationInfo,
  OfertasApi,
  OFERTA_ENTITY_NAME,
  mapDenominableListToOption,
  ofertaValueLabelFormatter,
  limitesCantidadHelperTextFormatter,
  precioReferenciaFormatter,
  TipoOfertaOptions,
  TituloActionLabel,
  TituloAction,
} from 'model'

import styles from '../../common/Oferta/Ofertas.module.scss'

interface OfertaRapidaProps {
  className?: string
  context: OfertaContextInfo
}

const OfertaRapidaFormFields = (
  props: OfertaRapidaProps & {
    formResult: UseFormReturn<OfertaCreationInfo, any>
  },
) => {
  const participanteState = useParticipanteOfertaState(props.context)

  const integracionTituloOptions = mapDenominableListToOption(
    props.context.currentTitulo?.integraciones,
  )

  const {
    valorInputEnabled,
    valorInputPlaceHolder,
    cantidadMaxima,
    cantidadMinima,
    valorReferencia,
    titulo,
  } = useOfertaEditState(props.context)

  const valorHelperText = precioReferenciaFormatter(valorReferencia)
  const cantidadHelperText = limitesCantidadHelperTextFormatter(cantidadMinima, cantidadMaxima)
  const tipoValorText = ofertaValueLabelFormatter(titulo)

  return (
    <>
      <Row className={styles.ofertaRapidaTitle_container}>
        <Col sm={12}>
          <div className={styles.ofertaRapidaTitle}>
            <p>{props?.context?.currentTitulo?.descripcion}</p>
          </div>
          <div className={styles.line} />
        </Col>
      </Row>
      <Row>
        <Col sm={12}>
          <div style={{ marginBottom: 10, height: 32 }}>
            <BymaSwitchButtonInput
              name='tipoOferta'
              id='no-competitivo-btn'
              options={TipoOfertaOptions}
              styles={{ fontSize: 12 }}
            />
          </div>
        </Col>
      </Row>
      <Row className='mb-3'>
        <Col sm={4}>
          <NumberInput
            name='cantidad'
            label='Cantidad'
            type='number'
            required={true}
            helperText={cantidadHelperText}
          />
        </Col>
        <Col sm={4}>
          <NumberInput
            name='valor'
            label={tipoValorText}
            type='number'
            placeHolder={valorInputPlaceHolder}
            required={valorInputEnabled}
            helperText={valorHelperText}
            readOnly={!valorInputEnabled}
            disabled={!valorInputEnabled}
          />
        </Col>
        <Col sm={4}>
          <BymaSelect
            name='integracionTituloId'
            label='Integración'
            required={true}
            options={integracionTituloOptions}
          />
        </Col>
      </Row>

      <OfertaParticipanteFormFields {...participanteState} />

      <Row>
        <LocalErrorAlert />
      </Row>
    </>
  )
}

const OfertaRapidaForm = (props: OfertaRapidaProps) => {
  const { className = '' } = props

  const defaultValues = useMemo(
    () => getOfertaBaseDefaultValues(props.context.currentTitulo),
    [props.context.currentTitulo],
  )

  const schema = useMemo(() => getOfertaBaseSchema(yup, props.context), [yup, props.context])

  const createOferta = useCallback((data) => {
    return OfertasApi.create(data)
  }, [])

  const queryClient = useQueryClient()

  //TODO reutilizar este callback
  const onSuccess = useCallback(() => {
    //si se produce el alta => se invalidan todas las listas de ofertas
    queryClient.invalidateQueries({
      predicate: (query) => {
        return query.queryKey[0] === OFERTA_ENTITY_NAME
      },
    })
  }, [queryClient])

  const createForm = useCreateForm<OfertaCreationInfo>({
    defaultValues,
    values: defaultValues,
    schema: schema,
    mutation: createOferta,
    //successNotification: (data) => `Se creo Oferta con id ${data['id']}`,
    successNotification: () => 'Se ingresó la oferta exitosamente',
    errorNotification: (error) => `Error Ingresando Oferta: ${error.message}`,
    onSuccess: onSuccess,
  })

  const currentTituloId = props.context.currentTitulo?.id
  const [prevTituloId, setPrevTituloId] = useState<Identifier | undefined>()

  //focus (al iniciarse y al cambiar de titulo)
  useEffect(() => {
    createForm.form.setFocus('cantidad')
  }, [currentTituloId])

  useEffect(() => {
    createForm.form.setFocus('cantidad')
  }, [])

  const localErrorApi = useLocalErrorProducer()

  //console.log(`DEBUG OfertaRapidaForm render prevTituloId = ${prevTituloId}`)

  if (!isEqual(prevTituloId, props.context.currentTitulo?.id)) {
    //console.log(`DEBUG OfertaRapidaForm cambio de titulo ${prevTituloId} ${currentTituloId}`)

    setPrevTituloId(currentTituloId)

    createForm.form.reset()
    createForm.form.setValue('tituloId', currentTituloId)

    if (localErrorApi) {
      localErrorApi.clearError()
    }
  }

  const handleOfertaExtendida = () => {
    props.context.setShowOfertaExtendida(true)
    props.context.setShowOfertaRapida(false)
    props.context.setTipoAltaOferta(TituloAction.OFERTA_EXTENDIDA)
  }

  return (
    <Loading>
      <FormProvider {...createForm.form}>
        <Form
          id='oferta-rapida-edit-form'
          className={styles.ofertaRapidaForm}
          onSubmit={createForm.onSubmit}
        >
          <BymaCard
            className={className}
            style={{
              backgroundColor: 'var(--secondary-gray-light)',
              borderRadius: '20px',
            }}
            footer={
              <div className={styles.footerOfertaExtendida_container}>
                <div className={styles.buttonOfertaExtendida_container}>
                  <BymaButton
                    type='button'
                    id='oferta-rapida-btn'
                    name='oferta-rapida-btn'
                    label={TituloActionLabel[TituloAction.OFERTA_EXTENDIDA]}
                    className={styles.buttonOfertaExtendida}
                    onClick={handleOfertaExtendida}
                  />
                </div>

                <div className={styles.buttonOfertaExtendida_container}>
                  <BymaButton
                    type='submit'
                    id='create-oferta-form-btn'
                    name='create-oferta-form-btn'
                    label={'Ofertar'}
                    iconRight='arrow_forward.svg'
                  />
                </div>
              </div>
            }
          >
            <OfertaRapidaFormFields {...props} formResult={createForm.form} />
          </BymaCard>
        </Form>
      </FormProvider>
    </Loading>
  )
}

export default (props: OfertaRapidaProps) => (
  <LocalErrorProvider>
    <LoadingProvider>
      <OfertaRapidaForm {...props} />
    </LoadingProvider>
  </LocalErrorProvider>
)

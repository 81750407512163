import React from 'react'
import { FormProvider } from 'react-hook-form'
import { Form } from 'react-bootstrap'

import ColocacionesApi from 'model/colocacion/ColocacionesApi'

import { Colocador } from 'model'
import { ColocadorOptionsQueryProps } from 'model'
import {
  ColocacionEditProps,
  useColocacionEditContext,
  ColocacionSectionType,
} from '../../../ColocacionViewContext'

import {
  AutorizacionSectionFieldsProps,
  AutorizacionSectionFields,
  useAutorizacionSectionForm,
} from '../AutorizacionSectionCommons'

const CoColocadoresSectionFields = (props: ColocacionEditProps) => {
  const sectionProps: AutorizacionSectionFieldsProps<Colocador> = {
    ...props,

    section: ColocacionSectionType.CO_COLOCADORES,

    cardTitle: 'Autorizacion Co-colocadores',

    dataProvider: (id) => ColocacionesApi.getCoColocadores(id),

    optionsParams: ColocadorOptionsQueryProps,

    dualListProps: {
      labelAvailable: 'Autorizados',
      labelSelected: 'Autorizados',
      labels: {
        availableFilterHeader: 'Buscar',
        selectedFilterHeader: 'Buscar',
        availableHeader: 'NO AUTORIZADOS',
        selectedHeader: 'AUTORIZADOS',
        moveLeft: '< Quitar',
        moveRight: 'Agregar >',
        moveLeftTooltip: 'Quitar Co-Colocador',
        moveRightTooltip: 'Agregar Co-Colocador',
      },
    },
  }
  return <AutorizacionSectionFields {...sectionProps} />
}

export const CoColocadoresSection = () => {
  const props = useColocacionEditContext()

  const { useFormReturn, onSubmit } = useAutorizacionSectionForm({
    successNotification: 'Se actualizaron los Co-Colocadores',

    errorNotification: 'Error actualizando Co-Colocadores',

    mutation: (id, autorizaciones) => ColocacionesApi.updateCoColocadores(id, autorizaciones),

    ...props,
  })

  return (
    <FormProvider {...useFormReturn}>
      <Form id='colocacion-cocolocadores-edit' onSubmit={useFormReturn.handleSubmit(onSubmit)}>
        <CoColocadoresSectionFields {...props} />
      </Form>
    </FormProvider>
  )
}

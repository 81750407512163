import React from 'react'
import { TextInput, BymaFormCard, LocalErrorAlert } from 'components'
import { Col, Row } from 'react-bootstrap'
import { Emisor } from 'model'

import { EntityModalProps, EntityModal } from 'features/common/Entity'

const EmisorSchemaProperties = (yup) =>
  yup.object().shape({
    denominacion: yup.string().required(),
  })

const EmisorForm = ({ editEnabled = false }) => (
  <>
    <BymaFormCard title={'Emisor'}>
      <Row sm={12}>
        <Col sm={10}>
          <TextInput name='denominacion' label='Denominación Emisor' readOnly={!editEnabled} />
        </Col>
      </Row>
    </BymaFormCard>

    <div style={{ marginTop: '1em' }}>
      <LocalErrorAlert />
    </div>
  </>
)

const EmisorModal = (props: EntityModalProps<Emisor>) => {
  const editEnabled = props.context.editEnabled

  return (
    <EntityModal
      {...props}
      focusField='denominacion'
      getSchema={EmisorSchemaProperties}
      renderCreateForm={() => EmisorForm({ editEnabled: true })}
      renderEditForm={() => EmisorForm({ editEnabled })}
    />
  )
}

export default EmisorModal

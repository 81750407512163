import React from 'react'
import ProtectedRoute from './components/ProtectedRoute/ProtectedRoute'
import { Route, Routes } from 'react-router-dom'
import Agentes from './features/Admin/Agentes'
import Roles from './features/Admin/Roles'
import Help from './features/Help'
import ChangePassword from 'features/ChangePassword'
import DashboardAgente from 'features/Dashboard'
import BymaHome from 'features/Home'

import { withPermissions } from 'core'
import * as BymaPermissions from 'services/auth/bymaPermissions'
import HistorialColocaciones from 'features/HistorialColocaciones/HistorialColocaciones'
import Emisores from 'features/Admin/Emisores'
import Colocadores from './features/Admin/Colocadores'
import GrupoEconomico from './features/Admin/GrupoEconomico'
import Inversor from './features/Admin/Inversores'
import Paises from './features/Admin/Paises'
import Colocacion from 'features/Colocacion'
import { routes } from 'routes'

export default function AppRoutes() {
  return (
    <Routes>
      <Route
        path={routes.dashboard}
        element={
          <ProtectedRoute>
            <DashboardAgente />
          </ProtectedRoute>
        }
      />

      <Route
        path={routes.home}
        element={
          <ProtectedRoute>
            <BymaHome />
          </ProtectedRoute>
        }
      />

      <Route
        path={routes.historial_colocaciones}
        element={
          <ProtectedRoute>
            <HistorialColocaciones />
          </ProtectedRoute>
        }
      />

      {withPermissions(
        <Route
          path={routes.colocaciones_new}
          element={
            <ProtectedRoute>
              <Colocacion.create />
            </ProtectedRoute>
          }
        />,
        BymaPermissions.COLOCACION_CREATE_PERMISSION,
      )}

      <Route
        path={routes.colocaciones_view}
        element={
          <ProtectedRoute>
            <Colocacion.edit />
          </ProtectedRoute>
        }
      >
        {Colocacion.routes}
      </Route>

      <Route
        path={routes.colocadores}
        element={
          <ProtectedRoute>
            <Colocadores.list />
          </ProtectedRoute>
        }
      />

      <Route
        path={routes.agentes}
        element={
          <ProtectedRoute>
            <Agentes.list />
          </ProtectedRoute>
        }
      />

      <Route
        path={routes.emisores}
        element={
          <ProtectedRoute>
            <Emisores.list />
          </ProtectedRoute>
        }
      />

      <Route
        path={routes.grupos_economicos}
        element={
          <ProtectedRoute>
            <GrupoEconomico.list />
          </ProtectedRoute>
        }
      />

      <Route
        path={routes.fci_ssn}
        element={
          <ProtectedRoute>
            <Inversor.list />
          </ProtectedRoute>
        }
      />

      {
        <Route
          path={routes.nacionalidades}
          element={
            <ProtectedRoute>
              <Paises.list />
            </ProtectedRoute>
          }
        />
      }

      <Route
        path={routes.roles}
        element={
          <ProtectedRoute>
            <Roles.edit />
          </ProtectedRoute>
        }
      />

      <Route
        path={routes.help}
        element={
          <ProtectedRoute>
            <Help.list />
          </ProtectedRoute>
        }
      />

      {withPermissions(
        <Route
          path={routes.help_edit}
          element={
            <ProtectedRoute>
              <Help.edit />
            </ProtectedRoute>
          }
        />,
        BymaPermissions.AYUDA_EDIT_PERMISSIONS,
      )}

      <Route
        path={routes.change_password}
        element={
          <ProtectedRoute>
            <ChangePassword />
          </ProtectedRoute>
        }
      />

      <Route
        path={routes.change_password_expired}
        element={<ChangePassword expiredPassword={true} />}
      />
    </Routes>
  )
}

import AxiosMockAdapter from 'axios-mock-adapter-path-params'

export const rolesResponse = [
  {
    id: 1,
    codigo: 'COLP_CONTROL',
    denominacion: 'Rol Interno - Procesos de Control',
    permisos: [],
  },
  {
    id: 4,
    codigo: 'COLP_COLOCADOR_PLUS',
    denominacion: 'Colocador Plus',
    permisos: [],
  },
  {
    id: 8,
    codigo: 'COLP_AGENTE',
    denominacion: 'Agente oferente',
    permisos: [
      {
        id: 4,
        codigo: 'colocacion:list:ingresada',
        denominacion: 'Listar todas las Colocaciones Ingresadas',
      },
      {
        id: 7,
        codigo: 'oferta:create:propia',
        denominacion: 'Crear Ofertas Propias',
      },
    ],
  },
  {
    id: 3,
    codigo: 'COLP_COLOCADOR',
    denominacion: 'Colocador',
    permisos: [
      {
        id: 3,
        codigo: 'colocacion:list:colocador',
        denominacion: 'Listar Colocaciones del Colocador o Co-Colocador',
      },
    ],
  },
  {
    id: 5,
    codigo: 'COLP_INGRESANTE',
    denominacion: 'Ingresante',
    permisos: [
      {
        id: 1,
        codigo: 'colocacion:list:all',
        denominacion: 'Listar todas las Colocaciones',
      },
    ],
  },
  {
    id: 6,
    codigo: 'COLP_CNV',
    denominacion: 'CNV',
    permisos: [
      {
        id: 4,
        codigo: 'colocacion:list:ingresada',
        denominacion: 'Listar todas las Colocaciones Ingresadas',
      },
    ],
  },
  {
    id: 2,
    codigo: 'COLP_MONITOREO',
    denominacion: 'Monitoreo',
    permisos: [
      {
        id: 4,
        codigo: 'colocacion:list:ingresada',
        denominacion: 'Listar todas las Colocaciones Ingresadas',
      },
    ],
  },
  {
    id: 9,
    codigo: 'COLP_ADMIN',
    denominacion: 'Administrador de Aplicacion',
    permisos: [
      {
        id: 5,
        codigo: 'rol:list',
        denominacion: 'Listar Roles',
      },
      {
        id: 6,
        codigo: 'rol:write',
        denominacion: 'Modificar Roles',
      },
    ],
  },
  {
    id: 7,
    codigo: 'COLP_AUDITORIA',
    denominacion: 'Auditoria',
    permisos: [
      {
        id: 4,
        codigo: 'colocacion:list:ingresada',
        denominacion: 'Listar todas las Colocaciones Ingresadas',
      },
      {
        id: 5,
        codigo: 'rol:list',
        denominacion: 'Listar Roles',
      },
    ],
  },
]

export const getRolResponse = (id: number) => {
  const roles = rolesResponse.filter((col) => col.id == id)
  return roles !== undefined && roles.length > 0 ? roles[0] : {}
}

export const getRolesExportResponse = () => {
  const blob = new Blob([''])
  return blob
}

export const mockRoles = (mock: AxiosMockAdapter) => {
  mock.onGet('/api/roles').reply(() => {
    return [200, rolesResponse]
  })

  mock.onGet('/api/roles/:id').reply((config: any) => {
    const { id } = config.routeParams
    return [200, getRolResponse(id)]
  })

  mock.onPut('/api/roles/:id').reply((config: any) => {
    const { id } = config.routeParams
    return [200, getRolResponse(id)]
  })

  mock.onGet('/api/roles/export').reply((config: any) => {
    return [
      200,
      getRolesExportResponse(),
      {
        'content-disposition': 'filename="roles.csv"',
        'content-type': 'application/csv',
      },
    ]
  })
}

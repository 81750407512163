import AxiosMockAdapter from 'axios-mock-adapter-path-params'

export const paisesResponse = [
  {
    id: 89160,
    denominacion: 'AFGANISTAN',
    codigo: '301',
  },
  {
    id: 89214,
    denominacion: 'ALBANIA',
    codigo: '401',
  },
  {
    id: 89215,
    denominacion: 'ALEMANIA FEDERAL',
    codigo: '402',
  },
  {
    id: 89216,
    denominacion: 'ALEMANIA ORIENTAL',
    codigo: '403',
  },
  {
    id: 89250,
    denominacion: 'ALEMANIA,REP.FED.',
    codigo: '438',
  },
  {
    id: 89217,
    denominacion: 'ANDORRA',
    codigo: '404',
  },
  {
    id: 89071,
    denominacion: 'ANGOLA',
    codigo: '149',
  },
  {
    id: 89292,
    denominacion: 'ANGUILA (TERRITORIO NO AUTONOMO DEL R. UNIDO)',
    codigo: '652',
  },
  {
    id: 89122,
    denominacion: 'ANTIGUA Y BARBUDA',
    codigo: '237',
  },
  {
    id: 89126,
    denominacion: 'ANTILLAS HOLANDESAS',
    codigo: '241',
  },
  {
    id: 89293,
    denominacion: 'ANTILLAS HOLANDESAS (TERRITORIO DE PAISES BAJOS)',
    codigo: '659',
  },
  {
    id: 89161,
    denominacion: 'ARABIA SAUDITA',
    codigo: '302',
  },
  {
    id: 89326,
    denominacion: 'ARCHIPIELAGO DE SVBALBARD',
    codigo: '696',
  },
  {
    id: 89026,
    denominacion: 'ARGELIA',
    codigo: '102',
  },
  {
    id: 89085,
    denominacion: 'ARGENTINA',
    codigo: '200',
  },
  {
    id: 89202,
    denominacion: 'ARMENIA',
    codigo: '349',
  },
  {
    id: 89127,
    denominacion: 'ARUBA',
    codigo: '242',
  },
  {
    id: 89294,
    denominacion: 'ARUBA (TERRITORIO DE PAISES BAJOS)',
    codigo: '653',
  },
  {
    id: 89295,
    denominacion: 'ASCENCION',
    codigo: '662',
  },
  {
    id: 89269,
    denominacion: 'AUSTRALIA',
    codigo: '501',
  },
  {
    id: 89218,
    denominacion: 'AUSTRIA',
    codigo: '405',
  },
  {
    id: 89203,
    denominacion: 'AZERBAIJAN',
    codigo: '350',
  },
  {
    id: 89124,
    denominacion: 'BAHAMAS',
    codigo: '239',
  },
  {
    id: 89162,
    denominacion: 'BAHREIN',
    codigo: '303',
  },
  {
    id: 89199,
    denominacion: 'BANGLADESH',
    codigo: '345',
  },
  {
    id: 89086,
    denominacion: 'BARBADOS',
    codigo: '201',
  },
  {
    id: 89219,
    denominacion: 'BELGICA',
    codigo: '406',
  },
  {
    id: 89121,
    denominacion: 'BELICE',
    codigo: '236',
  },
  {
    id: 89035,
    denominacion: 'BENIN',
    codigo: '112',
  },
  {
    id: 89296,
    denominacion: 'BERMUDAS (TERRITORIO NO AUTONOMO DEL R UNIDO)',
    codigo: '663',
  },
  {
    id: 89251,
    denominacion: 'BIELORRUSIA',
    codigo: '439',
  },
  {
    id: 89087,
    denominacion: 'BOLIVIA',
    codigo: '202',
  },
  {
    id: 89258,
    denominacion: 'BOSNIA HERZEGOVINA',
    codigo: '446',
  },
  {
    id: 89027,
    denominacion: 'BOTSWANA',
    codigo: '103',
  },
  {
    id: 89088,
    denominacion: 'BRASIL',
    codigo: '203',
  },
  {
    id: 89200,
    denominacion: 'BRUNEI',
    codigo: '346',
  },
  {
    id: 89220,
    denominacion: 'BULGARIA',
    codigo: '407',
  },
  {
    id: 89025,
    denominacion: 'BURKINA FASO',
    codigo: '101',
  },
  {
    id: 89028,
    denominacion: 'BURUNDI',
    codigo: '104',
  },
  {
    id: 89164,
    denominacion: 'BUTAN',
    codigo: '305',
  },
  {
    id: 89072,
    denominacion: 'CABO VERDE',
    codigo: '150',
  },
  {
    id: 89165,
    denominacion: 'CAMBODYA(EX-KAMPUCHE',
    codigo: '306',
  },
  {
    id: 89029,
    denominacion: 'CAMERUN',
    codigo: '105',
  },
  {
    id: 89297,
    denominacion: 'CAMPIONE DITALIA',
    codigo: '664',
  },
  {
    id: 89089,
    denominacion: 'CANADA',
    codigo: '204',
  },
  {
    id: 89034,
    denominacion: 'CHAD',
    codigo: '111',
  },
  {
    id: 89221,
    denominacion: 'CHECOSLOVAQUIA',
    codigo: '408',
  },
  {
    id: 89093,
    denominacion: 'CHILE',
    codigo: '208',
  },
  {
    id: 89169,
    denominacion: 'CHINA',
    codigo: '310',
  },
  {
    id: 89170,
    denominacion: 'CHIPRE',
    codigo: '311',
  },
  {
    id: 89248,
    denominacion: 'CHIPRE',
    codigo: '435',
  },
  {
    id: 89090,
    denominacion: 'COLOMBIA',
    codigo: '205',
  },
  {
    id: 89298,
    denominacion: 'COLONIA DE GIBRALTAR',
    codigo: '665',
  },
  {
    id: 89076,
    denominacion: 'COMORAS',
    codigo: '155',
  },
  {
    id: 89031,
    denominacion: 'CONGO',
    codigo: '108',
  },
  {
    id: 89167,
    denominacion: 'COREA DEMOCRATICA',
    codigo: '308',
  },
  {
    id: 89168,
    denominacion: 'COREA REPUBLICANA',
    codigo: '309',
  },
  {
    id: 89033,
    denominacion: 'COSTA DE MARFIL',
    codigo: '110',
  },
  {
    id: 89091,
    denominacion: 'COSTA RICA',
    codigo: '206',
  },
  {
    id: 89259,
    denominacion: 'CROACIA',
    codigo: '447',
  },
  {
    id: 89092,
    denominacion: 'CUBA',
    codigo: '207',
  },
  {
    id: 89222,
    denominacion: 'DINAMARCA',
    codigo: '409',
  },
  {
    id: 89075,
    denominacion: 'DJIBOUTI',
    codigo: '153',
  },
  {
    id: 89118,
    denominacion: 'DOMINICA',
    codigo: '233',
  },
  {
    id: 89094,
    denominacion: 'DOMINICANA,REP.',
    codigo: '209',
  },
  {
    id: 89095,
    denominacion: 'ECUADOR',
    codigo: '210',
  },
  {
    id: 89036,
    denominacion: 'EGIPTO',
    codigo: '113',
  },
  {
    id: 89096,
    denominacion: 'EL SALVADOR',
    codigo: '211',
  },
  {
    id: 89190,
    denominacion: 'EMIRATOS ARABES,UNID',
    codigo: '331',
  },
  {
    id: 89081,
    denominacion: 'ERITREA',
    codigo: '160',
  },
  {
    id: 89260,
    denominacion: 'ESLOVAQUIA',
    codigo: '448',
  },
  {
    id: 89261,
    denominacion: 'ESLOVENIA',
    codigo: '449',
  },
  {
    id: 89223,
    denominacion: 'ESPAï¿½A',
    codigo: '410',
  },
  {
    id: 89097,
    denominacion: 'ESTADOS UNIDOS',
    codigo: '212',
  },
  {
    id: 89252,
    denominacion: 'ESTONIA',
    codigo: '440',
  },
  {
    id: 89082,
    denominacion: 'ETIOPIA',
    codigo: '161',
  },
  {
    id: 89264,
    denominacion: 'FED. SER Y MONT YOGOE',
    codigo: '452',
  },
  {
    id: 89279,
    denominacion: 'FIJI, ISLAS',
    codigo: '512',
  },
  {
    id: 89171,
    denominacion: 'FILIPINAS',
    codigo: '312',
  },
  {
    id: 89224,
    denominacion: 'FINLANDIA',
    codigo: '411',
  },
  {
    id: 89225,
    denominacion: 'FRANCIA',
    codigo: '412',
  },
  {
    id: 89037,
    denominacion: 'GABON',
    codigo: '115',
  },
  {
    id: 89038,
    denominacion: 'GAMBIA',
    codigo: '116',
  },
  {
    id: 89173,
    denominacion: 'GAZA',
    codigo: '314',
  },
  {
    id: 89204,
    denominacion: 'GEORGIA',
    codigo: '351',
  },
  {
    id: 89039,
    denominacion: 'GHANA',
    codigo: '117',
  },
  {
    id: 89125,
    denominacion: 'GRANADA',
    codigo: '240',
  },
  {
    id: 89226,
    denominacion: 'GRECIA',
    codigo: '413',
  },
  {
    id: 89299,
    denominacion: 'GROENLANDIA',
    codigo: '666',
  },
  {
    id: 89300,
    denominacion: 'GUAM (TERRITORIO NO AUTONOMO DE LOS ESTADO UNIDOS',
    codigo: '667',
  },
  {
    id: 89098,
    denominacion: 'GUATEMALA',
    codigo: '213',
  },
  {
    id: 89040,
    denominacion: 'GUINEA',
    codigo: '118',
  },
  {
    id: 89077,
    denominacion: 'GUINEA BISSAU',
    codigo: '156',
  },
  {
    id: 89041,
    denominacion: 'GUINEA ECUATORIAL',
    codigo: '119',
  },
  {
    id: 89099,
    denominacion: 'GUYANA',
    codigo: '214',
  },
  {
    id: 89100,
    denominacion: 'HAITI',
    codigo: '215',
  },
  {
    id: 89247,
    denominacion: 'HOLANDA',
    codigo: '434',
  },
  {
    id: 89101,
    denominacion: 'HONDURAS',
    codigo: '216',
  },
  {
    id: 89197,
    denominacion: 'HONG KONG',
    codigo: '341',
  },
  {
    id: 89301,
    denominacion: 'HONG KONG (TERRITORIO DE CHINA)',
    codigo: '668',
  },
  {
    id: 89227,
    denominacion: 'HUNGRIA',
    codigo: '414',
  },
  {
    id: 89213,
    denominacion: 'INDET.(ASIA)',
    codigo: '398',
  },
  {
    id: 89332,
    denominacion: 'INDET.(CONTINENTE)',
    codigo: '998',
  },
  {
    id: 89268,
    denominacion: 'INDET.(EUROPA)',
    codigo: '498',
  },
  {
    id: 89290,
    denominacion: 'INDET.(OCEANIA)',
    codigo: '598',
  },
  {
    id: 89084,
    denominacion: 'INDETERMINADO (AFRICA)',
    codigo: '198',
  },
  {
    id: 89159,
    denominacion: 'INDETERMINADO.(AMERICA)',
    codigo: '298',
  },
  {
    id: 89174,
    denominacion: 'INDIA',
    codigo: '315',
  },
  {
    id: 89175,
    denominacion: 'INDONESIA',
    codigo: '316',
  },
  {
    id: 89176,
    denominacion: 'IRAK',
    codigo: '317',
  },
  {
    id: 89177,
    denominacion: 'IRAN',
    codigo: '318',
  },
  {
    id: 89228,
    denominacion: 'IRLANDA',
    codigo: '415',
  },
  {
    id: 89310,
    denominacion: 'ISALAS TURKAS Y CAICOS (TERRITORIO NO AUTONOMO DEL REINO UNIDO)',
    codigo: '678',
  },
  {
    id: 89305,
    denominacion: 'ISLA CHRISTMAS',
    codigo: '672',
  },
  {
    id: 89306,
    denominacion: 'ISLA DE COCOS O KEELING',
    codigo: '673',
  },
  {
    id: 89307,
    denominacion: 'ISLA DE COOK (TERRITORIO AUTONOMO ASOCIADO A NUEVA ZELANDA)',
    codigo: '654',
  },
  {
    id: 89308,
    denominacion: 'ISLA DE MAN (TERRITORIO DEL REINO UNIDO)',
    codigo: '676',
  },
  {
    id: 89309,
    denominacion: 'ISLA DE NORFOLK (TERRITORIO DEL R UNIDO)',
    codigo: '677',
  },
  {
    id: 89313,
    denominacion: 'ISLA QESHM',
    codigo: '681',
  },
  {
    id: 89229,
    denominacion: 'ISLANDIA',
    codigo: '416',
  },
  {
    id: 89302,
    denominacion: 'ISLAS AZORES',
    codigo: '669',
  },
  {
    id: 89304,
    denominacion: 'ISLAS CAIMAN (TERRITORIO NO AUTONOMO DE R UNIDO)',
    codigo: '671',
  },
  {
    id: 89312,
    denominacion: 'ISLAS DE SAN PEDRO Y MIGUELON',
    codigo: '680',
  },
  {
    id: 89303,
    denominacion: 'ISLAS DEL CANAL (GUERNESEY, JERSEY, ALDERNEY,G.STARK, L.SARK, ETC)',
    codigo: '670',
  },
  {
    id: 89132,
    denominacion: 'ISLAS MALVINAS',
    codigo: '254',
  },
  {
    id: 89311,
    denominacion: 'ISLAS PACIFICO',
    codigo: '679',
  },
  {
    id: 89314,
    denominacion: 'ISLAS VIRGENES BRITANICAS (TERRITORIO NO AUTONOMO DEL REINO UNIDO)',
    codigo: '682',
  },
  {
    id: 89315,
    denominacion: 'ISLAS VIRGENES DE ESTADOS UNIDOS DE AMERICA',
    codigo: '683',
  },
  {
    id: 89178,
    denominacion: 'ISRAEL',
    codigo: '319',
  },
  {
    id: 89230,
    denominacion: 'ITALIA',
    codigo: '417',
  },
  {
    id: 89102,
    denominacion: 'JAMAICA',
    codigo: '217',
  },
  {
    id: 89179,
    denominacion: 'JAPON',
    codigo: '320',
  },
  {
    id: 89180,
    denominacion: 'JORDANIA',
    codigo: '321',
  },
  {
    id: 89205,
    denominacion: 'KAZAJSTAN',
    codigo: '352',
  },
  {
    id: 89042,
    denominacion: 'KENYA',
    codigo: '120',
  },
  {
    id: 89206,
    denominacion: 'KIRGUIZISTAN',
    codigo: '353',
  },
  {
    id: 89281,
    denominacion: 'KIRIBATI, ISLAS',
    codigo: '514',
  },
  {
    id: 89182,
    denominacion: 'KUWAIT',
    codigo: '323',
  },
  {
    id: 89316,
    denominacion: 'LABUAM',
    codigo: '684',
  },
  {
    id: 89183,
    denominacion: 'LAOS',
    codigo: '324',
  },
  {
    id: 181126,
    denominacion: 'Laponia',
    codigo: 'Lapones',
  },
  {
    id: 89043,
    denominacion: 'LESOTHO',
    codigo: '121',
  },
  {
    id: 89253,
    denominacion: 'LETONIA',
    codigo: '441',
  },
  {
    id: 89184,
    denominacion: 'LIBANO',
    codigo: '325',
  },
  {
    id: 89044,
    denominacion: 'LIBERIA',
    codigo: '122',
  },
  {
    id: 89045,
    denominacion: 'LIBIA',
    codigo: '123',
  },
  {
    id: 89231,
    denominacion: 'LIECHTENSTEIN',
    codigo: '418',
  },
  {
    id: 89254,
    denominacion: 'LITUANIA',
    codigo: '442',
  },
  {
    id: 89232,
    denominacion: 'LUXEMBURGO',
    codigo: '419',
  },
  {
    id: 89198,
    denominacion: 'MACAO',
    codigo: '344',
  },
  {
    id: 89262,
    denominacion: 'MACEDONIA',
    codigo: '450',
  },
  {
    id: 89046,
    denominacion: 'MADAGASCAR',
    codigo: '124',
  },
  {
    id: 89317,
    denominacion: 'MADEIRA (TERRITORIO DE PORTUGAL)',
    codigo: '685',
  },
  {
    id: 89185,
    denominacion: 'MALASIA',
    codigo: '326',
  },
  {
    id: 89047,
    denominacion: 'MALAWI',
    codigo: '125',
  },
  {
    id: 89186,
    denominacion: 'MALDIVAS ISLAS',
    codigo: '327',
  },
  {
    id: 89048,
    denominacion: 'MALI',
    codigo: '126',
  },
  {
    id: 89233,
    denominacion: 'MALTA',
    codigo: '420',
  },
  {
    id: 89156,
    denominacion: 'MAR ARG ZONA ECO.EX',
    codigo: '295',
  },
  {
    id: 89288,
    denominacion: 'MARIANAS,ISLAS',
    codigo: '521',
  },
  {
    id: 89049,
    denominacion: 'MARRUECOS',
    codigo: '127',
  },
  {
    id: 89287,
    denominacion: 'MARSHALL,ISLAS',
    codigo: '520',
  },
  {
    id: 89050,
    denominacion: 'MAURICIO,ISLAS',
    codigo: '128',
  },
  {
    id: 89051,
    denominacion: 'MAURITANIA',
    codigo: '129',
  },
  {
    id: 89103,
    denominacion: 'MEXICO',
    codigo: '218',
  },
  {
    id: 89282,
    denominacion: 'MICRONESIA,EST.FEDER',
    codigo: '515',
  },
  {
    id: 89255,
    denominacion: 'MOLDAVIA',
    codigo: '443',
  },
  {
    id: 89234,
    denominacion: 'MONACO',
    codigo: '421',
  },
  {
    id: 89188,
    denominacion: 'MONGOLIA',
    codigo: '329',
  },
  {
    id: 89318,
    denominacion: 'MONSERRAT (TERRITORIO NO AUTONOMO DEL REINO UNIDO)',
    codigo: '686',
  },
  {
    id: 89265,
    denominacion: 'MONTENEGRO',
    codigo: '453',
  },
  {
    id: 89073,
    denominacion: 'MOZAMBIQUE',
    codigo: '151',
  },
  {
    id: 89163,
    denominacion: 'MYANMAR(EX-BIRMANIA)',
    codigo: '304',
  },
  {
    id: 89079,
    denominacion: 'NAMIBIA',
    codigo: '158',
  },
  {
    id: 89270,
    denominacion: 'NAURU',
    codigo: '503',
  },
  {
    id: 89189,
    denominacion: 'NEPAL',
    codigo: '330',
  },
  {
    id: 89104,
    denominacion: 'NICARAGUA',
    codigo: '219',
  },
  {
    id: 89052,
    denominacion: 'NIGER',
    codigo: '130',
  },
  {
    id: 89053,
    denominacion: 'NIGERIA',
    codigo: '131',
  },
  {
    id: 89319,
    denominacion: 'NIUE ',
    codigo: '687',
  },
  {
    id: 89235,
    denominacion: 'NORUEGA',
    codigo: '422',
  },
  {
    id: 89271,
    denominacion: 'NUEVA ZELANDIA',
    codigo: '504',
  },
  {
    id: 89187,
    denominacion: 'OMAN',
    codigo: '328',
  },
  {
    id: 89333,
    denominacion: 'OTROS PAISES',
    codigo: '999',
  },
  {
    id: 89236,
    denominacion: 'PAISES BAJOS',
    codigo: '423',
  },
  {
    id: 89191,
    denominacion: 'PAKISTAN',
    codigo: '332',
  },
  {
    id: 89283,
    denominacion: 'PALAU',
    codigo: '516',
  },
  {
    id: 89105,
    denominacion: 'PANAMA',
    codigo: '220',
  },
  {
    id: 89280,
    denominacion: 'PAPUA NUEVA GUINEA',
    codigo: '513',
  },
  {
    id: 89106,
    denominacion: 'PARAGUAY',
    codigo: '221',
  },
  {
    id: 89320,
    denominacion: 'PATAU',
    codigo: '655',
  },
  {
    id: 89107,
    denominacion: 'PERU',
    codigo: '222',
  },
  {
    id: 89321,
    denominacion: 'PITCAIRN',
    codigo: '690',
  },
  {
    id: 89322,
    denominacion: 'POLINESI FRANCESA (TERRITORIO DE ULTRAMAR DE FRANCIA)',
    codigo: '656',
  },
  {
    id: 89237,
    denominacion: 'POLONIA',
    codigo: '424',
  },
  {
    id: 89238,
    denominacion: 'PORTUGAL',
    codigo: '425',
  },
  {
    id: 89246,
    denominacion: 'POS.BRIT.(EUROPA)',
    codigo: '433',
  },
  {
    id: 89108,
    denominacion: 'PUERTO RICO',
    codigo: '223',
  },
  {
    id: 89181,
    denominacion: 'QATAR',
    codigo: '322',
  },
  {
    id: 89323,
    denominacion: 'REGIMEN APLICABLE A LAS SA FINANCIERAS (LEY 11073 DE LA ROU)',
    codigo: '693',
  },
  {
    id: 89239,
    denominacion: 'REINO UNIDO',
    codigo: '426',
  },
  {
    id: 89030,
    denominacion: 'REP. CENTROAFRICANA.',
    codigo: '107',
  },
  {
    id: 89263,
    denominacion: 'REP. CHECA',
    codigo: '451',
  },
  {
    id: 89032,
    denominacion: 'REP.DEMOCRAT.DEL CONGO EX ZAIRE',
    codigo: '109',
  },
  {
    id: 89065,
    denominacion: 'REPUBLICA DE SUDAFRICA',
    codigo: '143',
  },
  {
    id: 89201,
    denominacion: 'REPUBLICA DE YEMEN',
    codigo: '348',
  },
  {
    id: 89083,
    denominacion: 'RESTO (AFRICA)',
    codigo: '197',
  },
  {
    id: 89158,
    denominacion: 'RESTO AMERICA',
    codigo: '297',
  },
  {
    id: 89331,
    denominacion: 'RESTO CONTINENTE',
    codigo: '997',
  },
  {
    id: 89212,
    denominacion: 'RESTO DE ASIA',
    codigo: '397',
  },
  {
    id: 89267,
    denominacion: 'RESTO EUROPA',
    codigo: '497',
  },
  {
    id: 89289,
    denominacion: 'RESTO OCEANIA',
    codigo: '597',
  },
  {
    id: 89157,
    denominacion: 'RIOS ARG NAVEG INTER',
    codigo: '296',
  },
  {
    id: 89240,
    denominacion: 'RUMANIA',
    codigo: '427',
  },
  {
    id: 89256,
    denominacion: 'RUSIA',
    codigo: '444',
  },
  {
    id: 89055,
    denominacion: 'RWANDA',
    codigo: '133',
  },
  {
    id: 89123,
    denominacion: 'S.CRISTOBAL Y NEVIS',
    codigo: '238',
  },
  {
    id: 89285,
    denominacion: 'SALOMON,ISLAS',
    codigo: '518',
  },
  {
    id: 89325,
    denominacion: 'SAMAO AMERICANA (TERRITORIO NO AUTONOMO DE LOS ESTADOS UNIDOS)',
    codigo: '695',
  },
  {
    id: 89273,
    denominacion: 'SAMOA OCCIDENTAL',
    codigo: '506',
  },
  {
    id: 89241,
    denominacion: 'SAN MARINO',
    codigo: '428',
  },
  {
    id: 89120,
    denominacion: 'SAN VICENTE Y LAS GRANADINS',
    codigo: '235',
  },
  {
    id: 89324,
    denominacion: 'SANTA ELENA',
    codigo: '694',
  },
  {
    id: 89119,
    denominacion: 'SANTA LUCIA',
    codigo: '234',
  },
  {
    id: 89143,
    denominacion: 'SECTOR ANTARTICO ARG.',
    codigo: '265',
  },
  {
    id: 89056,
    denominacion: 'SENEGAL',
    codigo: '134',
  },
  {
    id: 89266,
    denominacion: 'SERBIA',
    codigo: '454',
  },
  {
    id: 89074,
    denominacion: 'SEYCHELLES',
    codigo: '152',
  },
  {
    id: 89057,
    denominacion: 'SIERRA LEONA',
    codigo: '135',
  },
  {
    id: 89192,
    denominacion: 'SINGAPUR',
    codigo: '333',
  },
  {
    id: 89193,
    denominacion: 'SIRIA',
    codigo: '334',
  },
  {
    id: 89058,
    denominacion: 'SOMALIA',
    codigo: '136',
  },
  {
    id: 89166,
    denominacion: 'SRI LANKA',
    codigo: '307',
  },
  {
    id: 89078,
    denominacion: 'STO.TOME Y PRINCIPE',
    codigo: '157',
  },
  {
    id: 89080,
    denominacion: 'SUDAFRICA',
    codigo: '159',
  },
  {
    id: 89060,
    denominacion: 'SUDAN',
    codigo: '138',
  },
  {
    id: 89242,
    denominacion: 'SUECIA',
    codigo: '429',
  },
  {
    id: 89243,
    denominacion: 'SUIZA',
    codigo: '430',
  },
  {
    id: 89117,
    denominacion: 'SURINAME',
    codigo: '232',
  },
  {
    id: 89059,
    denominacion: 'SWAZILANDIA',
    codigo: '137',
  },
  {
    id: 89172,
    denominacion: 'TAIWAN',
    codigo: '313',
  },
  {
    id: 89061,
    denominacion: 'TANZANIA',
    codigo: '139',
  },
  {
    id: 89207,
    denominacion: 'TAYIKISTAN',
    codigo: '354',
  },
  {
    id: 89277,
    denominacion: 'TER VINCULADOS A NUEVA. ZELANDA',
    codigo: '510',
  },
  {
    id: 89278,
    denominacion: 'TER. VINCULADOS A ESTADOS UNIDOS',
    codigo: '511',
  },
  {
    id: 89113,
    denominacion: 'TER.VINCULADOS A DINAMARCA',
    codigo: '228',
  },
  {
    id: 89116,
    denominacion: 'TER.VINCULADOS A ESTADOS UNIDOS',
    codigo: '231',
  },
  {
    id: 89210,
    denominacion: 'TERR. AU. PALESTINOS',
    codigo: '357',
  },
  {
    id: 89115,
    denominacion: 'TERRIT. HOLANDESES',
    codigo: '230',
  },
  {
    id: 89112,
    denominacion: 'TERRIT.VINCULADO AL R.UNIDO',
    codigo: '227',
  },
  {
    id: 89068,
    denominacion: 'TERRIT.VINCULADOS A ESPAï¿½A',
    codigo: '146',
  },
  {
    id: 89069,
    denominacion: 'TERRIT.VINCULADOS A FRANCIA',
    codigo: '147',
  },
  {
    id: 89114,
    denominacion: 'TERRIT.VINCULADOS A FRANCIA AMERIC.',
    codigo: '229',
  },
  {
    id: 89070,
    denominacion: 'TERRIT.VINCULADOS A PORTUGAL',
    codigo: '148',
  },
  {
    id: 89067,
    denominacion: 'TERRIT.VINCULADOS AL R UNIDO',
    codigo: '145',
  },
  {
    id: 89274,
    denominacion: 'TERRITORIO VINCULADOS A AUSTRALIA',
    codigo: '507',
  },
  {
    id: 89276,
    denominacion: 'TERRITORIOS VINCULADOS A FRANCIA',
    codigo: '509',
  },
  {
    id: 89275,
    denominacion: 'TERRITORIOS VINCULADOS AL R. UNIDO',
    codigo: '508',
  },
  {
    id: 89194,
    denominacion: 'THAILANDIA',
    codigo: '335',
  },
  {
    id: 89128,
    denominacion: 'TIERRA DEL FUEGO',
    codigo: '250',
  },
  {
    id: 89211,
    denominacion: 'TIMOR ORIENTAL',
    codigo: '358',
  },
  {
    id: 89062,
    denominacion: 'TOGO',
    codigo: '140',
  },
  {
    id: 89329,
    denominacion: 'TOKELAU',
    codigo: '699',
  },
  {
    id: 89286,
    denominacion: 'TONGA',
    codigo: '519',
  },
  {
    id: 89328,
    denominacion: 'TRIESTE (ITALIA)',
    codigo: '698',
  },
  {
    id: 89109,
    denominacion: 'TRINIDAD Y -TOBAGO',
    codigo: '224',
  },
  {
    id: 89327,
    denominacion: 'TRISTAN DACUNHA',
    codigo: '697',
  },
  {
    id: 89063,
    denominacion: 'TUNEZ',
    codigo: '141',
  },
  {
    id: 89208,
    denominacion: 'TURKMENISTAN',
    codigo: '355',
  },
  {
    id: 89249,
    denominacion: 'TURQUIA',
    codigo: '436',
  },
  {
    id: 89195,
    denominacion: 'TURQUIA',
    codigo: '336',
  },
  {
    id: 89284,
    denominacion: 'TUVALU',
    codigo: '517',
  },
  {
    id: 89257,
    denominacion: 'UCRANIA',
    codigo: '445',
  },
  {
    id: 89064,
    denominacion: 'UGANDA',
    codigo: '142',
  },
  {
    id: 89291,
    denominacion: 'URSS',
    codigo: '601',
  },
  {
    id: 89110,
    denominacion: 'URUGUAY',
    codigo: '225',
  },
  {
    id: 89209,
    denominacion: 'UZBEKISTAN',
    codigo: '356',
  },
  {
    id: 89272,
    denominacion: 'VANATU',
    codigo: '505',
  },
  {
    id: 89244,
    denominacion: 'VATICANO(SANTA SEDE)',
    codigo: '431',
  },
  {
    id: 89111,
    denominacion: 'VENEZUELA',
    codigo: '226',
  },
  {
    id: 89196,
    denominacion: 'VIETNAM',
    codigo: '337',
  },
  {
    id: 89245,
    denominacion: 'YUGOSLAVIA',
    codigo: '432',
  },
  {
    id: 89066,
    denominacion: 'ZAMBIA',
    codigo: '144',
  },
  {
    id: 89144,
    denominacion: 'ZF COLON',
    codigo: '270',
  },
  {
    id: 89146,
    denominacion: 'ZF COLONIA',
    codigo: '280',
  },
  {
    id: 89137,
    denominacion: 'ZF COMODORO RIVADAVIA',
    codigo: '259',
  },
  {
    id: 89134,
    denominacion: 'ZF CORDOBA',
    codigo: '256',
  },
  {
    id: 89147,
    denominacion: 'ZF FLORIDA',
    codigo: '281',
  },
  {
    id: 89136,
    denominacion: 'ZF GENERAL PICO',
    codigo: '258',
  },
  {
    id: 89138,
    denominacion: 'ZF IQUIQUE',
    codigo: '260',
  },
  {
    id: 89130,
    denominacion: 'ZF JUSTO DARACT',
    codigo: '252',
  },
  {
    id: 89129,
    denominacion: 'ZF LA PLATA',
    codigo: '251',
  },
  {
    id: 89148,
    denominacion: 'ZF LIBERTAD',
    codigo: '282',
  },
  {
    id: 89155,
    denominacion: 'ZF MANAOS',
    codigo: '291',
  },
  {
    id: 89135,
    denominacion: 'ZF MENDOZA',
    codigo: '257',
  },
  {
    id: 89150,
    denominacion: 'ZF NUEVA HELVECIA',
    codigo: '284',
  },
  {
    id: 89151,
    denominacion: 'ZF NUEVA PALMIRA',
    codigo: '285',
  },
  {
    id: 89141,
    denominacion: 'ZF PASO DE LOS LIBRES',
    codigo: '263',
  },
  {
    id: 89142,
    denominacion: 'ZF PUERTO IGUAZU',
    codigo: '264',
  },
  {
    id: 89139,
    denominacion: 'ZF PUNTA ARENAS',
    codigo: '261',
  },
  {
    id: 89131,
    denominacion: 'ZF RIO GALLEGOS',
    codigo: '253',
  },
  {
    id: 89152,
    denominacion: 'ZF RIO NEGRO',
    codigo: '286',
  },
  {
    id: 89153,
    denominacion: 'ZF RIVERA',
    codigo: '287',
  },
  {
    id: 89140,
    denominacion: 'ZF SALTA',
    codigo: '262',
  },
  {
    id: 89154,
    denominacion: 'ZF SAN JOSE',
    codigo: '288',
  },
  {
    id: 89133,
    denominacion: 'ZF TUCUMAN',
    codigo: '255',
  },
  {
    id: 89145,
    denominacion: 'ZF WINNER (STA. C.DE LA SIERRA',
    codigo: '271',
  },
  {
    id: 89149,
    denominacion: 'ZF ZONAMERICA',
    codigo: '283',
  },
  {
    id: 89054,
    denominacion: 'ZIMBABWE',
    codigo: '132',
  },
  {
    id: 89330,
    denominacion: 'ZONA LIBRE DE OSTRAVA (CIUDAD DE LA ATIGUA CHECOSLOVAQUIA)',
    codigo: '700',
  },
  {
    id: 214789,
    denominacion: 'zurinam',
    codigo: '9988',
  },
]

export const getPaisResponse = (id: number) => {
  const entities = paisesResponse.filter((col) => col.id == id)
  return entities !== undefined && entities.length > 0 ? entities[0] : {}
}

export const getPaisesExportResponse = () => {
  const blob = new Blob([''])
  return blob
}

export const mockPaises = (mock: AxiosMockAdapter) => {
  mock.onGet('/api/paises').reply(() => {
    return [200, paisesResponse]
  })

  mock.onGet('/api/paises/options').reply(() => {
    return [200, paisesResponse]
  })

  mock.onGet('/api/paises/:id').reply((config: any) => {
    const { id } = config.routeParams
    return [200, getPaisResponse(id)]
  })

  mock.onPost('/api/paises').reply((config: any) => {
    const { id } = config.routeParams
    return [200, getPaisResponse(id)]
  })

  mock.onDelete('/api/paises/:id').reply((config: any) => {
    const { id } = config.routeParams
    return [200, {}]
  })

  mock.onPut('/api/paises/:id').reply((config: any) => {
    const { id } = config.routeParams
    return [200, getPaisResponse(id)]
  })

  mock.onGet('/api/paises/export').reply(() => {
    return [
      200,
      getPaisesExportResponse(),
      {
        'content-disposition': 'filename="nacionalidades.csv"',
        'content-type': 'application/csv',
      },
    ]
  })
}

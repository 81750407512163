import React from 'react'
import { FormProvider } from 'react-hook-form'
import { Form } from 'react-bootstrap'

import ColocacionesApi from 'model/colocacion/ColocacionesApi'

import { AgenteOptionsQueryProps } from 'model'

import {
  ColocacionEditProps,
  useColocacionEditContext,
  ColocacionSectionType,
} from '../../../ColocacionViewContext'

import {
  AutorizacionSectionFieldsProps,
  AutorizacionSectionFields,
  AutorizacionSectionFieldName,
  useAutorizacionSectionForm,
} from '../AutorizacionSectionCommons'

import { Agente } from 'model'

const AgentesSectionFields = (props: ColocacionEditProps) => {
  const sectionProps: AutorizacionSectionFieldsProps<Agente> = {
    ...props,

    section: ColocacionSectionType.AGENTES,

    cardTitle: 'Autorización de Agentes',

    dataProvider: (id) => ColocacionesApi.getAgentesAutorizados(id),

    optionsParams: AgenteOptionsQueryProps,

    dualListProps: {
      labelAvailable: 'Autorizados',
      labelSelected: 'Autorizados',
      labels: {
        availableFilterHeader: 'Buscar',
        selectedFilterHeader: 'Buscar',
        availableHeader: 'NO AUTORIZADOS',
        selectedHeader: 'AUTORIZADOS',
        moveLeft: '< Quitar',
        moveRight: 'Agregar >',
        moveLeftTooltip: 'Quitar agente',
        moveRightTooltip: 'Agregar agente',
      },
    },
  }
  return <AutorizacionSectionFields {...sectionProps} />
}

const AgentesSectionComponent = (props: ColocacionEditProps) => {
  console.log(`DEBUG AgentesSection props=${JSON.stringify(props)}`)

  const { useFormReturn, onSubmit } = useAutorizacionSectionForm({
    schemaProvider: (yup) =>
      yup.object().shape({
        [AutorizacionSectionFieldName]: yup
          .array()
          .min(1, 'Informar al menos un agente')
          .required('Informar al menos un agente'),
      }),

    successNotification: 'Se actualizaron los Agentes',

    errorNotification: 'Error actualizando Agentes',

    mutation: (id, autorizaciones) => ColocacionesApi.updateAgentesAutorizados(id, autorizaciones),

    ...props,
  })

  return (
    <FormProvider {...useFormReturn}>
      <Form id='colocacion-agentes-edit' onSubmit={useFormReturn.handleSubmit(onSubmit)}>
        <AgentesSectionFields {...props} />
      </Form>
    </FormProvider>
  )
}

export const AgentesSection = () => {
  return <AgentesSectionComponent {...useColocacionEditContext()} />
}

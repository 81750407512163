import React from 'react'
import BymaOverlay from './BymaOverlay'

import './BymaOverlay.scss'
import { ErrorIcon, InfoIcon } from '..'

interface TooltipProps {
  text: string | string[]
  icon?: 'after' | 'before'
  type?: 'info' | 'error' | 'warning'
}

const BymaTooltip = (props: TooltipProps) => {
  const {
    text,
    icon = 'before',
    type = 'info'
  } = props

  if (type === 'error') {
    //const className = icon === 'before' ? 'byma-tooltip-custom-b' : 'byma-tooltip-custom-a'

    return (
      <BymaOverlay text={text}>
        <img src={ErrorIcon} />
      </BymaOverlay>
    )
  } else {
    const className = icon === 'before' ? 'byma-tooltip-image-b' : 'byma-tooltip-image-a'

    return (
      <BymaOverlay text={text}>
        {/*<img src={InfoIcon} />*/}
        <span className={className}  />
      </BymaOverlay>
    )
  }
}

export default BymaTooltip

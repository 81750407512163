import React from 'react'

import { TextInput, BymaFormCard, LocalErrorAlert, NumberInput, BymaSelect } from 'components'

import { Col, Row } from 'react-bootstrap'

import { EntityModalProps, EntityModal, DefaultEntitModalMessages } from 'features/common/Entity'
import { TituloIntegracion } from 'model/integracion'
import { TipoRedondeoOptions } from 'model'

const IntegracionSchemaProperties = (yup) =>
  yup.object().shape({
    denominacion: yup.string().required(),
    laminaMinima: yup.number().required(),
    cotizacion: yup.number().required(),
    cantidadMinima: yup.number().required(),
    tipoRedondeo: yup.string().required(),
  })

const IntegracionForm = ({ cardTitle, editEnabled }) => {
  const readOny = !editEnabled

  return (
    <>
      <BymaFormCard title={cardTitle}>
        <Row sm={12}>
          <Col sm={12}>
            <TextInput
              name='denominacion'
              required={true}
              readOnly={readOny}
              label='Denominación Integracion'
            />
          </Col>
        </Row>

        <Row sm={12}>
          <Col sm={4}>
            <NumberInput
              name='cotizacion'
              label={'Cotización'}
              type='number'
              readOnly={readOny}
              required={true}
            />
          </Col>
          <Col sm={4}>
            <NumberInput
              name='cantidadMinima'
              label={'Cantidad mínina'}
              type='number'
              required={true}
              readOnly={readOny}
            />
          </Col>
          <Col sm={4}>
            <NumberInput
              name='laminaMinima'
              label={'Lamina mínina'}
              type='number'
              required={true}
              readOnly={readOny}
            />
          </Col>
        </Row>

        <Row sm={12}>
          <Col sm={6}>
            <BymaSelect
              name='tipoRedondeo'
              label='Tipo redondeo'
              modalMenu
              required={true}
              readOnly={readOny}
              options={TipoRedondeoOptions}
            />
          </Col>
          <Col sm={6}>
            <TextInput
              name='aliasSiopel'
              required={true}
              readOnly={readOny}
              label='Alias Código SIOPEL'
            />
          </Col>
        </Row>
      </BymaFormCard>

      <div style={{ marginTop: '1em' }}>
        <LocalErrorAlert />
      </div>
    </>
  )
}

const IntegracionModal = (props: EntityModalProps<TituloIntegracion>) => {
  const editEnabled = props.context.editEnabled

  return (
    <EntityModal
      {...props}
      focusField='denominacion'
      getSchema={IntegracionSchemaProperties}
      showDelete={false}
      messages={{ ...DefaultEntitModalMessages, newTitle: 'entity.newTitleFem' }}
      renderEditForm={(props) =>
        IntegracionForm({
          ...props,
          cardTitle: editEnabled ? 'Editar Integración' : 'Detalle Integración',
          editEnabled,
        })
      }
      renderCreateForm={(props) =>
        IntegracionForm({
          ...props,
          cardTitle: 'Nueva Integración',
          editEnabled,
        })
      }
    />
  )
}

export default IntegracionModal

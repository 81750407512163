import React, { useCallback, useState } from 'react'

import {
  AllMatchIcon,
  BymaCard,
  BymaDataTable,
  BymaExportButton,
  BymaTextInputFilter,
} from 'components'
import { ColocacionSectionCard } from '../sections/ColocacionSectionCard'
import { ColocacionSummary } from '../ColocacionSummary'
import { ColocacionViewContext, useColocacionEditContext } from '../ColocacionViewContext'
import {
  OFERTA_ENTITY_NAME,
  TitulosApi,
  ofertaCantidadFormatter,
  ofertaValorFormatter,
  tituloModelToFormData,
} from 'model'

import styles from './ColocacionBoard.module.scss'
import { ListBase, useListContext, useNotificationProducer } from 'core'
import { FiltersHeader } from 'features/common/Filters'

interface OfertasPorValorProps {
  context: ColocacionViewContext
}

/**
 * Wrapper para proveer paginado local a partir de un resultado completo del backend
 *
 * @param dataProvider
 * @returns
 */
function useLocalPaginationDataProvider<T = any>(dataProvider) {
  //TODO por ahora disparamos la consulta cada vez que se solicita la pagina cero
  //Optimizacion: agregar un parametro que indique cuando hay que relanzar la consulta

  const [data, setData] = useState<T | undefined>(undefined)

  return (params) => {
    const { page, perPage } = params.pagination

    const pageIndex = page - 1

    const getPage = (result) => {
      //console.log(`DEBUG localPaginationDataProvider params=${JSON.stringify(params)}`)
      //console.log(`DEBUG localPaginationDataProvider result.length=${result.length} result=${JSON.stringify(result)}`)

      return {
        page: pageIndex,
        pageSize: perPage,
        total: result.length,
        content: result.slice(pageIndex * perPage, (pageIndex + 1) * perPage),
      }
    }

    if (data === undefined) {
      return dataProvider(params).then((result) => {
        setData(result)
        return getPage(result)
      })
    } else {
      return Promise.resolve(getPage(data))
    }
  }
}

const OFERTAS_POR_VALOR_CLASSIFIER = 'colocacionBoard.ofertasPorValor '
const LIST_KEY = [OFERTA_ENTITY_NAME, OFERTAS_POR_VALOR_CLASSIFIER]

const OfertasPorValorTable = (props: OfertasPorValorProps) => {
  const { addNotification } = useNotificationProducer()
  const context = props.context

  const defaultCellWidth = 240
  const defaultNumberAlign = 'left'
  const defaultNumberHeaderAlign = 'left'

  const ofertasPorValorColumns = [
    {
      field: 'valor',
      headerName: 'Precio',
      suppressMenu: false,
      textAlign: defaultNumberAlign,
      headerTextAlign: defaultNumberHeaderAlign,
      valueFormatter: (params) => ofertaValorFormatter(params?.data?.valor),

      maxWidth: 180,
      flex: 1,
    },
    {
      field: 'cantidad',
      headerName: 'Cantidad de ofertas',
      suppressMenu: false,
      textAlign: defaultNumberAlign,
      headerTextAlign: defaultNumberHeaderAlign,
      valueFormatter: (params) => ofertaCantidadFormatter(params?.data?.cantidad),

      maxWidth: 180,
      flex: 1,
    },
    {
      field: 'monto',
      headerName: 'Monto Total',
      suppressMenu: false,
      textAlign: defaultNumberAlign,
      headerTextAlign: defaultNumberHeaderAlign,
      valueFormatter: (params) => ofertaCantidadFormatter(params?.data?.monto),

      maxWidth: defaultCellWidth,
      flex: 1,
    },
    {
      field: 'acumulado',
      headerName: 'Acumulado Competitivo',
      suppressMenu: false,
      textAlign: defaultNumberAlign,
      headerTextAlign: defaultNumberHeaderAlign,
      valueFormatter: (params) => ofertaCantidadFormatter(params?.data?.acumulado),

      maxWidth: defaultCellWidth,
      flex: 1,
    },
    {
      field: 'acumuladoConNoCpmpetitivo',
      headerName: 'Acumulado competitivo + no competitivo',
      suppressMenu: false,
      textAlign: defaultNumberAlign,
      headerTextAlign: 'center',
      wrapHeaderText: true,
      valueFormatter: (params) => ofertaCantidadFormatter(params?.data?.acumuladoConNoCpmpetitivo),

      maxWidth: defaultCellWidth,
      flex: 1,
    },
  ]

  //Filtros
  const filters = [
    <BymaTextInputFilter
      name='nombreOrganizacion'
      key='nombreOrganizacion'
      label='Buscar'
      type='text'
      alwaysOn={true}
      style={{ width: '300px' }}
      variant='Small'
    />,
  ]

  const tituloId = context.tituloId

  const { filterValues } = useListContext()

  const dataProvider = useCallback(() => {
    return tituloId !== undefined
      ? TitulosApi.getOfertasPorValor(tituloId, { filter: filterValues }).then((data) => {
          context.setTitulo(tituloModelToFormData(data.titulo))
          return data.items
        })
      : Promise.reject('No hay titulo definida')
  }, [tituloId, filterValues])

  const paginatedDataProvider = useLocalPaginationDataProvider(dataProvider)

  return (
    <BymaCard
      key={'ofertas-por-valor-card'}
      header={{
        title: 'Ofertas por valor',
        extras: (
          <BymaExportButton
            id='ofertas-colocacion-export-button'
            name='ofertas-colocacion-export-button'
            serverDownload={() =>
              TitulosApi.exportOfertasPorValor(tituloId, { filter: filterValues })
            }
            getFileName={() => 'ofertas-colocacion.csv'}
            postDownloading={() =>
              addNotification({
                message: 'Se descargo archivo de Ofertas',
                type: 'info',
              })
            }
          />
        ),
      }}
    >
      <div style={{ paddingBottom: '20px', paddingTop: '20px' }}>
        <FiltersHeader filters={filters} showFiltersMenu={false} />
      </div>
      <BymaDataTable
        key='ofertas-por-valor'
        columns={ofertasPorValorColumns}
        sizeColumnsToFit={true}
        style={{ height: '350px', width: '100%' }}
        pagination={false}
        suppressHorizontalScroll={true}
        sortable={false}
        queryProps={{
          dataProvider: (params) => paginatedDataProvider(params),
          listKey: LIST_KEY,
        }}
        listPaginationProps={{
          showLimits: true,
          pageSize: 50,
        }}
      />
    </BymaCard>
  )
}

export default () => {
  const context = useColocacionEditContext()
  return (
    <ColocacionSectionCard title={'Ofertas por valor'} icon={AllMatchIcon} showFooter={false}>
      <div className={styles.OfertasPorValorContent}>
        <ColocacionSummary {...context} colocacionActionEnabled={false} />

        <ListBase listKey={LIST_KEY.join('.')} perPage={50}>
          <OfertasPorValorTable context={context} />
        </ListBase>
      </div>
    </ColocacionSectionCard>
  )
}

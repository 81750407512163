import React from 'react'

import { Col, Row } from 'react-bootstrap'

import { TextInput, BymaSelect } from 'components'

import { UseParticipanteOfertaStateReturn } from './useParticipanteOfertaState'

import { TituloAction, DuenioPorcentajeInversionOptions } from 'model'

export const OfertaParticipanteFormFields = (props: UseParticipanteOfertaStateReturn) => {
  const {
    tipoCuentOptions,
    isInversor: isGrupoEconomico,
    inversorOptions: grupoEconomicoOptions,
    isCuitVisible,
    isCapVisible,
    isNombreVisible,
    isNumeroVisible,
    existsCondominoComitente,
    condominoComitenteOptions,
    isNombreReadOnly,
    onBlurNumeroInput,
    onChangeCuentaSelect,
    numeroHelpText,
    nombreHelpText,
    categoriaClienteOptions,
    categoriaClienteOptionsEnabled,
    context,
  } = props

  const columnSize = TituloAction.OFERTA_EXTENDIDA === context.tipoAltaOferta ? 3 : 4
  const columnSizeNombre = TituloAction.OFERTA_EXTENDIDA === context.tipoAltaOferta ? 6 : 4

  const { readOnly, editEnabled } = props.context

  const readOnlyFields = readOnly || !editEnabled

  return (
    <>
      <Row>
        <Col sm={columnSize}>
          <BymaSelect
            name='cuenta'
            label='Cuenta'
            required={true}
            options={tipoCuentOptions}
            modalMenu
            onBeforeChange={onChangeCuentaSelect}
            readOnly={readOnlyFields || context.isFieldReadOnly('cuenta')}
          />
        </Col>

        {!isNumeroVisible ? null : isGrupoEconomico ? (
          <Col sm={columnSize}>
            <BymaSelect
              name='numeroCliente'
              label={numeroHelpText}
              options={grupoEconomicoOptions}
              required={true}
              modalMenu
              readOnly={readOnlyFields || context.isFieldReadOnly('numeroCliente')}
            />
          </Col>
        ) : (
          <Col sm={columnSize}>
            <TextInput
              name='numeroCliente'
              label={numeroHelpText}
              onBlur={onBlurNumeroInput}
              required={true}
              readOnly={readOnlyFields || context.isFieldReadOnly('numeroCliente')}
            />
          </Col>
        )}

        {!isNombreVisible ? null : existsCondominoComitente ? (
          <Col sm={columnSizeNombre}>
            <BymaSelect
              name='nombreCliente'
              label={nombreHelpText}
              options={condominoComitenteOptions}
              required={true}
              modalMenu
              readOnly={readOnlyFields || context.isFieldReadOnly('nombreCliente')}
            />
          </Col>
        ) : (
          <Col sm={columnSizeNombre}>
            <TextInput
              name='nombreCliente'
              label={nombreHelpText}
              required={true}
              readOnly={
                isNombreReadOnly || readOnlyFields || context.isFieldReadOnly('nombreCliente')
              }
            />
          </Col>
        )}
      </Row>

      <Row className='mt-3'>
        {isCuitVisible ? (
          <Col sm={columnSize}>
            <TextInput
              name='cuit'
              label='CUIT/CUIL'
              readOnly={readOnlyFields || context.isFieldReadOnly('cuit')}
            />
          </Col>
        ) : null}

        {isCapVisible ? (
          <Col sm={columnSize}>
            <BymaSelect
              name='duenioPorcentajeInversion'
              label='Dueño CAP'
              options={DuenioPorcentajeInversionOptions}
              modalMenu
              readOnly={readOnlyFields || context.isFieldReadOnly('duenioPorcentajeInversion')}
            />
          </Col>
        ) : null}

        {isCapVisible ? (
          <Col sm={columnSize}>
            <TextInput name='grupoEconomicoDenominacion' label='Grupo Economico' readOnly={true} />
          </Col>
        ) : null}

        {categoriaClienteOptionsEnabled ? (
          <Col sm={columnSize}>
            <BymaSelect
              name='categoriaClienteId'
              label='Categoria'
              options={categoriaClienteOptions}
              modalMenu
              readOnly={readOnlyFields || context.isFieldReadOnly('categoriaClienteId')}
            />
          </Col>
        ) : null}
      </Row>
    </>
  )
}

import React from 'react'
import { Dropdown } from 'react-bootstrap'

import styles from './ColocacionActionButton.module.scss'

import { BymaBadge } from 'components'

import { ColocacionViewContext } from './ColocacionViewContext'

import { useBymaMutation } from 'core'

import {
  getColocacionEnabledCommandOptions,
  getColocacionEstadoInfo,
  getColocacionEstadoBadgeType,
  ColocacionesApi,
  ColocacionCommand,
} from 'model/colocacion'

const getEstadoColorsClassName = (estadoBadgeType) => styles[`${estadoBadgeType}Colors`]

const ColocacionActionButtonRunner = (context: ColocacionViewContext) => {
  const colocacion = context.colocacion
  const options = getColocacionEnabledCommandOptions(colocacion)
  const estado = colocacion?.estado
  const estadoDescription = getColocacionEstadoInfo(estado).label
  const estadoBadgeType = getColocacionEstadoBadgeType(estado)
  const colorsClassName = getEstadoColorsClassName(estadoBadgeType)

  const mutation = useBymaMutation({
    mutation: (command) => ColocacionesApi.run(context.colocacionId, command as ColocacionCommand),
    errorNotification: 'Error ejecutando accion',
    successNotification: 'Se actualizo la colocacion exitosamente',
    onSuccess: (data) => context.setColocacion(data),
  })

  return (
    <div className='d-flex align-items-center'>
      <div className='pe-2'>Estado</div>

      <Dropdown className={`${styles.navDropdown} ${colorsClassName}`}>
        <Dropdown.Toggle
          className={`${styles.navDropdownToggle} ${colorsClassName}`}
          id='dropdown-basic'
        >
          {estadoDescription}
        </Dropdown.Toggle>

        <Dropdown.Menu className={styles.dropdownMenu}>
          {options.map((opt) => (
            <Dropdown.Item
              key={opt.value}
              className={styles.linkContainer}
              onClick={() => mutation.mutate(opt.value)}
            >
              {opt.label}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  )
}

const ColocacionActionButtonInfo = (context: ColocacionViewContext) => {
  const colocacion = context.colocacion
  const estado = colocacion?.estado
  const estadoDescription = getColocacionEstadoInfo(estado).label
  const estadoBadgeType = getColocacionEstadoBadgeType(estado)

  return (
    <div className='d-flex align-items-center' style={{ marginBottom: '5px', marginTop: '5px' }}>
      <div className='pe-2'>Estado</div>
      <BymaBadge variant='Big' className='px-2' text={estadoDescription} type={estadoBadgeType} />
    </div>
  )
}

export const ColocacionActionButton = (context: ColocacionViewContext) => {
  return context.readOnly || context.editEnabled ? (
    <ColocacionActionButtonInfo {...context} />
  ) : (
    <ColocacionActionButtonRunner {...context} />
  )
}

import { api, resolveBackendUrl } from '../../services/api'
import { ChangePasswordModel } from './Model'

class ChangePasswordApi {
  changePassword(data: ChangePasswordModel) {
    return api
      .post(resolveBackendUrl('/api/account/password'), data)
      .then((response) => response.data)
  }
}

export default new ChangePasswordApi()

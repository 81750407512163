import React from 'react'

import { TextInput, BymaFormCard, LocalErrorAlert, BymaUpload } from 'components'

import { Col, Row } from 'react-bootstrap'

import { EntityModalProps, EntityModal } from 'features/common/Entity'
import { ColocacionDocumento } from 'model/documento'

const DocumentoSchemaProperties = (yup) =>
  yup.object().shape({
    denominacion: yup.string().required(),
    documento: yup.mixed().required('Debe ingresar un archivo'),
  })

const DocumentoForm = ({ cardTitle, editEnabled }) => {
  return (
    <>
      <BymaFormCard title={cardTitle}>
        <Row sm={12}>
          <Col sm={6}>
            <TextInput
              name='denominacion'
              required={true}
              readOnly={!editEnabled}
              label='Denominación Documento'
            />
          </Col>
          <Col sm={6}>
            <BymaUpload
              name='documento'
              required={true}
              readOnly={!editEnabled}
              label='Documento'
              variant='Small'
              placeHolder='Seleccione un archivo'
            />
          </Col>
        </Row>
      </BymaFormCard>

      <div style={{ marginTop: '1em' }}>
        <LocalErrorAlert />
      </div>
    </>
  )
}

const DocumentoModal = (props: EntityModalProps<ColocacionDocumento>) => {
  const editEnabled = props.context.editEnabled

  return (
    <EntityModal
      {...props}
      focusField='denominacion'
      getSchema={DocumentoSchemaProperties}
      showDelete={false}
      renderEditForm={(props) =>
        DocumentoForm({
          ...props,
          cardTitle: editEnabled ? 'Editar Documento' : 'Detalle Documento',
          editEnabled,
        })
      }
      renderCreateForm={(props) =>
        DocumentoForm({
          ...props,
          cardTitle: editEnabled ? 'Editar Documento' : 'Detalle Documento',
          editEnabled,
        })
      }
    />
  )
}

export default DocumentoModal

import React, { useState, useCallback, CSSProperties } from 'react'
import { useFormContext, Controller } from 'react-hook-form'
import { getDualListBoxLabels } from './lang'
import { ErrorMessage } from '../Form/ErrorMessage'
import DualListBox from './ReactDualListBox'
import { isEqual } from 'lodash'

import './ReactDualListBox/scss/react-dual-listbox.scss'
import './BymaDualListBox.scss'

interface SelectOption {
  label: string
  value: Identifier
}

export interface DualListBoxProps {
  id: string
  name: string
  labelAvailable?: string
  labelSelected?: string
  options: SelectOption[]
  selectedOptions: Identifier[]
  className?: string
  canFilter?: boolean
  //width?: string
  //height?: string
  disabled?: boolean
  shouldValidate?: boolean
  shouldDirty?: boolean
  showMoveAllButtons?: boolean
  readOnly?: boolean
  style?: CSSProperties | undefined
  labels?: Object
}

const defaultLabels = {
  availableFilterHeader: 'Buscar',
  selectedFilterHeader: 'Buscar',
  availableHeader: 'PERMISOS',
  selectedHeader: 'PERMISOS CONCEDIDOS',
  moveLeft: '< Quitar',
  moveRight: 'Agregar >',
  moveAllLeft: '< Quitar todos',
  moveAllRight: 'Agregar todos >',
  moveLeftTooltip: 'Quitar permiso',
  moveRightTooltip: 'Agregar permiso',
}

const BymaDualListBox = (props: DualListBoxProps) => {
  const {
    name,
    options,
    selectedOptions,
    canFilter = false,
    disabled = false,
    shouldDirty = true,
    shouldValidate = true,
    readOnly = false,
    style = {},
    labels,
    showMoveAllButtons = false,
  } = props

  const [selected, setSelected] = useState<Identifier[]>(selectedOptions)
  const { control, setValue } = useFormContext()

  const width = '100%'
  const height = 'inherit'

  //see https://react.dev/learn/you-might-not-need-an-effect#adjusting-some-state-when-a-prop-changes
  const [prevSelectedOptions, setPrevSelectedOptions] = useState<Identifier[] | undefined>()
  if (!isEqual(selectedOptions, prevSelectedOptions)) {
    //console.log(`DEBUG BymaDualListBox setValue(${name},${selectedOptions})`)

    setPrevSelectedOptions(selectedOptions)
    setSelected(selectedOptions)
    setValue(name, selectedOptions, { shouldValidate, shouldDirty })
  }

  const handleChange = useCallback((s) => {
    setSelected(s)
  }, [])

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState }) => {
        const errorMessage = fieldState.error?.message
        return (
          <span
            className='form-group'
            data-toggle='popover'
            data-trigger='focus'
            data-content='Please selecet account(s)'
            data-mdb-perfect-scrollbar='true'
          >
            <div className='byma-dual-list-container'>
              <DualListBox
                style={{
                  width: width,
                  height: height,
                  ...style,
                }}
                showMoveAllButtons={showMoveAllButtons}
                className='byma-dual-list-box'
                options={options}
                selected={selected}
                onChange={(val: any) => {
                  field.onChange(val)
                  handleChange(val)
                }}
                canFilter={canFilter}
                lang={getDualListBoxLabels({ ...defaultLabels, ...labels })}
                moveKeys={['moveKeys1', 'moveKeys2', 'moveKeys3', 'moveKeys4']}
                showHeaderLabels={true}
                showNoOptionsText={true}
                noOptionText='No hay resultados que coincidan con la búsqueda.'
                showOrderButtons={true}
                disabled={disabled}
                readOnly={readOnly}
              />
              <ErrorMessage error={errorMessage} />
            </div>
          </span>
        )
      }}
    />
  )
}

export default BymaDualListBox

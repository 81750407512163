import { resolveBackendUrl, ResourceApi, ResourceApiMethods } from 'services/api'

import { OfertaCreationInfo, Oferta } from './Model'

class OfertasApi extends ResourceApi<Oferta, OfertaCreationInfo> {
  constructor() {
    super('/api/ofertas')
  }

  getOfertasPropias = async (params: QueryParams) => {
    const path = '/api/ofertas/propias'
    return this.apiMethods.getPaginatedList({
      url: resolveBackendUrl(path),
      params,
    })
  }

  export = async (params?: object) => {
    const path = '/api/ofertas/propias'
    return this.apiMethods.export({
      url: resolveBackendUrl(`${path}/export`),
      params: { ...params },
    })
  }
}

export default new OfertasApi()

import React, { useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

import { BymaHelpBox, BymaPageFrame, BymaStepper } from 'components'

import { LoadingProvider, LocalErrorProvider } from 'core'
import { getColocacionDetailRoute } from 'routes'
import { ModalidadOfertasColocacion } from 'model'

import ColocacionesApi from 'model/colocacion/ColocacionesApi'
import {
  generalStepFields,
  generalStepYup,
  generalStepFieldNames,
} from './ColocacionFields/ColocacionGeneralStep'
import {
  fechaHorariosStepFields,
  fechaHorariosStepYup,
  fechaHorariosStepFieldNames,
} from './ColocacionFields/ColocacionDatesStep'
import {
  complementariosStepFields,
  complementariosStepYup,
  complementariosStepFieldNames,
} from './ColocacionFields/ColocacionComplementStep'

import { Col, Row } from 'react-bootstrap'

import * as yup from 'yup'

const colocacionDefaultValues = {
  sustentable: false,
  modalidadOfertas: ModalidadOfertasColocacion.CERRADA,
  admiteCap: false,
  interaccionSiopel: false,
  cuitObligatorio: false,
}

const ColocacionForm = () => {
  const createColocacion = useCallback((data) => ColocacionesApi.create(data), [])

  const generalStepSchema = useMemo(() => generalStepYup(yup), [yup])
  const fechaHorariosStepSchema = useMemo(() => fechaHorariosStepYup(yup), [yup])
  const complementariosStepSchema = useMemo(() => complementariosStepYup(yup), [yup])

  const navigate = useNavigate()

  const onSuccess = useCallback(
    (data) => {
      if (data?.id) {
        navigate(getColocacionDetailRoute(data.id))
      }
    },
    [navigate],
  )

  const editContext = { isNew: true, editEnabled: true }

  return (
    <BymaStepper
      id='create-colocacion'
      activeStep={0}
      variant={'small'}
      onCancel={() => {}}
      successNotification={() => 'Se ingresó la colocación exitosamente'}
      errorNotification={(error) => `Error Ingresando Colocación: ${error.message}`}
      mutation={createColocacion}
      onSuccess={onSuccess}
      defaultValues={colocacionDefaultValues}
      showStepLabels={false}
      style={{
        backgroundColor: 'var(--secondary-gray-normal)',
        borderRadius: '20px',
      }}
      steps={[
        {
          label: 'Datos generales',
          children: generalStepFields(editContext),
          validationSchemaSteps: generalStepSchema,
          stepFields: generalStepFieldNames,
        },
        {
          label: 'Fechas y horarios',
          children: fechaHorariosStepFields(editContext),
          validationSchemaSteps: fechaHorariosStepSchema,
          stepFields: fechaHorariosStepFieldNames,
        },

        {
          label: 'Datos complementarios',
          children: complementariosStepFields(editContext),
          validationSchemaSteps: complementariosStepSchema,
          stepFields: complementariosStepFieldNames,
        },
      ]}
    />
  )
}

const CreateColocacionForm = () => {
  const renderForm = () => {
    return (
      <LocalErrorProvider>
        <LoadingProvider>
          <ColocacionForm />
        </LoadingProvider>
      </LocalErrorProvider>
    )
  }

  return (
    <BymaPageFrame
      bcHome={{ value: 'Dashboard', link: '/home' }}
      bcItems={[]}
      bcActive='Nueva Colocación'
      title='NUEVA COLOCACION'
      iconTitle='add.svg'
    >
      <Row sm={12}>
        <Col sm={2}>
          <BymaHelpBox
            title='Nueva colocación'
            children={
              <p>
                Completa la información requerida para crear una Nueva colocación. <br />
                Al finalizar, podrás editar la misma.
              </p>
            }
          />
        </Col>
        <Col sm={10}>{renderForm()}</Col>
      </Row>
    </BymaPageFrame>
  )
}

export default () => (
  <LoadingProvider>
    <CreateColocacionForm />
  </LoadingProvider>
)

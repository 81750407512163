import React from 'react'

import { NumberInput, BymaDatePicker, BymaFormCard, LocalErrorAlert } from 'components'

import { Col, Row } from 'react-bootstrap'

import { EntityModalProps, EntityModal } from 'features/common/Entity'
import { FlujoFondos } from 'model/flujoFondos'

const FlujoFondoSchemaProperties = (yup) =>
  yup.object().shape({
    fecha: yup.date().required(),
    monto: yup.number().required(),
  })

/**
 * Necesitamos especificar un default para los campos de tipo fecha
 */
const defaultValues = {
  fecha: null,
}

const FlujoFondoForm = ({ cardTitle, editEnabled }) => {
  return (
    <>
      <BymaFormCard title={cardTitle}>
        <Row sm={12}>
          <Col sm={6}>
            <BymaDatePicker
              name='fecha'
              required={true}
              readOnly={!editEnabled}
              label='Fecha'
              showTimeSelect={false}
              clearBtn={false}
            />
          </Col>

          <Col sm={6}>
            <NumberInput name='monto' required={true} readOnly={!editEnabled} label='Monto' />
          </Col>
        </Row>
      </BymaFormCard>

      <div style={{ marginTop: '1em' }}>
        <LocalErrorAlert />
      </div>
    </>
  )
}

const FlujoFondoModal = (props: EntityModalProps<FlujoFondos>) => {
  const editEnabled = props.context.editEnabled

  return (
    <EntityModal
      {...props}
      defaultValues={defaultValues}
      focusField='denominacion'
      getSchema={FlujoFondoSchemaProperties}
      showDelete={false}
      renderEditForm={(props) =>
        FlujoFondoForm({
          ...props,
          cardTitle: editEnabled ? 'Editar Integracion' : 'Detalle Integracion',
          editEnabled,
        })
      }
      renderCreateForm={(props) =>
        FlujoFondoForm({
          ...props,
          cardTitle: editEnabled ? 'Editar Integracion' : 'Detalle Integracion',
          editEnabled,
        })
      }
    />
  )
}

export default FlujoFondoModal

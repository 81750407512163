import React, { useState } from 'react'
import { ListBase, LazyComponent } from 'core'
import { BymaTabs } from 'components'

import { OFERTA_ENTITY_NAME, TituloAction } from 'model'

import { OfertaContextInfo } from '../../common/Oferta/OfertaContextInfo'
import OfertaRapidaForm from './OfertaRapidaForm'
import LibroOfertasTable from './LibroOfertasTable'

const OFERTA_LIST_CLASSIFIER = 'dashboard.libro'
const LIST_KEY = OFERTA_ENTITY_NAME + '.' + OFERTA_LIST_CLASSIFIER

const OfertaCreate = (context: OfertaContextInfo) => {
  const [libroVisible, setLibroVisible] = useState<boolean>(false)

  const action = context.tipoAltaOferta

  const altaOfertaEnabled =
    action === TituloAction.OFERTA_EXTENDIDA || action === TituloAction.OFERTA_RAPIDA

  const altaOfertaTabEventKey = 'tituloTab'
  const altaOfertaTabTitle = 'Oferta rápida'

  const altaOfertaTab = altaOfertaEnabled
    ? {
        eventKey: altaOfertaTabEventKey,
        title: altaOfertaTabTitle,
        children: <OfertaRapidaForm context={context} />,
      }
    : {
        eventKey: altaOfertaTabEventKey,
        title: altaOfertaTabTitle,
        children: <div />,
        disabled: true,
      }

  const libroTabEventKey = 'libroTab'

  const defaultActiveKey = altaOfertaEnabled ? altaOfertaTabEventKey : libroTabEventKey

  return (
    <ListBase listKey={LIST_KEY}>
      <BymaTabs
        defaultActiveKey={defaultActiveKey}
        style={{ height: '440px' }}
        closeable={true}
        onClose={() => context.setShowOfertaRapida(false)}
        onSelect={(key) => {
          if (key === libroTabEventKey) setLibroVisible(true)
        }}
        tabs={[
          altaOfertaTab,
          {
            eventKey: libroTabEventKey,
            title: 'Libro',
            children: (
              <LazyComponent visible={libroVisible}>
                <LibroOfertasTable context={context} />
              </LazyComponent>
            ),
          },
        ]}
      />
    </ListBase>
  )
}

export default OfertaCreate

import { useQuery, QueryKey } from 'react-query'
import useListContext from './useListContext'
import { useNotificationProducer } from '../notification'
import { useLoadingProducer } from 'core/loading'
import { getApiErrorMessage } from 'services/api'
export interface UseListQueryParams<T> {
  listKey?: string | string[]
  dataProvider: (params: QueryParams) => Promise<PaginatedResult<T>>
  keepPreviousData?: boolean
  queryKey?: QueryKey
  taskMessage?: string
  enabled?: boolean
}

const DEFAULT_TASK_MESSAGE = ''
const DEFAULT_LIST_KEY = 'LIST'

function useListQuery<T>({
  listKey = DEFAULT_LIST_KEY,
  dataProvider,
  keepPreviousData = true,
  queryKey,
  taskMessage = DEFAULT_TASK_MESSAGE,
  enabled = true,
}: UseListQueryParams<T>) {
  const { addNotification } = useNotificationProducer()

  const loadingProducer = useLoadingProducer()

  const { filterValues, sort, page, perPage } = useListContext()

  const getQueryKey = (resource, params) => {
    if (resource instanceof String || typeof resource === 'string') {
      return [resource, params]
    }
    return [...resource, params]
  }

  const queryParams = {
    filter: filterValues,
    sort,
    pagination: { page, perPage },
  }

  const actualQueryKey = queryKey ? queryKey : getQueryKey(listKey, queryParams)

  //console.log(`DEBUG useListQuery listKey=${listKey} actualQueryKey=${JSON.stringify(actualQueryKey)}`)

  const taskId: string =
    listKey instanceof String || typeof listKey === 'string'
      ? (listKey as string)
      : (listKey as string[]).join('.')

  const useQueryResult = useQuery(
    actualQueryKey,

    () => dataProvider(queryParams),

    {
      staleTime: 0,

      refetchOnWindowFocus: false,

      //keeppreiousdata para evitar que se vacie la tabla mientras actualiza
      keepPreviousData: keepPreviousData,

      enabled,

      onSettled: () => {
        loadingProducer.removeTask(taskId)
      },

      retry: false,
    },
  )

  const result = { ...useQueryResult, error: getApiErrorMessage(useQueryResult.error) }

  if (result.isError) {
    addNotification({ message: result.error, type: 'error' })
  } else if (useQueryResult.isLoading || useQueryResult.isFetching) {
    loadingProducer.addTask({ message: taskMessage, id: taskId })
  }

  return result
}

export default useListQuery

import { createContext } from 'react'

import { Colocacion } from 'model/colocacion'
import { Titulo } from 'model/titulo'
import { OfertaContextInfo, defaultOfertaContextInfo } from 'features/common/Oferta'

export interface ColocacionContextInfo {
  colocacion?: Colocacion
  setColocacion: (Colocacion) => void
  titulo?: Titulo
  setTitulo: (Titulo) => void
  createOfertaContext: OfertaContextInfo
  editOfertaContext: OfertaContextInfo
}

export const defaultColocacionContextInfo = {
  colocacion: undefined,
  setColocacion: () => {
    console.log('DEBUG ColocacionContext ATENCION!!!: default context')
  },
  titulo: undefined,
  setTitulo: () => {
    console.log('DEBUG ColocacionContext ATENCION!!!: default context')
  },
  createOfertaContext: defaultOfertaContextInfo,
  editOfertaContext: defaultOfertaContextInfo,
}

const ColocacionContext = createContext<ColocacionContextInfo>(defaultColocacionContextInfo)

ColocacionContext.displayName = 'ColocacionContext'

export { ColocacionContext }

import React from 'react'

import styles from './HelperText.module.scss'

export interface HelperTextProps {
  text: string
  showIcon?: boolean
}

export const HelperText = (props: HelperTextProps) => {
  const { text, showIcon = true } = props

  const className = showIcon ? styles.helpText : styles.helpTextNoIcon

  return <div className={className}>{text}</div>
}

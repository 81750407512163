import React, { useMemo } from 'react'

import {
  BymaSelect,
  BymaDataSelect,
  TextInput,
  BymaCheckbox,
  NumberInput,
  BymaCard,
} from 'components'

import { Col, Row } from 'react-bootstrap'

import {
  BooleanOptions,
  ColocadorOptionsQueryProps,
  EmisorOptionsQueryProps,
  MetodoAdjudicacionOptions,
  ModalidadOfertasColocacionOptions,
  TipoColocacionOptionsQueryProps,
} from 'model'
import { getFieldsSectionCardStyleProps } from '../utils'

const yupRequiredNestedId = (yup) =>
  yup.object({
    id: yup.number().required(),
  })

export const generalStepYupFields = (yup) => ({
  denominacion: yup.string().required(),
  emisor: yupRequiredNestedId(yup),
  colocador: yupRequiredNestedId(yup),
  tipoColocacion: yupRequiredNestedId(yup),
  metodoAdjudicacion: yup.string().required(),
  totalEmision: yup.number().required(),
  modalidadOfertas: yup.string().required(),
})

export const generalStepYup = (yup) => yup.object(generalStepYupFields(yup))

export const generalStepFieldNames = [
  'denominacion',
  'emisor.id',
  'colocador.id',
  'tipoColocacion.id',
  'metodoAdjudicacion',
  'totalEmision',
  'modalidadOfertas',
]

export const generalStepFields = ({ isNew, editEnabled }) => {
  const cardStyleProps = useMemo(() => getFieldsSectionCardStyleProps(isNew), [isNew])

  const readOnly = !editEnabled

  return (
    <BymaCard {...cardStyleProps} title={'Detalle'}>
      <Row className='mb-3'>
        {!isNew && (
          <Col sm={3}>
            <TextInput name='id' label='Número de colocación' readOnly={true} />
          </Col>
        )}
        <Col sm={isNew ? 9 : 6}>
          <TextInput name='denominacion' label='Denominación' readOnly={readOnly} required />
        </Col>

        <Col sm={3}>
          <BymaSelect
            name='modalidadOfertas'
            label='Modalidad'
            options={ModalidadOfertasColocacionOptions}
            required
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row className='mb-3'>
        <Col sm={3}>
          <BymaDataSelect
            name='emisor.id'
            label='Emisor'
            required={true}
            queryProps={EmisorOptionsQueryProps}
            readOnly={readOnly}
          />
        </Col>
        <Col sm={3}>
          <BymaDataSelect
            name='colocador.id'
            label='Colocador'
            required={true}
            queryProps={ColocadorOptionsQueryProps}
            readOnly={readOnly}
          />
        </Col>
        <Col sm={3}>
          <BymaDataSelect
            name='tipoColocacion.id'
            label='Tipo de Producto'
            queryProps={TipoColocacionOptionsQueryProps}
            readOnly={readOnly}
            required
          />
        </Col>
        <Col sm={3}>
          <NumberInput
            name='totalEmision'
            label='Total Emisión'
            readOnly={readOnly}
            required={true}
          />
        </Col>
      </Row>
      <Row className='mb-3'>
        <Col sm={3}>
          <BymaSelect
            name='metodoAdjudicacion'
            label='Método de Adjudicación'
            options={MetodoAdjudicacionOptions}
            required
            readOnly={readOnly}
          />
        </Col>

        <Col sm={2}>
          <BymaCheckbox
            type='radio'
            name='sustentable'
            label='Sustentable'
            options={BooleanOptions}
            readOnly={readOnly}
          />
        </Col>

        <Col sm={2}>
          <BymaCheckbox
            type='radio'
            name='admiteCap'
            label='Admite CAP'
            options={BooleanOptions}
            readOnly={readOnly}
          />
        </Col>
      </Row>
    </BymaCard>
  )
}

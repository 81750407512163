export const ROLES_PERMISSIONS = [/rol:*/]
export const EMISORES_PERMISSIONS = [/emisor:.*/]
export const AGENTES_PERMISSIONS = [/agente:.*/]
export const COLOCADORES_PERMISSIONS = [/colocador:.*/]
export const GRUPO_ECONOMICO_PERMISSIONS = [/grupo_economico:.*/]
export const INVERSOR_PERMISSIONS = [/fci_ssn:.*/]
export const NACIONALIDAD_PERMISSIONS = [/nacionalidad:.*/]
export const AYUDA_EDIT_PERMISSIONS = [/ayuda:.*/]
export const DASHBOARD_OFERTAS_PERMISSION = [/oferta:.*/]

export const COLOCACION_HISTORIAL_PERMISSION = [/colocacion:.*/]
export const COLOCACION_CREATE_PERMISSION = [/colocacion:create.*/]
export const COLOCACION_EDIT_PERMISSION = [/colocacion:edit.*/]

export const LIST_OFERTAS_PROPIAS_PERMISSION = ['oferta:list:propias']
export const OFERTA_CREATE_EN_NOMBRE_DE_PERMISSION = ['oferta:create:en_nombre_de']
export const OFERTA_CREATE_PROPIA_PERMISSION = ['oferta:create:propia']

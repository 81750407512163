import React from 'react'
import BymaCard, { CardProps } from './BymaCard'

export const BymaFormCard = (props: CardProps) => {
  return (
    <BymaCard
      title={props.title}
      titleBackgorundColor={'var(--secondary-gray-normal)'}
      style={{
        background: 'var(--secondary-gray-normal)',
        backgroundColor: 'var(--secondary-gray-normal)',
        borderRadius: '20px',
        border: '1px solid var(--gray-color-dark)',
        paddingTop: '2%',
        paddingBottom: '4%',
        marginTop: '2%',
      }}
    >
      {props.children}
    </BymaCard>
  )
}

import React from 'react'
import { routes, getColocacionViewRoute } from 'routes'

import {
  BymaDataTable,
  BymaExportButton,
  BymaCheckbox,
  BymaCard,
  useSelectSingleFilterProps,
  BymaTextInputFilter,
  BymaPageFrame,
  BymaDataSelect,
  BymaTableIconRow,
  BymaDatePicker,
  BymaClock,
  useBymaTableBadgeCellRenderProps,
  FilterButton,
  BymaTableBadgeRow,
  useNavigateDetailButtonCell,
} from 'components'

import { Col, Row } from 'react-bootstrap'

import { useNotificationProducer, useListContext, ListBase } from 'core'
import sustentableIcon from 'assets/img/eco.svg'

import {
  COLOCACION_ESTADO_INFO,
  COLOCACION_ENTITY_NAME,
  EstadoColocacionOptions,
  EmisorOptionsQueryProps,
  ColocadorOptionsQueryProps,
} from 'model'

import styles from './HistorialColocaciones.module.scss'

import { FiltersHeader } from '../common/Filters'
import ColocacionesApi from 'model/colocacion/ColocacionesApi'
import { formatDateWithHHMM } from 'utils/date'

const HISTORIAL_COLOCACIONES_COLUMNS = [
  {
    field: 'sustentable',
    headerName: '',
    maxWidth: 70,
    cellRenderer: BymaTableIconRow,
    cellRendererParams: (params) => {
      const sustentable = params?.data?.sustentable
      const icon = sustentable === true ? sustentableIcon : undefined
      return { icon: icon }
    },
  },
  {
    field: 'id',
    headerName: 'Colocación',
    width: 100,
    textAlign: 'left',
  },
  {
    field: 'denominacion',
    headerName: 'Denominación',
    textAlign: 'center',
    ...useBymaTableBadgeCellRenderProps((params) => params?.data?.denominacion),
  },
  {
    headerName: 'Emisor',
    field: 'emisor.denominacion',
    textAlign: 'center',
    ...useBymaTableBadgeCellRenderProps((params) => params?.data?.emisor?.denominacion),
  },
  {
    headerName: 'Colocador',
    field: 'colocador.denominacion',
    textAlign: 'center',
    ...useBymaTableBadgeCellRenderProps((params) => params?.data?.colocador?.denominacion),
  },
  {
    field: 'fechaInicio',
    headerName: 'Fecha inicio',
    textAlign: 'left',
    valueFormatter: (params) => formatDateWithHHMM(params?.data?.fechaInicio),
    maxWidth: 130,
  },
  {
    field: 'fechaCierre',
    headerName: 'Fecha cierre',
    textAlign: 'left',
    valueFormatter: (params) => formatDateWithHHMM(params?.data?.fechaCierre),
    maxWidth: 130,
  },
  {
    headerName: 'Estado',
    field: 'estado',
    textAlign: 'center',
    cellRenderer: BymaTableBadgeRow,
    cellRendererParams: (params) => {
      const estado = params?.data?.estado
      const estadoInfo = EstadoColocacionOptions.find((e) => e.value === estado)

      return estado
        ? {
            text: estadoInfo?.label,
            type: COLOCACION_ESTADO_INFO[estado].badgeType,
          }
        : {}
    },
    maxWidth: 120,
  },
  useNavigateDetailButtonCell({
    routeProvider: (data) => getColocacionViewRoute(data?.id),
  }),
]

const COLOCACION_LIST_CLASSIFIER = 'dashboard.historial_colocaciones'
const LIST_KEY = [COLOCACION_ENTITY_NAME, COLOCACION_LIST_CLASSIFIER]

const HistorialColocaciones = () => {
  const { addNotification } = useNotificationProducer()

  const selectSingleFilterProps = useSelectSingleFilterProps()

  //Filtros
  const filters = [
    <BymaTextInputFilter
      name='id'
      key='id'
      label='Colocación'
      type='number'
      style={{ minWidth: 156 }}
      variant='Small'
    />,

    <BymaTextInputFilter
      name='denominacion'
      key='denominacion'
      label='Denominación'
      style={{ minWidth: 190 }}
      variant='Small'
    />,

    <BymaDataSelect
      {...selectSingleFilterProps}
      name='emisorId'
      key='emisorId'
      label='Emisor'
      style={{ width: 140 }}
      maxMenuHeight={140}
      queryProps={EmisorOptionsQueryProps}
      placeholder='Seleccione'
    />,

    <BymaDataSelect
      {...selectSingleFilterProps}
      name='colocadorId'
      key='colocadorId'
      label='Colocador'
      style={{ width: 140 }}
      maxMenuHeight={140}
      queryProps={ColocadorOptionsQueryProps}
      placeholder='Seleccione'
    />,

    /*
    <BymaMultiSelect
      name='estados'
      key='estados'
      label='Estado'
      style={{ width: 140 }}
      options={EstadoColocacionOptions}
      alwaysOn={false}
      variant='Small'
      placeholder='Seleccione Estados'
      className={styles.bymaMultiSelect}
    />,
    */

    <BymaDatePicker
      key='fechaInicioDesde'
      name='fechaInicioDesde'
      label='Fecha inicio desde'
      variant='Small'
      showTimeSelect={false}
      style={{ width: 185 }}
    />,

    <BymaDatePicker
      key='fechaInicioHasta'
      name='fechaInicioHasta'
      label='Fecha inicio hasta'
      variant='Small'
      showTimeSelect={false}
      style={{ width: 185 }}
    />,

    <BymaCheckbox
      key='sustentable'
      type='checkbox'
      id={'sustentable'}
      name='sustentable'
      label='Sustentable'
      hideLabel={true}
      multicheck={false}
      variant='Small'
      options={[{ value: true, label: 'Sustentable' }]}
      style={{ width: '100vw' }}
    />,
  ]

  const { filterValues } = useListContext()

  return (
    <div>

      <BymaPageFrame
        bcHome={{ value: 'Dashboard', link: '/home' }}
        bcItems={[]}
        bcActive='Historial de colocaciones'
        title='Historial de colocaciones'
        iconTitle='history.svg'
        className={styles.historialColocacionesContainer}
      >
        <BymaCard key={'historial-colocaciones-card'}>
          <Row className={styles.exportBtnRow}>
            <Col sm={{ span: 1, offset: 11 }}>
              <BymaExportButton
                id='historial-colocaciones-export-button'
                name='historial-colocaciones-export-button'
                serverDownload={() => ColocacionesApi.export({ filter: filterValues })}
                getFileName={() => 'historial-colocaciones.csv'}
                postDownloading={() =>
                  addNotification({
                    message: 'Se descargo archivo de Colocaciones',
                    type: 'info',
                  })
                }
                exportBtnWrapperStyles={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                }}
              />
            </Col>
          </Row>
          <div className='d-flex justify-content-between'>
            <div className='row overflow-hidden'>
              <FiltersHeader
                filtersWrapperColClassName={`col-12 `}
                filterFormStyles={{ gap: 8 }}
                filters={filters}
                showFiltersMenu={false}
                listFiltersRowContainer={styles.listFiltersRowContainer}
                exportButton={<></>}
                // filterBtnColClassName={''}
              />
              {/* <FilterButton
                filters={filters}
                // className={styles.filtersButton}
                className='col-1'
              /> */}
            </div>

            <div className={styles.filterButtonContainer}>
              <FilterButton filters={filters} className={styles.filtersButton} />
            </div>
          </div>

          <Row className={'first-row'}>
            <BymaDataTable
              key='historial-colocaciones'
              columns={HISTORIAL_COLOCACIONES_COLUMNS}
              sizeColumnsToFit={true}
              style={{ height: '350px', width: '100%' }}
              pagination={false}
              sortable={false}
              queryProps={{
                dataProvider: (params) => ColocacionesApi.getColocaciones(params),
                listKey: LIST_KEY,
              }}
              listPaginationProps={{}}
              suppressHorizontalScroll={true}
            />
          </Row>
        </BymaCard>
      </BymaPageFrame>
    </div>
  )
}

export default () => (
  <ListBase
    listKey={LIST_KEY.join('.')}
    displayedFilters={{
      id: true,
      denominacion: true,
      emisorId: true,
      colocadorId: true,
      fechaInicioDesde: true,
      fechaInicioHasta: true,
      sustentable: true,
    }}
  >
    <HistorialColocaciones />
  </ListBase>
)

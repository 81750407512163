import { TipoValor, TIPO_VALOR_INFO, tituloTipoValorFormatter } from 'model/titulo'
import { TipoCuenta, TipoDocumento } from 'model/inversor'
import { formatTimestampShort, formatTimestamp } from 'utils/date'
import { numberFormat } from 'utils/number'
import { isNullOrUndefined } from 'utils/objects'

export enum EstadoOferta {
  NO_CONFIRMADA = 'NO_CONFIRMADA',
  VIGENTE = 'VIGENTE',
  CANCELADA = 'CANCELADA',
  PENDIENTE_CONFIRMACION_MAE = 'PENDIENTE_CONFIRMACION_MAE',
  PENDIENTE_MODIFICACION_MAE = 'PENDIENTE_MODIFICACION_MAE',
  PENDIENTE_BAJA_MAE = 'PENDIENTE_BAJA_MAE',
  RECHAZADA_MAE = 'RECHAZADA_MAE',
}

export const EstadoOfertaEditOptions = [
  { label: 'No Confirmada', value: EstadoOferta.NO_CONFIRMADA },
  { label: 'Vigente', value: EstadoOferta.VIGENTE },
]

export const EstadoOfertaShowOptions = [
  ...EstadoOfertaEditOptions,
  { label: 'Cancelada', value: EstadoOferta.CANCELADA },
]

/**
 * Agrupamiento de estados para Filtro
 */
export enum EstadoOfertaGroup {
  NO_CONFIRMADA = 'NO_CONFIRMADA',
  VIGENTE = 'VIGENTE',
  PENDIENTE = 'PENDIENTE',
  RECHAZADA = 'RECHAZADA',
  CANCELADA = 'CANCELADA',
}

export const EstadoOfertaGroupOptions = [
  { label: 'No Confirmada', value: EstadoOfertaGroup.NO_CONFIRMADA },
  { label: 'Vigente', value: EstadoOfertaGroup.VIGENTE },
  { label: 'Pendiente', value: EstadoOfertaGroup.PENDIENTE },
  { label: 'Rechazada', value: EstadoOfertaGroup.RECHAZADA },
  { label: 'Cancelada', value: EstadoOfertaGroup.CANCELADA },
]

/**
 * Detalle de estado para visualizacion
 */
export interface EstadoOfertaDetailInfo {
  shortText?: string
  text: string
  badgeType: string
}

export const ESTADO_OFERTA_GROUP_INFOS = {
  [EstadoOfertaGroup.NO_CONFIRMADA]: {
    badgeType: 'pending',
    text: 'No Confirmada',
  },
  [EstadoOfertaGroup.VIGENTE]: { badgeType: 'active', text: 'Vigente' },
  [EstadoOfertaGroup.PENDIENTE]: { badgeType: 'pending', text: 'Pendiente' },
  [EstadoOfertaGroup.RECHAZADA]: { badgeType: 'error', text: 'Rechazada' },
  [EstadoOfertaGroup.CANCELADA]: { badgeType: 'error', text: 'Cancelada' },
}

export enum EstadoOfertaDetail {
  NO_CONFIRMADA = 'NO_CONFIRMADA',
  VIGENTE = 'VIGENTE',
  ADJUDICADA = 'ADJUDICADA',
  ADJUDICADA_PARCIAL = 'ADJUDICADA_PARCIAL',
  NO_ADJUDICADA = 'NO_ADJUDICADA',
  CANCELADA = 'CANCELADA',
  PENDIENTE = 'PENDIENTE',
  RECHAZADA = 'RECHAZADA',
}

export const ESTADO_OFERTA_DETAIL_INFOS = {
  [EstadoOfertaDetail.NO_CONFIRMADA]: {
    badgeType: 'pending',
    text: 'No Confirmada',
  },
  [EstadoOfertaDetail.VIGENTE]: { badgeType: 'active', text: 'Vigente' },
  [EstadoOfertaDetail.PENDIENTE]: { badgeType: 'pending', text: 'Pendiente' },
  [EstadoOfertaDetail.ADJUDICADA]: { badgeType: 'active', text: 'Adjudicada' },
  [EstadoOfertaDetail.NO_ADJUDICADA]: {
    badgeType: 'error',
    text: 'No Adjudicada',
  },
  [EstadoOfertaDetail.ADJUDICADA_PARCIAL]: {
    badgeType: 'active',
    text: 'Adjudicada Parcial',
    shortText: 'Adjudicada *',
  },
  [EstadoOfertaDetail.RECHAZADA]: { badgeType: 'error', text: 'Rechazada' },
  [EstadoOfertaDetail.CANCELADA]: { badgeType: 'error', text: 'Cancelada' },
}

export const TipoCuentaNumeroDescription = {
  [TipoCuenta.COMITENTE]: 'Número comitente',
  [TipoCuenta.FCICNV]: 'Código de CNV',
  [TipoCuenta.SSN]: 'N° Registro',
  [TipoCuenta.LEI]: 'N° LEI',
  [TipoCuenta.CDI]: 'N° CDI',
  [TipoCuenta.CIE]: 'N° CIE',
}

export const TipoDocumentoOptions = [
  { label: 'CUIT', value: TipoDocumento.CUIT },
  { label: 'CUIL', value: TipoDocumento.CUIL },
  { label: 'CDI', value: TipoDocumento.CDI },
  { label: 'CIE', value: TipoDocumento.CIE },
  { label: 'FCINV', value: TipoDocumento.FCICNV },
  { label: 'SSN', value: TipoDocumento.SSN },
  { label: 'LEI', value: TipoDocumento.LEI },
]

export enum OrdenOfertas {
  ULTIMA_OFERTA_INGRESADA = 'ULTIMA_OFERTA_INGRESADA',
  MAYOR_VALOR = 'MAYOR_VALOR',
  MENOR_VALOR = 'MENOR_VALOR',
  MAYOR_CANTIDAD = 'MAYOR_CANTIDAD',
  MENOR_CANTIDAD = 'MENOR_CANTIDAD',
  MAYOR_NUM_OFERTA = 'MAYOR_NUM_OFERTA',
  MENOR_NUM_OFERTA = 'MENOR_NUM_OFERTA',
}

export enum OrdenOfertasLibroColocacion {
  MEJOR_VALOR = 'MEJOR_VALOR',
}

export const OrdenOfertasOptions = [
  { label: 'Última Oferta Ingresada', value: OrdenOfertas.ULTIMA_OFERTA_INGRESADA },

  { label: '↑ Cantidad', value: OrdenOfertas.MENOR_CANTIDAD },
  { label: '↓ Cantidad', value: OrdenOfertas.MAYOR_CANTIDAD },

  { label: '↑ Valor', value: OrdenOfertas.MENOR_VALOR },
  { label: '↓ Valor', value: OrdenOfertas.MAYOR_VALOR },

  { label: '↑ N° Oferta', value: OrdenOfertas.MENOR_NUM_OFERTA },
  { label: '↓ N° Oferta', value: OrdenOfertas.MAYOR_NUM_OFERTA },
].map((opt) => ({
  label: `Ordenar por ${opt.label}`,
  optionLabel: opt.label,
  value: opt.value,
}))

export enum TipoOferta {
  COMPETITIVA = 'COMPETITIVA',
  NO_COMPETITIVA = 'NO_COMPETITIVA',
}

export const TipoOfertaOptions = [
  { label: 'No Competitivo', value: TipoOferta.NO_COMPETITIVA },
  { label: 'Competitivo', value: TipoOferta.COMPETITIVA },
]

export enum ClaseOferta {
  CLASE_A = 'CLASE_A',
  CLASE_B = 'CLASE_B',
  CLASE_C = 'CLASE_C',
}

export const ClaseOfertaOptions = [
  { label: 'A', value: ClaseOferta.CLASE_A },
  { label: 'B', value: ClaseOferta.CLASE_B },
  { label: 'C', value: ClaseOferta.CLASE_C },
]

export enum DuenioPorcentajeInversion {
  CLIENTE = 'CLIENTE',
  GRUPO_ECONOMICO = 'GRUPO_ECONOMICO',
}

export const DuenioPorcentajeInversionOptions = [
  { label: 'A', value: DuenioPorcentajeInversion.CLIENTE },
  { label: 'B', value: DuenioPorcentajeInversion.GRUPO_ECONOMICO },
]

export interface Oferta extends BaseEntity {
  propia: boolean
  cantidad: number
  valor?: number
  porcentajeInversion?: number
  estado: EstadoOferta
  fechaAlta: string
  usuarioAlta: string
  tituloId: number
  tituloDenominacion: string
  colocacionId: number
  colocacionDenominacion: string
  colocacionEstado: string //EstadoColocacion
  tipo: string //TipoOferta
  cuenta: string //TipoCuenta
  origenOferta: string //OrigenOferta
  tipoValor: string //TipoValor
  cantidadAdjudicada: number
  estadoGroup: string //EstadoOfertaGroup
  estadoDetail: string //EstadoOfertaDetail
  agenteId: number
  agenteIdOrganizacion: string
  agenteNombreOrganizacion: string
  motivoBaja: string
  deTerceros: boolean
  numeroCliente: string
  observaciones: string
  cuit: string
  nombreCliente: string
  tipoPersona: string //TipoPersona
  integracionTituloId: number
  integracionTituloDenominacion: string
  timestampModificacion: string
  timestampConfirmacion: string
  usuarioConfirmacion: string
  clienteId: number
  clase: string //ClaseOferta
  secuenciaMae: string
  respuestaMae: string
  mensajeMae: string
  secuenciaSiopel: string
  secuenciaSiopelOriginal: string
  grupoEconomicoId: number
  nacionalidadClienteId: number
  categoriaClienteId: number
  montoALiquidar: number
  valorAdjudicado: number
}

export interface OfertaCreationInfo extends BaseEntity {
  cantidad: number
  valor?: number
  tipoDocumento: TipoDocumento
  cuit: string
  integracionTituloId: Identifier
  categoriaClienteId: Identifier
}

export const OFERTA_ENTITY_NAME = 'oferta'

const porcentajeInversionFormat = numberFormat(2)

export const ofertaPorcentajeInversionFormatter = (valor: number) => {
  return porcentajeInversionFormat.format(valor)
}

export const ofertaCapCellFormatter = (data: any) =>
  data?.porcentajeInversion
    ? `${ofertaPorcentajeInversionFormatter(data?.porcentajeInversion)}%`
    : ''

export const ofertaUltimaModificacionShortFormatter = (data: any) => {
  const value = data?.timestampModificacion
  return value ? formatTimestampShort(value) : ''
}

export const ofertaUltimaModificacionFullFormatter = (data: any) => {
  const value = data?.timestampModificacion
  return value ? formatTimestamp(value) : ''
}

const cantidadFormat = numberFormat(2)

export const ofertaCantidadFormatter = (valor: number) => {
  return cantidadFormat.format(valor)
}

export const ofertaCantidadCellRenderProvider = (data: any) => {
  const value = cantidadFormat.format(data.cantidad)
  const tooltipText = data.cantidadAdjudicada
    ? 'Cantidad adjudicada: ' + cantidadFormat.format(data.cantidadAdjudicada)
    : undefined
  return {
    value: value,
    tooltipText: tooltipText,
  }
}

const valorFormat = numberFormat(2)

const _toString = (s) => (s !== undefined ? s : '')
const _isNUmber = (n) => n !== undefined && n !== null && !isNaN(n)

const valorConTipoFormat = (n: number, tipoValorInfo: any) => {
  return _isNUmber(n)
    ? `${_toString(tipoValorInfo?.prefix)}${valorFormat.format(n)}${_toString(
        tipoValorInfo?.suffix,
      )}`
    : null
}

export const ofertaValorConTipoCellFormatter = (data: any) => {
  const tipoValor = data?.tipoValor || TipoValor.PRECIO
  const tipoValorInfo = TIPO_VALOR_INFO[tipoValor]
  const n = data?.valor
  return valorConTipoFormat(n, tipoValorInfo)
}

export const ofertaValorAdjudicadoTooltipFormatter = (data: any) => {
  const tipoValor = data?.tipoValor || TipoValor.PRECIO
  const tipoValorInfo = TIPO_VALOR_INFO[tipoValor]
  const n = data?.valorAdjudicado
  return _isNUmber(n) ? `Valor adjudicada: ${valorConTipoFormat(n, tipoValorInfo)}` : null
}

export const ofertaValorFormatter = (valor: number) => {
  if (isNullOrUndefined(valor)) {
    return ''
  }
  return valorFormat.format(valor)
}

export const ofertaValueLabelFormatter = (data: any) => {
  const tipoValor = data?.tipoValor
  const tipoValorInfo = tipoValor ? TIPO_VALOR_INFO[tipoValor] : undefined
  return tipoValorInfo ? tipoValorInfo.description : 'Valor'
}

export const ofertaNumeroClienteTooltipFormatter = (data: any) => {
  const tipoCuenta = data?.cuenta
  return tipoCuenta && data?.numeroCliente
    ? `${TipoCuentaNumeroDescription[tipoCuenta]}: ${data?.numeroCliente}`
    : null
}

export function ofertaTipoValorTitleFormatter(titulo: any) {
  return tituloTipoValorFormatter(titulo)
}

export const limitesCantidadHelperTextFormatter = (cantidadMinima, cantidadMaxima) => {
  const hasCantidadMaxima = !isNullOrUndefined(cantidadMaxima)
  const hasCantidadMinima = !isNullOrUndefined(cantidadMinima)
  if (!hasCantidadMaxima && !hasCantidadMinima) {
    return undefined
  }
  return `${hasCantidadMinima ? ofertaCantidadFormatter(cantidadMinima) : ''} - ${
    hasCantidadMaxima ? ofertaCantidadFormatter(cantidadMaxima) : ''
  }`
}

export const precioReferenciaFormatter = (valorReferencia) => {
  return !isNullOrUndefined(valorReferencia)
    ? `Precio referencia: ${ofertaValorFormatter(valorReferencia)}`
    : undefined
}

export const tramoFormatter = (tipoOferta) => {
  return !isNullOrUndefined(tipoOferta)
    ? TipoOfertaOptions.filter((opt) => opt.value === tipoOferta)[0]?.label
    : undefined
}

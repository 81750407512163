import { createContext } from 'react'

export const defaultLoadingApiContextInfo = {
  addTask: (task: LoadingTaskProps) => {
    //console.log(`DEBUG LoadingContext ATENCION!!!: addTask handler default ${JSON.stringify(task)}`)
  },

  removeTask: (id: string) => {
    //console.log(`DEBUG LoadingContext ATENCION!!!: removeTask handler default ${id}`)
  },
}

const LoadingApiContext = createContext<LoadingApiContextInfo | undefined>(undefined)

LoadingApiContext.displayName = 'LoadingApiContext'

const LoadingDataContext = createContext<LoadingDataContextInfo>({ tasks: [] })

LoadingDataContext.displayName = 'LoadingDataContext'

export { LoadingApiContext, LoadingDataContext }

import React from 'react'
import Breadcrumb from 'react-bootstrap/Breadcrumb'
import { LinkContainer } from 'react-router-bootstrap'

import './BymaBreadcrumb.scss'

interface BreadcrumbProps {
  home: BCLink
  items: BCLink[]
  active: string
}

const BymaBreadcrumb = (props: BreadcrumbProps) => {
  const { home, items, active } = props

  return (
    <Breadcrumb className='byma-breadcrumb'>
      <LinkContainer to={home.link} exact>
        <Breadcrumb.Item key='bc-home'>{home.value}</Breadcrumb.Item>
      </LinkContainer>

      {items &&
        items.length > 0 &&
        items.map((item: BCLink, idx: number) => (
          <Breadcrumb.Item href={home.link} key={'bc-' + idx}>
            {item.value}
          </Breadcrumb.Item>
        ))}
      <Breadcrumb.Item key='bc-active' active>
        {active}
      </Breadcrumb.Item>
    </Breadcrumb>
  )
}

export default BymaBreadcrumb

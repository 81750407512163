import React, { useState, ReactNode, CSSProperties } from 'react'
import Alert from 'react-bootstrap/Alert'

import './BymaAlert.scss'

interface AlertProps {
  children: ReactNode
  onClose?: any
  variant?: 'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'info' | 'light' | 'dark'
  closable?: boolean
  style?: CSSProperties | undefined
  messageId?: Identifier
}

const BymaAlert = (props: AlertProps) => {
  const { children, onClose = () => {}, variant = 'info', style = {}, messageId } = props

  const [visible, setVisible] = useState<boolean>(true)
  const [prevMessageId, setPrevMessageId] = useState<Identifier | undefined>(undefined)

  if (messageId !== prevMessageId) {
    setPrevMessageId(messageId)
    setVisible(true)
  }

  const width = '100%'
  //const height = 'inherit'

  return (
    <Alert
      className='byma-alert'
      key={'alert-' + variant}
      variant={variant}
      onClose={() => {
        onClose()
        setVisible(false)
      }}
      show={visible}
      style={{
        width: width,
        ...style,
      }}
      dismissible
    >
      {children}
    </Alert>
  )
}

export default BymaAlert

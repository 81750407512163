

import React, { useEffect, useState } from 'react'

import styles from './BymaClock.module.scss'

interface ClockProps {
    format?: string
}

const BymaClock = (props: ClockProps) => {
    const locale = undefined //"fa-IR"
    const [time, setTime] = useState(new Date().toLocaleTimeString(locale))

    useEffect(() => {
        const time = () => {
            const event = new Date()
            setTime(event.toLocaleTimeString(locale))
        }
        const intervalId = setInterval(time, 1000)

        return () => {
            clearInterval(intervalId)
        }
    }, [])

    return <div className={styles.bymaClock}> <span>{time}</span> </div>
}

export default BymaClock
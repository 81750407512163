export const mapDenominableToOption = (entity: any): SelectOption => ({
  label: entity.denominacion || '',
  value: entity.id,
})

export function mapDenominableListToOption<T>(entities: T[] | undefined): SelectOption[] {
  return entities ? entities.map((e) => mapDenominableToOption(e)) : []
}

export function mapEnumToOptions(o: any) {
  return Object.values(o).map((v) => ({
    value: v as Identifier,
    label: v ? v.toString() : '',
  }))
}

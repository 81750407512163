import AxiosMockAdapter from 'axios-mock-adapter-path-params'

let newTitulo = {
  integraciones: [],
  canjes: [],
  flujoFondos: [],
}

export const titulosResponse = [
  {
    id: 11,
    denominacion: 'Titulo 1.1',
    descripcion: 'TV23C - O0011D1 - VRD - MONEDA DE EMISION ARS - $',
    totalEmision: 10000000,
    valorReferencia: 168,
    tipoValor: 'PRECIO',

    monedaEmision: 'PESOS',
    monedaLiquidacion: 'PESOS',
    fechaLiquidacion: '2023-09-22T03:00:00Z',

    tramoCompetitivo: {
      cantidadMinimaOferta: 1.0,
      cantidadMaximaOferta: 2000000.0,
      multiploOferta: 1.0,
      precioMinimoOferta: 0,
      precioMaximoOferta: 10000.0,
      unidadProrrateo: 1.0,
    },

    tramoNoCompetitivo: {
      cantidadMinimaOferta: 500.0,
      cantidadMaximaOferta: 500000.0,
      multiploOferta: 1.0,
      unidadProrrateo: 1.0,
    },
    tramoCompetitivoHabilitado: true,

    integraciones: [
      { id: 1001, denominacion: 'Integracion 1.1.1' },
      { id: 1002, denominacion: 'Integracion 1.1.2' },
    ],
  },
  {
    id: 12,
    denominacion: 'Titulo 1.2',
    descripcion: 'TV23C - O0012D1 - VRD - MONEDA DE EMISION ARS - $',
    totalEmision: 50000000,
    valorReferencia: 202,
    tipoValor: 'PRECIO',

    monedaEmision: 'DOLAR',
    monedaLiquidacion: 'DOLAR',
    fechaLiquidacion: '2023-09-22T03:00:00Z',

    tramoCompetitivo: {
      cantidadMinimaOferta: 100,
      cantidadMaximaOferta: 1000000.0,
      multiploOferta: 1.0,
      precioMinimoOferta: 0,
      precioMaximoOferta: 12000.0,
      unidadProrrateo: 1.0,
    },

    tramoNoCompetitivo: {
      cantidadMinimaOferta: 300.0,
      cantidadMaximaOferta: 3000000.0,
      multiploOferta: 1.0,
      unidadProrrateo: 1.0,
    },
    tramoCompetitivoHabilitado: true,
    integraciones: [
      { id: 2001, denominacion: 'Integracion 1.2.1' },
      { id: 2002, denominacion: 'Integracion 1.2.2' },
      { id: 2003, denominacion: 'Integracion 1.2.3' },
    ],
  },
]

const NEW_TITULO_ID = 999

export const integracionesResponse = [
  {
    id: 1,
    denominacion: 'ARS',
    tituloDenominacion: 'Tituli 1 Licita Precio',
    cotizacion: 1.0,
    cantidadMinima: 1.0,
    laminaMinima: 1.0,
    tipoRedondeo: 'STANDARD',
    aliasSiopel: null,
  },
]

export const canjesResponse = [
  {
    id: 1,
    tituloDenominacion: 'Titulo 1 Licita Precio',
    precio: 1.0,
    tituloALiquidar: 'Titulo a Liquidar',
    cantidadMinima: 1.0,
    porcentaje: 10,
    laminaMinima: 1.0,
    tipoRedondeo: 'STANDARD',
    cotizacionUSD: 360,
  },
]

export const flujosResponse = [
  {
    id: 1,
    tituloDenominacion: 'Titulo 1 Licita Precio',
    monto: 800000,
    fecha: '2023-10-20T00:00:00Z',
  },
  {
    id: 2,
    tituloDenominacion: 'Titulo 1 Licita Precio',
    monto: 200000,
    fecha: '2023-11-20T00:00:00Z',
  },
]

export const corteGetResponse = {
  titulo: {
    id: 216966,
    persistenceVersion: 9,
    denominacion: 'ONE - 1386',
    colocacionId: null,
    tipo: 'VRD',
    totalEmision: 900000.0,
    tramoCompetitivo: null,
    tramoNoCompetitivo: null,
    tipoValor: 'TASA',
    valorReferencia: 10.0,
    validaValorOferta: false,
    fechaLiquidacion: '2018-04-16T03:00:00.000+00:00',
    variacionPorcentual: 10.22,
    montoMinimoAdj: null,
    montoMinimoAdjNoCompetitivo: null,
    asignadoCompetitivo: null,
    ofertasCompetitivo: null,
    valorCorte: null,
    valorCorteManual: null,
    prorrateoCorte: null,
    asignadoNoCompetitivo: null,
    ofertasNoCompetitivo: null,
    prorrateoNoCompetitivo: null,
    precioCorte: 100.0,
    maxAdjudicableNoComp: null,
    tickDePrecio: 1.0,
    porMaxTramoNoCompetitivo: 50.0,
    cantidadPagos: 0,
    corteManual: false,
    tramoCompetitivoHabilitado: true,
    codigoEspecie: null,
    codigoBolsa: null,
    maturity: null,
    duration: null,
    tipoProrrateoCompetitivo: 'TODOS',
    maximoXCuit: null,
    codigoApiComp: null,
    codigoApiNoComp: null,
    precioApiNoComp: null,
    cantidadAdjudicadaDeTodasLasOfertas: null,
    descripcion: 'ONE - 1386 - VRD - MONEDA DE EMISION PESOS - %',
    monedaEmision: 'PESOS',
    monedaLiquidacion: 'PESOS',
    integraciones: [
      {
        id: 1,
        persistenceVersion: 0,
        denominacion: 'Integracion Prueba',
        tituloColocacionId: null,
        tituloDenominacion: null,
        cotizacion: 1000.0,
        cantidadMinima: 1000.0,
        laminaMinima: 1.0,
        tipoRedondeo: 'UP',
        aliasSiopel: 'alias',
        totalOfertado: null,
        totalAdjudicado: null,
        totalIntegrado: null,
      },
    ],
  },
  context: {
    valorCorteAdjudicado: 10.0,
    corteManual: false,
    precioCorte: 100.0,
    totalOfertado: 1093291.0,
    totalAdjudicado: 900000.0,
    porcentajeOfertado: 121.47678,
    totalOfertadoComp: 952391.0,
    adjudicadoComp: 759100.0,
    prorrateoComp: 0.819573,
    totalOfertadoNoComp: 140900.0,
    adjudicadoNoComp: 140900.0,
    prorrateoNoComp: 1,
    integraciones: [
      {
        integracionTitulo: {
          id: 1,
          persistenceVersion: 0,
          denominacion: 'Integracion Prueba',
          tituloColocacionId: 216966,
          tituloDenominacion: 'ONE - 1386',
          cotizacion: 1000.0,
          cantidadMinima: 1000.0,
          laminaMinima: 1.0,
          tipoRedondeo: 'UP',
          aliasSiopel: 'alias',
          totalOfertado: null,
          totalAdjudicado: null,
          totalIntegrado: null,
        },
        sumOfertasIntegradas: 292521.0,
        sumAdjudicadoIntegrado: 243709.0,
        sumIntegrado: 243709000.0,
      },
    ],
  },
  totalEmision: 900000.0,
  valorCorte: null,
  iterar: false,
  action: null,
}

export const cortePostResponse = {
  titulo: {
    id: 216966,
    persistenceVersion: 9,
    denominacion: 'ONE - 1386',
    colocacionId: null,
    tipo: 'VRD',
    totalEmision: 900000.0,
    tramoCompetitivo: null,
    tramoNoCompetitivo: null,
    tipoValor: 'TASA',
    valorReferencia: 10.0,
    validaValorOferta: false,
    fechaLiquidacion: '2018-04-16T03:00:00.000+00:00',
    variacionPorcentual: 10.22,
    montoMinimoAdj: null,
    montoMinimoAdjNoCompetitivo: null,
    asignadoCompetitivo: null,
    ofertasCompetitivo: null,
    valorCorte: null,
    valorCorteManual: null,
    prorrateoCorte: null,
    asignadoNoCompetitivo: null,
    ofertasNoCompetitivo: null,
    prorrateoNoCompetitivo: null,
    precioCorte: 100.0,
    maxAdjudicableNoComp: null,
    tickDePrecio: 1.0,
    porMaxTramoNoCompetitivo: 50.0,
    cantidadPagos: 0,
    corteManual: false,
    tramoCompetitivoHabilitado: true,
    codigoEspecie: null,
    codigoBolsa: null,
    maturity: null,
    duration: null,
    tipoProrrateoCompetitivo: 'TODOS',
    maximoXCuit: null,
    codigoApiComp: null,
    codigoApiNoComp: null,
    precioApiNoComp: null,
    cantidadAdjudicadaDeTodasLasOfertas: null,
    descripcion: 'ONE - 1386 - VRD - MONEDA DE EMISION PESOS - %',
    monedaEmision: 'PESOS',
    monedaLiquidacion: 'PESOS',
    integraciones: [
      {
        id: 1,
        persistenceVersion: 0,
        denominacion: 'Integracion Prueba',
        tituloColocacionId: null,
        tituloDenominacion: null,
        cotizacion: 1000.0,
        cantidadMinima: 1000.0,
        laminaMinima: 1.0,
        tipoRedondeo: 'UP',
        aliasSiopel: 'alias',
        totalOfertado: null,
        totalAdjudicado: null,
        totalIntegrado: null,
      },
    ],
  },
  context: {
    valorCorteAdjudicado: 1.0,
    corteManual: true,
    precioCorte: 100.0,
    totalOfertado: 1093291.0,
    totalAdjudicado: 37500.0,
    porcentajeOfertado: 121.47678,
    totalOfertadoComp: 952391.0,
    adjudicadoComp: 18750.0,
    prorrateoComp: 1,
    totalOfertadoNoComp: 140900.0,
    adjudicadoNoComp: 18750.0,
    prorrateoNoComp: 0.1330731,
    integraciones: [
      {
        integracionTitulo: {
          id: 1,
          persistenceVersion: 0,
          denominacion: 'Integracion Prueba',
          tituloColocacionId: 216966,
          tituloDenominacion: 'ONE - 1386',
          cotizacion: 1000.0,
          cantidadMinima: 1000.0,
          laminaMinima: 1.0,
          tipoRedondeo: 'UP',
          aliasSiopel: 'alias',
          totalOfertado: null,
          totalAdjudicado: null,
          totalIntegrado: null,
        },
        sumOfertasIntegradas: 292521.0,
        sumAdjudicadoIntegrado: 37500.0,
        sumIntegrado: 37500000.0,
      },
    ],
  },
  totalEmision: 900000,
  valorCorte: 1,
  iterar: false,
  action: null,
}

const ofertasPorValorResponse = {
  titulo: {
    id: 216966,
    persistenceVersion: 9,
    denominacion: 'ONE - 1386',
    colocacionId: 1386,
    tipo: 'VRD',
    totalEmision: 900000.0,
    tramoCompetitivo: {
      cantidadMinimaOferta: 10.0,
      cantidadMaximaOferta: 1000000.0,
      multiploOferta: 1.0,
      precioMinimoOferta: null,
      precioMaximoOferta: null,
      unidadProrrateo: 1.0,
      montoMinimoAdj: null,
      porcentajeMaxAdj: null,
      tickDePrecio: 1.0,
      habilitado: true,
      codigoApi: null,
      precioApi: null,
    },
    tramoNoCompetitivo: {
      cantidadMinimaOferta: null,
      cantidadMaximaOferta: 1000000.0,
      multiploOferta: 1.0,
      precioMinimoOferta: null,
      precioMaximoOferta: null,
      unidadProrrateo: 1.0,
      montoMinimoAdj: null,
      porcentajeMaxAdj: 50.0,
      tickDePrecio: null,
      habilitado: false,
      codigoApi: null,
      precioApi: null,
    },
    tipoValor: 'TASA',
    valorReferencia: 10.0,
    validaValorOferta: false,
    fechaLiquidacion: '2018-04-16T03:00:00.000+00:00',
    variacionPorcentual: 10.22,
    montoMinimoAdj: null,
    montoMinimoAdjNoCompetitivo: null,
    asignadoCompetitivo: null,
    ofertasCompetitivo: null,
    valorCorte: null,
    valorCorteManual: null,
    prorrateoCorte: null,
    asignadoNoCompetitivo: null,
    ofertasNoCompetitivo: null,
    prorrateoNoCompetitivo: null,
    precioCorte: 100.0,
    maxAdjudicableNoComp: null,
    tickDePrecio: 1.0,
    porMaxTramoNoCompetitivo: 50.0,
    cantidadPagos: 0,
    corteManual: false,
    tramoCompetitivoHabilitado: true,
    codigoEspecie: null,
    codigoBolsa: null,
    maturity: null,
    duration: null,
    tipoProrrateoCompetitivo: 'TODOS',
    maximoXCuit: null,
    codigoApiComp: null,
    codigoApiNoComp: null,
    precioApiNoComp: null,
    cantidadAdjudicadaDeTodasLasOfertas: null,
    descripcion: 'ONE - 1386 - VRD - MONEDA DE EMISION PESOS - %',
    monedaEmision: 'PESOS',
    monedaLiquidacion: 'PESOS',
    integraciones: [
      {
        id: 1,
        persistenceVersion: 0,
        denominacion: 'Integracion Prueba',
        tituloColocacionId: 216966,
        tituloDenominacion: 'ONE - 1386',
        cotizacion: 1000.0,
        cantidadMinima: 1000.0,
        laminaMinima: 1.0,
        tipoRedondeo: 'UP',
        aliasSiopel: 'alias',
        totalOfertado: null,
        totalAdjudicado: null,
        totalIntegrado: null,
      },
    ],
  },
  items: [
    {
      valor: null,
      monto: 140900.0,
      acumuladoConNoCpmpetitivo: 140900.0,
      acumulado: null,
      cantidad: 50,
      promedioTasa: null,
      ofertaId: 58685,
      montoOfertado: 140900.0,
    },
    {
      valor: 1.0,
      monto: 18750.0,
      acumuladoConNoCpmpetitivo: 159650.0,
      acumulado: 18750.0,
      cantidad: 3,
      promedioTasa: 1.0,
      ofertaId: 59083,
      montoOfertado: 18750.0,
    },
    {
      valor: 5.0,
      monto: 2764.0,
      acumuladoConNoCpmpetitivo: 162414.0,
      acumulado: 21514.0,
      cantidad: 4,
      promedioTasa: 3.2857143,
      ofertaId: 59093,
      montoOfertado: 2764.0,
    },
    {
      valor: 6.0,
      monto: 1554.0,
      acumuladoConNoCpmpetitivo: 163968.0,
      acumulado: 23068.0,
      cantidad: 2,
      promedioTasa: 3.8888889,
      ofertaId: 59090,
      montoOfertado: 1554.0,
    },
    {
      valor: 7.0,
      monto: 1316.0,
      acumuladoConNoCpmpetitivo: 165284.0,
      acumulado: 24384.0,
      cantidad: 3,
      promedioTasa: 4.6666667,
      ofertaId: 59105,
      montoOfertado: 1316.0,
    },
    {
      valor: 8.0,
      monto: 10.0,
      acumuladoConNoCpmpetitivo: 165294.0,
      acumulado: 24394.0,
      cantidad: 1,
      promedioTasa: 4.9230769,
      ofertaId: 59141,
      montoOfertado: 10.0,
    },
    {
      valor: 9.0,
      monto: 950.0,
      acumuladoConNoCpmpetitivo: 166244.0,
      acumulado: 25344.0,
      cantidad: 3,
      promedioTasa: 5.6875,
      ofertaId: 59027,
      montoOfertado: 950.0,
    },
    {
      valor: 10.0,
      monto: 900870.0,
      acumuladoConNoCpmpetitivo: 1067114.0,
      acumulado: 926214.0,
      cantidad: 14,
      promedioTasa: 7.7,
      ofertaId: 42089,
      montoOfertado: 900870.0,
    },
    {
      valor: 12.0,
      monto: 6000.0,
      acumuladoConNoCpmpetitivo: 1073114.0,
      acumulado: 932214.0,
      cantidad: 1,
      promedioTasa: 7.8387097,
      ofertaId: 58921,
      montoOfertado: 6000.0,
    },
    {
      valor: 19.3,
      monto: 505.0,
      acumuladoConNoCpmpetitivo: 1073619.0,
      acumulado: 932719.0,
      cantidad: 1,
      promedioTasa: 8.196875,
      ofertaId: 61103,
      montoOfertado: 505.0,
    },
    {
      valor: 147.0,
      monto: 628.0,
      acumuladoConNoCpmpetitivo: 1074247.0,
      acumulado: 933347.0,
      cantidad: 2,
      promedioTasa: 16.3617647,
      ofertaId: 59099,
      montoOfertado: 628.0,
    },
    {
      valor: 790.0,
      monto: 555.0,
      acumuladoConNoCpmpetitivo: 1074802.0,
      acumulado: 933902.0,
      cantidad: 1,
      promedioTasa: 38.4657143,
      ofertaId: 58770,
      montoOfertado: 555.0,
    },
    {
      valor: 999.0,
      monto: 8600.0,
      acumuladoConNoCpmpetitivo: 1083402.0,
      acumulado: 942502.0,
      cantidad: 1,
      promedioTasa: 65.1472222,
      ofertaId: 58740,
      montoOfertado: 8600.0,
    },
    {
      valor: 1888.0,
      monto: 888.0,
      acumuladoConNoCpmpetitivo: 1084290.0,
      acumulado: 943390.0,
      cantidad: 1,
      promedioTasa: 114.4135135,
      ofertaId: 58750,
      montoOfertado: 888.0,
    },
    {
      valor: 12222.0,
      monto: 9001.0,
      acumuladoConNoCpmpetitivo: 1093291.0,
      acumulado: 952391.0,
      cantidad: 1,
      promedioTasa: 433.0342105,
      ofertaId: 58742,
      montoOfertado: 9001.0,
    },
  ],
}

export const getTituloResponse = (id: number) => {
  const titulos = titulosResponse.filter((col) => col.id == id)
  return titulos !== undefined && titulos.length > 0 ? titulos[0] : {}
}

export const mockTitulos = (mock: AxiosMockAdapter) => {
  mock.onGet('/api/colocaciones/:id/titulos').reply((config: any) => {
    return [200, titulosResponse]
  })

  mock.onGet('/api/titulos/:id').reply((config: any) => {
    const { id } = config.routeParams
    return [200, getTituloResponse(id)]
  })

  mock.onPut('/api/titulos/:id').reply((config: any) => {
    const { id } = config.routeParams
    return [200, getTituloResponse(id)]
  })

  mock.onPost('/api/titulos').reply((config: any) => {
    const json = JSON.parse(config?.data)
    newTitulo = { ...json, id: NEW_TITULO_ID, integraciones: [], canjes: [], flujoFondos: [] }
    return [200, newTitulo]
  })

  //integraciones
  mock.onGet('/api/titulos/:id/integraciones').reply((config: any) => {
    const { id } = config.routeParams
    if (id == NEW_TITULO_ID) {
      return [200, newTitulo.integraciones]
    }
    return [200, integracionesResponse]
  })

  mock.onPost('/api/titulos/:id/integraciones').reply(() => {
    return [200, integracionesResponse[0]]
  })

  mock.onPut('/api/titulos/11/integraciones/:id').reply(() => {
    return [200, integracionesResponse[0]]
  })

  mock.onDelete('/api/titulos/11/integraciones/:id').reply(() => {
    return [200, integracionesResponse[0]]
  })

  //canjes
  mock.onGet('/api/titulos/:id/canjes').reply((config: any) => {
    const { id } = config.routeParams
    if (id == NEW_TITULO_ID) {
      return [200, newTitulo.canjes]
    }
    return [200, canjesResponse]
  })

  mock.onPost('/api/titulos/:id/canjes').reply(() => {
    return [200, canjesResponse[0]]
  })

  mock.onPut('/api/titulos/11/canjes/:id').reply(() => {
    return [200, canjesResponse[0]]
  })

  mock.onDelete('/api/titulos/11/canjes/:id').reply(() => {
    return [200, canjesResponse[0]]
  })

  //flujo de fondos
  mock.onGet('/api/titulos/:id/flujo-fondos').reply((config: any) => {
    const { id } = config.routeParams
    if (id == NEW_TITULO_ID) {
      return [200, newTitulo.flujoFondos]
    }
    return [200, flujosResponse]
  })

  mock.onPost('/api/titulos/:id/flujo-fondos').reply(() => {
    return [200, flujosResponse[0]]
  })

  mock.onPut('/api/titulos/11/flujo-fondos/:id').reply(() => {
    return [200, flujosResponse[0]]
  })

  mock.onDelete('/api/titulos/11/flujo-fondos/:id').reply(() => {
    return [200, flujosResponse[0]]
  })

  // Simulación corte
  mock.onGet('/api/titulos/:id/calculo-corte').reply((config: any) => {
    return [200, corteGetResponse]
  })

  mock.onPost('/api/titulos/:id/calculo-corte').reply((config: any) => {
    return [200, cortePostResponse]
  })

  // Ofertas por valor
  mock.onGet('/api/titulos/:id/ofertas-valor').reply((config: any) => {
    return [200, ofertasPorValorResponse]
  })
}

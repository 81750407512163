import api, { setApiIdToken } from '../api'
import { resolveBackendUrl } from '../api/resourceApi'

export enum AuthenticationErrorMessage {
  INVALID_CREDENTIALS = 'Credenciales inválidas: Usuario y/o contraseña incorrecta',
  ERR_BAD_REQUEST = 'Requerimiento errones. Comuniquese con soporte.',
  UNKNOWN = 'El servicio no se encuentra disponible',
}

export enum AuthenticationError {
  INVALID_CREDENTIALS = 'INVALID_CREDENTIALS',
  ERR_BAD_REQUEST = 'ERR_BAD_REQUEST',
  UNKNOWN = 'UNKNOWN',
}

export interface ServiceResponse {
  success: boolean
  error?: string
  errorCode?: AuthenticationError
}

export interface AuthenticationServiceResponse extends ServiceResponse {
  token?: string | undefined
  userInfo?: UserInfo | undefined
}

const getErrorCode = (error) => {
  const status = error?.status || error?.response?.status
  return status === 401 || status === 403
    ? AuthenticationError.INVALID_CREDENTIALS
    : AuthenticationError.UNKNOWN
}

const account = async (token: string): Promise<AuthenticationServiceResponse> => {
  setApiIdToken(token)

  return api
    .get(resolveBackendUrl('/api/account'))
    .then((response) => {
      const data: any = response.data
      return {
        success: true,
        token,
        userInfo: {
          ...data,
        },
      }
    })
    .catch((error) => {
      console.log(`DEBUG authenticationService account error ${JSON.stringify(error)}`)
      return { success: false, error: error, errorCode: getErrorCode(error) }
    })
}

const login = async (params: any): Promise<AuthenticationServiceResponse> => {
  const { username, password } = params

  return api
    .post(resolveBackendUrl('/api/authenticate'), { username, password })
    .then((response) => {
      const data: any = response.data
      if (!data.idToken) {
        return {
          success: false,
          error: 'Token no especificado',
          errorCode: AuthenticationError.UNKNOWN,
        }
      } else {
        return account(data.idToken)
      }
    })
    .catch((error) => {
      console.log(`DEBUG authenticationService login error ${JSON.stringify(error)}`)
      return { success: false, error: error, errorCode: getErrorCode(error) }
    })
}

const authenticationService = {
  login,

  account,

  signout: async (): Promise<ServiceResponse> => {
    //const authContext: AuthContextInfo = useAuthContext()
    return api
      .post(resolveBackendUrl('/api/signout'), {})
      .then(() => {
        return { success: true }
      })
      .catch((error) => {
        console.log(`DEBUG authenticationService error ${JSON.stringify(error)}`)
        return { success: false, error: error, errorCode: getErrorCode(error) }
      })
  },
}

export { authenticationService }

import AxiosMockAdapter from 'axios-mock-adapter-path-params'

export const agentesResponse = [
  {
    id: 1,
    idOrganizacion: 'AG.001',
    denominacion: 'AEROMAR BURSATIL SOCIEDAD DE BOLSA S.A.',
    nroDepositante: 215,
    agenteBYMA: true,
    superAgente: false,
    agenteSupervisorId: null,
  },
  {
    id: 2,
    idOrganizacion: 'AG.002',
    denominacion: 'BANCO DE GALICIA Y BUENOS AIRES',
    nroDepositante: 54,
  },
  {
    id: 3,
    idOrganizacion: 'AG.003',
    denominacion: 'BANCO DE LA CIUDAD AUTONOMA DE BUENOS AIRES',
    nroDepositante: 362,
  },
  {
    id: 4,
    idOrganizacion: 'AG.004',
    denominacion: 'BANCO DE CORDOBA',
    nroDepositante: 234,
  },
  {
    id: 5,
    idOrganizacion: 'AG.005',
    denominacion: 'BANCO REGIONAL DE CUYO',
    nroDepositante: 98,
  },
  {
    id: 6,
    idOrganizacion: 'AG.006',
    denominacion: 'BANCO DE LA PROVINCIA DE SANTA FE',
    nroDepositante: 675,
  },
  {
    id: 7,
    idOrganizacion: 'AG.007',
    denominacion: 'BANCO BANEX',
    nroDepositante: 555,
  },
  {
    id: 8,
    idOrganizacion: 'AG.008',
    denominacion: 'BANCO SANTANDER RIO',
    nroDepositante: 43,
  },
  {
    id: 9,
    idOrganizacion: 'AG.009',
    denominacion: 'BANCO FRANCES',
    nroDepositante: 88,
  },
]

export const getAgenteResponse = (id: number) => {
  const agentes = agentesResponse.filter((col) => col.id == id)
  return agentes !== undefined && agentes.length > 0 ? agentes[0] : {}
}

export const mockAgentes = (mock: AxiosMockAdapter) => {
  mock.onGet('/api/agentes').reply(() => {
    return [200, agentesResponse]
  })

  mock.onGet('/api/agentes/:id').reply((config: any) => {
    const { id } = config.routeParams
    return [200, getAgenteResponse(id)]
  })
}

import { numberFormat } from 'utils/number'
import { isNullOrUndefined } from 'utils/objects'

export const FLUJO_FONDOS_ENTITY_NAME = 'flujo_fondos'

export interface FlujoFondos extends BaseEntity {
  fecha: string
  tituloDenominacion: string
  tituloColocacionId: number
  monto: number
}

const flujoFondosMontoNumberFormat = numberFormat(9)

export const flujoFondosMontoNumberFormatter = (value) =>
  !isNullOrUndefined(value) ? flujoFondosMontoNumberFormat.format(value) : ''

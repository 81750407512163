export interface GrupoEconomico extends DenominableEntity {
  numero: number
  cuit: string
  aliasSiopel?: string
}

export const GRUPO_ECONOMICO_OPTIONS_KEY = 'GRUPO_ECONOMICO_OPTIONS'

export const GRUPO_ECONOMICO_ENTITY_NAME = 'grupoEconomico'

export enum OrdenGrupoEconomico {
  DENOMINACION_ASC = 'denominacion_asc',
  DENOMINACION_DESC = 'denominacion_desc',
  CODIGO_ASC = 'numero_asc',
  CODIGO_DESC = 'numero_desc',
  CODIGO_SIOPEL_ASC = 'aliasSiopel_asc',
  CODIGO_SIOPEL_DESC = 'aliasSiopel_desc',
}

export const OrdenGrupoEconomicoOptions = [
  { label: '↑ Denominación', value: OrdenGrupoEconomico.DENOMINACION_ASC },
  { label: '↓ Denominación', value: OrdenGrupoEconomico.DENOMINACION_DESC },

  { label: '↑ Código', value: OrdenGrupoEconomico.CODIGO_ASC },
  { label: '↓ Código', value: OrdenGrupoEconomico.CODIGO_DESC },

  { label: '↑ Código SIOPEL', value: OrdenGrupoEconomico.CODIGO_SIOPEL_ASC },
  { label: '↓ Código SIOPEL', value: OrdenGrupoEconomico.CODIGO_SIOPEL_DESC },
].map((opt) => ({
  label: `Ordenar por ${opt.label}`,
  optionLabel: opt.label,
  value: opt.value,
}))

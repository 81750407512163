import { formatDateWithHHMM } from 'utils/date'
import { CategoriaCliente } from 'model/categoriaCliente'
import * as ColocacionIcons from './ColocacionIcons'
import { BymaBadgeTypes } from 'components'
import { Titulo } from 'model/titulo/Titulo'

export enum EstadoColocacion {
  PREINGRESADA = 'PREINGRESADA',
  INGRESADA = 'INGRESADA',
  ABIERTA = 'ABIERTA',
  SUSPENDIDA = 'SUSPENDIDA',
  CERRADA = 'CERRADA',
  ADJUDICADA = 'ADJUDICADA',
  PREADJUDICADA = 'PREADJUDICADA',
  DESIERTA = 'DESIERTA',
  CANCELADA = 'CANCELADA',
}

export const EstadoColocacionOptions = [
  { label: 'Abierta', value: EstadoColocacion.ABIERTA },
  { label: 'Cerrada', value: EstadoColocacion.CERRADA },
  { label: 'Ingresada', value: EstadoColocacion.INGRESADA },
  { label: 'Adjudicada', value: EstadoColocacion.ADJUDICADA },
  { label: 'Preadjudicada', value: EstadoColocacion.PREADJUDICADA },
  { label: 'Suspendida', value: EstadoColocacion.SUSPENDIDA },
  { label: 'Desierta', value: EstadoColocacion.DESIERTA },
  { label: 'Cancelada', value: EstadoColocacion.CANCELADA },
]

export const COLOCACION_ESTADO_INFO = {
  [EstadoColocacion.ABIERTA]: {
    icon: ColocacionIcons.EstadoColocacionAbiertaIcon,
    badgeType: 'active',
    label: 'Abierta',
  },
  [EstadoColocacion.INGRESADA]: {
    icon: ColocacionIcons.EstadoColocacionIngresadaIcon,
    badgeType: 'active',
    label: 'Ingresada',
  },
  [EstadoColocacion.ADJUDICADA]: {
    icon: ColocacionIcons.EstadoColocacionAdjudicadaIcon,
    badgeType: 'info',
    label: 'Adjudicada',
  },
  [EstadoColocacion.PREADJUDICADA]: {
    icon: ColocacionIcons.EstadoColocacionPreAdjudicadaIcon,
    badgeType: 'warning',
    label: 'PreAdjudicada',
  },
  [EstadoColocacion.CERRADA]: {
    icon: ColocacionIcons.EstadoColocacionCerradaIcon,
    badgeType: 'error',
    label: 'Cerrada',
  },
  [EstadoColocacion.SUSPENDIDA]: {
    icon: ColocacionIcons.EstadoColocacionSuspendidaIcon,
    badgeType: 'error',
    label: 'Suspendida',
  },
  [EstadoColocacion.CANCELADA]: {
    icon: ColocacionIcons.EstadoColocacionCanceladaIcon,
    badgeType: 'error',
    label: 'Cancelada',
  },
  [EstadoColocacion.DESIERTA]: {
    icon: ColocacionIcons.EstadoColocacionDesiertaIcon,
    badgeType: 'error',
    label: 'Desierta',
  },
  [EstadoColocacion.PREINGRESADA]: {
    icon: ColocacionIcons.EstadoColocacionPreIngresadaIcon,
    badgeType: 'error',
    label: 'PreIngresada',
  },
}

export const COLOCACION_ESTADO_INFO_DEFAULT = {
  icon: ColocacionIcons.EstadoColocacionAbiertaIcon,
  badgeType: 'unknown',
  label: '',
}

export function getColocacionEstadoInfo(estado?: EstadoColocacion) {
  const result = estado ? COLOCACION_ESTADO_INFO[estado] : undefined
  return result ? result : COLOCACION_ESTADO_INFO_DEFAULT
}

export function getColocacionEstadoLabel(estado: EstadoColocacion) {
  return estado ? COLOCACION_ESTADO_INFO[estado]?.label : ''
}

export function getColocacionEstadoIcon(estado: EstadoColocacion) {
  return estado ? COLOCACION_ESTADO_INFO[estado]?.icon : COLOCACION_ESTADO_INFO_DEFAULT.icon
}

export function getColocacionEstadoBadgeType(estado?: EstadoColocacion): BymaBadgeTypes {
  return estado
    ? (COLOCACION_ESTADO_INFO[estado]?.badgeType as BymaBadgeTypes)
    : (COLOCACION_ESTADO_INFO_DEFAULT.badgeType as BymaBadgeTypes)
}

/**
 * Permisos informados por el Backend
 */
export interface PermisosColocacion {
  crearOfertaPropiaEnabled: boolean
  crearOfertaEnNombreDeEnabled: boolean
  crearOfertaUnderwriterEnabled: boolean
  crearOfertaRapidaEnabled: boolean
  crearOfertaExtendidaEnabled: boolean
  verAdjudicacionEnabled: boolean
  verLibroOfertasEnabled: boolean
}

export enum MetodoAdjudicacion {
  AUTOMATICO = 'AUTOMATICO',
  BOOK_BUILDING = 'BOOK_BUILDING',
  CRONOLOGICO = 'CRONOLOGICO',
  CAP = 'CAP',
}

export const MetodoAdjudicacionOptions = [
  { value: MetodoAdjudicacion.AUTOMATICO, label: 'Automático' },
  { value: MetodoAdjudicacion.BOOK_BUILDING, label: 'Book Building' },
  { value: MetodoAdjudicacion.CRONOLOGICO, label: 'Cronológico' },
]

export enum ModalidadOfertasColocacion {
  ABIERTA = 'ABIERTA',
  CERRADA = 'CERRADA',
}

export const ModalidadOfertasColocacionOptions = [
  { value: ModalidadOfertasColocacion.ABIERTA, label: 'Abierta' },
  { value: ModalidadOfertasColocacion.CERRADA, label: 'Cerrada' },
]

export const modalidadOfertasColocacionToBoolean = (m) => {
  return m === ModalidadOfertasColocacion.ABIERTA ? true : false
}

export const booleanToModalidadOfertasColocacion = (b) => {
  return b ? ModalidadOfertasColocacion.ABIERTA : ModalidadOfertasColocacion.CERRADA
}

export interface ColocacionCreateDTO {
  denominacion: string

  //FK Emisor
  emisorId: Identifier

  //FK Colocador
  //Usar ColocadorOptionsQueryProps
  colocadorId: Identifier

  //FK TipoColocacion
  //Usar TipoColocacionOptionsQueryProps
  tipoColocacionId: Identifier

  metodoAdjudicacion: MetodoAdjudicacion

  sustentable: boolean

  totalEmision: number

  //TODO PABLO agregar en el backend el nuevo campo
  //y agregar la logica para 'supportedFields' en base a este campo
  admiteCAP: boolean

  //Corresponde a campo 'Modalidad'
  //Ver ModalidadColocacionOptions (se implementa como un dropdown)
  ofertasAbiertas: boolean
  modalidadOfertas: ModalidadOfertasColocacion

  interaccionSiopel: boolean

  rueda?: string

  //FK EnteLiquidador
  //Usar EnteLiquidadorOptionsQueryProps
  enteLiquidadorId: Identifier

  //TODO los campos fecha y hora en el form deberian convertirse a un solo campo antes de enviarse al backend
  //Date (fecha + hora)
  fechaInicio: string

  //Date (fecha + hora)
  fechaCierre: string

  //Date (fecha + hora)
  difusionDesde: string

  //Date (fecha + hora)
  difusionHasta: string

  //Date (fecha + hora)
  limiteRetiroOfertas: string

  cotizacion?: number

  cuitObligatorio: boolean

  //Numerico entero
  precision: number
}

export interface Colocacion extends BaseEntity {
  id: number
  denominacion: string
  fechaAlta: string
  fechaCierre: string
  colocadorDenominacion: string
  emisorDenominacion: string
  totalEmision: number
  permisos: PermisosColocacion
  estado: EstadoColocacion
  canje?: boolean
  titulos: Titulo[]
  categoriasCliente?: CategoriaCliente[]
  ofertaUnsupportedFields?: string[]
  tituloUnsupportedFields?: string[]
}

export enum TituloAction {
  OFERTA_RAPIDA = 'OFERTA_RAPIDA',
  OFERTA_EXTENDIDA = 'OFERTA_EXTENDIDA',
  VER_ADJUDUCACION = 'VER_ADJUDICACION',
  VER_LIBRO = 'VER_LIBRO',
}

export const TituloActionLabel = {
  [TituloAction.OFERTA_RAPIDA]: 'Oferta rápida',
  [TituloAction.OFERTA_EXTENDIDA]: 'Oferta extendida',
  [TituloAction.VER_ADJUDUCACION]: 'Ver Adjudicación',
  [TituloAction.VER_LIBRO]: 'Ver Libro',
}

export enum ColocacionCommand {
  PREINGRESAR = 'PREINGRESAR',
  INGRESAR = 'INGRESAR',
  ABRIR = 'ABRIR',
  SUSPENDER = 'SUSPENDER',
  CERRAR = 'CERRAR',
  PREADJUDICAR = 'PREADJUDICAR',
  REVERTIR_PREADJUDICACION = 'REVERTIR_PREADJUDICACION',
  ADJUDICAR = 'ADJUDICAR',
  DESIERTA = 'DESIERTA',
  CANCELAR = 'CANCELAR',
  REVERTIR_ADJUDICACION = 'REVERTIR_ADJUDICACION',
}

export const ColocacionCommandOptions = [
  { value: ColocacionCommand.PREINGRESAR, label: 'PreIngresar' },
  { value: ColocacionCommand.INGRESAR, label: 'Ingresar' },
  { value: ColocacionCommand.ABRIR, label: 'Abrir' },
  { value: ColocacionCommand.SUSPENDER, label: 'Suspender' },
  { value: ColocacionCommand.CERRAR, label: 'Cerrar' },
  { value: ColocacionCommand.PREADJUDICAR, label: 'PreAdjudicar' },
  { value: ColocacionCommand.REVERTIR_PREADJUDICACION, label: 'Revertir PreAdjudicacion' },
  { value: ColocacionCommand.ADJUDICAR, label: 'Adjudicar' },
  { value: ColocacionCommand.DESIERTA, label: 'Desierta' },
  { value: ColocacionCommand.CANCELAR, label: 'Cancelar' },
  { value: ColocacionCommand.REVERTIR_ADJUDICACION, label: 'Revertir Adjudicacion' },
]

const ColocacionCommandMap = {
  [EstadoColocacion.PREINGRESADA]: [ColocacionCommand.INGRESAR],
  [EstadoColocacion.INGRESADA]: [ColocacionCommand.ABRIR, ColocacionCommand.CANCELAR],
  [EstadoColocacion.ABIERTA]: [ColocacionCommand.CERRAR, ColocacionCommand.SUSPENDER],
  [EstadoColocacion.CERRADA]: [
    ColocacionCommand.PREADJUDICAR,
    ColocacionCommand.SUSPENDER,
    ColocacionCommand.DESIERTA,
  ],
  [EstadoColocacion.SUSPENDIDA]: [ColocacionCommand.ABRIR],
  [EstadoColocacion.PREADJUDICADA]: [
    ColocacionCommand.REVERTIR_PREADJUDICACION,
    ColocacionCommand.ADJUDICAR,
  ],
  [EstadoColocacion.ADJUDICADA]: [ColocacionCommand.REVERTIR_ADJUDICACION],
}

export const getColocacionEnabledCommands = (colocacion?: Colocacion): ColocacionCommand[] => {
  const estado = colocacion?.estado
  const comandos = estado ? ColocacionCommandMap[estado] : []
  return comandos || []
}

export const getColocacionEnabledCommandOptions = (colocacion?: Colocacion) => {
  const commands: ColocacionCommand[] = getColocacionEnabledCommands(colocacion)
  return ColocacionCommandOptions.filter((opt) => commands.includes(opt.value))
}

export const ColocacionMethods = {
  getTituloActionDefault: (colocacion: Colocacion, createOfertaPropiaDeEnabled: boolean) =>
    colocacion.permisos.verAdjudicacionEnabled
      ? TituloAction.VER_ADJUDUCACION
      : colocacion.permisos.crearOfertaRapidaEnabled && createOfertaPropiaDeEnabled
      ? TituloAction.OFERTA_RAPIDA
      : colocacion.permisos.crearOfertaExtendidaEnabled
      ? TituloAction.OFERTA_EXTENDIDA
      : colocacion.permisos.verLibroOfertasEnabled
      ? TituloAction.VER_LIBRO
      : undefined,
}

/**
 * Fecha y Hora de Cierre si el Estado de la Colocación es Abierta, Adjudicada, Cerrada, Suspendida, Ingresada, Desierta
 * o
 * Fecha y Hora de Apertura si el Estado de la Colocación es Ingresada.
 */
export const fechaColocacionCellFormatter = (data: Colocacion) => {
  const estado = data?.estado
  return estado === EstadoColocacion.INGRESADA
    ? `Apertura: ${formatDateWithHHMM(data.fechaInicio)} hs`
    : `Cierre ${formatDateWithHHMM(data.fechaCierre)} hs`
}

export function getTituloColocacionById(colocacion: Colocacion, tituloId: Identifier) {
  return colocacion.titulos.find((titulo) => titulo.id === tituloId)
}

export const COLOCACION_ENTITY_NAME = 'colocacion'

export enum TipoDocumento {
  CUIT = 'CUIT',
  CUIL = 'CUIL',
  CDI = 'CDI',
  CIE = 'CIE',
  FCICNV = 'FCICNV',
  SSN = 'SSN',
  LEI = 'LEI',
}

export enum TipoCuenta {
  COMITENTE = 'CUIT',
  FCICNV = 'FCICNV',
  SSN = 'SSN',
  LEI = 'LEI',
  CIE = 'CIE',
  CDI = 'CDI',
}

export const TipoCuentaOptions = [
  { value: TipoCuenta.COMITENTE, label: 'Comitente' },
  { value: TipoCuenta.FCICNV, label: 'FCI' },
  { value: TipoCuenta.SSN, label: 'Aseguradora' },
]

export const TipoCuentaExtendidaOptions = [
  ...TipoCuentaOptions,

  { value: TipoCuenta.LEI, label: 'Extranjero LEI' },
  { value: TipoCuenta.CDI, label: 'Extranjero CDI' },
  { value: TipoCuenta.CIE, label: 'Extranjero CIE' },
]

/**
 * Corresponde a la entidad PorcentajeInversion
 */
export interface Inversor extends DenominableEntity {
  tipoDocumento: TipoDocumento
  numero: string

  grupoEconomicoCuit: string
  grupoEconomicoId: Identifier
  grupoEconomicoDenominacion: string
}

export const INVERSOR_ENTITY_NAME = 'inversor'

export enum OrdenInversor {
  DENOMINACION_ASC = 'denominacion_asc',
  DENOMINACION_DESC = 'denominacion_desc',
  CODIGO_ASC = 'numero_asc',
  CODIGO_DESC = 'numero_desc',
}

export const OrdenInversorOptions = [
  { label: '↑ Denominación', value: OrdenInversor.DENOMINACION_ASC },
  { label: '↓ Denominación', value: OrdenInversor.DENOMINACION_DESC },

  { label: '↑ Código', value: OrdenInversor.CODIGO_ASC },
  { label: '↓ Código', value: OrdenInversor.CODIGO_DESC },
].map((opt) => ({
  label: `Ordenar por ${opt.label}`,
  optionLabel: opt.label,
  value: opt.value,
}))

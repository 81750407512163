import { useState, useMemo, useCallback } from 'react'
import { useAuthContext, isComponentAuthorized } from 'core'
import * as BymaPermissions from 'services/auth/bymaPermissions'
import {
  Titulo,
  Colocacion,
  TituloAction,
  OFERTA_ENTITY_NAME,
  OfertaCreationInfo,
  EstadoOferta,
} from 'model'
import { isNullOrUndefined } from 'utils/objects'

export const OFERTA_DASHBOARD_LIBRO_QUERY_KEY = OFERTA_ENTITY_NAME + '.dashboard.libro'
export const OFERTA_DASHBOARD_PROPIAS_QUERY_KEY = OFERTA_ENTITY_NAME + '.dashboard.propias'

export interface OfertaContextInfo {
  showOfertaRapida: boolean
  setShowOfertaRapida: (b: boolean) => void

  showOfertaExtendida: boolean
  setShowOfertaExtendida: (b: boolean) => void

  currentColocacion: Colocacion | undefined
  setCurrentColocacion: (c: Colocacion | undefined) => void
  currentTitulo: Titulo | undefined
  setCurrentTitulo: (t: Titulo | undefined) => void

  tipoAltaOferta?: TituloAction
  setTipoAltaOferta: (t: TituloAction | undefined) => void

  createOfertaEnNombreDeEnabled: boolean
  createOfertaPropiaEnabled: boolean

  isFieldEnabled: (field: string) => boolean
  isFieldReadOnly: (field: string) => boolean

  /**
   * Identificador de la Oferta (en caso de que se trate de una edicion)
   */
  ofertaId?: Identifier
  setOfertaId: (id: Identifier | undefined) => void

  /**
   * Datos de la oferta que se va a editar (se utiliza para calcular la modalidad de los campos)
   */
  oferta?: OfertaCreationInfo
  setOferta: (oferta: OfertaCreationInfo | undefined) => void

  readOnly?: boolean
  setReadOnly: (boolean) => void

  editEnabled: boolean
  setEditEnabled: (boolean) => void

  queryCount?: number
}

const defaultOfertaContextInfo: OfertaContextInfo = {
  showOfertaExtendida: false,
  setShowOfertaExtendida: () => {},

  showOfertaRapida: false,
  setShowOfertaRapida: () => {},

  currentColocacion: undefined,
  setCurrentColocacion: () => {},

  currentTitulo: undefined,
  setCurrentTitulo: () => {},
  isFieldEnabled: () => true,
  isFieldReadOnly: () => false,

  setOfertaId: () => {},

  setOferta: () => {},

  setReadOnly: () => {},

  createOfertaEnNombreDeEnabled: false,
  createOfertaPropiaEnabled: false,

  tipoAltaOferta: undefined,
  setTipoAltaOferta: () => {},

  editEnabled: true,
  setEditEnabled: () => {},
}

/**
 *
 * @param oferta Tomado de main/src/merval/colocaciones/ui/oferta/OfertaDetailController.java.txt metodo configureEditableFields
 */
const calcReadOnlyFields = (oferta) => {
  const result = {}

  if (!oferta) {
    return result
  }

  /*
  Los campos que se pueden editar en una colocación son:
Cantidad
Tasa Cupón
Integración
CAP
Nacionalidad

Para las colocaciones que no son de Siopel (esto lo tengo que chequear,,,, pero no me esta andando Siopel), también se puede modificar:
Cuenta 
Número 
Comitente 
CUIT
Esto de acuerdo al comportamiento de cada tipo de Cuenta.

En una colocación Bookbuilding se puede modificar el Estado.
  */

  // oferta.permiteModificarCantidadAdjudicada() && ColocacionesSecurity.permiteModificarCantidadAdjudicada(command);
  result['montoAdjudicado'] = true

  result['fechaAlta'] = true
  result['tituloId'] = true
  result['tipoOferta'] = true
  result['agenteId'] = true
  result['deTerceros'] = true

  result['integracionTituloId'] = false
  result['cantidad'] = false
  result['valor'] = false
  result['nacionalidadClienteId'] = false
  result['observaciones'] = false

  result['cuenta'] = oferta.interaccionSiopel
  result['numeroCliente'] = oferta.interaccionSiopel
  result['nombreCliente'] = oferta.interaccionSiopel
  result['cuit'] = oferta.interaccionSiopel
  result['duenioPorcentajeInversion'] = oferta.interaccionSiopel
  result['estado'] = oferta.interaccionSiopel

  return result
}

let globalQueryCount = 0

const useOfertaContext = (): OfertaContextInfo => {
  const [showOfertaRapida, setShowOfertaRapida] = useState<boolean>(false)
  const [showOfertaExtendida, setShowOfertaExtendida] = useState<boolean>(false)
  const [currentColocacion, setCurrentColocacion] = useState<Colocacion | undefined>(undefined)
  const [currentTitulo, setCurrentTitulo] = useState<Titulo | undefined>(undefined)
  const [tipoAltaOferta, setTipoAltaOferta] = useState<TituloAction | undefined>(undefined)
  const [ofertaId, setOfertaId] = useState<Identifier | undefined>(undefined)
  const [oferta, setOferta] = useState<OfertaCreationInfo | undefined>(undefined)
  const [readOnly, setReadOnly] = useState<boolean>(false)
  const [editEnabled, setEditEnabled] = useState<boolean>(true)

  const { userInfo } = useAuthContext()

  const createOfertaEnNombreDeEnabled = isComponentAuthorized(
    BymaPermissions.OFERTA_CREATE_EN_NOMBRE_DE_PERMISSION,
    userInfo?.authorities,
  )
    ? true
    : false

  const createOfertaPropiaEnabled = isComponentAuthorized(
    BymaPermissions.OFERTA_CREATE_PROPIA_PERMISSION,
    userInfo?.authorities,
  )
    ? true
    : false

  const isFieldEnabled = (field: string) =>
    !currentColocacion
      ? false
      : !currentColocacion.ofertaUnsupportedFields ||
        !currentColocacion.ofertaUnsupportedFields.includes(field)

  const defaultReadOnlyFields =
    !isNullOrUndefined(ofertaId) && oferta?.estado === EstadoOferta.VIGENTE

  const readOnlyFieldMap = useMemo(
    () => calcReadOnlyFields(oferta),
    [oferta, defaultReadOnlyFields],
  )

  const isFieldReadOnly = useCallback(
    (field) => {
      const readOnlyField = readOnlyFieldMap[field]
      return readOnlyField !== undefined ? readOnlyField : defaultReadOnlyFields
    },
    [readOnlyFieldMap, defaultReadOnlyFields],
  )

  const setOfertaIdImpl = (id) => {
    globalQueryCount++
    setOfertaId(id)
  }

  return {
    showOfertaRapida,
    setShowOfertaRapida,
    showOfertaExtendida,
    setShowOfertaExtendida,
    currentColocacion,
    setCurrentColocacion,
    currentTitulo,
    setCurrentTitulo,
    tipoAltaOferta,
    setTipoAltaOferta,
    createOfertaEnNombreDeEnabled,
    createOfertaPropiaEnabled,
    isFieldEnabled,
    isFieldReadOnly,
    ofertaId,
    setOfertaId: setOfertaIdImpl,
    oferta,
    setOferta,
    readOnly,
    setReadOnly,
    editEnabled,
    setEditEnabled,
    queryCount: globalQueryCount,
  }
}

export { useOfertaContext, defaultOfertaContextInfo }

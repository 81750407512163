import React, { useCallback } from 'react'

import { Col, Row } from 'react-bootstrap'

import { ListBase, LocalErrorProvider, useNotificationProducer } from 'core'

import {
  BymaButton,
  BymaDataTable,
  BymaExportButton,
  AddIcon,
  LocalErrorAlert,
  BymaCard,
} from 'components'
import { COLOCACION_ENTITY_NAME, ColocacionDocumentoApi } from 'model'
import { formatDateNoYearWithHHMMSS } from 'utils/date'

import { ColocacionSectionCard } from '../ColocacionSectionCard'
import { ColocacionEditProps, useColocacionEditContext } from '../../ColocacionViewContext'
import {
  EntityContextInfo,
  useDownloadButtonCell,
  //useEditButtonCell,
  useDeleteButtonCell,
  useEntityContext,
} from 'features/common/Entity'
import { COLOCACION_DOCUMENTO_ENTITY_NAME } from 'model/documento'

import DocumentoModal from './DocumentoModal'

import DocumentosIcon from 'assets/img/colocacion-view/documentos.svg'
import { ColocacionSummary } from 'features/Colocacion/ColocacionSummary'

const getColocacionDocumentosColumns = (
  context: EntityContextInfo,
  contentDownload: (params: any) => void,
  notificationProducer: NotificationApiContextInfo,
  readOnly?: boolean,
) => {
  return [
    {
      field: 'denominacion',
      headerName: 'Denominación del documento',
      textAlign: 'left',
    },
    {
      field: 'fileName',
      headerName: 'Documento',
      textAlign: 'left',
    },
    {
      field: 'fechaAlta',
      headerName: 'Fecha de Alta',
      textAlign: 'left',
      valueFormatter: (params) => formatDateNoYearWithHHMMSS(params?.data?.fechaAlta),
    },

    //useEditButtonCell({ context, minWidth: 40 }),

    useDownloadButtonCell({
      minWidth: 40,
      serverDownload: (data) => contentDownload(data),
      getFileName: (data) => data?.fileName,
      postDownloading: () => {
        notificationProducer.addNotification({
          message: 'Se descargo archivo',
          type: 'info',
        })
      },
    }),

    {
      ...useDeleteButtonCell({ context, minWidth: 40 }),
      hide: readOnly,
    },
  ]
}

const DocumentosSectionFields = ({ listKey, queryKey, context, documentosApi, readOnly }) => {
  const dataProvider = useCallback(
    (params) => documentosApi.getAllAsPage({ ...params }),
    [documentosApi],
  )

  const contentDownload = useCallback(
    (data) => documentosApi.downloadContent(data?.id),
    [documentosApi],
  )

  const notificationProducer = useNotificationProducer()

  return (
    <BymaCard titleBorder='none' footer={<></>}>
      <Row sm={12} className='mb-3'>
        <Col sm={12} style={{ display: 'flex', justifyContent: 'flex-end', gap: '2%' }}>
          {!readOnly && (
            <BymaButton
              id={'colocacion-nuevo-documento'}
              label='Nuevo Documento'
              name={'colocacion-nuevo-documento'}
              iconLeft={AddIcon}
              iconStyles={{ width: 12 }}
              onClick={() => {
                context.setEditEnabled(true)
                context.setShowModal(true)
              }}
            />
          )}
          <BymaExportButton
            id='colocacion-documentos-export-button'
            name='colocacion-documentos-export-button'
            serverDownload={() => documentosApi.export({})}
            getFileName={() => 'documentos.csv'}
            postDownloading={
              () => {}
              /*addNotification({
                message: 'Se descargo archivo de Ofertas',
                type: 'info',
              })*/
            }
          />
        </Col>
      </Row>
      <Row sm={12} className='mb-3'>
        <Col sm={12}>
          <BymaDataTable
            key='documentos-colocacion'
            columns={getColocacionDocumentosColumns(
              context,
              contentDownload,
              notificationProducer,
              readOnly,
            )}
            sizeColumnsToFit={true}
            style={{ height: '310px', width: '100%' }}
            pagination={false}
            sortable={false}
            showLoadingOverlay={false}
            queryProps={{
              dataProvider,
              listKey,
              queryKey,
            }}
            listPaginationProps={{
              showLimits: false,
            }}
            suppressHorizontalScroll={true}
          />
        </Col>
      </Row>
      <Row>
        <LocalErrorAlert />
      </Row>
    </BymaCard>
  )
}

export const DocumentosTable = (props: ColocacionEditProps) => {
  const documentosApi = new ColocacionDocumentoApi(props.colocacionId)

  const DOCUMENTOS_LIST_CLASSIFIER = 'colocacion.documentos'
  const LIST_KEY = [COLOCACION_ENTITY_NAME, DOCUMENTOS_LIST_CLASSIFIER]
  const QUERY_KEY = [...LIST_KEY, { id: props.colocacionId }]

  const context = useEntityContext(COLOCACION_DOCUMENTO_ENTITY_NAME, 'Documento', QUERY_KEY)

  return (
    <>
      <ColocacionSectionCard title={'Documentos'} icon={DocumentosIcon} showFooter={false}>
        <Row className='mb-3'>
          <ColocacionSummary {...props} />
        </Row>
        <Row>
          <DocumentosSectionFields
            listKey={LIST_KEY}
            queryKey={QUERY_KEY}
            context={context}
            documentosApi={documentosApi}
            readOnly={props.readOnly}
          />
        </Row>
      </ColocacionSectionCard>

      <DocumentoModal
        context={context}
        api={documentosApi}
        formId='colocacion-documentos-form'
        {...props}
      />
    </>
  )
}

const DocumentosSection = () => {
  const props = useColocacionEditContext()

  return (
    <ListBase listKey='colocacion.documentos'>
      <LocalErrorProvider>
        <DocumentosTable {...props} />
      </LocalErrorProvider>
    </ListBase>
  )
}

export default DocumentosSection

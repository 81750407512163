export const toApiError = (unknownError): ApiError => {
  console.log(`DEBUG toApiError error=${JSON.stringify(unknownError)}`)

  if (!unknownError) {
    return unknownError
  }

  if (unknownError.errorType === 'ApiError') {
    return unknownError as ApiError
  }

  const error = unknownError as any

  const responseStatus = error?.response?.status

  if (responseStatus === undefined) {
    return {
      message: 'No se pudo conectar con la aplicacion',
      errorType: 'ApiError',
    }
  }

  //TODO: usar i18n para estos mensajes
  if (responseStatus === 403) {
    return { message: 'Operacion no autorizada', errorType: 'ApiError', status: responseStatus }
  } else if (responseStatus === 500) {
    return {
      message: 'Se ha producido un error en la aplicacion',
      errorType: 'ApiError',
      status: responseStatus,
    }
  }

  //errores de validacion
  if (responseStatus === 400 && error?.response?.data) {
    return { ...error?.response?.data, errorType: 'ApiError', status: responseStatus }
  }

  return { message: '' + error, errorType: 'ApiError', status: responseStatus }
}

export const getApiErrorMessage = (error): string => {
  if (!error) {
    return 'Error de Aplicacion'
  }

  //console.log(`DEBUG getApiErrorMessage error=${JSON.stringify(error)}`)

  return error.message ? error.message : JSON.stringify(error)
}

export const getApiErrorFields = (apiError: ApiError) => {
  const details = apiError?.details
  if (!details) {
    return []
  }

  return details.filter((detail) => detail.field).map((detail) => detail.field)
}

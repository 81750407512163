import AxiosMockAdapter from 'axios-mock-adapter-path-params'

const condominosResponse = [
  {
    cuenta: '40',
    subcuenta: '1',
    orden: '01',
    tipoPersona: 'JU',
    denominacion: 'ADMINISTRACION ARAUJO Y LABANCA S.A',
    nacionalidad: '',
    identificadorTributario: 'CUIT',
    numeroIdentificador: '000030590110244',
  },
  {
    cuenta: '40',
    subcuenta: '1',
    orden: '02',
    tipoPersona: 'JU',
    denominacion: 'LABANCA EDUARDO ALEJANDRO',
    nacionalidad: 'AR',
    identificadorTributario: 'CUIT',
    numeroIdentificador: '000020114993930',
  },

  {
    cuenta: '40',
    subcuenta: '2',
    orden: '01',
    tipoPersona: 'FI',
    denominacion: 'TRENTINI LEONARDO',
    nacionalidad: '',
    identificadorTributario: 'CUIT',
    numeroIdentificador: '0000309999999',
  },
]

export const mockCondominos = (mock: AxiosMockAdapter) => {
  mock.onGet('/api/condominos').reply((config) => {
    const result = condominosResponse.filter((r) => config.params.comitente.startsWith(r.subcuenta))
    return [200, result]
  })
}

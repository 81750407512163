import React, { useState, useEffect, useMemo, useCallback } from 'react'
import { FormProvider } from 'react-hook-form'

import { Form } from 'react-bootstrap'

import { isEqual } from 'lodash'

import * as yup from 'yup'

import { BymaCard, Loading, BymaModal } from 'components'

import { useQueryClient } from 'react-query'

import { useBymaMutation } from 'core'

import {
  LoadingProvider,
  LocalErrorProvider,
  useLocalErrorProducer,
  useCreateForm,
  UseEditFormReturn,
  MutationSuccessCallbackParam,
  useEditForm,
} from 'core'

import { OfertaContextInfo, getOfertaBaseDefaultValues, getOfertaFullSchema } from '.'

import { OfertaCreationInfo, OfertasApi, OFERTA_ENTITY_NAME } from 'model'

import styles from './Ofertas.module.scss'
import './OfertaExtendida.scss'
import { OfertaExtendidaFormFields } from './OfertaExtendidaFormFields'

interface OfertaExtendidaProps {
  className?: string
  context: OfertaContextInfo
}

interface OfertaExtendidaFormProps extends OfertaExtendidaProps {
  editForm: UseEditFormReturn<OfertaCreationInfo>
}

const OFERTA_EXTENDIDA_FORM_ID = 'oferta-extendida-form'

//TODO PABLO reutilizar este hook en OfertaRapida
interface CreateOfertaFormProps {
  context: OfertaContextInfo
  onSuccess?: MutationSuccessCallbackParam<OfertaCreationInfo>
  schema: any
}

const useInvalidateOfertaQueries = () => {
  const queryClient = useQueryClient()
  //si se produce el alta => se invalidan todas las listas de ofertas

  return () => {
    queryClient.invalidateQueries({
      predicate: (query) => {
        return query.queryKey[0] === OFERTA_ENTITY_NAME
      },
    })
  }
}
const useCreateOfertaForm = ({ context, onSuccess = () => {}, schema }: CreateOfertaFormProps) => {
  const defaultValues = useMemo(() => {
    return getOfertaBaseDefaultValues(context.currentTitulo)
  }, [context.currentTitulo])

  const values = useMemo(() => {
    return getOfertaBaseDefaultValues(context.currentTitulo)
  }, [context.currentTitulo])

  const createOferta = useCallback(
    (data) => {
      const tituloId = data.tituloId || context.currentTitulo?.id
      if (tituloId) {
        return OfertasApi.create(tituloId, data)
      } else {
        throw new Error('Titulo no definido')
      }
    },
    [context.currentTitulo],
  )

  const invalidateOfertaQueries = useInvalidateOfertaQueries()

  const onSuccessImpl = useCallback(
    (data, variables) => {
      //si se produce el alta => se invalidan todas las listas de ofertas
      invalidateOfertaQueries()
      onSuccess(data, variables)
    },
    [invalidateOfertaQueries, onSuccess],
  )

  return useCreateForm<OfertaCreationInfo>({
    defaultValues,
    values,
    schema: schema,
    mutation: createOferta,
    //successNotification: (data) => `Se creo Oferta con id ${data['id']}`,
    successNotification: () => 'Se ingresó la oferta exitosamente',
    errorNotification: (error) => `Error Ingresando Oferta: ${error.message}`,
    onSuccess: onSuccessImpl,
  })
}

const usEditOfertaForm = ({ context, onSuccess = () => {}, schema }: CreateOfertaFormProps) => {
  //TODO revisar
  const defaultValues = {
    cantidad: null,
    valor: null,
  }

  const updateOferta = useCallback(
    (data) => {
      return OfertasApi.update(data)
    },
    [context.currentTitulo],
  )

  const invalidateOfertaQueries = useInvalidateOfertaQueries()

  //TODO reutilizar este callback en cualquier modificacion de oferta (creacion o edicion)
  const onSuccessImpl = useCallback(
    (data, variables) => {
      invalidateOfertaQueries()
      onSuccess(data, variables)
    },
    [invalidateOfertaQueries, onSuccess],
  )

  const getFormData = useCallback(() => {
    //console.log(`DEBUG usEditOfertaForm ${context.ofertaId}=context.ofertaId`)

    return Promise.resolve(context.oferta)
    //PRUEBA
    /*
    return context.ofertaId !== undefined
      ? OfertasApi.getOne(context.ofertaId).then((oferta) => {
          context.setOferta(oferta)
          return oferta
        })
      : Promise.resolve({})
    */
  }, [context.ofertaId, context.oferta])

  const queryKey = `OFERTA:${context.ofertaId}?count=${context.queryCount}`

  return useEditForm<OfertaCreationInfo>({
    queryKey: queryKey,
    defaultValues,
    getFormData,
    schema: schema,
    mutation: updateOferta,
    successNotification: () => 'Se actualizó la oferta exitosamente',
    errorNotification: (error) => `Error Actualizando Oferta: ${error.message}`,
    onSuccess: onSuccessImpl,
  })
}

interface CreateOfertaExtendidaFormProps {
  context: OfertaContextInfo
  onSuccess?: MutationSuccessCallbackParam<OfertaCreationInfo>
}

const useCreateOfertaExtendidaForm = (props: CreateOfertaExtendidaFormProps) => {
  const schema = useMemo(() => getOfertaFullSchema(yup, props.context), [yup, props.context])
  return useCreateOfertaForm({ ...props, schema })
}

const useEditOfertaExtendidaForm = (props: CreateOfertaExtendidaFormProps) => {
  const schema = useMemo(() => getOfertaFullSchema(yup, props.context), [yup, props.context])
  return usEditOfertaForm({ ...props, schema })
}

const OfertaExtendidaForm = (props: OfertaExtendidaFormProps) => {
  const { editForm: editForm, className, context } = props

  const currentTituloId = context.currentTitulo?.id
  const [prevTituloId, setPrevTituloId] = useState<Identifier | undefined>()

  const [prevShow, setPrevShow] = useState<boolean | undefined>()

  //en el caso de la edicion el primer campo editable es cantidad
  useEffect(() => {
    context.ofertaId ? editForm.form.setFocus('cantidad') : editForm.form.setFocus('tituloId')
  }, [context.editEnabled, context.ofertaId])

  const localErrorApi = useLocalErrorProducer()

  if (!isEqual(prevShow, props.context.showOfertaExtendida)) {
    console.log(
      `DEBUG OfertaExtendidaForm cambio de show ${prevShow} ${props.context.showOfertaExtendida}`,
    )

    setPrevShow(props.context.showOfertaExtendida)

    //PRUEBA
    if (!props.context.showOfertaExtendida) {
      editForm.form.reset()
      if (localErrorApi) {
        localErrorApi.clearError()
      }
    }
  }

  if (!isEqual(prevTituloId, props.context.currentTitulo?.id)) {
    console.log(`DEBUG OfertaExtendidaForm cambio de titulo ${prevTituloId} ${currentTituloId}`)

    setPrevTituloId(currentTituloId)

    //PRUEBA
    /*
    editForm.form.reset()

    if (localErrorApi) {
      localErrorApi.clearError()
    }
    */
  }

  return (
    <Loading>
      <FormProvider {...editForm.form}>
        <Form
          id={OFERTA_EXTENDIDA_FORM_ID}
          className={styles.ofertaRapidaForm}
          onSubmit={editForm.onSubmit}
        >
          <BymaCard
            className={className}
            style={{
              background: 'var(--secondary-gray-normal)',
              backgroundColor: 'var(--secondary-gray-normal)',
              borderRadius: '20px',
              paddingTop: '0px',
            }}
          >
            <OfertaExtendidaFormFields {...props} editForm={editForm} />
          </BymaCard>
        </Form>
      </FormProvider>
    </Loading>
  )
}

const CreateOfertaExtendidaModalComponent = (props: OfertaExtendidaProps) => {
  const handleHide = useCallback(() => props.context.setShowOfertaExtendida(false), [props.context])

  const editForm = useCreateOfertaExtendidaForm({
    context: props.context,
    onSuccess: handleHide,
  })

  const handleConfirmImpl = () => {
    //Al asociar el FORM al aceptar no hay que hacer nada mas en la confirmacion
  }

  const show = props.context.showOfertaExtendida

  return (
    <BymaModal
      id={'oferta-extendida-modal'}
      marginTop='40px'
      size='xl'
      show={show}
      title={<span className={styles.ofertaExtendidaTitle}>{'OFERTAR'}</span>}
      confirmText='Aprobar'
      cancelText='Cancelar'
      handleCancel={handleHide}
      handleConfirm={handleConfirmImpl}
      onConfirmSubmit={true}
      headerStyles={{ paddingBottom: '0px' }}
      bodyStyles={{ paddingTop: '5px' }}
      form={OFERTA_EXTENDIDA_FORM_ID}
    >
      <OfertaExtendidaForm {...props} editForm={editForm} />
    </BymaModal>
  )
}

const ConfirmDeleteModal = ({ showConfirm, setShowConfirm, handleConfirm }) => {
  return (
    <BymaModal
      id={'roles-edit-confirm-modal'}
      show={showConfirm}
      cancelText='Volver'
      children={<p>Está seguro que desea Cancelar la Oferta?</p>}
      handleCancel={() => {
        setShowConfirm(false)
      }}
      handleConfirm={() => {
        handleConfirm()
        setShowConfirm(false)
      }}
    />
  )
}

const EditOfertaExtendidaModalComponent = (props: OfertaExtendidaProps) => {
  const [showConfirmDelete, setShowConfirmDelete] = useState(false)

  const handleHide = useCallback(() => props.context.setShowOfertaExtendida(false), [props.context])

  const { context } = props

  const editForm = useEditOfertaExtendidaForm({
    context,
    onSuccess: handleHide,
  })

  const { editEnabled, showOfertaExtendida, setShowOfertaExtendida, setEditEnabled, readOnly } =
    props.context

  const editText = editEnabled ? 'Guardar' : 'Editar'
  const cancelText = 'Volver'
  const deleteText = 'Cancelar Oferta'

  console.log(
    `DEBUG EditOfertaExtendidaModalComponent editEnabled=${editEnabled} readOnly=${readOnly}`,
  )

  const handleCancelImpl = () => {
    setShowOfertaExtendida(false)
    setEditEnabled(false)
  }

  const handleEditImpl = (e) => {
    //console.log(`DEBUG EditEntityModalComponent handleEditImpl editEnabled=${editEnabled}`)

    //Al asociar el FORM al aceptar no hay que hacer nada mas en la confirmacion
    if (!editEnabled) {
      setEditEnabled(true)
    } else {
      editForm.onSubmit()
    }
    e.preventDefault()
  }

  const invalidateOfertaQueries = useInvalidateOfertaQueries()

  const deleteMutationResult = useBymaMutation({
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    mutation: () => OfertasApi.delete(context.ofertaId!),
    successNotification: 'Se eliminó la oferta exitosamente',
    errorNotification: 'Error Eliminando Oferta',
    onSuccess: () => {
      invalidateOfertaQueries()
      context.setShowOfertaExtendida(false)
    },
  })

  const handleDeleteImpl = () => {
    setShowConfirmDelete(true)
  }

  return (
    <BymaModal
      id={'oferta-extendida-modal'}
      marginTop='40px'
      size='xl'
      show={showOfertaExtendida}
      title={<span className={styles.ofertaExtendidaTitle}>{'DETALLE OFERTA'}</span>}
      confirmText={editText}
      cancelText={cancelText}
      handleCancel={handleCancelImpl}
      handleConfirm={handleEditImpl}
      //TODO arreglar esto
      onConfirmSubmit={editEnabled}
      // Delete
      showDelete={!editEnabled}
      showConfirm={!readOnly}
      deleteText={deleteText}
      handleDelete={handleDeleteImpl}
      headerStyles={{ paddingBottom: '0px' }}
      bodyStyles={{ paddingTop: '5px' }}
      form={OFERTA_EXTENDIDA_FORM_ID}
    >
      <OfertaExtendidaForm {...props} editForm={editForm} />

      <ConfirmDeleteModal
        showConfirm={showConfirmDelete}
        setShowConfirm={setShowConfirmDelete}
        handleConfirm={deleteMutationResult.mutate}
      />
    </BymaModal>
  )
}

export const CreateOfertaExtendidaModal = (props: OfertaExtendidaProps) => (
  <LocalErrorProvider>
    <LoadingProvider>
      <CreateOfertaExtendidaModalComponent {...props} />
    </LoadingProvider>
  </LocalErrorProvider>
)

export const EditOfertaExtendidaModal = (props: OfertaExtendidaProps) => (
  <LocalErrorProvider>
    <LoadingProvider>
      <EditOfertaExtendidaModalComponent {...props} />
    </LoadingProvider>
  </LocalErrorProvider>
)

import React, { CSSProperties } from 'react'
import { Form } from 'react-bootstrap'
import { ErrorMessage, getIconPath } from 'components'
import { useFormContext } from 'react-hook-form'

import styles from './BymaUpload.module.scss'

interface UploadProps extends FieldProps {
  placeHolder?: string
  helperText?: string
  hideErrorMessage?: boolean
  inputClassName?: string
  onChange?: (value: any) => void
  variant?: 'Default' | 'Small'
  iconLeft?: string
  iconStyles?: CSSProperties
}

const FILE_INPUT = 'byma_input_file_upload'

export const BymaUploadButton = (props: UploadProps) => {
  const {
    name,
    id = name,
    label,
    hideErrorMessage = false,
    readOnly: readonly = false,
    inputClassName,
    onChange: customOnChange,
    variant = 'Default',
    iconLeft,
    iconStyles = {}
  } = props

  const { register, formState, setValue, watch } = useFormContext()

  const formFieldProps = register(name)

  const onChange = (event: any) => {
    setValue(name, event.target?.files[0])

    console.log(`DEBUG BymaUpdaload ${name} input onChange value=${event.target?.files[0]}`)

    if (customOnChange) {
      customOnChange(event)
    }
  }

  const errorMessage: string | undefined = formState.errors[name]?.message as string

  const inputVariantClassName = inputClassName
    ? inputClassName
    : styles[`buttonInnerAddon${variant.charAt(0).toUpperCase() + variant.slice(1)}`]

  const readOnlyClass = readonly ? styles.buttonInnerAddonReadOnly : ''
  const inputClasses = `${errorMessage ? styles.inputFormFieldError : ''
    } ${inputVariantClassName} ${readOnlyClass}`

  const watchInputFile = watch(name)

  return (
    <Form.Group controlId='formFile' className={styles.BymaUploadButton}>

      <div className={`${styles.buttonInnerAddon} ${inputClasses}`}>
        {iconLeft && (
          <div className={styles.leftIcon}>
            <img
              className={styles.buttonLeftAddon}
              style={iconStyles}
              src={getIconPath(iconLeft)}
            />
          </div>
        )}
        <Form.Control
          type='file'
          className={styles.inputFormField}
          onChange={onChange}
          id={FILE_INPUT}
          style={{ display: 'none' }}
        />
        <label htmlFor={FILE_INPUT} style={{ width: '100%' }}>
          {label}
        </label>
      </div>

      {/* <HelperText text={'O arrastra y suelta el archivo acá'} showIcon={false} /> */}
      {!hideErrorMessage && <ErrorMessage error={errorMessage} />}
    </Form.Group>
  )
}

import EmisoresApi from './EmisoresApi'

export type Emisor = DenominableEntity

export const EMISOR_OPTIONS_KEY = 'EMISOR_OPTIONS'
export const EMISOR_ENTITY_NAME = 'emisor'

export const EmisorOptionsQueryProps = {
  dataProvider: () => EmisoresApi.getList(),
  listKey: EMISOR_OPTIONS_KEY,
}

export enum OrdenEmisor {
  DENOMINACION_ASC = 'denominacion_asc',
  DENOMINACION_DESC = 'denominacion_desc',
}

export const OrdenEmisorOptions = [
  { label: '↑ Denominación', value: OrdenEmisor.DENOMINACION_ASC },
  { label: '↓ Denominación', value: OrdenEmisor.DENOMINACION_DESC },
].map((opt) => ({
  label: `Ordenar por ${opt.label}`,
  optionLabel: opt.label,
  value: opt.value,
}))

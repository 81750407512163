import { ResourceApi, resolveBackendUrl } from 'services/api'
import { OfertasImporCreate, OfertasImport } from './OfertasImport'

class OfertasImportApi extends ResourceApi<OfertasImport, OfertasImporCreate> {
  constructor() {
    super('/api/ofertas-import', {}, true)
  }

  update = async (entity: OfertasImport, params?: object) => {
    return this.apiMethods.uploadUpdate({
      url: resolveBackendUrl(`${this.path}/${entity.id}`, this.urlPathParams),
      params: { ...params, ...entity },
    })
  }

  getOfertas = async (id: Identifier, params: QueryParams) => {
    const path = `/api/ofertas-import/${id}/ofertas`
    return this.apiMethods.getPaginatedList({
      url: resolveBackendUrl(path),
      params,
    })
  }

  createOfertas = async (id: Identifier) => {
    const path = `/api/ofertas-import/${id}/ofertas`
    return this.apiMethods.create({
      url: resolveBackendUrl(path),
    })
  }

  getTemplate = (id: Identifier) => {
    return this.apiMethods.export({
      url: resolveBackendUrl(`${this.path}/template`, this.urlPathParams),
    })
  }
}

export default new OfertasImportApi()

import AxiosMockAdapter from 'axios-mock-adapter-path-params'

export const emisoresResponse = [
  {
    id: 1,
    denominacion: 'OVOPROT INTERNACIONAL S.A.',
  },
  {
    id: 691,
    denominacion: 'BANCO COLUMBIA S.A.',
  },
  {
    id: 844,
    denominacion: 'BANCO DE VALORES S.A.',
  },
  {
    id: 2045,
    denominacion: 'NEXO EMPRENDIMIENTOS S.A.',
  },
  {
    id: 7787,
    denominacion: 'ASAHI MOTORS S. A.',
  },
]

export const getEmisorResponse = (id: number) => {
  const entities = emisoresResponse.filter((col) => col.id == id)
  return entities !== undefined && entities.length > 0 ? entities[0] : {}
}

export const getEmisoresExportResponse = () => {
  const blob = new Blob([''])
  return blob
}

export const mockEmisores = (mock: AxiosMockAdapter) => {
  mock.onGet('/api/emisores').reply(() => {
    return [200, emisoresResponse]
  })

  mock.onGet('/api/emisores/:id').reply((config: any) => {
    const { id } = config.routeParams
    return [200, getEmisorResponse(id)]
  })

  mock.onPut('/api/emisores/:id').reply((config: any) => {
    const { id } = config.routeParams
    return [200, getEmisorResponse(id)]
  })

  mock.onPost('/api/emisores').reply((config: any) => {
    const { id } = config.routeParams
    return [200, getEmisorResponse(id)]
  })

  mock.onGet('/api/emisores/export').reply(() => {
    return [
      200,
      getEmisoresExportResponse(),
      {
        'content-disposition': 'filename="emisores.csv"',
        'content-type': 'application/csv',
      },
    ]
  })

  mock.onDelete('/api/emisores/:id').reply((config: any) => {
    const { id } = config.routeParams
    return [200, {}]
  })
}

export const yupLocale = {
  mixed: {
    default: {
      key: 'validations.invalid',
    },
    required: {
      key: 'validations.required',
    },
    notType: ({ type }) => ({
      key: 'validations.invalidType',
      values: { type: type === 'number' ? 'numero' : type },
    }),
  },

  string: {
    email: {
      key: 'validations.email',
    },
    min: ({ min }) => ({
      key: 'validations.stringMin',
      values: { min },
    }),
    max: ({ max }) => ({
      key: 'validations.stringMax',
      values: { max },
    }),
  },

  number: {
    lessThan: ({ less }) => ({
      key: 'validations.lessThan',
      values: { less },
    }),
  },

  boolean: {},
}

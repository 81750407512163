import AxiosMockAdapter from 'axios-mock-adapter-path-params'

const enteLiquidadorListResponse = [
  {
    id: 1,
    denominacion: 'Cryl',
    codigo: '2',
  },
  {
    id: 2,
    denominacion: 'Maeclear',
    codigo: '1',
  },
  {
    id: 3,
    denominacion: 'Clearstrem',
    codigo: '3',
  },
  {
    id: 4,
    denominacion: 'Euroclear',
    codigo: '4',
  },
]

export const mockEnteLiquidador = (mock: AxiosMockAdapter) => {
  mock.onGet('/api/entes-liquidadores').reply(() => {
    return [200, enteLiquidadorListResponse]
  })
}

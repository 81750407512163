import React from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'
import { AddIcon } from './BymaIcons'
interface IProps extends SvgIconProps {
    iconName: string
}
const BymaMuiIcon: React.ElementType<SvgIconProps> = (props: SvgIconProps) => {
    return (
        <SvgIcon {...props}>
            {/*<use xlinkHref={`${AddIcon}#${iconName}`} /> */}
            <path d={AddIcon}/>
        </SvgIcon>
    )
}

export default BymaMuiIcon
import AxiosMockAdapter from 'axios-mock-adapter-path-params'

import { X_TOTAL_COUNT_HEADER } from '../api/resourceApi'
import { agentesResponse } from './agenteFixtures'

import { libroOfertasColocacionResponse } from './libroOfertasColocacionResponse'

export const colocacionesResponse = [
  {
    id: 24715699,
    denominacion: 'On pyme cnv garantizada construir serie v',
    fechaAlta: '2021-02-15T09:17:34.000+00:00',
    fechaCierre: '2022-02-15T09:17:34.000+00:00',
    colocadorDenominacion: 'Colocador 1 texto descripcion largo',
    emisorDenominacion: 'Emisor 1',
    estado: 'PREADJUDICADA',
    totalEmision: 350000,
  },
  {
    id: 24715700,
    denominacion: 'On pyme cnv garantizada construir serie v',
    fechaAlta: '2021-02-16T09:17:34.000+00:00',
    fechaCierre: '2022-02-15T09:17:34.000+00:00',
    colocadorDenominacion: 'Colocador 2',
    emisorDenominacion: 'Emisor 2 texto descripcion largo',
    estado: 'ADJUDICADA',
    totalEmision: 450000,
  },
  {
    id: 24715701,
    denominacion: 'On pyme cnv garantizada construir serie v',
    fechaInicio: '2021-02-18T09:17:34.000+00:00',
    fechaCierre: '2022-02-15T09:17:34.000+00:00',
    colocadorDenominacion: 'Colocador 3',
    emisorDenominacion: 'Emisor 2 texto descripcion largo',
    estado: 'CERRADA',
    totalEmision: 65241,
  },
  {
    id: 24715702,
    denominacion: 'On pyme cnv garantizada construir serie v',
    fechaInicio: '2021-02-19T09:17:34.000+00:00',
    fechaCierre: '2022-02-15T09:17:34.000+00:00',
    colocadorDenominacion: 'Colocador 5 texto descripcion largo',
    emisorDenominacion: 'Emisor 4',
    estado: 'ABIERTA',
    totalEmision: 98524,
  },
  {
    id: 24715703,
    denominacion: 'On pyme cnv garantizada construir serie v',
    fechaInicio: '2021-03-20T09:17:34.000+00:00',
    fechaCierre: '2022-02-15T09:17:34.000+00:00',
    colocadorDenominacion: 'Colocador 35 texto descripcion largo',
    emisorDenominacion: 'Emisor 3',
    estado: 'DESIERTA',
    totalEmision: 55555,
  },
  {
    id: 24715704,
    denominacion: 'On pyme cnv garantizada construir serie v',
    fechaInicio: '2021-03-05T09:17:34.000+00:00',
    fechaCierre: '2022-02-15T09:17:34.000+00:00',
    colocadorDenominacion: 'Colocador 7',
    emisorDenominacion: 'Emisor 8 texto descripcion largo',
    estado: 'INGRESADA',
    totalEmision: 5124,
  },
  {
    id: 24715705,
    denominacion: 'On pyme cnv garantizada construir serie v',
    fechaInicio: '2021-03-08T09:17:34.000+00:00',
    fechaCierre: '2022-02-15T09:17:34.000+00:00',
    colocadorDenominacion: 'Colocador 2',
    emisorDenominacion: 'Emisor 3 texto descripcion largo',
    estado: 'SUSPENDIDA',
    totalEmision: 2415,
  },
  {
    id: 24715706,
    denominacion: 'On pyme cnv garantizada construir serie v',
    fechaInicio: '2021-02-17T09:17:34.000+00:00',
    fechaCierre: '2022-02-15T09:17:34.000+00:00',
    colocadorDenominacion: 'Colocador 1 texto descripcion largo',
    emisorDenominacion: 'Emisor 4',
    estado: 'ABIERTA',
    totalEmision: 25163,
  },
]

const COLOCACION_NEW_ID = 3120

const COLOCACION_CLONAR_ID = 3121

export const colocacionesDashboardResponse = [
  //Automatica
  {
    id: COLOCACION_NEW_ID,
    denominacion: 'Colocacion Automatica',
    estado: 'ABIERTA',
    fechaCierre: '2017-02-22T09:17:34.000+00:00',
    fechaInicio: '2021-02-17T09:17:34.000+00:00',
    ofertaUnsupportedFields: ['clase', 'estado', 'cantidad'],

    titulos: [
      {
        id: 11,
        denominacion: 'Titulo 1.1',
        descripcion: 'TV23C - O0011D1 - VRD - MONEDA DE EMISION ARS - $',
        totalEmision: 10000000,
        valorReferencia: 168,
        tipoValor: 'PRECIO',

        tramoCompetitivo: {
          cantidadMinimaOferta: 1.0,
          cantidadMaximaOferta: 2000000.0,
          multiploOferta: 1.0,
          precioMinimoOferta: 0,
          precioMaximoOferta: 10000.0,
          unidadProrrateo: 1.0,
        },

        tramoNoCompetitivo: {
          cantidadMinimaOferta: 500.0,
          cantidadMaximaOferta: 500000.0,
          multiploOferta: 1.0,
          unidadProrrateo: 1.0,
        },
        tramoCompetitivoHabilitado: true,

        integraciones: [
          { id: 1001, denominacion: 'Integracion 1.1.1' },
          { id: 1002, denominacion: 'Integracion 1.1.2' },
        ],
      },
      {
        id: 12,
        denominacion: 'Titulo 1.2',
        descripcion: 'TV23C - O0012D1 - VRD - MONEDA DE EMISION ARS - $',
        totalEmision: 50000000,
        valorReferencia: 202,
        tipoValor: 'PRECIO',

        tramoCompetitivo: {
          cantidadMinimaOferta: 100,
          cantidadMaximaOferta: 1000000.0,
          multiploOferta: 1.0,
          precioMinimoOferta: 0,
          precioMaximoOferta: 12000.0,
          unidadProrrateo: 1.0,
        },

        tramoNoCompetitivo: {
          cantidadMinimaOferta: 300.0,
          cantidadMaximaOferta: 3000000.0,
          multiploOferta: 1.0,
          unidadProrrateo: 1.0,
        },
        tramoCompetitivoHabilitado: true,
        integraciones: [
          { id: 2001, denominacion: 'Integracion 1.2.1' },
          { id: 2002, denominacion: 'Integracion 1.2.2' },
          { id: 2003, denominacion: 'Integracion 1.2.3' },
        ],
      },
    ],
    permisos: {
      crearOfertaPropiaEnabled: true,
      crearOfertaEnNombreDeEnabled: false,
      crearOfertaUnderwriterEnabled: false,
      crearOfertaRapidaEnabled: true,
      crearOfertaExtendidaEnabled: true,
      verAdjudicacionEnabled: false,
    },
    sustentable: false,
    colocador: {
      id: 2,
      denominacion: 'INTL CIBSA S.A.',
    },
    emisor: {
      id: 7787,
      denominacion: 'ASAHI MOTORS S. A.',
    },
  },

  // Bookbuilding
  {
    id: COLOCACION_CLONAR_ID,
    denominacion: 'Colocacion Bookbuilding',
    estado: 'ABIERTA',
    fechaCierre: '2017-02-22T09:17:34.000+00:00',
    fechaInicio: '2021-02-17T09:17:34.000+00:00',
    tramoCompetitivoHabilitado: false,
    ofertaUnsupportedFields: ['clase'],
    titulos: [
      {
        id: 21,
        denominacion: 'Titulo 2.1',
        descripcion: 'TV23C - O0021D1 - VRD - MONEDA DE EMISION ARS - $',
        tipoValor: 'TASA',

        integraciones: [
          { id: 1001, denominacion: 'Integracion 2.1.1' },
          { id: 1002, denominacion: 'Integracion 2.1.2' },
        ],
      },
      {
        id: 22,
        denominacion: 'Titulo 2.2',
        descripcion: 'TV23C - O0022D1 - VRD - MONEDA DE EMISION ARS - $',
        tipoValor: 'TASA',

        integraciones: [
          { id: 2001, denominacion: 'Integracion 2.2.1' },
          { id: 2002, denominacion: 'Integracion 2.2.2' },
          { id: 2003, denominacion: 'Integracion 2.2.3' },
        ],
      },
    ],
    permisos: {
      crearOfertaPropiaEnabled: true,
      crearOfertaEnNombreDeEnabled: false,
      crearOfertaUnderwriterEnabled: false,
      crearOfertaRapidaEnabled: false,
      crearOfertaExtendidaEnabled: true,
      verAdjudicacionEnabled: false,
    },
    sustentable: true,
    colocador: {
      id: 2,
      denominacion: 'INTL CIBSA S.A.',
    },
    emisor: {
      id: 7787,
      denominacion: 'ASAHI MOTORS S. A.',
    },
  },

  //CRONOLOGICO
  {
    id: 3122,
    denominacion: 'Colocacion Cronologica',
    estado: 'ABIERTA',
    fechaCierre: '2017-02-22T09:17:34.000+00:00',
    fechaInicio: '2021-02-17T09:17:34.000+00:00',
    ofertaUnsupportedFields: ['estado'],
    titulos: [
      {
        id: 51,
        denominacion: 'Titulo 5.1',
        descripcion: 'TV23C - O0031D1 - VRD - MONEDA DE EMISION ARS - $',
        totalEmision: 10000000,
        tramoCompetitivo: {
          cantidadMinima: 100,
          precioMinimo: 5,
          precioMaximo: 15,
        },
        tramoNoCompetitivo: { cantidadMinima: 500 },
        tipoValor: 'TASA',
        integraciones: [
          { id: 1001, denominacion: 'Integracion 3.1.1' },
          { id: 1002, denominacion: 'Integracion 3.1.2' },
        ],
      },
      {
        id: 52,
        denominacion: 'Titulo 5.2',
        descripcion: 'TV23C - O0032D1 - VRD - MONEDA DE EMISION ARS - $',
        totalEmision: 50000000,
        tramoCompetitivo: {
          cantidadMinima: 100,
          precioMinimo: 5,
          precioMaximo: 15,
        },
        tramoNoCompetitivo: { cantidadMinima: 500 },
        tipoValor: 'TASA',
        integraciones: [
          { id: 2001, denominacion: 'Integracion 3.2.1' },
          { id: 2002, denominacion: 'Integracion 3.2.2' },
          { id: 2003, denominacion: 'Integracion 3.2.3' },
        ],
      },
    ],
    permisos: {
      crearOfertaPropiaEnabled: true,
      crearOfertaEnNombreDeEnabled: false,
      crearOfertaUnderwriterEnabled: false,
      crearOfertaRapidaEnabled: false,
      crearOfertaExtendidaEnabled: true,
      verAdjudicacionEnabled: false,
      verLibroOfertasEnabled: false,
    },
    sustentable: false,
    colocador: {
      id: 2,
      denominacion: 'INTL CIBSA S.A.',
    },
    emisor: {
      id: 7787,
      denominacion: 'ASAHI MOTORS S. A.',
    },
  },

  {
    id: 3200,
    denominacion: 'Colocacion Automatica SIN CAP',
    estado: 'ABIERTA',
    fechaCierre: '2017-02-22T09:17:34.000+00:00',
    fechaInicio: '2021-02-17T09:17:34.000+00:00',
    ofertaUnsupportedFields: ['clase', 'estado', 'porcentajeInversion'],
    titulos: [
      {
        id: 11,
        denominacion: 'Titulo 9.1',
        descripcion: 'TV23C - O0091D1 - VRD - MONEDA DE EMISION ARS - $',
        totalEmision: 10000000,
        valorReferencia: 168,
        tipoValor: 'PRECIO',

        tramoCompetitivo: {
          cantidadMinimaOferta: 1.0,
          cantidadMaximaOferta: 2000000.0,
          multiploOferta: 1.0,
          precioMinimoOferta: 0,
          precioMaximoOferta: 1000.0,
          unidadProrrateo: 1.0,
        },

        tramoNoCompetitivo: {
          cantidadMinimaOferta: 500.0,
          cantidadMaximaOferta: 500000.0,
          multiploOferta: 1.0,
          unidadProrrateo: 1.0,
        },
        tramoCompetitivoHabilitado: true,

        integraciones: [
          { id: 1001, denominacion: 'Integracion 9.1.1' },
          { id: 1002, denominacion: 'Integracion 9.1.2' },
        ],
      },
      {
        id: 12,
        denominacion: 'Titulo 1.2',
        descripcion: 'TV23C - O0092D1 - VRD - MONEDA DE EMISION ARS - $',
        totalEmision: 50000000,
        valorReferencia: 202,
        tipoValor: 'PRECIO',

        tramoCompetitivo: {
          cantidadMinimaOferta: 100,
          cantidadMaximaOferta: 1000000.0,
          multiploOferta: 1.0,
          precioMinimoOferta: 0,
          precioMaximoOferta: 1000.0,
          unidadProrrateo: 1.0,
        },

        tramoNoCompetitivo: {
          cantidadMinimaOferta: 300.0,
          cantidadMaximaOferta: 3000000.0,
          multiploOferta: 1.0,
          unidadProrrateo: 1.0,
        },
        tramoCompetitivoHabilitado: true,
        integraciones: [
          { id: 2001, denominacion: 'Integracion 9.2.1' },
          { id: 2002, denominacion: 'Integracion 9.2.2' },
          { id: 2003, denominacion: 'Integracion 9.2.3' },
        ],
      },
    ],
    permisos: {
      crearOfertaPropiaEnabled: true,
      crearOfertaEnNombreDeEnabled: false,
      crearOfertaUnderwriterEnabled: false,
      crearOfertaRapidaEnabled: true,
      crearOfertaExtendidaEnabled: true,
      verAdjudicacionEnabled: false,
    },
    sustentable: false,
    colocador: {
      id: 2,
      denominacion: 'INTL CIBSA S.A.',
    },
    emisor: {
      id: 7787,
      denominacion: 'ASAHI MOTORS S. A.',
    },
  },

  {
    id: 3300,
    denominacion: 'Colocacion Automatica con Categoria SIN CAP',
    estado: 'ABIERTA',
    fechaCierre: '2017-02-22T09:17:34.000+00:00',
    fechaInicio: '2021-02-17T09:17:34.000+00:00',
    ofertaUnsupportedFields: ['clase', 'estado', 'porcentajeInversion'],
    titulos: [
      {
        id: 11,
        denominacion: 'Titulo 9.1',
        descripcion: 'TV23C - O0091D1 - VRD - MONEDA DE EMISION ARS - $',
        totalEmision: 10000000,
        valorReferencia: 168,
        tipoValor: 'PRECIO',

        tramoCompetitivo: {
          cantidadMinimaOferta: 1.0,
          cantidadMaximaOferta: 2000000.0,
          multiploOferta: 1.0,
          precioMinimoOferta: 0,
          precioMaximoOferta: 1000.0,
          unidadProrrateo: 1.0,
        },

        tramoNoCompetitivo: {
          cantidadMinimaOferta: 500.0,
          cantidadMaximaOferta: 500000.0,
          multiploOferta: 1.0,
          unidadProrrateo: 1.0,
        },
        tramoCompetitivoHabilitado: true,

        integraciones: [
          { id: 1001, denominacion: 'Integracion 9.1.1' },
          { id: 1002, denominacion: 'Integracion 9.1.2' },
        ],
      },
      {
        id: 12,
        denominacion: 'Titulo 1.2',
        descripcion: 'TV23C - O0092D1 - VRD - MONEDA DE EMISION ARS - $',
        totalEmision: 50000000,
        valorReferencia: 202,
        tipoValor: 'PRECIO',

        tramoCompetitivo: {
          cantidadMinimaOferta: 100,
          cantidadMaximaOferta: 1000000.0,
          multiploOferta: 1.0,
          precioMinimoOferta: 0,
          precioMaximoOferta: 1000.0,
          unidadProrrateo: 1.0,
        },

        tramoNoCompetitivo: {
          cantidadMinimaOferta: 300.0,
          cantidadMaximaOferta: 3000000.0,
          multiploOferta: 1.0,
          unidadProrrateo: 1.0,
        },
        tramoCompetitivoHabilitado: true,
        integraciones: [
          { id: 2001, denominacion: 'Integracion 9.2.1' },
          { id: 2002, denominacion: 'Integracion 9.2.2' },
          { id: 2003, denominacion: 'Integracion 9.2.3' },
        ],
      },
    ],
    permisos: {
      crearOfertaPropiaEnabled: true,
      crearOfertaEnNombreDeEnabled: false,
      crearOfertaUnderwriterEnabled: false,
      crearOfertaRapidaEnabled: true,
      crearOfertaExtendidaEnabled: true,
      verAdjudicacionEnabled: false,
    },
    sustentable: false,
    colocador: {
      id: 2,
      denominacion: 'INTL CIBSA S.A.',
    },
    emisor: {
      id: 7787,
      denominacion: 'ASAHI MOTORS S. A.',
    },
    categoriasCliente: [
      { id: 1, denominacion: 'Categoria 1' },
      { id: 2, denominacion: 'Categoria 2' },
    ],
  },

  {
    id: 3301,
    denominacion: 'Colocacion Automatica con Categoria',
    estado: 'ABIERTA',
    fechaCierre: '2017-02-22T09:17:34.000+00:00',
    fechaInicio: '2021-02-17T09:17:34.000+00:00',
    ofertaUnsupportedFields: ['clase', 'estado'],
    titulos: [
      {
        id: 11,
        denominacion: 'Titulo 9.1',
        descripcion: 'TV23C - O0091D1 - VRD - MONEDA DE EMISION ARS - $',
        totalEmision: 10000000,
        valorReferencia: 168,
        tipoValor: 'PRECIO',

        tramoCompetitivo: {
          cantidadMinimaOferta: 1.0,
          cantidadMaximaOferta: 2000000.0,
          multiploOferta: 1.0,
          precioMinimoOferta: 0,
          precioMaximoOferta: 1000.0,
          unidadProrrateo: 1.0,
        },

        tramoNoCompetitivo: {
          cantidadMinimaOferta: 500.0,
          cantidadMaximaOferta: 500000.0,
          multiploOferta: 1.0,
          unidadProrrateo: 1.0,
        },
        tramoCompetitivoHabilitado: true,

        integraciones: [
          { id: 1001, denominacion: 'Integracion 9.1.1' },
          { id: 1002, denominacion: 'Integracion 9.1.2' },
        ],
      },
      {
        id: 12,
        denominacion: 'Titulo 1.2',
        descripcion: 'TV23C - O0092D1 - VRD - MONEDA DE EMISION ARS - $',
        totalEmision: 50000000,
        valorReferencia: 202,
        tipoValor: 'PRECIO',

        tramoCompetitivo: {
          cantidadMinimaOferta: 100,
          cantidadMaximaOferta: 1000000.0,
          multiploOferta: 1.0,
          precioMinimoOferta: 0,
          precioMaximoOferta: 1000.0,
          unidadProrrateo: 1.0,
        },

        tramoNoCompetitivo: {
          cantidadMinimaOferta: 300.0,
          cantidadMaximaOferta: 3000000.0,
          multiploOferta: 1.0,
          unidadProrrateo: 1.0,
        },
        tramoCompetitivoHabilitado: true,
        integraciones: [
          { id: 2001, denominacion: 'Integracion 9.2.1' },
          { id: 2002, denominacion: 'Integracion 9.2.2' },
          { id: 2003, denominacion: 'Integracion 9.2.3' },
        ],
      },
    ],
    permisos: {
      crearOfertaPropiaEnabled: true,
      crearOfertaEnNombreDeEnabled: false,
      crearOfertaUnderwriterEnabled: false,
      crearOfertaRapidaEnabled: true,
      crearOfertaExtendidaEnabled: true,
      verAdjudicacionEnabled: false,
    },
    sustentable: true,
    colocador: {
      id: 2,
      denominacion: 'INTL CIBSA S.A.',
    },
    emisor: {
      id: 7787,
      denominacion: 'ASAHI MOTORS S. A.',
    },
    categoriasCliente: [
      { id: 1, denominacion: 'Categoria 1' },
      { id: 2, denominacion: 'Categoria 2' },
    ],
  },

  //

  {
    id: 3123,
    denominacion: 'Colocacion Adjudicada',
    estado: 'ADJUDICADA',
    fechaCierre: '2017-02-22T09:17:34.000+00:00',
    fechaInicio: '2021-02-17T09:17:34.000+00:00',
    tramoCompetitivoHabilitado: false,
    titulos: [
      {
        id: 31,
        denominacion: 'Titulo 3.1',
        descripcion: 'TV23C - O0000D1 - VRD - MONEDA DE EMISION ARS - $',
        tipoValor: 'TASA',
      },
      {
        id: 32,
        denominacion: 'Titulo 3.2',
        descripcion: 'TV23C - O0000D1 - VRD - MONEDA DE EMISION ARS - $',
        tipoValor: 'TASA',
      },
    ],
    permisos: {
      crearOfertaPropiaEnabled: true,
      crearOfertaEnNombreDeEnabled: false,
      crearOfertaUnderwriterEnabled: false,
      crearOfertaRapidaEnabled: false,
      crearOfertaExtendidaEnabled: false,
      verAdjudicacionEnabled: true,
    },
    sustentable: true,
    colocador: {
      id: 2,
      denominacion: 'INTL CIBSA S.A.',
    },
    emisor: {
      id: 7787,
      denominacion: 'ASAHI MOTORS S. A.',
    },
  },

  //
  {
    id: 3125,
    denominacion: 'Colocacion 3123 ON PYME CNV Cerrada',
    estado: 'CERRADA',
    fechaCierre: '2017-02-22T09:17:34.000+00:00',
    fechaInicio: '2021-02-17T09:17:34.000+00:00',
    titulos: [
      {
        id: 41,
        denominacion: 'Titulo 4.1',
        totalEmision: 10000000,
        tramoCompetitivo: {
          cantidadMinima: 100,
          precioMinimo: 5,
          precioMaximo: 15,
        },
        tramoNoCompetitivo: { cantidadMinima: 500 },
        tipoValor: 'TASA',
      },
      {
        id: 42,
        denominacion: 'Titulo 4.2',
        totalEmision: 50000000,
        tramoCompetitivo: {
          cantidadMinima: 100,
          precioMinimo: 5,
          precioMaximo: 15,
        },
        tramoNoCompetitivo: { cantidadMinima: 500 },
        tipoValor: 'TASA',
      },
    ],
    permisos: {
      crearOfertaPropiaEnabled: true,
      crearOfertaEnNombreDeEnabled: false,
      crearOfertaUnderwriterEnabled: false,
      crearOfertaRapidaEnabled: false,
      crearOfertaExtendidaEnabled: false,
      verAdjudicacionEnabled: false,
      verLibroOfertasEnabled: true,
    },
    sustentable: true,
    colocador: {
      id: 2,
      denominacion: 'INTL CIBSA S.A.',
    },
    emisor: {
      id: 7787,
      denominacion: 'ASAHI MOTORS S. A.',
    },
  },

  {
    id: 4125,
    denominacion: 'Colocacion 4125 ON PYME CNV Garantizada CONTRUIR',
    estado: 'ABIERTA',
    fechaCierre: '2017-02-22T09:17:34.000+00:00',
    fechaInicio: '2021-02-17T09:17:34.000+00:00',

    titulos: [
      {
        id: 61,
        denominacion: 'Titulo 6.1',
        totalEmision: 10000000,
        tramoCompetitivo: {
          cantidadMinima: 100,
          precioMinimo: 5,
          precioMaximo: 15,
        },
        tramoNoCompetitivo: { cantidadMinima: 500 },
        tipoValor: 'TASA',
      },
      {
        id: 62,
        denominacion: 'Titulo 6.2',
        totalEmision: 50000000,
        tramoCompetitivo: {
          cantidadMinima: 100,
          precioMinimo: 5,
          precioMaximo: 15,
        },
        tramoNoCompetitivo: { cantidadMinima: 500 },
        tipoValor: 'TASA',
      },
    ],
    permisos: {
      crearOfertaPropiaEnabled: true,
      crearOfertaEnNombreDeEnabled: false,
      crearOfertaUnderwriterEnabled: false,
      crearOfertaRapidaEnabled: false,
      crearOfertaExtendidaEnabled: false,
      verAdjudicacionEnabled: false,
      verLibroOfertasEnabled: false,
    },
    sustentable: false,
    colocador: {
      id: 2,
      denominacion: 'INTL CIBSA S.A.',
    },
    emisor: {
      id: 7787,
      denominacion: 'ASAHI MOTORS S. A.',
    },
  },

  {
    id: 4126,
    denominacion: 'Colocacion 4126 ON PYME CNV Garantizada CONTRUIR',
    estado: 'ABIERTA',
    fechaCierre: '2017-02-22T09:17:34.000+00:00',
    fechaInicio: '2021-02-17T09:17:34.000+00:00',

    titulos: [
      {
        id: 71,
        denominacion: 'Titulo 7.1',
        totalEmision: 10000000,
        tramoCompetitivo: {
          cantidadMinima: 100,
          precioMinimo: 5,
          precioMaximo: 15,
        },
        tramoNoCompetitivo: { cantidadMinima: 500 },
        tipoValor: 'TASA',
      },
      {
        id: 72,
        denominacion: 'Titulo 7.2',
        totalEmision: 50000000,
        tramoCompetitivo: {
          cantidadMinima: 100,
          precioMinimo: 5,
          precioMaximo: 15,
        },
        tramoNoCompetitivo: { cantidadMinima: 500 },
        tipoValor: 'TASA',
      },
    ],
    permisos: {
      crearOfertaPropiaEnabled: true,
      crearOfertaEnNombreDeEnabled: false,
      crearOfertaUnderwriterEnabled: false,
      crearOfertaRapidaEnabled: false,
      crearOfertaExtendidaEnabled: false,
      verAdjudicacionEnabled: false,
      verLibroOfertasEnabled: false,
    },
    sustentable: false,
    colocador: {
      id: 2,
      denominacion: 'INTL CIBSA S.A.',
    },
    emisor: {
      id: 7787,
      denominacion: 'ASAHI MOTORS S. A.',
    },
  },
]

const getDocumentosResponse = () => [
  {
    denominacion: 'Documento 1',
    documento: 'documento1.pdf',
    fechaAlta: '2017-02-22T09:17:34.000+00:00',
  },
  {
    denominacion: 'Documento 2',
    documento: 'documento2.pdf',
    fechaAlta: '2017-02-22T09:17:34.000+00:00',
  },
  {
    denominacion: 'Documento 3',
    documento: 'documento3.pdf',
    fechaAlta: '2017-02-22T09:17:34.000+00:00',
  },
  {
    denominacion: 'Documento 4',
    documento: 'documento4.pdf',
    fechaAlta: '2017-02-22T09:17:34.000+00:00',
  },
  {
    denominacion: 'Documento 5',
    documento: 'documento5.pdf',
    fechaAlta: '2017-02-22T09:17:34.000+00:00',
  },
]

const getAgentesResponse = () => [
  {
    id: 1,
    denominacion: 'AG.001',
  },
  {
    id: 2,
    denominacion: 'AG.002',
  },
  {
    id: 3,
    denominacion: 'AG.003',
  },
]

export const getColocacionResponse = (id: number) => {
  const colocaciones = colocacionesDashboardResponse.filter((col) => col.id == id)
  return colocaciones !== undefined && colocaciones.length > 0 ? colocaciones[0] : {}
}

const getColocacionesPorTituloResponse = () => [
  {
    tituloId: 1,
    tituloDenominacion: 'Titulo 1',
    totalEmision: 50000000,
    porcentajeOfertado: 20,
    totalCompetitivo: 20000,
    tasaPromedio: 23,
    totalNoCompetitivo: 2500000,
  },
  {
    tituloId: 2,
    tituloDenominacion: 'Titulo 2',
    totalEmision: 50000000,
    porcentajeOfertado: 20,
    totalCompetitivo: 20000,
    tasaPromedio: 23,
    totalNoCompetitivo: 2500000,
  },
  {
    tituloId: 3,
    tituloDenominacion: 'Titulo 3',
    totalEmision: 50000000,
    porcentajeOfertado: 20,
    totalCompetitivo: 20000,
    tasaPromedio: 23,
    totalNoCompetitivo: 2500000,
  },
  {
    tituloId: 4,
    tituloDenominacion: 'Titulo 4',
    totalEmision: 50000000,
    porcentajeOfertado: 20,
    totalCompetitivo: 20000,
    tasaPromedio: 23,
    totalNoCompetitivo: 2500000,
  },
  {
    tituloId: 5,
    tituloDenominacion: 'Titulo 5',
    totalEmision: 50000000,
    porcentajeOfertado: 20,
    totalCompetitivo: 20000,
    tasaPromedio: 23,
    totalNoCompetitivo: 2500000,
  },
  {
    tituloId: 6,
    tituloDenominacion: 'Titulo 6',
    totalEmision: 50000000,
    porcentajeOfertado: 20,
    totalCompetitivo: 20000,
    tasaPromedio: 23,
    totalNoCompetitivo: 2500000,
  },
]

export const mockColocaciones = (mock: AxiosMockAdapter) => {
  mock.onGet('/api/colocaciones').reply((config: any) => {
    const params = config.params
    const total = params?.id !== undefined ? 5 : params?.estado !== undefined ? 15 : 12

    return [200, colocacionesDashboardResponse, { [X_TOTAL_COUNT_HEADER]: total }]
  })

  mock.onGet('/api/colocaciones/dashboard').reply(() => {
    return [200, colocacionesDashboardResponse, { [X_TOTAL_COUNT_HEADER]: 77 }]
  })

  mock.onGet('/api/colocaciones/titulos/dashboard').reply(() => {
    return [
      200,
      colocacionesDashboardResponse
        .map((c) => c.titulos.map((t) => ({ id: t.id, denominacion: t.descripcion })))
        .flat(),
    ]
  })

  mock.onGet('/api/colocaciones/:id').reply((config: any) => {
    const { id } = config.routeParams
    return [200, getColocacionResponse(id)]
  })

  mock.onPost('/api/colocaciones/clonar/:id').reply(() => {
    return [200, getColocacionResponse(COLOCACION_CLONAR_ID)]
  })

  mock.onPost('/api/colocaciones/:id/command/ABRIR').reply((config: any) => {
    const { id } = config.routeParams
    return [200, { ...getColocacionResponse(id), estado: 'ABIERTA' }]
  })
  mock.onPost('/api/colocaciones/:id/command/CERRAR').reply((config: any) => {
    const { id } = config.routeParams
    return [200, { ...getColocacionResponse(id), estado: 'CERRADA' }]
  })

  mock.onPut('/api/colocaciones/:id').reply(() => {
    return [200, getColocacionResponse(1)]
  })

  mock.onDelete('/api/colocaciones/:id').reply(() => {
    return [200, getColocacionResponse(1)]
  })

  mock.onPost('/api/colocaciones').reply(() => {
    return [200, getColocacionResponse(COLOCACION_NEW_ID)]
  })

  mock.onGet('/api/colocaciones/:id/documentos').reply((config: any) => {
    return [200, getDocumentosResponse()]
  })

  mock.onPost('/api/colocaciones/:id/documentos').reply((config: any) => {
    return [200, getDocumentosResponse()]
  })

  mock.onGet('/api/colocaciones/:id/agentes').reply((config: any) => {
    return [200, [agentesResponse[0].id, agentesResponse[1].id]]
  })

  mock.onPut('/api/colocaciones/:id/agentes').reply((config: any) => {
    return [200, getAgentesResponse()]
  })

  mock.onGet('/api/colocaciones/:id/co-colocadores').reply((config: any) => {
    return [200, [1]]
  })

  mock.onPut('/api/colocaciones/:id/co-colocadores').reply((config: any) => {
    return [200, [1]]
  })

  mock.onGet('/api/colocaciones/:id/categorias-cliente').reply((config: any) => {
    return [200, [1]]
  })

  mock.onPut('/api/colocaciones/:id/categorias-cliente').reply((config: any) => {
    return [200, [1]]
  })

  mock.onGet('/api/colocaciones/:id/totales-titulo').reply((config: any) => {
    return [200, getColocacionesPorTituloResponse(), { [X_TOTAL_COUNT_HEADER]: 77 }]
  })

  mock.onGet('/api/colocaciones/:id/ofertas').reply((config: any) => {
    return [200, libroOfertasColocacionResponse, { [X_TOTAL_COUNT_HEADER]: 77 }]
  })
}

import React, { useMemo, useReducer } from 'react'
import { LoadingApiContext, LoadingDataContext } from './LoadingContext'
import { withContextProviders } from 'core/util'

const removeTask = (state: LoadingDataContextInfo, id: Identifier) => {
  const newTasks = state.tasks.filter((t) => t.id !== id)
  if (newTasks.length !== state.tasks.length) {
    return { tasks: newTasks }
  } else {
    return state
  }
}

const addTask = (state: LoadingDataContextInfo, task: LoadingTaskProps) => {
  const newTasks = state.tasks.slice()
  newTasks.push(task)
  return { tasks: newTasks }
}

type Actions = { type: 'add'; task: LoadingTaskProps } | { type: 'remove'; id: Identifier }

const reducer = (state: LoadingDataContextInfo, action: Actions): LoadingDataContextInfo => {
  switch (action.type) {
    case 'add':
      return addTask(state, action.task)
    case 'remove':
      return removeTask(state, action.id)
  }
}

export const LoadingProvider = ({ children }) => {
  const [tasks, dispatch] = useReducer(reducer, { tasks: [] })

  const api = useMemo(
    () => ({
      removeTask: (id: Identifier) => {
        dispatch({ type: 'remove', id })
      },

      addTask: (task: LoadingTaskProps) => {
        dispatch({ type: 'add', task })
      },
    }),
    [dispatch],
  )

  //console.log('DEBUG LoadingProvider render')

  return (
    <LoadingApiContext.Provider value={api}>
      <LoadingDataContext.Provider value={tasks}>{children}</LoadingDataContext.Provider>
    </LoadingApiContext.Provider>
  )
}

export const withLoadingProvider =
  <P extends object>(Component: React.ComponentType<P>) =>
  (props: P) =>
    (
      <LoadingProvider>
        <Component {...(props as P)} />
      </LoadingProvider>
    )

import React from 'react'

import styles from './BymaSwitchButton.module.scss'
import SwitchSelector from './switch-selector'

interface SwitchButtonProps {
  id: string
  name?: string
  options: SelectOption[]
  onChange: () => void
  backgroundColor?: string
  selectedBackgroundColor?: string
  fontColor?: string
  fontSize?: number
  initialSelectedIndex?: number
  forcedSelectedIndex?: number
  readOnly?: boolean
}

export const BymaSwitchButton = (props: SwitchButtonProps) => {
  const {
    options,
    onChange,
    backgroundColor = 'rgba(108, 117, 125, 0.3)',
    selectedBackgroundColor = 'var(--gray-color-dark)',
    fontColor = 'white',
    fontSize = 14,
    initialSelectedIndex = 0,
    forcedSelectedIndex,
    readOnly = false,
  } = props

  const optionsStruct =
    options &&
    options.map((option) => ({
      label: option.label,
      value: option.value,
      selectedBackgroundColor: selectedBackgroundColor,
    }))

  //console.log(`DEBUG ByaSwitchButton optionStruct=${JSON.stringify(optionsStruct)}`)

  return (
    <SwitchSelector
      className={styles.bymaSwitchSelector}
      onChange={onChange}
      options={optionsStruct}
      initialSelectedIndex={initialSelectedIndex}
      forcedSelectedIndex={forcedSelectedIndex}
      backgroundColor={backgroundColor}
      fontColor={fontColor}
      fontSize={fontSize}
      disabled={readOnly}
    />
  )
}

import * as React from 'react'

import { forwardRef, useCallback } from 'react'
import PropTypes from 'prop-types'

import Form from 'react-bootstrap/Form'
import { BymaTableColumn } from './BymaTable'

export interface TableColumnButtonMenuItemProps {
  column: BymaTableColumn
  onShow: (params: { source: string }) => void
  onHide: (params: { source: string }) => void
  checked: boolean
  resource: string
  disabledHide?: boolean
}

export const TableColumnButtonMenuItem = forwardRef<any, TableColumnButtonMenuItemProps>((props, ref) => {
  const { column, onShow, onHide, checked, disabledHide = false } = props

  const onChange = useCallback(
    (event) => {
      if (event.target.checked) {
        onShow({ source: column.field })
      } else {
        onHide({ source: column.field })
      }
    },
    [column.field, onShow, onHide],
  )

  //console.log(`DEBUG TableColumnButtonMenuItem render name=${filter.props.name} checked=${checked}`)

  return (

    //see https://react-bootstrap.github.io/forms/checks-radios/
    <Form.Check
      type='checkbox'
      name={column.field}
      label={column.headerName}
      value={column.field}
      key={column.field}
      checked={checked}
      onChange={onChange}
      disabled={disabledHide}
    />
  )
})

TableColumnButtonMenuItem.propTypes = {
  //column: PropTypes.element.isRequired,
  onShow: PropTypes.func.isRequired,
  //resource: PropTypes.string,
}
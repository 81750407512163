export type EnteLiquidador = DenominableEntity

export const ENTE_LIQUIDADOR_OPTIONS_KEY = 'ENTE_LIQUIDADOR_OPTIONS'

export const ENTE_LIQUIDADOR_ENTITY_NAME = 'enteLiquidador'

export enum OrdenEnteLiquidador {
  DENOMINACION_ASC = 'denominacion_asc',
  DENOMINACION_DESC = 'denominacion_desc',
}

export const OrdenEnteLiquidadorOptions = [
  { label: '↑ Denominación', value: OrdenEnteLiquidador.DENOMINACION_ASC },
  { label: '↓ Denominación', value: OrdenEnteLiquidador.DENOMINACION_DESC },
].map((opt) => ({
  label: `Ordenar por ${opt.label}`,
  optionLabel: opt.label,
  value: opt.value,
}))

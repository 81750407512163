import React, { CSSProperties, PropsWithChildren, ReactNode } from 'react'

import { BymaTitle } from 'components'
import { Row } from 'react-bootstrap'

const DefaultHeaderExtras = null

export interface ColocacionSectionCardProps extends PropsWithChildren {
  title?: string
  icon?: string
  headerExtras?: ReactNode
  cardTitle?: string
  showFooter?: boolean
  style?: CSSProperties | undefined
  titleBackgorundColor?: string
  titleExtrasRight?: JSX.Element | JSX.Element[]
}

export const ColocacionSectionCard = (props: ColocacionSectionCardProps) => {
  const readOnly = false
  const {
    title = '',
    icon,
    titleBackgorundColor,
    headerExtras = DefaultHeaderExtras,
    titleExtrasRight,
  } = props

  return (
    <>
      {title !== '' && (
        <Row style={{ marginLeft: '10px', marginRight: '0px', marginBottom: '20px' }}>
          <BymaTitle text={title} icon={icon} extrasRight={titleExtrasRight} />
        </Row>
      )}
      <Row style={{ marginLeft: '10px', marginRight: '0px' }}>{props.children}</Row>
    </>
  )
}

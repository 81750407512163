import { CardVariant } from 'components/BymaCard'

/**
 * TODO: una vez eliminada la propiedad 'titleBackgorundColor' esta funcion se puede eliminar y solo utilizar la variante
 *
 * @param isNew Dependiendo si los campos se muestran en un alta o una edicion, el estilo del card es diferente
 *
 * @returns
 */
export const getFieldsSectionCardStyleProps = (isNew) => {
  const variant: CardVariant = isNew ? 'ColocacionNew' : 'Colocacion'

  //TODO el background color deberia ser parte del estilo de cada variante
  //revisar y eliminar esta propiedad
  const titleBackgorundColor = isNew ? 'var(--byma-primary-2)' : 'transparent'

  return { variant, titleBackgorundColor }
}

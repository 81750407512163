import React from 'react'

import { TextInput, BymaFormCard, LocalErrorAlert, BymaCheckbox, BymaDataSelect } from 'components'

import { Col, Row } from 'react-bootstrap'
import { Agente, BooleanOptions, AgenteOptionsQueryProps } from 'model'

import { EntityModalProps, EntityModal } from 'features/common/Entity'

const AgenteSchemaProperties = (yup) =>
  yup.object().shape({
    denominacion: yup.string().required(),
  })

const AgenteForm = ({ cardTitle, editEnabled }) => (
  <>
    <BymaFormCard title={cardTitle}>
      <Row sm={12} className='mb-3'>
        <Col sm={6}>
          <TextInput name='idOrganizacion' label='Código Agente' readOnly={true} />
        </Col>
        <Col sm={6}>
          <TextInput name='denominacion' label='Denominación Agente' readOnly={true} />
        </Col>
      </Row>

      <Row sm={12} className='mb-3'>
        <Col sm={3}>
          <BymaCheckbox
            type='radio'
            id='agenteBYMA'
            name='agenteBYMA'
            multicheck={false}
            label={'Agente BYMA'}
            options={BooleanOptions}
            readOnly={!editEnabled}
          />
        </Col>

        <Col sm={3}>
          <BymaCheckbox
            type='radio'
            id='superAgente'
            name='superAgente'
            multicheck={false}
            label={'Agente Supervisor'}
            options={BooleanOptions}
            readOnly={!editEnabled}
          />
        </Col>

        <Col sm={6}>
          <BymaDataSelect
            modalMenu
            name='superAgenteAsociadoId'
            id='superAgenteAsociadoId'
            label='Supervisor Asociado'
            required={false}
            queryProps={AgenteOptionsQueryProps}
            readOnly={!editEnabled}
            isClearable={true}
          />
        </Col>
      </Row>

      <Row sm={12}>
        <Col sm={3}>
          <TextInput name='nroDepositante' label='No Depositante' readOnly={!editEnabled} />
        </Col>
      </Row>
    </BymaFormCard>

    <div style={{ marginTop: '1em' }}>
      <LocalErrorAlert />
    </div>
  </>
)

const AgenteModal = (props: EntityModalProps<Agente>) => {
  const editEnabled = props.context.editEnabled

  return (
    <EntityModal
      {...props}
      focusField='agenteBYMA'
      getSchema={AgenteSchemaProperties}
      showDelete={false}
      renderEditForm={(props) =>
        AgenteForm({
          ...props,
          cardTitle: editEnabled ? 'Editar Agente' : 'Detalle Agente',
          editEnabled,
        })
      }
    />
  )
}

export default AgenteModal

import React, { CSSProperties } from 'react'

import { BymaButton } from '../BymaButton/BymaButton'
import { useDownloadFile, DownloadFileProps, useLoadingProducer } from 'core'

import styles from './BymaDownloadButton.module.scss'

export type DownloadButtonProps = Omit<Partial<DownloadFileProps>, 'serverDownload'> & {
  id: string
  name: string
  columns?: any[]
  label?: string
  icon?: string
  serverDownload: () => Promise<Blob>
  getFileName: () => string
  downloadBtnWrapperStyles?: CSSProperties
}

export const BymaDownloadButton = ({
  id,
  label = '',
  icon = 'download.svg',
  serverDownload,
  getFileName,
  onError = (error) => alert(`Error en Export ${error}`),
  preDownloading = () => {},
  postDownloading = () => {
    alert('Download Finalizado')
  },
  downloadBtnWrapperStyles,
}: DownloadButtonProps) => {
  const loadingProducer = useLoadingProducer()

  const preDownloadingHandler = () => {
    loadingProducer.addTask({ message: 'Descargando Archivo', id })
    preDownloading()
  }

  const postDownloadingHandler = () => {
    loadingProducer.removeTask(id)
    postDownloading()
  }

  const errorHandler = (error) => {
    loadingProducer.removeTask(id)
    onError(error)
  }

  const {
    download,
    ref: downloadLinkRef,
    url: downloadLinkUrl,
    name: downloadFileName,
    isDownloading,
  } = useDownloadFile({
    serverDownload: serverDownload,
    getFileName: getFileName,
    onError: errorHandler,
    preDownloading: preDownloadingHandler,
    postDownloading: postDownloadingHandler,
  })

  return (
    <div style={downloadBtnWrapperStyles}>
      <a href={downloadLinkUrl} download={downloadFileName} className='hidden' ref={downloadLinkRef} />
      <BymaButton
        type='button'
        id={id}
        name={id}
        label={label}
        onClick={download}
        //groupClassName={'download-group-button'}
        groupClassName={styles.downloadGroupButton}
        iconLeft={icon}
        disabled={isDownloading}
        style={{
          backgroundColor: '#626573',
          border: '1px solid #626573',
          height: '33px',
        }}
      />
    </div>
  )
}

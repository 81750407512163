import { useContext } from 'react'

import { defaultColocacionContextInfo, ColocacionContext } from './ColocacionContext'

import { ColocacionContextInfo } from './ColocacionContext'

const useColocacionContext = (): ColocacionContextInfo => {
  return useContext(ColocacionContext) || defaultColocacionContextInfo
}

export { useColocacionContext }

import { useContext } from 'react'

import { defaultNotificationApiContextInfo } from './NotificationContext'

import { NotificationApiContext, NotificationDataContext } from './NotificationContext'

const useNotificationProducer = (): NotificationApiContextInfo => {
  return useContext(NotificationApiContext) || defaultNotificationApiContextInfo
}

const useNotificationConsumer = (): NotificationDataContextInfo => {
  return useContext(NotificationDataContext)
}

export { useNotificationProducer, useNotificationConsumer }

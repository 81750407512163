import React from 'react'

import ColocacionDashboardApi from './ColocacionDashboardApi'
import ColocacionDashboardTable from './ColocacionDashboardTable'

import {
  BymaMultiSelect,
  BymaDataSelect,
  Loading,
  BymaCard,
  BymaButton,
  useSelectSingleFilterProps,
  BymaTextInputFilter,
  BymaAlert,
} from 'components'

import { ListBase, useListQuery, LoadingProvider } from 'core'

import { EmisorOptionsQueryProps, EstadoColocacionOptions, EstadoColocacion } from 'model'

import styles from './ColocacionDashboardList.module.scss'
import { OfertaContextInfo } from 'features/common/Oferta/OfertaContextInfo'
import { useOfertaContext } from 'features/common/Oferta/OfertaContextInfo'
import { FiltersHeader } from 'features/common/Filters'

import { COLOCACION_ENTITY_NAME } from 'model'

import i18n from 'i18n'
import { useNavigate } from 'react-router-dom'

const LIST_CLASSIFIER = 'dashboard'
const LIST_KEY = COLOCACION_ENTITY_NAME + '.' + LIST_CLASSIFIER

const ColocacionDashboardList = (props: OfertaContextInfo) => {
  const { data, isError, error } = useListQuery({
    dataProvider: (queryParams) => ColocacionDashboardApi.getPaginatedList(queryParams),
    listKey: LIST_KEY,
  })

  return (
    <Loading>
      {isError ? (
        <BymaAlert variant='danger'>
          <div>{error ? error.toString() : 'Error cargando datos'}</div>
        </BymaAlert>
      ) : data && data?.content.length > 0 ? (
        <ColocacionDashboardTable data={data?.content} context={props} />
      ) : (
        <div className={styles.noRowsDiv}>
          {data?.content ? i18n.t('text.noRowsToShow') : i18n.t('text.loadingInfo')}
        </div>
      )}
    </Loading>

    /* Por el momento no se utiliza paginacion
      <ListPagination pageSize={10} total={data?.total || 0} />
      */
  )
}

const estadoColocacionOptions = EstadoColocacionOptions.filter(
  (opt) => opt.value !== EstadoColocacion.PREINGRESADA && opt.value !== EstadoColocacion.CANCELADA,
)

const ColocacionDashboardPanel = () => {
  const ofertaContextInfo = useOfertaContext()
  const navigate = useNavigate()

  const selectSingleFilterProps = useSelectSingleFilterProps()

  const filters = [
    <BymaTextInputFilter
      name='id'
      key='id'
      label='Colocación'
      alwaysOn={true}
      type='number'
      variant='Small'
      style={{ minWidth: '130px' }}
    />,

    <BymaTextInputFilter
      name='denominacion'
      key='denominacion'
      label='Denominación'
      alwaysOn={true}
      variant='Small'
      style={{ width: 107 }}
    />,

    <BymaDataSelect
      {...selectSingleFilterProps}
      name='emisorId'
      key='emisorId'
      label='Emisor'
      style={{ width: 180 }}
      maxMenuHeight={130}
      queryProps={EmisorOptionsQueryProps}
      alwaysOn={true}
      placeholder='Seleccione un Emisor'
    />,

    <BymaMultiSelect
      name='estados'
      key='estados'
      label='Estado'
      style={{ width: '223px' }}
      options={estadoColocacionOptions}
      alwaysOn={true}
      variant='Small'
      placeholder='Seleccione Estados'
    />,
  ]

  //parametros para el export (sin paginado)

  return (
    <BymaCard key={'colocaciones-card'}>
      <FiltersHeader
        filters={filters}
        showFiltersMenu={false}
        exportButton={
          <BymaButton
            key='colocaciones-history-button'
            id='colocaciones-history-button'
            name='colocaciones-history-button'
            label='Historial de colocaciones'
            className={styles.colocacionesHistoryButton}
            groupClassName='d-flex container fluid'
            onClick={() => navigate('/historial-colocaciones')}
          />
        }
      />
      <div className={styles.container}>
        <div className={styles.panel}>
          <ColocacionDashboardList {...ofertaContextInfo} />
        </div>
      </div>
    </BymaCard>
  )
}

export default () => (
  <LoadingProvider>
    <ListBase listKey={LIST_KEY}>
      <ColocacionDashboardPanel />
    </ListBase>
  </LoadingProvider>
)

import * as React from 'react'

import { useListContext } from 'core'
import { PaginationControl } from './PaginationControl'

export interface ListPaginationProps {
  total: number
  pageSize?: number
  showAlways?: boolean
  showLimits?: boolean
}

const ListPagination = ({
  total,
  pageSize,
  showAlways = false,
  showLimits = true,
}: ListPaginationProps) => {
  const { page, perPage, setPage } = useListContext()

  const limit = pageSize !== undefined ? pageSize : perPage
  const changePage = (pageNumber) => setPage(pageNumber)

  const from = (page - 1) * perPage + 1
  const to = Math.min(from + perPage - 1, total)
  const text = `${from} a ${to} de ${total}`

  return showAlways || total > limit ? (
    <div>
      <PaginationControl
        page={page}
        limit={limit}
        total={total}
        changePage={changePage}
      ></PaginationControl>
      {showLimits ? <div style={{ width: '100%', textAlign: 'center' }}>{text}</div> : null}
    </div>
  ) : null
}

export default ListPagination

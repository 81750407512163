import { useState, useEffect, useCallback } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { useQueryClient } from 'react-query'

import {
  InversorApi,
  Inversor,
  CondominosApi,
  Condomino,
  AgenteOptionsQueryProps,
  TituloAction,
  mapDenominableListToOption,
  TipoCuenta,
  TipoCuentaNumeroDescription,
  TipoCuentaOptions,
  TipoCuentaExtendidaOptions,
} from 'model'

import { useOptionsQuery } from 'core'
import { OfertaContextInfo } from './OfertaContextInfo'
import { isNullOrUndefined } from 'utils/objects'

export const TipoCuentaNombreHelp = {
  [TipoCuenta.COMITENTE]: 'Nombre',
  [TipoCuenta.FCICNV]: 'Nombre de FCI',
  [TipoCuenta.SSN]: 'Nombre',
  [TipoCuenta.LEI]: 'Nombre',
  [TipoCuenta.CDI]: 'Nombre',
  [TipoCuenta.CIE]: 'Nombre',
}

export interface CondominoInputStateProps {
  x?: string
}

export interface InversorOption extends SelectOption {
  tipoDocumento: string
  numero: string
  denominacion: string
  cuit: string
  grupoEconomicoDenominacion: string
  grupoEconomicoId: Identifier
}

const toInversorOption = (data: Inversor): InversorOption => {
  return {
    value: data.numero,
    label: `${data.numero} - ${data.denominacion}`,
    chipLabel: data.numero,
    tipoDocumento: data.tipoDocumento,
    numero: data.numero,
    denominacion: data.denominacion,
    cuit: data.grupoEconomicoCuit,
    grupoEconomicoDenominacion: data.grupoEconomicoDenominacion,
    grupoEconomicoId: data.grupoEconomicoId,
  }
}

export interface CondominoComitenteOption extends SelectOption {
  denominacion: string
  cuit: string
}

const toCondominoComitenteOption = (data: Condomino): CondominoComitenteOption => {
  return {
    value: data.denominacion,
    label: data.denominacion,
    denominacion: data.denominacion,
    cuit: data.numeroIdentificador,
  }
}

const findCondominoByNombre = (options, nombre, defaultFirst) => {
  return options.find((c) => c.value === nombre) || (defaultFirst && options[0])
}

const isTipoCuentaGrupoEconomico = (cuentaValue) =>
  cuentaValue === TipoCuenta.FCICNV || cuentaValue === TipoCuenta.SSN

const isTipoCuentaSSN = (cuentaValue) => cuentaValue === TipoCuenta.SSN

const isTipoCuentaComitente = (cuentaValue) => cuentaValue === TipoCuenta.COMITENTE

const fetchCondominos = (queryClient, params) => {
  return queryClient.fetchQuery({
    queryKey: ['CONDOMINOS_OFERTA', params],
    queryFn: () => CondominosApi.getList(params),
    staleTime: 60 * 1000,
  })
}

const fetchInversores = (queryClient, params) => {
  //TODO agregar (si no existe) o modificar (si existe) la opcion que este definida en ofertaContext.numeroCliente / nombreCliente/ cuit
  //De esta forma si el usuario modifico los datos, estos se preservan
  return queryClient.fetchQuery({
    queryKey: ['INVERSORES_OFERTA', params],
    queryFn: () => InversorApi.getOptions(params),
    staleTime: 60 * 1000,
  })
}

export function useParticipanteOfertaState(context: OfertaContextInfo) {
  const queryClient = useQueryClient()

  const [isInversor, setInversor] = useState<boolean>(false)
  const [inversorOptions, setInversorOptions] = useState<InversorOption[]>([])

  const [existsCondominoComitente, setExistsCondominoComitente] = useState<boolean>(false)
  const [condominoComitenteOptions, setCondominoComitenteOptions] = useState<
    CondominoComitenteOption[]
  >([])

  const [prevComitente, setPrevComitente] = useState<string | undefined>(undefined)

  const { setValue, control, setFocus } = useFormContext()

  const cuenta = useWatch({ control, name: 'cuenta' })
  const numero = useWatch({ control, name: 'numeroCliente' })
  const nombre = useWatch({ control, name: 'nombreCliente' })
  const agenteId = useWatch({ control, name: 'agenteId' })

  const { readOnly, editEnabled } = context

  const readOnlyFields = readOnly || !editEnabled

  // la seleccion de agente se habilita en base al conexto (puede ingresar ofertas en nombre de otro?)
  const agenteOptionsEnabled = context.createOfertaEnNombreDeEnabled
  const agenteOptionsQuery = useOptionsQuery({
    ...AgenteOptionsQueryProps,
    enabled: agenteOptionsEnabled,
  })

  const categoriaClienteOptions =
    cuenta &&
    context.tipoAltaOferta === TituloAction.OFERTA_EXTENDIDA &&
    context.currentColocacion?.categoriasCliente?.length
      ? mapDenominableListToOption(context.currentColocacion?.categoriasCliente)
      : undefined
  const categoriaClienteOptionsEnabled =
    categoriaClienteOptions && categoriaClienteOptions?.length > 0

  //se debe mostrar el input CUIT/CUIL ?
  const isCuitVisible =
    (cuenta && cuenta === TipoCuenta.COMITENTE) ||
    cuenta === TipoCuenta.FCICNV ||
    cuenta === TipoCuenta.SSN

  const isCuitReadOnly = !isCuitVisible || isTipoCuentaSSN(cuenta)

  const tipoCuentOptions =
    context.tipoAltaOferta === TituloAction.OFERTA_EXTENDIDA
      ? TipoCuentaExtendidaOptions
      : TipoCuentaOptions

  const isCapVisible =
    cuenta &&
    isTipoCuentaGrupoEconomico(cuenta) &&
    context.tipoAltaOferta === TituloAction.OFERTA_EXTENDIDA &&
    context.isFieldEnabled('porcentajeInversion')

  //setear opciones en funcion del tipo de cuenta seleccionado

  //console.log(`DEBUG useParticipanteOfertaState cuenta=${cuenta} numero=${numero}`)

  const updateInversorDependencies = useCallback(
    (numero, options) => {
      const invOpt = options.find((ge) => ge.value === numero)
      setValue('nombreCliente', invOpt ? invOpt?.denominacion : null)
      const cuit = isCuitReadOnly ? undefined : invOpt?.cuit
      setValue('cuit', cuit)
      setValue('grupoEconomicoId', invOpt?.grupoEconomicoId)
      setValue('grupoEconomicoDenominacion', invOpt ? invOpt?.grupoEconomicoDenominacion : null)
    },
    [setValue, isCuitReadOnly],
  )

  useEffect(() => {
    console.log(
      `DEBUG useParticipanteOfertaState entrando en useEffect cuenta=${cuenta} numero=${numero} context=${context.oferta?.cuenta}`,
    )

    const isGE = isTipoCuentaGrupoEconomico(cuenta)
    const isComitente = isTipoCuentaComitente(cuenta)

    if (isGE) {
      //TODO usar "fetchQuery" para aprovechar cache
      fetchInversores(queryClient, { tipoDocumento: cuenta })
        .then((data) => {
          /*
          console.log(
            `DEBUG useParticipanteOfertaState en useEffect respuesta de inversores cuenta=${cuenta} numero=${numero}`,
          )
          */
          const options = data.map(toInversorOption)
          setInversorOptions(options)
          setValue('numeroCliente', numero)
          updateInversorDependencies(numero, options)
        })
        .catch((error) => {
          console.log(`ERROR invocando endpoint de inversores ${error}`)
          setInversorOptions([])
        })
    } else {
      setInversorOptions([])
    }
    setInversor(isGE)

    if (!isComitente) {
      setCondominoComitenteOptions([])
      setExistsCondominoComitente(false)
    }

    const onReset = () => {
      //console.log(`DEBUG useParticipanteOfertaState en useEffect onReset cuenta=${cuenta} numero=${numero}`)

      setInversorOptions([])
      setInversor(false)
      setCondominoComitenteOptions([])
      setExistsCondominoComitente(false)
      setPrevComitente(undefined)
    }

    if (!cuenta) {
      onReset()
    }
  }, [cuenta, queryClient])

  const updateCondominos = useCallback(
    (comitente, agente, nombre) => {
      //console.log(`DEBUG useParticipanteOfertaState updateCondominos 1 comitente=${comitente}`)

      if (!comitente) {
        setCondominoComitenteOptions([])
        setExistsCondominoComitente(false)
        setValue('nombreCliente', undefined)
        setValue('cuit', undefined)
        return
      }

      //console.log(`DEBUG useParticipanteOfertaState updateCondominos 2 comitente=${comitente}`)

      fetchCondominos(queryClient, { comitente, agente })
        .then((data) => {
          //console.log(`DEBUG useParticipanteOfertaState updateCondominos 3 comitente=${comitente} prevComitente=${prevComitente} ${nombre} data=${JSON.stringify(data)}`)

          const options = data.map(toCondominoComitenteOption)
          const exists = options && options.length > 0

          setCondominoComitenteOptions(options)
          setExistsCondominoComitente(exists)

          if (exists) {
            const condomino = findCondominoByNombre(options, nombre, true)
            setValue('nombreCliente', condomino.value)
          } else {
            setValue('nombreCliente', undefined)
            setValue('cuit', undefined)
          }
          //se setea el foco para que el usuario seleccione
          setTimeout(() => {
            setFocus('nombre')
          }, 0)
        })
        .catch((error) => {
          console.log(`ERROR invocando endpoint de condominos ${error}`)

          setCondominoComitenteOptions([])
          setExistsCondominoComitente(false)
        })
    },
    [queryClient],
  )

  const onBlurNumeroInput = useCallback(
    (event) => {
      const value = event.target.value

      if (value !== prevComitente) {
        updateCondominos(value, agenteId, nombre)
        setPrevComitente(value)
      }
    },
    [prevComitente, agenteId],
  )

  const onChangeCuentaSelect = useCallback(() => {
    //console.log(`DEBUG useParticipanteOfertaState en useEffect onCuentaSelectChanged cuenta=${cuenta} numero=${numero}`)

    setValue('nombreCliente', null)
    setValue('numeroCliente', null)
    setValue('cuit', null)
    setValue('grupoEconomicoId', null)
    setValue('grupoEconomicoDenominacion', null)
    setPrevComitente(undefined)
  }, [setValue])

  //setear nombre y cuit del grupo economico seleccionado
  useEffect(() => {
    if (isTipoCuentaGrupoEconomico(cuenta)) {
      updateInversorDependencies(numero, inversorOptions)
      /*
      console.log(
        `DEBUG useOfertaParticipanteState useEffect[numero,cuenta] ${JSON.stringify(invOpt)}`,
      )
      */
    }
  }, [numero, cuenta])

  //setear el cuit del condomino seleccionado
  useEffect(() => {
    const isComitente = isTipoCuentaComitente(cuenta)

    if (isComitente && existsCondominoComitente && condominoComitenteOptions) {
      const condomino = findCondominoByNombre(condominoComitenteOptions, nombre, false)
      setValue('cuit', condomino?.cuit)
    }
  }, [cuenta, existsCondominoComitente, condominoComitenteOptions, nombre])

  //cambio de agente: se recalcula la lista de condominos
  //NOTA: no se utiliza 'numero' en las dependencias del callback porque solo nos intenresa
  //disparar la consulta en el evento 'blur'
  //TODO REVISAR ESTO xq en la edicion setea elprimer condomino
  useEffect(() => {
    if (isTipoCuentaComitente(cuenta)) {
      updateCondominos(numero, agenteId, nombre)
    }
  }, [agenteId, cuenta])

  return {
    tipoCuentOptions,

    isInversor,
    inversorOptions,

    existsCondominoComitente,
    condominoComitenteOptions,

    onBlurNumeroInput: isTipoCuentaComitente(cuenta) ? onBlurNumeroInput : undefined,

    isNombreReadOnly: isInversor || readOnlyFields,
    isCuitVisible,
    isCapVisible,
    isNombreVisible: !isNullOrUndefined(cuenta),
    isNumeroVisible: !isNullOrUndefined(cuenta),

    numeroHelpText: TipoCuentaNumeroDescription[cuenta] || 'Número',
    nombreHelpText: TipoCuentaNombreHelp[cuenta] || 'Nombre',

    agenteOptions: agenteOptionsQuery.data,
    agenteOptionsEnabled: agenteOptionsEnabled,

    categoriaClienteOptions,
    categoriaClienteOptionsEnabled,

    onChangeCuentaSelect,

    context,
  }
}

export type UseParticipanteOfertaStateReturn = ReturnType<typeof useParticipanteOfertaState>

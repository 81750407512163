import React from 'react'

import { NumberInput, TextInput, BymaSelect, BymaFormCard, LocalErrorAlert } from 'components'

import { Col, Row } from 'react-bootstrap'

import { EntityModalProps, EntityModal } from 'features/common/Entity'

import { Canje, TipoRedondeoOptions } from 'model'

const CanjeSchemaProperties = (yup) =>
  yup.object().shape({
    tituloALiquidar: yup.string().required(),
    precio: yup.number().required(),
    porcentaje: yup.number().required(),
  })

const CanjeForm = ({ cardTitle, editEnabled }) => {
  return (
    <>
      <BymaFormCard title={cardTitle}>
        <Row sm={12}>
          <Col sm={6}>
            <TextInput
              name='tituloALiquidar'
              required={true}
              readOnly={!editEnabled}
              label='Título a liquidar'
            />
          </Col>

          <Col sm={6}>
            <NumberInput
              name='precio'
              required={true}
              readOnly={!editEnabled}
              label='Valor Teórico'
            />
          </Col>
        </Row>

        <Row sm={12}>
          <Col sm={6}>
            <TextInput
              name='porcentaje'
              required={true}
              readOnly={!editEnabled}
              label='Porcentaje'
            />
          </Col>

          <Col sm={6}>
            <NumberInput
              name='cantidadMinima'
              required={true}
              readOnly={!editEnabled}
              label='Cantidad Mínima'
            />
          </Col>
        </Row>

        <Row sm={12}>
          <Col sm={6}>
            <TextInput
              name='laminaMinima'
              required={true}
              readOnly={!editEnabled}
              label='Lamina Mínima'
            />
          </Col>

          <Col sm={6}>
            <BymaSelect
              name='tipoRedondeo'
              label='Tipo Redondeo'
              required={true}
              readOnly={!editEnabled}
              modalMenu
              options={TipoRedondeoOptions}
            />
          </Col>
        </Row>

        <Row sm={12}>
          <Col sm={6}>
            <NumberInput
              name='cotizacionUSD'
              required={true}
              readOnly={!editEnabled}
              label='Tipo de Cambio'
            />
          </Col>
        </Row>
      </BymaFormCard>

      <div style={{ marginTop: '1em' }}>
        <LocalErrorAlert />
      </div>
    </>
  )
}

const CanjeModal = (props: EntityModalProps<Canje>) => {
  const editEnabled = props.context.editEnabled

  return (
    <EntityModal
      {...props}
      focusField='denominacion'
      getSchema={CanjeSchemaProperties}
      showDelete={false}
      renderEditForm={(props) =>
        CanjeForm({
          ...props,
          cardTitle: editEnabled ? 'Editar Canje' : 'Detalle Canje',
          editEnabled,
        })
      }
      renderCreateForm={(props) =>
        CanjeForm({
          ...props,
          cardTitle: editEnabled ? 'Editar Canje' : 'Detalle Canje',
          editEnabled,
        })
      }
    />
  )
}

export default CanjeModal

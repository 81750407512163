import PaisesApi from './PaisesApi'

export type Pais = DenominableEntity

export const PAIS_OPTIONS_KEY = 'PAIS_OPTIONS'

export const PaisOptionsQueryProps = {
  dataProvider: () => PaisesApi.getOptions(),
  listKey: PAIS_OPTIONS_KEY,
}

export const PAIS_ENTITY_NAME = 'pais'

export enum OrdenPais {
  DENOMINACION_ASC = 'denominacion_asc',
  DENOMINACION_DESC = 'denominacion_desc',
}

export const OrdenPaisOptions = [
  { label: '↑ Denominación', value: OrdenPais.DENOMINACION_ASC },
  { label: '↓ Denominación', value: OrdenPais.DENOMINACION_DESC },
].map((opt) => ({
  label: `Ordenar por ${opt.label}`,
  optionLabel: opt.label,
  value: opt.value,
}))

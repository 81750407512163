import { useContext } from 'react'

import { LocalErrorApiContext, LocalErrorDataContext } from './LocalErrorContext'

const useLocalErrorProducer = (): LocalErrorApiContextInfo | undefined => {
  return useContext(LocalErrorApiContext)
}

const useLocalErrorConsumer = (): LocalErrorDataContextInfo => {
  return useContext(LocalErrorDataContext)
}

export { useLocalErrorProducer, useLocalErrorConsumer }

import React, { useCallback, useMemo } from 'react'

import {
  BymaButton,
  BymaCard,
  BymaCheckbox,
  BymaTable,
  BymaTableColumn,
  BymaTitle,
  FlagIcon,
  Loading,
  LocalErrorAlert,
  NumberInput,
  TextInput,
} from 'components'

import { useEditForm } from 'core'

import { Col, Form, Row } from 'react-bootstrap'

import * as yup from 'yup'
import { ColocacionSectionCard } from '../sections/ColocacionSectionCard'
import { ColocacionSummary } from '../ColocacionSummary'
import { ColocacionViewContext, useColocacionEditContext } from '../ColocacionViewContext'
import { FormProvider } from 'react-hook-form'
import {
  BooleanOptions,
  TitulosApi,
  ofertaCantidadFormatter,
  tituloFormDataToModel,
  tituloModelToFormData,
} from 'model'
import { useNavigate } from 'react-router-dom'

import styles from './ColocacionBoard.module.scss'

interface SimulacionCorteProps {
  context: ColocacionViewContext
  integraciones?: any
}

enum SimulacionCorteAction {
  SIMULATION_TYPE = 'CALCULAR',
  SAVE_TYPE = 'GUARDAR',
}

const SIMULACION_CORTE_FORM_ID = 'simulacion-corte-form'

const getSimulacionCorteSchema = (yup) => yup.object().shape({})

const columnDefaults = {
  textAlign: 'left',
  unSortIcon: true,
  wrapHeaderText: true,
  flex: 1,
}

const simulacionCorteColumns: BymaTableColumn[] = [
  {
    ...columnDefaults,
    field: 'integracionTitulo.tituloDenominacion',
    headerName: 'Denominación Título',
  },

  {
    ...columnDefaults,
    field: 'integracionTitulo.denominacion',
    headerName: 'Integración',
  },

  {
    ...columnDefaults,
    field: 'sumOfertasIntegradas',
    headerName: 'Total Ofertas',
    valueFormatter: (params) => ofertaCantidadFormatter(params?.data?.sumOfertasIntegradas),
  },

  {
    ...columnDefaults,
    field: 'sumAdjudicadoIntegrado',
    headerName: 'Total Integrado',
    valueFormatter: (params) => ofertaCantidadFormatter(params?.data?.sumAdjudicadoIntegrado),
  },

  {
    ...columnDefaults,
    field: 'sumIntegrado',
    headerName: 'Total a Integrar',
    valueFormatter: (params) => ofertaCantidadFormatter(params?.data?.sumIntegrado),
  },
]

const SimulacionCorteIntegraciones = (props: { integraciones: any }) => {
  const { integraciones } = props

  const rowData = integraciones
    ? integraciones.map((integracion) => ({
        ...integracion,
        id: integracion.integracionTitulo?.id,
      }))
    : undefined

  return (
    <div className={styles.simulacionCorteResume}>
      <BymaTitle text={'Resumen del cálculo'} style={{ paddingLeft: '0px' }} icon={undefined} />

      <BymaTable
        columns={simulacionCorteColumns}
        key='integraciones'
        rowData={rowData}
        isError={false}
        isFetching={false}
        isLoading={false}
        error={null}
        sizeColumnsToFit={true}
        style={{ height: '100px', width: '100%' }}
        pagination={false}
        suppressHorizontalScroll={true}
        sortable={false}
      />
    </div>
  )
}

const SimulacionCorteFormFields = (props: SimulacionCorteProps) => {
  const { integraciones } = props

  return (
    <>
      <Row sm={12}>
        <Col sm={9}>
          <TextInput name='titulo.denominacion' required={true} label='Denominación Título' />
        </Col>
        <Col sm={3}>
          <BymaCheckbox
            type='radio'
            id={'iterar-cap'}
            name='iterar'
            multicheck={false}
            label={'Iterar CAP'}
            options={BooleanOptions}
          />
        </Col>
      </Row>
      <Row sm={12}>
        <Col sm={3}>
          <NumberInput name='totalEmision' label={'Total Emisión'} type='number' />
        </Col>
        <Col sm={3}>
          <NumberInput name='valorCorte' label={'Valor Corte (Manual)'} type='number' />
        </Col>
        <Col sm={3}>
          <NumberInput name='tasaVN' label={'Tasa V/N'} type='number' />
        </Col>
      </Row>

      <hr className={styles.divider} />

      <Row sm={12}>
        <Col sm={3}>
          <NumberInput
            name='context.valorCorteAdjudicado'
            label={'Valor Corte'}
            type='number'
            readOnly={true}
          />
        </Col>
        <Col sm={3}>
          <BymaCheckbox
            type='radio'
            id={'iterar-cap'}
            name='context.corteManual'
            multicheck={false}
            label={'Manual'}
            options={BooleanOptions}
            readOnly={true}
          />
        </Col>
        <Col sm={3}>
          <NumberInput
            name='context.precioCorte'
            label={'Precio Corte'}
            type='number'
            readOnly={true}
          />
        </Col>
      </Row>

      <Row sm={12}>
        <Col sm={3}>
          <NumberInput
            name='context.totalOfertado'
            label={'Total Ofertas'}
            type='number'
            readOnly={true}
          />
        </Col>
        <Col sm={3}>
          <NumberInput
            name='context.totalAdjudicado'
            label={'Total adjudicado'}
            type='number'
            readOnly={true}
          />
        </Col>
        <Col sm={3}>
          <NumberInput
            name='context.porcentajeOfertado'
            label={'% ofertado sobre el Total'}
            type='number'
            readOnly={true}
          />
        </Col>
      </Row>

      <Row sm={12}>
        <Col sm={3}>
          <NumberInput
            name='context.totalOfertadoComp'
            label={'Total Ofertas Comp.'}
            type='number'
            readOnly={true}
          />
        </Col>
        <Col sm={3}>
          <NumberInput
            name='context.adjudicadoComp'
            label={'Total adjudicado Comp.'}
            type='number'
            readOnly={true}
          />
        </Col>
        <Col sm={3}>
          <NumberInput
            name='context.prorrateoComp'
            label={'Factor Prorrateo Base Comp.'}
            type='number'
            readOnly={true}
          />
        </Col>
      </Row>

      <Row sm={12}>
        <Col sm={3}>
          <NumberInput
            name='context.totalOfertadoNoComp'
            label={'Total Ofertas No Comp.'}
            type='number'
            readOnly={true}
          />
        </Col>
        <Col sm={3}>
          <NumberInput
            name='context.adjudicadoNoComp'
            label={'Total adjudicado No Comp.'}
            type='number'
            readOnly={true}
          />
        </Col>
        <Col sm={3}>
          <NumberInput
            name='context.prorrateoNoComp'
            label={'Factor Prorrateo Base No Comp.'}
            type='number'
            readOnly={true}
          />
        </Col>
      </Row>

      <hr className={styles.divider} />

      <SimulacionCorteIntegraciones integraciones={integraciones} />

      <div style={{ marginTop: '1em' }}>
        <LocalErrorAlert />
      </div>
    </>
  )
}

const SimulacionCorteForm = (props: SimulacionCorteProps) => {
  const { context } = props
  const { tituloId, titulo, colocacionId } = context

  const navigate = useNavigate()

  const schema = useMemo(() => getSimulacionCorteSchema(yup), [yup])

  const onSuccessSimulate = () => {}

  const onSuccessSave = () => {
    navigate(`/colocaciones/view/${colocacionId}/board`)
  }

  const handleCancel = () => {
    navigate(`/colocaciones/view/${colocacionId}/board`)
  }

  const getFormData = useCallback(() => {
    return TitulosApi.initCalculoCorte(tituloId).then((result) => {
      context.setTitulo(tituloModelToFormData(result.titulo))
      return result
    })
  }, [tituloId, titulo])

  const mutation = useCallback((formData) => {
    const formActionType = formData.action
    console.log(formActionType)

    if (formActionType === SimulacionCorteAction.SAVE_TYPE) {
      return TitulosApi.calcularCorte(tituloId, tituloFormDataToModel(formData)).then((result) => {
        if (result?.titulo) {
          context.setTitulo(result.titulo)
        }
        context.setEditEnabled(false)
        return {
          ...titulo,
          action: formActionType,
        }
      })
    } else {
      return TitulosApi.calcularCorte(tituloId, tituloFormDataToModel(formData)).then((titulo) => {
        context.setEditEnabled(false)
        return {
          ...titulo,
          action: formActionType,
        }
      })
    }
  }, [])

  const queryKey = `simulacion_corte:${tituloId}`

  const editForm = useEditForm<any>({
    queryKey,
    getFormData: getFormData,
    mutation: mutation,
    schema: schema,

    confirm: false,
    keepPreviousData: true,
    updateOnSuccess: true,

    successNotification: (params: any) => {
      const formActionType = params.action
      if (formActionType === SimulacionCorteAction.SAVE_TYPE) {
        return 'Se actualizó el Título exitosamente'
      } else {
        return 'Simulación realizada'
      }
    },
    errorNotification: (params: any) => {
      const formActionType = params.action
      if (formActionType === SimulacionCorteAction.SAVE_TYPE) {
        return 'Error actualizando el Título'
      } else {
        return 'Error en Simulación'
      }
    },

    onSuccess: (params: any) => {
      const formActionType = params.action

      console.log(`DEBUG SimulacionCorte formActionType=${formActionType}`)

      if (formActionType === SimulacionCorteAction.SAVE_TYPE) {
        onSuccessSave()
      } else {
        onSuccessSimulate()
      }
    },

    clearLocalErrorOnLoad: true,
  })

  //console.log(`DEBUG SimulacionCorte ${JSON.stringify(editForm.form.getValues())}`)

  return (
    <Loading>
      <FormProvider {...editForm.form}>
        <Form
          id={SIMULACION_CORTE_FORM_ID}
          className={styles.SimulacionCorteForm}
          onSubmit={editForm.onSubmit}
        >
          <BymaCard
            className={'className'}
            style={{
              background: 'var(--secondary-gray-normal)',
              backgroundColor: 'var(--secondary-gray-normal)',
              borderRadius: '20px',
              padding: '20px',
              border: 'none',
            }}
            footer={
              <>
                <BymaButton
                  type='button'
                  id='roles-form-btn'
                  name='roles-form-btn'
                  label={'Cancelar'}
                  onClick={handleCancel}
                  style={{
                    backgroundColor: 'transparent',
                    border: '1px solid #626573',
                    marginTop: 5,
                    marginLeft: 20,
                  }}
                />
                <BymaButton
                  type='submit'
                  id='roles-form-btn'
                  name='roles-form-btn'
                  label={'Calcular'}
                  onClick={() => {
                    editForm.form.setValue('action', SimulacionCorteAction.SIMULATION_TYPE)
                  }}
                  style={{
                    backgroundColor: '#626573',
                    border: '1px solid #626573',
                    marginTop: 5,
                    marginLeft: 20,
                  }}
                />
                <BymaButton
                  type='submit'
                  id='roles-form-btn'
                  name='roles-form-btn'
                  label={'Guardar'}
                  onClick={() => {
                    editForm.form.setValue('action', SimulacionCorteAction.SAVE_TYPE)
                  }}
                  style={{
                    backgroundColor: 'var(--byma-primary-2)',
                    border: '1px solid var(--byma-primary-2)',
                    marginTop: 5,
                    marginLeft: 20,
                  }}
                />
              </>
            }
          >
            <SimulacionCorteFormFields
              {...props}
              integraciones={editForm.form.getValues('context.integraciones')}
            />
          </BymaCard>
        </Form>
      </FormProvider>
    </Loading>
  )
}

export default () => {
  const context = useColocacionEditContext()
  return (
    <ColocacionSectionCard title={'Simulacion de corte'} icon={FlagIcon} showFooter={false}>
      <div className={styles.SimulacionCorteContent}>
        <ColocacionSummary {...context} colocacionActionEnabled={true} />
        <SimulacionCorteForm context={context} />
      </div>
    </ColocacionSectionCard>
  )
}

import React, { useCallback, useState, CSSProperties } from 'react'
import { useFormContext, Controller } from 'react-hook-form'
import { BymaSwitchButton } from 'components'

interface SwitchButtonProps extends FieldProps {
  options: SelectOption[]
  initialSelectedIndex?: number
  styles?: CSSProperties
}

export const BymaSwitchButtonInput = ({
  name,
  options,
  initialSelectedIndex = 0,
  styles,
  readOnly,
}: SwitchButtonProps) => {
  const { control } = useFormContext()

  const [selectedIndex, setSelectedIndex] = useState<number>(initialSelectedIndex)

  const switchValue = useCallback(() => {
    const newSelectedIndex = selectedIndex === 0 ? 1 : 0
    setSelectedIndex(newSelectedIndex)

    //console.log(`DEBUG BymaSwitchButtonInput name=${name} newSelectedIndex=${newSelectedIndex}`)

    return options[newSelectedIndex]?.value
  }, [selectedIndex, options])

  //console.log(`DEBUG BymaSwitchButtonInput render name=${name}`)

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        const forcedSelectedIndex =
          field.value !== undefined
            ? options.findIndex(({ value }) => value === field.value)
            : undefined

        return (
          <BymaSwitchButton
            id={name}
            options={options}
            forcedSelectedIndex={forcedSelectedIndex}
            onChange={() => {
              if (readOnly) return
              field.onChange(switchValue())
            }}
            readOnly={readOnly}
            fontSize={styles?.fontSize as number}
          />
        )
      }}
    />
  )
}

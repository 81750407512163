import React, { useEffect, PropsWithChildren, useCallback, useMemo } from 'react'
import { FormProvider } from 'react-hook-form'
import { BymaModal, Loading } from 'components'

import i18n from 'i18n'

import { Path } from 'react-hook-form'

import { Form } from 'react-bootstrap'

import * as yup from 'yup'

interface EntityModalMessages {
  newTitle: string
  viewTitle: string
  editTitle: string
}

export const DefaultEntitModalMessages: EntityModalMessages = {
  newTitle: 'entity.newTitle',
  viewTitle: 'entity.viewTitle',
  editTitle: 'entity.editTitle',
}

import { useLocalErrorProducer, withLocalErrorProvider, LoadingProvider } from 'core'

import {
  EntityModalProps,
  useCreateEntityForm,
  useEditEntityForm,
  useDeleteEntityMutation,
} from './useEntityForm'

import styles from './EntityModal.module.scss'

interface CreateEditEntityProps<E extends BaseEntity>
  extends PropsWithChildren<EntityModalProps<E>> {
  focusField?: string
  cardTitle?: string
  confirmText?: string
  cancelText?: string
  deleteText?: string
  showDelete?: boolean
  schema: any
  defaultValues?: any
  messages?: EntityModalMessages
  readOnly?: boolean
}

interface CreateEditEntityFormProps<E extends BaseEntity> extends CreateEditEntityProps<E> {
  useFormReturn: any
}

interface DeleteEntityProps<E extends BaseEntity> extends EntityModalProps<E> {
  confirmText?: string
  cancelText?: string
}

const EntityForm = <E extends BaseEntity>(props: CreateEditEntityFormProps<E>) => {
  const { useFormReturn, formId, focusField, children } = props

  const { form } = useFormReturn

  useEffect(() => {
    if (props.context.editEnabled) {
      focusField && form.setFocus(focusField)
    }
  }, [focusField, props.context.editEnabled])

  const onSubmit = (event) => {
    event.preventDefault()
    useFormReturn.onSubmit()
  }

  return (
    <Loading>
      <FormProvider {...form}>
        <Form id={formId} className={styles.editCreateEntityForm} onSubmit={onSubmit}>
          {children}
        </Form>
      </FormProvider>
    </Loading>
  )
}

const EditEntityModalComponent = <E extends BaseEntity>(props: CreateEditEntityProps<E>) => {
  const {
    context,
    api,
    navigateTo,
    formId,
    schema,
    showDelete = false,
    cancelText = 'Cancelar',
    deleteText = 'Eliminar',
    defaultValues = {},
    focusField,
    messages = DefaultEntitModalMessages,
    readOnly,
  } = props

  const { entityName, entityLabel, showModal, setShowModal, editEnabled, setEditEnabled } = context

  const editText = editEnabled ? 'Guardar' : 'Editar'

  //console.log(`EditEntityModal Rendering ${JSON.stringify(props)}`)

  const useEntityFormReturn = useEditEntityForm<E>({
    context: context,
    schema: schema,
    api: api,
    navigateTo: navigateTo,
    formId: formId,
    defaultValues,
  })

  const { mutate: deleteMutation } = useDeleteEntityMutation(props, () => {
    setShowModal(false)
  })

  const localErrorProducer = useLocalErrorProducer()

  const handleEditImpl = (e) => {
    //console.log(`DEBUG EditEntityModalComponent handleEditImpl editEnabled=${editEnabled}`)

    //Al asociar el FORM al aceptar no hay que hacer nada mas en la confirmacion
    if (!editEnabled) {
      setEditEnabled(true)

      if (localErrorProducer) {
        localErrorProducer.clearError()
      }
      focusField && useEntityFormReturn.form.setFocus(focusField as Path<E>)
    } else {
      useEntityFormReturn.onSubmit()
    }
    e.preventDefault()
  }

  const handleCancelImpl = () => {
    setShowModal(false)
    setEditEnabled(false)
  }

  const handleDeleteImpl = () => {
    if (context.entityId !== undefined) {
      deleteMutation(context.entityId)
    } else {
      console.log('ERROR no hay una entidad seteada en el contexto para eliminacion')
    }
  }

  const cardTitle = i18n.t(editEnabled ? messages.editTitle : messages.viewTitle, {
    entity: entityLabel,
  })

  const onShow = useCallback(() => {
    if (localErrorProducer) {
      localErrorProducer.clearError()
    }

    useEntityFormReturn.form.reset()
  }, [localErrorProducer, useEntityFormReturn])

  return (
    <BymaModal
      id={entityName + '-modal-edit'}
      marginTop='40px'
      size='xl'
      show={showModal}
      title={<span className={styles.entityCardTitle}>{'+ ' + cardTitle}</span>}
      confirmText={editText}
      cancelText={cancelText}
      handleCancel={handleCancelImpl}
      handleConfirm={handleEditImpl}
      //TODO arreglar esto
      onConfirmSubmit={editEnabled}
      // Delete
      showDelete={!editEnabled && showDelete}
      showConfirm={!readOnly}
      deleteText={deleteText}
      handleDelete={handleDeleteImpl}
      headerStyles={{ paddingBottom: '0px' }}
      bodyStyles={{ paddingTop: '5px' }}
      form={props.formId}
      onShow={onShow}
    >
      <EntityForm {...props} useFormReturn={useEntityFormReturn} cardTitle={cardTitle} />
    </BymaModal>
  )
}

export const EditEntityModal = withLocalErrorProvider(EditEntityModalComponent)

const CreateEntityModalComponent = <E extends BaseEntity>(props: CreateEditEntityProps<E>) => {
  const {
    context,
    api,
    navigateTo,
    formId,
    schema,
    confirmText = 'Guardar',
    cancelText = 'Cancelar',
    defaultValues = {},
    messages = DefaultEntitModalMessages,
  } = props

  const { entityName, entityLabel, showModal, setShowModal } = context

  const cardTitle = i18n.t(messages.newTitle, { entity: entityLabel })
  const useEntityFormReturn = useCreateEntityForm({
    context: context,
    schema: schema,
    api: api,
    navigateTo: navigateTo,
    formId: formId,
    defaultValues,
  })

  const handleCreateImpl = () => {
    //Al habilitar onConfirmSubmit no hay que hacer nada
  }

  const localErrorProducer = useLocalErrorProducer()

  const onShow = useCallback(() => {
    if (localErrorProducer) {
      localErrorProducer.clearError()
    }
    useEntityFormReturn.form.reset()
  }, [localErrorProducer])

  return (
    <BymaModal
      id={entityName + '-modal-create'}
      marginTop='40px'
      size='xl'
      show={showModal}
      title={<span className={styles.entityCardTitle}>{'+ ' + cardTitle}</span>}
      confirmText={confirmText}
      onConfirmSubmit={true}
      cancelText={cancelText}
      handleCancel={() => setShowModal(false)}
      handleConfirm={handleCreateImpl}
      headerStyles={{ paddingBottom: '0px' }}
      bodyStyles={{ paddingTop: '5px' }}
      form={formId}
      onShow={onShow}
    >
      <EntityForm {...props} useFormReturn={useEntityFormReturn} cardTitle={cardTitle} />
    </BymaModal>
  )
}

export const CreateEntityModal = withLocalErrorProvider(CreateEntityModalComponent)

export const DeleteEntityModal = <E extends BaseEntity>(props: DeleteEntityProps<E>) => {
  const { context, confirmText = 'Si, eliminar', cancelText = 'Cancelar' } = props

  const { showDeleteModal, setShowDeleteModal, setShowModal, setEditEnabled } = context

  const { entityLabel } = context

  const { mutate } = useDeleteEntityMutation(props)

  const handleDeleteImpl = () => {
    if (context.entityId !== undefined) {
      mutate(context.entityId)
    } else {
      console.log('ERROR no hay una entidad seteada en el contexto para eliminacion')
    }
  }

  return (
    <BymaModal
      id={'delete-entity-confirm-modal'}
      show={showDeleteModal}
      children={
        <p className={styles.changePassModal}>
          <span>{`Confirmas la eliminación del ${entityLabel}`}</span>
          <span>
            {' '}
            &nbsp;<b>{context.entityDescription}</b>
          </span>
        </p>
      }
      handleCancel={() => {
        setShowDeleteModal(false)
        setEditEnabled(false)
      }}
      handleConfirm={() => {
        handleDeleteImpl()
        setShowModal(false)
        setShowDeleteModal(false)
        setEditEnabled(false)
      }}
      confirmText={confirmText}
      cancelText={cancelText}
    />
  )
}

interface EntityModalControllerProps<E extends BaseEntity> extends EntityModalProps<E> {
  renderCreateForm?: (props: EntityModalProps<E>) => JSX.Element
  renderEditForm?: (props: EntityModalProps<E>) => JSX.Element
  renderDeleteDialog?: (props: EntityModalProps<E>) => JSX.Element
  getSchema: (yup: any) => any
  focusField?: string
  showDelete?: boolean
  messages?: EntityModalMessages
  defaultValues?: any
}

export const EntityModalController = <E extends BaseEntity>(
  props: EntityModalControllerProps<E>,
) => {
  const schema = useMemo(() => props.getSchema(yup), [yup])

  const {
    renderCreateForm = () => null,
    renderEditForm = () => null,
    renderDeleteDialog = (props) => <DeleteEntityModal {...props} />,
    //el default es 'false': se desactiva la eliminacion desde el detalle
    showDelete = false,
  } = props

  if (props.context.showDeleteModal) {
    return renderDeleteDialog(props)
  }

  /*
  console.log(
    `DEBUG EntityModalController props.context=${JSON.stringify(
      props.context,
    )} props.context.entityId=${props.context.entityId}`,
  )
  */

  return props.context.entityId === undefined ? (
    <CreateEntityModal
      {...props}
      formId={props.formId}
      schema={schema}
      focusField={props.focusField}
    >
      {renderCreateForm(props)}
    </CreateEntityModal>
  ) : (
    <EditEntityModal
      {...props}
      formId={props.formId}
      schema={schema}
      focusField={props.focusField}
      showDelete={showDelete}
      readOnly={props.context.readOnly}
    >
      {renderEditForm(props)}
    </EditEntityModal>
  )
}

export const EntityModal = <E extends BaseEntity>(props: EntityModalControllerProps<E>) => (
  <LoadingProvider>
    <EntityModalController {...props} />
  </LoadingProvider>
)

import React, { useMemo } from 'react'

import { BymaCard, BymaDatePicker } from 'components'

import { Col, Row } from 'react-bootstrap'
import { getFieldsSectionCardStyleProps } from '../utils'

const yupRequiredDiaHora = (yup) =>
  yup.object({
    dia: yup.date().required(),
    hora: yup.date().required(),
  })

export const fechaHorariosStepYupFields = (yup) => ({
  difusionDesde: yup.date().required(),
  difusionHasta: yup.date().required(),

  fechaInicio: yupRequiredDiaHora(yup),
  fechaCierre: yupRequiredDiaHora(yup),
  limiteRetiroOfertas: yupRequiredDiaHora(yup),
})

export const fechaHorariosStepYup = (yup) => yup.object(fechaHorariosStepYupFields(yup))

export const fechaHorariosStepFieldNames = [
  'difusionDesde',
  'difusionHasta',
  'fechaInicio.dia',
  'fechaInicio.hora',
  'fechaCierre.dia',
  'fechaCierre.hora',
  'limiteRetiroOfertas.dia',
  'limiteRetiroOfertas.hora',
]

const DATE_PLACEHOLDER = 'dd/mm/aaaa'

export const fechaHorariosStepFields = ({ isNew, editEnabled }) => {
  const cardStyleProps = useMemo(() => getFieldsSectionCardStyleProps(isNew), [isNew])

  const readOnly = !editEnabled

  return (
    <BymaCard padding='0px'>
      <Row className='mb-3'>
        {/*  Periodo Difusion */}
        <Col sm={6}>
          <BymaCard {...cardStyleProps} title={'Periodo de difusión'}>
            <Row className='mb-3'>
              <Col sm={6}>
                <BymaDatePicker
                  key='difusionDesde'
                  name='difusionDesde'
                  label='Desde'
                  required={true}
                  readOnly={readOnly}
                  showTimeSelect={false}
                  clearBtn={false}
                  placeholder={DATE_PLACEHOLDER}
                  className='w-100'
                />
              </Col>

              <Col sm={6}>
                <BymaDatePicker
                  key='difusionHasta'
                  name='difusionHasta'
                  label='Hasta'
                  required={true}
                  readOnly={readOnly}
                  showTimeSelect={false}
                  clearBtn={false}
                  placeholder={DATE_PLACEHOLDER}
                  className='w-100'
                />
              </Col>
            </Row>
          </BymaCard>
        </Col>
        <Col sm={6}>
          <BymaCard {...cardStyleProps} title={'Inicio de la licitación'}>
            <Row className='mb-3'>
              <Col sm={6}>
                <BymaDatePicker
                  key='fechaInicio.dia'
                  name='fechaInicio.dia'
                  label='Día'
                  required={true}
                  readOnly={readOnly}
                  showTimeSelect={false}
                  clearBtn={false}
                  placeholder={DATE_PLACEHOLDER}
                  className='w-100'
                />
              </Col>

              <Col sm={6}>
                <BymaDatePicker
                  key='fechaInicio.hora'
                  name='fechaInicio.hora'
                  label='Hora'
                  required={true}
                  readOnly={readOnly}
                  showTimeSelect={false}
                  hourPicker
                  className='w-100'
                />
              </Col>
            </Row>
          </BymaCard>
        </Col>
      </Row>

      {/* FILA 2*/}
      <Row>
        {/*  Periodo Difusion */}
        <Col sm={6}>
          <BymaCard {...cardStyleProps} title={'Cierre de la licitación'}>
            <Row className='mb-3'>
              <Col sm={6}>
                <BymaDatePicker
                  key='fechaCierre.dia'
                  name='fechaCierre.dia'
                  label='Día'
                  required={true}
                  readOnly={readOnly}
                  showTimeSelect={false}
                  clearBtn={false}
                  placeholder={DATE_PLACEHOLDER}
                  className='w-100'
                />
              </Col>

              <Col sm={6}>
                <BymaDatePicker
                  key='fechaCierre.hora'
                  name='fechaCierre.hora'
                  label='Hora'
                  required={true}
                  readOnly={readOnly}
                  showTimeSelect={false}
                  hourPicker
                  className='w-100'
                />
              </Col>
            </Row>
          </BymaCard>
        </Col>

        <Col sm={6}>
          <BymaCard {...cardStyleProps} title={'Fecha limite de retiro'}>
            <Row className='mb-3'>
              <Col sm={6}>
                <BymaDatePicker
                  key='limiteRetiroOfertas.dia'
                  name='limiteRetiroOfertas.dia'
                  label='Día'
                  required={true}
                  readOnly={readOnly}
                  showTimeSelect={false}
                  clearBtn={false}
                  placeholder={DATE_PLACEHOLDER}
                  className='w-100'
                />
              </Col>

              <Col sm={6}>
                <BymaDatePicker
                  key='limiteRetiroOfertas.hora'
                  name='limiteRetiroOfertas.hora'
                  label='Hora'
                  required={true}
                  readOnly={readOnly}
                  showTimeSelect={false}
                  hourPicker
                  className='w-100'
                />
              </Col>
            </Row>
          </BymaCard>
        </Col>
      </Row>
    </BymaCard>
  )
}

import React, { useCallback } from 'react'

import { ListBase, useBymaMutation, useRefreshList, withLoadingProvider } from 'core'
import {
  AttachFileIcon,
  BymaButton,
  BymaCard,
  BymaCheckbox,
  BymaDataTable,
  BymaTableColumn,
  BymaTableErrorRow,
  BymaUploadButton,
  Loading,
} from 'components'
import {
  ColocacionSectionType,
  ColocacionViewContext,
} from 'features/Colocacion/ColocacionViewContext'

import {
  EstadoOfertasImportItem,
  OFERTA_IMPORT_ENTITY_NAME,
  OrdenOfertasImport,
} from 'model/ofertasImport'

import styles from './ImportOfertas.module.scss'
import OfertasImportApi from 'model/ofertasImport/OfertasImportApi'
import { FiltersHeader } from 'features/common/Filters'
import BymaTooltip from 'components/BymaOverlay/BymaTooltip'
import { FormProvider, useForm } from 'react-hook-form'
import { Form } from 'react-bootstrap'

interface OfertasPreviewProps {
  context: ColocacionViewContext
  importData: any
  fileName: string
  setImportData: (importData: any) => void
}

const LIST_KEY = [OFERTA_IMPORT_ENTITY_NAME, 'list']

const TABLE_COL_PREFIX = 'oferta.'
const POSITION_TABLE_FIELD = 'posicion'

const OfertasPreviewContent = (props: OfertasPreviewProps) => {
  const { fileName } = props

  const tableDataProvider = useCallback(
    (params) => {
      return OfertasImportApi.getOfertas(props.importData.id, params)
    },
    [props.importData.id],
  )

  const matchErrorField = (colId: string, error: any) =>
    (!error?.field && colId === POSITION_TABLE_FIELD) || error.field === colId

  const ofertaErrorCellRender = (params: any) => {
    const colId = params?.column?.colId?.replaceAll(TABLE_COL_PREFIX, '') || ''

    const data = params.data

    const oferta = data?.oferta || {}

    const dataErrors = data?.errors || []
    const dataWarnings = data?.warnings || []

    const error = [].concat(
      ...dataErrors.filter((error) => matchErrorField(colId, error)).map((item) => item.message),
    )
    const warning = [].concat(
      ...dataWarnings
        .filter((warning) => matchErrorField(colId, warning))
        .map((item) => item.message),
    )

    const value = colId === POSITION_TABLE_FIELD ? data[POSITION_TABLE_FIELD] : oferta[colId]

    let tooltipText
    if (error?.length > 0 || warning?.length > 0) {
      tooltipText = data.estado === EstadoOfertasImportItem.ERROR ? error : warning
    }

    return {
      value: value,
      tooltipText: tooltipText,
      errorLevel: data.estado,
    }
  }

  const columnDefaults = {
    textAlign: 'left',
    unSortIcon: true,
    wrapHeaderText: true,
    flex: 1,
    cellRenderer: BymaTableErrorRow,
    cellRendererParams: (params) => ofertaErrorCellRender(params),
  }

  const columns: BymaTableColumn[] = [
    {
      ...columnDefaults,
      field: POSITION_TABLE_FIELD,
      headerName: 'Posicion',
    },
    {
      ...columnDefaults,
      field: TABLE_COL_PREFIX + 'id',
      headerName: 'Número de oferta',
    },
    {
      ...columnDefaults,
      field: TABLE_COL_PREFIX + 'tituloDenominacion',
      headerName: 'Denominación Título',
    },
    {
      ...columnDefaults,
      field: TABLE_COL_PREFIX + 'agenteIdOrganizacion',
      headerName: 'Agente',
    },
    {
      ...columnDefaults,
      field: TABLE_COL_PREFIX + 'deTerceros',
      headerName: 'De terceros',
    },
    {
      ...columnDefaults,
      field: TABLE_COL_PREFIX + 'FALTA',
      headerName: 'Licita',
    },
    {
      ...columnDefaults,
      field: TABLE_COL_PREFIX + 'valor',
      headerName: 'Precio ofertado',
    },
    {
      ...columnDefaults,
      field: TABLE_COL_PREFIX + 'FALTA',
      headerName: 'Precio adjudicado',
    },
    {
      ...columnDefaults,
      field: TABLE_COL_PREFIX + 'cantidad',
      headerName: 'Cantidad ofertada',
    },
    {
      ...columnDefaults,
      field: TABLE_COL_PREFIX + 'cantidadAdjudicada',
      headerName: 'Cantidad adjudicada',
    },
    {
      ...columnDefaults,
      field: TABLE_COL_PREFIX + 'monedaEmision',
      headerName: 'Moneda',
    },
    {
      ...columnDefaults,
      field: TABLE_COL_PREFIX + 'FALTA',
      headerName: 'Integracion',
    },
  ]

  const getRowStyle = (params) => {
    const rowIndex = params.node.rowIndex ?? 0

    if (params.data.estado === EstadoOfertasImportItem.OK) {
      return {
        marginTop: rowIndex === 0 ? '0' : `${10 * rowIndex}px`,
        backgroundColor: 'transparent',
        borderRadius: '20px',
        paddingBottom: '20px',
      }
    }

    return params.data.estado === EstadoOfertasImportItem.ERROR
      ? {
          marginTop: rowIndex === 0 ? '0' : `${10 * rowIndex}px`,
          backgroundColor: 'rgba(247, 85, 85, 0.2)',
          borderRadius: '20px',
          paddingBottom: '20px',
        }
      : {
          marginTop: rowIndex === 0 ? '0' : `${10 * rowIndex}px`,
          backgroundColor: '#ffc10733',
          borderRadius: '20px',
          paddingBottom: '20px',
        }
  }

  const PreviewFooter = ({ onCreateOfertas, onUpdateImport }) => {
    return (
      <div className={styles.previewImportButtons}>
        <div className={styles.previewLeftButtons}>
          <BymaUploadButton
            name='archivo'
            required={true}
            label='Seleccionar nuevo archivo y previsualizar'
            iconLeft={AttachFileIcon}
            style={{
              height: '33px',
            }}
            onChange={onUpdateImport}
            variant='Small'
          />
        </div>

        <div className={styles.previewRightButtons}>
          <BymaButton
            type='button'
            id='cancel-preview-import-btn'
            name='cancel-preview-import-btn'
            style={{
              backgroundColor: 'transparent',
              border: '1px solid white',
              padding: '6px 16px',
            }}
            label={'Cancelar'}
            onClick={() => {}}
          />

          <BymaButton
            type='button'
            id='complete-preview-import-btn'
            name='complete-preview-import-btn'
            label={'Importar'}
            style={{
              backgroundColor: '#626573',
              border: '1px solid #626573',
              height: '33px',
            }}
            onClick={onCreateOfertas}
          />
        </div>
      </div>
    )
  }

  const filters = [
    <BymaCheckbox
      key='order'
      type='checkbox'
      id='order'
      name='order'
      label='Ordenar por Ofertas con Errores'
      labelStyle={{ display: 'none' }}
      hideLabel={true}
      multicheck={false}
      alwaysOn={true}
      variant='Small'
      options={[
        { value: OrdenOfertasImport.BY_ERROR_LEVEL, label: 'Ordenar por Ofertas con errores' },
      ]}
    />,
  ]

  const PreviewHeader = () => {
    return (
      <div className={styles.previewHeader}>
        <div className={styles.tableTitle}>
          <span>Previsualizacion de ofertas</span>
          <BymaTooltip
            text={'Para poder completar la importación, es necesario que no haya ningun error'}
            icon='after'
          />
        </div>
        <div className={styles.tableSubs}>
          <span>Archivo adjunto:</span>
          <span className={styles.fileName}>{fileName}</span>
        </div>
      </div>
    )
  }

  const { mutate: mutateCreateOfertas } = useBymaMutation({
    mutation: (importId: Identifier) => OfertasImportApi.createOfertas(importId),
    successNotification: 'Las ofertas fueron cargadas correctamente',
    errorNotification: (error) => `Error importando ofertas: ${error.message}`,
    onSuccess: () =>
      props.context.setSection(ColocacionSectionType.TABLERO_COLOCACIONES, { moveBack: true }),
    taskMessage: 'Ingresando las Ofertas...',
  })

  const refreshList = useRefreshList(LIST_KEY)

  const { mutate: mutateUpdateImport } = useBymaMutation({
    mutation: (data: any) => OfertasImportApi.update({ ...data, id: props.importData?.id }),
    successNotification: () => 'Archivo cargado exitosamente',
    errorNotification: (error) => `Error Cargando archivo: ${error.message}`,
    taskMessage: 'Cargando archivo de ofertas',
    onSuccess: (data, variables) => {
      refreshList()
      props.setImportData({
        ...data,
        fileName: variables?.archivo?.name,
      })
    },
  })

  const onUpdateImport = (data: any) => {
    mutateUpdateImport(data)
  }

  const onCreateOfertas = () => {
    mutateCreateOfertas(props.importData?.id)
  }

  const methods = useForm({
    //resolver: yupResolver(schema()),
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    shouldUnregister: false,
  })

  const onUpdateImportSubmit = methods.handleSubmit(onUpdateImport)

  return (
    <FormProvider {...methods}>
      <Form
        encType='multipart/form-data'
        id='import-ofertas-edit-form'
        onSubmit={onUpdateImportSubmit}
      >
        <ListBase listKey={LIST_KEY.join('.')} perPage={50}>
          <BymaCard
            className={styles.importOfertasPreview}
            key={'import-ofertas-preview'}
            header={{ title: <PreviewHeader /> }}
            footer={
              <PreviewFooter
                onCreateOfertas={onCreateOfertas}
                onUpdateImport={onUpdateImportSubmit}
              />
            }
          >
            <div style={{ paddingBottom: '20px', paddingTop: '20px' }}>
              <FiltersHeader filters={filters} showFiltersMenu={false} />
            </div>

            <Loading>
              <BymaDataTable
                key={'ofertas-import-preview'}
                getRowStyle={getRowStyle}
                sizeColumnsToFit={true}
                style={{ height: '310px', width: '100%' }}
                pagination={false}
                sortable={false}
                queryProps={{
                  dataProvider: tableDataProvider,
                  listKey: [...LIST_KEY, props.importData.id.toString()],
                }}
                listPaginationProps={{
                  showLimits: true,
                  pageSize: 5,
                }}
                suppressHorizontalScroll={true}
                columns={columns}
                showLoadingOverlay={false}
              />
            </Loading>
          </BymaCard>
        </ListBase>
      </Form>
    </FormProvider>
  )
}

export default withLoadingProvider(OfertasPreviewContent)

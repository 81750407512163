/* eslint-disable react/display-name */
import React, { CSSProperties, useCallback } from 'react'
import { useFormContext, Controller } from 'react-hook-form'
import { HelperText, ErrorMessage } from 'components'
import BymaTooltip from 'components/BymaOverlay/BymaTooltip'
import { NumericFormat } from 'react-number-format'
import { DefaultNumberFormatConfig } from 'utils/number'
import { get } from 'lodash'

import styles from './NumberInput.module.scss'
import { isNullOrUndefined } from 'utils/objects'

export interface NumberInputProps extends FieldProps {
  leftIcon?: string
  autoComplete?: boolean
  placeHolder?: string
  onChange?: (selected: any) => void
  inputClassName?: string
  fullWidth?: boolean
  divStyle?: CSSProperties
  variant?: 'Default' | 'Small' | 'Large'
  hideErrorMessage?: boolean
  helperText?: string
  required?: boolean
  step?: string
  decimalSeparator?: string
  thousandSeparator?: string
  decimalScale?: number
}

// see https://github.com/s-yadav/react-number-format/issues/528
const sanitizeValue = (value) => (isNullOrUndefined(value) ? '' : value)

export const NumberInput = (props: NumberInputProps) => {
  const {
    name,
    id = name,
    label,
    className,
    inputClassName,
    fullWidth = true,
    leftIcon,
    onChange: customOnChange,
    readOnly: readonly = false,
    placeHolder = '',
    disabled,
    style = {},
    divStyle = {},
    variant = 'Default',
    deps = [],
    hideErrorMessage = false,
    helperText = undefined,
    required = false,
    toolTipText = undefined,
    decimalSeparator = DefaultNumberFormatConfig.decimalSeparator,
    thousandSeparator = DefaultNumberFormatConfig.thousandSeparator,
    decimalScale = DefaultNumberFormatConfig.decimalScale,
  } = props

  const { register, formState } = useFormContext()

  const formFieldProps = register(name, { deps })

  const errorMessage: string | undefined = get(formState.errors, name)?.message as string

  //console.log(`DEBUG NumberInput render ${name} errors=${JSON.stringify(Object.keys(formState.errors))} errorMessage=${errorMessage} deps=${deps}`)

  const handleChange = (selected) => {
    customOnChange && customOnChange(selected)
  }

  const inputVariantClassName = inputClassName
    ? inputClassName
    : styles[`inputInnerAddon${variant.charAt(0).toUpperCase() + variant.slice(1)}`]

  //console.log(`DEBUG NumberInput variant=${variant} InputVariantClassName=${inputVariantClassName}`)

  const readOnlyClass = readonly ? styles.inputInnerAddonReadOnly : ''

  const inputClasses = `${
    errorMessage ? styles.inputFormFieldError : ''
  } ${inputVariantClassName} ${readOnlyClass}`

  const getStyle = () => {
    return fullWidth ? { ...style, width: '100%' } : style
  }

  const { control } = useFormContext()

  const parseNumber = useCallback(
    (value: string): number | undefined | null => {
      let formattedValue: string | undefined = undefined
      if (value !== undefined && value !== null && value.trim().length > 0) {
        formattedValue = value.replaceAll(thousandSeparator, '').replaceAll(decimalSeparator, '.')
      }
      return formattedValue !== undefined ? Number(formattedValue) : null
    },
    [thousandSeparator, decimalSeparator],
  )

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState }) => {
        const value = field.value ? field.value : props.value

        return (
          <div
            className={
              styles.bymaNumberField +
              ' form-group ' +
              styles[`bymaNumberField${variant}`] +
              ' ' +
              (className ?? '')
            }
            style={divStyle}
          >
            {label && (
              <div>
                <label htmlFor={id} className={styles.fieldLabel}>
                  {label + (required ? ' *' : '')}
                </label>
                {toolTipText && <BymaTooltip text={toolTipText} />}
              </div>
            )}
            <div className={`${styles.inputInnerAddon} ${inputClasses}`}>
              {leftIcon && (
                <div className={styles.leftIcon}>
                  <img className={styles.inputLeftAddon} src={'images/icons/' + leftIcon} />
                </div>
              )}

              <NumericFormat
                className={styles.inputNumberFormField + ' bg-transparent '}
                onChange={(event) => {
                  const value = event.target.value

                  console.log(
                    `DEBUG NumberInput onChange value=${value} parsed=${parseNumber(value)}`,
                  )

                  field.onChange(parseNumber(value))
                  handleChange(value)
                }}
                style={getStyle()}
                readOnly={readonly}
                placeholder={readonly ? '-' : placeHolder}
                value={sanitizeValue(value)}
                decimalScale={decimalScale}
                decimalSeparator={decimalSeparator}
                thousandSeparator={thousandSeparator}
                onBlur={field.onBlur}
                getInputRef={field.ref}
                disabled={disabled}
              />
            </div>

            {helperText && <HelperText text={helperText} />}
            {!hideErrorMessage && <ErrorMessage error={errorMessage} />}
          </div>
        )
      }}
    />
  )
}

import React from 'react'
import {
  TextInput,
  NumberInput,
  BymaSelect,
  BymaDataSelect,
  BymaFormCard,
  LocalErrorAlert,
} from 'components'
import { Col, Row } from 'react-bootstrap'
import { GrupoEconomicoOptionsQueryProps, TipoDocumentoOptions } from 'model'

export const InversorForm = ({ cardTitle, editEnabled = false }) => {
  return (
    <>
      <BymaFormCard title={cardTitle}>
        <Row sm={12}>
          <Col sm={3}>
            <NumberInput
              name='numero'
              label='Código FCI/SSN'
              required={true}
              readOnly={!editEnabled}
            />
          </Col>

          <Col sm={6}>
            <TextInput
              name='denominacion'
              label='Denominación FCI/SSN'
              required={true}
              readOnly={!editEnabled}
            />
          </Col>

          <Col sm={3}>
            <BymaSelect
              modalMenu
              name='tipoDocumento'
              label='Tipo de Registro'
              required={true}
              readOnly={!editEnabled}
              options={TipoDocumentoOptions}
            />
          </Col>
        </Row>

        <Row sm={12}>
          <Col sm={6}>
            <BymaDataSelect
              modalMenu
              name='grupoEconomicoId'
              id='grupoEconomicoId'
              label='Grupo Económico'
              required={false}
              queryProps={GrupoEconomicoOptionsQueryProps}
              readOnly={!editEnabled}
              isClearable={true}
            />
          </Col>
        </Row>
      </BymaFormCard>

      <div style={{ marginTop: '1em' }}>
        <LocalErrorAlert />
      </div>
    </>
  )
}

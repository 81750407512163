import React from 'react'
import { Col, Row, Container } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { useQuery } from 'react-query'

import {
  BymaCard,
  BymaHelpBox,
  BymaButton,
  BymaPageFrame,
  BymaNavigateButton,
  BymaCopyButton,
} from 'components'

import styles from './Help.module.scss'

import { withPermissions } from 'core'
import * as BymaPermissions from 'services/auth/bymaPermissions'

import HelpApi from './HelpApi'

import { HelpItem, METADATA_TYPE, SECCION_AYUDA_CONFIG } from 'model/help'

const Help = () => {
  const navigate = useNavigate()

  const { data } = useQuery(['help_view'], () => HelpApi.getList(), {
    refetchOnWindowFocus: false,
  })

  const getButtons = (contacts: string[] | undefined, key: string) => {
    let buttons
    if (contacts) {
      buttons = contacts.map((contact: any, idx: number) => {
        return <BymaCopyButton key={'button-' + key + idx} text={contact} />
      })
    }

    return buttons
  }

  const getItemBody = (item: HelpItem, metadata: any) => {
    const configUi = item.configUi
    const type = metadata.type
    const order = metadata.order

    let htmlString = ''

    if (type === METADATA_TYPE.INFO) {
      const text = configUi?.text
      if (text !== undefined && text !== null && text.length > 0) {
        htmlString = text.replaceAll(/(\r\n|\n|\r)/gm, '<br>')

        return <p key={'paragraph-' + order} dangerouslySetInnerHTML={{ __html: htmlString }} />
      }
    } else if (type === METADATA_TYPE.CONTACT) {
      const text = metadata.text
      const parts = text.split(/(\r\n|\n|\r)/)
      if (parts && parts.length === 7) {
        return (
          <p key={'paragraph-' + order}>
            {parts[0]} <br></br>
            <div className={styles.contactHelpButtons}>
              {parts[2]}
              {getButtons(configUi?.contactosAtencion, 'contactosAtencion')}
            </div>
            <div className={styles.contactHelpButtons}>
              {parts[4]}
              {getButtons(configUi?.contactosMercado, 'contactosMercado')}{' '}
            </div>
          </p>
        )
      }
    }

    return <></>
  }

  const getItemButton = (item: HelpItem, metadata: any) => {
    const configUi = item.configUi
    const buttonText = configUi?.buttonText
    const link = configUi?.link

    const type = metadata?.type
    const order = metadata?.order
    const buttonIcon = metadata?.buttonIcon

    const refLink =
      link !== undefined && link !== null && !link.trim().startsWith('http')
        ? 'https://' + link
        : link

    if (type === METADATA_TYPE.INFO && buttonText && buttonIcon) {
      return (
        <BymaButton
          id={'button-' + order}
          name={'button-' + order}
          label={buttonText}
          iconLeft={buttonIcon}
          href={refLink}
          className={styles.bymaHelpButton}
          style={{
            backgroundColor: 'var(--byma-button-gray)',
            border: '1px solid var(--byma-button-gray)',
          }}
        />
      )
    }

    return <></>
  }

  const renderHelpCards = () => {
    if (data) {
      data.sort((item1, item2) => {
        return SECCION_AYUDA_CONFIG[item1.codigo].order - SECCION_AYUDA_CONFIG[item2.codigo].order
      })
    }

    return (
      data &&
      data.map((item) => {
        const metadata = SECCION_AYUDA_CONFIG[item.codigo]

        return (
          <div key={'help-item-' + item.codigo} className={styles.bymaHelpItem}>
            <BymaCard
              title={metadata.title}
              key={'help-item-' + metadata?.order}
              style={{ minHeight: 120, display: 'flex', alignItems: 'center' }}
            >
              <Row>
                <Col className='col-8'>{getItemBody(item, metadata)}</Col>
                <Col className='col-4' style={{ alignSelf: 'center' }}>
                  {getItemButton(item, metadata)}
                </Col>
              </Row>
            </BymaCard>
          </div>
        )
      })
    )
  }

  const handleCancel = () => {
    navigate('/')
  }

  return (
    <BymaPageFrame
      bcHome={{ value: 'Dashboard', link: '/home' }}
      bcItems={[]}
      bcActive='Ayuda'
      title='AYUDA'
      iconTitle='navbar/question-circle.svg'
      className={styles.bymaHelpContainer}
    >
      {withPermissions(
        <Container className={styles.editBtnContainer}>
          <BymaNavigateButton
            navigateTo={'/help/edit'}
            id={'help-edit'}
            name={'help-edit'}
            label={'Editar'}
            icon={'edit-square.svg'}
            style={{
              float: 'right',
              height: '33px',
              marginRight: '25px',
              border: '1px solid #FFFFFF',
            }}
          />
        </Container>,
        BymaPermissions.AYUDA_EDIT_PERMISSIONS,
        <div className={styles.editFiller}></div>,
      )}

      <Container>
        <BymaHelpBox
          title='BYMA Primarias'
          className={styles.ayudaHelpBox}
          children={
            <span>
              BYMA Primarias es el Sistema de BYMA para la colocación primaria de valores
              negociables con oferta pública mediante subasta o licitación pública. Permite que
              tanto los Agentes Miembros BYMA como otros intermediarios, participar libremente del
              proceso de colocación primaria.
            </span>
          }
        />
      </Container>

      <Container>{renderHelpCards()}</Container>
    </BymaPageFrame>
  )
}

export default Help

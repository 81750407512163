import React, { ReactNode } from 'react'
import { Col, Container, Row } from 'react-bootstrap'

import BymaBreadcrumb from '../BymaBreadcrumb'
import { BymaTransientToast } from '../BymaToast'
import { BymaTitle } from 'components'

import styles from './BymaPageFrame.module.scss'

interface PageFrameProps {
  children: ReactNode
  bcHome: BCLink
  bcItems: BCLink[]
  bcActive: string
  title?: string
  iconTitle?: string
  className?: string
  showBreadcrums?: boolean
  showTitle?: boolean
  showToast?: boolean
}

const BymaPageFrame = (props: PageFrameProps) => {
  const {
    bcHome,
    bcItems,
    bcActive,
    children,
    title = '',
    iconTitle,
    className = '',
    showBreadcrums = true,
    showTitle = true,
    showToast = false,
  } = props

  return (
    <React.Fragment>
      <Container fluid className={styles.bymaPageFrame /*'byma-page-frame '*/ + ' ' + className}>
        <Row className={styles.bymaPageBreadcrumb /*'byma-page-breadcrumb'*/}>
          <Col className='col-8'>
            {showBreadcrums && <BymaBreadcrumb home={bcHome} items={bcItems} active={bcActive} />}
          </Col>
        </Row>

        {showTitle ? (
          <Row className={styles.bymaPageTitle}>
            <div className={styles.bymaPageToast}>
              <BymaTransientToast /*className={styles.bymaPageToast}*/ />
            </div>
            <BymaTitle text={title} icon={iconTitle ? 'icons/' + iconTitle : undefined} />
          </Row>
        ) : (
          <br />
        )}

        {showToast ? (
          <div className={styles.toastContainer}>
            <BymaTransientToast />
          </div>
        ) : null}

        <Container fluid className='mx-auto'>
          {children}
        </Container>
      </Container>
    </React.Fragment>
  )
}

export default BymaPageFrame

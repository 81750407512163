import AxiosMockAdapter from 'axios-mock-adapter-path-params'

export const inversoresResponse = [
  {
    id: 1750,
    denominacion: 'EL SURCO CIA DE SEGUROS SAA',
    numero: 2,
    tipoDocumento: 'SSN',
    grupoEconomicoCuit: '2321918437',
    grupoEconomicoDenominacion: 'Grupo Economico 2',
  },
  {
    id: 1751,
    denominacion: 'SMG CIA ARG DE SEGUROS SA',
    numero: 6,
    tipoDocumento: 'SSN',
    grupoEconomicoCuit: '239999999',
    grupoEconomicoDenominacion: 'Grupo Economico 6',
  },
  {
    id: 1997,
    denominacion: 'SUPERFONDO MULTIACTIVOS DOLAR F.C.I',
    numero: 1003,
    tipoDocumento: 'FCICNV',
    grupoEconomicoCuit: '2377777777',
    grupoEconomicoDenominacion: 'Grupo Economico 1003',
  },
  {
    id: 1998,
    denominacion: 'SUPERFONDO RENTA GLOBAL F.C.I.',
    numero: 1004,
    tipoDocumento: 'FCICNV',
    grupoEconomicoCuit: '23888888888',
    grupoEconomicoDenominacion: 'Grupo Economico 1004',
  },
]

export const getInversorResponse = (id: number) => {
  const entities = inversoresResponse.filter((col) => col.id == id)
  return entities !== undefined && entities.length > 0 ? entities[0] : {}
}

export const getInversorExportResponse = () => {
  const blob = new Blob([''])
  return blob
}

export const mockInversores = (mock: AxiosMockAdapter) => {
  mock.onGet('/api/inversores').reply(() => {
    return [200, inversoresResponse]
  })

  mock.onGet('/api/inversores/options').reply(() => {
    return [200, inversoresResponse]
  })

  mock.onGet('/api/inversores/:id').reply((config: any) => {
    const { id } = config.routeParams
    return [200, getInversorResponse(id)]
  })

  mock.onPost('/api/inversores').reply((config: any) => {
    const { id } = config.routeParams
    return [200, getInversorResponse(id)]
  })

  mock.onDelete('/api/inversores/:id').reply((config: any) => {
    const { id } = config.routeParams
    return [200, {}]
  })

  mock.onPut('/api/inversores/:id').reply((config: any) => {
    const { id } = config.routeParams
    return [200, getInversorResponse(id)]
  })

  mock.onGet('/api/inversores/export').reply(() => {
    return [
      200,
      getInversorExportResponse(),
      {
        'content-disposition': 'filename="fci-ssn.csv"',
        'content-type': 'application/csv',
      },
    ]
  })
}

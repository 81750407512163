import React from 'react'
import { useTranslation } from 'react-i18next'

import styles from './ErrorMessage.module.scss'

export interface ErrorMessageProps {
  error?: ValidationError | string
}

export const ErrorMessage = ({ error }: ErrorMessageProps) => {
  const { t } = useTranslation()

  let errorText: string[] = []

  if (error === undefined) {
    errorText = ['']
  } else if (typeof error === 'string') {
    errorText = error.split('\n')
  } else {
    errorText = [t(error.key, error.values)]
  }

  return errorText.length == 1 ? (
    <div className={styles.errorText}>{errorText[0]}</div>
  ) : (
    <div className={styles.errorText}>
      {' '}
      {errorText.map((error) => (
        <div key={error}>{error}</div>
      ))}{' '}
    </div>
  )
}

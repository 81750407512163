import { AxiosResponse } from 'axios'
import api from './api'

export interface ApiRequest<T = object> {
  url: string
  params?: T
}

export const X_TOTAL_COUNT_HEADER = 'x-total-count'

export const SORT_FILTER_NAME = 'sortFilterParam'

export class ResourceApiMethods<T> {
  _getPaginatedResult = (
    response: AxiosResponse<unknown, any>,
    queryParams: QueryParams | undefined,
  ): PaginatedResult<T> => {
    const total = response.headers[X_TOTAL_COUNT_HEADER]

    return {
      page: queryParams?.pagination?.page || 0,
      pageSize: queryParams?.pagination?.perPage || 0,
      total: total || 0,
      content: response.data as T[],
    }
  }

  getList = async (request: ApiRequest): Promise<T[]> => {
    return api
      .get(request.url, { params: this._getQueryParams(request) })
      .then((response) => response.data as T[])
  }

  getOptions = async <O = T>(request: ApiRequest): Promise<O[]> => {
    return api
      .get(request.url, { params: this._getQueryParams(request) })
      .then((response) => response.data as O[])
  }

  getAllAsPage = async (request: ApiRequest): Promise<PaginatedResult<T>> => {
    return this.getList(request).then((data) => ({
      page: 0,
      pageSize: data.length,
      total: data.length,
      content: data as T[],
    }))
  }

  getPaginatedList = async (request: ApiRequest<QueryParams>): Promise<PaginatedResult<T>> => {
    const query = this._getQueryParams(request)

    return api
      .get(request.url, { params: query })
      .then((response) => this._getPaginatedResult(response, request.params))
  }

  getOne = async (request: ApiRequest): Promise<T> => {
    return api.get(request.url).then((response) => response.data as T)
  }

  update = async (request: ApiRequest): Promise<T> => {
    return api.put(request.url, request.params).then((response) => response.data as T)
  }

  updateBatch = async (request: ApiRequest): Promise<T[]> => {
    return api.put(request.url, request.params).then((response) => response.data as T[])
  }

  delete = async (request: ApiRequest): Promise<T> => {
    return api.delete(request.url).then((response) => response.data as T)
  }

  create = async (request: ApiRequest): Promise<T> => {
    return api.post(request.url, request.params).then((response) => response.data as T)
  }

  export = async (request: ApiRequest): Promise<Blob> => {
    const query = this._getQueryParams(request)

    return api.download(request.url, { params: query }).then((response) => response.data as Blob)
  }

  getNestedResources = async <N>(request: ApiRequest): Promise<N[]> => {
    return api.get(request.url).then((response) => response.data as N[])
  }

  getNestedResource = async <N>(request: ApiRequest): Promise<N> => {
    return api.get(request.url).then((response) => response.data as N)
  }

  updateNestedResources = async <N>(request: ApiRequest): Promise<N[]> => {
    return api.put(request.url, request.params).then((response) => response.data as N[])
  }

  createNestedResource = async <N>(request: ApiRequest): Promise<N> => {
    return api.post(request.url, request.params).then((response) => response.data as N)
  }

  upload = async (request: ApiRequest): Promise<T> => {
    return api.postMultipart(request.url, request.params).then((response) => response.data as T)
  }

  uploadUpdate = async (request: ApiRequest): Promise<T> => {
    return api.putMultipart(request.url, request.params).then((response) => response.data as T)
  }

  uploadNestedResource = async <N>(request: ApiRequest): Promise<N> => {
    return api.postMultipart(request.url, request.params).then((response) => response.data as N)
  }

  private _getSortFromFilterParam(param) {
    if (!param || !param.split) {
      return {}
    }
    const tokens = param.split('_')
    return { sort: tokens[0], sortColumn: tokens[0], sortDir: tokens[1] || 'asc' }
  }

  private _getQueryParams(request: ApiRequest<QueryParams>) {
    const params = request.params || {}

    const { pagination, sort, filter = {}, ...rest } = params

    const { sortFilterParam, ...filterParams } = filter

    const paginationParams = pagination
      ? { page: pagination.page - 1, size: pagination.perPage }
      : {}

    const sortParams = sortFilterParam
      ? this._getSortFromFilterParam(sortFilterParam)
      : sort
      ? { sort: sort.field, sortColumn: sort.field, sortDir: sort.order }
      : {}

    const query = {
      ...filterParams,
      ...paginationParams,
      ...sortParams,
      ...rest,
    }
    return query
  }
}

const resolveBackendUrl = (path: string, params: Object = {}) => {
  //console.log(`DEBUG resolveBackendUrl ${path} params=${JSON.stringify(params)}`)

  let finalPath = path
  if (Object.keys(params).length > 0) {
    Object.keys(params).forEach((key) => {
      finalPath = finalPath.replaceAll(':' + key, params[key])
    })
  }

  return process.env.REACT_APP_BACKEND_URL + finalPath
}

export { resolveBackendUrl }

type Merge<X, Y> = {
  [K in keyof X | keyof Y]: (K extends keyof X ? X[K] : never) | (K extends keyof Y ? Y[K] : never)
}

export interface QueryApi<T extends BaseEntity> {
  getList: (params?: object) => Promise<T[]>
  getPaginatedList: (params: QueryParams) => Promise<PaginatedResult<T>>
  getAllAsPage: (params: QueryParams) => Promise<PaginatedResult<T>>
  getOne: (id: any, params?: object) => Promise<T>
}

export interface CRUDApi<T extends BaseEntity, A extends BaseEntity = T, U extends BaseEntity = T> {
  create: (entity: A, params?: object) => Promise<T>
  update: (entity: U, params?: object) => Promise<T>
  delete: (entityId: Identifier, params?: QueryParams) => Promise<T>
}

export type EntityApi<
  T extends BaseEntity,
  A extends BaseEntity = T,
  U extends BaseEntity = T,
> = Merge<QueryApi<T>, CRUDApi<T, A, U>>

export interface ResourceApiConverters<T> {
  formDataToModel?: (any) => T
  modelToFormData?: (T) => any
}

export class ResourceApi<T extends BaseEntity, A extends BaseEntity = T, U extends BaseEntity = T>
  implements EntityApi<T, A, U>
{
  apiMethods: ResourceApiMethods<T>
  path: string
  urlPathParams: Object = {}
  multipart = false
  converters?: ResourceApiConverters<T>

  constructor(
    path: string,
    urlPathParams: Object = {},
    multipart = false,
    converters: ResourceApiConverters<T> = {},
  ) {
    this.apiMethods = new ResourceApiMethods()
    this.path = path
    this.urlPathParams = urlPathParams
    this.multipart = multipart
    this.converters = converters
  }

  _formDataToModel = (data: any) =>
    this.converters?.formDataToModel && data ? this.converters?.formDataToModel(data) : data

  _modelToFormData = (model: T) =>
    this.converters?.modelToFormData && model ? this.converters?.modelToFormData(model) : model

  getList = async (params?: object) => {
    return this.apiMethods.getList({
      url: resolveBackendUrl(this.path, this.urlPathParams),
      params,
    })
  }

  getOptions = async <O = T>(params?: object) => {
    return this.apiMethods.getOptions<O>({
      url: resolveBackendUrl(`${this.path}/options`, this.urlPathParams),
      params,
    })
  }

  getPaginatedList = async (params: QueryParams) => {
    return this.apiMethods.getPaginatedList({
      url: resolveBackendUrl(this.path, this.urlPathParams),
      params,
    })
  }

  getAllAsPage = async (params: QueryParams) => {
    return this.apiMethods.getAllAsPage({
      url: resolveBackendUrl(this.path, this.urlPathParams),
      params,
    })
  }

  getOne = async (id: any, params?: object) => {
    return this.apiMethods
      .getOne({
        url: resolveBackendUrl(`${this.path}/${id}`, this.urlPathParams),
        params,
      })
      .then((data) => this._modelToFormData(data))
  }

  create = async (entity: A, params?: object) => {
    if (this.multipart) {
      return this.apiMethods.upload({
        url: resolveBackendUrl(`${this.path}`, this.urlPathParams),
        params: { ...params, ...entity },
      })
    } else {
      return this.apiMethods.create({
        url: resolveBackendUrl(`${this.path}`, this.urlPathParams),
        params: { ...params, ...this._formDataToModel(entity) },
      })
    }
  }

  update = async (entity: U, params?: object) => {
    return this.apiMethods.update({
      url: resolveBackendUrl(`${this.path}/${entity.id}`, this.urlPathParams),
      params: { ...params, ...this._formDataToModel(entity) },
    })
  }

  updateBatch = async (entities: T[]) => {
    return this.apiMethods.updateBatch({
      url: resolveBackendUrl(`${this.path}/batch`, this.urlPathParams),
      params: entities,
    })
  }

  export = async (params?: QueryParams) => {
    return this.apiMethods.export({
      url: resolveBackendUrl(`${this.path}/export`, this.urlPathParams),
      params: { ...params },
    })
  }

  delete = async (entityId: Identifier) => {
    return this.apiMethods.delete({
      url: resolveBackendUrl(`${this.path}/${entityId}`, this.urlPathParams),
    })
  }
}

import { createContext } from 'react'

const LocalErrorApiContext = createContext<LocalErrorApiContextInfo | undefined>(undefined)

LocalErrorApiContext.displayName = 'LocalErrorApiContext'

const LocalErrorDataContext = createContext<LocalErrorDataContextInfo>({
  error: undefined,
})

LocalErrorDataContext.displayName = 'LocalErrorDataContext'

export { LocalErrorApiContext, LocalErrorDataContext }

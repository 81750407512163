import React from 'react'
import {
  TextInput,
  BymaFormCard,
  LocalErrorAlert,
  BymaSelect,
  BymaSingleAccordion,
} from 'components'
import { Col, Row } from 'react-bootstrap'

import { LugarLiquidacionOptions, SituacionIvaOptions, TipoColocadorIvaOptions } from 'model'

export const ColocadorForm = ({ cardTitle, editEnabled = false }) => {
  return (
    <>
      <BymaFormCard title={cardTitle}>
        <Row sm={12}>
          <Col sm={6}>
            <TextInput
              name='denominacion'
              label='Denominación Colocador'
              required={true}
              readOnly={!editEnabled}
            />
          </Col>
          <Col sm={6}>
            <TextInput name='cuit' label='CUIT - CUIL' required={true} readOnly={!editEnabled} />
          </Col>
        </Row>
      </BymaFormCard>
      <BymaSingleAccordion
        id={'colocador-edit-accordion'}
        style={{
          marginTop: '2%',
          backgroundColor: 'transparent',
          color: 'white',
        }}
      >
        <BymaFormCard title={'Más info'}>
          <Row sm={12} className='mb-3'>
            <Col sm={6}>
              <TextInput name='direccion' label='Dirección' readOnly={!editEnabled} />
            </Col>
            <Col sm={6}>
              <TextInput name='email' label='Email' readOnly={!editEnabled} />
            </Col>
          </Row>

          <Row sm={12} className='mb-3'>
            <Col sm={6}>
              <TextInput name='contacto' label='Contacto' readOnly={!editEnabled} />
            </Col>
            <Col sm={6}>
              <TextInput name='telefono' label='Teléfono' readOnly={!editEnabled} />
            </Col>
          </Row>

          <Row sm={12} className='mb-3'>
            <Col sm={6}>
              <BymaSelect
                modalMenu
                name='situacionIva'
                label='Situación ante el IVA'
                readOnly={!editEnabled}
                options={SituacionIvaOptions}
              />
            </Col>
            <Col sm={6}>
              <BymaSelect
                modalMenu
                name='lugarLiquidacion'
                label='Lugar de liquidación'
                readOnly={!editEnabled}
                options={LugarLiquidacionOptions}
              />
            </Col>
          </Row>

          <Row sm={12}>
            <Col sm={6}>
              <BymaSelect
                modalMenu
                name='tipoColocador'
                label='Tipo de Colocador'
                readOnly={!editEnabled}
                options={TipoColocadorIvaOptions}
              />
            </Col>
            <Col sm={6}>
              <TextInput name='numeroCuenta' label='Número de cuenta' readOnly={!editEnabled} />
            </Col>
          </Row>
        </BymaFormCard>
      </BymaSingleAccordion>

      <div style={{ marginTop: '1em' }}>
        <LocalErrorAlert />
      </div>
    </>
  )
}

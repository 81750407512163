import { ResourceApi } from '../../services/api/resourceApi'
import { Pais } from './Pais'

class PaisesApi extends ResourceApi<Pais> {
  constructor() {
    super('/api/paises')
  }
}

export default new PaisesApi()

import React, { useState } from 'react'
import { useForm, FormProvider } from 'react-hook-form'

import * as yup from 'yup'
import { Col, Form, Row } from 'react-bootstrap'
import { yupResolver } from '@hookform/resolvers/yup'

import {
  BymaButton,
  TextInput,
  BymaCard,
  BymaHelpBox,
  BymaModal,
  BymaPageFrame,
  BymaAlert,
  Loading,
} from 'components'

import { useLogout, useBymaMutation, LoadingProvider } from 'core'

import ChangePasswordApi from './ChangePasswordApi'

import styles from './ChangePassword.module.scss'

interface ChangePasswordProps {
  expiredPassword?: boolean
}

const schema = () =>
  yup
    .object()
    .shape({
      oldPassword: yup.string().required('campo requerido'),
      newPassword: yup
        .string()
        .matches(
          /^(?=.*\d)(?=.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?])(?=.*[A-Z]).{8,}$/,
          'No cumple con alguna de las especificaciones',
        )
        .min(8, 'La contraseña debe tener al menos 8 caracteres')
        .required('campo requerido'),
      confirmPassword: yup
        .string()
        .required('campo requerido')
        .oneOf([yup.ref('newPassword')], 'No coinciden las contraseñas'),
    })
    .required()

const ChangePassword = (props: ChangePasswordProps) => {
  const { expiredPassword = false } = props

  const [showConfirm, setShowConfirm] = useState<boolean>(false)
  const [formData] = useState<any>(undefined)

  // Password states
  const [showPassword, setShowPassword] = useState({
    newPassword: false,
    confirmPassword: false,
    oldPassword: false,
  })
  const [size, setSize] = useState<boolean>(false)
  const [lettersNumbers, setLettersNumbers] = useState<boolean>(false)
  const [oneCapital, setOneCapital] = useState<boolean>(false)
  const [oneNumber, setOneNumber] = useState<boolean>(false)
  const [specialCharacter, setSpecialCharacter] = useState<boolean>(false)

  const methods = useForm<any>({
    resolver: yupResolver(schema()),
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  })

  React.useEffect(() => {
    methods.setFocus('codigo')
  }, [methods.setFocus])

  const logout = useLogout()

  const { mutate } = useBymaMutation({
    mutation: (user: any) => ChangePasswordApi.changePassword({ ...user }),
    successNotification: '¡Cambio de contraseña exitoso!',
    errorNotification: '¡No se pudo cambiar la contraseña!',
    onSuccess: () => {
      if (expiredPassword) {
        setTimeout(() => logout(), 2000)
      }
    },
  })

  const handleConfirm = () => {
    console.log(formData)
    mutate(formData)
  }

  const validateField = (text: string) => {
    // Utiliza 6 caracteres como mínimo.
    setSize(text.length >= 8 ? true : false)
    // Combinación entre números y letras.
    setLettersNumbers(text.match(/^(?=.*[a-zA-Z])(?=.*[0-9])/) ? true : false)
    // Al menos un número.
    setOneNumber(text.match(/\d/) ? true : false)
    // Al menos una letra mayúscula.
    setOneCapital(text.match(/[A-Z]/) ? true : false)
    // Al menos un caracter especial.
    /* eslint-disable */
    setSpecialCharacter(text.match(/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/) ? true : false)
  }

  const renderForm = () => {
    return (
      <Loading>
        <FormProvider {...methods}>
          <Form
            id='change-pass-edit-form'
            onSubmit={(event) => {
              event.preventDefault()
              event.stopPropagation()
              methods.trigger()
              if (methods.formState.isValid) {
                setShowConfirm(true)
              }
            }}
          >
            <BymaCard
              title={'Cambio de contraseña'}
              footer={
                <div>
                  <BymaButton
                    type='submit'
                    id='change-pass-form-btn'
                    name='change-pass-form-btn'
                    label={'Confirmar'}
                    disabled={false /*!methods.formState.isValid*/}
                    style={{
                      border: '1px solid transparent',
                      marginTop: 64,
                      padding: '8px 16px',
                      fontFamily: 'var(--font-family-regular)',
                      fontSize: '14px',
                      lineHeight: '15px',
                    }}
                  />
                </div>
              }
            >
              <Row>
                <Col>
                  <p className={styles.changePassTitle}>Ingresa tu nueva contraseña</p>
                </Col>
              </Row>
              <Row className='mb-3'>
                <Col>
                  <TextInput
                    name='oldPassword'
                    id='oldPassword'
                    label='Antigua contraseña'
                    type={showPassword.oldPassword ? 'text' : 'password'}
                    rightIcon={
                      showPassword.oldPassword ? 'login/visibility.svg' : 'login/visibility_off.svg'
                    }
                    handleRightIconClick={() =>
                      setShowPassword({
                        ...showPassword,
                        oldPassword: !showPassword.oldPassword,
                      })
                    }
                    leftIcon='login/lock.svg'
                    placeHolder='Contraseña antigua'
                  />
                </Col>
              </Row>
              <Row className='mb-3'>
                <Col>
                  <TextInput
                    name='newPassword'
                    deps={'confirmPassword'}
                    label='Nueva contraseña'
                    type={showPassword.confirmPassword ? 'text' : 'password'}
                    leftIcon='login/lock.svg'
                    rightIcon={
                      showPassword.confirmPassword
                        ? 'login/visibility.svg'
                        : 'login/visibility_off.svg'
                    }
                    handleRightIconClick={() =>
                      setShowPassword({
                        ...showPassword,
                        confirmPassword: !showPassword.confirmPassword,
                      })
                    }
                    placeHolder='Contraseña nueva'
                    onChange={(event) => {
                      validateField(event.target.value)
                    }}
                    hideErrorMessage
                  />
                </Col>
              </Row>
              <Row className='mb-3'>
                <Col>
                  <TextInput
                    name='confirmPassword'
                    deps={'newPassword'}
                    label='Confirmar nueva contraseña'
                    type={showPassword.newPassword ? 'text' : 'password'}
                    //icon="fa-lock"
                    leftIcon='login/lock.svg'
                    rightIcon={
                      showPassword.newPassword ? 'login/visibility.svg' : 'login/visibility_off.svg'
                    }
                    handleRightIconClick={() =>
                      setShowPassword({
                        ...showPassword,
                        newPassword: !showPassword.newPassword,
                      })
                    }
                    placeHolder='Confirma la contraseña nueva'
                  />
                </Col>
              </Row>
            </BymaCard>

            <BymaModal
              id={'change-password-confirm-modal'}
              show={showConfirm}
              children={<p className={styles.changePassModal}>¿Querés cambiar tu contraseña?</p>}
              handleCancel={() => {
                setShowConfirm(false)
              }}
              handleConfirm={() => {
                handleConfirm()
                setShowConfirm(false)
              }}
              confirmText='Si, cambiar'
            />
          </Form>
        </FormProvider>
      </Loading>
    )
  }

  const renderCheckList = () => {
    const passwordDirty = methods.formState.dirtyFields.newPassword

    return (
      <BymaHelpBox
        title='Seguridad de la contraseña'
        className={styles.changePassHelpBox}
        children={
          <ul className={styles.changePassHelpBoxUl}>
            <li
              className={size ? styles.checked : passwordDirty ? styles.error : styles.notChecked}
            >
              <div>Utiliza 8 caracteres como mínimo.</div>
            </li>
            <li
              className={
                lettersNumbers ? styles.checked : passwordDirty ? styles.error : styles.notChecked
              }
            >
              <div>Combinación entre números y letras.</div>
            </li>
            <li
              className={
                oneNumber ? styles.checked : passwordDirty ? styles.error : styles.notChecked
              }
            >
              <div>Al menos un número.</div>
            </li>
            <li
              className={
                oneCapital ? styles.checked : passwordDirty ? styles.error : styles.notChecked
              }
            >
              <div>Al menos una letra mayúscula.</div>
            </li>
            <li
              className={
                specialCharacter ? styles.checked : passwordDirty ? styles.error : styles.notChecked
              }
            >
              <div>Al menos un caracter especial.</div>
            </li>
          </ul>
        }
      />
    )
  }

  return (
    <BymaPageFrame
      bcHome={{ value: 'Dashboard', link: '/home' }}
      bcItems={[]}
      bcActive='Cambio de contraseña'
      title='CAMBIO DE CONTRASEÑA'
      iconTitle='login/lock_open.svg'
      className={styles.changePassContainer}
      showBreadcrums={!expiredPassword}
    >
      {expiredPassword && (
        <div>
          <BymaAlert
            variant='info'
            closable={true}
            style={{ marginBottom: 14 }}
            children={
              'Tu contraseña está vencida. Crea una nueva para volver a utilizar en el sistema.'
            }
          />
        </div>
      )}
      <Row className={styles.changePassForm}>
        <Col className='col-3' style={{ paddingRight: 0 }}>
          {renderCheckList()}
        </Col>
        <Col className='col-5 offset-1'>{renderForm()}</Col>
      </Row>
    </BymaPageFrame>
  )
}

export default (props: ChangePasswordProps) => (
  <LoadingProvider>
    <ChangePassword {...props} />
  </LoadingProvider>
)

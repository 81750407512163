import { ResourceApi } from 'services/api/resourceApi'
import { ENTE_LIQUIDADOR_OPTIONS_KEY, EnteLiquidador } from './EnteLiquidador'

class EnteLiquidadorApi extends ResourceApi<EnteLiquidador> {
  constructor() {
    super('/api/entes-liquidadores')
  }
}

const EnteLiquidadorApiInstance = new EnteLiquidadorApi()

export const EnteLiquidadorOptionsQueryProps = {
  dataProvider: () => EnteLiquidadorApiInstance.getList(),
  listKey: ENTE_LIQUIDADOR_OPTIONS_KEY,
}

export default EnteLiquidadorApiInstance

import React from 'react'
import { BymaBadge, BymaClock } from '..'

import './BymaInfoBar.scss'
import { useAuthContext } from 'core'

interface InfoBarProps {
    empty?: boolean
}

const BymaInfoBar = ({ empty = false }: InfoBarProps) => {

    const { userInfo } = useAuthContext()

    console.log('Rendering info bar')
    console.log(userInfo)

    const StatusInfo = () => {
        return <div>
            {'Estado de la aplicación: '}
            <BymaBadge text={'cerrado'} type='error' />
        </div>
    }

    const SiopelInfo = () => {
        return <div>
            {'SIOPEL: '}
            <BymaBadge text={'Si Conn Log'} type='success' />
        </div>
    }

    const InfoBar = () => {
        return <div className='info-bar-container'>
            <StatusInfo />
            <SiopelInfo />
            <BymaClock />
        </div>
    }

    return !empty ? <InfoBar /> : <></>
}

export default BymaInfoBar
import { ResourceApi } from 'services/api/resourceApi'
import { GRUPO_ECONOMICO_OPTIONS_KEY, GrupoEconomico } from './GrupoEconomico'

class GrupoEconomicoApi extends ResourceApi<GrupoEconomico> {
  constructor() {
    super('/api/grupos-economicos')
  }
}

const GrupoEconomicoApiInstance = new GrupoEconomicoApi()

export const GrupoEconomicoOptionsQueryProps = {
  dataProvider: () => GrupoEconomicoApiInstance.getList(),
  listKey: GRUPO_ECONOMICO_OPTIONS_KEY,
}

export default GrupoEconomicoApiInstance

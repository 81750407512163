import { TipoOferta, Titulo } from 'model'
import { OfertaContextInfo } from './OfertaContextInfo'
import { isNullOrUndefined } from 'utils/objects'

const numberYupTransform = (value: any, originalValue: any) => {
  return isNullOrUndefined(originalValue) ||
    (typeof originalValue === 'string' && originalValue.trim() === '')
    ? undefined
    : value
}

const stringYupTransform = (value: any, originalValue: any) => {
  return isNullOrUndefined(originalValue) ? undefined : value
}

const yupNumber = (yup) => yup.number().transform(numberYupTransform)
const yupNumberRequired = (yup) => yupNumber(yup).required()
const yupNumberNullable = (yup) => yupNumber(yup).nullable()

const yupString = (yup) => yup.string().transform(stringYupTransform)
const yupStringRequired = (yup) => yupString(yup).required()
const yupStringNullable = (yup) => yupString(yup).nullable()

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ofertaSchemaBaseProperties = (yup, context: OfertaContextInfo) => ({
  cantidad: yupNumberRequired(yup),
  //see https://github.com/jquense/yup/issues/298
  //.transform(numberYupTransform),

  valor: yup.number().when('tipoOferta', {
    is: (tipoOferta) => {
      return TipoOferta.COMPETITIVA === tipoOferta
    },
    then: yupNumberRequired(yup),
    otherwise: yupNumberNullable(yup),
  }),

  integracionTituloId: yupNumberRequired(yup),

  cuenta: yupStringRequired(yup),

  nombreCliente: yupStringRequired(yup),

  numeroCliente: yupStringRequired(yup),

  cuit: yupStringNullable(yup),
})

const ofertaSchemaConditionanlProperties = (yup, context: OfertaContextInfo) => {
  const properties = {}

  if (context.isFieldEnabled('clase')) {
    properties['clase'] = yupStringRequired(yup)
  }

  if (context.isFieldEnabled('estado')) {
    properties['estado'] = yupStringRequired(yup)
  }

  if (context.createOfertaEnNombreDeEnabled) {
    properties['agenteId'] = yupNumberRequired(yup)
    properties['deTerceros'] = yup.boolean().required()
  }

  return properties
}

const ofertaSchemaFullProperties = (yup, context: OfertaContextInfo) => ({
  ...ofertaSchemaBaseProperties(yup, context),

  porcentajeInversion: yup.number().nullable().transform(numberYupTransform),

  tituloId: yup.number().required(),

  nacionalidadClienteId: yup.number().nullable(),
  grupoEconomicoId: yup.number().nullable(),

  ...ofertaSchemaConditionanlProperties(yup, context),
})

export const getOfertaBaseSchema = (yup, context: OfertaContextInfo) =>
  yup.object().shape(ofertaSchemaBaseProperties(yup, context)).required()

export const getOfertaFullSchema = (yup, context: OfertaContextInfo) =>
  yup.object().shape(ofertaSchemaFullProperties(yup, context)).required()

export const getOfertaBaseDefaultValues = (titulo: Titulo | undefined) => ({
  tipoOferta: TipoOferta.NO_COMPETITIVA,
  integracionTituloId: titulo?.integraciones ? titulo?.integraciones[0]?.id : undefined,
  tituloId: titulo?.id,
  deTerceros: true,
  valor: null,
  cantidad: null,
})

export type TipoColocacion = DenominableEntity

export const TIPO_COLOCACION_OPTIONS_KEY = 'TIPO_COLOCACION_OPTIONS'

export const TIPO_COLOCACION_ENTITY_NAME = 'tipoColocacion'

export enum OrdenTipoColocacion {
  DENOMINACION_ASC = 'denominacion_asc',
  DENOMINACION_DESC = 'denominacion_desc',
}

export const OrdenTipoColocacionOptions = [
  { label: '↑ Denominación', value: OrdenTipoColocacion.DENOMINACION_ASC },
  { label: '↓ Denominación', value: OrdenTipoColocacion.DENOMINACION_DESC },
].map((opt) => ({
  label: `Ordenar por ${opt.label}`,
  optionLabel: opt.label,
  value: opt.value,
}))

import React, { CSSProperties } from 'react'

import { Col, Row } from 'react-bootstrap'

import { ColocacionViewContext } from './ColocacionViewContext'
import { BymaCard, BymaBadge } from 'components'

import { getColocacionEstadoLabel, getColocacionEstadoBadgeType, EstadoColocacion } from 'model'
import { isNullOrUndefined } from 'utils/objects'
import { ColocacionActionButton } from './ColocacionActionButton'

interface ColocacionSummaryProps extends ColocacionViewContext {
  showTitulo?: boolean
  colocacionActionEnabled?: boolean
}

interface DataLabelProps {
  text: string
  style?: CSSProperties
}

export const ColocacionSummary = (props: ColocacionSummaryProps) => {
  const { colocacionActionEnabled = false } = props

  const DataLabel = ({ text, style }: DataLabelProps) => (
    <div style={{ marginRight: '10px', fontWeight: 500, fontSize: 14, ...style }}>{text}</div>
  )

  const nroColocacion = props.colocacionId?.toString() || ''
  const denominacionColocacion = props.colocacion?.denominacion || ''
  const estado = props.colocacion?.estado || EstadoColocacion.ABIERTA

  const { showTitulo = !isNullOrUndefined(props.tituloId) } = props
  const denominacionTitulo = props.titulo?.denominacion || ''

  return (
    <BymaCard style={{ border: '1px solid #6C757D' }}>
      <Row className='mb-1'>
        <Col className='d-flex align-items-center justify-content-start'>
          <div className='d-flex align-items-center justify-content-start ps-4'>
            <DataLabel text='Colocación N°:' />
            <BymaBadge text={nroColocacion} type='data' variant='Big' />
          </div>
        </Col>
        <Col className='d-flex align-items-center justify-content-center'>
          <div className='d-flex align-items-center justify-content-center'>
            <DataLabel text='Denominación:' />
            <div style={{ fontWeight: 300, fontSize: 14 }}>{denominacionColocacion}</div>
          </div>
        </Col>

        <Col className='d-flex align-items-center justify-content-center'>
          {colocacionActionEnabled && !props.editEnabled && !props.readOnly ? (
            <ColocacionActionButton {...props} />
          ) : (
            <div className='d-flex align-items-center justify-content-center'>
              <DataLabel text='Estado' style={{ fontSize: 16 }} />
              <BymaBadge
                variant='Big'
                className='px-2'
                text={getColocacionEstadoLabel(estado)}
                type={getColocacionEstadoBadgeType(estado)}
              />
            </div>
          )}
        </Col>
      </Row>

      {showTitulo && (
        <Row>
          <Col className='d-flex align-items-center justify-content-start'>
            <div className='d-flex align-items-center justify-content-start ps-4'>
              <DataLabel text='Título:' />
              <BymaBadge text={denominacionTitulo} type='data' variant='Big' />
            </div>
          </Col>
        </Row>
      )}
    </BymaCard>
  )
}

import React, { useState } from 'react'
import FormCheck from 'react-bootstrap/FormCheck'

import './BooleanInput.scss'

interface BooleanProps {
  id: string
  label: string
  value: string | number | readonly string[] | undefined
  onChange: any
  disabled?: boolean
  className?: string
}

export const BooleanInput = (props: BooleanProps) => {
  const { id, label, className } = props
  const [checked, setChecked] = useState<boolean>(false)

  return (
    <FormCheck
      id={id}
      className={'boolean-input ' + className}
      type='switch'
      checked={checked}
      onChange={() => {
        console.log('Cambio checkbox!!!!! ' + checked)
        setChecked(checked ? false : true)
        console.log('quedo ' + checked)
      }}
      label={label}
      //disabled={disabled}
    />
  )
}

export default BooleanInput

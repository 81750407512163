import React, { CSSProperties, ReactNode } from 'react'
import Modal from 'react-bootstrap/Modal'

import './BymaModal.scss'
import { BymaButton } from '..'

interface ModalProps {
  id: string
  show: boolean
  children: ReactNode
  title?: string | ReactNode
  size?: 'sm' | 'lg' | 'xl'
  fullscreen?: true | string | 'sm-down' | 'md-down' | 'lg-down' | 'xl-down' | 'xxl-down'
  marginTop?: string
  // Cancel
  handleCancel: any
  showCancel?: boolean
  cancelText?: string
  // Delete
  handleDelete?: any
  showDelete?: boolean
  deleteText?: string
  // Confirm
  handleConfirm: any
  showConfirm?: boolean
  confirmText?: string
  onConfirmSubmit?: boolean

  closeButton?: boolean
  scrollable?: boolean
  style?: CSSProperties | undefined
  headerStyles?: CSSProperties | undefined
  bodyStyles?: CSSProperties | undefined
  backdrop?: 'static' | true | false
  form?: string
  onShow?: () => void
}

const BymaModal = (props: ModalProps) => {
  const {
    id,
    show,
    children,
    title = '',
    handleCancel,
    showCancel = true,
    cancelText = 'Cancelar',
    handleConfirm,
    showDelete = false,
    deleteText = 'Eliminar',
    handleDelete,
    showConfirm = true,
    confirmText = 'Confirmar',
    marginTop = '10%',
    style = {},
    closeButton = true,
    scrollable = false,
    headerStyles = {},
    bodyStyles = {},
    size,
    fullscreen,
    backdrop = 'static',
    form,
    onShow,
  } = props

  return (
    <Modal
      key={id}
      show={show}
      onHide={handleCancel}
      size={size}
      fullscreen={fullscreen}
      className={`byma-modal-container ${id}`}
      backdrop={backdrop}
      style={{
        marginTop: marginTop,
        ...style,
      }}
      onShow={onShow}
      autoFocus={true}
      scrollable={scrollable}
    >
      <Modal.Header closeButton={closeButton} style={{ ...headerStyles }}>
        <Modal.Title style={{ width: '100%' }}>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ ...bodyStyles }}>{children}</Modal.Body>
      <Modal.Footer>
        {showCancel && (
          <BymaButton
            type='button'
            id='entity-cancel-btn'
            name='entity-cancel-btn'
            label={cancelText}
            onClick={handleCancel}
            style={{
              backgroundColor: 'transparent',
              border: '1px solid white',
              padding: '6px 16px',
            }}
          />
        )}
        {showDelete && (
          <BymaButton
            type='button'
            id='entity-delete-btn'
            name='entity-delete-btn'
            label={deleteText}
            onClick={handleDelete}
            form={form}
            style={{
              backgroundColor: 'var(--byma-button-gray)',
              border: '1px solid var(--byma-button-gray)',
              padding: '10.5px 16px',
              fontFamily: 'var(--font-family-regular)',
              fontSize: '14px',
              lineHeight: '15px',
            }}
          />
        )}

        {showConfirm && (
          <BymaButton
            id='entity-confirm-btn'
            name='entity-confirm-btn'
            label={confirmText}
            onClick={handleConfirm}
            type={'submit'}
            form={form}
            style={{
              padding: '10.5px 16px',
              fontFamily: 'var(--font-family-regular)',
              fontSize: '14px',
              lineHeight: '15px',
            }}
          />
        )}
      </Modal.Footer>
    </Modal>
  )
}

export default BymaModal

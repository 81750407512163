import React, { CSSProperties } from 'react'
import { useNavigate } from 'react-router-dom'
import { interpolateString } from 'utils/string'

import { BymaButton } from '../BymaButton/BymaButton'

import styles from './BymaNavigateButton.module.scss'

interface NavigateButtonProps {
  id: string
  label: string
  name: string
  navigateTo?: string
  data?: string
  icon: string
  onClick?: (event: any) => void
  style?: CSSProperties | undefined
}

export const BymaNavigateButton = (props: NavigateButtonProps) => {
  const { id, name = id, label, navigateTo, data, icon, onClick = undefined, style = {} } = props
  const navigate = useNavigate()

  const handleOnClick = (event: any) => {
    if (onClick !== undefined) {
      onClick(event)
    } else if (navigateTo !== undefined) {
      navigate(interpolateString(navigateTo as string, data))
    }
  }

  return (
    <BymaButton
      type='button'
      id={id}
      name={name}
      label={label}
      onClick={handleOnClick}
      groupClassName={styles.navigateGroupButton}
      iconLeft={icon}
      style={{
        backgroundColor: 'transparent',
        border: 'none',
        ...style,
      }}
    />
  )
}

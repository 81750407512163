import React, { useState } from 'react'

import { useForm, FormProvider } from 'react-hook-form'

import { useAuthContext } from 'core'

import { BymaLoadingOverlay } from 'components'
import {
  LoginIcon,
  PersonFilledIcon,
  LockIcon,
  LoginVisibilityOn,
  LoginVisibilityOff,
} from 'components'

import {
  authenticationService,
  AuthenticationServiceResponse,
  AuthenticationErrorMessage,
} from 'services/auth'

import { TextInput, BymaButton, BymaAlert } from 'components'

import * as yup from 'yup'

import { Col, Container, Form, FormGroup, Row } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'

import { yupResolver } from '@hookform/resolvers/yup'
import styles from './Login.module.scss'

import { PASSWORD_EXPIRATION_DAYS_WARNING } from '../../config'

type FormValues = {
  username: string
  password: string
}

const schema = () =>
  yup
    .object()
    .shape({
      username: yup.string().required(),
      password: yup.string().required(),
    })
    .required()

const Login = () => {
  const navigate = useNavigate()
  const [showPassword, setShowPassword] = useState(false)
  const [isLoading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<string | undefined>(undefined)
  const [showRecoveryToast, setShowRecoveryToast] = useState<boolean>(false)
  const authContext: AuthContextInfo = useAuthContext()

  const useFormResult = useForm<FormValues>({
    resolver: yupResolver(schema()),
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
  })

  const { handleSubmit, setFocus } = useFormResult

  React.useEffect(() => {
    setFocus('username')
  }, [setFocus])

  const translateAuthenticationError = (response: AuthenticationServiceResponse) => {
    const errorMessage =
      AuthenticationErrorMessage[response?.errorCode || AuthenticationErrorMessage.UNKNOWN]

    //console.log(`DEBUG Login translateAuthenticationError = ${errorMessage} response=${response}`)

    return errorMessage
  }

  const onSubmit = handleSubmit((data) => {
    setLoading(true)
    authenticationService
      .login({ username: data.username, password: data.password })
      .then((response) => {
        setLoading(false)
        if (!response.success) {
          /*
          console.log(
            `DEBUG Login error = ${response.error} response = ${JSON.stringify(response)}`,
          )
          */
          setError(translateAuthenticationError(response))
          return
        }

        authContext.setToken(response.token)
        authContext.setAuthenticated(true)
        authContext.setUserInfo(response.userInfo)

        let navigateTo = '/home'

        if (
          response.userInfo &&
          response.userInfo.passwordExpirationDays > 0 &&
          response.userInfo.passwordExpirationDays <= PASSWORD_EXPIRATION_DAYS_WARNING
        ) {
          navigateTo = '/change-password-warning'
        }

        setTimeout(() => {
          navigate(navigateTo)
        }, 500)
      })
      .catch((error) => {
        setLoading(false)
        setError(`Error inesperado: ${error}`)
      })
  })

  return (
    <Container className={styles.login} fluid>
      <div className={styles.logo}>
        <img alt='' className={styles.logoImage} src={LoginIcon} />
      </div>
      <BymaLoadingOverlay isLoading={isLoading} className={styles.loading}>
        <div className={styles.rowWrapper}>
          <Row className='m-0 flex-grow-1'>
            <Col md='4' className='align-self-center'>
              <Row>
                <h1>La plataforma de BYMA para licitar y suscribir valores negociables</h1>
              </Row>
            </Col>
            <Col md={{ span: 4, offset: 3 }} className='align-self-center'>
              <FormProvider {...useFormResult}>
                <Form id='loginForm' onSubmit={onSubmit} className={styles.form} autoComplete='off'>
                  <div className={styles.loginHeader} style={{ marginBottom: '64px' }}>
                    <h2 className={styles.title}>Bienvenido</h2>
                    <h5 className={styles.subtitle}>Ingresá tu usuario y contraseña</h5>
                  </div>

                  <FormGroup className='mb-3'>
                    <TextInput
                      name='username'
                      id='username'
                      label='Usuario'
                      className={styles.formInput}
                      leftIcon={PersonFilledIcon}
                      placeHolder='Usuario'
                      variant='Large'
                    ></TextInput>
                  </FormGroup>

                  <FormGroup className='mb-3'>
                    <TextInput
                      name='password'
                      id='password'
                      label='Contraseña'
                      type={showPassword ? 'text' : 'password'}
                      leftIcon={LockIcon}
                      rightIcon={showPassword ? LoginVisibilityOn : LoginVisibilityOff}
                      handleRightIconClick={() => setShowPassword(!showPassword)}
                      placeHolder='Contraseña'
                      className={styles.formInput}
                      variant='Large'
                    ></TextInput>
                  </FormGroup>

                  <div className={styles.loginErrorAlert}>
                    {error && (
                      <BymaAlert
                        variant='danger'
                        children={error}
                        onClose={() => {
                          setError(undefined)
                        }}
                      />
                    )}
                  </div>

                  <BymaButton
                    type='submit'
                    id='login-btn'
                    name='login-btn'
                    label={'Ingresar'}
                    className={styles.submitButton}
                    groupClassName={styles.submitButtonGroup}
                    variant='Large'
                  />

                  <a
                    className={styles.recoveryQuestion}
                    onClick={() => {
                      console.log('CLico en open recovery password')
                      setShowRecoveryToast(true)
                    }}
                  >
                    ¿Te olvidaste la contraseña?
                  </a>

                  <div className={styles.loginRecoveryAlert}>
                    {showRecoveryToast && (
                      <BymaAlert
                        children={
                          <p>
                            La podes restaurar desde la aplicación{' '}
                            <a
                              href='https://www.google.com.ar'
                              rel='noopener noreferrer'
                              target='_blank'
                            >
                              USUARIOS BYMA
                            </a>{' '}
                            o a través de{' '}
                            <a
                              href='https://www.w3schools.com/'
                              rel='noopener noreferrer'
                              target='_blank'
                            >
                              BYMA DIGITAL
                            </a>
                            .
                          </p>
                        }
                        closable={true}
                        onClose={() => {
                          setShowRecoveryToast(false)
                        }}
                        variant='primary'
                      />
                    )}
                  </div>
                </Form>
              </FormProvider>
            </Col>
          </Row>
        </div>
      </BymaLoadingOverlay>
    </Container>
  )
}

export default Login

import { axiosInstance } from './axiosInstance'

//import Cookies from 'js-cookie'
import { getApiIdToken } from './tokenHolder'

const getRequestHeaders = () => {
  const idToken = getApiIdToken()
  return idToken ? { authorization: `Bearer ${idToken}` } : {}
}

export default {
  get: <T>(url: string, params?: object) =>
    axiosInstance.get<T>(url, {
      headers: getRequestHeaders(),
      ...params,
    }),

  post: <T>(url: string, data: any) =>
    axiosInstance.post<T>(url, data, {
      headers: getRequestHeaders(),
    }),

  postMultipart: <T>(url: string, data: any) =>
    axiosInstance.post<T>(url, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
        ...getRequestHeaders(),
      },
    }),

  put: <T>(url: string, data: any) =>
    axiosInstance.put<T>(url, data, {
      headers: getRequestHeaders(),
    }),

  putMultipart: <T>(url: string, data: any) =>
    axiosInstance.put<T>(url, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
        ...getRequestHeaders(),
      },
    }),

  patch: <T>(url: string, data: any) =>
    axiosInstance.patch<T>(url, data, {
      headers: getRequestHeaders(),
    }),
  delete: <T>(url: string) =>
    axiosInstance.delete<T>(url, {
      headers: getRequestHeaders(),
    }),
  download: (url: string, params?: object) =>
    axiosInstance.get(url, {
      headers: {
        ...getRequestHeaders(),
        Accept: 'application/octet-stream',
      },
      ...params,
      responseType: 'blob',
    }),
}

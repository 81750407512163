import React from 'react'
import { TextInput, NumberInput, BymaFormCard, LocalErrorAlert } from 'components'
import { Col, Row } from 'react-bootstrap'

export const GrupoEconomicoForm = ({ cardTitle, editEnabled = false }) => {
  return (
    <>
      <BymaFormCard title={cardTitle}>
        <Row sm={12}>
          <Col sm={6}>
            <NumberInput
              name='numero'
              label='Código Grupo Económico'
              required={true}
              readOnly={!editEnabled}
            />
          </Col>

          <Col sm={6}>
            <TextInput
              name='denominacion'
              label='Denominación Grupo Económico'
              required={true}
              readOnly={!editEnabled}
            />
          </Col>
        </Row>

        <Row sm={12}>
          <Col sm={3}>
            <TextInput name='cuit' label='CUIT/CUIL' required={true} readOnly={!editEnabled} />
          </Col>

          <Col sm={6}>
            <TextInput
              name='aliasSiopel'
              label='Código SIOPEL'
              required={false}
              readOnly={!editEnabled}
            />
          </Col>
        </Row>
      </BymaFormCard>

      <div style={{ marginTop: '1em' }}>
        <LocalErrorAlert />
      </div>
    </>
  )
}

import React from 'react'
import { useState } from 'react'
import AuthContext from './AuthContext'
import { setApiIdToken } from '../../services/api'

export const AuthContainer = ({ children }) => {
  const [userInfo, setUserInfo] = useState<UserInfo | undefined>(undefined)
  const [token, setToken] = useState<string | undefined>(undefined)
  const [authenticated, setAuthenticated] = useState<boolean>(false)

  const tokenConsumer = (newToken: string | undefined) => {
    setToken(newToken)
    setApiIdToken(newToken)
  }
  const userInfoConsumer = (newUserInfo: UserInfo | undefined) => {
    setUserInfo(newUserInfo)
  }
  const authenticatedConsumer = (newAuthenticated: boolean) => {
    setAuthenticated(newAuthenticated)
  }
  const getPasswordExpirationDays = () => userInfo?.passwordExpirationDays

  return (
    <AuthContext.Provider
      value={{
        userInfo,
        setUserInfo: userInfoConsumer,
        token,
        setToken: tokenConsumer,
        authenticated,
        setAuthenticated: authenticatedConsumer,
        getPasswordExpirationDays,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export default AuthContainer

import AgentesApi from './AgentesApi'

export interface Agente extends DenominableEntity {
  idOrganizacion: string //Codigo Agente
  agenteBYMA: boolean
  superAgente: boolean //Supervisor
  superAgenteAsociadoId?: Identifier //Supervisor Asociado (id)
  superAgenteAsociadoDenominacion?: string //Supervisor Asociado (denominacion)
  nroDepositante?: string //Nro Depositante
}

export const AGENTE_OPTIONS_KEY = 'AGENTE_OPTIONS'

export const AGENTE_ENTITY_NAME = 'agente'

function agenteOptionMapper(source: Agente): SelectOption {
  return {
    value: source.id,
    label: `${source.idOrganizacion} - ${source.denominacion}`,
  }
}

export const AgenteOptionsQueryProps = {
  dataProvider: () => AgentesApi.getList(),
  listKey: AGENTE_OPTIONS_KEY,
  optionMapper: agenteOptionMapper,
}

export enum OrdenAgentes {
  DENOMINACION_ASC = 'nombreOrganizacion_asc',
  DENOMINACION_DESC = 'nombreOrganizacion_desc',
  CODIGO_ASC = 'idOrganizacion_asc',
  CODIGO_DESC = 'idOrganizacion_desc',
  NRO_DEPOSITANTE_ASC = 'nroDepositante_asc',
  NRO_DEPOSITANTE_DESC = 'nroDepositante_desc',
}

export const OrdenAgentesOptions = [
  { label: '↑ Código', value: OrdenAgentes.CODIGO_ASC },
  { label: '↓ Código', value: OrdenAgentes.CODIGO_DESC },

  { label: '↑ Denominación', value: OrdenAgentes.DENOMINACION_ASC },
  { label: '↓ Denominación', value: OrdenAgentes.DENOMINACION_DESC },

  { label: '↑ Nro. Depositante', value: OrdenAgentes.NRO_DEPOSITANTE_ASC },
  { label: '↓ Nro. Depositante', value: OrdenAgentes.NRO_DEPOSITANTE_DESC },
].map((opt) => ({
  label: `Ordenar por ${opt.label}`,
  optionLabel: opt.label,
  value: opt.value,
}))

import AxiosMockAdapter from 'axios-mock-adapter-path-params'

import { mockColocaciones } from './colocacionFixtures'
import { mockTitulos } from './tituloFixtures'
import { mockOfertas } from './ofertaFixtures'
import { mockOfertasImport } from './ofertaImportFixtures'
import { mockRoles } from './rolFixtures'
import { mockPermisos } from './permisoFixtures'
import { mockAuthentication } from './loginFixtures'
import { mockEmisores } from './emisorFixtures'
import { mockCategoriasCliente } from './categoriaClienteFixtures'
import { mockColocadores } from './colocadorFixtures'
import { mockGrupoEconomico } from './grupoEconomicoFixtures'
import { mockEnteLiquidador } from './enteLiquidadorFixtures'
import { mockTipoColocacion } from './tipoColocacionFixture'
import { mockAgentes } from './agenteFixtures'
import { mockInversores } from './inversorFixtures'
import { mockPaises } from './paisFixtures'
import { mockCondominos } from './condominoFixtures'
import { mockHelp } from './helpFixtures'

import { agentesResponse, getAgenteResponse } from './agenteFixtures'

export const initMock = (mock: AxiosMockAdapter) => {
  /* Auth */
  mockAuthentication(mock)

  /* Colocaciones */
  mockColocaciones(mock)

  /* Titulos */
  mockTitulos(mock)

  /* Colocadores */
  mockColocadores(mock)

  /* Ofertas */
  mockOfertas(mock)

  /* Ofertas Import */
  mockOfertasImport(mock)

  /* Emisores */
  mockEmisores(mock)

  /* Colocadores */
  mockColocadores(mock)

  /* Grupos Economicos */
  mockGrupoEconomico(mock)

  /* Categorias Cliente */
  mockCategoriasCliente(mock)

  /* Agentes */
  mockAgentes(mock)

  /* Grupos Economicos */
  mockInversores(mock)

  /* Paises */
  mockPaises(mock)

  /* Entes Liquidadores */
  mockEnteLiquidador(mock)

  /* Tipos de Colocacion */
  mockTipoColocacion(mock)

  /* Condominos */
  mockCondominos(mock)

  /* Help */
  mockHelp(mock)

  mock.onGet('/api/agentes').reply(() => {
    return [200, agentesResponse]
  })

  mock.onGet('/api/agentes/{id}').reply(() => {
    return [200, getAgenteResponse(1)]
  })

  /* Roles */
  mockRoles(mock)

  /* Permisos */
  mockPermisos(mock)

  mock.onPut('/api/users/:id').reply((config: any) => {
    const { id } = config.routeParams
    console.log(`MOCK PUT /api/users/${id}`)
    return [200, { data: {} }]
  })

  /* Usuarios */

  mock.onPut('/api/users/:id').reply((config: any) => {
    const { id } = config.routeParams
    console.log(`MOCK PUT /api/users/${id}`)
    console.log(JSON.parse(config.data))
    const data = JSON.parse(config.data)
    if (data && data.oldPassword && data.oldPassword.startsWith('xxx')) {
      return [500, { data: {} }]
    }

    return [200, { data: {} }]
  })
}

import * as React from 'react'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'

import MenuIcon from '@mui/icons-material/Menu'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'

//import MailIcon from '@mui/icons-material/Mail'

import { TreeView } from '@mui/x-tree-view'
import { BymaTreeItem } from './BymaTreeItem'
import { BymaDrawer } from './BymaDrawer'
import { BymaDrawerHeader } from './BymaDrawerHeader'

import styles from './BymaSidebar.module.scss'
import BymaMuiIcon from 'components/BymaIcons/BymaMuiIcon'
import Sidebar from './Sidebar'

interface SidebarItem {
  key: string
  label: string
  icon?: JSX.Element
  onClick?: () => void
  subItems?: SidebarItem[]
}
interface BymaSidebarProps {
  key: string
  items: SidebarItem[]
  children: React.ReactNode
  isDarkTheme?: boolean
  iconType?: 'menu' | 'chevron'
}

const BymaSidebar = (props: BymaSidebarProps) => {
  const { children, items } = props

  return (
    <div className={styles.bymaSidebar}>
      <Sidebar items={items} />

      <div style={{ width: '100%' }}>{children}</div>
    </div>
  )
}

export default BymaSidebar

import { CSSProperties, useCallback, useState } from 'react'
import { ClassNamesConfig } from 'react-select'

import styles from './BymaSelect.module.scss'

export const bymaSelectClassesNames = (props: {
  isError: boolean
  variant?: 'Default' | 'Small'
  hideInput?: boolean
  readOnly?: boolean
}): ClassNamesConfig => {
  return {
    singleValue: () => styles.selectFieldInputSingleValue,
    input: () =>
      props.hideInput
        ? styles[`selectFieldInput${props.variant}`] + ' ' + styles.selectFieldInputHidden
        : styles[`selectFieldInput${props.variant}`],
    control: (state) => {
      if (props.readOnly) {
        return styles.selectFieldControlReadOnly
      }
      const selectStyle = state.isFocused
        ? styles[`selectFieldControlFocus${props.variant}`]
        : styles[`selectFieldControl${props.variant}`]
      return props.isError ? styles.selectFieldControlError : selectStyle
    },
    menu: () => styles.selectFieldMenu,
    menuList: () => styles.selectFieldMenuList,
    option: () => styles[`selectFieldMenuOption${props.variant}`],
    multiValue: () => styles.selectFieldInputMultiValue,
    multiValueLabel: () => styles.selectFielMultiValueGeneric,
    multiValueRemove: () => styles.selectFielMultiValueRemove,
    indicatorSeparator: () => styles.indicatorSeparator,
    indicatorsContainer: () => styles[`indicatorsContainer${props.variant}`],
    valueContainer: () => {
      if (props.readOnly) {
        return styles.selectValueContainerReadOnly
      }
      return styles[`selectValueContainer${props.variant}`]
    },
  }
}

/**
 * Asumimos que el argumento del callback (baseStyles) es siempre el mismo y por lo tan to podemos cachear el resultado
 *
 * @param callback
 * @returns
 */
const useLazyCalc = (callback) => {
  return (arg0) => {
    return callback(arg0)
  }
  //NOTA: este intento de optimizacion genera problemas
  /*
  const [valueCache, setValueCache] = useState()

  return (arg0) => {
    if (valueCache) {
      return valueCache
    }
    const result = callback(arg0)
    setValueCache(result)
    return result
  }
  */
}

export const bymaSelectStyles = (style: CSSProperties | undefined) => {
  const calcOptionStylesSelected = useCallback((baseStyles) => {
    //console.log('DEBUG bymaSelectStyles  calculando optionsSelected')
    return {
      ...baseStyles,
      background: 'var(--secondary-gray-light) !important',
    }
  }, [])

  const calcOptionStylesNotSelected = useCallback((baseStyles) => {
    //console.log('DEBUG bymaSelectStyles  calculando optionsNotSelected')
    return {
      ...baseStyles,
      background: 'transparent',
    }
  }, [])

  const calcControlStyles = useCallback(
    (baseStyles) => {
      //console.log('DEBUG bymaSelectStyles calculando control')
      const controStyles = {
        ...baseStyles,
        minHeight: '25px',
        height: style && style.height ? style.height : '100%',
      }
      return controStyles
    },
    [style],
  )

  const calcValueContainerStyles = useCallback(
    (baseStyles) => {
      //console.log('DEBUG bymaSelectStyles calculando valueContainer')
      return {
        ...baseStyles,
        minHeight: '25px',
        height: style && style.height ? style.height : '100%',
      }
    },
    [style],
  )

  const calcIndicatorsContainerStyles = useCallback(
    (baseStyles) => {
      //console.log('DEBUG bymaSelectStyles calculando indicatorsContainer')
      return {
        ...baseStyles,
        minHeight: '25px',
        height: style && style.height ? style.height : '100%',
      }
    },
    [style],
  )

  //const valueContainerStylesFn = useLazyCalc(calcValueContainerStyles)
  const controlStylesFn = useLazyCalc(calcControlStyles)
  const indicatorContainerStylesFn = useLazyCalc(calcIndicatorsContainerStyles)
  const optionStylesSelectedFn = useLazyCalc(calcOptionStylesSelected)
  const optionStylesNotSelectedFn = useLazyCalc(calcOptionStylesNotSelected)

  return {
    control: controlStylesFn,

    option: (baseStyles, state) => {
      return state.isFocused || state.isSelected
        ? optionStylesSelectedFn(baseStyles)
        : optionStylesNotSelectedFn(baseStyles)
    },

    valueContainer: calcValueContainerStyles, //En este caso no funciona bien el caching

    indicatorsContainer: indicatorContainerStylesFn,
  }
}

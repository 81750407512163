import React, { CSSProperties, KeyboardEventHandler, useCallback } from 'react'
import { useFormContext, Controller } from 'react-hook-form'
import CreatableSelect from 'react-select/creatable'
import { HelperText, ErrorMessage } from 'components'
import { bymaSelectClassesNames, bymaSelectStyles } from './SelectCommons'
import BymaTooltip from 'components/BymaOverlay/BymaTooltip'

import styles from './BymaSelect.module.scss'

import i18n from 'i18n'

import isEqual from 'lodash/isEqual'

interface CreatableSelectProps extends FieldProps {
  onChange?: (selected: any) => void
  valueMapper?: (option: SelectOption | null) => any
  style?: CSSProperties | undefined
  placeholder?: string
  helperText?: string
  value?: string[]
  variant?: 'Default' | 'Small'
}

const hasValue = (fieldValue) =>
  fieldValue !== undefined && fieldValue !== null && fieldValue !== ''

const createOption = (label: string) =>
  hasValue(label)
    ? {
        label,
        value: label,
      }
    : undefined

//IMPORTANTE: retornar null en vez de undefined: react-form-hook no soporta setValue(undefined)
const optionsToValue = (options) =>
  options !== undefined && options.length ? options[0].value : null

const valueToOptions = (value) => (hasValue(value) ? [createOption(value)] : [])

const isDigit = (c) => c >= '0' && c <= '9'

const isControlKey = (key) =>
  key === 'Backspace' || key === 'Delete' || key === 'Tab' || key === 'Enter'

export const BymaTextInputFilter = (props: CreatableSelectProps) => {
  const {
    name,
    id = name,
    label,
    onChange: customOnChange = () => {},
    className = '',
    placeholder = i18n.t('text.writeHere'),
    style = {},
    helperText = undefined,
    required = false,
    toolTipText = undefined,
    type,
    variant,
  } = props

  const width = 'inherit'
  const height = 'inherit'

  const [inputValue, setInputValue] = React.useState('')

  const handleChange = (selected) => {
    customOnChange(selected)
  }

  const { control } = useFormContext()

  const stylesFn = useCallback(() => bymaSelectStyles(styles), [styles])

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState }) => {
        const value = valueToOptions(field.value)

        const hideInput = !!(value && value.length > 0)

        /*
        console.log(
          `DEBUG BymaTextInputFilter ${name} hideInput=${hideInput} value=${JSON.stringify(
            value,
          )} field.value=${JSON.stringify(field.value)}`,
        )
        */

        const handleBlur = (/*event*/) => {
          /*
          console.log(
            `DEBUG BymaTextInputFilter ${name} handleBlur 1 field.value=${JSON.stringify(
              field.value,
            )}`,
          )
          */

          const prev = valueToOptions(field?.value)
          const newOption = createOption(inputValue)
          const updatedValue = newOption ? [...prev, newOption] : prev

          /*
          console.log(
            `DEBUG BymaTextInputFilter ${name} handleBlur 2 prev=${JSON.stringify(
              prev,
            )} newOption=${newOption}`,
          )

          console.log(
            `DEBUG BymaTextInputFilter ${name} handleBlur 2 updatedValue=${JSON.stringify(
              updatedValue,
            )}`,
          )

          console.log(
            `DEBUG BymaTextInputFilter ${name} handleBlur 2 optionsToValue(updatedValue)=${JSON.stringify(
              optionsToValue(updatedValue),
            )}`,
          )
          */

          setInputValue('')

          if (!isEqual(prev, updatedValue)) {
            field.onChange(optionsToValue(updatedValue))
          }
        }

        const handleKeyDown: KeyboardEventHandler = (event) => {
          /*
          console.log(
            `DEBUG BymaTextInputFilter ${name} handleKeyDown event.key=${
              event.key
            } field.value=${JSON.stringify(field.value)}`,
          )
          */

          const prev = valueToOptions(field?.value)
          const newOption = createOption(inputValue)
          const updatedValue = newOption ? [...prev, newOption] : prev

          /*
          console.log(
            `DEBUG BymaTextInputFilter ${name} handleKeyDown updatedValue=${JSON.stringify(
              updatedValue,
            )}`,
          )
          */

          if (
            hasValue(field.value) &&
            event.key !== 'Backspace' &&
            event.key !== 'Delete' &&
            event.key !== 'Tab'
          ) {
            /*
            console.log(
              `DEBUG BymaTextInputFilter ${name} field.value !== undefined event.key=${event.key}`,
            )
            */

            event.preventDefault()
            return
          }

          if (type === 'number' && !isControlKey(event.key) && !isDigit(event.key)) {
            event.preventDefault()
            return
          }

          if (!inputValue) {
            console.log(`DEBUG BymaTextInputFilter ${name} !inputValue`)
            return
          }

          if (hasValue(field.value)) {
            //TODO revisar esto cuando se haga el refactor unificando con el multiple
            return
          }

          switch (event.key) {
            case 'Enter':
              setInputValue('')
              field.onChange(optionsToValue(updatedValue))
              event.preventDefault()
              break
            case 'Tab':
              setInputValue('')
              field.onChange(optionsToValue(updatedValue))
              break
          }
        }

        const components = {
          DropdownIndicator: null,
        }
        const errorMessage = fieldState.error?.message

        /*
        console.log(
          `DEBUG BymaTextInputFilter ${name} tiene valor=${!!(
            value && value.length > 0
          )} ${JSON.stringify(value)}`,
        )
        */

        return (
          <span
            className={'d-inline-block'}
            data-toggle='popover'
            data-trigger='focus'
            data-content='Please selecet account(s)'
            data-mdb-perfect-scrollbar='true'
          >
            <div
              className={
                styles.bymaTextFilter + ' ' + className + styles[`bymaTextFilter${variant}`]
              }
              style={{
                width: width,
                height: height,
                ...style,
              }}
            >
              {label !== undefined ? (
                <div className='d-flex'>
                  <label htmlFor={id} className={styles.inputLabel}>
                    {label + (required ? ' *' : '')}
                  </label>
                  {toolTipText && <BymaTooltip text={toolTipText} />}
                </div>
              ) : null}

              <CreatableSelect
                {...field}
                components={{ ...components }}
                inputValue={inputValue}
                isClearable={false}
                isMulti={true}
                menuIsOpen={false}
                onChange={(newValue: any) => {
                  const fieldValue = optionsToValue(newValue)

                  field.onChange(fieldValue)
                  handleChange(fieldValue)
                }}
                onInputChange={(newValue: any) => setInputValue(newValue)}
                onKeyDown={handleKeyDown}
                onBlur={handleBlur}
                placeholder={placeholder}
                styles={stylesFn()}
                classNames={bymaSelectClassesNames({
                  isError: fieldState.error !== undefined,
                  variant: 'Small',
                  hideInput: hideInput,
                })}
                value={value}
              />
            </div>
            {helperText && <HelperText text={helperText} />}
            <ErrorMessage error={errorMessage} />
          </span>
        )
      }}
    />
  )
}

import React from 'react'
import BymaTooltip from 'components/BymaOverlay/BymaTooltip'
import {
  BymaBadge,
  BymaBadgeTypes,
  BymaTableColumn,
  BymaNavigateButton,
  VisibilityIcon,
  ErrorIcon,
} from '..'

interface TableRowProps {
  value: string
}

interface TableRowInfoProps extends TableRowProps {
  tooltipText?: string
}
interface TableRowErrorProps extends TableRowProps {
  tooltipText: string
  errorLevel: string
}

interface TableRowIconProps extends TableRowProps {
  icon?: string
}

interface TableRowLinkProps extends TableRowProps {
  href: string
}

interface TableRowBadgeProps extends TableRowProps {
  icon?: string
  type?: BymaBadgeTypes
  overlayText?: string
  text?: string
  variant?: 'Default' | 'Big'
  className?: string
}

export const BymaTableInfoRow = (props: TableRowInfoProps) => {
  const { tooltipText, value } = props

  return value !== null && value !== undefined ? (
    <div className='byma-table-info-row'>
      <span>{value}</span>
      {tooltipText && <BymaTooltip text={tooltipText} icon='after' />}
    </div>
  ) : null
}

export const BymaTableErrorRow = (props: TableRowErrorProps) => {
  const { tooltipText, value } = props

  return value !== null && value !== undefined ? (
    <div className='byma-table-error-row'>
      <span>{value}</span>
      {tooltipText && <BymaTooltip text={tooltipText} icon='after' type='error' />}
    </div>
  ) : null
}

export const BymaTableLinkRow = (props: TableRowLinkProps) => {
  const { href, value } = props

  return (
    <a href={href} style={{ color: 'white' }}>
      {value}
    </a>
  )
}

export const BymaTableIconRow = (props: TableRowIconProps) => {
  const { value, icon } = props

  return (
    <div>
      {value !== undefined ? (
        <span style={{ flexGrow: 1, textOverflow: 'ellipsis', paddingRight: '5%' }}>{value}</span>
      ) : undefined}
      {icon && <img src={icon} />}
    </div>
  )
}

export const BymaTableBadgeRow = (props: TableRowBadgeProps) => {
  const { value, overlayText, type, text, variant, className } = props

  return (text !== undefined && text !== null && text.length > 0) ||
    (value !== undefined && value !== null && value.length > 0) ? (
    <BymaBadge
      text={text ? text : value}
      overlayText={overlayText}
      type={type}
      variant={variant}
      className={className}
    />
  ) : undefined
}

export const useBymaTableBadgeCellRenderProps = (dataProvider: (params) => any) => {
  return {
    cellRenderer: BymaTableBadgeRow,
    cellRendererParams: (params) => {
      const value = dataProvider(params)
      return value
        ? {
            text: value,
            overlayText: value,
            type: 'data-cell',
          }
        : {}
    },
  }
}

export interface NavigateButtonCellProps extends Omit<BymaTableColumn, 'field'> {
  routeProvider: (data: any) => string
  field?: string
}

export const useNavigateDetailButtonCell = ({
  headerName = 'Ver y Editar',
  field = 'edit',
  routeProvider,
  minWidth = 50,
  maxWidth = 80,
  ...rest
}: NavigateButtonCellProps): BymaTableColumn => {
  return {
    field,
    headerName,
    cellClass: 'action-button',
    cellRenderer: BymaNavigateButton,
    cellRendererParams: (params) => {
      return {
        navigateTo: routeProvider(params?.data),
        label: '',
        icon: VisibilityIcon,
      }
    },
    minWidth,
    maxWidth,
    ...rest,
  }
}

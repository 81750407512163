import React from 'react'
import { Navigate } from 'react-router-dom'
import * as BymaPermissions from 'services/auth/bymaPermissions'
import { isComponentAuthorized, useAuthContext } from 'core/auth'

const BymaHome = () => {
  const { userInfo } = useAuthContext()

  return isComponentAuthorized(
    BymaPermissions.DASHBOARD_OFERTAS_PERMISSION,
    userInfo?.authorities,
  ) ? (
    <Navigate to='/dashboard' />
  ) : (
    <div></div>
  )
}

export default BymaHome

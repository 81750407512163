import React, { CSSProperties, ReactNode, useState } from 'react'

import { Stepper } from './react-form-stepper'
import { StepDTO } from './react-form-stepper/components/Step/StepTypes'
import { FormProvider, useForm } from 'react-hook-form'

import { BymaButton, LocalErrorAlert } from '..'

import './BymaStepper.scss'
import { Form } from 'react-bootstrap'
import { yupResolver } from '@hookform/resolvers/yup'
import Lazy from 'yup/lib/Lazy'
import { AnyObjectSchema } from 'yup'
import {
  ErrorNotificationParamType,
  MutationFnParam,
  MutationSuccessCallbackParam,
  NotificationParamType,
  useBymaMutation,
} from 'core'

import { getApiErrorFields } from 'services/api'

interface BymaStep extends StepDTO {
  children?: ReactNode
  renderChildren?: () => ReactNode
  validationSchemaSteps: AnyObjectSchema | Lazy<any, unknown>
  stepFields: string[]
}

interface StepperProps {
  id: string
  key?: string
  steps: BymaStep[]
  activeStep?: number
  onCancel: () => void
  // Form props
  mutation: MutationFnParam<any, any>
  successNotification?: NotificationParamType<any, any>
  errorNotification?: ErrorNotificationParamType<any>
  onSuccess: MutationSuccessCallbackParam<any, any>
  defaultValues?: any
  variant?: 'small' | 'medium' | 'large'
  showStepLabels?: boolean
  style?: CSSProperties | undefined
}

const BymaStepper = (props: StepperProps) => {
  const {
    id,
    steps,
    onCancel,
    mutation,
    successNotification,
    errorNotification,
    onSuccess,
    defaultValues = {},
    showStepLabels = true,
    variant = 'large',
    style = {},
  } = props

  const [activeStep, setActiveStep] = useState<number>(0)

  const getStepContent = () => {
    const currentStep =
      steps !== undefined && steps[activeStep] !== undefined ? steps[activeStep] : undefined
    const render = currentStep?.renderChildren
    return render ? render() : currentStep?.children
  }

  const finalSteps: StepDTO[] = steps.map((step: BymaStep, idx: number) => ({
    label: showStepLabels ? step.label : undefined,
    active: idx === activeStep,
    completed: step.completed,
    onClick: () => setActiveStep(idx),
  }))

  const currentBymaStep = steps[activeStep]
  const currentValidationSchema = currentBymaStep.validationSchemaSteps
  const currentFieldNames = currentBymaStep.stepFields

  const onError = (apiError: any) => {
    const errorFields = getApiErrorFields(apiError as ApiError)
    const errorIndex = steps.findIndex((step) =>
      step.stepFields.some((f) => errorFields.includes(f)),
    )
    if (errorIndex >= 0) {
      setActiveStep(errorIndex)
    }
  }

  const useFormReturn = useForm({
    shouldUnregister: false,
    defaultValues,
    resolver: yupResolver(currentValidationSchema),
    mode: 'onSubmit',
  })

  const useBymaMutationResult = useBymaMutation<any, any, any>({
    mutation: mutation,
    onSuccess: onSuccess,
    onError: onError,
    successNotification: successNotification,
    errorNotification: errorNotification,
    useFormReturn: useFormReturn,
  })

  const { handleSubmit, reset, trigger } = useFormReturn

  const onSubmitImpl = (newData: any) => {
    useBymaMutationResult.mutate(newData)
  }

  const handleCancel = (e) => {
    setActiveStep(0)
    reset()
    onCancel()
    e.preventDefault()
  }

  const handleNext = async (e) => {
    //
    //TODO BP-261 ejecutar el trigger solo en los campos del step
    //De otro modo se blnaquean los errores de los siguientes steps (eg: errores seteados por el backend)
    //
    //Tambien tener en cuenta que el trigger va a limpiar los errores informados x el backend
    //Se deberia hacer un backup de los errores provenientes del backend y vovler a colocar los errores
    //despues de la validacion
    //
    const isStepValid = await trigger(currentFieldNames)
    if (isStepValid) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1)
    }
    e.preventDefault()
  }

  const handleBack = (e) => {
    setActiveStep(activeStep - 1)
    e.preventDefault()
  }

  let connectorStyleConfig = {}
  let styleConfig = {}

  if (variant === 'large') {
    connectorStyleConfig = {
      size: '-45%',
      stepSize: '20.5%',
    }

    styleConfig = {
      size: '35%',
      circleFontSize: '25px',
      labelFontSize: '10px',
      borderRadius: '50%',
    }
  } else if (variant === 'small') {
    connectorStyleConfig = {
      size: showStepLabels ? '-40%' : '-80%',
      stepSize: showStepLabels ? '13%' : '13%',
    }

    styleConfig = {
      size: '20%',
      circleFontSize: '16px',
      labelFontSize: '10px',
      borderRadius: '50%',
    }
  }

  return (
    <FormProvider {...useFormReturn}>
      <Form
        id={id + '-stepper-form'}
        onSubmit={handleSubmit(onSubmitImpl)}
        style={{ padding: '0px' }}
      >
        <div style={{ ...style }}>
          <Stepper
            style={{ width: '70%', marginLeft: '15%' }}
            steps={finalSteps}
            stepClassName='byma-custom-step'
            connectorStateColors={true}
            hideConnectors={false}
            connectorStyleConfig={{
              activeColor: '#0183d9',
              disabledColor: '#4E5261',
              completedColor: '#0183d9',
              size: '-45%',
              stepSize: '20.5%',
              style: 'border: 1px solid',
              ...connectorStyleConfig,
            }}
            styleConfig={{
              activeBgColor: '#0183d9',
              completedBgColor: '#0183d9',
              inactiveBgColor: 'var(--secondary-gray-light)',
              activeTextColor: '#FFFFFF',
              completedTextColor: '#FFFFFF',
              inactiveTextColor: 'var(--secondary-gray-normal)',
              size: '35%',
              circleFontSize: '25px',
              labelFontSize: '10px',
              borderRadius: '50%',
              fontWeight: '400',
              ...styleConfig,
            }}
            activeStep={activeStep}
          />

          <div style={{ padding: '20px' }}>
            {getStepContent()}

            <div className='byma-stepper-local-alert'>
              <LocalErrorAlert />
            </div>

            <div className='byma-stepper-buttons'>
              <BymaButton
                type='button'
                id='cancel-step-btn'
                name='cancel-step-btn'
                style={{
                  backgroundColor: 'transparent',
                  border: '1px solid white',
                  padding: '6px 16px',
                }}
                label={'Cancelar'}
                onClick={handleCancel}
              />

              {activeStep !== 0 && (
                <BymaButton
                  type='button'
                  id='complete-step-btn'
                  name='complete-step-btn'
                  onClick={handleBack}
                  label={'Volver'}
                />
              )}
              {activeStep === steps.length - 1 ? (
                <BymaButton
                  type='submit'
                  id='complete-step-btn'
                  name='complete-step-btn'
                  label={'Completar'}
                />
              ) : (
                <BymaButton
                  type='button'
                  id='next-step-btn'
                  name='next-step-btn'
                  label={'Siguiente'}
                  onClick={handleNext}
                />
              )}
            </div>
          </div>
        </div>
      </Form>
    </FormProvider>
  )
}

export default BymaStepper

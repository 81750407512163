import React from 'react'

import {
  BymaButton,
  BymaCard,
  BymaDataTable,
  BymaExportButton,
  BymaPageFrame,
  BymaTextInputFilter,
  BymaSelect,
} from 'components'

import { ListBase, useListContext, useNotificationProducer, LoadingProvider } from 'core'

import { FiltersHeader } from 'features/common/Filters'

import { useEditButtonCell, useDeleteButtonCell } from 'features/common/Entity'

import styles from './Colocadores.module.scss'
import { EntityContextInfo, useEntityContext } from 'features/common/Entity'
import {
  COLOCADOR_ENTITY_NAME,
  ColocadoresApi,
  OrdenColocadoresOptions,
  OrdenColocadores,
} from 'model/colocador'
import ColocadorModal from './ColocadorModal'
import { SORT_FILTER_NAME } from 'services/api'

const getColocadoresColumns = (context: EntityContextInfo) => {
  return [
    {
      field: 'denominacion',
      headerName: 'Denominación Colocador',
      textAlign: 'left',
      minWidth: 350,
    },
    {
      field: 'cuit',
      headerName: 'CUIT / CUIL',
      textAlign: 'left',
      maxWidth: 150,
    },

    useEditButtonCell({ context, minWidth: 40 }),
    useDeleteButtonCell({ context, minWidth: 40 }),
  ]
}

/**
 *
 * @returns TODO crear componente SbaList
 */
const ColocadoresTable = () => {
  const { addNotification } = useNotificationProducer()

  const context = useEntityContext(COLOCADOR_ENTITY_NAME)

  const { filterValues } = useListContext()

  const handleAddColocador = () => {
    context.setEntityId(undefined)
    context.setShowModal(true)
    context.setEditEnabled(true)
  }

  const dataProvider = (params) => ColocadoresApi.getPaginatedList(params)

  const filters = [
    <BymaTextInputFilter
      name='denominacion'
      key='denominacion'
      label='Denominación Colocador'
      type='text'
      alwaysOn={true}
      style={{ width: '220px' }}
      variant='Small'
    />,

    <BymaTextInputFilter
      name='cuit'
      key='cuit'
      label='CUIT/CUIL'
      type='text'
      alwaysOn={true}
      style={{ width: '220px' }}
      variant='Small'
    />,

    <BymaSelect
      name={SORT_FILTER_NAME}
      key={SORT_FILTER_NAME}
      label='Ordenar'
      style={{ minWidth: '250px' }}
      options={OrdenColocadoresOptions}
      alwaysOn={true}
      variant='Small'
      hideLabel={true}
    />,
  ]

  return (
    <BymaPageFrame
      bcHome={{ value: 'Administrar', link: '/home' }}
      bcItems={[]}
      bcActive='ABM Colocadores'
      showTitle={false}
      showToast={true}
      className={styles.abmColocadoresContainer}
    >
      <BymaCard key={'colocadores-card'} header={{ title: 'Colocadores' }}>
        <div style={{ paddingBottom: '1%', paddingTop: '2%' }}>
          <FiltersHeader
            filters={filters}
            showFiltersMenu={false}
            extra={[
              <BymaButton
                type='button'
                id='colocadores-add-btn'
                key='colocadores-add-btn'
                name='colocadores-add-btn'
                label={'+ Nuevo Colocador'}
                onClick={handleAddColocador}
              />,
            ]}
            exportButton={
              <BymaExportButton
                id='colocadores-export-button'
                name='colocadores-export-button'
                serverDownload={() => ColocadoresApi.export({ filter: filterValues })}
                getFileName={() => 'colocadores.csv'}
                postDownloading={() =>
                  addNotification({
                    message: 'Se descargo archivo de Colocadores',
                    type: 'info',
                  })
                }
              />
            }
          />
        </div>

        <BymaDataTable
          key='colocadores'
          columns={getColocadoresColumns(context)}
          sizeColumnsToFit={true}
          style={{ height: '310px', width: '100%' }}
          queryProps={{
            dataProvider: dataProvider,
            listKey: context.listQueryKey,
          }}
          listPaginationProps={{
            showLimits: true,
          }}
          //suppressHorizontalScroll={true}
        />
      </BymaCard>

      <ColocadorModal
        context={context}
        api={ColocadoresApi}
        navigateTo='/colocadores'
        formId='colocador-form'
      />
    </BymaPageFrame>
  )
}

const ColocadoresList = () => {
  return (
    <LoadingProvider>
      <ListBase
        listKey='colocadores.hist'
        filterDefaultValues={{ [SORT_FILTER_NAME]: OrdenColocadores.DENOMINACION_ASC }}
      >
        <ColocadoresTable />
      </ListBase>
    </LoadingProvider>
  )
}

export default ColocadoresList

/* eslint-disable react/display-name */
import React, { CSSProperties } from 'react'
import { useFormContext } from 'react-hook-form'
import cloneDeep from 'lodash/cloneDeep'
import { HelperText, ErrorMessage, getIconPath } from 'components'
import BymaTooltip from 'components/BymaOverlay/BymaTooltip'

import styles from './TextInput.module.scss'
import { get } from 'lodash'

export interface TextInputProps extends FieldProps {
  leftIcon?: string
  rightIcon?: string
  autoComplete?: boolean
  placeHolder?: string
  onChange?: (selected: any) => void
  inputClassName?: string
  fullWidth?: boolean
  divStyle?: CSSProperties
  variant?: 'Default' | 'Small' | 'Large'
  handleRightIconClick?: () => void
  onBlur?: (selected: any) => void
  hideErrorMessage?: boolean
  parse?: (value: any) => any
  helperText?: string
  required?: boolean
}

export const TextInput = (props: TextInputProps) => {
  const {
    name,
    id = name,
    label,
    value,
    className,
    inputClassName,
    fullWidth = true,
    leftIcon,
    rightIcon,
    onChange: customOnChange,
    onBlur: customOnBlur,
    type = 'text',
    readOnly: readonly = false,
    placeHolder = 'Completa',
    style = {},
    divStyle = {},
    variant = 'Default',
    deps = [],
    handleRightIconClick,
    hideErrorMessage = false,
    parse,
    helperText = undefined,
    required = false,
    toolTipText = undefined,
  } = props

  const { register, formState } = useFormContext()

  const formFieldProps = register(name, { deps })

  const errorMessage: string | undefined = get(formState.errors, name)?.message as string

  //console.log(`DEBUG TextInput render ${name} errors=${JSON.stringify(Object.keys(formState.errors))} errorMessage=${errorMessage} deps=${deps}`)

  const onChange = (event: any) => {
    //console.log('DEBUG TextInput onChange')

    if (parse && event.target) {
      event = cloneDeep(event)
      event.target.value = parse(event.target.value)
    }

    formFieldProps.onChange(event)

    if (customOnChange) {
      customOnChange(event)
    }
  }

  const onBlur = (event: any) => {
    //console.log('DEBUG TextInput onBlur')

    formFieldProps.onBlur(event)
    if (customOnBlur) {
      customOnBlur(event)
    }
  }

  const inputVariantClassName = inputClassName
    ? inputClassName
    : styles[`inputInnerAddon${variant.charAt(0).toUpperCase() + variant.slice(1)}`]

  //console.log(`DEBUG TextInput variant=${variant} InputVariantClassName=${inputVariantClassName}`)

  const readOnlyClass = readonly ? styles.inputInnerAddonReadOnly : ''

  const inputClasses = `${
    errorMessage ? styles.inputFormFieldError : ''
  } ${inputVariantClassName} ${readOnlyClass}`

  const getStyle = () => {
    return fullWidth ? { ...style, width: '100%' } : style
  }

  return (
    <div
      className={
        styles.bymaTextField +
        ' form-group ' +
        styles[`bymaTextField${variant}`] +
        ' ' +
        (className ?? '')
      }
      style={divStyle}
    >
      {label && (
        <div>
          <label htmlFor={id} className={styles.fieldLabel}>
            {label + (required ? '*' : '')}
          </label>
          {toolTipText && <BymaTooltip text={toolTipText} />}
        </div>
      )}
      <div className={`${styles.inputInnerAddon} ${inputClasses}`}>
        {leftIcon && (
          <div className={styles.leftIcon}>
            <img className={styles.inputLeftAddon} src={getIconPath(leftIcon)} />
          </div>
        )}
        <input
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...formFieldProps}
          type={type}
          className={styles.inputFormField + ' bg-transparent '}
          readOnly={readonly}
          ref={formFieldProps.ref}
          placeholder={readonly ? '-' : placeHolder}
          defaultValue={value}
          autoComplete='new-password'
          onChange={onChange}
          onBlur={onBlur}
          style={getStyle()}
        />
        {rightIcon && (
          <div className={styles.rightIcon} onClick={handleRightIconClick}>
            <img src={getIconPath(rightIcon)} />
          </div>
        )}
      </div>

      {helperText && <HelperText text={helperText} />}
      {!hideErrorMessage && <ErrorMessage error={errorMessage} />}
    </div>
  )
}

import React, { useCallback, useMemo } from 'react'

import {
  AttachFileIcon,
  BymaButton,
  BymaCard,
  BymaSingleAccordion,
  BymaUploadButton,
  DownloadIcon,
  Loading,
} from 'components'
import { ColocacionViewContext } from 'features/Colocacion/ColocacionViewContext'
import { FormProvider } from 'react-hook-form'
import { Form } from 'react-bootstrap'
import { useCreateForm, withLoadingProvider } from 'core'
import OfertasImportApi from 'model/ofertasImport/OfertasImportApi'
import * as yup from 'yup'

import styles from './ImportOfertas.module.scss'

interface ImportOfertasProps {
  context: ColocacionViewContext
  setImportData: (importData: any) => void
}

const ImportOfertasContent = (props: ImportOfertasProps) => {
  //
  //TODO BP-348 invocar la exportacion de ofertas (la misma api que se utiliza en LibroOfertasColocacion)
  //
  const handleDownloadModel = () => {}

  const handleCancel = () => {}

  const FieldsDefinitions = () => {
    return (
      <div className={styles.filedsDefinitions}>
        <BymaCard
          id={'import-ofertas-fields-info-card'}
          titleBackgorundColor='var(--secondary-gray-normal)'
          style={{ marginBottom: '20px' }}
        >
          <BymaSingleAccordion id={'import-ofertas-fields-info'} title={'Definición de los campos'}>
            <span>
              {
                ' Sed ut perspiciatis, unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam eaque ipsa, quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt, explicabo. Nemo enim ipsam voluptatem, quia voluptas sit, aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos, qui ratione voluptatem sequi nesciunt, neque porro quisquam est, qui dolorem ipsum, quia dolor sit amet consectetur adipisci[ng] velit, sed quia non numquam [do] eius modi tempora inci[di]dunt, ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum[d] exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? [D]Quis autem vel eum i[r]ure reprehenderit, qui in ea voluptate velit esse, quam nihil molestiae consequatur, vel illum, qui dolorem eum fugiat, quo voluptas nulla pariatur? At vero eos et accusamus et iusto odio dignissimos ducimus, qui blanditiis praesentium voluptatum deleniti atque corrupti, quos dolores et quas molestias excepturi sint, obcaecati cupiditate non provident, similique sunt in culpa, qui officia deserunt mollitia animi, id est laborum et dolorum fuga. Et harum quidem reru[d]um facilis est e[r]t expedita distinctio. Nam libero tempore, cum soluta nobis est eligendi optio, cumque nihil impedit, quo minus id, quod maxime placeat facere possimus, omnis voluptas assumenda est, omnis dolor repellend[a]us. Temporibus autem quibusdam et aut officiis debitis aut rerum necessitatibus saepe eveniet, ut et voluptates repudiandae sint et molestiae non recusandae. Itaque earum rerum hic tenetur a sapiente delectus, ut aut reiciendis voluptatibus maiores alias consequatur aut perferendis doloribus asperiores repellat.'
              }
            </span>
          </BymaSingleAccordion>
        </BymaCard>
      </div>
    )
  }

  const StepsContent = (props: any) => {
    return (
      <div className={styles.importOfertasSteps}>
        <div className={styles.importOfertasStep}>
          <div className={styles.stepNumber}>
            <span>1</span>
          </div>
          <div className={styles.stepDescription}>Descargar el archivo de ofertas</div>
        </div>
        <div className={styles.importOfertasStep}>
          <div className={styles.stepNumber}>
            <span>2</span>
          </div>
          <div className={styles.stepDescription}>
            Seleccionar la opción para modificar el estado de las ofertas. Los estados pueden ser{' '}
            {'"No confirmada"'} - {'"Vigente"'}
          </div>
        </div>
        <div className={styles.importOfertasStep}>
          <div className={styles.stepNumber}>
            <span>3</span>
          </div>
          <div className={styles.stepDescription}>
            Seleccionar el archivo con las ofertas y su nuevo estado
          </div>
        </div>
        <div className={styles.importOfertasStep}>
          <div className={styles.stepNumber}>
            <span>4</span>
          </div>
          <div className={styles.stepDescription}>
            Previsualiza las ofertas con su nuevo estado. En el caso de que se encuentren errores,
            deberías volver a subir el archivo completo nuevamente
          </div>
        </div>
        <div className={styles.importOfertasStep}>
          <div className={styles.stepNumber}>
            <span>5</span>
          </div>
          <div className={styles.stepDescription}>
            Selecciona la opción importar para aceptar la modificación del estado
          </div>
        </div>
      </div>
    )
  }

  const { context, setImportData } = props

  const { colocacionId } = context

  const uploadFile = useCallback(
    (data) => {
      return OfertasImportApi.create({ ...data, colocacionId })
    },
    [colocacionId],
  )

  const getUploadFileSchema = (yup) =>
    yup.object().shape({
      archivo: yup.mixed().required('Debe ingresar un archivo'),
    })

  const schema = useMemo(() => getUploadFileSchema(yup), [yup])

  const onSuccessImpl = useCallback((data, variables) => {
    setImportData({
      ...data,
      fileName: variables?.archivo?.name,
    })
  }, [])

  const uploadForm = useCreateForm<any>({
    defaultValues: {},
    values: {},
    schema: schema,
    mutation: uploadFile,
    taskMessage: 'Cargando archivo de ofertas...',
    successNotification: () => 'Archivo cargado exitosamente',
    errorNotification: (error) => `Error Cargando archivo: ${error.message}`,
    onSuccess: onSuccessImpl,
  })

  const IMPORT_OFERTAS_UPLOAD_FORM_ID = 'import-ofertas-upload-form'

  return (
    <FormProvider {...uploadForm.form}>
      <Form
        style={{ width: '100%' }}
        encType='multipart/form-data'
        id={IMPORT_OFERTAS_UPLOAD_FORM_ID}
        onSubmit={uploadForm.onSubmit}
      >
        <Loading>
          <BymaCard
            key={'import-ofertas-card'}
            footer={
              <div className={styles.stepsButtons}>
                <div className={styles.stepsRightButtons}>
                  {/* TODO Usar BymaDownloadButton o useDownloadFile */}
                  <BymaButton
                    className={styles.stepButton}
                    type='button'
                    id={'download-model-file'}
                    name={'download-model-file'}
                    label={'Descargar archivo modelo'}
                    onClick={handleDownloadModel}
                    //groupClassName={'export-group-button'}
                    groupClassName={styles.exportGroupButton}
                    iconLeft={DownloadIcon}
                    //disabled={isDownloading}
                    style={{
                      backgroundColor: '#626573',
                      border: '1px solid #626573',
                      height: '33px',
                    }}
                  />

                  <BymaUploadButton
                    name='archivo'
                    required={true}
                    label='Seleccionar archivo y previsualizar'
                    iconLeft={AttachFileIcon}
                    style={{
                      height: '33px',
                    }}
                    onChange={() => {
                      uploadForm.onSubmit()
                    }}
                    variant='Small'
                  />
                </div>
                <div className={styles.stepsLeftButtons}>
                  <BymaButton
                    type='button'
                    id='import-oferts-cancel-btn'
                    name='import-oferts-cancel-btn'
                    label={'Cancelar'}
                    onClick={handleCancel}
                    style={{
                      backgroundColor: 'transparent',
                      border: '1px solid #626573',
                    }}
                  />
                  <BymaButton
                    type='submit'
                    id='import-oferts-submit-btn'
                    name='import-oferts-submit-btn'
                    label={'Importar'}
                    onClick={() => {}}
                    disabled={true}
                    style={{
                      backgroundColor: '#626573',
                      border: '1px solid #626573',
                    }}
                  />
                </div>
              </div>
            }
          >
            <StepsContent />

            <FieldsDefinitions />
          </BymaCard>
        </Loading>
      </Form>
    </FormProvider>
  )
}

export default withLoadingProvider(ImportOfertasContent)

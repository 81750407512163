import React, { useCallback, useMemo } from 'react'

//TODO implementar hook para referescar el detalle
import { useQueryClient } from 'react-query'

import { useNavigate } from 'react-router-dom'
import { FormProvider } from 'react-hook-form'
import { Form } from 'react-bootstrap'
import { Col, Row } from 'react-bootstrap'

import { useEditForm, useLocalErrorProducer } from 'core'
import { routes } from 'routes'

import { BymaButton, LocalErrorAlert, BymaCard, EditIcon, DeleteIcon, BymaModal } from 'components'

import { getColocacionDetailRoute } from 'routes'
import ColocacionesApi from 'model/colocacion/ColocacionesApi'

import {
  generalStepFields,
  generalStepYupFields,
} from '../../ColocacionFields/ColocacionGeneralStep'
import {
  fechaHorariosStepFields,
  fechaHorariosStepYupFields,
} from '../../ColocacionFields/ColocacionDatesStep'
import {
  complementariosStepFields,
  complementariosStepYupFields,
} from '../../ColocacionFields/ColocacionComplementStep'

import * as yup from 'yup'

import { ColocacionSectionCard } from '../ColocacionSectionCard'
import { ColocacionViewContext, useColocacionEditContext } from '../../ColocacionViewContext'
import { useBymaMutation } from 'core'

import EditarColocacionIcon from 'assets/img/colocacion-view/colocacion_edit.svg'

import { ColocacionActionButton } from 'features/Colocacion/ColocacionActionButton'

//TODO usar variantes + estilo para los botones (en este caso secondary)
const secondaryButtonStyle = {
  backgroundColor: '#626573',
  border: 'none',
  padding: '10.5px 16px',
  fontFamily: 'var(--font-family-regular)',
  fontSize: '14px',
  lineHeight: '15px',
}

//TODO usar variantes + estilo para los botones (en este caso primary)
const primaryStyleButton = {
  border: '1px solid #626573',
  marginTop: 5,
  marginLeft: 0,
}

const EditButton = (context: ColocacionViewContext) => {
  const localErrorProducer = useLocalErrorProducer()

  return (
    <BymaButton
      id='entity-confirm-btn'
      name='entity-confirm-btn'
      label={'Editar'}
      onClick={() => {
        context.setEditEnabled(true)
        if (localErrorProducer) {
          localErrorProducer.clearError()
        }
      }}
      type={'button'}
      iconLeft={EditIcon}
      style={{
        padding: '10.5px 16px',
        fontFamily: 'var(--font-family-regular)',
        fontSize: '14px',
        lineHeight: '15px',
      }}
    />
  )
}

const ClonarButton = (context: ColocacionViewContext) => {
  const navigate = useNavigate()

  const onClonarSuccess = useCallback(
    (data) => {
      if (data?.id) {
        navigate(getColocacionDetailRoute(data.id))
      }
    },
    [navigate],
  )

  const clonarMutationResult = useBymaMutation({
    mutation: () => ColocacionesApi.clonar(context.colocacionId),
    successNotification: 'Se clonó la colocación exitosamente',
    onSuccess: (data) => onClonarSuccess(data),
  })

  return (
    <BymaButton
      type='button'
      id='roles-form-btn'
      name='roles-form-btn'
      label={'+ Clonar'}
      onClick={clonarMutationResult.mutate}
      style={secondaryButtonStyle}
    />
  )
}

const DeleteButton = (context: ColocacionViewContext) => {
  return (
    <BymaButton
      type='button'
      id='roles-form-btn'
      name='roles-form-btn'
      label={'Eliminar'}
      onClick={context.setShowConfirm}
      iconLeft={DeleteIcon}
      style={secondaryButtonStyle}
    />
  )
}

const SaveButton = () => (
  <BymaButton
    id='entity-confirm-btn'
    name='entity-confirm-btn'
    label={'Guardar'}
    type={'submit'}
    style={primaryStyleButton}
  />
)

const ColocacionMainSectionFields = (context: ColocacionViewContext) => {
  return (
    <BymaCard
      titleBorder='none'
      footer={
        context.readOnly ? null : context.editEnabled ? (
          <SaveButton />
        ) : (
          <DeleteButton {...context} />
        )
      }
    >
      {!context.readOnly && !context.editEnabled && (
        <Row sm={12} className='mb-3'>
          <Col sm={12} style={{ display: 'flex', justifyContent: 'flex-end', gap: '2%' }}>
            <>
              <EditButton {...context} />
              <ClonarButton {...context} />
            </>
          </Col>
        </Row>
      )}

      <div>
        {!context.editEnabled && (
          <Row>
            <LocalErrorAlert />
          </Row>
        )}

        <Row className='mb-3 border-bottom'>
          <Col>{generalStepFields(context)}</Col>
        </Row>

        <Row className='mb-3 border-bottom'>
          <Col>{fechaHorariosStepFields(context)}</Col>
        </Row>

        <Row>
          <Col>{complementariosStepFields(context)}</Col>
        </Row>

        {context.editEnabled && (
          <Row className='mt-3'>
            <LocalErrorAlert />
          </Row>
        )}
      </div>
    </BymaCard>
  )
}

const ConfirmDeleteModal = ({ showConfirm, setShowConfirm, handleConfirm }) => {
  return (
    <BymaModal
      id={'roles-edit-confirm-modal'}
      show={showConfirm}
      children={<p>Confirma eliminacion de la Colocación?</p>}
      handleCancel={() => {
        setShowConfirm(false)
      }}
      handleConfirm={() => {
        handleConfirm()
        setShowConfirm(false)
      }}
    />
  )
}

export const ColocacionMainSection = () => {
  const context = useColocacionEditContext()

  const { colocacionId } = context

  const validationSchema = useMemo(
    () =>
      yup.object({
        ...generalStepYupFields(yup),
        ...fechaHorariosStepYupFields(yup),
        ...complementariosStepYupFields(yup),
      }),
    [yup],
  )

  const getFormData = useCallback(() => {
    return ColocacionesApi.getOne(colocacionId).then((c) => {
      context.setColocacion(c)
      return c
    })
  }, [colocacionId])

  const mutation = useCallback((formData) => {
    return ColocacionesApi.update(formData).then((c) => {
      context.setColocacion(c)
      context.setEditEnabled(false)
      return c
    })
  }, [])

  //TODO usar hook para refrescar un detalle
  const queryClient = useQueryClient()

  const queryKey = `colocacion_edit:${colocacionId}`

  const editForm = useEditForm<any>({
    queryKey,
    getFormData: getFormData,
    mutation: mutation,
    schema: validationSchema,

    confirm: false,
    keepPreviousData: true,

    successNotification: 'Se actualizó la Colocación',
    errorNotification: 'Error actualizando Colocación',

    onSuccess: () => queryClient.invalidateQueries(queryKey),

    clearLocalErrorOnLoad: true,
  })

  //eliminacion
  const navigate = useNavigate()
  const onDeleteSuccess = useCallback(() => {
    navigate(routes.dashboard)
  }, [navigate])

  const deleteMutationResult = useBymaMutation({
    mutation: () => ColocacionesApi.delete(context.colocacionId),
    successNotification: 'Se eliminó la colocación exitosamente',
    onSuccess: () => onDeleteSuccess(),
  })

  return (
    <ColocacionSectionCard
      title={context.editEnabled ? 'Editar Colocación' : 'Detalle Colocación'}
      showFooter={false}
      icon={EditarColocacionIcon}
      titleExtrasRight={<ColocacionActionButton {...context} />}
    >
      <FormProvider {...editForm.form}>
        <Form id='colocacion-edit-main' onSubmit={editForm.onSubmit} style={{ padding: 0 }}>
          <ColocacionMainSectionFields {...context} />
        </Form>
      </FormProvider>

      <ConfirmDeleteModal {...context} handleConfirm={deleteMutationResult.mutate} />
    </ColocacionSectionCard>
  )
}

import { parseISO } from 'date-fns'

export const isoDateFormatPattern =
  /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d*)?(?:[-+]\d{2}:?\d{2}|Z)?$/

function isIsoDateString(value: any): boolean {
  return value && typeof value === 'string' && isoDateFormatPattern.test(value)
}

export function toDate(value: any) {
  return isIsoDateString(value)
    ? parseISO(value)
    : value instanceof Date
    ? (value as Date)
    : undefined
}

export const libroOfertasColocacionResponse = [
  {
    id: 52155,
    colocacionId: 24517,
    tituloDenominacion: 'Col Abierta',
    cantidad: 30256369,
    valor: 1000,
    integracionTituloDenominacion: 'ARS $',
    porcentajeInversion: 10,
    estadoDetail: 'VIGENTE',
    cuenta: 'FCICNV',
    numeroCliente: 20,
    timestampModificacion: '2023-04-30T13:15:06Z',
    tipoValor: 'PRECIO',
    colocacionEstado: 'ABIERTA',
    // Campos nuevos traidos de OfertaDTO del back!!!!
    fechaAlta: '2023-04-30T13:15:06Z',
    usuarioAlta: 'sw',
    tituloId: 52145,
    colocacionDenominacion: 'test mock',
    tipo: 'test mock', //TipoOferta
    origenOferta: 'test mock', //OrigenOferta
    cantidadAdjudicada: 9999,
    estadoGroup: 'test mock', //EstadoOfertaGroup
    agenteId: 9999,
    agenteIdOrganizacion: 'Agente org id',
    agenteNombreOrganizacion: 'Agente name org',
    motivoBaja: 'test mock',
    deTerceros: true,
    observaciones: 'test mock',
    cuit: 'test mock',
    nombreCliente: 'test mock',
    tipoPersona: 'test mock', //TipoPersona
    integracionTituloId: 9999,
    timestampConfirmacion: 'test mock',
    usuarioConfirmacion: 'test mock',
    clienteId: 9999,
    clase: 'test mock', //ClaseOferta
    secuenciaMae: 'test mock',
    respuestaMae: 'test mock',
    mensajeMae: 'test mock',
    secuenciaSiopel: 'test mock',
    secuenciaSiopelOriginal: 'test mock',
    grupoEconomicoId: 9999,
    nacionalidadClienteId: 9999,
    categoriaClienteId: 9999,
    montoALiquidar: 9999,
    valorAdjudicado: 9999,
  },
  {
    id: 52154,
    colocacionId: 24517,
    tituloDenominacion: 'Col Cerrada',
    cantidad: 30256369,
    valor: 8.5,
    integracionTituloDenominacion: 'ARS $',
    porcentajeInversion: 7.5,
    estadoDetail: 'RECHAZADA',
    cuenta: 'SSN',
    numeroCliente: 32,
    timestampModificacion: '2023-04-30T14:25:19Z',
    cantidadAdjudicada: 80000,
    valorAdjudicado: 9.25,
    tipoValor: 'TASA',
    colocacionEstado: 'CERRADA',
    // Campos nuevos traidos de OfertaDTO del back!!!!
    fechaAlta: '2023-04-30T13:15:06Z',
    usuarioAlta: 'sw',
    tituloId: 52145,
    colocacionDenominacion: 'test mock',
    tipo: 'test mock', //TipoOferta
    origenOferta: 'test mock', //OrigenOferta
    estadoGroup: 'test mock', //EstadoOfertaGroup
    agenteId: 9999,
    agenteIdOrganizacion: 'Agente org id',
    agenteNombreOrganizacion: 'Agente name org',
    motivoBaja: 'test mock',
    deTerceros: true,
    observaciones: 'test mock',
    cuit: 'test mock',
    nombreCliente: 'test mock',
    tipoPersona: 'test mock', //TipoPersona
    integracionTituloId: 9999,
    timestampConfirmacion: 'test mock',
    usuarioConfirmacion: 'test mock',
    clienteId: 9999,
    clase: 'test mock', //ClaseOferta
    secuenciaMae: 'test mock',
    respuestaMae: 'test mock',
    mensajeMae: 'test mock',
    secuenciaSiopel: 'test mock',
    secuenciaSiopelOriginal: 'test mock',
    grupoEconomicoId: 9999,
    nacionalidadClienteId: 9999,
    categoriaClienteId: 9999,
    montoALiquidar: 9999,
  },
  {
    id: 625123,
    colocacionId: 24517,
    tituloDenominacion: 'Col Adjudicada',
    cantidad: 30256369,
    valor: 5621,
    integracionTituloDenominacion: 'ARS $',
    porcentajeInversion: 10,
    estadoDetail: 'ADJUDICADA_PARCIAL',
    cuenta: 'CUIT',
    numeroCliente: '30.256.369',
    timestampModificacion: '2023-04-30T14:16:37Z',
    colocacionEstado: 'ADJUDICADA',
    // Campos nuevos traidos de OfertaDTO del back!!!!
    fechaAlta: '2023-04-30T13:15:06Z',
    usuarioAlta: 'sw',
    tituloId: 52145,
    colocacionDenominacion: 'test mock',
    tipo: 'test mock', //TipoOferta
    origenOferta: 'test mock', //OrigenOferta
    cantidadAdjudicada: 9999,
    estadoGroup: 'test mock', //EstadoOfertaGroup
    agenteId: 9999,
    agenteIdOrganizacion: 'Agente org id',
    agenteNombreOrganizacion: 'Agente name org',
    motivoBaja: 'test mock',
    deTerceros: true,
    observaciones: 'test mock',
    cuit: 'test mock',
    nombreCliente: 'test mock',
    tipoPersona: 'test mock', //TipoPersona
    integracionTituloId: 9999,
    timestampConfirmacion: 'test mock',
    usuarioConfirmacion: 'test mock',
    clienteId: 9999,
    clase: 'test mock', //ClaseOferta
    secuenciaMae: 'test mock',
    respuestaMae: 'test mock',
    mensajeMae: 'test mock',
    secuenciaSiopel: 'test mock',
    secuenciaSiopelOriginal: 'test mock',
    grupoEconomicoId: 9999,
    nacionalidadClienteId: 9999,
    categoriaClienteId: 9999,
    montoALiquidar: 9999,
    valorAdjudicado: 9999,
  },
  {
    id: 625124,
    colocacionId: 24517,
    tituloDenominacion: 'Col PreAdjudicada',
    cantidad: 30256369,
    valor: 3000,
    integracionTituloDenominacion: 'ARS $',
    porcentajeInversion: 2.15,
    estadoDetail: 'VIGENTE',
    cuenta: 'CUIT',
    numeroCliente: '30.256.369',
    timestampModificacion: '2023-04-30T14:16:37Z',
    colocacionEstado: 'PREADJUDICADA',
    // Campos nuevos traidos de OfertaDTO del back!!!!
    fechaAlta: '2023-04-30T13:15:06Z',
    usuarioAlta: 'sw',
    tituloId: 52145,
    colocacionDenominacion: 'test mock',
    tipo: 'test mock', //TipoOferta
    origenOferta: 'test mock', //OrigenOferta
    cantidadAdjudicada: 9999,
    estadoGroup: 'test mock', //EstadoOfertaGroup
    agenteId: 9999,
    agenteIdOrganizacion: 'Agente org id',
    agenteNombreOrganizacion: 'Agente name org',
    motivoBaja: 'test mock',
    deTerceros: true,
    observaciones: 'test mock',
    cuit: 'test mock',
    nombreCliente: 'test mock',
    tipoPersona: 'test mock', //TipoPersona
    integracionTituloId: 9999,
    timestampConfirmacion: 'test mock',
    usuarioConfirmacion: 'test mock',
    clienteId: 9999,
    clase: 'test mock', //ClaseOferta
    secuenciaMae: 'test mock',
    respuestaMae: 'test mock',
    mensajeMae: 'test mock',
    secuenciaSiopel: 'test mock',
    secuenciaSiopelOriginal: 'test mock',
    grupoEconomicoId: 9999,
    nacionalidadClienteId: 9999,
    categoriaClienteId: 9999,
    montoALiquidar: 9999,
    valorAdjudicado: 9999,
  },
  {
    id: 325147,
    colocacionId: 24517,
    tituloDenominacion: 'Col Suspendida',
    cantidad: 30256369,
    valor: 9521,
    integracionTituloDenominacion: 'ARS $',
    porcentajeInversion: 10,
    estadoDetail: 'PENDIENTE',
    cuenta: 'LEI',
    numeroCliente: '30.256.369',
    timestampModificacion: '2023-04-30T14:16:37Z',
    colocacionEstado: 'SUSPENDIDA',
    // Campos nuevos traidos de OfertaDTO del back!!!!
    fechaAlta: '2023-04-30T13:15:06Z',
    usuarioAlta: 'sw',
    tituloId: 52145,
    colocacionDenominacion: 'test mock',
    tipo: 'test mock', //TipoOferta
    origenOferta: 'test mock', //OrigenOferta
    cantidadAdjudicada: 9999,
    estadoGroup: 'test mock', //EstadoOfertaGroup
    agenteId: 9999,
    agenteIdOrganizacion: 'Agente org id',
    agenteNombreOrganizacion: 'Agente name org',
    motivoBaja: 'test mock',
    deTerceros: true,
    observaciones: 'test mock',
    cuit: 'test mock',
    nombreCliente: 'test mock',
    tipoPersona: 'test mock', //TipoPersona
    integracionTituloId: 9999,
    timestampConfirmacion: 'test mock',
    usuarioConfirmacion: 'test mock',
    clienteId: 9999,
    clase: 'test mock', //ClaseOferta
    secuenciaMae: 'test mock',
    respuestaMae: 'test mock',
    mensajeMae: 'test mock',
    secuenciaSiopel: 'test mock',
    secuenciaSiopelOriginal: 'test mock',
    grupoEconomicoId: 9999,
    nacionalidadClienteId: 9999,
    categoriaClienteId: 9999,
    montoALiquidar: 9999,
    valorAdjudicado: 9999,
  },
  {
    id: 52146,
    colocacionId: 24517,
    tituloDenominacion: 'Col Cancelada',
    cantidad: 30256369,
    cantidadAdjudicada: 1240000,
    valor: 235,
    valorAdjudicado: 240,
    integracionTituloDenominacion: 'ARS $',
    porcentajeInversion: 10,
    estadoDetail: 'ADJUDICADA',
    cuenta: 'SSN',
    numeroCliente: 8,
    timestampModificacion: '2023-04-30T14:16:37Z',
    colocacionEstado: 'CANCELADA',
    // Campos nuevos traidos de OfertaDTO del back!!!!
    fechaAlta: '2023-04-30T13:15:06Z',
    usuarioAlta: 'sw',
    tituloId: 52145,
    colocacionDenominacion: 'test mock',
    tipo: 'test mock', //TipoOferta
    origenOferta: 'test mock', //OrigenOferta
    estadoGroup: 'test mock', //EstadoOfertaGroup
    agenteId: 9999,
    agenteIdOrganizacion: 'Agente org id',
    agenteNombreOrganizacion: 'Agente name org',
    motivoBaja: 'test mock',
    deTerceros: true,
    observaciones: 'test mock',
    cuit: 'test mock',
    nombreCliente: 'test mock',
    tipoPersona: 'test mock', //TipoPersona
    integracionTituloId: 9999,
    timestampConfirmacion: 'test mock',
    usuarioConfirmacion: 'test mock',
    clienteId: 9999,
    clase: 'test mock', //ClaseOferta
    secuenciaMae: 'test mock',
    respuestaMae: 'test mock',
    mensajeMae: 'test mock',
    secuenciaSiopel: 'test mock',
    secuenciaSiopelOriginal: 'test mock',
    grupoEconomicoId: 9999,
    nacionalidadClienteId: 9999,
    categoriaClienteId: 9999,
    montoALiquidar: 9999,
  },
  {
    id: 62511,
    colocacionId: 24517,
    tituloDenominacion: 'Col Desierta',
    cantidad: 30256369,
    valor: 8521,
    integracionTituloDenominacion: 'ARS $',
    porcentajeInversion: 10,
    estadoDetail: 'ADJUDICADA_PARCIAL',
    cuenta: 'SSN',
    numeroCliente: 27,
    timestampModificacion: '2023-04-30T14:16:37Z',
    colocacionEstado: 'DESIERTA',
    // Campos nuevos traidos de OfertaDTO del back!!!!
    fechaAlta: '2023-04-30T13:15:06Z',
    usuarioAlta: 'sw',
    tituloId: 52145,
    colocacionDenominacion: 'test mock',
    tipo: 'test mock', //TipoOferta
    origenOferta: 'test mock', //OrigenOferta
    cantidadAdjudicada: 9999,
    estadoGroup: 'test mock', //EstadoOfertaGroup
    agenteId: 9999,
    agenteIdOrganizacion: 'Agente org id',
    agenteNombreOrganizacion: 'Agente name org',
    motivoBaja: 'test mock',
    deTerceros: true,
    observaciones: 'test mock',
    cuit: 'test mock',
    nombreCliente: 'test mock',
    tipoPersona: 'test mock', //TipoPersona
    integracionTituloId: 9999,
    timestampConfirmacion: 'test mock',
    usuarioConfirmacion: 'test mock',
    clienteId: 9999,
    clase: 'test mock', //ClaseOferta
    secuenciaMae: 'test mock',
    respuestaMae: 'test mock',
    mensajeMae: 'test mock',
    secuenciaSiopel: 'test mock',
    secuenciaSiopelOriginal: 'test mock',
    grupoEconomicoId: 9999,
    nacionalidadClienteId: 9999,
    categoriaClienteId: 9999,
    montoALiquidar: 9999,
    valorAdjudicado: 9999,
  },
  {
    id: 98412,
    colocacionId: 24517,
    tituloDenominacion: 'Col Ingresada',
    cantidad: 30256369,
    valor: 5214,
    integracionTituloDenominacion: 'ARS $',
    porcentajeInversion: 10,
    estadoDetail: 'PENDIENTE',
    cuenta: 'SSN',
    numeroCliente: 52,
    timestampModificacion: '2023-04-30T14:16:37Z',
    colocacionEstado: 'INGRESADA',
    // Campos nuevos traidos de OfertaDTO del back!!!!
    fechaAlta: '2023-04-30T13:15:06Z',
    usuarioAlta: 'sw',
    tituloId: 52145,
    colocacionDenominacion: 'test mock',
    tipo: 'test mock', //TipoOferta
    origenOferta: 'test mock', //OrigenOferta
    cantidadAdjudicada: 9999,
    estadoGroup: 'test mock', //EstadoOfertaGroup
    agenteId: 9999,
    agenteIdOrganizacion: 'Agente org id',
    agenteNombreOrganizacion: 'Agente name org',
    motivoBaja: 'test mock',
    deTerceros: true,
    observaciones: 'test mock',
    cuit: 'test mock',
    nombreCliente: 'test mock',
    tipoPersona: 'test mock', //TipoPersona
    integracionTituloId: 9999,
    timestampConfirmacion: 'test mock',
    usuarioConfirmacion: 'test mock',
    clienteId: 9999,
    clase: 'test mock', //ClaseOferta
    secuenciaMae: 'test mock',
    respuestaMae: 'test mock',
    mensajeMae: 'test mock',
    secuenciaSiopel: 'test mock',
    secuenciaSiopelOriginal: 'test mock',
    grupoEconomicoId: 9999,
    nacionalidadClienteId: 9999,
    categoriaClienteId: 9999,
    montoALiquidar: 9999,
    valorAdjudicado: 9999,
  },
  {
    id: 98412,
    colocacionId: 24517,
    tituloDenominacion: 'Col PreIngresada',
    cantidad: 30256369,
    valor: 5214,
    integracionTituloDenominacion: 'ARS $',
    porcentajeInversion: 10,
    estadoDetail: 'PENDIENTE',
    cuenta: 'SSN',
    numeroCliente: 52,
    timestampModificacion: '2023-04-30T14:16:37Z',
    colocacionEstado: 'PREINGRESADA',
    // Campos nuevos traidos de OfertaDTO del back!!!!
    fechaAlta: '2023-04-30T13:15:06Z',
    usuarioAlta: 'sw',
    tituloId: 52145,
    colocacionDenominacion: 'test mock',
    tipo: 'test mock', //TipoOferta
    origenOferta: 'test mock', //OrigenOferta
    cantidadAdjudicada: 9999,
    estadoGroup: 'test mock', //EstadoOfertaGroup
    agenteId: 9999,
    agenteIdOrganizacion: 'Agente org id',
    agenteNombreOrganizacion: 'Agente name org',
    motivoBaja: 'test mock',
    deTerceros: true,
    observaciones: 'test mock',
    cuit: 'test mock',
    nombreCliente: 'test mock',
    tipoPersona: 'test mock', //TipoPersona
    integracionTituloId: 9999,
    timestampConfirmacion: 'test mock',
    usuarioConfirmacion: 'test mock',
    clienteId: 9999,
    clase: 'test mock', //ClaseOferta
    secuenciaMae: 'test mock',
    respuestaMae: 'test mock',
    mensajeMae: 'test mock',
    secuenciaSiopel: 'test mock',
    secuenciaSiopelOriginal: 'test mock',
    grupoEconomicoId: 9999,
    nacionalidadClienteId: 9999,
    categoriaClienteId: 9999,
    montoALiquidar: 9999,
    valorAdjudicado: 9999,
  },
]

export const getOfertaFromLibroColocacionResponse = (id: number) => {
  const ofertas = libroOfertasColocacionResponse.filter((col) => col.id == id)
  return ofertas !== undefined && ofertas.length > 0 ? ofertas[0] : {}
}

import React from 'react'
import { Button } from 'react-bootstrap'
import styles from './BymaDatePicker.module.scss'

const BymaDatePickerCustomHeader = (props) => {
    const {
        date,
        decreaseMonth,
        increaseMonth,
        prevMonthButtonDisabled,
        nextMonthButtonDisabled,
        prevYearButtonDisabled,
        nextYearButtonDisabled,
        increaseYear,
        decreaseYear
    } = props

    //console.log('Rendeirng custom header')
    //console.log(props)

    const months = [
        "Ene",
        "Feb",
        "Mar",
        "Abr",
        "May",
        "Jun",
        "Jul",
        "Ago",
        "Sep",
        "Oct",
        "Nov",
        "Dic"
    ];

    return (
        <div className={styles.bymaDatePickerCustomHeader} >
            <div className={styles.bymaDatePickerLeftButtons} >
                <Button
                    id={'prev-year-button'}
                    className={styles.bymaDatePickerButon}
                    onClick={decreaseYear}
                    disabled={prevYearButtonDisabled}>
                    {"<<"}
                </Button>
                <Button
                    id={'prev-month-button'}
                    className={styles.bymaDatePickerButon}
                    onClick={decreaseMonth}
                    disabled={prevMonthButtonDisabled}>
                    {"<"}
                </Button>
            </div>
            <span>{months[date.getMonth()]} {date.getFullYear()}</span>
            <div className={styles.bymaDatePickerRightButtons} >
                <Button
                    id={'next-month-button'}
                    className={styles.bymaDatePickerButon}
                    onClick={increaseMonth}
                    disabled={nextMonthButtonDisabled}>
                    {">"}
                </Button>
                <Button
                    id={'next-year-button'}
                    className={styles.bymaDatePickerButon}
                    onClick={increaseYear}
                    disabled={nextYearButtonDisabled}>
                    {">>"}
                </Button>

            </div>
        </div>
    )
}

export default BymaDatePickerCustomHeader
import React, { useCallback, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'

import { Row } from 'react-bootstrap'

import {
  useEditQuery,
  useOptionsQuery,
  UseListOptionsParams,
  useBymaMutation,
  LocalErrorProvider,
} from 'core'

import {
  BymaDualListBox,
  LocalErrorAlert,
  DualListBoxProps,
  BymaCard,
  BymaButton,
} from 'components'

import { AutorizacionColocacion, COLOCACION_ENTITY_NAME } from 'model'

import { ColocacionEditProps } from '../../ColocacionViewContext'
import { ColocacionSectionCard } from '../ColocacionSectionCard'

import AutorizacionIcon from 'assets/img/colocacion-view/autorizacion.svg'
import { ColocacionSummary } from 'features/Colocacion/ColocacionSummary'
export interface AutorizacionSectionFieldsProps<D> extends ColocacionEditProps {
  cardTitle: string
  section: string
  dataProvider: (id: Identifier) => Promise<AutorizacionColocacion[]>
  optionsParams: UseListOptionsParams<D>
  dualListProps: Omit<DualListBoxProps, 'name' | 'id' | 'selectedOptions' | 'options'>
}

export const AutorizacionSectionFieldName = 'autorizaciones'

const AutorizacionSectionFieldsContent = <D = DenominableEntity,>(
  props: AutorizacionSectionFieldsProps<D>,
) => {
  const { colocacionId, dataProvider, optionsParams, section, dualListProps, cardTitle, readOnly } =
    props

  const queryKey = [COLOCACION_ENTITY_NAME, colocacionId, section]

  //query para obtener las autorizaciones asigandas
  const actualDataProvider = useCallback(() => dataProvider(colocacionId), [colocacionId])

  const editQuery = useEditQuery<AutorizacionColocacion[]>({
    entityKey: COLOCACION_ENTITY_NAME,
    id: colocacionId,
    queryKey,
    dataProvider: actualDataProvider,
  })

  const autorizacionesSelected = editQuery?.data || []

  //query para obtener todas las opciones posibles
  const autorizacionOptionsQuery = useOptionsQuery(optionsParams)

  const autorizacionesOptions = autorizacionOptionsQuery?.data || []

  /*
  console.log(
    `DEBUG AutorizacionSectionFields ${section} autorizacionesOptions=${JSON.stringify(
      autorizacionesOptions,
    )}`,
  )
  */

  const SaveButton = () => (
    <BymaButton
      id='entity-confirm-btn'
      name='entity-confirm-btn'
      label={'Guardar'}
      type={'submit'}
      style={{
        padding: '10.5px 16px',
        fontFamily: 'var(--font-family-regular)',
        fontSize: '14px',
        lineHeight: '15px',
      }}
    />
  )

  return (
    <ColocacionSectionCard title={cardTitle} icon={AutorizacionIcon}>
      <Row className='mb-3'>
        <ColocacionSummary {...props} />
      </Row>
      <Row>
        <BymaCard titleBorder='none' footer={<>{readOnly ? null : <SaveButton />}</>}>
          <Row className='mb-3'>
            <BymaDualListBox
              id={AutorizacionSectionFieldName}
              name={AutorizacionSectionFieldName}
              selectedOptions={autorizacionesSelected}
              options={autorizacionesOptions}
              {...dualListProps}
              canFilter={true}
              readOnly={readOnly}
              shouldDirty={!readOnly}
              shouldValidate={false}
              style={{ height: '500px' }}
              showMoveAllButtons={true}
            />
          </Row>
          <Row className='mt-2'>
            <LocalErrorAlert />
          </Row>
        </BymaCard>
      </Row>
    </ColocacionSectionCard>
  )
}

export const AutorizacionSectionFields = <D = DenominableEntity,>(
  props: AutorizacionSectionFieldsProps<D>,
) => {
  return (
    <LocalErrorProvider>
      <AutorizacionSectionFieldsContent {...props} />
    </LocalErrorProvider>
  )
}

export interface AutorizacionSectionFormProps extends ColocacionEditProps {
  successNotification: string
  errorNotification: string
  mutation: (id: Identifier, data: AutorizacionColocacion[]) => Promise<unknown>
  schemaProvider?: (yup) => any
}

export const useAutorizacionSectionForm = (props: AutorizacionSectionFormProps) => {
  const { colocacionId, schemaProvider = (yup) => yup.object().shape({}) } = props

  //validaciones
  const validationSchema = useMemo(() => schemaProvider(yup), [yup, schemaProvider])

  const updateAutorizaciones = useCallback(
    (formData) => {
      const autorizaciones = formData[AutorizacionSectionFieldName]
      return props.mutation(colocacionId, autorizaciones)
    },
    [colocacionId],
  )

  const { mutate } = useBymaMutation({
    mutation: updateAutorizaciones,
    successNotification: props.successNotification,
    errorNotification: props.errorNotification,
  })

  const onSubmit = (data: any) => {
    mutate(data)
  }

  const useFormReturn = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    shouldUnregister: false,
    defaultValues: { [AutorizacionSectionFieldName]: [] },
  })

  return {
    useFormReturn,
    onSubmit,
  }
}

import React, { CSSProperties, JSXElementConstructor, ReactElement, ReactNode, useState } from 'react'
import { Accordion, useAccordionButton } from 'react-bootstrap'
import { ChevronDown } from 'react-bootstrap-icons'

import styles from './BymaSingleAccordion.module.scss'

interface SingleAccordionProps {
    id: string
    key?: string
    children: ReactElement<any, string | JSXElementConstructor<any>> & ReactNode
    style?: CSSProperties | undefined
    title?: string
}

const CustomToggle = ({ eventKey }) => {
    const [rotateChevron, setRotateChevron] = useState(false)
    const decoratedOnClick = useAccordionButton(eventKey, () => setRotateChevron(!rotateChevron))
    const rotate = rotateChevron ? 'rotate(180deg)' : 'rotate(0)'
    return (
        <button type='button' className={styles.singleAccordionButton} onClick={decoratedOnClick}>
            <ChevronDown
                style={{ transform: rotate, transition: 'all 0.2s linear' }}
                color='#ffffff'
                size={24}
            />
        </button>
    )
}

const BymaSingleAccordion = (props: SingleAccordionProps) => {
    const {
        id,
        key = id,
        style = {},
        title,
        children
    } = props

    return (
        <Accordion
            key={key}
            style={style}
            className={styles.singleAccordionContainer}>
            <Accordion.Item eventKey='0' className={styles.singleAccordionItem}>
                <div className={styles.singleAccordionHeader}>
                    <span className={styles.accordionHeaderTitle}>{title !== undefined ? title : ''}</span>
                    <CustomToggle eventKey='0' />
                </div>
                <Accordion.Collapse eventKey='0' className={styles.singleAccordionBody}>
                    {children}
                </Accordion.Collapse>
            </Accordion.Item>
        </Accordion>
    )

}

export default BymaSingleAccordion
import React, { useState } from 'react'

import { useForm, FormProvider } from 'react-hook-form'
import { mapDenominableToOption } from 'model'

import {
  BymaCard,
  BymaDualListBox,
  BymaPageFrame,
  BymaHelpBox,
  BymaExportButton,
  BymaButton,
  BymaSelect,
  BymaLoadingOverlay,
  BymaModal,
  Loading,
} from 'components'

import RolesApi from './RolesApi'
import PermisosApi from './PermisosApi'

import * as yup from 'yup'

import { Col, Form, Row } from 'react-bootstrap'

import { yupResolver } from '@hookform/resolvers/yup'
import { useQueries, useQuery } from 'react-query'

import { useNavigate } from 'react-router-dom'
import { Rol, Permiso } from './Model'

import {
  useAuthContext,
  userHasAuthority,
  useNotificationProducer,
  useBymaMutation,
  LoadingProvider,
} from 'core'

import './Roles.scss'
import BymaOverlay from 'components/BymaOverlay/BymaOverlay'

const RolEdit = () => {
  //console.log('DEBUG render RoleEdit')

  const { userInfo } = useAuthContext()

  const readOnly = !userHasAuthority(userInfo, 'rol:write')

  const navigate = useNavigate()
  const [roles, setRoles] = useState<Rol[]>([])
  const [selectedRolId, setSelectedRolId] = useState<Identifier | undefined>(undefined)
  const [rol, setRol] = useState<Rol | undefined>(undefined)
  const [permisos, setPermisos] = useState<Permiso[]>([])

  const [showConfirm, setShowConfirm] = useState<boolean>(false)
  const [formData, setFormData] = useState<any>(undefined)

  const queryResults = useQueries([
    {
      queryKey: ['roles'],

      queryFn: async () => {
        return RolesApi.getList()
      },
      onSuccess: (data: Rol[]) => {
        setRoles(data)
      },
      onError: (error) => {
        console.log(`ERROR obteniendo roles: ${JSON.stringify(error)}`)
        setRoles([])
      },

      refetchOnWindowFocus: false,
    },

    {
      queryKey: ['permisos'],

      queryFn: async () => {
        console.log('DEBUG RolesEdit en query[permisos]')
        return PermisosApi.getList()
      },

      onSuccess: (data: Permiso[]) => {
        setPermisos(data)
      },
      onError: (error) => {
        console.log(`ERROR obteniendo permisos: ${JSON.stringify(error)}`)
        setPermisos([])
      },

      refetchOnWindowFocus: false,
    },
  ])

  const isLoadingData = queryResults.some((query) => query.isLoading)
  const isFetchingData = queryResults.some((query) => query.isFetching)
  const isErrorData = queryResults.some((query) => query.isError)
  const errorData = queryResults.some((query) => query.error)

  //TODO JIRA BP-52 Usar un nuevo hook useDetailQuery que incluya manejo notification y progress
  const { isLoading: isLoadingRol, isFetching: isFetchingRol = false } = useQuery(
    ['rol', selectedRolId],
    () => {
      //console.log(`DEBUG RolesEdit en queryRol[${selectedRolId}]`);
      return RolesApi.getOne(selectedRolId)
    },
    {
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        setRol(data)
      },
      enabled: rol !== selectedRolId,
    },
  )

  const { addNotification } = useNotificationProducer()

  const { mutate } = useBymaMutation({
    mutation: (rol: Rol) => RolesApi.update(rol),
    successNotification: 'Se actualizo el Rol',
    errorNotification: 'Error actualizando Rol',
  })

  const handleCancel = () => {
    //TODO a donde lleva el 'Cancelar' ?
    navigate('/roles')
  }

  const permisosOptions = permisos && rol ? permisos.map(mapDenominableToOption) : []

  const permisosSelected = rol ? rol.permisos.map((permiso) => permiso.id) : []

  console.log(`DEBUG RolesEdit PermisosSelected=${JSON.stringify(permisosSelected)}`)

  //FORM
  const schema = () =>
    yup.object().shape({
      id: yup.number().required(),
      permisos: yup
        .array()
        .min(1, 'Informar al menos un permiso')
        .required('Informar al menos un permiso'),
    })

  const methods = useForm({
    resolver: yupResolver(schema()),
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    shouldUnregister: false,
    defaultValues: { id: undefined, permisos: [] },
  })

  //console.log(`DEBUG RolesEdit formstate.isValid = ${methods.formState.isValid} formState.isLoading=${methods.formState.isLoading}`)
  //console.log(`DEBUG RolesEdit formstate.errors = ${JSON.stringify(methods.formState.errors)}`)

  React.useEffect(() => {
    methods.setFocus('id')
  }, [methods.setFocus])

  const handleConfirm = () => {
    console.log(`DEBUG RolesEdit onSubmit(${JSON.stringify(formData)})`)

    const dto = {
      id: formData.id,
      permisos: formData.permisos.map((p) => ({ id: p })),
    }

    //TODO REVISAR ESTO: en este tipo de formulario tenemos que actualizar el objeto subyacente para que no 'resetee'
    setRol({ id: selectedRolId || 0, permisos: dto.permisos })

    mutate(dto)
  }

  const submitDisabled = false /*Object.keys(methods.formState.errors).length !== 0;*/

  const onSubmit = (data: any) => {
    setFormData(data)
    setShowConfirm(true)
  }

  const editRolForm = () => {
    return (
      <Loading>
        <FormProvider {...methods}>
          <Form
            id='roles-edit-form'
            className='roles-edit-form'
            onSubmit={methods.handleSubmit(onSubmit)}
          >
            <BymaCard
              style={{
                width: '100%',
              }}
              footer={
                <>
                  <BymaButton
                    type='button'
                    id='roles-form-btn'
                    name='roles-form-btn'
                    label={'Cancelar'}
                    onClick={handleCancel}
                    style={{
                      backgroundColor: 'transparent',
                      border: '1px solid #626573',
                      marginTop: 5,
                      marginLeft: 20,
                    }}
                  />
                  {!readOnly && (
                    <BymaButton
                      type='submit'
                      id='roles-form-btn'
                      name='roles-form-btn'
                      label={'Guardar'}
                      disabled={submitDisabled}
                      style={{
                        backgroundColor: '#626573',
                        border: '1px solid #626573',
                        marginTop: 5,
                        marginLeft: 20,
                      }}
                    />
                  )}
                </>
              }
            >
              <Row>
                <Col style={{ paddingRight: 0 }}>
                  <BymaSelect
                    id='id'
                    name='id'
                    label='Roles'
                    className='form-input'
                    multiselect={false}
                    menuIsOpen={false}
                    onChange={(selected) => {
                      setSelectedRolId(selected.value)
                    }}
                    options={roles.map(mapDenominableToOption)}
                  />
                </Col>
                <div className='roles-actions' />
                <Col>
                  <div className='export-buttom-col'>
                    <BymaOverlay text={'Descargar Excel'} placement='top'>
                      <div>
                        <BymaExportButton
                          id='roles-export-button'
                          name='roles-export-button'
                          serverDownload={() => RolesApi.export()}
                          getFileName={() => 'roles.csv'}
                          postDownloading={() =>
                            addNotification({
                              message: 'Se descargo archivo de Roles',
                              type: 'info',
                            })
                          }
                        />
                      </div>
                    </BymaOverlay>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  {
                    <BymaLoadingOverlay isLoading={isFetchingRol || isLoadingRol} loadingText=''>
                      <BymaDualListBox
                        id='permisos'
                        name='permisos'
                        labelAvailable='Permisos'
                        labelSelected='Permisos'
                        options={permisosOptions}
                        selectedOptions={permisosSelected}
                        canFilter={true}
                        readOnly={readOnly}
                        shouldDirty={!readOnly}
                        shouldValidate={false /*!readOnly*/}
                        style={{ height: '90%' }}
                        labels={{
                          availableFilterHeader: 'Buscar',
                          selectedFilterHeader: 'Buscar',
                          availableHeader: 'PERMISOS',
                          selectedHeader: 'PERMISOS CONCEDIDOS',
                          moveLeft: '< Quitar',
                          moveRight: 'Agregar >',
                          moveLeftTooltip: 'Quitar permiso',
                          moveRightTooltip: 'Agregar permiso',
                        }}
                      />
                    </BymaLoadingOverlay>
                  }
                </Col>
              </Row>
            </BymaCard>

            <BymaModal
              id={'roles-edit-confirm-modal'}
              show={showConfirm}
              children={<p>Confirma actualización del Rol?</p>}
              handleCancel={() => {
                setShowConfirm(false)
              }}
              handleConfirm={() => {
                handleConfirm()
                setShowConfirm(false)
              }}
            />
          </Form>
        </FormProvider>
      </Loading>
    )
  }

  return (
    <BymaPageFrame
      bcHome={{ value: 'Dashboard', link: '/home' }}
      bcItems={[]}
      bcActive='Roles'
      title='ADMINISTRAR ROLES'
      iconTitle='widgets.svg'
      className='roles-edit-container'
    >
      <BymaLoadingOverlay
        isLoading={isLoadingData || isFetchingData}
        loadingText='Cargando datos...'
      >
        <div>
          {isErrorData ? (
            <span>{`Error: ${errorData}`}</span>
          ) : (
            <div>
              <Row className={'first-row'}>
                <Col className='col-3'>
                  <BymaHelpBox
                    title='Roles y permisos'
                    children={
                      <p>
                        Seleccioná sobre que rol vas a modificar.
                        <br /> Una vez seleccionado mueve de izquierda hacia derecha que permisos
                        quieres otorgar a este rol. Y de derecha a izquierda para quitarle.
                      </p>
                    }
                  />
                </Col>
                <Col className='col-9'>{editRolForm()}</Col>
              </Row>
            </div>
          )}
        </div>
      </BymaLoadingOverlay>
    </BymaPageFrame>
  )
}

export default () => (
  <LoadingProvider>
    <RolEdit />
  </LoadingProvider>
)

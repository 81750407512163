import React, { useEffect, useState } from 'react'

import Colocaciones from './Colocaciones'
import {
  BymaTransientToast,
  BymaSortableList,
  BymaDropdownButton,
  BymaDropdownCheckItem,
  BymaCheckItem,
} from 'components'

import MisOfertasTable from './Oferta/MisOfertasTable'

import { useAuthContext, isComponentAuthorized } from 'core'
import * as BymaPermissions from 'services/auth/bymaPermissions'

import styles from './Dashboard.module.scss'
import { Container } from 'react-bootstrap'
import { SortableItemProps } from '@components/BymaSortableList/react-sortable-list'

const MIS_OFERTAS_ITEM: SortableItemProps = {
  id: 'misOfertas',
  title: 'Mis ofertas',
  content: (
    <div className={styles.dashboardItemContainer}>
      <MisOfertasTable />
    </div>
  ),
}

const COLOCACIONES_ITEM: SortableItemProps = {
  id: 'colocaciones',
  title: 'Colocaciones',
  content: (
    <div className={styles.dashboardItemContainer}>
      <Colocaciones />
    </div>
  ),
}

const getItems = (props: {
  showMisOfertas: boolean
  showColocaciones: boolean
  misOfertasEnabled: boolean
}): SortableItemProps[] => {
  const items: SortableItemProps[] = []

  if (props.misOfertasEnabled && props.showMisOfertas) {
    items.push(MIS_OFERTAS_ITEM)
  }

  if (props.showColocaciones) {
    items.push(COLOCACIONES_ITEM)
  }
  return items
}

const DashboardColocaciones = () => {
  const { userInfo } = useAuthContext()
  const misOfertasEnabled = !!isComponentAuthorized(
    BymaPermissions.LIST_OFERTAS_PROPIAS_PERMISSION,
    userInfo?.authorities,
  )

  const [items, setItems] = useState(
    getItems({
      showMisOfertas: true,
      showColocaciones: true,
      misOfertasEnabled: misOfertasEnabled,
    }),
  )

  const [showComponent, setShowComponent] = useState({
    misOfertas: true,
    colocaciones: true,
  })

  useEffect(() => {
    setItems(
      getItems({
        showColocaciones: showComponent.colocaciones,
        showMisOfertas: showComponent.misOfertas,
        misOfertasEnabled: misOfertasEnabled,
      }),
    )
  }, [showComponent])

  const WidgetsButton = () => {
    return (
      <BymaDropdownButton
        id='editar-widgets-btn'
        label='Editar widgets'
        name='editar-widgets'
        iconLeft='widgets.svg'
        className={styles.editarWidgetsBtn}
      >
        {[MIS_OFERTAS_ITEM, COLOCACIONES_ITEM].map((item) => {
          const defaultItem = item === COLOCACIONES_ITEM

          const checkItem: BymaCheckItem = {
            label: item.title,
            name: item.id,
            source: item.id,
          }

          const checked = defaultItem ? true : showComponent[item.id]

          return (
            <BymaDropdownCheckItem
              key={'filter-' + item.id}
              checkItem={checkItem}
              resource=''
              onShow={() => setShowComponent({ ...showComponent, [item.id]: true })}
              onHide={() => setShowComponent({ ...showComponent, [item.id]: false })}
              checked={checked}
              disabled={defaultItem}
            />
          )
        })}
      </BymaDropdownButton>
    )
  }

  return (
    <div className={styles.container}>
      <div className={styles.toastContainer}>
        <BymaTransientToast />
      </div>
      <Container fluid className={styles.editarWidgetsContainer}>
        {misOfertasEnabled ? <WidgetsButton /> : undefined}
      </Container>
      <div className={styles.dashboardContainer}>
        <BymaSortableList
          key={'byma-sortable-list'}
          items={items}
          setItems={setItems}
          disabled={!misOfertasEnabled}
        />
      </div>
    </div>
  )
}

export default DashboardColocaciones

import { useNavigate } from 'react-router-dom'
import { authenticationService } from '../../services/auth'
import useAuthContext from './useAuthContext'
import { useStoreContext } from 'services/store'

export default () => {
  const authContext: AuthContextInfo = useAuthContext()
  const navigate = useNavigate()

  const storeContext = useStoreContext()

  return () => {
    authenticationService.signout().then(() => {
      navigate('/login')
      authContext.setToken(undefined)
      authContext.setAuthenticated(false)
      authContext.setUserInfo(undefined)

      storeContext.teardown()
    })
  }
}

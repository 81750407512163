import React from 'react'

import {
  BymaButton,
  BymaCard,
  BymaDataTable,
  BymaExportButton,
  BymaPageFrame,
  BymaTextInputFilter,
  BymaSelect,
} from 'components'

import { ListBase, useListContext, useNotificationProducer, LoadingProvider } from 'core'

import { FiltersHeader } from 'features/common/Filters'

import { useEditButtonCell, useDeleteButtonCell } from 'features/common/Entity'

import styles from './GrupoEconomico.module.scss'
import { EntityContextInfo, useEntityContext } from 'features/common/Entity'

import {
  GRUPO_ECONOMICO_ENTITY_NAME,
  GrupoEconomicoApi,
  OrdenGrupoEconomicoOptions,
  OrdenGrupoEconomico,
} from 'model/grupoEconomico'

import GrupoEconomicoModal from './GrupoEconomicoModal'
import { SORT_FILTER_NAME } from 'services/api'

const getGrupoEconomicoColumns = (context: EntityContextInfo) => {
  return [
    {
      field: 'numero',
      headerName: 'Código Grupo Económico',
      textAlign: 'left',
      minWidth: 250,
    },
    {
      field: 'denominacion',
      headerName: 'Denominación Grupo Económico',
      textAlign: 'left',
      minWidth: 350,
    },
    {
      field: 'cuit',
      headerName: 'CUIT / CUIL',
      textAlign: 'left',
      width: 150,
    },
    {
      field: 'aliasSiopel',
      headerName: 'Código SIOPEL',
      textAlign: 'left',
      minWidth: 150,
    },

    useEditButtonCell({ context }),
    useDeleteButtonCell({ context }),
  ]
}

/**
 *
 * @returns TODO crear componente SbaList
 */
const GrupoEconomicoTable = () => {
  const { addNotification } = useNotificationProducer()

  const context = useEntityContext(GRUPO_ECONOMICO_ENTITY_NAME, 'Grupo Económico')

  const { filterValues } = useListContext()

  const handleAddGrupoEconomico = () => {
    context.setEntityId(undefined)
    context.setShowModal(true)
    context.setEditEnabled(true)
  }

  const dataProvider = (params) => GrupoEconomicoApi.getPaginatedList(params)

  const filters = [
    <BymaTextInputFilter
      name='numero'
      key='numero'
      label='Código Grupo Económico'
      type='text'
      alwaysOn={true}
      style={{ width: '200px' }}
      variant='Small'
    />,

    <BymaTextInputFilter
      name='denominacion'
      key='denominacion'
      label='Denominación Grupo Económico'
      type='text'
      alwaysOn={true}
      style={{ width: '300px' }}
      variant='Small'
    />,

    <BymaSelect
      name={SORT_FILTER_NAME}
      key={SORT_FILTER_NAME}
      label='Ordenar'
      style={{ minWidth: '250px' }}
      options={OrdenGrupoEconomicoOptions}
      alwaysOn={true}
      variant='Small'
      hideLabel={true}
    />,
  ]

  return (
    <BymaPageFrame
      bcHome={{ value: 'Administrar', link: '/home' }}
      bcItems={[]}
      bcActive='ABM Grupos Económicos'
      showTitle={false}
      showToast={true}
      className={styles.abmCGrupoEconomicoContainer}
    >
      <BymaCard key={'grupos-economicos-card'} header={{ title: 'Grupos Económicos' }}>
        <div style={{ paddingBottom: '1%', paddingTop: '2%' }}>
          <FiltersHeader
            filters={filters}
            showFiltersMenu={false}
            extra={[
              <BymaButton
                type='button'
                id='grupos-economicos-add-btn'
                key='grupos-economicos-add-btn'
                name='grupos-economicos-add-btn'
                label={'+ Nuevo Grupo Económico'}
                onClick={handleAddGrupoEconomico}
              />,
            ]}
            exportButton={
              <BymaExportButton
                id='grupos-economicos-export-button'
                name='grupos-economicos-export-button'
                serverDownload={() => GrupoEconomicoApi.export({ filter: filterValues })}
                getFileName={() => 'grupos-economicos.csv'}
                postDownloading={() =>
                  addNotification({
                    message: 'Se descargo archivo de Grupos Económicos',
                    type: 'info',
                  })
                }
              />
            }
          />
        </div>

        <BymaDataTable
          key='grupos-economicos'
          columns={getGrupoEconomicoColumns(context)}
          sizeColumnsToFit={true}
          style={{ height: '310px', width: '100%' }}
          queryProps={{
            dataProvider: dataProvider,
            listKey: context.listQueryKey,
          }}
          listPaginationProps={{
            showLimits: true,
          }}
          //suppressHorizontalScroll={true}
        />
      </BymaCard>

      <GrupoEconomicoModal
        context={context}
        api={GrupoEconomicoApi}
        navigateTo='/grupos-economicos'
        formId='colocador-form'
      />
    </BymaPageFrame>
  )
}

const GrupoEconomicoList = () => {
  return (
    <LoadingProvider>
      <ListBase
        listKey='grupos-economicos.hist'
        filterDefaultValues={{ [SORT_FILTER_NAME]: OrdenGrupoEconomico.DENOMINACION_ASC }}
      >
        <GrupoEconomicoTable />
      </ListBase>
    </LoadingProvider>
  )
}

export default GrupoEconomicoList

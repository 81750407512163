let idToken: string | undefined = undefined
let refreshToken: string | undefined = undefined

const setApiIdToken = (token: string | undefined) => {
  idToken = token
}

const setApiRefreshToken = (token: string | undefined) => {
  refreshToken = token
}

const getApiIdToken = () => {
  return idToken
}

export { setApiIdToken, setApiRefreshToken, getApiIdToken }

import React from 'react'
import { EntityModal } from 'features/common/Entity'

import { GrupoEconomico } from 'model/grupoEconomico'
import { EntityModalProps } from 'features/common/Entity'
import { GrupoEconomicoForm } from './GrupoEconomicoForm'

const GrupoEconomicoSchemaProperties = (yup) =>
  yup.object().shape({
    numero: yup.string().required(),
    denominacion: yup.string().required(),
    cuit: yup.string().required(),
  })

const GrupoEconomicoModal = (props: EntityModalProps<GrupoEconomico>) => {
  const editEnabled = props.context.editEnabled

  return (
    <EntityModal
      {...props}
      focusField='numero'
      getSchema={GrupoEconomicoSchemaProperties}
      renderCreateForm={(props) =>
        GrupoEconomicoForm({ ...props, cardTitle: 'Nuevo Grupo Económico', editEnabled: true })
      }
      renderEditForm={(props) =>
        GrupoEconomicoForm({
          ...props,
          cardTitle: editEnabled ? 'Editar Grupo Económico' : 'Detalle Grupo Económico',
          editEnabled,
        })
      }
    />
  )
}

export default GrupoEconomicoModal

import React, { useMemo, useReducer } from 'react'
import { LocalErrorApiContext, LocalErrorDataContext } from './LocalErrorContext'

let errorNumber = 1

const setError = (state: LocalErrorDataContextInfo, error: LocalErrorProps | undefined) => {
  return error === undefined
    ? { error }
    : error?.id !== undefined
    ? { error }
    : { error: { ...error, id: errorNumber++ } }
}

type Actions = { type: 'set'; error: LocalErrorProps } | { type: 'clear' }

const reducer = (state: LocalErrorDataContextInfo, action: Actions): LocalErrorDataContextInfo => {
  switch (action.type) {
    case 'set':
      return setError(state, action.error)

    case 'clear':
      return setError(state, undefined)
  }
}

export const LocalErrorProvider = ({ children }) => {
  const [error, dispatch] = useReducer(reducer, { error: undefined })

  const api = useMemo(
    () => ({
      setError: (error: LocalErrorProps) => {
        dispatch({ type: 'set', error })
      },

      clearError: () => {
        dispatch({ type: 'clear' })
      },
    }),
    [dispatch],
  )

  return (
    <LocalErrorApiContext.Provider value={api}>
      <LocalErrorDataContext.Provider value={error}>{children}</LocalErrorDataContext.Provider>
    </LocalErrorApiContext.Provider>
  )
}

export function withLocalErrorProvider(Component) {
  return function ThemeComponent(props) {
    return (
      <LocalErrorProvider>
        <Component {...props} />
      </LocalErrorProvider>
    )
  }
}

import { ResourceApi, ResourceApiConverters } from 'services/api'
import { FlujoFondos } from './FlujoFondos'
import { formatDateISO, toDate } from 'utils/date'

const converters: ResourceApiConverters<FlujoFondos> = {
  formDataToModel: (formData: any) => {
    const result = { ...formData, fecha: formatDateISO(formData.fecha) }
    return result
  },
  modelToFormData: (flujo) => ({ ...flujo, fecha: toDate(flujo.fecha) }),
}

export class FlujoFondosApi extends ResourceApi<FlujoFondos> {
  constructor(tituloId: Identifier) {
    super('/api/titulos/:tituloId/flujo-fondos', { tituloId }, false, converters)
  }
}

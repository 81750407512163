/* eslint-disable react/display-name */
import React, { CSSProperties } from 'react'
import { useFormContext } from 'react-hook-form'
import cloneDeep from 'lodash/cloneDeep'
import { HelperText, ErrorMessage } from 'components'
import BymaTooltip from 'components/BymaOverlay/BymaTooltip'

import styles from './TextArea.module.scss'
import { get } from 'lodash'

export interface TextAreaProps extends FieldProps {
  placeHolder?: string
  onChange?: (selected: any) => void
  inputClassName?: string
  fullWidth?: boolean
  divStyle?: CSSProperties
  handleRightIconClick?: () => void
  onBlur?: (selected: any) => void
  hideErrorMessage?: boolean
  parse?: (value: any) => any
  helperText?: string
  required?: boolean
  rows?: number
}

export const TextArea = (props: TextAreaProps) => {
  const {
    name,
    id = name,
    label,
    value,
    className,
    inputClassName,
    fullWidth = true,
    onChange: customOnChange,
    onBlur: customOnBlur,
    placeHolder = '',
    readOnly: readonly = false,
    style = {},
    divStyle = {},
    deps = [],
    hideErrorMessage = false,
    parse,
    helperText = undefined,
    required = false,
    toolTipText = undefined,
    rows = 3,
  } = props

  const { register, formState } = useFormContext()

  const formFieldProps = register(name, { deps })

  const errorMessage: string | undefined = get(formState.errors, name)?.message as string

  const onChange = (event: any) => {
    //console.log('DEBUG TextArea onChange')

    if (parse && event.target) {
      event = cloneDeep(event)
      event.target.value = parse(event.target.value)
    }

    formFieldProps.onChange(event)

    if (customOnChange) {
      customOnChange(event)
    }
  }

  const onBlur = (event: any) => {
    //console.log('DEBUG TextArea onBlur')

    formFieldProps.onBlur(event)
    if (customOnBlur) {
      customOnBlur(event)
    }
  }

  //console.log(`DEBUG TextArea variant=${variant} InputVariantClassName=${inputVariantClassName}`)

  const readOnlyClass = readonly ? styles.inputInnerAddonReadOnly : ''

  const inputClasses = `${
    errorMessage ? styles.inputFormFieldError : ''
  } ${inputClassName} ${readOnlyClass}`

  const getStyle = () => {
    return fullWidth ? { ...style, width: '100%' } : style
  }

  return (
    <div
      key={'texarea-group-' + id}
      className={styles.bymaTextField + ' form-group ' + (className ?? '')}
      style={divStyle}
    >
      {label && (
        <div>
          <label htmlFor={id} className={styles.fieldLabel}>
            {label + (required ? ' *' : '')}
          </label>
          {toolTipText && <BymaTooltip text={toolTipText} />}
        </div>
      )}
      <div key={'inner-addon-' + id} className={`${styles.inputInnerAddon} ${inputClasses}`}>
        <textarea
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...formFieldProps}
          key={id}
          className={styles.inputFormField + ' bg-transparent '}
          readOnly={readonly}
          ref={formFieldProps.ref}
          placeholder={placeHolder}
          defaultValue={value}
          //value={value}
          autoComplete='new-password'
          onChange={onChange}
          onBlur={onBlur}
          style={getStyle()}
          rows={rows}
        />
      </div>

      {helperText && <HelperText text={helperText} />}
      {!hideErrorMessage && <ErrorMessage error={errorMessage} />}
    </div>
  )
}

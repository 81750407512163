import { createContext } from 'react'

import { Store } from './types'
import { localStorageStore } from './localStorageStore'
import { memoryStore } from './memoryStore'

const defaultStore = localStorageStore()
//const defaultStore = memoryStore()

export const StoreContext = createContext<Store>(defaultStore)

import React, { CSSProperties } from 'react'

//import './BymaTitle.scss'
import styles from './BymaTitle.module.scss'
import { getIconPath } from '..'

interface TitleProps {
  text: string
  size?: number
  icon?: string
  style?: CSSProperties | undefined
  extrasRight?: JSX.Element | JSX.Element[]
}

const BymaTitle = (props: TitleProps) => {
  const { text, style = {}, icon, extrasRight } = props
  return (
    <div className={styles.bymaTitle} style={style}>
      <div className={styles.leftContainer}>
        {icon && (
          <div className={styles.titleIconContainer}>
            <img src={getIconPath(icon)} className={styles.titleIcon} />
          </div>
        )}
        <div className={styles.titleText}>{text}</div>
      </div>

      {extrasRight && <div>{extrasRight}</div>}
    </div>
  )
}

export default BymaTitle

import React from 'react'

import { Col, Row } from 'react-bootstrap'

import {
  BymaCard,
  TextInput,
  BymaSelect,
  BymaDataSelect,
  LocalErrorAlert,
  BymaSwitchButtonInput,
  BymaCheckbox,
  NumberInput,
  BymaSingleAccordion,
} from 'components'

import { UseEditFormReturn } from 'core'

import {
  OfertaContextInfo,
  useParticipanteOfertaState,
  useOfertaEditState,
  OfertaParticipanteFormFields,
} from '.'

import {
  OfertaCreationInfo,
  mapDenominableListToOption,
  ofertaValueLabelFormatter,
  ofertaTipoValorTitleFormatter,
  limitesCantidadHelperTextFormatter,
  precioReferenciaFormatter,
  TipoOfertaOptions,
  ClaseOfertaOptions,
  EstadoOfertaEditOptions,
  PaisOptionsQueryProps,
  BooleanOptions,
  EstadoOfertaShowOptions,
} from 'model'

import styles from './Ofertas.module.scss'
import './OfertaExtendida.scss'

interface OfertaExtendidaProps {
  className?: string
  context: OfertaContextInfo
}

interface OfertaExtendidaFormProps extends OfertaExtendidaProps {
  editForm: UseEditFormReturn<OfertaCreationInfo>
}

export const OfertaExtendidaFormFields = (props: OfertaExtendidaFormProps) => {
  const participanteState = useParticipanteOfertaState(props.context)

  const tituloOptions = mapDenominableListToOption(props.context.currentColocacion?.titulos)

  const { context, editForm } = props
  const { editEnabled, readOnly } = context

  const {
    valorInputEnabled,
    valorInputPlaceHolder,
    cantidadMaxima,
    cantidadMinima,
    integracionTituloOptions,
    valorReferencia,
    titulo,
    isCapVisible,
  } = useOfertaEditState(context)

  const valorHelperText = precioReferenciaFormatter(valorReferencia)
  const tipoValorText = ofertaValueLabelFormatter(titulo)
  const tipoValorTitle = ofertaTipoValorTitleFormatter(titulo)
  const cantidadHelperText = limitesCantidadHelperTextFormatter(cantidadMinima, cantidadMaxima)

  const cardCommonStyles = {
    backgroundColor: 'transparent',
    border: '1px solid var(--gray-color-dark)',
    borderRadius: '20px',
    padding: '15px',
  }

  const colocacionDescription = `${context?.currentColocacion?.denominacion}  |  LICITA ${tipoValorTitle}`

  const readOnlyFields = readOnly || !editEnabled

  const estadoEditable =
    editForm.isFieldDefined('estado') && !readOnlyFields && !context.isFieldReadOnly('estado')
  const showEstado = estadoEditable || context.ofertaId

  return (
    <>
      <Row>
        <Col sm={12}>
          <span className={styles.ofertaExtendidaDescription}>{colocacionDescription}</span>
        </Col>
      </Row>

      <Row>
        <LocalErrorAlert />
      </Row>

      <Row>
        <Col sm={12} style={{ padding: 0 }}>
          <div className='oferta-extendida-no-competitivo'>
            <BymaSwitchButtonInput
              name='tipoOferta'
              id='oferta-extendida-no-competitivo-btn'
              options={TipoOfertaOptions}
              readOnly={!editEnabled}
            />
          </div>
        </Col>
      </Row>

      <BymaCard
        id={'oferta-titulo'}
        style={{
          marginBottom: '20px',
          marginTop: '20px',
          ...cardCommonStyles,
        }}
      >
        <Row sm={12}>
          <Col sm={9}>
            <BymaSelect
              modalMenu
              name='tituloId'
              label='Titulo'
              required={true}
              options={tituloOptions}
              readOnly={readOnlyFields || context.isFieldReadOnly('tituloId')}
            />
          </Col>

          {editForm.isFieldDefined('clase') ? (
            <Col sm={3}>
              <BymaSelect
                modalMenu
                name='clase'
                label='Clase'
                required={true}
                options={ClaseOfertaOptions}
                readOnly={readOnlyFields || context.isFieldReadOnly('clase')}
              />
            </Col>
          ) : null}

          {showEstado ? (
            <Col sm={3}>
              <BymaSelect
                modalMenu
                name='estado'
                label='Estado'
                required={true}
                options={estadoEditable ? EstadoOfertaEditOptions : EstadoOfertaShowOptions}
                readOnly={!estadoEditable}
              />
            </Col>
          ) : null}
        </Row>
      </BymaCard>

      <BymaCard
        id={'oferta-general'}
        title={'General'}
        titleBackgorundColor='var(--secondary-gray-normal)'
        style={{ marginBottom: '20px', ...cardCommonStyles }}
      >
        <Row sm={12}>
          <Col sm={3}>
            <NumberInput
              name='cantidad'
              label='Cantidad'
              required={true}
              helperText={cantidadHelperText}
              readOnly={readOnlyFields || context.isFieldReadOnly('cantidad')}
            />
          </Col>
          <Col sm={3}>
            <NumberInput
              name='valor'
              label={tipoValorText}
              type='number'
              required={valorInputEnabled}
              placeHolder={valorInputPlaceHolder}
              helperText={valorHelperText}
              readOnly={!valorInputEnabled || readOnlyFields || context.isFieldReadOnly('valor')}
              disabled={!valorInputEnabled}
            />
          </Col>
          <Col sm={3}>
            <BymaSelect
              name='integracionTituloId'
              label='Integración'
              required={true}
              options={integracionTituloOptions}
              modalMenu
              readOnly={readOnlyFields || context.isFieldReadOnly('integracionTituloId')}
            />
          </Col>
          {isCapVisible ? (
            <Col sm={3}>
              <NumberInput
                name='porcentajeInversion'
                label='CAP'
                readOnly={readOnlyFields || context.isFieldReadOnly('porcentajeInversion')}
              />
            </Col>
          ) : null}
        </Row>
      </BymaCard>

      <BymaCard
        id={'oferta-participantes'}
        title={'Participantes'}
        titleBackgorundColor='var(--secondary-gray-normal)'
        style={{ marginBottom: '10px', ...cardCommonStyles }}
      >
        {participanteState.agenteOptionsEnabled ? (
          <Row sm={12} className='mb-3'>
            <Col sm={9}>
              <BymaSelect
                modalMenu
                name='agenteId'
                label='Agente'
                required={true}
                options={participanteState.agenteOptions}
                readOnly={readOnlyFields || context.isFieldReadOnly('agenteId')}
              />
            </Col>
            <Col sm={3}>
              <BymaCheckbox
                type='radio'
                id={'agente-terceros'}
                name='deTerceros'
                multicheck={false}
                label={'De terceros'}
                options={BooleanOptions}
                readOnly={readOnlyFields || context.isFieldReadOnly('deTerceros')}
              />
            </Col>
          </Row>
        ) : null}

        <OfertaParticipanteFormFields {...participanteState} />
      </BymaCard>

      <BymaSingleAccordion id={'oferta-extendida-mas-info'}>
        <BymaCard
          id={'oferta-info-extra'}
          title={'Mas info'}
          titleBackgorundColor='var(--secondary-gray-normal)'
          style={{ marginBottom: '20px', ...cardCommonStyles }}
        >
          <Row sm={12}>
            <Col sm={3}>
              <BymaDataSelect
                name='nacionalidadClienteId'
                label='Nacionalidad'
                required={false}
                queryProps={PaisOptionsQueryProps}
                maxMenuHeight={130}
                modalMenu
                readOnly={readOnlyFields || context.isFieldReadOnly('nacionalidadClienteId')}
              />
            </Col>
            <Col sm={9}>
              <TextInput
                name='observaciones'
                label='Observaciones'
                readOnly={readOnlyFields || context.isFieldReadOnly('observaciones')}
              />
            </Col>
          </Row>
        </BymaCard>
      </BymaSingleAccordion>
    </>
  )
}

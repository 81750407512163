import { useState, useCallback } from 'react'

export interface EntityContextInfo {
  entityName: string
  entityLabel: string
  entityId?: Identifier
  setEntityId: (b: Identifier | undefined) => void
  entityDescription?: string
  setEntityDescription: (b: string | undefined) => void
  showModal: boolean
  setShowModal: (b: boolean) => void
  showDeleteModal: boolean
  setShowDeleteModal: (b: boolean) => void
  showNestedDeleteModal: boolean
  setShowNestedDeleteModal: (b: boolean) => void
  editEnabled: boolean
  setEditEnabled: (b: boolean) => void
  queryCount: number
  listQueryKey: any
  readOnly?: boolean
}

//contador para forzar un refresh de las entidades en cada consulta
let globalQueryCount = 0

//TODO usar un map con las propiedades en vez de una lista de parametros
const useEntityContext = (
  entityName: string,
  entityLabel?: string,
  listQueryKey?: any,
  readOnly?: boolean,
): EntityContextInfo => {
  const [showModal, setShowModal] = useState<boolean>(false)
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false)
  const [showNestedDeleteModal, setShowNestedDeleteModal] = useState<boolean>(false)
  const [entityId, setEntityId] = useState<Identifier | undefined>(undefined)
  const [entityDescription, setEntityDescription] = useState<string | undefined>(undefined)
  const [editEnabled, setEditEnabled] = useState<boolean>(false)

  const setEntityIdWrapper = useCallback(
    (id) => {
      setEntityId(id)
      globalQueryCount++
      //console.log(`DEBUG useEntityContext se actualiza queryCount=${globalQueryCount}`)
    },
    [setEntityId],
  )

  const actualListQueryKey = listQueryKey || [entityName, 'entity.list']

  return {
    entityName,
    entityLabel: entityLabel || entityName,
    showModal,
    setShowModal,
    showDeleteModal,
    setShowDeleteModal,
    showNestedDeleteModal,
    setShowNestedDeleteModal,
    entityId,
    setEntityId: setEntityIdWrapper,
    editEnabled,
    setEditEnabled,
    queryCount: globalQueryCount,
    listQueryKey: actualListQueryKey,
    entityDescription,
    setEntityDescription,
    readOnly,
  }
}

export { useEntityContext }

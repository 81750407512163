import AxiosMockAdapter from 'axios-mock-adapter-path-params'

const grupoEconomicoListResponse = [
  {
    id: 62,
    persistenceVersion: 1,
    denominacion: '<image src=/sicolp/images/logo/byma-logo-blanco.png onload=alert(1)>',
    numero: 123456,
    cuit: '23219184379',
    aliasSiopel: '1234567',
  },
  {
    id: 49,
    persistenceVersion: 1,
    denominacion: 'ADCAP ASSET MANAG SFFCISA',
    numero: 381,
    cuit: '30710198388',
    aliasSiopel: '381',
  },
  {
    id: 31,
    persistenceVersion: 1,
    denominacion: 'ADMINISTRADORA DE FONDOS S.A.',
    numero: 2471,
    cuit: '33715382259',
    aliasSiopel: '2471',
  },
  {
    id: 5,
    persistenceVersion: 1,
    denominacion: 'ADMINISTRADORA DE TITULOS Y VALORES SASGFCI',
    numero: 11,
    cuit: '30649280726',
    aliasSiopel: '11',
  },
  {
    id: 28,
    persistenceVersion: 1,
    denominacion: 'ALAMERICA S.G.F.C.I.S.A.',
    numero: 2418,
    cuit: '30715483641',
    aliasSiopel: '2418',
  },
  {
    id: 41,
    persistenceVersion: 1,
    denominacion: 'ALLARIA LEDESMA FONDOS ADMINISTRADOS SGFCISA',
    numero: 356,
    cuit: '30710456808',
    aliasSiopel: '356',
  },
  {
    id: 46,
    persistenceVersion: 1,
    denominacion: 'ARGENFUNDS S.A.',
    numero: 377,
    cuit: '30711596603',
    aliasSiopel: '377',
  },
  {
    id: 50,
    persistenceVersion: 1,
    denominacion: 'AXIS SGFCISA',
    numero: 384,
    cuit: '30712241450',
    aliasSiopel: '384',
  },
  {
    id: 53,
    persistenceVersion: 1,
    denominacion: 'AYRES CAPITAL ASSET MANAGEMENT S.A.U.',
    numero: 394,
    cuit: '30708318066',
    aliasSiopel: '394',
  },
  {
    id: 11,
    persistenceVersion: 1,
    denominacion: 'BACS ADMINISTRADORA DE ACTIVOS SASGFCI',
    numero: 27,
    cuit: '30660515980',
    aliasSiopel: '27',
  },
]

export const getGrupoEconomicoResponse = (id: number) => {
  const entities = grupoEconomicoListResponse.filter((col) => col.id == id)
  return entities !== undefined && entities.length > 0 ? entities[0] : {}
}

export const getGrupoEconomicoExportResponse = () => {
  const blob = new Blob([''])
  return blob
}

export const mockGrupoEconomico = (mock: AxiosMockAdapter) => {
  mock.onGet('/api/grupos-economicos').reply(() => {
    return [200, grupoEconomicoListResponse]
  })

  mock.onGet('/api/grupos-economicos/:id').reply((config: any) => {
    const { id } = config.routeParams
    return [200, getGrupoEconomicoResponse(id)]
  })

  mock.onPost('/api/grupos-economicos').reply((config: any) => {
    const { id } = config.routeParams
    return [200, getGrupoEconomicoResponse(id)]
  })

  mock.onDelete('/api/grupos-economicos/:id').reply((config: any) => {
    const { id } = config.routeParams
    return [200, {}]
  })

  mock.onPut('/api/grupos-economicos/:id').reply((config: any) => {
    const { id } = config.routeParams
    return [200, getGrupoEconomicoResponse(id)]
  })

  mock.onGet('/api/grupos-economicos/export').reply(() => {
    return [
      200,
      getGrupoEconomicoExportResponse(),
      {
        'content-disposition': 'filename="grupos-economicos.csv"',
        'content-type': 'application/csv',
      },
    ]
  })
}

import { ResourceApi } from 'services/api/resourceApi'
import { Colocador } from './Colocador'

class ColocadoresApi extends ResourceApi<Colocador> {
  constructor() {
    super('/api/colocadores')
  }
}

export default new ColocadoresApi()

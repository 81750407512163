import { isNullOrUndefined } from 'utils/objects'

import AddIcon from 'assets/img/add.svg'
import AdjudicacionIcon from 'assets/img/adjudicacion.svg'
import FlagIcon from 'assets/img/flag.svg'
import AllMatchIcon from 'assets/img/all-match.svg'
import EstadoButtonIcon from 'assets/img/estado-button.svg'
import UploadIcon from 'assets/img/upload.svg'
import { ReactComponent as AddIconElement } from 'assets/img/add.svg'
import DeleteIcon from 'assets/img/delete.svg'
import EditIcon from 'assets/img/edit.svg'
import PlusIcon from 'assets/img/plus.svg'
import FolderOpenIcon from 'assets/img/folder_open.svg'
import AddFilter from 'assets/img/add-filter.svg'

import ArrowBackIcon from 'assets/img/arrow-back.svg'

import LogoutIcon from 'assets/img/login/logout.svg'
import LockIcon from 'assets/img/login/lock.svg'
import PersonFilledGrayIcon from 'assets/img/login/person_filled_grey.svg'
import PersonFilledIcon from 'assets/img/login/person_filled.svg'
import LoginIcon from 'assets/img/login/logo-login.svg'
import LoginVisibilityOn from 'assets/img/login/visibility.svg'
import LoginVisibilityOff from 'assets/img/login/visibility_off.svg'

import VisibilityIcon from 'assets/img/visibility.svg'
import DownloadIcon from 'assets/img/download.svg'

import NavBarMessageIcon from 'assets/img/navbar/mail.svg'
import NavBarInfoIcon from 'assets/img/navbar/question-circle.svg'
import NavBarSettingsIcon from 'assets/img/navbar/settings.svg'

import ToggleOnIcon from 'assets/img/toggle-on.svg'
import AttachFileIcon from 'assets/img/attach-file.svg'
import ErrorIcon from 'assets/img/error.svg'
import InfoIcon from 'assets/img/info.svg'

/**
 * Funcion para manejar paths de iconos legacy. Remover cuando no se utilicen mas los paths hardcoded
 *
 * @param icon
 * @returns
 */
export function getIconPath(icon) {
  if (isNullOrUndefined(icon)) {
    return icon
  }
  if (icon.startsWith('/static')) {
    return icon
  }

  //para soportar clases que todavia usen referencias hardocdeadas (remover cuando se hayan actualziado)
  if (!icon.includes('/')) {
    return '../images/icons/' + icon
  }

  if (icon.startsWith('icons/')) {
    return '../images/' + icon
  }

  if (!icon.startsWith('/')) {
    return 'images/icons/' + icon
  }

  return icon
}

export {
  AddIcon,
  AddIconElement,
  DeleteIcon,
  ArrowBackIcon,
  LogoutIcon,
  LockIcon,
  LoginIcon,
  AddFilter,
  PersonFilledIcon,
  PersonFilledGrayIcon,
  LoginVisibilityOn,
  LoginVisibilityOff,
  VisibilityIcon,
  DownloadIcon,
  NavBarMessageIcon,
  NavBarInfoIcon,
  NavBarSettingsIcon,
  EditIcon,
  PlusIcon,
  FolderOpenIcon,
  AdjudicacionIcon,
  EstadoButtonIcon,
  UploadIcon,
  FlagIcon,
  AllMatchIcon,
  ToggleOnIcon,
  AttachFileIcon,
  ErrorIcon,
  InfoIcon
}

import { BymaNavigateButton, BymaTableColumn, BymaDownloadButton } from 'components'

import { EntityContextInfo } from 'features/common/Entity'
import { DownloadIcon, VisibilityIcon, DeleteIcon } from 'components/BymaIcons'

interface EntityButtonProps extends Omit<BymaTableColumn, 'field'> {
  context: EntityContextInfo
  field?: string
}

interface CellDownloadButtonProps extends Omit<BymaTableColumn, 'field'> {
  serverDownload: (data: any) => void
  getFileName: (data: any) => string
  postDownloading: (data: any) => void
  field?: string
}

export const useEditButtonCell = ({
  headerName = 'Ver y Editar',
  field = 'edit',
  context,
  minWidth = 50,
  maxWidth = 80,
  ...rest
}: EntityButtonProps): BymaTableColumn => ({
  field,
  headerName,
  cellClass: 'action-button',
  cellRenderer: BymaNavigateButton,
  cellRendererParams: (params) => {
    return {
      onClick: () => {
        context.setEntityId(params?.data?.id)
        context.setShowModal(true)
        context.setEditEnabled(false)
      },
      label: '',
      icon: VisibilityIcon,
    }
  },
  minWidth,
  maxWidth,
  ...rest,
})

export const useDeleteButtonCell = ({
  headerName = 'Eliminar',
  field = 'delete',
  context,
  minWidth = 50,
  maxWidth = 60,
  ...rest
}: EntityButtonProps): BymaTableColumn => ({
  field,
  headerName,
  cellClass: 'action-button',
  cellRenderer: BymaNavigateButton,
  cellRendererParams: (params) => ({
    onClick: () => {
      context.setEntityId(params?.data?.id)
      context.setEntityDescription(params?.data?.denominacion)
      context.setShowModal(false)
      context.setShowDeleteModal(true)
    },
    label: '',
    icon: DeleteIcon,
  }),
  minWidth,
  maxWidth,
  ...rest,
})

export const useDownloadButtonCell = ({
  headerName = 'Descargar',
  field = 'download',
  minWidth = 50,
  maxWidth = 80,
  serverDownload,
  getFileName,
  postDownloading,
  ...rest
}: CellDownloadButtonProps): BymaTableColumn => ({
  field,
  headerName,
  cellClass: 'action-button',
  minWidth,
  maxWidth,
  cellRenderer: BymaDownloadButton,
  cellRendererParams: (params) => {
    return {
      label: '',
      icon: DownloadIcon,
      serverDownload: () => serverDownload(params?.data),
      getFileName: () => getFileName(params?.data),
      postDownloading: () => postDownloading(params?.data),
    }
  },
  ...rest,
})

import React from 'react'
import { Form } from 'react-bootstrap'
import BymaTooltip from 'components/BymaOverlay/BymaTooltip'
import { HelperText, ErrorMessage } from 'components'
import { useFormContext } from 'react-hook-form'

import styles from './BymaUpload.module.scss'
import { getValue } from '@testing-library/user-event/dist/utils'

interface UploadProps extends FieldProps {
  placeHolder?: string
  helperText?: string
  hideErrorMessage?: boolean
  inputClassName?: string
  onChange?: (value: any) => void
  variant?: 'Default' | 'Small'
}

const FILE_INPUT = 'byma_input_file_upload'

export const BymaUpload = (props: UploadProps) => {
  const {
    name,
    id = name,
    label,
    placeHolder = 'Selecione',
    required = false,
    toolTipText = undefined,
    hideErrorMessage = false,
    readOnly: readonly = false,
    inputClassName,
    onChange: customOnChange,
    variant = 'Default',
  } = props

  const { register, formState, setValue, watch } = useFormContext()

  const formFieldProps = register(name)

  const onChange = (event: any) => {
    setValue(name, event.target?.files[0])

    console.log(`DEBUG BymaUpdaload ${name} input onChange value=${event.target?.files[0]}`)

    if (customOnChange) {
      customOnChange(event)
    }
  }

  const errorMessage: string | undefined = formState.errors[name]?.message as string

  const inputVariantClassName = inputClassName
    ? inputClassName
    : styles[`inputInnerAddon${variant.charAt(0).toUpperCase() + variant.slice(1)}`]

  const readOnlyClass = readonly ? styles.inputInnerAddonReadOnly : ''
  const inputClasses = `${
    errorMessage ? styles.inputFormFieldError : ''
  } ${inputVariantClassName} ${readOnlyClass}`

  const watchInputFile = watch(name)

  return (
    <Form.Group controlId='formFile' className={styles.bymaUploadField}>
      {label && (
        <div>
          <Form.Label htmlFor={id} className={styles.fieldLabel}>
            {label + (required ? ' *' : '')}
          </Form.Label>
          {toolTipText && <BymaTooltip text={toolTipText} />}
        </div>
      )}
      <div className={`${styles.inputInnerAddon} ${inputClasses}`}>
        <div className={styles.leftIcon}>
          <img className={styles.inputLeftAddon} src={'images/icons/attach-file.svg'} />
        </div>
        <Form.Control
          type='file'
          className={styles.inputFormField}
          onChange={onChange}
          id={FILE_INPUT}
          style={{ display: 'none' }}
        />
        <label htmlFor={FILE_INPUT} style={{ width: '100%' }}>
          {watchInputFile?.name || placeHolder}
        </label>
      </div>

      {/* <HelperText text={'O arrastra y suelta el archivo acá'} showIcon={false} /> */}
      {!hideErrorMessage && <ErrorMessage error={errorMessage} />}
    </Form.Group>
  )
}

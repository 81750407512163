import React from 'react'

import styles from './BymaPair.module.scss'

interface PairProps {
  text: string
  value: any
}

const BymaPair = (props: PairProps) => {
  const { text, value } = props
  const valor = value === undefined || value === null || value.toString().length == 0 ? '-' : value

  return (
    <span className={styles.bymaPairText}>
      <span>{text + ': '}</span>
      <span className={styles.bymaPairValue}>{valor}</span>
    </span>
  )
}

export default BymaPair

import React, { ReactNode, useMemo, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

import { useEditForm, withPermissions } from 'core'
import { FormProvider } from 'react-hook-form'
import { Col, Container, Form, Row } from 'react-bootstrap'

import {
  BymaCard,
  BymaHelpBox,
  BymaButton,
  BymaPageFrame,
  TextArea,
  TextInput,
  BymaCreatableSelect,
  BymaModal,
  BymaNavigateButton,
} from 'components'
import * as BymaPermissions from 'services/auth/bymaPermissions'

import { HelpItem, SECCION_AYUDA_CONFIG, SECCION_AYUDA } from 'model/help'

import * as yup from 'yup'
import HelpApi from './HelpApi'

import styles from './Help.module.scss'
import cloneDeep from 'lodash/cloneDeep'

const getFieldName = (codigo, field) => `${codigo}.${field}`

const BUTTON_TEXT_FIELD = 'buttonText'
const LINK_FIELD = 'link'
const BODY_TEXT_FIELD = 'text'
const CONTACTOS_ATENCION_FIELD = 'contactosAtencion'
const CONTACTOS_MERCADO_FIELD = 'contactosMercado'

const getSchema = () => {
  const getSeccionInfo = () => {
    return yup.object().shape({
      [BUTTON_TEXT_FIELD]: yup.string().required(),
      [LINK_FIELD]: yup.string().required(),
      [BODY_TEXT_FIELD]: yup.string().required(),
    })
  }

  const getSeccionContactos = () => {
    return yup.object().shape({
      //TODO hacerlos required
      [CONTACTOS_ATENCION_FIELD]: yup
        .array()
        .min(1, 'Debe ingresar al menos un contacto de atención'),
      [CONTACTOS_MERCADO_FIELD]: yup
        .array()
        .min(1, 'Debe ingresar al menos un contacto de negocicación'),
    })
  }

  return yup.object().shape({
    [SECCION_AYUDA.MANUAL]: getSeccionInfo(),
    [SECCION_AYUDA.TUTORIAL]: getSeccionInfo(),
    [SECCION_AYUDA.ARANCELES]: getSeccionInfo(),
    [SECCION_AYUDA.NORMATIVA]: getSeccionInfo(),
    [SECCION_AYUDA.COLOCACIONES]: getSeccionInfo(),
    [SECCION_AYUDA.CONTACTO]: getSeccionContactos(),
  })
}

const formDataToBackend = (formData): HelpItem[] => {
  const result = Object.keys(formData).map((key) => ({
    id: 0,
    codigo: key,
    configUi: cloneDeep(formData[key]),
    //el backend almacena este dato en una columna especifica
    link: formData[LINK_FIELD],
  }))
  return result
}

const backendToFormData = (apiBody) => {
  const formData = {}
  if (apiBody) {
    apiBody.forEach((item) => {
      formData[item.codigo] = item.configUi
    })
  }
  return formData
}

const BymaConfirmModal = ({ confirmText, handleConfirm, showConfirm, setShowConfirm }) => {
  return (
    <BymaModal
      id={'help-edit-confirm-modal'}
      show={showConfirm}
      children={<p>{confirmText}</p>}
      handleCancel={() => {
        setShowConfirm(false)
      }}
      handleConfirm={() => {
        handleConfirm()
        setShowConfirm(false)
      }}
    />
  )
}

const HelpEdit = () => {
  const navigate = useNavigate()

  const getFormData = useCallback(() => {
    return HelpApi.getList().then((items) => backendToFormData(items))
  }, [])

  const mutation = useCallback((formData) => HelpApi.updateBatch(formDataToBackend(formData)), [])

  const schema = useMemo(() => getSchema(), [])

  const editForm = useEditForm<any>({
    queryKey: 'help_edit',
    getFormData: getFormData,
    mutation: mutation,
    schema: schema,

    confirm: true,
    keepPreviousData: true,

    successNotification: 'Se actualizó la Información de Ayuda',
    errorNotification: 'Error actualizando Información de Ayuda',
    onSuccess: () => navigate('/help'),
  })

  const HelpItemEditor = (buttonProps: { title: string; children: ReactNode }) => {
    return (
      <div className={styles.bymaHelpEditItem}>
        <span>{buttonProps.title}</span>
        {buttonProps.children}
      </div>
    )
  }

  const getItemDescription = (codigo: string, config: any) => {
    const type = config.type
    const order = config.order

    if (type === 'info') {
      return <TextArea name={getFieldName(codigo, BODY_TEXT_FIELD)} rows={3} />
    } else if (type === 'contacto') {
      const text = config.text
      const parts = text.split(/(\r\n|\n|\r)/)

      if (parts && parts.length === 7) {
        return (
          <div key={'paragraph-' + order}>
            {parts[0]} <br></br>
            <br></br>
            <div className={styles.contactHelpButtons}>
              <p className={styles.contactText}>{parts[2]}</p>
              {<BymaCreatableSelect name={getFieldName(codigo, CONTACTOS_ATENCION_FIELD)} />}
            </div>
            <div className={styles.contactHelpButtons}>
              <p className={styles.contactText}>{parts[4]}</p>
              {<BymaCreatableSelect name={getFieldName(codigo, CONTACTOS_MERCADO_FIELD)} />}
            </div>
          </div>
        )
      }
      return <></>
    }
    return <></>
  }

  const getItemButton = (codigo: string, config: any) => {
    const type = config.type

    if (type === 'info') {
      return (
        <HelpItemEditor title={'Botón'}>
          <Row className='mb-3'>
            <Col>
              <TextInput name={getFieldName(codigo, BUTTON_TEXT_FIELD)} />
            </Col>
          </Row>
          <Row>
            {/* Solo se van a utilizar Links
            <Col className='col-4'>
              <BymaSelect
                name={getFieldName(codigo, LINK_FIELD)}
                options={[
                  { label: 'Adjunto', value: 'adjunto' },
                  { label: 'Link', value: 'link' },
                ]}
                style={{ fontSize: 14, fontWeight: '300' }}
              />
            </Col>
            */}
            <Col>
              <TextInput name={getFieldName(codigo, LINK_FIELD)} />
            </Col>
          </Row>
        </HelpItemEditor>
      )
    }

    return <></>
  }

  const renderHelpCards = () => {
    return Object.keys(SECCION_AYUDA_CONFIG).map((codigo: any) => {
      const config = SECCION_AYUDA_CONFIG[codigo]
      return (
        <div key={'help-item-' + codigo} className={styles.bymaHelpItem}>
          <BymaCard title={config.title} key={'help-item-' + codigo}>
            <Row>
              <Col className={config.descriptionClassname}>
                <HelpItemEditor title={config.subTitle}>
                  {getItemDescription(codigo, config)}
                </HelpItemEditor>
              </Col>
              <Col className={config.buttonClassname}>{getItemButton(codigo, config)}</Col>
            </Row>
          </BymaCard>
        </div>
      )
    })
  }

  const handleCancel = () => {
    navigate('/help')
  }

  return (
    <FormProvider {...editForm.form}>
      <Form id='help-edit-form' onSubmit={editForm.onSubmit}>
        <BymaCard
          style={{
            width: '100%',
            background: 'transparent',
            backgroundColor: 'transparent',
            padding: 0,
          }}
          footer={
            <div className={styles.footerBtnRoles_container}>
              <div className={styles.footerBtnRoles_btns_container}>
                <div className={styles.footerBtnRoles}>
                  <BymaButton
                    type='button'
                    id='roles-form-btn'
                    name='roles-form-btn'
                    label={'Cancelar'}
                    onClick={handleCancel}
                    style={{
                      backgroundColor: '#626573',
                      border: '1px solid #626573',
                    }}
                  />
                </div>

                <div className={styles.footerBtnRoles}>
                  <BymaButton
                    type='submit'
                    id='roles-form-btn'
                    name='roles-form-btn'
                    label={'Guardar'}
                    iconLeft='check.svg'
                    style={{
                      backgroundColor: '#0071CE',
                      border: '1px solid #0071CE',
                      gap: 4,
                    }}
                  />
                </div>
              </div>
            </div>
          }
        >
          <BymaPageFrame
            bcHome={{ value: 'Dashboard', link: '/home' }}
            bcItems={[]}
            bcActive='Ayuda'
            title='AYUDA'
            iconTitle='navbar/question-circle.svg'
            className={styles.bymaHelpContainer}
          >
            {withPermissions(
              <Container className={styles.editBtnContainer}>
                <BymaNavigateButton
                  navigateTo={'/help/edit'}
                  id={'help-edit'}
                  name={'help-edit'}
                  label={'Editar'}
                  icon={'edit-square.svg'}
                  style={{
                    float: 'right',
                    height: '33px',
                    marginRight: '25px',
                    border: '1px solid #FFFFFF',
                  }}
                />
              </Container>,

              BymaPermissions.AYUDA_EDIT_PERMISSIONS,
            )}
            <Container>
              <BymaHelpBox
                title='BYMA Primarias'
                className={styles.ayudaHelpBox}
                children={
                  <span>
                    BYMA Primarias es el Sistema de BYMA para la colocación primaria de valores
                    negociables con oferta pública mediante subasta o licitación pública. Permite
                    que tanto los Agentes Miembros BYMA como otros intermediarios, participar
                    libremente del proceso de colocación primaria.
                  </span>
                }
              />
            </Container>

            <Container>{renderHelpCards()}</Container>
          </BymaPageFrame>
        </BymaCard>

        <BymaConfirmModal
          showConfirm={editForm.showConfirm}
          setShowConfirm={editForm.setShowConfirm}
          handleConfirm={editForm.handleConfirm}
          confirmText='Desea Actualizar la Ayuda?'
        />
      </Form>
    </FormProvider>
  )
}

export default HelpEdit

import { mapDenominableToOption } from 'model'
import { useQuery, QueryKey } from 'react-query'

const DEFAULT_OPTIONS_STALE_TIME = 60 * 1000

export type OptionsDataProvider<T> = (params: any) => Promise<T[]>
export interface UseListOptionsParams<T, O extends SelectOption = SelectOption> {
  listKey?: string
  dataProvider: OptionsDataProvider<T>
  keepPreviousData?: boolean
  queryKey?: QueryKey
  queryParams?: any
  staleTime?: number
  enabled?: boolean
  errorHandler?: (error: any) => O[]
  optionMapper?: (source: T) => O
}

function defaultOptionMapper<T, O extends SelectOption = SelectOption>(source: T): O {
  return mapDenominableToOption(source) as O
}

export function useOptionsQuery<T, O extends SelectOption = SelectOption>({
  dataProvider,
  queryParams = {},
  keepPreviousData = true,
  staleTime = DEFAULT_OPTIONS_STALE_TIME,
  queryKey,
  listKey,
  optionMapper = defaultOptionMapper,
  errorHandler,
  enabled,
}: UseListOptionsParams<T, O>) {
  const getQueryKey = (resource, params) => {
    if (resource instanceof String) {
      return [resource, params]
    }
    return [...resource, params]
  }
  const actualQueryKey = queryKey ? queryKey : getQueryKey(listKey, queryParams)

  const defaultErrorHandler = (error) => {
    console.log(
      `ERROR obteniendo opciones para ${JSON.stringify(actualQueryKey)}: ${JSON.stringify(error)}`,
    )
    return []
  }
  const actualErrorHandler = errorHandler ? errorHandler : defaultErrorHandler

  const result = useQuery<O[]>(
    actualQueryKey,

    () => dataProvider(queryParams).then((data) => data.map((item) => optionMapper(item))),

    {
      staleTime: staleTime,
      refetchOnWindowFocus: false,
      keepPreviousData: keepPreviousData,
      enabled: enabled,
    },
  )

  if (result.isError) {
    return { ...result, data: actualErrorHandler(result.error) }
  }

  return result
}

import React, { CSSProperties, ReactNode } from 'react'
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import { Button } from 'react-bootstrap'

import './BymaTabs.scss'

interface TabProps {
  eventKey: string
  title: string
  children: ReactNode
  disabled?: boolean
}

interface TabsProps {
  defaultActiveKey: string
  tabs: TabProps[]
  className?: string
  //width?: string
  //height?: string
  closeable?: boolean
  onClose?: any
  onSelect?: any
  variant?: 'tabs' | 'pills'
  style?: CSSProperties | undefined
}

const BymaTabs = (props: TabsProps) => {
  const {
    defaultActiveKey,
    tabs,
    className,
    closeable = false,
    variant = 'tabs',
    onClose = () => {},
    onSelect = () => {},
    style = {},
  } = props

  const width = '100%'
  const height = 'inherit'

  return (
    <div
      className={'byma-tabs'}
      style={{
        width: width,
        height: height,
        ...style,
      }}
    >
      {closeable && (
        <Button
          type={'button'}
          id={'byma-tabs-close-button'}
          className={'byma-tabs-close-button'}
          onClick={() => {
            onClose()
          }}
        />
      )}
      <Tabs
        defaultActiveKey={defaultActiveKey}
        id='byma-tab'
        variant={variant}
        onSelect={onSelect}
        //unmountOnExit={true}
        className={' byma-tab-header ' + className}
      >
        {tabs &&
          tabs.map((tab: TabProps, idx: number) => (
            <Tab
              key={'byma-tab-' + idx}
              eventKey={tab.eventKey}
              title={tab.title}
              className={'byma-tab-content '}
              disabled={tab.disabled}
            >
              {tab.children}
            </Tab>
          ))}
      </Tabs>
    </div>
  )
}

export default BymaTabs

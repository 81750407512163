import React from 'react'

import {
  BymaDataTable,
  BymaExportButton,
  BymaSelect,
  BymaDataSelect,
  BymaTableLinkRow,
  BymaCard,
  useSelectSingleFilterProps,
  BymaTextInputFilter,
  BymaTableColumn,
} from 'components'

import { ListBase, useNotificationProducer, useListContext } from 'core'

import {
  OFERTA_ENTITY_NAME,
  ESTADO_OFERTA_DETAIL_INFOS,
  OfertasApi,
  getColocacionEstadoIcon,
  OrdenOfertasOptions,
  OrdenOfertas,
  EstadoOfertaGroupOptions,
  EstadoOfertaDetailInfo,
  EstadoOfertaDetail,
  ofertaCapCellFormatter,
  ofertaValorConTipoCellFormatter,
  ofertaCantidadCellRenderProvider,
  ofertaUltimaModificacionFullFormatter,
  ofertaValorAdjudicadoTooltipFormatter,
  ofertaNumeroClienteTooltipFormatter,
} from 'model'

import { BymaTableInfoRow, BymaTableIconRow, BymaTableBadgeRow } from 'components'

import { FiltersHeader } from '../../common/Filters'
import TituloDashboardApi from './TituloDashboardApi'

const MIS_OFERTAS_COLUMNS: BymaTableColumn[] = [
  {
    field: 'id',
    headerName: 'N° de Oferta',
    cellRenderer: BymaTableLinkRow,
    cellRendererParams: (params) => {
      const value = params.data.id
      return { value: value, href: 'www.google.com.ar' }
    },
    minWidth: 100,
  },
  {
    field: 'colocacionId',
    headerName: 'Colocación',
    textAlign: 'left',
    minWidth: 125,
    cellRenderer: BymaTableIconRow,
    cellRendererParams: (params) => {
      const value = params.data.colocacionId
      const estado = params?.data?.colocacionEstado
      const icon = getColocacionEstadoIcon(estado)
      return { value: value, icon }
    },
  },
  {
    field: 'tituloDenominacion',
    headerName: 'Título',
    textAlign: 'left',
    minWidth: 180,
  },
  {
    field: 'cantidad',
    headerName: 'Cantidad',
    textAlign: 'left',
    minWidth: 85,
    cellRenderer: BymaTableInfoRow,
    cellRendererParams: (params) => ofertaCantidadCellRenderProvider(params.data),
  },
  {
    field: 'valor',
    headerName: 'Valor',
    textAlign: 'left',
    headerTooltip: 'Valor descripción',
    minWidth: 90,

    cellRenderer: BymaTableInfoRow,
    cellRendererParams: (params) => {
      const value = ofertaValorConTipoCellFormatter(params?.data)
      const tooltipText = ofertaValorAdjudicadoTooltipFormatter(params?.data)
      return {
        value: value,
        tooltipText: tooltipText,
      }
    },
  },
  { field: 'integracionTituloDenominacion', headerName: 'Integración', minWidth: 90 },
  {
    field: 'porcentajeInversion',
    headerName: 'CAP',
    textAlign: 'center',
    minWidth: 45,

    valueFormatter: (params) => ofertaCapCellFormatter(params?.data),
  },
  {
    field: 'numeroCliente',
    headerName: 'Número',
    textAlign: 'left',
    minWidth: 95,

    cellRenderer: BymaTableInfoRow,
    cellRendererParams: (params) => {
      const value = params.data.numeroCliente
      const tooltipText = ofertaNumeroClienteTooltipFormatter(params.data)
      return {
        value: value,
        tooltipText: tooltipText,
      }
    },
  },

  {
    field: 'timestampModificacion',
    headerName: 'Últ. mod.',
    textAlign: 'left',
    minWidth: 98,
    valueFormatter: (params) => ofertaUltimaModificacionFullFormatter(params?.data),
  },

  {
    headerName: 'Estado de la oferta',
    field: 'estadoDetail',
    minWidth: 181,
    textAlign: 'center',

    cellRenderer: BymaTableBadgeRow,
    cellRendererParams: (params) => {
      const estado = params?.data?.estadoDetail || EstadoOfertaDetail.VIGENTE
      const estadoOfertaDetailInfo: EstadoOfertaDetailInfo = ESTADO_OFERTA_DETAIL_INFOS[estado]

      return estadoOfertaDetailInfo
        ? {
            text: estadoOfertaDetailInfo.shortText || estadoOfertaDetailInfo.text,
            overlayText: estadoOfertaDetailInfo.text,
            type: estadoOfertaDetailInfo.badgeType,
          }
        : {}
    },
  },
]

const OFERTA_LIST_CLASSIFIER = 'dashboard.mis_ofertas'
const LIST_KEY = [OFERTA_ENTITY_NAME, OFERTA_LIST_CLASSIFIER]

const TITULO_DASHBOARD_OPTIONS_KEY = 'titulo_dashboard_options'

const MisOfertasTable = () => {
  const { addNotification } = useNotificationProducer()

  const selectSingleFilterProps = useSelectSingleFilterProps()

  //Filtros
  const filters = [
    <BymaTextInputFilter
      name='colocacionId'
      key='colocacionId'
      label='N° de colocación'
      type='number'
      alwaysOn={true}
      style={{ width: '125px' }}
      variant='Small'
    />,

    <BymaDataSelect
      {...selectSingleFilterProps}
      name='tituloId'
      key='tituloId'
      label='Título'
      style={{ width: '292px' }}
      queryProps={{
        dataProvider: () => TituloDashboardApi.getList(),
        listKey: TITULO_DASHBOARD_OPTIONS_KEY,
      }}
      alwaysOn={true}
    />,

    <BymaSelect
      {...selectSingleFilterProps}
      name='estadoGroup'
      key='estadoGroup'
      label='Estado de la oferta'
      style={{ minWidth: '181px' }}
      options={EstadoOfertaGroupOptions}
      alwaysOn={true}
      placeholder='Seleccione un Estado'
    />,

    <BymaSelect
      name='ordenOfertas'
      key='ordenOfertas'
      label='Ordenar'
      options={OrdenOfertasOptions}
      alwaysOn={true}
      variant='Small'
      hideLabel={true}
    />,
  ]

  const { filterValues } = useListContext()

  //TODO PABLO eliminar el <div style> Se agrego para generar espacio y alinear
  return (
    <BymaCard key={'mis-ofertas-card'}>
      <div style={{ paddingBottom: '20px' }}>
        <FiltersHeader
          filters={filters}
          showFiltersMenu={false}
          exportButton={
            <BymaExportButton
              id='mis-ofertas-export-button'
              name='mis-ofertas-export-button'
              serverDownload={() => OfertasApi.export({ filter: filterValues })}
              getFileName={() => 'mis-ofertas.csv'}
              postDownloading={() =>
                addNotification({
                  message: 'Se descargo archivo de Ofertas',
                  type: 'info',
                })
              }
            />
          }
        />
      </div>

      <BymaDataTable
        key='mis-ofertas'
        columns={MIS_OFERTAS_COLUMNS}
        sizeColumnsToFit={true}
        style={{ height: '310px', width: '100%' }}
        pagination={false}
        sortable={false}
        queryProps={{
          dataProvider: (params) => OfertasApi.getOfertasPropias(params),
          listKey: LIST_KEY,
        }}
        listPaginationProps={{
          showLimits: false,
        }}
        suppressHorizontalScroll={true}
      />
    </BymaCard>
  )
}

export default () => (
  <ListBase
    listKey={LIST_KEY.join('.')}
    filterDefaultValues={{ ordenOfertas: OrdenOfertas.ULTIMA_OFERTA_INGRESADA }}
    perPage={50}
  >
    <MisOfertasTable />
  </ListBase>
)

import React, { useMemo } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'

import { BymaSelect, NumberInput, TextInput, BymaCard } from 'components'

import { Col, Row } from 'react-bootstrap'

import { TipoProrrateoCompetitivoOptions } from 'model'

import { getFieldsSectionCardStyleProps } from '../../utils'

const requiredWhenHabilitado = (yup) =>
  yup.number().when('habilitado', {
    is: true,
    then: (schema) => schema.required(),
  })

export const tituloTramosStepYupFields = (yup) => ({
  tramoCompetitivo: yup.object({
    tickDePrecio: requiredWhenHabilitado(yup),
    multiploOferta: requiredWhenHabilitado(yup),
  }),

  tramoNoCompetitivo: yup.object({
    multiploOferta: yup.number().required(),
    porcentajeMaxAdj: yup.number().required(),
  }),
})

export const tituloTramosStepYup = (yup) => yup.object(tituloTramosStepYupFields(yup))

export const tramosStepFieldNames = [
  'tramoCompetitivo.cantidadMinimaOferta',
  'tramoCompetitivo.cantidadMaximaOferta',
  'tramoCompetitivo.multiploOferta',
  'tramoCompetitivo.precioMinimoOferta',
  'tramoCompetitivo.precioMaximoOferta',
  'tramoCompetitivo.montoMinimoAdj',
  'tramoCompetitivo.unidadProrrateo',
  'tramoCompetitivo.tickDePrecio',
  'tramoCompetitivo.codigoApi',

  'tipoProrrateo',

  'tramoNoCompetitivo.cantidadMinimaOferta',
  'tramoNoCompetitivo.cantidadMaximaOferta',
  'tramoNoCompetitivo.multiploOferta',
  'tramoNoCompetitivo.montoMinimoAdj',
  'tramoNoCompetitivo.unidadProrrateo',
  'tramoNoCompetitivo.porcentajeMaxAdj',
  'tramoNoCompetitivo.codigoApi',
  'tramoNoCompetitivo.precioApi',
]

export const TituloTramoCompetitivoStepFields = ({ isNew, editEnabled }) => {
  const cardStyleProps = useMemo(() => getFieldsSectionCardStyleProps(isNew), [isNew])

  const readOnly = !editEnabled

  const formContext = useFormContext()
  const control = formContext ? formContext.control : null
  const tramoCompetitivoHabilitado = control
    ? useWatch({ control, name: 'tramoCompetitivo.habilitado' })
    : false

  return (
    <BymaCard {...cardStyleProps} title={'Tramo competitivo'}>
      <Row className='mb-3'>
        <Col sm={3}>
          <NumberInput
            name='tramoCompetitivo.cantidadMinimaOferta'
            label='Cantidad mínima de oferta'
            readOnly={readOnly || !tramoCompetitivoHabilitado}
          />
        </Col>
        <Col sm={3}>
          <NumberInput
            name='tramoCompetitivo.cantidadMaximaOferta'
            label='Cantidad máxima de oferta'
            readOnly={readOnly || !tramoCompetitivoHabilitado}
          />
        </Col>
        <Col sm={3}>
          <NumberInput
            name='tramoCompetitivo.multiploOferta'
            label='Múltiplo de oferta'
            required
            readOnly={readOnly || !tramoCompetitivoHabilitado}
          />
        </Col>
      </Row>

      <Row className='mb-3'>
        <Col sm={3}>
          <NumberInput
            name='tramoCompetitivo.precioMinimoOferta'
            label='Valor mínimo de oferta'
            readOnly={readOnly || !tramoCompetitivoHabilitado}
          />
        </Col>
        <Col sm={3}>
          <NumberInput
            name='tramoCompetitivo.precioMaximoOferta'
            label='Valor máximo de oferta'
            readOnly={readOnly || !tramoCompetitivoHabilitado}
          />
        </Col>
        <Col sm={3}>
          <NumberInput
            name='tramoCompetitivo.tickDePrecio'
            label='Tick de precio'
            required
            readOnly={readOnly || !tramoCompetitivoHabilitado}
          />
        </Col>
      </Row>

      <Row className='mb-3'>
        <Col sm={3}>
          <NumberInput
            name='tramoCompetitivo.montoMinimoAdj'
            label='Cantidad mínima de adjudicación'
            readOnly={readOnly || !tramoCompetitivoHabilitado}
          />
        </Col>
        <Col sm={3}>
          <NumberInput
            name='tramoCompetitivo.unidadProrrateo'
            label='Múltiplo de adjudicación'
            readOnly={readOnly || !tramoCompetitivoHabilitado}
          />
        </Col>
        <Col sm={3}>
          <BymaSelect
            name='tipoProrrateo'
            label='Tipo prorrateo'
            required={true}
            options={TipoProrrateoCompetitivoOptions}
            readOnly={readOnly || !tramoCompetitivoHabilitado}
          />
        </Col>
      </Row>

      <Row className='mb-3'>
        <Col sm={3}>
          <TextInput
            name='tramoCompetitivo.codigoApi'
            label='Código SIOPEL'
            readOnly={readOnly || !tramoCompetitivoHabilitado}
          />
        </Col>
      </Row>
    </BymaCard>
  )
}

export const TituloTramoNoCompetitivoStepFields = ({ isNew, editEnabled }) => {
  const cardStyleProps = useMemo(() => getFieldsSectionCardStyleProps(isNew), [isNew])

  const readOnly = !editEnabled

  return (
    <BymaCard {...cardStyleProps} title={'Tramo no competitivo'}>
      <Row className='mb-3'>
        <Col sm={3}>
          <NumberInput
            name='tramoNoCompetitivo.cantidadMinimaOferta'
            label='Cantidad mínima de oferta'
            readOnly={readOnly}
          />
        </Col>
        <Col sm={3}>
          <NumberInput
            name='tramoNoCompetitivo.cantidadMaximaOferta'
            label='Cantidad máxima de oferta'
            readOnly={readOnly}
          />
        </Col>
        <Col sm={3}>
          <NumberInput
            name='tramoNoCompetitivo.multiploOferta'
            label='Múltiplo de oferta'
            required
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row className='mb-3'>
        <Col sm={3}>
          <NumberInput
            name='tramoNoCompetitivo.montoMinimoAdj'
            label='Cantidad mínima de adjudicación'
            readOnly={readOnly}
          />
        </Col>
        <Col sm={3}>
          <NumberInput
            name='tramoNoCompetitivo.unidadProrrateo'
            label='Múltiplo de adjudicación'
            readOnly={readOnly}
          />
        </Col>

        <Col sm={3}>
          <NumberInput
            name='tramoNoCompetitivo.porcentajeMaxAdj'
            label='Adjudicación máxima en %'
            required={true}
            readOnly={readOnly}
          />
        </Col>
      </Row>

      <Row className='mb-3'>
        <Col sm={3}>
          <TextInput
            name='tramoNoCompetitivo.codigoApi'
            label='Código SIOPEL'
            readOnly={readOnly}
          />
        </Col>

        <Col sm={3}>
          <NumberInput
            name='tramoNoCompetitivo.precioApi'
            label='Precio SIOPEL'
            readOnly={readOnly}
          />
        </Col>
      </Row>
    </BymaCard>
  )
}

export const TituloTramosStepFields = (props) => {
  return (
    <>
      <TituloTramoCompetitivoStepFields {...props} />
      <TituloTramoNoCompetitivoStepFields {...props} />
    </>
  )
}

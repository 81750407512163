import React, { useCallback, useMemo } from 'react'

import { Col, Row } from 'react-bootstrap'

import ColocacionesApi from 'model/colocacion/ColocacionesApi'
import { TitulosApi } from 'model/titulo'

import {
  BymaButton,
  BymaDataTable,
  BymaExportButton,
  BymaTableColumn,
  AddIcon,
  VisibilityIcon,
  BymaNavigateButton,
  BymaCard,
  LocalErrorAlert,
} from 'components'

import TituloEditIcon from 'assets/img/colocacion-view/titulo_edit.svg'
import { ColocacionSummary } from 'features/Colocacion/ColocacionSummary'

import {
  COLOCACION_ENTITY_NAME,
  tituloTipoValorFormatter,
  tituloTotalEmisiomFormatter,
} from 'model'
import { formatDateNoYearWithHHMMSS } from 'utils/date'

import { ColocacionSectionCard } from './ColocacionSectionCard'
import { ColocacionSectionType, useColocacionEditContext } from '../ColocacionViewContext'

import { DeleteEntityModal, useDeleteButtonCell, useEntityContext } from 'features/common/Entity'
import { LocalErrorProvider } from 'core'

const TitulosSectionContent = ({
  listKey,
  queryKey,
  colocacionId,
  setSection,
  entityContext,
  readOnly,
}) => {
  const dataProvider = useCallback(
    (params) => ColocacionesApi.getTitulos(colocacionId, params),
    [colocacionId],
  )

  const tituloColumns: BymaTableColumn[] = useMemo(
    () => [
      {
        field: 'denominacion',
        headerName: 'Denominación del Título',
        textAlign: 'left',
      },
      {
        field: 'totalEmision',
        headerName: 'Total Emisión',
        textAlign: 'left',
        valueFormatter: (params) => tituloTotalEmisiomFormatter(params?.data?.totalEmision),
      },
      {
        field: 'monedaEmision',
        headerName: 'Moneda de Emisión',
        textAlign: 'left',
      },
      {
        field: 'tipoValor',
        headerName: 'Variable de Licitación',
        textAlign: 'left',
        valueFormatter: (params) => tituloTipoValorFormatter(params?.data),
      },
      {
        field: 'fechaLiquidacion',
        headerName: 'Fecha de Liquidación',
        textAlign: 'left',
        valueFormatter: (params) => formatDateNoYearWithHHMMSS(params?.data?.fechaLiquidacion),
      },
      {
        field: 'detail-action',
        headerName: 'Ver y Editar',
        cellClass: 'action-button',
        cellRenderer: BymaNavigateButton,
        cellRendererParams: (params) => {
          return {
            label: '',
            icon: VisibilityIcon,
            onClick: () => {
              setSection(ColocacionSectionType.TITULO, { tituloId: params.data?.id })
            },
          }
        },
        minWidth: 50,
        maxWidth: 80,
      },

      useDeleteButtonCell({ context: entityContext, hide: readOnly }),
    ],

    [entityContext, readOnly],
  )

  return (
    <BymaCard titleBorder='none' footer={<></>}>
      <Row sm={12} className='mb-3'>
        <Col sm={12} style={{ display: 'flex', justifyContent: 'flex-end', gap: '2%' }}>
          {!readOnly && (
            <BymaButton
              id={'colocacion-nuevo-titulo'}
              label='Nuevo Título'
              name={'colocacion-nuevo-titulo'}
              iconLeft={AddIcon}
              iconStyles={{ width: 12 }}
              onClick={() => {
                setSection(ColocacionSectionType.CREATE_TITULO, {})
              }}
            />
          )}

          <BymaExportButton
            id='colocacion-titulos-export-button'
            name='colocacion-titulos-export-button'
            serverDownload={() => ColocacionesApi.export({})}
            getFileName={() => 'titulos.csv'}
            postDownloading={
              () => {}
              /*addNotification({
                message: 'Se descargo archivo de Titulos',
                type: 'info',
              })*/
            }
          />
        </Col>
      </Row>
      <Row sm={12} className='mb-3'>
        <Col sm={12}>
          <BymaDataTable
            key='documentos-colocacion'
            columns={tituloColumns}
            sizeColumnsToFit={true}
            style={{ height: '310px', width: '100%' }}
            pagination={false}
            sortable={false}
            showLoadingOverlay={false}
            queryProps={{
              dataProvider,
              listKey,
              queryKey,
            }}
            listPaginationProps={{
              showLimits: false,
            }}
            suppressHorizontalScroll={true}
          />
        </Col>
      </Row>

      <Row className='mt-2'>
        <LocalErrorAlert />
      </Row>
    </BymaCard>
  )
}

export const TitulosSection = () => {
  const props = useColocacionEditContext()

  const DOCUMENTOS_LIST_CLASSIFIER = 'colocacion.titulos'
  const LIST_KEY = [COLOCACION_ENTITY_NAME, DOCUMENTOS_LIST_CLASSIFIER]
  const QUERY_KEY = [...LIST_KEY, { id: props.colocacionId }]

  const entityContext = useEntityContext('titulo', 'Titulo', QUERY_KEY)

  return (
    <ColocacionSectionCard title={'Titulos'} icon={TituloEditIcon} showFooter={false}>
      <Row className='mb-3'>
        <ColocacionSummary {...props} />
      </Row>
      <Row>
        <LocalErrorProvider>
          <TitulosSectionContent
            listKey={LIST_KEY}
            queryKey={QUERY_KEY}
            colocacionId={props.colocacionId}
            setSection={props.setSection}
            entityContext={entityContext}
            readOnly={props.readOnly}
          />
        </LocalErrorProvider>
      </Row>
      <DeleteEntityModal context={entityContext} api={TitulosApi} formId={''} />
    </ColocacionSectionCard>
  )
}

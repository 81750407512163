import AxiosMockAdapter from 'axios-mock-adapter-path-params'
import { X_TOTAL_COUNT_HEADER } from 'services/api'

const ofertaImportResponse = {
  id: 480740,
  persistenceVersion: 3,
  fechaAlta: '2023-04-24T17:32:37.355+00:00',
  usuarioAlta: 'cshanley',
  estado: 'ERROR',
  colocacionId: 2561,

  ofertas: [
    {
      id: 480741,
      persistenceVersion: 2,
      posicion: 1,
      estado: 'WARNING',

      warnings: [
        {
          field: null,
          message: 'El valor ingresado tiene una variacion mayor al 10% con respecto a la TASA V/N',
          errorCode: null,
        },
      ],
      errors: [],
      oferta: {
        id: 480741,
        persistenceVersion: null,
        propia: false,
        fechaAlta: null,
        usuarioAlta: null,
        tituloId: 480416,
        tituloDenominacion: null,
        colocacionId: 2561,
        colocacionDenominacion: null,
        colocacionEstado: null,
        tipoOferta: 'COMPETITIVA',
        cuenta: 'CUIT',
        origenOferta: null,
        valor: 1,
        tipoValor: 'TASA',
        cantidad: 5000000,
        cantidadAdjudicada: null,
        estado: 'VIGENTE',
        estadoGroup: 'VIGENTE',
        estadoDetail: 'VIGENTE',
        agenteId: 92607,
        agenteIdOrganizacion: 'AG.151',
        agenteNombreOrganizacion: 'MAV - INVERTIR ONLINE S.A.',
        porcentajeInversion: null,
        motivoBaja: null,
        deTerceros: true,
        numeroCliente: null,
        observaciones: null,
        cuit: null,
        nombreCliente: null,
        tipoPersona: null,
        integracionTituloId: 480417,
        integracionTituloDenominacion: null,
        timestampModificacion: null,
        timestampConfirmacion: null,
        usuarioConfirmacion: null,
        clienteId: null,
        clase: null,
        secuenciaMae: null,
        respuestaMae: null,
        mensajeMae: null,
        secuenciaSiopel: null,
        secuenciaSiopelOriginal: null,
        grupoEconomicoId: null,
        nacionalidadClienteId: 89085,
        categoriaClienteId: null,
        montoALiquidar: null,
        valorAdjudicado: null,
        duenioPorcentajeInversion: null,
        interaccionSiopel: false,
        monedaEmision: 'PESOS',
      },
    },

    {
      id: 480812,
      persistenceVersion: 2,
      posicion: 2,
      estado: 'ERROR',
      warnings: null,
      errors: [
        {
          field: 'cantidad',
          message: 'El valor mínimo permitido es 0',
          errorCode: 'min',
        },
        {
          field: 'cantidad',
          message: 'El valor debe ser mayor que cero',
          errorCode: 'min',
        },
      ],
      oferta: {
        id: 480812,
        persistenceVersion: null,
        propia: false,
        fechaAlta: null,
        usuarioAlta: null,
        tituloId: 480416,
        tituloDenominacion: null,
        colocacionId: 2561,
        colocacionDenominacion: null,
        colocacionEstado: null,
        tipoOferta: 'COMPETITIVA',
        cuenta: 'CUIT',
        origenOferta: null,
        valor: 1,
        tipoValor: 'TASA',
        cantidad: 0,
        cantidadAdjudicada: null,
        estado: 'VIGENTE',
        estadoGroup: 'VIGENTE',
        estadoDetail: 'VIGENTE',
        agenteId: 92607,
        agenteIdOrganizacion: 'AG.151',
        agenteNombreOrganizacion: 'MAV - INVERTIR ONLINE S.A.',
        porcentajeInversion: null,
        motivoBaja: null,
        deTerceros: true,
        numeroCliente: null,
        observaciones: null,
        cuit: null,
        nombreCliente: null,
        tipoPersona: null,
        integracionTituloId: 480417,
        integracionTituloDenominacion: null,
        timestampModificacion: null,
        timestampConfirmacion: null,
        usuarioConfirmacion: null,
        clienteId: null,
        clase: null,
        secuenciaMae: null,
        respuestaMae: null,
        mensajeMae: null,
        secuenciaSiopel: null,
        secuenciaSiopelOriginal: null,
        grupoEconomicoId: null,
        nacionalidadClienteId: 89085,
        categoriaClienteId: null,
        montoALiquidar: null,
        valorAdjudicado: null,
        duenioPorcentajeInversion: null,
        interaccionSiopel: false,
        monedaEmision: 'PESOS',
      },
    },

    {
      id: 480743,
      persistenceVersion: 3,
      posicion: 3,
      estado: 'WARNING',
      warnings: [
        {
          field: null,
          message: [
            'El valor ingresado tiene una variacion mayor al 10% con respecto a la TASA V/N',
            'El valor ingresado debe ser numerico'
          ],
          errorCode: null,
        },
      ],
      errors: [],

      oferta: {
        id: 480743,
        persistenceVersion: null,
        propia: false,
        fechaAlta: null,
        usuarioAlta: null,
        tituloId: 480416,
        tituloDenominacion: null,
        colocacionId: 2561,
        colocacionDenominacion: null,
        colocacionEstado: null,
        tipoOferta: 'COMPETITIVA',
        cuenta: 'CUIT',
        origenOferta: null,
        valor: 1,
        tipoValor: 'TASA',
        cantidad: 5000000,
        cantidadAdjudicada: null,
        estado: 'VIGENTE',
        estadoGroup: 'VIGENTE',
        estadoDetail: 'VIGENTE',
        agenteId: 92607,
        agenteIdOrganizacion: 'AG.151',
        agenteNombreOrganizacion: 'MAV - INVERTIR ONLINE S.A.',
        porcentajeInversion: null,
        motivoBaja: null,
        deTerceros: true,
        numeroCliente: null,
        observaciones: null,
        cuit: null,
        nombreCliente: null,
        tipoPersona: null,
        integracionTituloId: 480417,
        integracionTituloDenominacion: null,
        timestampModificacion: null,
        timestampConfirmacion: null,
        usuarioConfirmacion: null,
        clienteId: null,
        clase: null,
        secuenciaMae: null,
        respuestaMae: null,
        mensajeMae: null,
        secuenciaSiopel: null,
        secuenciaSiopelOriginal: null,
        grupoEconomicoId: null,
        nacionalidadClienteId: 89085,
        categoriaClienteId: null,
        montoALiquidar: null,
        valorAdjudicado: null,
        duenioPorcentajeInversion: null,
        interaccionSiopel: false,
        monedaEmision: 'PESOS',
      },
    },

    {
      id: 480744,
      persistenceVersion: 3,
      posicion: 4,
      estado: 'ERROR',
      warnings: null,
      errors: [
        {
          field: 'valor',
          message: ['El valor maximo permitido es 100', 'El valor debe ser menor de 400000'],
          errorCode: 'min',
        }
      ],

      oferta: {
        id: 480744,
        persistenceVersion: null,
        propia: false,
        fechaAlta: null,
        usuarioAlta: null,
        tituloId: 480416,
        tituloDenominacion: null,
        colocacionId: 2561,
        colocacionDenominacion: null,
        colocacionEstado: null,
        tipoOferta: 'COMPETITIVA',
        cuenta: 'CUIT',
        origenOferta: null,
        valor: 1,
        tipoValor: 'TASA',
        cantidad: 5000000,
        cantidadAdjudicada: null,
        estado: 'VIGENTE',
        estadoGroup: 'VIGENTE',
        estadoDetail: 'VIGENTE',
        agenteId: 92607,
        agenteIdOrganizacion: 'AG.151',
        agenteNombreOrganizacion: 'MAV - INVERTIR ONLINE S.A.',
        porcentajeInversion: null,
        motivoBaja: null,
        deTerceros: true,
        numeroCliente: null,
        observaciones: null,
        cuit: null,
        nombreCliente: null,
        tipoPersona: null,
        integracionTituloId: 480417,
        integracionTituloDenominacion: null,
        timestampModificacion: null,
        timestampConfirmacion: null,
        usuarioConfirmacion: null,
        clienteId: null,
        clase: null,
        secuenciaMae: null,
        respuestaMae: null,
        mensajeMae: null,
        secuenciaSiopel: null,
        secuenciaSiopelOriginal: null,
        grupoEconomicoId: null,
        nacionalidadClienteId: 89085,
        categoriaClienteId: null,
        montoALiquidar: null,
        valorAdjudicado: null,
        duenioPorcentajeInversion: null,
        interaccionSiopel: false,
        monedaEmision: 'PESOS',
      },
    },

    {
      id: 480745,
      persistenceVersion: 3,
      posicion: 5,
      estado: 'OK',
      warnings: [],
      errors: [],

      oferta: {
        id: 480745,
        persistenceVersion: null,
        propia: false,
        fechaAlta: null,
        usuarioAlta: null,
        tituloId: 480416,
        tituloDenominacion: null,
        colocacionId: 2561,
        colocacionDenominacion: null,
        colocacionEstado: null,
        tipoOferta: 'COMPETITIVA',
        cuenta: 'CUIT',
        origenOferta: null,
        valor: 1,
        tipoValor: 'TASA',
        cantidad: 5000000,
        cantidadAdjudicada: null,
        estado: 'VIGENTE',
        estadoGroup: 'VIGENTE',
        estadoDetail: 'VIGENTE',
        agenteId: 92607,
        agenteIdOrganizacion: 'AG.151',
        agenteNombreOrganizacion: 'MAV - INVERTIR ONLINE S.A.',
        porcentajeInversion: null,
        motivoBaja: null,
        deTerceros: true,
        numeroCliente: null,
        observaciones: null,
        cuit: null,
        nombreCliente: null,
        tipoPersona: null,
        integracionTituloId: 480417,
        integracionTituloDenominacion: null,
        timestampModificacion: null,
        timestampConfirmacion: null,
        usuarioConfirmacion: null,
        clienteId: null,
        clase: null,
        secuenciaMae: null,
        respuestaMae: null,
        mensajeMae: null,
        secuenciaSiopel: null,
        secuenciaSiopelOriginal: null,
        grupoEconomicoId: null,
        nacionalidadClienteId: 89085,
        categoriaClienteId: null,
        montoALiquidar: null,
        valorAdjudicado: null,
        duenioPorcentajeInversion: null,
        interaccionSiopel: false,
        monedaEmision: 'PESOS',
      },
    },

    {
      id: 480746,
      persistenceVersion: 3,
      posicion: 6,
      estado: 'OK',
      warnings: [],
      errors: [],

      oferta: {
        id: 480746,
        persistenceVersion: null,
        propia: false,
        fechaAlta: null,
        usuarioAlta: null,
        tituloId: 480416,
        tituloDenominacion: null,
        colocacionId: 2561,
        colocacionDenominacion: null,
        colocacionEstado: null,
        tipoOferta: 'COMPETITIVA',
        cuenta: 'CUIT',
        origenOferta: null,
        valor: 1,
        tipoValor: 'TASA',
        cantidad: 5000000,
        cantidadAdjudicada: null,
        estado: 'VIGENTE',
        estadoGroup: 'VIGENTE',
        estadoDetail: 'VIGENTE',
        agenteId: 92607,
        agenteIdOrganizacion: 'AG.151',
        agenteNombreOrganizacion: 'MAV - INVERTIR ONLINE S.A.',
        porcentajeInversion: null,
        motivoBaja: null,
        deTerceros: true,
        numeroCliente: null,
        observaciones: null,
        cuit: null,
        nombreCliente: null,
        tipoPersona: null,
        integracionTituloId: 480417,
        integracionTituloDenominacion: null,
        timestampModificacion: null,
        timestampConfirmacion: null,
        usuarioConfirmacion: null,
        clienteId: null,
        clase: null,
        secuenciaMae: null,
        respuestaMae: null,
        mensajeMae: null,
        secuenciaSiopel: null,
        secuenciaSiopelOriginal: null,
        grupoEconomicoId: null,
        nacionalidadClienteId: 89085,
        categoriaClienteId: null,
        montoALiquidar: null,
        valorAdjudicado: null,
        duenioPorcentajeInversion: null,
        interaccionSiopel: false,
        monedaEmision: 'PESOS',
      },
    },

    {
      id: 480747,
      persistenceVersion: 3,
      posicion: 7,
      estado: 'OK',
      warnings: [],
      errors: [],

      oferta: {
        id: 480747,
        persistenceVersion: null,
        propia: false,
        fechaAlta: null,
        usuarioAlta: null,
        tituloId: 480416,
        tituloDenominacion: null,
        colocacionId: 2561,
        colocacionDenominacion: null,
        colocacionEstado: null,
        tipoOferta: 'COMPETITIVA',
        cuenta: 'CUIT',
        origenOferta: null,
        valor: 1,
        tipoValor: 'TASA',
        cantidad: 5000000,
        cantidadAdjudicada: null,
        estado: 'VIGENTE',
        estadoGroup: 'VIGENTE',
        estadoDetail: 'VIGENTE',
        agenteId: 92607,
        agenteIdOrganizacion: 'AG.151',
        agenteNombreOrganizacion: 'MAV - INVERTIR ONLINE S.A.',
        porcentajeInversion: null,
        motivoBaja: null,
        deTerceros: true,
        numeroCliente: null,
        observaciones: null,
        cuit: null,
        nombreCliente: null,
        tipoPersona: null,
        integracionTituloId: 480417,
        integracionTituloDenominacion: null,
        timestampModificacion: null,
        timestampConfirmacion: null,
        usuarioConfirmacion: null,
        clienteId: null,
        clase: null,
        secuenciaMae: null,
        respuestaMae: null,
        mensajeMae: null,
        secuenciaSiopel: null,
        secuenciaSiopelOriginal: null,
        grupoEconomicoId: null,
        nacionalidadClienteId: 89085,
        categoriaClienteId: null,
        montoALiquidar: null,
        valorAdjudicado: null,
        duenioPorcentajeInversion: null,
        interaccionSiopel: false,
        monedaEmision: 'PESOS',
      },
    },

    {
      id: 480748,
      persistenceVersion: 3,
      posicion: 8,
      estado: 'OK',
      warnings: [],
      errors: [],

      oferta: {
        id: 480748,
        persistenceVersion: null,
        propia: false,
        fechaAlta: null,
        usuarioAlta: null,
        tituloId: 480416,
        tituloDenominacion: null,
        colocacionId: 2561,
        colocacionDenominacion: null,
        colocacionEstado: null,
        tipoOferta: 'COMPETITIVA',
        cuenta: 'CUIT',
        origenOferta: null,
        valor: 1,
        tipoValor: 'TASA',
        cantidad: 5000000,
        cantidadAdjudicada: null,
        estado: 'VIGENTE',
        estadoGroup: 'VIGENTE',
        estadoDetail: 'VIGENTE',
        agenteId: 92607,
        agenteIdOrganizacion: 'AG.151',
        agenteNombreOrganizacion: 'MAV - INVERTIR ONLINE S.A.',
        porcentajeInversion: null,
        motivoBaja: null,
        deTerceros: true,
        numeroCliente: null,
        observaciones: null,
        cuit: null,
        nombreCliente: null,
        tipoPersona: null,
        integracionTituloId: 480417,
        integracionTituloDenominacion: null,
        timestampModificacion: null,
        timestampConfirmacion: null,
        usuarioConfirmacion: null,
        clienteId: null,
        clase: null,
        secuenciaMae: null,
        respuestaMae: null,
        mensajeMae: null,
        secuenciaSiopel: null,
        secuenciaSiopelOriginal: null,
        grupoEconomicoId: null,
        nacionalidadClienteId: 89085,
        categoriaClienteId: null,
        montoALiquidar: null,
        valorAdjudicado: null,
        duenioPorcentajeInversion: null,
        interaccionSiopel: false,
        monedaEmision: 'PESOS',
      },
    },

    {
      id: 480749,
      persistenceVersion: 3,
      posicion: 9,
      estado: 'OK',
      warnings: [],
      errors: [],

      oferta: {
        id: 480749,
        persistenceVersion: null,
        propia: false,
        fechaAlta: null,
        usuarioAlta: null,
        tituloId: 480416,
        tituloDenominacion: null,
        colocacionId: 2561,
        colocacionDenominacion: null,
        colocacionEstado: null,
        tipoOferta: 'COMPETITIVA',
        cuenta: 'CUIT',
        origenOferta: null,
        valor: 1,
        tipoValor: 'TASA',
        cantidad: 5000000,
        cantidadAdjudicada: null,
        estado: 'VIGENTE',
        estadoGroup: 'VIGENTE',
        estadoDetail: 'VIGENTE',
        agenteId: 92607,
        agenteIdOrganizacion: 'AG.151',
        agenteNombreOrganizacion: 'MAV - INVERTIR ONLINE S.A.',
        porcentajeInversion: null,
        motivoBaja: null,
        deTerceros: true,
        numeroCliente: null,
        observaciones: null,
        cuit: null,
        nombreCliente: null,
        tipoPersona: null,
        integracionTituloId: 480417,
        integracionTituloDenominacion: null,
        timestampModificacion: null,
        timestampConfirmacion: null,
        usuarioConfirmacion: null,
        clienteId: null,
        clase: null,
        secuenciaMae: null,
        respuestaMae: null,
        mensajeMae: null,
        secuenciaSiopel: null,
        secuenciaSiopelOriginal: null,
        grupoEconomicoId: null,
        nacionalidadClienteId: 89085,
        categoriaClienteId: null,
        montoALiquidar: null,
        valorAdjudicado: null,
        duenioPorcentajeInversion: null,
        interaccionSiopel: false,
        monedaEmision: 'PESOS',
      },
    },

    {
      id: 480750,
      persistenceVersion: 3,
      posicion: 10,
      estado: 'OK',
      warnings: [],
      errors: [],

      oferta: {
        id: 480750,
        persistenceVersion: null,
        propia: false,
        fechaAlta: null,
        usuarioAlta: null,
        tituloId: 480416,
        tituloDenominacion: null,
        colocacionId: 2561,
        colocacionDenominacion: null,
        colocacionEstado: null,
        tipoOferta: 'COMPETITIVA',
        cuenta: 'CUIT',
        origenOferta: null,
        valor: 1,
        tipoValor: 'TASA',
        cantidad: 5000000,
        cantidadAdjudicada: null,
        estado: 'VIGENTE',
        estadoGroup: 'VIGENTE',
        estadoDetail: 'VIGENTE',
        agenteId: 92607,
        agenteIdOrganizacion: 'AG.151',
        agenteNombreOrganizacion: 'MAV - INVERTIR ONLINE S.A.',
        porcentajeInversion: null,
        motivoBaja: null,
        deTerceros: true,
        numeroCliente: null,
        observaciones: null,
        cuit: null,
        nombreCliente: null,
        tipoPersona: null,
        integracionTituloId: 480417,
        integracionTituloDenominacion: null,
        timestampModificacion: null,
        timestampConfirmacion: null,
        usuarioConfirmacion: null,
        clienteId: null,
        clase: null,
        secuenciaMae: null,
        respuestaMae: null,
        mensajeMae: null,
        secuenciaSiopel: null,
        secuenciaSiopelOriginal: null,
        grupoEconomicoId: null,
        nacionalidadClienteId: 89085,
        categoriaClienteId: null,
        montoALiquidar: null,
        valorAdjudicado: null,
        duenioPorcentajeInversion: null,
        interaccionSiopel: false,
        monedaEmision: 'PESOS',
      },
    },

    {
      id: 480751,
      persistenceVersion: 3,
      posicion: 11,
      estado: 'OK',
      warnings: [],
      errors: [],

      oferta: {
        id: 480751,
        persistenceVersion: null,
        propia: false,
        fechaAlta: null,
        usuarioAlta: null,
        tituloId: 480416,
        tituloDenominacion: null,
        colocacionId: 2561,
        colocacionDenominacion: null,
        colocacionEstado: null,
        tipoOferta: 'COMPETITIVA',
        cuenta: 'CUIT',
        origenOferta: null,
        valor: 1,
        tipoValor: 'TASA',
        cantidad: 5000000,
        cantidadAdjudicada: null,
        estado: 'VIGENTE',
        estadoGroup: 'VIGENTE',
        estadoDetail: 'VIGENTE',
        agenteId: 92607,
        agenteIdOrganizacion: 'AG.151',
        agenteNombreOrganizacion: 'MAV - INVERTIR ONLINE S.A.',
        porcentajeInversion: null,
        motivoBaja: null,
        deTerceros: true,
        numeroCliente: null,
        observaciones: null,
        cuit: null,
        nombreCliente: null,
        tipoPersona: null,
        integracionTituloId: 480417,
        integracionTituloDenominacion: null,
        timestampModificacion: null,
        timestampConfirmacion: null,
        usuarioConfirmacion: null,
        clienteId: null,
        clase: null,
        secuenciaMae: null,
        respuestaMae: null,
        mensajeMae: null,
        secuenciaSiopel: null,
        secuenciaSiopelOriginal: null,
        grupoEconomicoId: null,
        nacionalidadClienteId: 89085,
        categoriaClienteId: null,
        montoALiquidar: null,
        valorAdjudicado: null,
        duenioPorcentajeInversion: null,
        interaccionSiopel: false,
        monedaEmision: 'PESOS',
      },
    },
  ],
}

const ofertasResponse_1 = [
  {
    id: 61412,
    persistenceVersion: 0,
    posicion: 1,
    warnings: null,
    errors: [
      {
        field: 'valor',
        message: 'El valor no es multiplo del Tick de Precio  (1.0000000)',
        errorCode: 'validation.error',
      },
    ],
    oferta: {
      id: null,
      persistenceVersion: null,
      propia: false,
      fechaAlta: null,
      usuarioAlta: null,
      tituloId: 216966,
      tituloDenominacion: null,
      colocacionId: 1386,
      colocacionDenominacion: null,
      colocacionEstado: null,
      tipoOferta: 'COMPETITIVA',
      cuenta: 'CUIT',
      origenOferta: null,
      valor: 10.5,
      tipoValor: 'TASA',
      cantidad: 600.0,
      cantidadAdjudicada: null,
      estado: 'NO_CONFIRMADA',
      estadoGroup: 'NO_CONFIRMADA',
      estadoDetail: 'NO_CONFIRMADA',
      agenteId: 125,
      agenteIdOrganizacion: 'AG.001',
      agenteNombreOrganizacion: 'ALDAZABAL Y CIA. S.A. SOCIEDAD DE BOLSA',
      porcentajeInversion: null,
      motivoBaja: null,
      deTerceros: true,
      numeroCliente: '1',
      observaciones: null,
      cuit: null,
      nombreCliente: 'Pablo Pastorino',
      tipoPersona: null,
      integracionTituloId: 1,
      integracionTituloDenominacion: null,
      timestampModificacion: null,
      timestampConfirmacion: null,
      usuarioConfirmacion: null,
      clienteId: null,
      clase: null,
      secuenciaMae: null,
      respuestaMae: null,
      mensajeMae: null,
      secuenciaSiopel: null,
      secuenciaSiopelOriginal: null,
      grupoEconomicoId: null,
      nacionalidadClienteId: 89085,
      categoriaClienteId: null,
      montoALiquidar: null,
      valorAdjudicado: null,
      duenioPorcentajeInversion: null,
      interaccionSiopel: false,
      monedaEmision: 'PESOS',
    },
    estado: 'ERROR',
  },
]

export const mockOfertasImport = (mock: AxiosMockAdapter) => {
  mock.onGet('/api/ofertas-import/:id').reply(() => {
    return [200, ofertaImportResponse]
  })

  mock.onPost('/api/ofertas-import').reply(() => {
    return [200, ofertaImportResponse]
  })

  mock.onPut('/api/ofertas-import/:id').reply(() => {
    return [200, ofertaImportResponse]
  })

  mock.onPost('/api/ofertas-import/:id/ofertas').reply(() => {
    return [200, ofertaImportResponse]
  })


  mock.onGet('/api/ofertas-import/:id/ofertas').reply((config: any) => {
    /*
    //return [200, ofertaImportResponse.ofertas]
    console.log('obteniendo ofertas')
    console.log(config)
    const params = config.params
    const { page,  sort, sortDir } = params
    const size = 5
    const total = ofertaImportResponse.ofertas.length
    const fromIndex = page * size
    const toIndex = (page * size) + size + 1
    console.log(fromIndex)
    console.log(toIndex)
    return [200, ofertaImportResponse.ofertas.slice(fromIndex, toIndex), { [X_TOTAL_COUNT_HEADER]: total }]
    */

    //TODO BP-349-import-preview
    return [200, ofertaImportResponse.ofertas, { [X_TOTAL_COUNT_HEADER]: ofertaImportResponse.ofertas.length }]
  })
}

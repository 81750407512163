import ColocadoresApi from './ColocadoresApi'

export interface Colocador extends DenominableEntity {
  organizacionDenominacion?: string
  organizacionId?: string
  cuit?: string
  direccion?: string
  situacionIva?: string
  email?: string
  telefono?: string
  contacto?: string
  tipoColocador?: string
  numeroCuenta?: string
  lugarLiquidacion?: string
}

export const COLOCADOR_OPTIONS_KEY = 'COLOCADOR_OPTIONS'

export const COLOCADOR_ENTITY_NAME = 'colocador'

export enum OrdenColocadores {
  DENOMINACION_ASC = 'denominacion_asc',
  DENOMINACION_DESC = 'denominacion_desc',
}

export const OrdenColocadoresOptions = [
  { label: '↑ Denominación', value: OrdenColocadores.DENOMINACION_ASC },
  { label: '↓ Denominación', value: OrdenColocadores.DENOMINACION_DESC },
].map((opt) => ({
  label: `Ordenar por ${opt.label}`,
  optionLabel: opt.label,
  value: opt.value,
}))

export const ColocadorOptionsQueryProps = {
  dataProvider: () => ColocadoresApi.getList(),
  listKey: COLOCADOR_OPTIONS_KEY,
}

export const LugarLiquidacionOptions = [
  { value: 'MERVAL', label: 'MERVAL' },
  { value: 'MAE', label: 'MAE' },
  { value: 'CENTRAL', label: 'CENTRAL' },
]

export const SituacionIvaOptions = [
  { value: 'CONSUMIDOR_FINAL', label: 'Consumidor Final' },
  { value: 'PROVEEDOR_EXTERIOR', label: 'Proveedor del Exterior' },
  { value: 'IVA_NO_ALCANZADO', label: 'IVA no alcanzado' },
  { value: 'RESPONSABLE_MONOTRIBUTISTA', label: 'Responsable Monotributo' },
  { value: 'RESPONSABLE_INSCRIPTO', label: 'Responsable Inscripto' },
  { value: 'SUJETO_EXENTO', label: 'Sujeto Exento' },
  { value: 'SUJETO_NO_CATEGORIZADO', label: 'Sujeto no Categorizado' },
]

export const TipoColocadorIvaOptions = [
  { value: 'BANCO', label: 'Banco' },
  { value: 'BANCO', label: 'Agente' },
  { value: 'OTRO', label: 'Otro' },
]

import React from 'react'
import AxiosMockAdapter from 'axios-mock-adapter-path-params'
import { HelpItem } from 'model/help'

export const helpResponse: HelpItem[] = [
  {
    id: 1,
    codigo: 'manual',
    link: 'www.youtube.com',
    configUi: {
      text:
        'Conocé más sobre nuestra aplicación.\n' +
        'Podrás encontrar todas las funcionalidades detalladas y los diferentes\n usos de la plataforma, ' +
        ' en el Manual del usuario de BYMA Primarias. ',
      buttonText: 'Descargar Manual de usuario',
      buttonIcon: 'send.svg',
      link: 'www.youtube.com',
    },
  },
  {
    id: 2,
    codigo: 'tutorial',
    link: 'www.youtube.com',
    configUi: {
      text:
        'Aprendé a manejar nuestra plataforma de la forma más sencilla.\n' +
        'Accedé a una demo grabada con definiciones de las principales\n funcionalidades ' +
        'y ejemplos de uso de la aplicación BYMA Primarias.',
      buttonText: 'ir al video',
      buttonIcon: 'send.svg',
      link: 'www.youtube.com',
    },
  },
  {
    id: 3,
    codigo: 'colocaciones',
    link: 'www.google.com',
    configUi: {
      text: 'Accedé a la información, documentación y listado de las colocaciones\n habilitadas en BYMA Primarias.',
      buttonText: 'Ir a colocaciones',
      buttonIcon: 'send.svg',
      link: 'www.google.com',
    },
  },
  {
    id: 4,
    codigo: 'aranceles',
    link: 'www.google.com',
    configUi: {
      text:
        'Conocé nuestros aranceles.\n' +
        ' Podrás encontrar nuestros derechos de mercado y membresías\n publicados en nuestra web institucional.',
      buttonText: 'Derechos y membresías',
      buttonIcon: 'send.svg',
      link: 'www.google.com',
    },
  },
  {
    id: 5,
    codigo: 'normativa',
    link: 'comunicado.pdf',
    configUi: {
      text:
        'Consultá el Comunicado N° 14306.\n' +
        'Expone las disposiciones que regulan el mercado primario en BYMA.',
      buttonText: 'Comunicado N° 14306',
      buttonIcon: 'send.svg',
      link: 'www.google.com',
    },
  },
  {
    id: 6,
    codigo: 'contacto',
    configUi: {
      contactosAtencion: ['ctc@tecval.com.ar', '+54 9 11 4317 8998'],
      contactosMercado: ['monitoreo@byma.com.ar'],
      text:
        'Consultas y soporte sobre la plataforma BYMA Primarias:\n' +
        'Centro de Atención a Usuarios: \n' +
        'Negociación y solicitudes de baja de operaciones: \n',
    },
  },
]

export const mockHelp = (mock: AxiosMockAdapter) => {
  mock.onGet('/api/ayuda').reply(() => {
    return [200, helpResponse]
  })

  mock.onPut('/api/ayuda/batch').reply(() => {
    return [200, helpResponse, {}]
  })
}

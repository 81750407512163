import React from 'react'
import {
  BymaCard,
  BymaDataTable,
  BymaNavigateButton,
  BymaTableBadgeRow,
  FlagIcon,
  AllMatchIcon,
} from 'components'

import { ListBase, LoadingProvider } from 'core'
import {
  ColocacionesApi,
  TITULO_ENTITY_NAME,
  ofertaCantidadFormatter,
  ofertaValorFormatter,
} from 'model'
import { ColocacionSectionType, ColocacionViewContext } from '../ColocacionViewContext'
interface TotalesGeneralesTituloProps {
  context: ColocacionViewContext
}

const TOTALES_TITULO_LIST_CLASSIFIER = 'colocacionBoard.totalesGeneralesTitulo'

const LIST_KEY = [TITULO_ENTITY_NAME, TOTALES_TITULO_LIST_CLASSIFIER]

const TotalesGeneralesTituloTable = (props: TotalesGeneralesTituloProps) => {
  const context = props.context

  const defaultCellWidth = 240
  const defaultNumberAlign = 'left'
  const defaultNumberHeaderAlign = 'left'

  const calculoTituloColumns = [
    {
      field: 'tituloDenominacion',
      headerName: 'Denominación Titulo',
      suppressMenu: false,
      textAlign: 'center',
      minWidth: 120,
      maxWidth: defaultCellWidth,
      flex: 1,
    },
    {
      field: 'totalEmision',
      headerName: 'Total emisión',
      suppressMenu: false,
      textAlign: defaultNumberAlign,
      headerTextAlign: defaultNumberHeaderAlign,
      valueFormatter: (params) => ofertaCantidadFormatter(params?.data?.totalEmision),

      maxWidth: defaultCellWidth,
      minWidth: 100,
      flex: 1,
    },
    {
      field: 'porcentajeOfertado',
      headerName: '% Ofertado/Emisión',
      suppressMenu: false,
      cellRenderer: BymaTableBadgeRow,
      cellRendererParams: (params) => {
        const value = params.data.porcentajeOfertado
        return {
          value: value ? ofertaValorFormatter(value) + '%' : null,
          type: 'grey',
          className: 'byma-badgePorcentajeOfertado',
        }
      },
      maxWidth: defaultCellWidth,
      minWidth: 100,
      flex: 1,
    },
    {
      field: 'totalCompetitivo',
      headerName: 'Total competitivo',
      suppressMenu: false,
      textAlign: defaultNumberAlign,
      headerTextAlign: defaultNumberHeaderAlign,
      valueFormatter: (params) => ofertaCantidadFormatter(params?.data?.totalCompetitivo),

      maxWidth: defaultCellWidth,
      minWidth: 100,
      flex: 1,
    },
    {
      field: 'tasaPromedio',
      headerName: 'Valor promedio',
      suppressMenu: false,
      textAlign: defaultNumberAlign,
      headerTextAlign: defaultNumberHeaderAlign,

      maxWidth: defaultCellWidth,
      minWidth: 100,
      flex: 1,
    },
    {
      field: 'totalNoCompetitivo',
      headerName: 'Total no competitivo',
      suppressMenu: false,
      textAlign: defaultNumberAlign,
      headerTextAlign: defaultNumberHeaderAlign,
      valueFormatter: (params) => ofertaCantidadFormatter(params?.data?.totalNoCompetitivo),

      maxWidth: defaultCellWidth,
      minWidth: 100,
      flex: 1,
    },
    {
      field: 'calculo-corte-action',
      headerName: 'Calculo corte',
      cellClass: 'action-button',
      wrapHeaderText: true,
      cellRenderer: BymaNavigateButton,
      cellRendererParams: (params) => {
        return {
          label: '',
          icon: FlagIcon,
          onClick: () => {
            context.setSection(ColocacionSectionType.TITULO_CALCULO_CORTE, {
              moveBack: true,
              tituloId: params.data?.tituloId,
            })
          },
        }
      },
      minWidth: 100,
      maxWidth: 260,
      flex: 1,
    },
    {
      field: 'ofertas-por-valor-action',
      headerName: 'Ofertas por valor',
      cellClass: 'action-button',
      wrapHeaderText: true,
      cellRenderer: BymaNavigateButton,
      cellRendererParams: (params) => {
        return {
          label: '',
          icon: AllMatchIcon,
          onClick: () => {
            context.setSection(ColocacionSectionType.TITULO_OFERTAS_POR_VALOR, {
              moveBack: true,
              tituloId: params.data?.tituloId,
            })
          },
        }
      },
      minWidth: 100,
      maxWidth: 260,
      flex: 1,
    },
  ]

  const queryKey = [...LIST_KEY, { titulo: context.colocacionId }]

  const colocacionId = context.colocacionId

  const dataProvider = () =>
    colocacionId !== undefined
      ? ColocacionesApi.totalesTitulo(colocacionId)
      : Promise.reject('No hay colocación definida')

  return (
    <BymaDataTable
      key='libro'
      columns={calculoTituloColumns}
      sizeColumnsToFit={true}
      style={{ width: '100%' }}
      pagination={false}
      suppressHorizontalScroll={true}
      sortable={false}
      autoHeight={true}
      queryProps={{
        dataProvider: dataProvider,
        queryKey,
      }}
    />
  )
}

export default (props: TotalesGeneralesTituloProps) => (
  <LoadingProvider>
    <ListBase listKey={LIST_KEY.join('.')}>
      <BymaCard key={'ofertas-colocacion-card'} header={{ title: 'Totales Generales por Titulo' }}>
        <TotalesGeneralesTituloTable {...props} />
      </BymaCard>
    </ListBase>
  </LoadingProvider>
)

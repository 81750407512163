import React, { CSSProperties, ReactNode, useEffect, useState, useMemo } from 'react'
import cloneDeep from 'lodash/cloneDeep'
import isEqual from 'lodash/isEqual'
import { AgGridReact } from 'ag-grid-react'
import { useListContext } from 'core'
import { BymaNavigateButton, withLoadingOverlay, BymaAlert } from 'components'

//import styles from './BymaTable.module.scss'
import './BymaTable.scss'

import i18n from 'i18n'
import { isNullOrUndefined } from 'utils/objects'
import { CellStyle, RowClassParams, RowStyle } from 'ag-grid-community'

/*
https://www.ag-grid.com/javascript-data-grid/grid-state/
https://www.ag-grid.com/javascript-data-grid/column-state/
*/
export interface BymaTableApi {
  setColumnsVisible: (columns: string | string[], visible: boolean) => void
}

export type TableProps = {
  key: string
  title?: string
  rowData: BaseEntity[] | undefined
  columns?: BymaTableColumn[]
  columnDefaults?: BymaTableColumnDefaults
  pagination?: boolean
  pageSize?: number
  sortable?: boolean
  isLoading: boolean
  isFetching: boolean
  isError: boolean
  error: string | null | unknown
  alwaysMultiSort?: boolean
  sizeColumnsToFit?: boolean
  suppressHorizontalScroll?: boolean
  style?: CSSProperties | undefined
  extras?: ReactNode
  rowBuffer?: number
  backgroundColor?: string
  context?: any
  noRowsText?: string
  showLoadingOverlay?: boolean
  suppressNoRowsOverlay?: boolean
  setApiRef?: (api: BymaTableApi) => void
  getRowStyle?: (params: RowClassParams) => RowStyle | undefined;
  autoHeight?: boolean
}

type ColumnFilterParams = {
  buttons: string[]
  debounceMs: number
}

type BymaTableColumnDefaults = {
  resizable?: boolean
  sortable?: boolean
  filterParams?: ColumnFilterParams
}

export type BymaTableColumn = {
  field: string
  comparator?: any
  filter?: any
  cellRenderer?: any
  cellRendererParams?: any
  disabled?: boolean
  cellClass?: string
  infoTooltip?: string
  suppressSizeToFit?: boolean
  maxWidth?: number
  minWidth?: number
  width?: number
  wrapHeaderText?: boolean
  headerName?: string
  headerTooltip?: string
  textAlign?: string
  flex?: number

  //https://www.ag-grid.com/react-data-grid/cell-styles/
  cellStyle?: CellStyle
  headerClass?: string

  unSortIcon?: boolean

  valueFormatter?: any
  hide?: boolean
  disabledHide?: boolean
  sortable?: boolean

  //sortingOrder es una lista de 'asc' | 'desc' | null para controlar como se modifica el sort segun los click
  //sortingOrder?: any
  //sort?: any
}

const prepareColumn = (column: BymaTableColumn): BymaTableColumn => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { textAlign = 'center', ...validColumnProperties } = column

  const { cellStyle = {}, wrapHeaderText = false } = column

  const baseStyle = cellStyle
  const bymaCellStyle = {
    ...baseStyle,
    textAlign: textAlign,
  }

  //console.log( `DEBUG BymaTable prepareColumn ${column.field} cellStyle=${JSON.stringify(cellStyle)}`, )

  return {
    ...validColumnProperties,
    cellStyle: bymaCellStyle,
    wrapHeaderText: wrapHeaderText,
    headerClass: 'text-' + textAlign,
  }

  /*if (column.textAlign) {
    const baseStyle = column.cellStyle || {}
    const cellStyle = {
      ...baseStyle,
      textAlign: column.textAlign
    }

    //console.log( `DEBUG BymaTable prepareColumn ${column.field} cellStyle=${JSON.stringify(cellStyle)}`, )

    return {
      ...column,
      cellStyle,
      wrapHeaderText: column.wrapHeaderText,
      headerClass: 'text-' + column.textAlign,
    }
  }

  return column*/
}

const prepareColumns = (columns: BymaTableColumn[]): BymaTableColumn[] => {
  return columns.map((c) => prepareColumn(c)).filter((c) => !c.disabled)
}

const applyListColumnOrder = (
  columns: BymaTableColumn[],
  columnOrder: ColumnOrderPayload,
): BymaTableColumn[] => {
  //console.log(`DEBUG BymaTable applyListColumnOrder columnOrder=${JSON.stringify(columnOrder)}`)

  if (!columnOrder.columnOrder) {
    return columns
  }

  const findColumn = (id: string) => columns.find((c) => c.field === id)

  const ids: string[] = columnOrder.columnOrder

  return ids
    .map((id) => findColumn(id))
    .filter((c) => c !== undefined)
    .map((c) => c as BymaTableColumn)
    .map((c) => ({ ...c, hide: false }))
}

const applyColumnState = (columns: BymaTableColumn[], columnStates: any): BymaTableColumn[] => {
  if (!columnStates) {
    return columns
  }

  const findColumn = (id: string) => columnStates.find((c) => c.colId === id)

  return columns.map((column) => {
    const colState = findColumn(column.field)

    if (colState) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { sort, ...supportedColProperties } = colState
      return { ...column, ...supportedColProperties }
    } else {
      return column
    }
  })
}

// para delegar el ordenamiento en el server se anulan los comparadores
const prepareServerSideModel = (columnDefaults) => {
  columnDefaults.comparator = () => 0

  /*
    const sortColumn = columns.find( c => c.field === sort.field)
    // See https://www.ag-grid.com/javascript-data-grid/column-state/
    if(sortColumn){
        sortColumn.sort= sort.order ? sort.order.toLowerCase : 'asc'
    }
    */
}

const applyColumnsFixes = (columns: BymaTableColumn[]) => {
  // Icono info columns header
  return columns.map((col) => {
    if (col.headerTooltip && col.headerName) {
      return {
        ...col,
        /*headerComponentParams: {
                    template: getTooltipIcon(col.headerName)
                }*/
        headerName: col.headerName + '    🛈',
      }
    }

    return col
  })
}

const BymaTable = (props: TableProps) => {
  const {
    sizeColumnsToFit = false,
    title = undefined,
    style = {},
    pagination = false,
    sortable = false,
    suppressHorizontalScroll = false,
    extras = undefined,
    rowBuffer = 15,
    backgroundColor = 'transparent',
    context,
    noRowsText,
    showLoadingOverlay = true,
    suppressNoRowsOverlay = false,
    setApiRef,
    autoHeight,
    getRowStyle
  } = props

  const columnDefaults = {
    resizable: true,
    sortable: sortable,
    suppressSizeToFit: false,
    disabled: false,

    cellStyle: { textAlign: 'center' },

    headerClass: 'text-center ',

    ...props.columnDefaults,

    filterParams: {
      buttons: ['reset', 'apply'],
      debounceMs: 200,
      ...props.columnDefaults?.filterParams,
    },
  }

  const tableColumns = props.columns ? props.columns : context.columns

  //console.log(`DEBUG BymaTable entering tableColumns=${JSON.stringify(tableColumns)}`)

  const columns = useMemo(() => applyColumnsFixes(tableColumns), [tableColumns])

  const [columnApi, setColumnApi] = useState<any>()

  const pageSize = props.pageSize ? props.pageSize : 10

  const { serverSideModel, setSort, columnOrder, setColumnOrder, columnState, setColumnState } =
    useListContext()

  const [prevColumnOrder, setPrevColumnOrder] = useState(columnOrder)

  const [firstDataRendered, setFirstDataRendered] = useState(false)

  const gridColumns = serverSideModel ? cloneDeep(columns) : columns
  if (serverSideModel) {
    prepareServerSideModel(columnDefaults)
  }

  const orderedGridColumns = applyColumnState(
    applyListColumnOrder(prepareColumns(gridColumns), columnOrder),
    columnState,
  )

  //cambio en la visibilidad de las columnas
  useEffect(() => {
    if (context?.columns && columnApi) {
      //console.log(`DEBUG BymaTable cambio context columnOrder=${JSON.stringify(columnOrder)}`)

      const isVisibleColumn = (id) => {
        const result = context.columns.find((c) => c.field === id && !c.hide)

        //console.log(
        //  `DEBUG DEBUG BymaTable cambio context.columns 1isVisibleColumn(${id})= ${result} `,
        //)

        return result
      }

      const colIds = columnApi
        .getAllDisplayedColumns()
        .filter((col) => isVisibleColumn(col.colId))
        .map((col) => col.colId)

      //console.log(`DEBUG BymaTable cambio context.columns 2 colIds=${colIds}`)

      //Insertar las columnas visibles del contexto que todavia no estan en el orden
      context.columns.forEach((c, index) => {
        if (!c.hide && !colIds.includes(c.field)) {
          //TODO mejorar el algoritmo para determinar el indice
          //(eg comparar con la ultima columna visible y mantener la distancia relativa)
          colIds.splice(index, 0, c.field)
        }
      })

      const columnOrderPayload = { columnOrder: colIds }

      if (!isEqual(prevColumnOrder, columnOrderPayload)) {
        setPrevColumnOrder(columnOrderPayload)
        setColumnOrder(columnOrderPayload)
      }
    }
  }, [context?.columns, columnApi])

  /*
  useEffect(() => {
    if (columnApi && columnState) {
      //console.log(`DEBUG BymaTable useEffect columnState=${JSON.stringify(columnState)}`)

      //https://www.ag-grid.com/javascript-data-grid/column-state/
      columnApi.applyColumnState(columnState)
    }
  }, [columnState, columnApi])
  */

  const onSortChanged = (event) => {
    const {
      api: { sortController },
    } = event
    const sortModel = sortController.getSortModel()

    //console.log(`DEBUG BymaTable onSortChanged sortModel=${JSON.stringify(sortModel)}`)

    if (sortModel.length > 0) {
      const { colId, sort } = sortModel[0]
      //TODO usar un enum para la direccion
      setSort({ field: colId, order: sort.toUpperCase() })
    } else {
      //TODO como anular el ordenamiento ? Usar el default ?
      setSort({ field: 'id', order: 'ASC' })
    }
  }

  const onDragStopped = (params) => {
    //console.log('DEBUG BymaTable 3 onDragStopped')

    const colIds = params.columnApi.getAllDisplayedColumns().map((col) => col.colId)

    const columnState = params.columnApi.getColumnState()

    //console.log(`DEBUG BymaTable onDraggStopped columnStat=${JSON.stringify(columnState)}`)

    setColumnState(columnState)

    //console.log(`DEBUG BymaTable 3.1 onDragStopped colIds=${colIds}`)

    const columnOrderPayload = { columnOrder: colIds }

    if (!isEqual(prevColumnOrder, columnOrderPayload)) {
      setPrevColumnOrder(columnOrderPayload)
      setColumnOrder(columnOrderPayload)
    }
  }

  const onFirstDataRendered = (params) => {
    const gridApi = params.api

    if (sizeColumnsToFit) {
      gridApi.sizeColumnsToFit({})
    }

    setFirstDataRendered(true)
  }

  const onGridReady = (params) => {
    //console.log(`DEBUG BymaTable 4 onGridReady sizeColumnsToFit=${sizeColumnsToFit}`)

    // Aca colocamos todos los metodos que son llamados por la api de AgGrid
    const gridApi = params.api

    //Si el parente necesita la referncia al api de la tabla
    if (setApiRef) {
      setApiRef(gridApi)
    }

    setColumnApi(params.columnApi)

    gridApi.hideOverlay()

    if (!isNullOrUndefined(props.rowData) && props.rowData?.length == 0) {
      gridApi.showNoRowsOverlay()
    }

    //console.log('Seteando columnas en table context!!!!!!!!!!!')
    //console.log(props)
  }

  //si se aplica sizeColumnsToFit ocultamos el contenido hasta que se haya hecho el resizing
  const hiddenWhileSizing = !firstDataRendered && sizeColumnsToFit
  const containerClassName = hiddenWhileSizing
    ? 'byma-table-container byma-table-container-hidden'
    : 'byma-table-container'

  //console.log(`DEBUG BymaTable render returning columns=${JSON.stringify(orderedGridColumns)}`)

  return (
    <div
      key={props.key + '-container'}
      style={{
        backgroundColor: backgroundColor,
      }}
      className={containerClassName}
    >
      {withLoadingOverlay({
        ...props,
        isLoading: props.isLoading || props.isFetching,
        loadingText: '',
        showLoadingOverlay,

        children: (
          <>
            <div className='byma-table-header'>
              {title && <span className='byma-table-title'>{title}</span>}
              {extras && <span className='byma-table-extras'>{extras}</span>}
            </div>
            <div
              className={/*styles.tableFrame +*/ 'byma-table-frame ag-theme-alpine-dark'}
              style={{
                height: 'inherit',
                width: '100%',
                ...style,
              }}
            >
              {props.isError ? (
                <BymaAlert variant='danger'>
                  <div>{props.error ? props.error.toString() : 'Error cargando datos'}</div>
                </BymaAlert>
              ) : props.rowData === undefined ? null : (
                <AgGridReact
                  className={/*styles.bymaTable*/ 'byma-table'}
                  key={props.key + '-table'}
                  rowData={props.rowData}
                  columnDefs={orderedGridColumns}
                  defaultColDef={columnDefaults}
                  pagination={pagination}
                  suppressPaginationPanel={!pagination}
                  paginationPageSize={pageSize}
                  paginateChildRows={true}
                  alwaysMultiSort={props.alwaysMultiSort}
                  onSortChanged={onSortChanged}
                  getRowStyle={getRowStyle}
                  //onColumnVisible={onColumnVisible}
                  onDragStopped={onDragStopped}
                  onGridReady={onGridReady}
                  onFirstDataRendered={onFirstDataRendered}
                  suppressHorizontalScroll={suppressHorizontalScroll}
                  rowBuffer={rowBuffer}
                  tooltipShowDelay={50}
                  enableBrowserTooltips={false}
                  tooltipHideDelay={500000}
                  suppressDragLeaveHidesColumns={true}
                  context={context}
                  enableCellTextSelection={true}
                  ensureDomOrder={true}
                  overlayNoRowsTemplate={`<div>${noRowsText || i18n.t('text.noRowsToShow')}</div>`}
                  animateRows={true}
                  suppressLoadingOverlay={false}
                  suppressNoRowsOverlay={suppressNoRowsOverlay}
                  //TODO PRUEBA PARA ADAPTAR EL HEIGHT A LA CANTIDAD DE FILAS
                  domLayout={autoHeight ? 'autoHeight' : 'normal'}
                />
              )}
            </div>
          </>
        ),
      })}
    </div>
  )
}

export default BymaTable

const editCellRendererSelector =
  ({ editPath, editLabel = 'Editar', icon = 'fa fa-edit' }) =>
  (params) => {
    return {
      component: BymaNavigateButton,
      params: {
        navigateTo: `${editPath}/${params.data.id}`,
        label: editLabel,
        icon: icon,
      },
    }
  }

export { editCellRendererSelector }

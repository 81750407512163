import React from 'react'

import styles from './BymaCopyButton.module.scss'

interface OptionsButtonProps {
  key: string
  text: string
}

const CopyIcon = () => (
  <svg width='17' height='20' viewBox='0 0 17 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M2.75 19.3C2.25 19.3 1.825 19.125 1.475 18.775C1.125 18.425 0.949997 18 0.949997 17.5V4.8H2.45V17.5C2.45 17.5833 2.479 17.654 2.537 17.712C2.59566 17.7707 2.66666 17.8 2.75 17.8H12.45V19.3H2.75ZM6.25 15.8C5.75 15.8 5.325 15.625 4.975 15.275C4.625 14.925 4.45 14.5 4.45 14V2.625C4.45 2.10834 4.625 1.675 4.975 1.325C5.325 0.975003 5.75 0.800003 6.25 0.800003H14.625C15.1417 0.800003 15.575 0.975003 15.925 1.325C16.275 1.675 16.45 2.10834 16.45 2.625V14C16.45 14.5 16.275 14.925 15.925 15.275C15.575 15.625 15.1417 15.8 14.625 15.8H6.25ZM6.25 14.3H14.625C14.7083 14.3 14.7833 14.2707 14.85 14.212C14.9167 14.154 14.95 14.0833 14.95 14V2.625C14.95 2.54167 14.9167 2.46667 14.85 2.4C14.7833 2.33334 14.7083 2.3 14.625 2.3H6.25C6.16666 2.3 6.096 2.33334 6.038 2.4C5.97933 2.46667 5.95 2.54167 5.95 2.625V14C5.95 14.0833 5.97933 14.154 6.038 14.212C6.096 14.2707 6.16666 14.3 6.25 14.3Z'
      fill='white'
    />
  </svg>
)

export const BymaCopyButton = (props: OptionsButtonProps) => {
  const { key, text } = props

  const copyContent = async (value: string) => {
    try {
      await navigator.clipboard.writeText(value)
      console.log('Content copied to clipboard')
    } catch (err) {
      console.error('Failed to copy: ', err)
    }
  }

  return (
    <div className={styles.copyTextButton_container}>
      <span className={styles.copyTextButton_text}>{text}</span>
      <button id={key} className={styles.copyTextButton} onClick={() => copyContent(text)}>
        <CopyIcon />
      </button>
    </div>
  )
}

import { ResourceApi } from 'services/api/resourceApi'
import { CategoriaCliente, CATEGORIA_CLIENTE_OPTIONS_KEY } from './CategoriaCliente'

class CategoriasClienteApi extends ResourceApi<CategoriaCliente> {
  constructor() {
    super('/api/categorias-cliente')
  }
}

const CategoriasClienteApiInstance = new CategoriasClienteApi()

export default CategoriasClienteApiInstance

export const CategoriaClienteOptionsQueryProps = {
  dataProvider: () => CategoriasClienteApiInstance.getOptions(),
  listKey: CATEGORIA_CLIENTE_OPTIONS_KEY,
}

import React, { useCallback } from 'react'

import { Col, Row } from 'react-bootstrap'

import { ListBase } from 'core'

import { BymaButton, BymaDataTable, AddIcon, LocalErrorAlert, BymaCard } from 'components'

import { TITULO_ENTITY_NAME, FlujoFondosApi, flujoFondosMontoNumberFormatter } from 'model'
import { formatDateOnly } from 'utils/date'

import { ColocacionEditProps } from '../../../ColocacionViewContext'
import {
  EntityContextInfo,
  useEditButtonCell,
  useDeleteButtonCell,
  useEntityContext,
} from 'features/common/Entity'

import FlujoFondosModal from './FlujoFondoModal'

const getFlujoFondosColumns = (context: EntityContextInfo, readOnly) => {
  return [
    {
      field: 'tituloDenominacion',
      headerName: 'Titulo',
      textAlign: 'left',
      minWidth: 200,
    },
    {
      field: 'fecha',
      headerName: 'Fecha',
      textAlign: 'left',
      maxWidth: 200,
      valueFormatter: (params) => formatDateOnly(params?.data?.fecha),
    },

    {
      field: 'monto',
      headerName: 'Monto',
      textAlign: 'right',
      maxWidth: 200,
      valueFormatter: (params) => flujoFondosMontoNumberFormatter(params?.data?.monto),
    },

    useEditButtonCell({ context, minWidth: 80 }),

    {
      ...useDeleteButtonCell({ context, minWidth: 80 }),
      hide: readOnly,
    },
  ]
}

const FlujoFondossFields = ({ listKey, queryKey, context, api, readOnly }) => {
  const dataProvider = useCallback((params) => api.getAllAsPage({ ...params }), [api])

  return (
    <>
      <Row sm={12} className='mb-3'>
        <Col sm={12}>
          <BymaDataTable
            key='titulo-canjes'
            columns={getFlujoFondosColumns(context, readOnly)}
            sizeColumnsToFit={true}
            style={{ height: '200px', width: '100%' }}
            pagination={false}
            sortable={false}
            showLoadingOverlay={false}
            queryProps={{
              dataProvider,
              listKey,
              queryKey,
            }}
            listPaginationProps={{
              showLimits: false,
            }}
            suppressHorizontalScroll={true}
          />
        </Col>
      </Row>

      <Row>
        <Col style={{ display: 'flex', justifyContent: 'right' }}>
          <BymaButton
            id={'titulo-nuevo-flujo-fondos'}
            label='Agregar Flujo de Fondos'
            name={'titulo-nuevo-canje'}
            iconLeft={AddIcon}
            iconStyles={{ width: 12 }}
            onClick={() => {
              context.setEntityId(undefined)
              context.setEditEnabled(true)
              context.setShowModal(true)
            }}
          />
        </Col>
      </Row>

      <Row className='mt-3'>
        <LocalErrorAlert />
      </Row>
    </>
  )
}

export const FlujoFondossTable = (props: ColocacionEditProps) => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const api = new FlujoFondosApi(props.tituloId!)

  const CANJES_LIST_CLASSIFIER = 'titulo.canjess'
  const LIST_KEY = [TITULO_ENTITY_NAME, CANJES_LIST_CLASSIFIER]
  const QUERY_KEY = [...LIST_KEY, { id: props.tituloId }]

  const readOnly = props.readOnly
  const context = useEntityContext(TITULO_ENTITY_NAME, 'Flujo de Fondos', QUERY_KEY, readOnly)

  return (
    <BymaCard titleBorder='none' footer={<></>}>
      <BymaCard
        titleBackgorundColor='var(--byma-primary-2)'
        style={{
          border: '1px solid var(--byma-primary-2)',
          backgroundColor: '#384256',
        }}
        title={'FlujoFondoss'}
      >
        <FlujoFondossFields
          listKey={LIST_KEY}
          queryKey={QUERY_KEY}
          context={context}
          api={api}
          readOnly={readOnly}
        />
      </BymaCard>

      <FlujoFondosModal context={context} api={api} formId='titulo-flujo-fondos-form' />
    </BymaCard>
  )
}

const FlujoFondossList = (props: ColocacionEditProps) => {
  return (
    <ListBase listKey='titulo.flujoFondos'>
      <FlujoFondossTable {...props} />
    </ListBase>
  )
}

export default FlujoFondossList

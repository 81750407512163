import React, { useCallback } from 'react'

import { Col, Row } from 'react-bootstrap'

import { ListBase } from 'core'

import { BymaButton, BymaDataTable, AddIcon, LocalErrorAlert, BymaCard } from 'components'
import { TITULO_ENTITY_NAME, TituloIntegracionApi, integracionNumberPropertyFormatter } from 'model'

import { ColocacionEditProps } from '../../../ColocacionViewContext'
import {
  EntityContextInfo,
  useEditButtonCell,
  useDeleteButtonCell,
  useEntityContext,
} from 'features/common/Entity'

import IntegracionModal from './IntegracionModal'

const getIntegracionColumns = (context: EntityContextInfo, readOnly) => {
  return [
    {
      field: 'tituloDenominacion',
      headerName: 'Titulo',
      textAlign: 'left',
    },
    {
      field: 'denominacion',
      headerName: 'Denominación',
      textAlign: 'left',
    },
    {
      field: 'cotizacion',
      headerName: 'Cotización',
      textAlign: 'right',
      valueFormatter: (params) => integracionNumberPropertyFormatter(params?.data?.cotizacion),
    },

    {
      field: 'cantidadMinima',
      headerName: 'Cantidad Mínima',
      textAlign: 'right',
      valueFormatter: (params) => integracionNumberPropertyFormatter(params?.data?.cantidadMinima),
    },

    {
      field: 'laminaMinima',
      headerName: 'Lámina Mínima',
      textAlign: 'right',
      valueFormatter: (params) => integracionNumberPropertyFormatter(params?.data?.laminaMinima),
    },

    {
      field: 'tipoRedondeo',
      headerName: 'Tipo redondeo',
      textAlign: 'left',
    },

    {
      field: 'aliasSiopel',
      headerName: 'Alias Código Siopel',
      textAlign: 'left',
    },

    useEditButtonCell({ context, minWidth: 80 }),

    {
      ...useDeleteButtonCell({ context, minWidth: 80 }),
      hide: readOnly,
    },
  ]
}

const IntegracionesFields = ({ listKey, queryKey, context, api, readOnly }) => {
  const dataProvider = useCallback((params) => api.getAllAsPage({ ...params }), [api])

  return (
    <>
      <Row sm={12} className='mb-3'>
        <Col sm={12}>
          <BymaDataTable
            key='titulo-integraciones'
            columns={getIntegracionColumns(context, readOnly)}
            sizeColumnsToFit={true}
            style={{ height: '200px', width: '100%' }}
            pagination={false}
            sortable={false}
            showLoadingOverlay={false}
            queryProps={{
              dataProvider,
              listKey,
              queryKey,
            }}
            listPaginationProps={{
              showLimits: false,
            }}
            suppressHorizontalScroll={true}
          />
        </Col>
      </Row>

      <Row>
        <Col style={{ display: 'flex', justifyContent: 'right' }}>
          {!readOnly && (
            <BymaButton
              id={'titulo-nueva-integracion'}
              label='Agregar Integración'
              name={'titulo-nueva-integracion'}
              iconLeft={AddIcon}
              iconStyles={{ width: 12 }}
              onClick={() => {
                context.setEntityId(undefined)
                context.setEditEnabled(true)
                context.setShowModal(true)
              }}
            />
          )}
        </Col>
      </Row>

      <Row className='mt-3'>
        <LocalErrorAlert />
      </Row>
    </>
  )
}

export const IntegracionesTable = (props: ColocacionEditProps) => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const api = new TituloIntegracionApi(props.tituloId!)

  const INTEGRACIONES_LIST_CLASSIFIER = 'titulo.integraciones'
  const LIST_KEY = [TITULO_ENTITY_NAME, INTEGRACIONES_LIST_CLASSIFIER]
  const QUERY_KEY = [...LIST_KEY, { id: props.tituloId }]

  const readOnly = props.readOnly
  const context = useEntityContext(TITULO_ENTITY_NAME, 'Integración', QUERY_KEY, readOnly)

  return (
    <BymaCard titleBorder='none' footer={<></>}>
      <BymaCard
        titleBackgorundColor='var(--byma-primary-2)'
        style={{
          border: '1px solid var(--byma-primary-2)',
          backgroundColor: '#384256',
        }}
        title={'Integraciones'}
      >
        <IntegracionesFields
          listKey={LIST_KEY}
          queryKey={QUERY_KEY}
          context={context}
          api={api}
          readOnly={readOnly}
        />
      </BymaCard>

      <IntegracionModal context={context} api={api} formId='titulo-integracion-form' />
    </BymaCard>
  )
}

const IntegracionesList = (props: ColocacionEditProps) => {
  return (
    <ListBase listKey='titulo.integraciones'>
      <IntegracionesTable {...props} />
    </ListBase>
  )
}

export default IntegracionesList

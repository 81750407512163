import { toDate } from 'utils/date'
import { TipoRedondeo } from 'model/common/TipoRedondeo'
import { formatNumber } from 'utils/number'

export const TITULO_ENTITY_NAME = 'titulo'

export interface TramoCompetitivo {
  cantidadMinimaOferta: number
  cantidadMaximaOferta: number
  multiploOferta: number
  precioMinimoOferta: number
  precioMaximoOferta: number
  unidadProrrateo: number
  montoMinimoAdj: number
  tickDePrecio: number
  codigoApi?: string
}
export interface TramoNoCompetitivo {
  cantidadMinimaOferta: number
  cantidadMaximaOferta: number
  multiploOferta: number
  unidadProrrateo: number
  montoMinimoAdj: number
  porcentajeMaxAdj: number
  codigoApi?: string
  precioApi?: number
}
export interface Integracion extends BaseEntity {
  denominacion: string
  cotizacion?: number
  cantidadMinima?: number
  laminaMinima?: number
  tipoRedondeo?: TipoRedondeo
  aliasSiopel: string
}

export enum Moneda {
  PESOS = 'PESOS',
  DOLARES = 'DOLARES',
  UVA = 'UVA',
}

export const MonedaOptions = [
  { value: Moneda.PESOS, label: 'Pesos' },
  { value: Moneda.DOLARES, label: 'Dolares' },
  { value: Moneda.UVA, label: 'UVA' },
]

export enum TipoProrrateoCompetitivo {
  CORTE = 'CORTE',
  TODOS = 'TODOS',
}

export const TipoProrrateoCompetitivoOptions = [
  { value: TipoProrrateoCompetitivo.CORTE, label: 'Al Corte' },
  { value: TipoProrrateoCompetitivo.TODOS, label: 'Todos' },
]

export enum TipoProrrateoNoCompetitivo {
  PROPORCIONAL = 'PROPORCIONAL',
  CRONOLOGICO = 'CRONOLOGICO',
}

export const TipoProrrateoNoCompetitivoOptions = [
  { value: TipoProrrateoNoCompetitivo.PROPORCIONAL, label: 'Proporcional' },
  { value: TipoProrrateoNoCompetitivo.CRONOLOGICO, label: 'Cronologico' },
]

export interface Titulo extends BaseEntity {
  colocacionId: number

  denominacion: string

  /**
   * descripcion completa provista por el backend
   */
  descripcion: string

  totalEmision: number
  valorReferencia: number
  variacionPorcentual: number

  tipoValor: string

  monedaEmision: string
  monedaLiquidacion: string
  fechaLiquidacion: string
  maturity: number
  duration: number
  cantidadPagos: number
  precioCorte: number

  //codigo numerico
  codigoEspecie: number
  //codigo alfanumerico
  codigoBolsa: string

  tramoCompetitivo: TramoCompetitivo
  tramoNoCompetitivo: TramoNoCompetitivo
  tramoCompetitivoHabilitado: boolean
  integraciones: Integracion[]
}

export enum TipoValor {
  TASA = 'TASA',
  PRECIO = 'PRECIO',
  TIR = 'TIR',
  MARGEN = 'MARGEN',
  TASA_CUPON = 'TASA_CUPON',
}
export interface TipoValorInfo {
  shortText: string
  prefix?: string
  suffix?: string
}

export const TIPO_VALOR_INFO = {
  [TipoValor.TASA]: { shortText: '%', description: 'Tasa (%)', suffix: '%', title: 'Tasa' },
  [TipoValor.PRECIO]: { shortText: '$', description: 'Precio ($)', prefix: '$', title: 'Precio' },
  [TipoValor.TIR]: { shortText: '%', description: 'Tir (%)', suffix: '%', title: 'Tir' },
  [TipoValor.MARGEN]: { shortText: '%', description: 'Margen (%)', suffix: '%', title: 'Margen' },
  [TipoValor.TASA_CUPON]: {
    shortText: '%',
    description: 'Tasa Cupón (%)',
    suffix: '%',
    title: 'Tasa Cupón',
  },
}

export const TipoValorOptions = Object.entries(TIPO_VALOR_INFO).map((e) => ({
  value: e[0],
  label: e[1].title,
}))

export enum TipoTitulo {
  VRD = 'VRD',
  CP = 'CP',
  VDF = 'VDF',
  ACC = 'ACC',
  BD = 'BD',
  CUOTA_PARTE = 'CUOTA_PARTE',
}

export const TipoTituloOptions = [
  { value: TipoTitulo.VRD, label: 'VRD' },
  { value: TipoTitulo.CP, label: 'CP' },
  { value: TipoTitulo.VDF, label: 'VDF' },
  { value: TipoTitulo.ACC, label: 'ACC' },
  { value: TipoTitulo.BD, label: 'BD' },
  { value: TipoTitulo.CUOTA_PARTE, label: 'Cuota Parte' },
]

export const tituloTotalEmisiomFormatter = (n: number) => formatNumber(n, 2)
export const tituloCantidadFormatter = (n: number) => formatNumber(n, 2)
export const tituloPrecioFormatter = (n: number) => formatNumber(n, 2)

export function tipoValorLicitacionFormatter(tipoValor) {
  return tipoValor ? TIPO_VALOR_INFO[tipoValor]?.title : ''
}

export function tituloTipoValorFormatter(titulo: any) {
  return tipoValorLicitacionFormatter(titulo?.tipoValor)
}

export const tituloFormDataToModel = (formData) => {
  console.log(`DEBUG tituloFormDataToModel formData=${JSON.stringify(formData)}`)

  return formData?.tramoCompetitivo?.habilitado
    ? { ...formData, tramoCompetitivoHabilitado: true }
    : { ...formData, tramoCompetitivoHabilitado: false, tramoCompetitivo: {} }
}

export const tituloModelToFormData = (titulo: Titulo) => {
  if (!titulo) {
    return {}
  }

  const tramoCompetitivo = titulo.tramoCompetitivo
  const fechaLiquidacion = toDate(titulo.fechaLiquidacion)

  return tramoCompetitivo
    ? {
        ...titulo,
        fechaLiquidacion,
      }
    : {
        ...titulo,
        tramoCompetitivo: {},
        fechaLiquidacion,
      }
}

import * as React from 'react'
import { useState, useCallback, ReactNode, HtmlHTMLAttributes } from 'react'

import PropTypes from 'prop-types'
import { BymaDropdownButton } from 'components/Buttons/BymaDropdownButton'
import { BymaTableContext } from './BymaTableContext'
import { BymaTableColumn } from './BymaTable'
import { TableColumnButtonMenuItem } from './TableColumnButtonMenuItem'
import { AddFilter } from 'components'

export interface TableColumnButtonProps extends HtmlHTMLAttributes<HTMLDivElement> {
  context: BymaTableContext
  //classes?: ClassesOverride<typeof useStyles>;
  className?: string
  resource?: string
  label?: string
  showColumn?: (field: string) => void
  hideColumn?: (field: string) => void
  displayedFilters?: any
}

const TableColumnButton = (props: TableColumnButtonProps): JSX.Element | null => {
  const { className, context, label = 'Editar columnas', ...rest } = props

  const [, setOpen] = useState(false)

  //console.log(`DEBUG TableColumnButton render displayedFilters= ${JSON.stringify(context.columns)}`)

  const handleShow = useCallback(
    ({ source }) => {
      //console.log('CAMBIO COLUMNA SELECTOR to show ' + source)
      context.showColumn(source)
      //setOpen(false)
    },
    [context.showColumn, setOpen],
  )

  const handleHide = useCallback(
    ({ source }) => {
      console.log('CAMBIO COLUMNA SELECTOR to hide ' + source)
      context.hideColumn(source)
      //setOpen(false)
    },
    [context.hideColumn, setOpen],
  )

  //console.log('Rendering byma tamble columns button')
  //console.log(context.columns)

  return (
    <div
      {...sanitizeRestProps(rest)}
      //className={styles.filterBtnContainer}
    >
      <BymaDropdownButton
        id='filter-button'
        name='filter-button'
        iconLeft={AddFilter}
        className={className}
        label={label}
      >
        {context.columns
          .filter((c) => !c.disabled && !c.disabledHide)
          .map((column) => {
            const columnElement = column as BymaTableColumn

            const { field, hide, disabledHide } = columnElement

            return (
              <TableColumnButtonMenuItem
                key={field}
                column={columnElement}
                resource=''
                onShow={handleShow}
                onHide={handleHide}
                checked={!hide}
                disabledHide={disabledHide}
              />
            )
          })}
      </BymaDropdownButton>
    </div>
  )
}

const sanitizeRestProps = ({ ...rest }) => rest

TableColumnButton.propTypes = {
  resource: PropTypes.string,
  columns: PropTypes.arrayOf(PropTypes.node),
  showColumn: PropTypes.func,
  hideColumn: PropTypes.func,
  classes: PropTypes.object,
  className: PropTypes.string,
}

export default TableColumnButton

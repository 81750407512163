import React, { useMemo } from 'react'

import { getFieldsSectionCardStyleProps } from '../../utils'

import {
  BymaSelect,
  TextInput,
  BymaCheckbox,
  NumberInput,
  BymaCard,
  BymaDatePicker,
} from 'components'

import { Col, Row } from 'react-bootstrap'

import {
  BooleanOptions,
  MonedaOptions,
  TipoValorOptions,
  TipoTituloOptions,
  DefaultIntegerConstraint,
  DefaultDecimalConstraint,
  numberConstraintYup,
} from 'model'

// NOTA Por ahora se comentan validaciones para facilitar las pruebas
const validate = false

export const tituloDetailStepYupFields = (yup) => ({
  denominacion: yup.string().required(),
  tipo: yup.string().required(),
  tipoValor: yup.string().required(),
  fechaLiquidacion: yup.date().required(),

  totalEmision: numberConstraintYup(yup, DefaultDecimalConstraint),
  duration: numberConstraintYup(yup, DefaultDecimalConstraint),
  maturity: numberConstraintYup(yup, DefaultIntegerConstraint),
  cantidadPagos: numberConstraintYup(yup, DefaultIntegerConstraint),

  valorReferencia: numberConstraintYup(yup, DefaultDecimalConstraint),
  variacionPorcentual: numberConstraintYup(yup, DefaultDecimalConstraint),
})

export const tituloDetailStepYup = (yup) => yup.object(tituloDetailStepYupFields(yup))

export const tituloDetailStepFieldNames = [
  'denominacion',
  'tipo',
  'totalEmision',
  'monedaEmision',
  'monedaLiquidacion',
  'duration',
  'maturity',
  'cantidadPagos',
  'tipoValor',
  'valorReferencia',
  'variacionPorcentual',
  'fechaLiquidacion',
  'codigoEspecie',
  'codigoBolsa',
  'tramoCompetitivo.habilitado',
  'precioCorte',
]

const DATE_PLACEHOLDER = 'dd/mm/aaaa'

export const TituloDetailStepFields = ({ isNew, editEnabled }: EditContext) => {
  const cardStyleProps = useMemo(() => getFieldsSectionCardStyleProps(isNew), [isNew])

  const readOnly = !editEnabled

  return (
    <BymaCard {...cardStyleProps} title={'Detalle'}>
      <Row className='mb-3'>
        <Col sm={6}>
          <TextInput name='denominacion' label='Denominación' required readOnly={readOnly} />
        </Col>
        <Col sm={3}>
          <BymaSelect
            name='tipo'
            label='Tipo'
            options={TipoTituloOptions}
            required
            readOnly={readOnly}
          />
        </Col>

        <Col sm={3}>
          <BymaSelect
            name='tipoValor'
            label='Variable de licitación'
            required={true}
            options={TipoValorOptions}
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row className='mb-3'>
        <Col sm={3}>
          <NumberInput
            name='totalEmision'
            label='Valor nominal Emisión'
            required={true}
            decimalScale={DefaultDecimalConstraint.maxFractionalDigits}
            readOnly={readOnly}
          />
        </Col>

        <Col sm={3}>
          <BymaSelect
            name='monedaEmision'
            label='Moneda de emisión'
            required={true}
            options={MonedaOptions}
            readOnly={readOnly}
          />
        </Col>

        <Col sm={3}>
          <BymaSelect
            name='monedaLiquidacion'
            label='Moneda de Liquidación'
            required={true}
            options={MonedaOptions}
            readOnly={readOnly}
          />
        </Col>

        <Col sm={3}>
          <BymaDatePicker
            name='fechaLiquidacion'
            label='Fecha de Liquidación'
            required={true}
            showTimeSelect={false}
            clearBtn={false}
            placeholder={DATE_PLACEHOLDER}
            readOnly={readOnly}
          />
        </Col>
      </Row>

      <Row className='mb-3'>
        <Col sm={3}>
          <NumberInput
            name='maturity'
            label='Maturity'
            required={true}
            decimalScale={DefaultIntegerConstraint.maxFractionalDigits}
            readOnly={readOnly}
          />
        </Col>

        <Col sm={3}>
          <NumberInput
            name='duration'
            label='Duration'
            decimalScale={DefaultDecimalConstraint.maxFractionalDigits}
            readOnly={readOnly}
          />
        </Col>

        <Col sm={3}>
          <NumberInput
            name='cantidadPagos'
            label='Cantidad de pagos'
            decimalScale={DefaultIntegerConstraint.maxFractionalDigits}
            readOnly={readOnly}
          />
        </Col>

        <Col sm={3}>
          <NumberInput name='precioCorte' label='Precio de corte' readOnly={readOnly || isNew} />
        </Col>
      </Row>

      <Row className='mb-3'>
        <Col sm={3}>
          <NumberInput
            required={true}
            name='valorReferencia'
            label='Precio tasa referencia'
            readOnly={readOnly}
          />
        </Col>
        <Col sm={3}>
          <NumberInput
            required={true}
            name='variacionPorcentual'
            label='% de alerta de variación'
            readOnly={readOnly}
          />
        </Col>
        <Col sm={3}>
          <TextInput name='codigoEspecie' label='Código de especie CVSA' readOnly={readOnly} />
        </Col>
        <Col sm={3}>
          <TextInput name='codigoBolsa' label='Código de especie BYMA' readOnly={readOnly} />
        </Col>
      </Row>

      <Row className='mb-3'>
        <Col sm={4}>
          <BymaCheckbox
            type='radio'
            name='tramoCompetitivo.habilitado'
            label='Tramo Competitivo Habilitado'
            options={BooleanOptions}
            readOnly={readOnly}
          />
        </Col>
      </Row>
    </BymaCard>
  )
}

import React from 'react'
import { useLocalErrorConsumer } from 'core'
import { BymaAlert } from 'components'

const LocalErrorAlert = () => {
  const { error } = useLocalErrorConsumer()

  //En caso de que haya un error.supportId
  //Agregar el texto: Por favor enviar codigo de error a soporte <>error.supportId<>
  //Agregar boton para copia a clipboard
  return error ? (
    <BymaAlert messageId={error?.id} variant='danger'>
      <div>{error.message}</div>
    </BymaAlert>
  ) : null
}

export { LocalErrorAlert }

import { useLoadingProducer } from 'core/loading'
import { useNotificationProducer } from '../notification'
import { getApiErrorMessage } from 'services/api'
import { useLocalErrorProducer } from 'core/error'

const DEFAULT_TASK_MESSAGE = ''
const DEFAULT_TASK_ID = 'useFetchDetailQuery'

export interface UseFetchDetailQueryParams<T> {
  dataProvider: (id: Identifier) => Promise<any>
  taskMessage?: string
  taskId?: string
  onSuccess?: (T) => void
  clearLocalErrorOnLoad?: boolean
}

function useFetchDetailQuery<T>({
  dataProvider,
  taskMessage = DEFAULT_TASK_MESSAGE,
  taskId = DEFAULT_TASK_ID,
  onSuccess = () => {},
  clearLocalErrorOnLoad,
}: UseFetchDetailQueryParams<T>) {
  const loadingProducer = useLoadingProducer()

  const { addNotification } = useNotificationProducer()

  const localErrorApi = useLocalErrorProducer()

  const onSuccessImpl = (data) => {
    if (localErrorApi && clearLocalErrorOnLoad) {
      localErrorApi.clearError()
    }
    onSuccess(data)
  }

  return (id: Identifier) => {
    loadingProducer.addTask({ message: taskMessage, id: taskId })

    return dataProvider(id)
      .then((result) => {
        onSuccessImpl(result)
        return result
      })

      .catch((error) => {
        const apiError = getApiErrorMessage(error)
        addNotification({ message: apiError, type: 'error' })
      })

      .finally(() => {
        loadingProducer.removeTask(taskId)
      })
  }
}

export { useFetchDetailQuery }

import { useQuery, QueryKey } from 'react-query'
import { useLoadingProducer } from 'core/loading'
import { useNotificationProducer } from '../notification'
import { getApiErrorMessage } from 'services/api'
import { useLocalErrorProducer } from 'core/error'

export interface UseEditQueryParams<T> {
  entityKey: string
  id: Identifier
  dataProvider: (id: Identifier) => Promise<T>
  keepPreviousData?: boolean
  queryKey?: QueryKey
  taskMessage?: string
  enabled?: boolean
  cacheTime?: number
  onSuccess?: (T) => void
  clearLocalErrorOnLoad?: boolean
}

const DEFAULT_TASK_MESSAGE = ''

function useEditQuery<T>({
  entityKey,
  id,
  dataProvider,
  keepPreviousData = true,
  queryKey,
  taskMessage = DEFAULT_TASK_MESSAGE,
  enabled = true,
  onSuccess = () => {},
  cacheTime,
  clearLocalErrorOnLoad,
}: UseEditQueryParams<T>) {
  const loadingProducer = useLoadingProducer()
  const { addNotification } = useNotificationProducer()

  const getQueryKey = (resource, id) => {
    if (resource instanceof String || typeof resource === 'string') {
      return [resource, id]
    }
    return [...resource, id]
  }

  const actualQueryKey = queryKey ? queryKey : getQueryKey(entityKey, id)

  const taskId: string = JSON.stringify(actualQueryKey)

  const localErrorApi = useLocalErrorProducer()

  const onSuccessImpl = (data) => {
    if (localErrorApi && clearLocalErrorOnLoad) {
      localErrorApi.clearError()
    }
    onSuccess(data)
  }

  const useQueryResult = useQuery(
    actualQueryKey,

    () => dataProvider(id),

    {
      staleTime: 0,

      refetchOnWindowFocus: false,

      //keeppreiousdata para evitar que se vacie la tabla mientras actualiza
      keepPreviousData: keepPreviousData,

      enabled,

      cacheTime,

      onSettled: () => {
        loadingProducer.removeTask(taskId)
      },

      onSuccess(data) {
        onSuccessImpl(data)
      },

      retry: false,
    },
  )

  const result = { ...useQueryResult, error: getApiErrorMessage(useQueryResult.error) }

  if (result.isError) {
    addNotification({ message: result.error, type: 'error' })
  } else if (useQueryResult.isLoading || useQueryResult.isFetching) {
    loadingProducer.addTask({ message: taskMessage, id: taskId })
  }

  return result
}

export { useEditQuery }

import { ResourceApi, resolveBackendUrl } from 'services/api'
import { ColocacionDocumento } from './Documento'

export class ColocacionDocumentoApi extends ResourceApi<ColocacionDocumento> {
  constructor(colocacionId: Identifier) {
    super('/api/colocaciones/:colocacionId/documentos', { colocacionId }, true)
  }

  downloadContent = (id: Identifier) => {
    return this.apiMethods.export({
      url: resolveBackendUrl(`${this.path}/${id}/content`, this.urlPathParams),
    })
  }
}

import { EntityApi } from 'services/api'
import { useCreateForm, useEditForm, useBymaMutation } from 'core'
import { useCallback } from 'react'
import { EntityContextInfo } from '../core'
import { useRefreshList } from 'core'

export interface EntityModalProps<E extends BaseEntity> {
  context: EntityContextInfo
  navigateTo?: string
  api: EntityApi<E>
  formId: string
}

//extender CreateFormProps
export interface EntityFormProps<E extends BaseEntity> extends EntityModalProps<E> {
  schema: any
  defaultValues?: any
}

export interface ModalEntityFormProps<E extends BaseEntity> {
  form: any
  formId: string
}

export const useRefreshEntityList = (context: EntityContextInfo) => {
  return useRefreshList(context.listQueryKey)
}

export const useDeleteEntityMutation = <E extends BaseEntity>(
  props: EntityModalProps<E>,
  onSuccess?: () => void,
) => {
  const { context, api } = props

  const refreshList = useRefreshEntityList(context)

  const mutation = useCallback(() => {
    if (context.entityId) return api.delete(context.entityId)
    else throw new Error('Entidad no especificada')
  }, [context.entityId])

  const onSucessImpl = onSuccess
    ? () => {
        refreshList()
        onSuccess()
      }
    : refreshList

  return useBymaMutation({
    mutation,
    successNotification: `El ${context.entityLabel} ha sido eliminado`,
    errorNotification: `Error eliminando el ${context.entityLabel}`,
    onSuccess: onSucessImpl,
  })
}

export const useCreateEntityForm = <E extends BaseEntity>(props: EntityFormProps<E>) => {
  const { schema, api, context, defaultValues = {} } = props
  const { entityLabel } = context

  const createEntity = useCallback((data) => api.create(data), [api])

  const refreshList = useRefreshEntityList(context)

  return useCreateForm<E>({
    defaultValues,
    values: {},
    schema: schema,
    mutation: createEntity,
    successNotification: () => `Se creó el ${entityLabel} exitosamente`,
    errorNotification: (error) => `Error creando ${entityLabel}: ${error.message}`,
    onSuccess: () => {
      console.log(`DEBUG useCreateEntityForm  onSuccess entityLabel=${entityLabel}`)

      context.setShowModal(false)
      refreshList()
    },
  })
}

export const useEditEntityForm = <E extends BaseEntity>(props: EntityFormProps<E>) => {
  const { schema, api, context, defaultValues = {} } = props
  const { entityLabel, entityName, entityId = undefined } = context

  const updateEntity = useCallback((data) => api.update(data), [api])

  const getFormData = useCallback(() => {
    return api.getOne(entityId).then((response) => response)
  }, [api, entityId])

  //console.log(`DEBUG useEditEntityForm entityId=${entityId}`)

  const queryKey = `${entityName}.edit:${entityId}?count=${context.queryCount}`

  const refreshList = useRefreshEntityList(context)

  return useEditForm<E>({
    queryKey: queryKey,
    getFormData: getFormData,
    mutation: updateEntity,
    schema: schema,
    successNotification: () => `Se actualizó el ${entityLabel} exitosamente`,
    errorNotification: (error) => `Error actualizando ${entityLabel}: ${error.message}`,
    onSuccess: () => {
      context.setShowModal(false)
      refreshList()
    },
    defaultValues,
  })
}

export interface NumberFormatConfig {
  decimalSeparator: string
  thousandSeparator: string
  decimalScale: number
}

export const DEFAULT_DECIMAL_SCALE = 2

export const DefaultNumberFormatConfig: NumberFormatConfig = {
  decimalSeparator: ',',
  thousandSeparator: '.',
  decimalScale: DEFAULT_DECIMAL_SCALE,
}

export const defaultNumberFormat = new Intl.NumberFormat('es', {
  maximumFractionDigits: DEFAULT_DECIMAL_SCALE,
  useGrouping: true,
})

export const numberFormat = (scale?: number) =>
  scale !== undefined && scale !== DEFAULT_DECIMAL_SCALE
    ? new Intl.NumberFormat('es', {
        maximumFractionDigits: scale,
        useGrouping: true,
      })
    : defaultNumberFormat

export const numberFormatFromConfig = (config: NumberFormatConfig) =>
  new Intl.NumberFormat('es', {
    maximumFractionDigits: config.decimalScale,
    useGrouping: true,
  })

export function formatNumber(n: number, scale?: number) {
  return n !== undefined && n !== null && !isNaN(n) ? numberFormat(scale).format(n) : ''
}

import { ResourceApi } from '../../services/api/resourceApi'
import { Emisor } from './Emisor'

class EmisoresApi extends ResourceApi<Emisor> {
  constructor() {
    super('/api/emisores')
  }
}

export default new EmisoresApi()

import React from 'react'
import { FormProvider } from 'react-hook-form'
import { Form } from 'react-bootstrap'

import { CategoriaCliente, CategoriaClienteOptionsQueryProps, ColocacionesApi } from 'model'

import {
  ColocacionEditProps,
  useColocacionEditContext,
  ColocacionSectionType,
} from '../../../ColocacionViewContext'

import {
  AutorizacionSectionFieldsProps,
  AutorizacionSectionFields,
  useAutorizacionSectionForm,
} from '../AutorizacionSectionCommons'

const CategoriasClienteSectionFields = (props: ColocacionEditProps) => {
  const sectionProps: AutorizacionSectionFieldsProps<CategoriaCliente> = {
    ...props,

    section: ColocacionSectionType.CATEGORIAS_CLIENTES,

    cardTitle: 'Categoría de Clientes',

    dataProvider: (id) => ColocacionesApi.getCategoriasCliente(id),

    optionsParams: CategoriaClienteOptionsQueryProps,

    dualListProps: {
      labelAvailable: 'Autorizados',
      labelSelected: 'Autorizados',
      labels: {
        availableFilterHeader: 'Buscar',
        selectedFilterHeader: 'Buscar',
        availableHeader: 'NO AUTORIZADOS',
        selectedHeader: 'AUTORIZADOS',
        moveLeft: '< Quitar',
        moveRight: 'Agregar >',
        moveLeftTooltip: 'Quitar Categoría',
        moveRightTooltip: 'Agregar Categoría',
      },
    },
  }
  return <AutorizacionSectionFields {...sectionProps} />
}

export const CategoriasClienteSection = () => {
  const props = useColocacionEditContext()

  const { useFormReturn, onSubmit } = useAutorizacionSectionForm({
    successNotification: 'Se actualizaron las Categorías de Cliente',

    errorNotification: 'Error actualizando Categorías de Cliente',

    mutation: (id, autorizaciones) => ColocacionesApi.updateCategoriasCliente(id, autorizaciones),

    ...props,
  })

  return (
    <FormProvider {...useFormReturn}>
      <Form id='colocacion-categorias-cliente-edit' onSubmit={useFormReturn.handleSubmit(onSubmit)}>
        <CategoriasClienteSectionFields {...props} />
      </Form>
    </FormProvider>
  )
}

import React, { useState } from 'react'
import { Sidebar as ProSidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar'
import styles from './BymaSidebar.module.scss'

interface SidebarItem {
  key: string
  label: string
  icon?: JSX.Element
  onClick?: () => void
  subItems?: SidebarItem[]
}

interface Props {
  items: SidebarItem[]
}

const MENU_BG = '#3B3F50'
const MENU_BUTTON_HOVER_BG = '#4E5261'

const Sidebar = ({ items }: Props) => {
  const [collapsed, setCollapsed] = useState(true)
  const [delayHandler, setDelayHandler] = React.useState<NodeJS.Timeout>()

  return (
    <div
      style={{
        display: 'flex',
        height: '100%',
        position: 'relative',
        width: 60,
      }}
    >
      <ProSidebar
        collapsed={collapsed}
        backgroundColor='transparent'
        style={{ border: 'none', position: 'absolute' }}
        onMouseEnter={() => setDelayHandler(setTimeout(() => setCollapsed(false), 300))}
        onMouseLeave={() => {
          if (delayHandler) {
            clearTimeout(delayHandler)
          }
          setTimeout(() => setCollapsed(true), 100)
        }}
        collapsedWidth='50px'
      >
        <Menu
          style={{ backgroundColor: MENU_BG, borderRadius: 24, overflow: 'hidden' }}
          menuItemStyles={{
            button: {
              backgroundColor: 'transparent',
              '&:hover': {
                backgroundColor: MENU_BUTTON_HOVER_BG,
                color: '#ffffff',
              },
              fontSize: 13,
              fontWeight: 500,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            },
            subMenuContent: {
              backgroundColor: 'transparent',
              borderBottom: '2px solid #4E5261',
            },

            icon: {
              marginRight: 0,
            },
            SubMenuExpandIcon: {
              display: !collapsed ? 'none' : 'fixed',
            },
          }}
          className={styles.newSidebar}
        >
          {items.map((item) => {
            return item.subItems === undefined || item.subItems.length === 0 ? (
              <MenuItem key={item.key} icon={item.icon} onClick={item.onClick}>
                {item.label}
              </MenuItem>
            ) : (
              <SubMenu label={item.label} icon={item.icon}>
                {item.subItems.map((subitem: any, subitemIdx: number) => (
                  <MenuItem
                    key={subitemIdx}
                    icon={subitem.icon ? subitem.icon : item.icon}
                    onClick={!!subitem.onClick && subitem.onClick}
                  >
                    {subitem.label}
                  </MenuItem>
                ))}
              </SubMenu>
            )
          })}
        </Menu>
      </ProSidebar>
    </div>
  )
}

export default Sidebar

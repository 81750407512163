import React, { useState } from 'react'

import { useNavigate } from 'react-router-dom'
import { getColocacionViewRoute } from 'routes'

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import { BymaButton, BymaAccordion, BymaBadge, BymaModal } from 'components'

import { OfertaContextInfo } from '../../common/Oferta/OfertaContextInfo'

import {
  getColocacionEstadoLabel,
  Colocacion,
  ColocacionMethods,
  TituloAction,
  TituloActionLabel,
  Titulo,
  fechaColocacionCellFormatter,
  getColocacionEstadoBadgeType,
} from 'model'

import OfertaCreate from '../Oferta'

import styles from './ColocacionDashboardTable.module.scss'
import getColocacionTituloDetail from './ColocacionTituloDetail'
import { CreateOfertaExtendidaModal } from '../../common/Oferta/OfertaExtendidaForm'

interface ColocacionDashboardTableProps {
  data: any
  context: OfertaContextInfo
}

//TODO BP-178 Utilizar directorio src para imagenes
const EcoIcon = () => (
  <div className={styles.ecoIcon_container}>
    <svg width='17' height='17' viewBox='0 0 17 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M2.24615 14.2488C1.54103 13.5405 0.9904 12.726 0.59425 11.8054C0.198083 10.8847 0 9.93289 0 8.94982C0 7.96392 0.188783 6.98573 0.56635 6.01523C0.943916 5.04471 1.55385 4.1383 2.39615 3.296C3.00513 2.68703 3.76764 2.18543 4.68367 1.7912C5.59969 1.39697 6.63719 1.09665 7.79617 0.89025C8.95514 0.683833 10.2183 0.563641 11.5856 0.529675C12.9529 0.495691 14.3871 0.5396 15.8884 0.6614C15.9961 2.0947 16.033 3.48731 15.999 4.83922C15.965 6.19114 15.8467 7.45172 15.6442 8.62095C15.4416 9.79018 15.1458 10.8431 14.7567 11.7796C14.3676 12.7161 13.8666 13.4908 13.2538 14.1037C12.4282 14.9357 11.5452 15.5431 10.6048 15.9257C9.66439 16.3084 8.71196 16.4998 7.74747 16.4998C6.73632 16.4998 5.74293 16.3017 4.7673 15.9056C3.79167 15.5094 2.95128 14.9572 2.24615 14.2488ZM4.48842 14.0844C4.99099 14.3998 5.52208 14.6312 6.0817 14.7787C6.64132 14.9261 7.19741 14.9998 7.74997 14.9998C8.52098 14.9998 9.2836 14.8473 10.0379 14.5421C10.7921 14.237 11.5077 13.7396 12.1846 13.0498C12.4834 12.7498 12.7863 12.329 13.0934 11.7873C13.4005 11.2457 13.6661 10.5373 13.8902 9.66232C14.1143 8.78732 14.2845 7.72899 14.4007 6.48732C14.5169 5.24566 14.5397 3.76649 14.4692 2.04982C13.6525 2.01649 12.7285 2.0072 11.6971 2.02195C10.6657 2.03668 9.63683 2.12066 8.61055 2.27387C7.58427 2.42707 6.61439 2.66713 5.70092 2.99405C4.78748 3.32098 4.0487 3.7665 3.4846 4.3306C2.741 5.07418 2.22433 5.82707 1.9346 6.58925C1.64485 7.35143 1.49997 8.07419 1.49997 8.75752C1.49997 9.70239 1.68427 10.5569 2.05285 11.321C2.42145 12.0851 2.80511 12.6524 3.20382 13.0229C3.76279 11.7665 4.54836 10.5611 5.56052 9.40658C6.57269 8.25209 7.85506 7.24472 9.40763 6.38447C8.23328 7.40882 7.22751 8.53382 6.39033 9.75945C5.55316 10.9851 4.91919 12.4267 4.48842 14.0844Z'
        fill='#4FAE22'
      />
    </svg>
  </div>
)

/**
 * Tabla para desplegar todas las colocaciones
 *
 * @param param0
 * @returns
 */
const ColocacionDashboardTable = ({ data, context }: ColocacionDashboardTableProps) => {
  const [showColocacion, setShowColocacion] = useState<boolean>(false)

  const [selected, setSelected] = useState<number[]>([])

  const navigate = useNavigate()

  const onColocacionClick = (colocacion: number) => {
    if (selected.includes(colocacion)) {
      const filterSelected = selected.filter((option) => option !== colocacion)
      setSelected(filterSelected)
    } else {
      setSelected([...selected, colocacion])
    }
  }
  const getColocacionHeader = (colocacion: Colocacion, full: boolean) => {
    const contentFull = (
      <Row className='w-100 align-items-center'>
        <Col sm={5} className={styles.denominationContainer}>
          {colocacion.sustentable && <EcoIcon />}
          <p className='m-0'>{colocacion.denominacion}</p>
        </Col>
        <Col sm={3} className='d-flex align-items-center justify-content-end'>
          <BymaBadge text={colocacion.colocador?.denominacion} type={'data'} />
          <div
            className='d-flex align-items-center justify-content-center'
            style={{ minWidth: 85 }}
          >
            <BymaBadge
              text={getColocacionEstadoLabel(colocacion.estado)}
              type={getColocacionEstadoBadgeType(colocacion.estado)}
            />
          </div>
        </Col>

        <Col sm={2} className='d-flex align-items-center justify-content-center'>
          <BymaBadge text={fechaColocacionCellFormatter(colocacion)} type={'data'} />
        </Col>
        <Col sm={2} className='d-flex align-items-center justify-content-start'>
          <BymaButton
            id={'button-colocacion-' + colocacion.id}
            name={'button-colocacion-' + colocacion.id}
            className={styles.buttonColocacion}
            onClick={(event) => {
              navigate(getColocacionViewRoute(colocacion?.id))
              // Esto es para que no cierre/abra el acordeon
              event.stopPropagation()
            }}
            label={'Ver Colocación'}
          />
        </Col>
      </Row>
    )

    const contentResume = (
      <Row className='w-100 align-items-center'>
        <Col sm={7}>
          {colocacion.sustentable && <EcoIcon />}
          <p className='m-0'>{colocacion.denominacion}</p>
        </Col>
        <Col sm={4} className='d-flex align-items-center justify-content-start'>
          <BymaButton
            id={'button-colocacion-' + colocacion.id}
            name={'button-colocacion-' + colocacion.id}
            className={styles.buttonColocacion}
            onClick={(event) => {
              navigate(getColocacionViewRoute(colocacion?.id))
              // Esto es para que no cierre/abra el acordeon
              event.stopPropagation()
            }}
            label={'Ver Colocación'}
          />
        </Col>
      </Row>
    )

    return {
      content: !full ? contentFull : contentResume,
      title: 'N°' + colocacion.id,
    }
  }

  const getTituloHeader = (context: OfertaContextInfo, titulo: Titulo, colocacion, clicked) => {
    const tituloAction = ColocacionMethods.getTituloActionDefault(
      colocacion,
      context.createOfertaPropiaEnabled,
    )

    const actionLabel = tituloAction ? TituloActionLabel[tituloAction] : undefined

    const handleCreateOfertaRapida = (event, titulo) => {
      //stopPropagation para evitar que se colapse/ expanda el accordion
      event.stopPropagation()

      context.setShowOfertaRapida(true)
      context.setCurrentTitulo(titulo)
      context.setTipoAltaOferta(tituloAction)
      context.setCurrentColocacion(colocacion)
    }

    const handleCreateOfertaExtendida = (event, titulo) => {
      //stopPropagation para evitar que se colapse/ expanda el accordion
      event.stopPropagation()

      //se cierra el formulario inline de oferta rapida
      //NOTA: El componente SwitchSelector tiene problemas cuando hay dos activos a la vez
      //por eso es mejor cerrar el formulario de oferta rapida
      context.setShowOfertaRapida(false)
      context.setShowOfertaExtendida(true)

      context.setCurrentTitulo(titulo)
      context.setTipoAltaOferta(tituloAction)
      context.setCurrentColocacion(colocacion)
    }

    const handleVerAdjudicacion = () => {
      //TODO ir a pagina de adjudicacion
    }

    const handleClickAction = (event, titulo) => {
      //stopPropagation para evitar que se colapse/ expanda el accordion

      event.stopPropagation()

      if (tituloAction === TituloAction.VER_ADJUDUCACION) {
        handleVerAdjudicacion()
      }
      if (tituloAction === TituloAction.OFERTA_EXTENDIDA) {
        handleCreateOfertaExtendida(event, titulo)
      } else {
        handleCreateOfertaRapida(event, titulo)
      }
    }

    const content = (
      <Row
        style={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Col sm={7} className={styles.denominationContainer}>
          <p className='m-0 ms-2'>{titulo.descripcion}</p>
        </Col>
        <Col sm={5} className={styles.rightColBtnContainer}>
          {tituloAction !== undefined && actionLabel !== undefined ? (
            <BymaButton
              id={'button-titulo-' + titulo.id}
              name={'button-titulo-' + titulo.id}
              className={styles.buttonOferta}
              label={actionLabel}
              onClick={(event) => handleClickAction(event, titulo)}
              disabled={context.showOfertaRapida && titulo.id === context.currentTitulo?.id}
              iconRight='arrow_forward.svg'
            />
          ) : null}
        </Col>
      </Row>
    )

    return {
      content,
      title: undefined,
      active: clicked.includes(titulo.id),
    }
  }

  /**
   * Detalle de Una colocacion
   *
   * @param colocacion
   * @returns
   */
  const getColocacionBody = (colocacion) => {
    return (
      <BymaAccordion
        key={'titulos-accordion-' + colocacion.id}
        useLeftButton={true}
        closed={context.showOfertaRapida}
        items={
          colocacion.titulos
            ? colocacion.titulos.map((titulo) => ({
                header: getTituloHeader(context, titulo, colocacion, selected),
                body: getColocacionTituloDetail(titulo),
                id: titulo.id,
              }))
            : []
        }
        onClick={(e) => onColocacionClick(e)}
        style={{ backgroundColor: '#CED4DA0D' }}
      />
    )
  }

  return (
    <Container style={{ padding: 0 }}>
      <Row>
        <Col sm={context.showOfertaRapida ? 6 : 12}>
          {
            <BymaAccordion
              key={'main-colocacion-accordion'}
              style={{
                marginBottom: '25px',
                backgroundColor: '#4e5261',
              }}
              items={data.map((colocacion) => ({
                header: getColocacionHeader(colocacion, context.showOfertaRapida),
                body: getColocacionBody(colocacion),
              }))}
            />
          }
        </Col>
        <Col sm={6}>{context.showOfertaRapida ? <OfertaCreate {...context} /> : null}</Col>
      </Row>

      <BymaModal
        id={'colocacion-modal'}
        show={showColocacion}
        title={'Colocación'}
        handleCancel={() => {
          setShowColocacion(false)
        }}
        handleConfirm={() => {
          setShowColocacion(false)
        }}
      >
        <></>
      </BymaModal>

      <CreateOfertaExtendidaModal context={context} />
    </Container>
  )
}

export default ColocacionDashboardTable

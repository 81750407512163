import { useCallback } from 'react'
import { useQueryClient } from 'react-query'
import { isEqual } from 'lodash'

const refreshListQuery = (queryClient, listQueryKey) => {
  queryClient.invalidateQueries({
    predicate: (query) => {
      const slice = query.queryKey?.slice && query.queryKey.slice(0, listQueryKey.length)
      return slice && isEqual(listQueryKey, slice)
    },
  })
}

export const useRefreshList = (queryKeyPrefix) => {
  const queryClient = useQueryClient()

  const refreshList = useCallback(() => {
    //console.log(`DEBUG useRefreshList ${queryKeyPrefix}`)

    refreshListQuery(queryClient, queryKeyPrefix)
  }, [queryClient, queryKeyPrefix])

  return refreshList
}

import React, { CSSProperties, useCallback } from 'react'
import { useFormContext, Controller } from 'react-hook-form'
import { default as ReactSelect, SingleValue, MultiValue, createFilter } from 'react-select'
import { components } from 'react-select'
import { HelperText, ErrorMessage } from 'components'
import { bymaSelectClassesNames, bymaSelectStyles } from './SelectCommons'
import BymaTooltip from 'components/BymaOverlay/BymaTooltip'

import styles from './BymaSelect.module.scss'

// NOTA: se retorna null en vez de undefined (para q funcione correctamente el "clear")
// TODO: verificar en toda la app que retornar null no trae problemas
const valueMapper = (option: SelectOption | null): any => (option ? option.value : null)

export type SelectFieldValue = Identifier | Identifier[] | null
export type SelectComponentValue = SelectOption | SelectOption[]
export type SelectFieldValueTransform = (SelectFieldValue) => SelectFieldValue
export type SelectComponentValueTransform = (SelectComponentValue) => SelectComponentValue
export type SelectVariant = 'Default' | 'Small'
export type SelectMode = 'Default' | 'SingleFilter'

import i18n from 'i18n'

export const useSelectSingleFilterProps = () => {
  return {
    multiselect: true,

    variant: 'Small' as SelectVariant,

    selectMode: 'SingleFilter' as SelectMode,

    placeholder: i18n.t('text.writeHere'),

    selectFieldValueTransform: (fieldValue: SelectFieldValue) => {
      //console.log(`DEBUG BymaSelect selectFieldValueTransform fieldValue=${JSON.stringify(fieldValue)}`)

      if (Array.isArray(fieldValue)) {
        const multiFieldValue = fieldValue as Identifier[]

        if (multiFieldValue.length > 1) {
          return multiFieldValue[1]
        } else if (multiFieldValue.length > 0) {
          return multiFieldValue[0]
        } else {
          return null
        }
      } else {
        return fieldValue
      }
    },

    selectComponentValueTransform: (componentValue: SelectComponentValue) => {
      const multiComponentValue = componentValue as SelectOption[]

      //console.log(`DEBUG BymaSelect selectComponentValueTransform componentValue=${JSON.stringify(componentValue)}`)

      if (multiComponentValue !== undefined && multiComponentValue.length > 1) {
        return multiComponentValue.slice(1, 2)
      } else {
        return componentValue
      }
    },
  }
}

export interface SelectProps extends FieldProps {
  options?: SelectOption[]
  onChange?: (selected: any) => void
  onBeforeChange?: (selected: any) => void
  multiselect?: boolean
  menuIsOpen?: boolean
  useCheckbox?: boolean
  isClearable?: boolean
  style?: CSSProperties | undefined
  defaultValue?: unknown
  placeholder?: string
  helperText?: string
  maxMenuHeight?: number
  variant?: SelectVariant
  selectMode?: SelectMode
  selectFieldValueTransform?: SelectFieldValueTransform
  selectComponentValueTransform?: SelectComponentValueTransform
  modalMenu?: boolean
}

export const BymaSelect = (props: SelectProps) => {
  const {
    name,
    id = name,
    options,
    label,
    onChange: customOnChange = () => {},
    onBeforeChange = () => {},
    multiselect = false,
    menuIsOpen = false,
    useCheckbox = false,
    isClearable = false,
    className = '',
    //default value para haer un 'clear'
    //https://github.com/JedWatson/react-select/issues/3066
    defaultValue = '',
    placeholder = i18n.t('text.selectPlaceHolderDefault'),
    style = {},
    helperText = undefined,
    required = false,
    toolTipText = undefined,
    maxMenuHeight,
    variant = 'Default',
    modalMenu = false,
    selectFieldValueTransform,
    selectComponentValueTransform,
    selectMode = 'Default' as SelectMode,
    hideLabel,
    readOnly = false,
  } = props

  const width = 'inherit'
  const height = 'inherit'

  const Option = (props) => {
    const optionLabel = props?.data?.optionLabel || props.label

    return useCheckbox ? (
      <components.Option {...props}>
        <input type='checkbox' checked={props.isSelected} onChange={() => null} />{' '}
        <label>{optionLabel}</label>
      </components.Option>
    ) : (
      <components.Option {...props}>
        <label>{optionLabel}</label>
      </components.Option>
    )
  }

  const SingleValue = (props) => {
    return (
      <components.SingleValue {...props}>
        {props.data.chipLabel ? props.data.chipLabel : props.data.label}
      </components.SingleValue>
    )
  }

  const handleChange = (selected) => {
    customOnChange(selected)
  }

  const handleBeforeChange = (selected) => {
    if (onBeforeChange) {
      onBeforeChange(selected)
    }
  }

  const { control } = useFormContext()

  const stylesFn = useCallback(() => bymaSelectStyles(styles), [styles])

  const getComponentValue = (fieldValue: SelectFieldValue) => {
    const componentValue = options
      ? multiselect && Array.isArray(fieldValue)
        ? options.filter((opt) =>
            fieldValue ? (fieldValue as Identifier[]).includes(opt.value) : undefined,
          )
        : options.find((opt) => fieldValue == opt.value)
      : undefined

    /*console.log(
      `DEBUG BymaSelect getComponentValue ${name} fieldValue=${JSON.stringify(
        fieldValue,
      )} componentValue=${JSON.stringify(
        componentValue,
      )} transform=${selectComponentValueTransform}`,
    )*/

    return selectComponentValueTransform
      ? selectComponentValueTransform(componentValue)
      : componentValue
  }

  const getFieldValue = (componentValue: SelectComponentValue) => {
    const fieldValue = multiselect
      ? (componentValue as MultiValue<SelectOption>).map((opt) => valueMapper(opt))
      : valueMapper(componentValue as SingleValue<SelectOption>)

    /*console.log(
      `DEBUG BymaSelect getFieldValue ${name} fieldValue=${JSON.stringify(
        fieldValue,
      )} transform=${selectFieldValueTransform}`,
    )*/

    return selectFieldValueTransform ? selectFieldValueTransform(fieldValue) : fieldValue
  }

  const spanClassName =
    variant === 'Small' ? 'd-inline-block' : styles.selectFormGroup + ' d-inline-block'

  const labelDivClassName = variant === 'Small' ? 'd-flex' : styles.bymaSelect

  const noOptionsMessage = useCallback(
    () => (options && options.length > 0 ? 'No hay mas Opciones' : 'No hay Opciones'),
    [options],
  )

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState }) => {
        const value = field.value ? field.value : props.value

        const selectValue = getComponentValue(value)

        const hideInput = !!(selectMode === 'SingleFilter' && selectValue)

        /*console.log(
          `DEBUG BymaSelect Controller ${field.name} ${JSON.stringify(value)} ${JSON.stringify(
            selectValue,
          )}`,
        )*/

        const errorMessage = fieldState.error?.message

        const readOnlyClass = readOnly ? styles.selectReadOnly : ''

        return (
          <span
            className={spanClassName}
            data-toggle='popover'
            data-trigger='focus'
            data-content='Please selecet account(s)'
            data-mdb-perfect-scrollbar='true'
          >
            <div
              className={styles.bymaSelect + ' ' + styles[`bymaSelect${variant}`] + ' ' + className}
              style={{
                width: width,
                height: height,
                ...style,
              }}
            >
              {label !== undefined ? (
                <div
                  className={labelDivClassName}
                  style={hideLabel ? { visibility: 'hidden' } : {}}
                >
                  <label htmlFor={id} className={styles.bymaSelectLabel}>
                    {label + (required ? '*' : '')}
                  </label>
                  {toolTipText && <BymaTooltip text={toolTipText} />}
                </div>
              ) : null}

              <ReactSelect
                className={'bg-transparent reactBymaSelect ' + readOnlyClass}
                options={options}
                isMulti={multiselect}
                closeMenuOnSelect={!multiselect || selectMode === 'SingleFilter'}
                hideSelectedOptions={true}
                components={{
                  Option,
                  SingleValue,
                  DropdownIndicator: readOnly ? () => null : components.DropdownIndicator,
                  IndicatorSeparator: readOnly ? () => null : components.IndicatorSeparator,
                }}
                isDisabled={readOnly}
                onChange={(val) => {
                  const calculatedFieldValue = getFieldValue(val as SelectComponentValue)

                  /*
                  console.log(
                    `DEBUG BymaSelect Controller onChange ${
                      field.name
                    } field.value=${JSON.stringify(field.value)} val=${JSON.stringify(
                      val,
                    )} calc=${JSON.stringify(calculatedFieldValue)} options=${JSON.stringify(
                      options,
                    )}`,
                  )
                  */
                  handleBeforeChange(val)

                  field.onChange(calculatedFieldValue)

                  handleChange(val)
                }}
                isClearable={isClearable}
                value={selectValue || defaultValue}
                menuIsOpen={menuIsOpen ? true : undefined}
                placeholder={readOnly ? '-' : placeholder}
                maxMenuHeight={maxMenuHeight}
                classNames={bymaSelectClassesNames({
                  isError: fieldState.error !== undefined,
                  variant,
                  hideInput: hideInput,
                  readOnly: readOnly,
                })}
                styles={stylesFn()}
                ref={field.ref}
                //Optimizacion https://github.com/JedWatson/react-select/issues/3128
                filterOption={createFilter({ ignoreAccents: false })}
                menuPosition={modalMenu ? 'fixed' : 'absolute'}
                noOptionsMessage={noOptionsMessage}
                menuPortalTarget={
                  modalMenu ? document.querySelector('dialog') : document.querySelector('body')
                }
                //inputRef={field.ref}
                //allowSelectAll={true}
              />
            </div>
            {helperText && <HelperText text={helperText} />}
            <ErrorMessage error={errorMessage} />
          </span>
        )
      }}
    />
  )
}

import { ResourceApiMethods, resolveBackendUrl } from 'services/api'

import { Condomino } from './Condomino'
interface CondominosFilter {
  agente?: Identifier
  comitente: string
}

export class CondominosApi {
  apiMethods: ResourceApiMethods<Condomino>

  constructor() {
    this.apiMethods = new ResourceApiMethods()
  }

  getList = async (filter: CondominosFilter) => {
    const path = '/api/condominos'

    console.log(`ANTES DE invocar ${path}`)

    return this.apiMethods.getList({
      url: resolveBackendUrl(path),
      params: filter,
    })
  }
}

export default new CondominosApi()

import React from 'react'

import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'

import './BymaOverlay.scss'

interface OverlayProps {
  children: React.ReactElement | ((props: any) => React.ReactNode)
  text: string | string[]
  placement?: 'top' | 'right' | 'bottom' | 'left'
}

const BymaOverlay = (props: OverlayProps) => {
  const { children, text, placement = 'bottom' } = props

  const renderTooltip = (props) => {
    return (
      <Tooltip id='button-tooltip' {...props} className='byma-tooltip'>
        {
          Array.isArray(text) && text.length > 1 
          ? <ul>
            {text.map((item: string) => <li key={'item-' + item}>{item}</li>)}
            </ul>
          : Array.isArray(text) && text.length === 1 ? text[0] : text
        }
      </Tooltip>
    )
  }

  return (
    <OverlayTrigger
      placement={placement}
      delay={{ show: 250, hide: 400 }}
      //delay={{ show: 250, hide: 40000000 }}
      overlay={renderTooltip}
    >
      {children}
    </OverlayTrigger>
  )
}

export default BymaOverlay

import AxiosMockAdapter from 'axios-mock-adapter-path-params'

const tipoColocacionListResponse = [
  {
    id: 1,
    denominacion: 'Obligaciones Negociables',
    codigo: '2',
  },
  {
    id: 2,
    denominacion: 'Fideicomiso Financiero',
    codigo: '1',
  },
  {
    id: 3,
    denominacion: 'Acciones',
    codigo: '3',
  },
  {
    id: 4,
    denominacion: 'Letras',
    codigo: '4',
  },
  {
    id: 5,
    denominacion: 'Canje',
    codigo: '5',
  },
]

export const mockTipoColocacion = (mock: AxiosMockAdapter) => {
  mock.onGet('/api/tipos-colocacion/options').reply(() => {
    return [200, tipoColocacionListResponse]
  })
}

import React from 'react'

import {
  BymaCard,
  BymaDataTable,
  BymaExportButton,
  BymaPageFrame,
  BymaTextInputFilter,
  BymaSelect,
} from 'components'

import { AgentesApi, AGENTE_ENTITY_NAME, OrdenAgentes, OrdenAgentesOptions } from 'model'
import { ListBase, LoadingProvider, useListContext, useNotificationProducer } from 'core'
import { FiltersHeader } from 'features/common/Filters'
import AgenteModal from './AgenteModal'

import styles from './Agentes.module.scss'
import { EntityContextInfo, useEntityContext } from 'features/common/Entity'
import { useEditButtonCell } from 'features/common/Entity'
import { SORT_FILTER_NAME } from 'services/api'

const getAgentesColumns = (context: EntityContextInfo) => {
  return [
    {
      field: 'idOrganizacion',
      headerName: 'Código',
      textAlign: 'left',
      maxWidth: 150,
    },
    {
      field: 'denominacion',
      headerName: 'Denominación Agente',
      textAlign: 'left',
      minWidth: 350,
    },
    {
      field: 'nroDepositante',
      headerName: 'Nro. Depositante',
      textAlign: 'left',
      maxWidth: 150,
    },

    useEditButtonCell({ context, minWidth: 40 }),
  ]
}

/**
 *
 * @returns TODO crear componente SbaList
 */
const AgentesTable = () => {
  //const gridStyle = useMemo(() => ({ height: '100%', width: '100%' }), [])
  const { addNotification } = useNotificationProducer()

  const dataProvider = (params) => AgentesApi.getPaginatedList(params)

  const filters = [
    <BymaTextInputFilter
      name='idOrganizacion'
      key='idOrganizacion'
      label='Código Agente'
      type='text'
      alwaysOn={true}
      style={{ width: '300px' }}
      variant='Small'
    />,

    <BymaTextInputFilter
      name='nombreOrganizacion'
      key='nombreOrganizacion'
      label='Denominación Agente'
      type='text'
      alwaysOn={true}
      style={{ width: '300px' }}
      variant='Small'
    />,

    <BymaSelect
      name={SORT_FILTER_NAME}
      key={SORT_FILTER_NAME}
      label='Ordenar'
      style={{ minWidth: '250px' }}
      options={OrdenAgentesOptions}
      alwaysOn={true}
      variant='Small'
      hideLabel={true}
    />,
  ]
  const { filterValues } = useListContext()

  const context = useEntityContext(AGENTE_ENTITY_NAME)

  return (
    <BymaPageFrame
      bcHome={{ value: 'Administrar', link: '/home' }}
      bcItems={[]}
      bcActive='ABM Agentes'
      showTitle={false}
      showToast={true}
      className={styles.abmAgentesContainer}
    >
      <BymaCard key={'agentes-card'} header={{ title: 'Agentes' }}>
        <div style={{ paddingBottom: '1%', paddingTop: '2%' }}>
          <FiltersHeader
            filters={filters}
            showFiltersMenu={false}
            exportButton={
              <BymaExportButton
                id='agentes-export-button'
                name='agentes-export-button'
                serverDownload={() => AgentesApi.export({ filter: filterValues })}
                getFileName={() => 'agentes.csv'}
                postDownloading={() =>
                  addNotification({
                    message: 'Se descargo archivo de Agentes',
                    type: 'info',
                  })
                }
              />
            }
          />
        </div>

        <BymaDataTable
          key='agentes'
          columns={getAgentesColumns(context)}
          sizeColumnsToFit={true}
          style={{ height: '310px', width: '100%' }}
          pagination={false}
          sortable={false}
          queryProps={{
            dataProvider: dataProvider,
            listKey: context.listQueryKey,
          }}
          listPaginationProps={{
            showLimits: true,
          }}
          //suppressHorizontalScroll={true}
        />
      </BymaCard>

      <AgenteModal context={context} api={AgentesApi} navigateTo='/agentes' formId='agente-form' />
    </BymaPageFrame>
  )
}

const AgentesList = () => {
  return (
    <LoadingProvider>
      <ListBase
        listKey='agentes.hist'
        filterDefaultValues={{ [SORT_FILTER_NAME]: OrdenAgentes.CODIGO_ASC }}
      >
        <AgentesTable />
      </ListBase>
    </LoadingProvider>
  )
}

export default AgentesList

import { ResourceApi, resolveBackendUrl } from 'services/api'
import { Colocacion } from 'model/colocacion/Model'
import { AutorizacionColocacion } from './AutorizacionColocacion'

import { toDate } from 'utils/date'
import {
  modalidadOfertasColocacionToBoolean,
  booleanToModalidadOfertasColocacion,
  ColocacionCommand,
} from 'model'

const diaHoraToDate = (data: any) => {
  if (data.dia && data.hora) {
    data.dia.setMinutes(data.hora.getMinutes())
    data.dia.setSeconds(data.hora.getSeconds())
    data.dia.setHours(data.hora.getHours())
  }

  //console.log(`DEBUG diaHoraToDate data=${JSON.stringify(data)}`)

  return data?.dia
}

const dateToDiaHora = (data: any) => {
  const result = data ? { dia: toDate(data), hora: toDate(data) } : null
  return result
}

const colocacionFormDataToModel = (data: any) => {
  const { fechaInicio, fechaCierre, limiteRetiroOfertas, modalidadOfertas, ...rest } = data
  return {
    fechaInicio: diaHoraToDate(fechaInicio),
    fechaCierre: diaHoraToDate(fechaCierre),
    limiteRetiroOfertas: diaHoraToDate(limiteRetiroOfertas),
    ofertasAbiertas: modalidadOfertasColocacionToBoolean(modalidadOfertas),
    ...rest,
  }
}

const colocacionModelToFormData = (data: any) => {
  const {
    difusionDesde,
    difusionHasta,
    fechaInicio,
    fechaCierre,
    limiteRetiroOfertas,
    ofertasAbiertas,
    ...rest
  } = data
  return {
    difusionDesde: toDate(difusionDesde),
    difusionHasta: toDate(difusionHasta),
    fechaInicio: dateToDiaHora(fechaInicio),
    fechaCierre: dateToDiaHora(fechaCierre),
    limiteRetiroOfertas: dateToDiaHora(limiteRetiroOfertas),
    modalidadOfertas: booleanToModalidadOfertasColocacion(ofertasAbiertas),
    ...rest,
  }
}

class ColocacionesApi extends ResourceApi<Colocacion> {
  constructor() {
    super('/api/colocaciones', {}, false, {
      formDataToModel: colocacionFormDataToModel,
      modelToFormData: colocacionModelToFormData,
    })
  }

  getColocaciones = async (params: QueryParams) => {
    const path = '/api/colocaciones'
    return this.apiMethods.getPaginatedList({
      url: resolveBackendUrl(path),
      params,
    })
  }

  getAgentesAutorizados = async (id: any): Promise<AutorizacionColocacion[]> => {
    const path = `/api/colocaciones/${id}/agentes`
    return this.apiMethods.getNestedResources({
      url: resolveBackendUrl(path),
    })
  }

  updateAgentesAutorizados = async (id: any, agentes: AutorizacionColocacion[]) => {
    const path = `/api/colocaciones/${id}/agentes`
    return this.apiMethods.updateNestedResources({
      url: resolveBackendUrl(path),
      params: agentes,
    })
  }

  getCoColocadores = async (id: any): Promise<AutorizacionColocacion[]> => {
    const path = `/api/colocaciones/${id}/co-colocadores`
    return this.apiMethods.getNestedResources({
      url: resolveBackendUrl(path),
    })
  }

  updateCoColocadores = async (id: any, data: AutorizacionColocacion[]) => {
    const path = `/api/colocaciones/${id}/co-colocadores`
    return this.apiMethods.updateNestedResources({
      url: resolveBackendUrl(path),
      params: data,
    })
  }

  getCategoriasCliente = async (id: any): Promise<AutorizacionColocacion[]> => {
    const path = `/api/colocaciones/${id}/categorias-cliente`
    return this.apiMethods.getNestedResources({
      url: resolveBackendUrl(path),
    })
  }

  updateCategoriasCliente = async (id: any, data: AutorizacionColocacion[]) => {
    const path = `/api/colocaciones/${id}/categorias-cliente`
    return this.apiMethods.updateNestedResources({
      url: resolveBackendUrl(path),
      params: data,
    })
  }

  getTitulos = async (id: any, params: QueryParams) => {
    const path = `/api/colocaciones/${id}/titulos`
    return this.apiMethods.getAllAsPage({
      url: resolveBackendUrl(path),
      params: { ...params, sort: undefined },
    })
  }

  clonar = async (id: Identifier) => {
    const path = `/api/colocaciones/clonar/${id}`
    return this.apiMethods.create({
      url: resolveBackendUrl(path),
    })
  }

  run = async (id: Identifier, command: ColocacionCommand) => {
    const path = `/api/colocaciones/${id}/command/${command}`
    return this.apiMethods.create({
      url: resolveBackendUrl(path),
    })
  }

  /**
   * Retorna TotalesTitulo[]
   * @param id
   * @returns
   */
  totalesTitulo = async (id: Identifier) => {
    const path = `/api/colocaciones/${id}/totales-titulo`
    return this.apiMethods.getAllAsPage({
      url: resolveBackendUrl(path),
    })
  }

  getOfertas = async (id: Identifier, params: QueryParams) => {
    const path = `/api/colocaciones/${id}/ofertas`
    return this.apiMethods.getPaginatedList({
      url: resolveBackendUrl(path),
      params,
    })
  }

  exportOfertas = async (id: Identifier, params?: object) => {
    const path = `/api/colocaciones/${id}/ofertas/export`
    return this.apiMethods.export({
      url: resolveBackendUrl(path),
      params: { ...params },
    })
  }

  importOfertas = async (id: Identifier, params?: object) => {
    const path = `/api/colocaciones/${id}/ofertas/import`
    return this.apiMethods.export({
      url: resolveBackendUrl(path),
      params: { ...params },
    })
  }
}

export default new ColocacionesApi()

import React, { useMemo } from 'react'

import { BymaCheckbox, NumberInput, TextInput, BymaCard, BymaDataSelect } from 'components'

import { Col, Row } from 'react-bootstrap'
import { BooleanOptions } from 'model'
import { EnteLiquidadorOptionsQueryProps } from 'model/enteLiquidador'
import { getFieldsSectionCardStyleProps } from '../utils'

export const complementariosStepYupFields = (yup) => ({
  cotizacion: yup.number().required(),
  precision: yup.string().required(),
})

export const complementariosStepYup = (yup) => yup.object(complementariosStepYupFields(yup))

export const complementariosStepFieldNames = [
  'cotizacion',
  'precision',
  'cuitObligatorio',
  'interaccionSiopel',
  'enteLiquidador.id',
  'rueda',
]

export const complementariosStepFields = ({ isNew, editEnabled }) => {
  const cardStyleProps = useMemo(() => getFieldsSectionCardStyleProps(isNew), [isNew])

  const readOnly = !editEnabled

  return (
    <BymaCard {...cardStyleProps} title={'Datos Complementarios'}>
      <Row className='mb-3'>
        <Col sm={3}>
          <BymaCheckbox
            type='radio'
            name='cuitObligatorio'
            multicheck={false}
            label={'Oferta con CUIT obligatorio'}
            options={BooleanOptions}
            readOnly={readOnly}
          />
        </Col>

        <Col sm={2}>
          <NumberInput name='precision' label='Precisión' required={true} readOnly={readOnly} />
        </Col>

        <Col sm={3}>
          <NumberInput
            name='cotizacion'
            label={'Cotizaciones'}
            type='number'
            required={true}
            readOnly={readOnly}
          />
        </Col>
      </Row>

      <Row>
        <Col sm={3}>
          <BymaCheckbox
            type='radio'
            name='interaccionSiopel'
            multicheck={false}
            label={'Interaccion SIOPEL'}
            options={BooleanOptions}
            readOnly={readOnly}
          />
        </Col>

        <Col sm={2}>
          <TextInput name='rueda' label={'Rueda SIOPEL'} required={true} readOnly={readOnly} />
        </Col>

        <Col sm={3}>
          <BymaDataSelect
            name='enteLiquidador.id'
            label='Ente Liquidador SIOPEL'
            queryProps={EnteLiquidadorOptionsQueryProps}
            required
            readOnly={readOnly}
          />
        </Col>
      </Row>
    </BymaCard>
  )
}

import { ResourceApi, resolveBackendUrl } from 'services/api'
import { Titulo } from './Titulo'
import { CalculoCorteTitulo } from './AdjudicacionTitulo'

class TitulosApi extends ResourceApi<Titulo> {
  constructor() {
    super('/api/titulos')
  }

  /**
   * Inicializa calculo de Corte
   *
   * @param id Id de titulo
   * @returns
   */
  initCalculoCorte = async (id: any) => {
    const path = `/api/titulos/${id}/calculo-corte`
    return this.apiMethods.getOne({
      url: resolveBackendUrl(path),
    })
  }

  /**
   *
   * @param id Realiza un calculo de corte
   *
   * @param calculoCorteTitulo
   * @returns
   */
  calcularCorte = async (id: any, calculoCorteTitulo: CalculoCorteTitulo) => {
    const path = `/api/titulos/${id}/calculo-corte`
    return this.apiMethods.create({
      url: resolveBackendUrl(path),
      params: calculoCorteTitulo,
    })
  }

  /*
  getOfertasPorValor = async (id: any, params: QueryParams) => {
    const path = `/api/titulos/${id}/ofertas-valor`
    return this.apiMethods.getPaginatedList({
      url: resolveBackendUrl(path),
      params,
    })
  }
  */
  getOfertasPorValor = async (id: any, params: QueryParams) => {
    const path = `/api/titulos/${id}/ofertas-valor`
    return this.apiMethods.getOne({
      url: resolveBackendUrl(path),
      params,
    })
  }

  exportOfertasPorValor = async (id: any, params: QueryParams) => {
    const path = `/api/titulos/${id}/ofertas-valor`
    return this.apiMethods.export({
      url: resolveBackendUrl(`${path}/export`),
      params: { ...params },
    })
  }
}

export default new TitulosApi()

import React, { CSSProperties } from 'react'
import { useFormContext, Controller } from 'react-hook-form'
import { HelperText, ErrorMessage } from 'components'
import BymaTooltip from 'components/BymaOverlay/BymaTooltip'

import styles from './BymaCheckbox.module.scss'
import { FormCheck } from 'react-bootstrap'

export interface CheckboxProps extends BaseProps {
  name: string
  label?: string
  labelStyle?: CSSProperties
  value?: any
  className?: string
  readOnly?: boolean
  required?: boolean
  toolTipText?: string
  options?: CheckboxOption[]
  onChange?: (selected: any) => void
  multicheck?: boolean
  valueMapper?: (option: SelectOption | null) => any
  style?: CSSProperties | undefined
  defaultValue?: unknown
  helperText?: string
  variant?: 'Default' | 'Small'
  type?: 'checkbox' | 'radio' | 'switch'
  hideLabel?: boolean
  alwaysOn?: boolean
}

export const BymaCheckbox = (props: CheckboxProps) => {
  const {
    name,
    id = name,
    options,
    label,
    labelStyle = {},
    onChange: customOnChange = () => {},
    multicheck = false,
    className = '',
    style = {},
    helperText = undefined,
    required = false,
    toolTipText = undefined,
    variant = 'Default',
    type = 'checkbox',
    hideLabel = false,
    readOnly = false,
  } = props

  const width = 'inherit'
  const height = 'inherit'

  const { control } = useFormContext()

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState }) => {
        const value = field.value !== undefined ? field.value : props.value

        const checked = options ? options?.map((option) => option.value === value || false) : []

        const errorMessage = fieldState.error?.message

        return (
          <span
            className={styles.checkboxFormGroup + ' d-inline-block'}
            data-toggle='popover'
            data-trigger='focus'
            data-content='Please selecet account(s)'
            data-mdb-perfect-scrollbar='true'
          >
            <div
              className={styles[`bymaCheckbox${variant}`] + ' ' + className}
              style={{
                width: width,
                height: height,
                ...style,
              }}
            >
              <div>
                <label
                  htmlFor={id}
                  style={hideLabel ? { ...labelStyle, visibility: 'hidden' } : labelStyle}
                >
                  {label ? label + (required ? ' *' : '') : ' '}
                </label>
                {toolTipText && <BymaTooltip text={toolTipText} />}
              </div>
              {options?.map((option: CheckboxOption, idx: number) => {
                return (
                  <FormCheck
                    id={id}
                    readOnly={readOnly}
                    disabled={readOnly}
                    key={`check-box-${id}-${idx}`}
                    className={styles.bymaCheckbox + ' ' + className}
                    type={type}
                    checked={checked[idx]}
                    onChange={(event) => {
                      const value = event.target.checked
                      let checkedValue: boolean[] = []

                      if (type === 'checkbox' && multicheck === true) {
                        checkedValue = options?.map((opt: any, optIdx: number) =>
                          optIdx === idx ? value : checked[optIdx],
                        )
                      } else {
                        const prevValue = checked[idx]
                        checkedValue = options?.map((opt: any, optIdx: number) =>
                          optIdx === idx ? !prevValue : false,
                        )
                      }

                      const selectedIndex = checkedValue.indexOf(true)
                      const selectedValue = options[selectedIndex]?.value

                      field.onChange(selectedValue)
                      customOnChange(selectedValue)
                    }}
                    label={option.label}
                    ref={field.ref}
                    //disabled={disabled}
                  />
                )
              })}
            </div>
            {helperText && <HelperText text={helperText} />}
            <ErrorMessage error={errorMessage} />
          </span>
        )
      }}
    />
  )
}

import { Oferta } from 'model'
import { ResourceApiMethods, resolveBackendUrl } from 'services/api'

export class LibroOfertasApi {
  apiMethods: ResourceApiMethods<Oferta>

  constructor() {
    this.apiMethods = new ResourceApiMethods()
  }

  getList = async (tituloId: Identifier) => {
    const path = `/api/titulos/${tituloId}/ofertas/dashboard`
    //no hay paginacion
    return this.apiMethods.getAllAsPage({ url: resolveBackendUrl(path) })
  }
}

export default new LibroOfertasApi()

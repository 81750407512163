export enum TipoRedondeo {
  UP = 'UP',
  STANDARD = 'STANDARD',
  DOWN = 'DOWN',
}

export const TipoRedondeoOptions = [
  { value: TipoRedondeo.UP, label: TipoRedondeo.UP },
  { value: TipoRedondeo.STANDARD, label: TipoRedondeo.STANDARD },
  { value: TipoRedondeo.DOWN, label: TipoRedondeo.DOWN },
]

export default {
  availableFilterHeader: 'Filttro disponibles',
  availableHeader: 'Disponible',
  filterPlaceholder: 'Escriba acá',
  moveAllLeft: 'Mover todos',
  moveAllRight: 'Mover todos',
  moveLeft: 'Mover a disponibe',
  moveRight: 'Mover a seleccionado',
  moveLeftTooltip: 'Mover a disponibe',
  moveRightTooltip: 'Mover a seleccionado',
  moveBottom: 'Rearrange to bottom',
  moveDown: 'Rearrange down',
  moveUp: 'Rearrange up',
  moveTop: 'Rearrange to top',
  noAvailableOptions: 'No hay opciones disponibles',
  noSelectedOptions: 'No hay opciones seleccionadass',
  requiredError: 'Por favor seleccione al menos una opcion',
  selectedFilterHeader: 'Filtro seleccionados',
  selectedHeader: 'Seleccionado',
}

import * as React from 'react'

import { forwardRef, useCallback } from 'react'
import PropTypes from 'prop-types'
import Form from 'react-bootstrap/Form'

import styles from './BymaDropdownCheckItem.module.scss'

export interface BymaCheckItem {
  label: string
  name: string
  source: string
}

export interface BymaDropdownCheckItemProps {
  checkItem: BymaCheckItem
  onShow: () => void
  onHide: () => void
  checked: boolean
  resource: string
  disabled?: boolean
}

export const BymaDropdownCheckItem = forwardRef<any, BymaDropdownCheckItemProps>((props, ref) => {
  const { checkItem, onShow, onHide, checked, disabled = false } = props

  const onChange = useCallback(
    (event) => (event.target.checked ? onShow() : onHide()),
    [checkItem.source, onShow, onHide],
  )

  //console.log(`DEBUG BymaDropdownCheckItem render name=${checkItem.name} checked=${checked}`)

  return (
    //see https://react-bootstrap.github.io/forms/checks-radios/
    <Form.Check
      type='checkbox'
      name={checkItem.name}
      label={checkItem.label || checkItem.name}
      value={checkItem.name}
      key={checkItem.name}
      checked={checked}
      onChange={onChange}
      className={styles.bymaDropdownButtonCheckItem}
      disabled={disabled}
    />
  )
})

BymaDropdownCheckItem.propTypes = {
  //checkItem: PropTypes.element.isRequired,
  onShow: PropTypes.func.isRequired,
  //resource: PropTypes.string,
}

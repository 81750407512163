import { ResourceApi } from 'services/api'

import { Inversor } from './Model'

class InversorApi extends ResourceApi<Inversor> {
  constructor() {
    super('/api/inversores')
  }
}

export default new InversorApi()

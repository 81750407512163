import AxiosMockAdapter from 'axios-mock-adapter-path-params'

export const categoriasClienteResponse = [
  {
    id: 1,
    denominacion: 'Categoria 1',
  },
  {
    id: 2,
    denominacion: 'Categoria 2',
  },
  {
    id: 3,
    denominacion: 'Categoria 3',
  },
]

export const getCategoriasClienteResponse = (id: number) => {
  const entities = categoriasClienteResponse.filter((col) => col.id == id)
  return entities !== undefined && entities.length > 0 ? entities[0] : {}
}

export const mockCategoriasCliente = (mock: AxiosMockAdapter) => {
  mock.onGet('/api/categorias-cliente').reply(() => {
    return [200, categoriasClienteResponse]
  })

  mock.onGet('/api/categorias-cliente/options').reply(() => {
    return [200, categoriasClienteResponse]
  })
}

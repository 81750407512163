import React, { useMemo } from 'react'
import { BymaDataTable, BymaBadge } from 'components'
import { LoadingProvider } from 'core'
import { OfertaContextInfo } from 'features/common/Oferta/OfertaContextInfo'
import {
  OFERTA_ENTITY_NAME,
  ESTADO_OFERTA_GROUP_INFOS,
  ofertaValueLabelFormatter,
  ofertaCantidadFormatter,
  ofertaValorFormatter,
} from 'model'
import LibroOfertasApi from './LibroOfertasApi'

interface LibroOfertasProps {
  context: OfertaContextInfo
}

const LIBRO_COLUMNS = [
  {
    field: 'propia',
    headerName: '',

    //eliminamos padding para que ocupe menos espacio la columna
    cellStyle: { padding: '0px' },

    cellRenderer: BymaBadge,

    cellRendererParams: (params) => {
      return {
        text: params?.data?.propia ? 'Oferta propia' : '',
        type: 'info',
      }
    },
  },
  {
    field: 'id',
    headerName: 'N° de Oferta',
    suppressMenu: false,
  },
  {
    field: 'cantidad',
    valueFormatter: (params) => ofertaCantidadFormatter(params?.data?.cantidad),
    wrapHeaderText: false,
  },
  {
    headerValueGetter: (params) => {
      return ofertaValueLabelFormatter(params.context?.titulo)
    },
    field: 'valor',
    valueFormatter: (params) => ofertaValorFormatter(params?.data?.valor),
  },
  {
    field: 'estado',
    cellRenderer: BymaBadge,
    cellRendererParams: (params) => {
      const estado = params?.data?.estadoGroup
      const details = ESTADO_OFERTA_GROUP_INFOS[estado]

      return {
        text: details?.shorText || details?.text || '',
        type: details?.badgeType,
      }
    },
  },
]

const OFERTA_LIST_CLASSIFIER = 'dashboard.libro'

const LibroOfertasTable = (props: LibroOfertasProps) => {
  const context = props.context

  const queryKey = [
    OFERTA_ENTITY_NAME,
    OFERTA_LIST_CLASSIFIER,
    { titulo: context.currentTitulo?.id },
  ]

  const titulo = context.currentTitulo || {}
  const tituloId = context.currentTitulo?.id

  const dataProvider = () =>
    tituloId !== undefined
      ? LibroOfertasApi.getList(tituloId)
      : Promise.reject('No hay titulo definido')

  const columns = LIBRO_COLUMNS

  const tableContext = useMemo(() => ({ titulo: titulo }), [tituloId])

  return (
    <BymaDataTable
      title='Ofertas'
      key='libro'
      columns={columns}
      sizeColumnsToFit={true}
      style={{ height: '350px', width: '100%' }}
      pagination={false}
      suppressHorizontalScroll={true}
      sortable={false}
      context={tableContext}
      queryProps={{
        dataProvider: dataProvider,
        queryKey,
      }}
    />
  )
}

export default (props: LibroOfertasProps) => (
  <LoadingProvider>
    <LibroOfertasTable {...props} />
  </LoadingProvider>
)

import React, { CSSProperties, ReactNode } from 'react'
import Card from 'react-bootstrap/Card'

import styles from './BymaCard.module.scss'

interface CardHeader {
  title: string | React.ReactElement
  extras?: ReactNode
}

export type CardVariant = 'Default' | 'Colocacion' | 'ColocacionNew'
export interface CardProps extends BaseProps {
  children: ReactNode
  title?: string
  header?: CardHeader
  footer?: ReactNode
  className?: string
  padding?: string
  titleBackgorundColor?: string
  titleBorder?: string
  style?: CSSProperties | undefined
  variant?: CardVariant
}

const BymaCard = (props: CardProps) => {
  const {
    children,
    title = undefined,
    header = undefined,
    footer,
    className = '',
    padding = '20px',
    titleBackgorundColor = 'var(--primary-gray-dark)',
    titleBorder = 'none',
    style = {},
    variant = 'Default',
  } = props

  const height = 'inherit'
  const width = '100%'

  // Card background
  const background = style.background ? style.background : 'var(--secondary-gray-normal)'
  const backgroundColor = style.backgroundColor
    ? style.backgroundColor
    : 'var(--secondary-gray-normal)'
  const border = style.border ? style.border : '1px solid #1a73e8 !important'
  const borderRadius = style.borderRadius ? style.borderRadius : '20px'

  // Card inner background
  const innerBackground = style.background ? 'transparent' : 'var(--secondary-gray-normal)'
  const innerBackgroundColor = style.backgroundColor
    ? 'transparent'
    : 'var(--secondary-gray-normal)'
  const innerBorder = style.border ? 'transparent' : '1px solid #1a73e8 !important'
  const innerBorderRadius = style.borderRadius ? undefined : '20px'

  const bymaCardClassContainer = {
    Default: 'bymaCardClassContainerDefault',
    Colocacion: 'bymaCardClassContainerColocacion',
    ColocacionNew: 'bymaCardClassContainerColocacionNew',
  }

  return (
    <div
      className={styles[bymaCardClassContainer[variant]]}
      style={{
        width: width,
        height: height,
        padding: padding,
        background: background,
        backgroundColor: backgroundColor,
        borderRadius: borderRadius,
        border: border,
        ...style,
      }}
    >
      {title && (
        <Card.Header
          className={styles.bymaCardTitle}
          style={{
            backgroundColor: titleBackgorundColor,
            border: titleBorder,
          }}
        >
          {title}
        </Card.Header>
      )}
      <Card
        className={styles.bymaCard + ' ' + className}
        style={{
          background: innerBackground,
          backgroundColor: innerBackgroundColor,
          borderRadius: innerBorderRadius,
          border: innerBorder,
        }}
      >
        {header && (
          <Card.Header className={styles.bymaCardHeader}>
            <span className={styles.headerSpan}>{header.title}</span>
            {header.extras && <div>{header.extras}</div>}
          </Card.Header>
        )}
        <Card.Body className={styles.bymaCardBody}>{children}</Card.Body>
        {footer && <Card.Footer className={styles.bymaCardFooter}>{footer}</Card.Footer>}
      </Card>
    </div>
  )
}

export default BymaCard

import { useRef, useState } from 'react'

export interface DownloadFileProps {
  readonly serverDownload: () => Promise<Blob>
  readonly preDownloading: () => void
  readonly postDownloading: () => void
  readonly onError: (error: any) => void
  readonly getFileName: () => string
}

export interface DownloadedFileInfo {
  readonly download: () => Promise<void>
  readonly ref: React.MutableRefObject<HTMLAnchorElement | null>
  readonly name: string | undefined
  readonly url: string | undefined
  readonly isDownloading: boolean
}

/**
 * @see https://www.techprescient.com/react-custom-hook-typescript-to-download-a-file-through-api/
 * @param param0
 * @returns
 */
export const useDownloadFile = ({
  serverDownload,
  preDownloading,
  postDownloading,
  onError,
  getFileName,
}: DownloadFileProps): DownloadedFileInfo => {
  const ref = useRef<HTMLAnchorElement | null>(null)
  const [url, setFileUrl] = useState<string>()
  const [name, setFileName] = useState<string>()
  const [isDownloading, setDownloading] = useState<boolean>(false)

  const download = async () => {
    try {
      setDownloading(true)

      preDownloading()

      const blob = await serverDownload()

      const url = window.URL.createObjectURL(
        new Blob([blob], {
          type: 'application/csv',
        }),
      )

      setFileUrl(url)

      setFileName(getFileName())

      //see https://github.com/facebook/react/issues/25525
      setTimeout(() => {
        ref.current?.click()
        setDownloading(false)
        postDownloading()
        URL.revokeObjectURL(url)
      }, 100)
    } catch (error) {
      setDownloading(false)

      console.log(`DEBUG useFileDownLoad error=${error}`)

      onError(error)
    }
  }

  return { download, ref, url, name, isDownloading }
}

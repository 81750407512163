import React, { CSSProperties, ReactNode } from 'react'
import { ListFilters } from 'components'

import styles from './FiltersHeader.module.scss'
import { Container } from 'react-bootstrap'

interface FiltersHeaderProps {
  filters: ReactNode[]
  filtersStyle?: CSSProperties
  extra?: ReactNode[]
  extraStyles?: CSSProperties
  exportButton?: ReactNode
  showFiltersMenu: boolean
  filterFormStyles?: CSSProperties
  filtersWrapperColClassName?: string
  filterBtnColClassName?: string
  filterBtnClassName?: string
  listFiltersRowContainer?: any
}

export const FiltersHeader = (props: FiltersHeaderProps) => {
  const {
    filters,
    filtersStyle,
    extra,
    extraStyles,
    exportButton,
    showFiltersMenu,
    filterFormStyles,
    filterBtnColClassName,
    filterBtnClassName,
    filtersWrapperColClassName,
    listFiltersRowContainer,
  } = props

  return (
    <div className={styles.filtersHeader} style={filtersStyle}>
      <Container className={styles.filters}>
        <ListFilters
          filters={filters}
          showFiltersMenu={showFiltersMenu}
          filterFormStyles={filterFormStyles}
          filtersWrapperColClassName={filtersWrapperColClassName}
          filterBtnColClassName={filterBtnColClassName}
          filterBtnClassName={filterBtnClassName}
          listFiltersRowContainer={listFiltersRowContainer}
        />
      </Container>
      {extra && (
        <div className={styles.extraDiv} style={extraStyles}>
          {extra}
        </div>
      )}
      <div className={styles.exportButtonDiv}>{exportButton}</div>
    </div>
  )
}

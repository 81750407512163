import useAuthContext from './useAuthContext'

export const isComponentAuthorized = (
  componentPermissions: ComponentPermission[],
  userPermissions: string[] | undefined,
) => {
  const matchPermission = (list: string[], permission: ComponentPermission) => {
    return list.some((up) => {
      const result = up.match(permission)

      /*
      console.log(
        `DEBUG isComponentAuthorized=${result} userPermission = ${up} componentPermission=${permission} ${typeof permission}`,
      )
      */

      return result
    })
  }

  const result =
    !componentPermissions ||
    componentPermissions.length == 0 ||
    (userPermissions &&
      componentPermissions.some((permission) => matchPermission(userPermissions, permission)))
  return result
}

export const authorizeComponent = <T,>(
  component: T,
  componentPermissions: ComponentPermission[],
  userPermissions: string[] | undefined,
  fallBackComponent?: false | JSX.Element,
): T | false | JSX.Element =>
  isComponentAuthorized(componentPermissions, userPermissions)
    ? component
    : fallBackComponent !== undefined
    ? fallBackComponent
    : false

export const withPermissions = <T,>(
  component: T,
  componentPermissions: ComponentPermission[],
  fallBackComponent?: false | JSX.Element,
): T | false | JSX.Element => {
  const authContext = useAuthContext()
  return authorizeComponent(
    component,
    componentPermissions,
    authContext.userInfo?.authorities,
    fallBackComponent,
  )
}

export const isPasswordExpired = () => {
  const authContext = useAuthContext()
  return authContext !== undefined &&
    authContext.userInfo !== undefined &&
    authContext.userInfo.passwordExpirationDays !== undefined &&
    authContext.userInfo.passwordExpirationDays <= 0
    ? true
    : false
}

export const userHasAuthority = (user: UserInfo | undefined, authority: string) =>
  user && user.authorities.includes(authority)

import { ResourceApi } from 'services/api/resourceApi'
import { TIPO_COLOCACION_OPTIONS_KEY, TipoColocacion } from './TipoColocacion'

class TipoColocacionApi extends ResourceApi<TipoColocacion> {
  constructor() {
    super('/api/tipos-colocacion')
  }
}

const TipoColocacionApiInstance = new TipoColocacionApi()

export const TipoColocacionOptionsQueryProps = {
  dataProvider: () => TipoColocacionApiInstance.getOptions(),
  listKey: TIPO_COLOCACION_OPTIONS_KEY,
}

export default TipoColocacionApiInstance

import React from 'react'
import { EntityModal } from 'features/common/Entity'

import { Inversor } from 'model/inversor'
import { EntityModalProps } from 'features/common/Entity'
import { InversorForm } from './InversorForm'

const InversorSchemaProperties = (yup) =>
  yup.object().shape({
    numero: yup.string().required(),
    denominacion: yup.string().required(),
    tipoDocumento: yup.string().required(),
  })

const InversorModal = (props: EntityModalProps<Inversor>) => {
  const editEnabled = props.context.editEnabled

  return (
    <EntityModal
      {...props}
      focusField='numero'
      getSchema={InversorSchemaProperties}
      renderCreateForm={(props) =>
        InversorForm({ ...props, cardTitle: 'Nuevo FCI/SSN', editEnabled: true })
      }
      renderEditForm={(props) =>
        InversorForm({
          ...props,
          cardTitle: editEnabled ? 'Editar FCI/SSN' : 'Detalle FCI/SSN',
          editEnabled,
        })
      }
    />
  )
}

export default InversorModal

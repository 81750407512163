import React, { useEffect } from 'react'

import BymaToast from './BymaToast'

import { useNotificationConsumer, useNotificationProducer } from 'core/notification'
import { Col, Row } from 'react-bootstrap'

const BymaTransientToast = ({ timeout = 5000 }) => {
  const { removeNotifications } = useNotificationProducer()
  const { notifications } = useNotificationConsumer()

  useEffect(() => {
    //TODO JIRA Optimizar eliminando el interval una vez que se eliminan todas las notificaciones
    const interval = setInterval(() => {
      removeNotifications(timeout)
    }, timeout)

    return () => {
      clearInterval(interval)
    }
  }, [removeNotifications])

  //console.log(`BymaTransientToast render notifications=${JSON.stringify(notifications)}`)

  return (
    <>
      {notifications.length > 0 ? (
        <div>
          {notifications.map((not: any, idx: number) => {
            return (
              <Row key={'row-' + idx}>
                <Col key={'col-' + idx}>
                  <BymaToast
                    key={'notification-' + idx}
                    body={not.message}
                    type={not.type === 'error' ? 'error' : 'success'}
                  />
                </Col>
              </Row>
            )
          })}
        </div>
      ) : null}
    </>
  )
}

export default BymaTransientToast

import * as React from 'react'
import { useState, useCallback, ReactNode, HtmlHTMLAttributes, useContext } from 'react'

import PropTypes from 'prop-types'
import { useListContext } from 'core'

import { FilterButtonMenuItem } from './FilterButtonMenuItem'
import { FilterContext } from './FilterContext'
import { BymaDropdownButton } from 'components/Buttons/BymaDropdownButton'
import styles from './Filter.module.scss'

export interface FilterButtonProps extends HtmlHTMLAttributes<HTMLDivElement> {
  //classes?: ClassesOverride<typeof useStyles>;
  className?: string
  resource?: string
  filterValues?: any
  showFilter?: (filterName: string, defaultValue: any) => void
  displayedFilters?: any
  filters?: ReactNode[]
}

const FilterButton = (props: FilterButtonProps): JSX.Element | null => {
  const {
    filters: filtersProp,
    //classes: classesOverride,
    className,
    ...rest
  } = props
  const filters = useContext(FilterContext) || filtersProp

  const { displayedFilters = {}, showFilter, hideFilter } = useListContext(props)
  const [, setOpen] = useState(false)

  console.log(`DEBUG FilterButton render displayedFilters= ${JSON.stringify(displayedFilters)}`)

  const handleShow = useCallback(
    ({ source, defaultValue }) => {
      showFilter(source, defaultValue)
      setOpen(false)
    },
    [showFilter, setOpen],
  )

  const handleHide = useCallback(
    ({ source }) => {
      hideFilter(source)
      setOpen(false)
    },
    [hideFilter, setOpen],
  )

  return (
    <div {...sanitizeRestProps(rest)} className={styles.filterBtnContainer}>
      <BymaDropdownButton
        id='filter-button'
        name='filter-button'
        iconLeft='add-filter.svg'
        className={className}
      >
        {(filters || []).map((filter) => {
          const filterElement = filter as JSX.Element
          const alwaysOn = filterElement?.props?.alwaysOn

          const checked =
            alwaysOn || displayedFilters[filterElement.props.name] !== undefined
              ? displayedFilters[filterElement.props.name]
              : false

          return alwaysOn ? undefined : (
            <FilterButtonMenuItem
              key={filterElement.props.name}
              filter={filterElement}
              resource=''
              onShow={handleShow}
              onHide={handleHide}
              checked={checked}
            />
          )
        })}
      </BymaDropdownButton>

      {/*
            <Dropdown className={styles.bymaDropdownButton}>
                <Dropdown.Toggle variant="success" id="dropdown-basic">
                    Filtros
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    {

                        (filters || []).map((filter) => {
                            const filterElement = filter as JSX.Element
                            const checked =
                                displayedFilters[filterElement.props.name] !== undefined ?
                                    displayedFilters[filterElement.props.name]
                                    : false

                            return (
                                <FilterButtonMenuItem
                                    key={filterElement.props.name}
                                    filter={filterElement}
                                    resource=""
                                    onShow={handleShow}
                                    onHide={handleHide}
                                    checked={checked}
                                />
                            )
                        }
                        )
                    }

                </Dropdown.Menu>
            </Dropdown>
            */}
    </div>
  )
}

const sanitizeRestProps = ({ ...rest }) => rest

FilterButton.propTypes = {
  resource: PropTypes.string,
  filters: PropTypes.arrayOf(PropTypes.node),
  displayedFilters: PropTypes.object,
  filterValues: PropTypes.object,
  showFilter: PropTypes.func,
  classes: PropTypes.object,
  className: PropTypes.string,
}
export default FilterButton

import React, { ReactNode } from 'react'

import Dropdown from 'react-bootstrap/Dropdown'

import styles from './BymaDropdownButton.module.scss'
import { getIconPath } from 'components'

export interface DropdownButtonProps {
  id: string
  name: string
  label?: string
  className?: string
  width?: string
  height?: string
  onClick?: any
  iconLeft?: string
  iconRight?: string
  backgroundColor?: string
  border?: string
  disabled?: boolean
  children: ReactNode
}

export const BymaDropdownButton = (props: DropdownButtonProps) => {
  const { label, iconLeft, iconRight, className = '', children } = props

  return (
    <Dropdown className={styles.bymaDropdownButton}>
      <Dropdown.Toggle
        variant='primary'
        id='dropdown-basic'
        className={styles.dropdownToggle + ' ' + className}
      >
        {iconLeft ? (
          <img className={styles.iconLeftAddon} src={getIconPath(iconLeft)} />
        ) : undefined}
        <span>{label ? label : ''}</span>
        {iconRight ? (
          <img className={styles.iconRightAddon} src={getIconPath(iconRight)} />
        ) : undefined}
      </Dropdown.Toggle>
      <Dropdown.Menu className={styles.bymaDropdownMenu}>{children}</Dropdown.Menu>
    </Dropdown>
  )
}

import React, { useEffect } from 'react'
import { ColocacionSectionCard } from '../sections/ColocacionSectionCard'
import { ColocacionSummary } from '../ColocacionSummary'
import {
  ColocacionRoutePaths,
  ColocacionViewContext,
  useColocacionEditContext,
} from '../ColocacionViewContext'
import TotalesGeneralesTituloTable from './TotalesGeneralesTituloTable'
import LibroOfertasColocacion from './LibroOfertasColocacion'
import { Route } from 'react-router-dom'
import SimulacionCorte from './SimulacionCorte'

import styles from './ColocacionBoard.module.scss'
import OfertasPorValor from './OfertasPorValor'

export interface ColocacionesBoardProps extends ColocacionViewContext {
  key?: string
}

export const ColocacionesBoardSection = () => {
  const context = useColocacionEditContext()

  useEffect(() => {
    context.ensureColocacion()
  }, [context])

  /*
  const items = [
    {
      id: 'totalesGeneralesTitulo',
      title: 'Totales Generales por Titulo',
      content: (
        <div className={styles.boardItemContainer}>
          <TotalesGeneralesTituloTable context={context} />
        </div>
      ),
    },
    {
      id: 'libroOfertas',
      title: 'Libro de ofertas',
      content: (
        <div className={styles.boardItemContainer}>
          <LibroOfertasColocacion {...context} />
        </div>
      ),
    },
  ]
  */

  return (
    <ColocacionSectionCard>
      <div className={styles.ColocacionBoardContent}>
        <ColocacionSummary {...context} colocacionActionEnabled={true} />

        <div className={styles.boardItemContainer}>
          <TotalesGeneralesTituloTable context={context} />
        </div>

        <div className={styles.boardItemContainer}>
          <LibroOfertasColocacion {...context} />
        </div>

        {/*
        {items[0].content}
        {items[1].content}
                <BymaSortableList
                    key={'byma-sortable-list'}
                    items={items}
                />
                */}
      </div>
    </ColocacionSectionCard>
  )
}

export const ColocacionBoardRoutes = (
  <>
    <Route path={ColocacionRoutePaths.tituloCalculoCorte} element={<SimulacionCorte />} />
    <Route path={ColocacionRoutePaths.tituloOfertasPorValor} element={<OfertasPorValor />} />
  </>
)

import React, { useState, useCallback } from 'react'
import { ColocacionContext } from './ColocacionContext'
import { withContextProviders } from 'core/util'
import { Colocacion } from 'model/colocacion'
import { Titulo } from 'model/titulo'
import { useOfertaContext } from 'features/common/Oferta'

export const ColocacionContextProvider = ({ children }) => {
  const [colocacion, setColocacion] = useState<Colocacion | undefined>(undefined)
  const [titulo, setTitulo] = useState<Titulo | undefined>(undefined)
  const createOfertaContext = useOfertaContext()
  const editOfertaContext = useOfertaContext()

  const setColocacionImpl = useCallback((c) => {
    setColocacion(c)
    createOfertaContext.setCurrentColocacion(c)
    editOfertaContext.setCurrentColocacion(c)
  }, [])

  const value = {
    colocacion,

    setColocacion: setColocacionImpl,

    titulo,

    setTitulo,

    createOfertaContext,

    editOfertaContext,
  }

  return <ColocacionContext.Provider value={value}>{children}</ColocacionContext.Provider>
}

export const withColocacionContextProvider = withContextProviders([
  { provider: ColocacionContextProvider, props: {} },
])

import { ResourceApi, resolveBackendUrl } from '../../services/api/resourceApi'
import { Agente } from './Agente'

class AgentesApi extends ResourceApi<Agente> {
  constructor() {
    super('/api/agentes')
  }

  getColocacionAgentes = async (id: any, params: QueryParams) => {
    const path = `/api/colocaciones/${id}/agentes`
    return this.apiMethods.getList({
      url: resolveBackendUrl(path),
      params: { ...params, sort: undefined },
    })
  }
}

export default new AgentesApi()

import axios, { AxiosInstance } from 'axios'
import AxiosMockAdapter from 'axios-mock-adapter-path-params'

import { initMock } from '../mock'
import { toApiError } from './apiError'
interface AxiosImpl {
  axiosInstance: AxiosInstance
  axiosMockAdapterInstance: AxiosMockAdapter
}

const applyMiddleware = (axiosInstance: AxiosInstance) => {
  //debug request
  axiosInstance.interceptors.request.use((request) => {
    console.log(
      `DEBUG API-REQUEST ${request.method} ${request.url}`,
      JSON.stringify(request, null, 2),
    )

    return request
  })

  //debug response
  axiosInstance.interceptors.response.use((response) => {
    console.log(
      `DEBUG API-RESPONSE ${response.config.url} ${response.config.method}`,
      JSON.stringify(response, null, 2),
    )

    return response
  })

  //error response
  axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
      console.log('DEBUG axiosInterceptor error')
      return Promise.reject(toApiError(error))
    },
  )

  return axiosInstance
}

const createAxiosMock = (): AxiosImpl => {
  console.log('ATENCION: Utilizando Axios MOCK')

  const routeParams = {
    ':id': '[0-9]{1,8}',
  }

  const axiosMockInstance = axios.create()
  const axiosMockAdapterInstance = new AxiosMockAdapter(
    axiosMockInstance,
    {
      delayResponse: 500,
    },
    routeParams,
  )

  initMock(axiosMockAdapterInstance)
  return {
    axiosInstance: applyMiddleware(axiosMockInstance),
    axiosMockAdapterInstance,
  }
}

const createAxiosLive = () => {
  const axiosLiveInstance = axios.create({})
  return {
    axiosInstance: applyMiddleware(axiosLiveInstance),
    axiosMockAdapterInstance: null,
  }
}

export const { axiosInstance, axiosMockAdapterInstance } =
  process.env.REACT_APP_isAxiosMock === 'true' ? createAxiosMock() : createAxiosLive()

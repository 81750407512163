const KEYS = {
  SPACEBAR: ' ',
  ENTER: 'Enter',
}

const ALIGNMENTS = {
  MIDDLE: 'middle',
  TOP: 'top',
}

export { KEYS, ALIGNMENTS }

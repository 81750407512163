export const routes = {
  dashboard: '/dashboard',
  home: '/home',
  historial_colocaciones: '/historial-colocaciones/*',
  colocadores: '/colocadores',

  colocaciones_new: '/colocaciones/new',
  colocaciones_view: '/colocaciones/view/:colocacionId',

  agentes: '/agentes',
  emisores: '/emisores',
  grupos_economicos: '/grupos-economicos',
  fci_ssn: '/fci-ssn',
  nacionalidades: '/nacionalidades',
  roles: '/roles',
  help: '/help',
  help_edit: '/help/edit',
  change_password: '/change-password',
  change_password_expired: '/change-password-expired',
}

export const getColocacionViewRoute = (id) => routes.colocaciones_view.replace(':colocacionId', id)

export const getColocacionDetailRoute = (id) =>
  `${routes.colocaciones_view.replace(':colocacionId', id)}/detail`

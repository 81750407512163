/* eslint-disable @typescript-eslint/no-unused-vars */

import React, { useEffect, useState } from 'react'
import { Container } from 'react-bootstrap'
import { Route, Routes, useLocation } from 'react-router-dom'

import { BrowserRouter } from 'react-router-dom'

import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'

import AuthContainer from './core/auth/AuthContainer'
import useAuthContext from './core/auth/useAuthContext'

import { QueryClientProvider, QueryClient } from 'react-query'

import Login from './features/Login/Login'
import AppRoutes from './AppRoutes'

import AppNavbar from './components/Navbar'

import 'ag-grid-community/styles/ag-grid.css' // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-alpine.css' // Optional theme CSS
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/css/bootstrap.css'

import './App.scss'
import './index.scss'

// NOTA: Usamos un unico notification provider: si no queremos compartir mensajes en diferentes pantallas hay que
// definir el NotificationProvider para cada pantalla
import { NotificationProvider } from './core/notification'

import { BymaModal } from './components'

const PasswordWarning = ({ passwordExpirationDays }) => {
  const [passwordWarningConfirmed, setPasswordWarningConfirmed] = useState(false)

  return !passwordWarningConfirmed ? (
    <BymaModal
      id={'expiration-pass-message-modal'}
      show={true}
      children={<p>{`Atencion: Su contraseña vence en ${passwordExpirationDays} dias`}</p>}
      showCancel={false}
      handleCancel={() => {}}
      handleConfirm={() => {
        setPasswordWarningConfirmed(true)
      }}
    />
  ) : null
}

function MainContainer() {
  const auth = useAuthContext()
  const { pathname } = useLocation()

  if (!auth.authenticated) {
    return <Login />
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  //TODO la logica para manejar la expiracion quedo distribuida en varios componentes
  //(Navbar, Changepassword, ProtectedRoute)
  //creo q seria mejor construir un componente para el cambio de password expirada que use un Navbar vacio y sin rutas
  const passwordExpirationDays = auth.getPasswordExpirationDays()

  const passwordExpired = passwordExpirationDays !== undefined && passwordExpirationDays <= 0

  const passwordWarning =
    passwordExpirationDays !== undefined && passwordExpirationDays > 0 && passwordExpirationDays < 5

  return (
    <Container fluid>
      <AppNavbar empty={passwordExpired} />
      <Container fluid className='app-container'>
        <AppRoutes />
        {passwordWarning ? (
          <PasswordWarning passwordExpirationDays={passwordExpirationDays} />
        ) : null}
      </Container>
    </Container>
  )
}

function AppContainer() {
  return (
    <Container fluid>
      <MainContainer />
    </Container>
  )
}

function App() {
  const queryClient = new QueryClient()

  return (
    <QueryClientProvider client={queryClient}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <AuthContainer>
          <BrowserRouter basename={'/'}>
            <NotificationProvider>
              <AppContainer />
            </NotificationProvider>
          </BrowserRouter>
        </AuthContainer>{' '}
      </LocalizationProvider>
    </QueryClientProvider>
  )
}

export default App

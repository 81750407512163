import { isNullOrUndefined } from 'utils/objects'

export interface NumberConstraint {
  maxIntegerDigits?: number
  maxFractionalDigits?: number
}

export const DefaultDecimalConstraint: NumberConstraint = {
  maxIntegerDigits: 12,
  maxFractionalDigits: 7,
}

export const DefaultIntegerConstraint: NumberConstraint = {
  maxIntegerDigits: 19,
  maxFractionalDigits: 0,
}

export const numberConstraintYup = (yup, nc: NumberConstraint, required = true) => {
  // console.log(`DEBUG numberConstraintYup nc=${JSON.stringify(nc)}`)

  let result = yup.number().transform((val, orig) => (orig === null ? undefined : val))

  result = required ? result.required() : result.nullable()

  if (nc.maxFractionalDigits === 0) {
    result = result.integer()
  }

  if (!isNullOrUndefined(nc.maxIntegerDigits) && !isNullOrUndefined(nc.maxFractionalDigits)) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const maxValue = 10 ** nc.maxIntegerDigits!
    result = result.lessThan(maxValue)
  }

  return result
}

/*
Titulo

		<property name="totalEmision" column="TOTAL_EMISION" scale="7" precision="19" not-null="true"/>
		<property name="valorReferencia" column="VALOR_REFERENCIA" scale="7" precision="19"/>
		
		<property name="unidadMinima" column="UNIDAD_MINIMA" scale="7" precision="19"/>
		<property name="unidadProrrateo" column="UNIDAD_PRORRATEO" scale="7" precision="19"/>

		<property name="unidadMinimaNoCompetitivo" column="UNIDAD_MINIMA_NC" scale="7" precision="19"/>
		<property name="unidadProrrateoNoCompetitivo" column="UNIDAD_PRORRATEO_NC" scale="7" precision="19"/>
		
		<property name="montoMaximoCompetitivo" column="MONTO_MAXIMO_COMP" scale="7" precision="19"/>
		<property name="montoMaximoNoCompetitivo" column="MONTO_MAXIMO_NO_COMP" scale="7" precision="19"/>

		<property name="montoMinimoOferta" column="MONTO_MINIMO_OFERTA" scale="7" precision="19"/>
		<property name="montoMinimoOfertaNoCompetitivo" column="MONTO_MINIMO_OFERTA_NC" scale="7" precision="19"/>
		
		<property name="montoMinimoAdj" column="MONTO_MINIMO_ADJ" scale="7" precision="19"/>
		<property name="montoMinimoAdjNoCompetitivo" column="MONTO_MINIMO_ADJ_NC" scale="7" precision="19"/>


        <!-- excepciones -->
        <property name="variacionPorcentual" column="VARIACION_PORCENTUAL" scale="2" precision="7"/>
        <property name="porMaxTramoNoCompetitivo" column="POR_TRAMO_NO_COMP" scale="2" precision="5"/>


        <property name="maturity" column="MATURITY" scale="0" precision="19"/>
		
		<property name="duration" column="DURATION" scale="7" precision="19"/>

*/

/* Canje
<property name="precio" column="PRECIO" scale="7" precision="19" not-null="true"/>

<property name="porcentaje" column="PORCENTAJE" scale="7" precision="19" not-null="true"/>

<property name="cantidadMinima" column="CANTIDAD_MINIMA" scale="0" precision="19" not-null="true"/>

<property name="laminaMinima" column="LAMINA_MINIMA" scale="0" precision="19" not-null="true"/>
*/

/* FLUJO 
<property name="monto" column="MONTO" scale="7" precision="19" not-null="true"/>
*/

/*
INTEGRACION
        <property name="cotizacion" column="COTIZACION" scale="10" precision="19" not-null="true"/>

        <property name="cantidadMinima" column="CANTIDAD_MINIMA" scale="10" precision="19" not-null="true"/>

        <property name="laminaMinima" column="LAMINA_MINIMA" scale="10" precision="19" not-null="true"/>

        <property name="totalOfertado" column="TOTAL_OFERTADO" scale="10" precision="25" not-null="false"/>

        <property name="totalAdjudicado" column="TOTAL_ADJUDICADO" scale="10" precision="25" not-null="false"/>

        <property name="totalIntegrado" column="TOTAL_INTEGRADO" scale="10" precision="25" not-null="false"/>

*/

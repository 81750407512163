import React from 'react'

import { Col, Row } from 'react-bootstrap'
import { BymaBadge, BymaPair } from 'components'

import styles from './ColocacionTituloDetail.module.scss'

import { tituloTotalEmisiomFormatter, tituloCantidadFormatter, tituloPrecioFormatter } from 'model'

const getColocacionTituloDetail = (titulo) => {
  const tc = titulo?.tramoCompetitivo
  const tnc = titulo?.tramoNoCompetitivo

  const emision = `VN Emisión: ${tituloTotalEmisiomFormatter(titulo.totalEmision)}`
  const tipoValor = `Tipo ${titulo.tipoValor}`

  const detailBadgeText = `   ${emision}       ${tipoValor}   `
  const preservedStr = detailBadgeText.replace(/ /g, '\u00A0')
  return (
    <>
      <div className={styles.tituloDetail}>
        {titulo.tramoCompetitivoHabilitado && (
          <>
            <Row sm={12} className={styles.tituloDetail_rowEmision}>
              <Col sm={3}>
                <BymaBadge text={preservedStr} type='grey' />
              </Col>
            </Row>
            <Row sm={12} className={styles.tituloDetail_rowTramo}>
              <Col sm={2}>
                <BymaBadge text='Tramo competitivo' type='dark' />
              </Col>
            </Row>
            <Row sm={12} className={styles.tramoDetail}>
              <Col sm={2}>
                <BymaPair
                  text='Cantidad mínima'
                  value={tituloCantidadFormatter(tc?.cantidadMinimaOferta)}
                />
              </Col>
              <Col sm={3}>
                <BymaPair
                  text='Cantidad máxima'
                  value={tituloCantidadFormatter(tc?.cantidadMaximaOferta)}
                />
              </Col>
              <Col sm={2}>
                <BymaPair
                  text='Precio mínimo'
                  value={tituloPrecioFormatter(tc?.precioMinimoOferta)}
                />
              </Col>
              <Col sm={2}>
                <BymaPair
                  text='Precio máximo'
                  value={tituloPrecioFormatter(tc?.precioMaximoOferta)}
                />
              </Col>
              <Col sm={2}>
                <BymaPair text='Multiplo oferta' value={tc?.multiploOferta} />
              </Col>
            </Row>
            <div className={styles.tramoDivisor} />
          </>
        )}
        <Row sm={12} className={styles.tituloDetail_rowTramo}>
          <Col sm={2}>
            <BymaBadge text='Tramo no competitivo' type='dark' />
          </Col>
        </Row>
        <Row sm={12} className={styles.tramoDetail}>
          <Col sm={2}>
            <BymaPair
              text='Cantidad mínima'
              value={tituloCantidadFormatter(tnc?.cantidadMinimaOferta)}
            />
          </Col>
          <Col sm={3}>
            <BymaPair
              text='Cantidad máxima'
              value={tituloCantidadFormatter(tnc?.cantidadMaximaOferta)}
            />
          </Col>
          <Col sm={2}>
            <BymaPair text='Multiplo oferta' value={tnc?.multiploOferta} />
          </Col>
        </Row>
      </div>
    </>
  )
}

export default getColocacionTituloDetail

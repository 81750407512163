import React, { useState } from 'react'
import { ThemeConfig } from 'bootstrap-darkmode'
import Container from 'react-bootstrap/Container'
import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'
import NavDropdown from 'react-bootstrap/NavDropdown'
import { LinkContainer } from 'react-router-bootstrap'
import BooleanInput from 'components/Inputs/BooleanInput/BooleanInput'

import { withPermissions, useLogout, useAuthContext } from 'core/auth'

import './AppNavbar.scss'

import { routes } from 'routes/routes'

import * as BymaPermissions from 'services/auth/bymaPermissions'
import {
  BymaButton,
  AddIcon,
  LogoutIcon,
  LockIcon,
  PersonFilledGrayIcon,
  NavBarMessageIcon,
  NavBarInfoIcon,
  NavBarSettingsIcon,
  BymaClock,
} from '..'
import BymaInfoBar from './BymaInfoBar'

const ADMIN_MENU_PERMISSIONS = [
  ...BymaPermissions.ROLES_PERMISSIONS,
  ...BymaPermissions.COLOCADORES_PERMISSIONS,
  ...BymaPermissions.AGENTES_PERMISSIONS,
  ...BymaPermissions.EMISORES_PERMISSIONS,
  ...BymaPermissions.GRUPO_ECONOMICO_PERMISSIONS,
  ...BymaPermissions.INVERSOR_PERMISSIONS,
  ...BymaPermissions.NACIONALIDAD_PERMISSIONS,
]

interface NavBarProps {
  empty?: boolean
}

const AppNavBarItems = () => {
  const logout = useLogout()

  const { userInfo } = useAuthContext()

  return (
    <Navbar.Collapse id='responsive-navbar-nav'>
      <Nav className='app-navbar-items me-auto my-2 my-lg-0' navbarScroll>
        {withPermissions(
          <LinkContainer to='/historial-colocaciones'>
            <Nav.Item>Historial de colocaciones</Nav.Item>
          </LinkContainer>,
          BymaPermissions.COLOCACION_HISTORIAL_PERMISSION,
        )}
        {withPermissions(
          <NavDropdown
            title='Administrar'
            id='admin-scrolling-dropdown'
            className='config-dropdown'
          >
            {withPermissions(
              <LinkContainer to='/roles'>
                <NavDropdown.Item>Roles</NavDropdown.Item>
              </LinkContainer>,
              BymaPermissions.ROLES_PERMISSIONS,
            )}

            {withPermissions(
              <LinkContainer to='/agentes'>
                <NavDropdown.Item>Agentes</NavDropdown.Item>
              </LinkContainer>,
              BymaPermissions.AGENTES_PERMISSIONS,
            )}

            {withPermissions(
              <LinkContainer to='/colocadores'>
                <NavDropdown.Item>Colocadores</NavDropdown.Item>
              </LinkContainer>,
              BymaPermissions.COLOCADORES_PERMISSIONS,
            )}
            {withPermissions(
              <LinkContainer to='/emisores'>
                <NavDropdown.Item>Emisores</NavDropdown.Item>
              </LinkContainer>,
              BymaPermissions.EMISORES_PERMISSIONS,
            )}
            {withPermissions(
              <LinkContainer to='/grupos-economicos'>
                <NavDropdown.Item>Grupos Económicos</NavDropdown.Item>
              </LinkContainer>,
              BymaPermissions.GRUPO_ECONOMICO_PERMISSIONS,
            )}
            {withPermissions(
              <LinkContainer to='/fci-ssn'>
                <NavDropdown.Item>FCI/SSN</NavDropdown.Item>
              </LinkContainer>,
              BymaPermissions.INVERSOR_PERMISSIONS,
            )}
            {withPermissions(
              <LinkContainer to='/nacionalidades'>
                <NavDropdown.Item>Nacionalidaes</NavDropdown.Item>
              </LinkContainer>,
              BymaPermissions.NACIONALIDAD_PERMISSIONS,
            )}
          </NavDropdown>,
          ADMIN_MENU_PERMISSIONS,
        )}
      </Nav>

      <Nav className='right-buttons d-flex align-items-center'>
        {withPermissions(
          <LinkContainer to={routes.colocaciones_new}>
            <BymaButton
              id={routes.colocaciones_new}
              label='Nueva Colocación'
              name={routes.colocaciones_new}
              iconLeft={AddIcon}
              iconStyles={{ width: 12 }}
            />
          </LinkContainer>,
          BymaPermissions.COLOCACION_CREATE_PERMISSION,
        )}

        <Nav.Link className='navbar-button' style={{ marginLeft: '4px !important' }}>
          <LinkContainer to='/messages'>
            <NavDropdown.Item>
              <img className='navbar-button-icon' src={NavBarMessageIcon} />
            </NavDropdown.Item>
          </LinkContainer>
        </Nav.Link>

        <Nav.Link className='navbar-button'>
          <LinkContainer to='/help'>
            <NavDropdown.Item>
              <img className='navbar-button-icon' src={NavBarInfoIcon} />
            </NavDropdown.Item>
          </LinkContainer>
        </Nav.Link>

        <NavDropdown
          id='config-scrolling-dropdown'
          className='config-dropdown'
          title={<img className='navbar-button-icon' src={NavBarSettingsIcon} />}
        >
          <Nav.Link>
            <BooleanInput
              id='dark-mode-button'
              label='Darkmode'
              value='dark-item'
              onChange={() => /*setDarkTheme(!darkTheme)*/ { }}
              disabled={true}
            />
          </Nav.Link>
          <NavDropdown.Divider />
          <Nav.Link>
            <LinkContainer to='/change-password'>
              <NavDropdown.Item>
                <img
                  className='navbar-dropdown-icon'
                  style={{ paddingLeft: '5px' }}
                  src={LockIcon}
                />
                <span style={{ marginLeft: '4px' }}>Contraseña</span>
              </NavDropdown.Item>
            </LinkContainer>
          </Nav.Link>
          <Nav.Link>
            <LinkContainer to='/login'>
              <NavDropdown.Item onClick={logout}>
                <img className='navbar-dropdown-icon' src={LogoutIcon} />
                <span>Cerrar sesión</span>
              </NavDropdown.Item>
            </LinkContainer>
          </Nav.Link>
          <Nav.Link>
            <LinkContainer to='/'>
              <NavDropdown.Item>
                <img
                  className='navbar-dropdown-icon'
                  style={{ marginLeft: '5px' }}
                  src={PersonFilledGrayIcon}
                />
                <span className='user-label'>{userInfo?.username}</span>
              </NavDropdown.Item>
            </LinkContainer>
          </Nav.Link>
        </NavDropdown>
      </Nav>
    </Navbar.Collapse>
  )
}

const AppNavBar = ({ empty = false }: NavBarProps) => {
  //const navigate = useNavigate()
  const [theme, setTheme] = useState<string>('dark')

  const themeConfig = new ThemeConfig()
  themeConfig.loadTheme = () => {
    return theme
  }
  themeConfig.initTheme()

  return (
    <div className='byma-navbar'>
      <Navbar bg={theme} expand='lg' className='app-navbar'>
        <Container fluid className='app-navbar-content'>
          <Navbar.Brand className='navbar-logo'>
            <img alt='' src='/images/logos/logo-navbar.svg' className='d-inline-block align-top' />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls='responsive-navbar-nav' />
          {!empty ? <AppNavBarItems /> : null}
        </Container>
      </Navbar>
      <BymaInfoBar empty={empty}/>
    </div>
  )
}

export default AppNavBar

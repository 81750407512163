import React, { useCallback, useMemo } from 'react'

import { Row } from 'react-bootstrap'

import { LoadingProvider, LocalErrorProvider } from 'core'
import { BymaStepper } from 'components'

import {
  TituloDetailStepFields,
  tituloDetailStepYup,
  tituloDetailStepFieldNames,
} from '../TituloFields/TituloDetailStep'

import {
  TituloTramosStepFields,
  tituloTramosStepYup,
  tramosStepFieldNames,
} from '../TituloFields/TituloTramosStep'

import * as yup from 'yup'
import { TitulosApi, tituloFormDataToModel } from 'model'
import {
  ColocacionEditProps,
  ColocacionSectionType,
  useColocacionEditContext,
} from '../../ColocacionViewContext'

import { ColocacionSectionCard } from '../ColocacionSectionCard'
import TituloEditIcon from 'assets/img/colocacion-view/titulo_edit.svg'
import { ColocacionSummary } from 'features/Colocacion/ColocacionSummary'

const tituloDefaultValues = {
  sustentable: false,
  admiteCap: false,
  interaccionSiopel: false,
  cuitObligatorio: false,
  tramoCompetitivo: { habilitado: true },
}

const TituloForm = (props: ColocacionEditProps) => {
  const titulosApi = TitulosApi

  const createTitulo = useCallback((data) => titulosApi.create(tituloFormDataToModel(data)), [])

  const { colocacionId } = props
  const detailStepSchema = useMemo(() => tituloDetailStepYup(yup), [yup])
  const tramosStepSchema = useMemo(() => tituloTramosStepYup(yup), [yup])

  const onSuccess = useCallback((data) => {
    props.setSection(ColocacionSectionType.TITULO, { tituloId: data?.id, moveBack: true })
  }, [])

  //seteamos colocacionId
  const actualDefaultValues = useMemo(
    () => ({ ...tituloDefaultValues, colocacionId }),
    [colocacionId],
  )

  return (
    <BymaStepper
      id='create-titulo'
      activeStep={0}
      variant={'small'}
      onCancel={() => {}}
      successNotification={() => 'Se ingresó el título exitosamente'}
      errorNotification={(error) => `Error Ingresando el título: ${error.message}`}
      mutation={createTitulo}
      onSuccess={onSuccess}
      defaultValues={actualDefaultValues}
      showStepLabels={false}
      style={{
        backgroundColor: 'var(--secondary-gray-normal)',
        borderRadius: '20px',
        padding: '0px',
      }}
      steps={[
        {
          label: 'Datos generales',
          renderChildren: () => <TituloDetailStepFields {...props} />,
          validationSchemaSteps: detailStepSchema,
          stepFields: tituloDetailStepFieldNames,
        },
        {
          label: 'Fechas y horarios',
          renderChildren: () => <TituloTramosStepFields {...props} />,
          validationSchemaSteps: tramosStepSchema,
          stepFields: tramosStepFieldNames,
        },
      ]}
    />
  )
}

export const CreateTituloSection = () => {
  const props = useColocacionEditContext(true)

  return (
    <ColocacionSectionCard showFooter={false} title='Nuevo Título' icon={TituloEditIcon}>
      <LoadingProvider>
        <Row className='mb-3'>
          <ColocacionSummary {...props} />
        </Row>
        <Row>
          <LocalErrorProvider>
            <TituloForm {...props} />
          </LocalErrorProvider>
        </Row>
      </LoadingProvider>
    </ColocacionSectionCard>
  )
}

import React from 'react'
import BymaTable from './BymaTable'
import { TableProps } from './BymaTable'
import { useListQuery, UseListQueryParams } from 'core'
import { ListPaginationProps, ListPagination } from 'components'

export interface BymaDataTableProps<T extends BaseEntity>
  extends Omit<TableProps, 'isError' | 'isLoading' | 'isFetching' | 'error' | 'rowData'> {
  queryProps: UseListQueryParams<T>
  listPaginationProps?: Omit<ListPaginationProps, 'total'>
}

/**
 * Shortcut para una tabla conectada a una fuente de datos
 *
 * @param props
 * @returns
 */
export function BymaDataTable<T extends BaseEntity>(props: BymaDataTableProps<T>) {
  const { isLoading, isFetching, isError, error, data } = useListQuery(props.queryProps)

  const rowData = data?.ignore ? undefined : data?.content

  return (
    <>
      <BymaTable {...props} {...{ isLoading, isFetching, isError, error, rowData }} />
      {props.listPaginationProps ? (
        <ListPagination {...props.listPaginationProps} total={data?.total || 0} />
      ) : null}
    </>
  )
}

import React from 'react'
import { SelectProps, BymaSelect } from './BymaSelect'
import { useOptionsQuery, UseListOptionsParams } from 'core'

export interface BymaDataSelectProps extends Omit<SelectProps, 'options'> {
  queryProps: UseListOptionsParams<any>
}

/**
 * Shortcut para crear un select conectado a una fuente de datos
 *
 * @param props
 * @returns
 */
export const BymaDataSelect = (props: BymaDataSelectProps) => {
  const { data } = useOptionsQuery<any, SelectOption>(props.queryProps)
  return <BymaSelect {...props} options={data || []}></BymaSelect>
}

import React, { CSSProperties, KeyboardEventHandler, useCallback } from 'react'
import { useFormContext, Controller } from 'react-hook-form'
import CreatableSelect from 'react-select/creatable'
import { HelperText, ErrorMessage } from 'components'
import { bymaSelectClassesNames, bymaSelectStyles } from './SelectCommons'
import BymaTooltip from 'components/BymaOverlay/BymaTooltip'

import styles from './BymaSelect.module.scss'

interface CreatableSelectProps extends FieldProps {
  onChange?: (selected: any) => void
  isMulti?: boolean
  isClearable?: boolean
  valueMapper?: (option: SelectOption | null) => any
  style?: CSSProperties | undefined
  placeholder?: string
  helperText?: string
  value?: string[]
}

export const BymaCreatableSelect = (props: CreatableSelectProps) => {
  const {
    name,
    id = name,
    label,
    onChange: customOnChange = () => {},
    isMulti = true,
    isClearable = false,
    className = '',
    placeholder = 'Escriba y presione enter',
    style = {},
    helperText = undefined,
    required = false,
    toolTipText = undefined,
  } = props

  const width = 'inherit'
  const height = 'inherit'

  const createOption = (label: string) => ({
    label,
    value: label,
  })

  const [inputValue, setInputValue] = React.useState('')

  const handleChange = (selected) => {
    customOnChange(selected)
  }

  const { control } = useFormContext()

  const stylesFn = useCallback(() => bymaSelectStyles(styles), [styles])

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState }) => {
        const handleKeyDown: KeyboardEventHandler = (event) => {
          const prev =
            field !== undefined && field.value !== undefined && field.value.length > 0
              ? field.value
              : []

          const updatedValue = [...prev.map((item) => createOption(item)), createOption(inputValue)]

          if (!inputValue) return
          switch (event.key) {
            case 'Enter':
            case 'Tab':
              setInputValue('')
              field.onChange(updatedValue.map((item) => item.value))
              event.preventDefault()
          }
        }

        const components = {
          DropdownIndicator: null,
        }

        const errorMessage = fieldState.error?.message

        return (
          <span
            className={styles.selectFormGroup + ' d-inline-block'}
            data-toggle='popover'
            data-trigger='focus'
            data-content='Please selecet account(s)'
            data-mdb-perfect-scrollbar='true'
          >
            <div
              className={styles.bymaSelect + ' ' + className}
              style={{
                width: width,
                height: height,
                ...style,
              }}
            >
              {label !== undefined ? (
                <div className='d-flex'>
                  <label htmlFor={id} className={styles.bymaSelectLabel}>
                    {label + (required ? ' *' : '')}
                  </label>
                  {toolTipText && <BymaTooltip text={toolTipText} />}
                </div>
              ) : null}

              <CreatableSelect
                {...field}
                components={components}
                inputValue={inputValue}
                isClearable={isClearable}
                isMulti={isMulti}
                menuIsOpen={false}
                onChange={(newValue: any) => {
                  field.onChange(newValue.map((item) => item.value))
                  handleChange(newValue.map((item) => item.value))
                }}
                onInputChange={(newValue: any) => setInputValue(newValue)}
                onKeyDown={handleKeyDown}
                placeholder={placeholder}
                styles={stylesFn()}
                classNames={bymaSelectClassesNames({
                  isError: fieldState.error !== undefined,
                  variant: 'Default',
                })}
                value={field.value ? field.value.map((val) => ({ label: val, value: val })) : []}
              />
            </div>
            {helperText && <HelperText text={helperText} />}
            <ErrorMessage error={errorMessage} />
          </span>
        )
      }}
    />
  )
}
